import React from "react";
import { FormLabel, FormControl, Text, Textarea } from "@chakra-ui/react";

const TextareaField = (props) => {
  const { label, required = true, placeholder, error, value, setValue } = props;
  return (
    <FormControl mr="2">
      <FormLabel>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </FormLabel>
      <Textarea
        isInvalid={error}
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
      />
      {error && <Text color="red">{error}</Text>}
    </FormControl>
  );
};

export default TextareaField;
