import { Text, Flex, Box, Image, Checkbox, Button } from "@chakra-ui/react";
import React from "react";
import Card from "components/card/Card.js";
import { useColorModeValue } from "@chakra-ui/system";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import ReactStars from "react-rating-stars-component";
import { FaArrowRight } from "react-icons/fa";

export default function WideProjectCard(props) {
  const {
    project,
    isChecked = false,
    toggleProject = () => {},
    showCheckbox,
    showRating = false,
    canRate,
    onRating,
    rating,
    type,
    NextPhaseHandler,
    programPhaseDetailsHandler
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p="1" mb="2" boxShadow={cardShadow} key={project._id}>
      
      <Flex>
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
        >
          <Image
            h="50px"
            w="50px"
            src={config.apiUrl + `/project/file/` + project.images}
            borderRadius="8px"
            fallbackSrc={placeholder}
            objectFit="cover"
          />
          <Box ml="3">
            <Text fontWeight="normal">{project.title}</Text>
            <Text fontSize="sm">{project.category}</Text>
            {showRating && (
              <ReactStars
                defaultState={5}
                count={5}
                onChange={(rate) => onRating(project._id, rate)}
                size={20}
                activeColor="#ffd700"
                value={rating}
                isHalf
                edit={canRate}
              />
            )}
          </Box>
        </Flex>
        {showCheckbox && (
          <Checkbox
            me="16px"
            colorScheme="brandScheme"
            borderColor={"#3965ff"}
            onChange={toggleProject}
            isChecked={isChecked}
          />
        )}
        {type == "phase" && (
          <Flex w="100%" justifyContent="flex-end">
            <Button
              colorScheme={"green"}
              color="white"
              my="auto"
              mr="20px"
              rightIcon={<FaArrowRight />}
              onClick={()=> NextPhaseHandler(project._id)}
              disabled={!project.hasNextPhase}
            >
              Next Phase
            </Button>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
