import Card from "components/card/Card.js";
import Pagination from "components/pagination/Pagination";
import { useColorModeValue } from "@chakra-ui/system";
import {
  Center,
  Select,
  Text,
  Box,
  Flex,
  Button,
  Input,
  Textarea,
  Heading,
  Divider,
  Checkbox,
  Image,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { toast, ToastContainer } from "react-toastify";
import { SocketContext } from "contexts/SocketContext";
import MySelect from "react-select";
import { MeetingReviewReducer } from "reducers/callOfVoting";
import { useParams } from "react-router-dom";
import SidebarDocs from "components/sidebar/components/SidebarCard";
import { addOrganization } from "reducers/organization";
import { sectors, docs } from "../../../utils/constants";
import { addOpportunity } from "reducers/callofStartups";
import UploadComponent from "./components/UploadFile";

const AddCallOfStartups = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [title, settitle] = useState();
  const [Description, setDescription] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [Ticketnumber, setTicketnumber] = useState();
  const [Budget, setBudget] = useState();
  const [TicketValue, setTicketValue] = useState();
  const [photo, setPhoto] = useState("");
  const [documents, setdocuments] = useState([]);

  const [titleerror, settitleerror] = useState();
  const [Descriptionerror, setDescriptionerror] = useState();
  const [selectedOptionserror, setSelectedOptionserror] = useState([]);
  const [documentserror, setdocumentserror] = useState();
  const [TicketValueerror, setTicketValueerror] = useState();
  const [Budgeterror, setBudgeterror] = useState();
  const [Ticketnumbererror, setTicketnumbererror] = useState();
  
  const [photoerror, setPhotoerror] = useState("");
  const OrganizationStatus = useSelector((state) =>
    state.Organization.addorg ? state.Organization.addorg.status : []
  );
  const Upfiles = (file) => {
    setPhoto(file);
  };

  const validateForm = () => {
    let valid = true;
    if (!title) {
      settitleerror("The title is required!");
      valid = false;
    } else {
      settitleerror("");
    }
    if (!Description) {
      setDescriptionerror("The Description is required!");
      valid = false;
    } else {
      setDescriptionerror("");
    }
    if (!documents.length > 0 ) {
      setdocumentserror("The documents is required!");
      valid = false;
    } else {
      setdocumentserror("");
    }
    if (!TicketValue) {
      setTicketValueerror("The Ticket Value is required!");
      valid = false;
    } else {
      setTicketValueerror("");
    }
    if (!Budget) {
      setBudgeterror("Budget is required!");
      valid = false;
    } else {
      setBudgeterror("");
    }
    if (!selectedOptions.length > 0) {
      setSelectedOptionserror("Sector is required!");
      valid = false;
    } else {
      setSelectedOptionserror("");
    }
    if (!Ticketnumber) {
      setTicketnumbererror("The Ticket number is required!");
      valid = false;
    } else {
      setTicketnumbererror("");
    }
    
    if (!photo) {
      setPhotoerror("Image is required!");
      valid = false;
    } else {
      setPhotoerror("");
    }
    return valid;
  };
  const OrganizationReducerAction = async () => {
    if (!validateForm()) {
      return;
    }
    console.log("jajajajajaakakakakakalalalal")

    const listOfdocs = []
    const categories = []
    for (var i = 0; i < selectedOptions.length; i++) {
      categories.push(selectedOptions[i].value);
    }
    for (var i = 0; i < documents.length; i++) {
      listOfdocs.push(documents[i].value);
    }

    console.log(categories,selectedOptions,'categories')
    const Oppdata = new FormData();
    Oppdata.append("title", title);
    Oppdata.append("description", Description);
    Oppdata.append("budget", Budget);
    Oppdata.append("ticketNumber", Ticketnumber);
    Oppdata.append("ticketValue", TicketValue);
    Oppdata.append("listOfdocs", listOfdocs);
    Oppdata.append("organization", id);
    Oppdata.append("categories", categories);
      {/* */}

    Oppdata.append("image", photo[0]);
    const data = {
      Oppdata,
    };
    await dispatch(addOpportunity(data)).unwrap().then(()=> {                    window.location.href = "/#/admin/organization/" + id;  });
  };
  useEffect(() => {
    console.log(OrganizationStatus, "OrganizationStatus");
  }, [OrganizationStatus]);
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <ToastContainer />

        <Card>
          <Heading size="sm" mb="2">
            General Informations
          </Heading>
          <Divider mb="2" />
          <Flex mt="2" alignItems={"center"}>
            <UploadComponent setFiles={Upfiles} />
          </Flex>
          {photoerror && (
            <Text mt="3" color="red">
              {photoerror}
            </Text>
          )}
          <Flex mt="2" direction={"column"}>
            <Input
              justifyContent={"end"}
              onChange={(ev) => {
                settitle(ev.target.value);
              }}
              name="Title"
              placeholder="call for Startups Name"
              id="Title"
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            ></Input>
            {titleerror && (
              <Text mt="3" color="red">
                {titleerror}
              </Text>
            )}
          </Flex>{" "}
          <Flex mt="2" direction={"column"}>
            <Textarea
              marginTop={"5px!important"}
              w={"100%"}
              marginRight="10px"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              placeholder="Description"
              borderRadius="5px"
            />
            {Descriptionerror && (
              <Text mt="3" color="red">
                {Descriptionerror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>Budget</Text>

            <NumberInput
              onChange={(valueString) => {setBudget(valueString)}}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {Budgeterror && (
              <Text mt="3" color="red">
                {Budgeterror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>Ticket number </Text>

            <NumberInput
              onChange={(valueString) => {setTicketnumber(valueString)}}
              // value={format(value)}
              max={50}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {Ticketnumbererror && (
              <Text mt="3" color="red">
                {Ticketnumbererror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>Ticket value </Text>

            <NumberInput
              onChange={(valueString) => {setTicketValue(valueString)}}
              // value={format(value)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {TicketValueerror && (
              <Text mt="3" color="red">
                {TicketValueerror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>activity area</Text>

            <MySelect
              className="InputGroupy"
              placeholder="Select Categories"
              options={sectors}
              value={selectedOptions}
              onChange={setSelectedOptions}
              isMulti
            />
            {selectedOptionserror && (
                <Text mt="3" color="red">
                  {selectedOptionserror}
                </Text>
              )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>List of documents</Text>

            <MySelect
              className="InputGroupy"
              placeholder="Select List of documents"
              options={docs}
              value={documents}
              onChange={setdocuments}
              isMulti
            />
            {documentserror && (
                <Text mt="3" color="red">
                  {documentserror}
                </Text>
              )}
          </Flex>
          {/* <Flex mt="2" direction={"column"}>
            <Text>List of documents</Text>
            <Select
              justifyContent={"end"}
              onChange={(ev) => {setdocuments(ev.target.value)
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            >
              <option value="">---</option>
              <option value="Silent Investor">Silent Investor</option>
              <option value="Advisory Role">Advisory Role</option>
              <option value="Active Management Role">
                Active Management Role
              </option>
            </Select>
            {documentserror && (
                <Text mt="3" color="red">
                  {documentserror}
                </Text>
              )}
          </Flex> */}
          <Flex justifyContent="end" mt="4">
            
            <Button colorScheme="blue" size="md"  onClick={OrganizationReducerAction}>
              Confirm
            </Button>
          </Flex>
        </Card>
      </Box>
    </>
  );
};

export default AddCallOfStartups;
