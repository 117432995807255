export default function calculateDiffBetweenTwoDates(d1, d2) {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  const diff = date2.getTime() - date1.getTime();
  let msec = diff;
  const hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  const mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  const ss = Math.floor(msec / 1000);
  msec -= ss * 1000;
  return hh + ":" + mm;
}

export const checkIsValidTwitterUrl = (val) => {
  const regex = new RegExp(
    "^(?:(http|https)://)?(?:www\\.)?twitter\\.com\\/.*$",
    "i"
  );
  return val ? regex.test(val) : false;
};
export const checkIsValidFacebookUrl = (val) => {
  const regex = new RegExp(
    "^(?:(http|https)://)?(?:www\\.)?facebook\\.com\\/.*$",
    "i"
  );
  return val ? regex.test(val) : false;
};
export const checkIsValidGithubUrl = (val) => {
  const regex = new RegExp(
    "^(?:(http|https)://)?(?:www\\.)?github\\.com\\/.*$",
    "i"
  );
  return val ? regex.test(val) : false;
};

export const checkIsValidLinkedinUrl = (val) => {
  const regex = new RegExp(
    "^(http|https):\\/\\/[a-z]{2,3}\\.linkedin\\.com\\/.*$",
    "i"
  );
  return val ? regex.test(val) : false;
};

export const checkIsValidSiteWeb = (val) => {
  const regex = new RegExp("^(https?)://[^s/$.?#].[^s]*$");
  return val ? regex.test(val) : false;
};
export const checkIsValidDiscordUrl = (val) => {
  const regex = new RegExp("^https://discord.gg/[a-zA-Z0-9]+$");
  return val ? regex.test(val) : false;
};
export const checkIsNumber = (val) => {
  const regex = /^\d+(.[0-9]{1,2})?$/;
  return val ? regex.test(val) : false;
};

export const NumToTime = (num) => {
  num *= 60;
  var hours = Math.floor(num / 60);
  var minutes = num % 60;
  if (minutes + "".length < 2) {
    minutes = "0" + minutes;
  }
  return hours + "h" + minutes;
};

/**
 * Check if is a valid email
 */
export const checkIsValidEmail = (val) => {
  /** mail regex */
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  return val ? regex.test(val) : false;
};

export const validatePhoneNumber = (input_str) => {
  var re = /^(00216|216|\+216)(9|5|4|2)([0-9]{7})$/;
  return re.test(input_str);
};
export const isGitHubUrlValid = (url) => {
  const githubUrlRegex =
    /^https:\/\/github\.com\/[a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+(\/)?$/;

  return githubUrlRegex.test(url);
};
export const obfuscateEmail = (email) => {
  const atIndex = email.indexOf("@");
  if (atIndex === -1) {
    return email; // Invalid email format
  }

  const username = email.substring(0, atIndex);
  const domain = email.substring(atIndex + 1);

  const obfuscatedUsername = username[0] + "*".repeat(username.length - 1);
  const obfuscatedDomain = domain[0] + "*".repeat(domain.length - 1);

  return obfuscatedUsername + "@" + obfuscatedDomain;
};

export const calculateRemainingPeriod = (applicationDate) => {
  const parsedApplicationDate = new Date(applicationDate);
  const currentDate = new Date();
  const timeDifference = parsedApplicationDate - currentDate;

  // Calculate time units
  const seconds = timeDifference / 1000;
  const minutes = Math.abs(seconds) / 60;
  const hours = minutes / 60;
  const days = Math.floor(hours / 24);
  const months = Math.abs(
    parsedApplicationDate.getMonth() - currentDate.getMonth()
  );
  if (seconds < 0) {
    return false;
  }
  if (hours < 24) {
    return { value: Math.floor(hours), unit: "hours" };
  } else if (days < 30) {
    return { value: days, unit: "days" };
  } else if (months < 12) {
    return { value: months, unit: "months" };
  } else {
    const years = Math.floor(months / 12);
    return { value: years, unit: "years" };
  }
};
export const getRoomName = (userIds) => {
  if (userIds) {
    const sortedUserIds = userIds.sort();
    const roomName = sortedUserIds.join("_");
    return roomName;
  }
};
