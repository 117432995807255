import Card from "components/card/Card.js";
import {
  Text,
  Textarea,
  Flex,
  Button,
  Heading,
  Divider,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import UploadFile from "components/button/UploadFile";
import { excelMimeTypes } from "utils/constants";
import { acceptedMimeTypes } from "utils/constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { applyOpportunity } from "reducers/callofStartups";
import { getRequestCallofstartupById } from "reducers/callofStartups";
import { useSelector } from "react-redux";

const Step8 = (props) => {
  const { applyData, setApplyData, setStep ,requestCall} = props;
  const { idrequest, id } = useParams();

  const [businessPlan, setBusinessPlan] = useState(requestCall ? requestCall.businessPlan :applyData.businessPlan);
  const [demo, setDemo] = useState(requestCall ? requestCall.demo : applyData.demo);
  const [pitchDeck, setPitchDeck] = useState(requestCall ? requestCall.pitchDeck : applyData.pitchDeck);
  const [additionalInformation, setadditionalInformation] = useState(requestCall ? requestCall.AdditionalInformation : applyData.additionalInformation);
  const dispatch = useDispatch();
  const confirm = async () => {
    const Oppdata = new FormData();
    Oppdata.append("callOfStartup", id);
    Oppdata.append("project", requestCall ? requestCall.project._id : applyData.project);
    Oppdata.append("demo", demo[0]);
    Oppdata.append("pitchDeck", pitchDeck[0]);
    Oppdata.append("businessPlan", businessPlan[0]);
    Oppdata.append("AdditionalInformation", additionalInformation);
    Oppdata.append("step", 9);

        const dataaddOpp = {
      Oppdata,
    };
    await dispatch(applyOpportunity(dataaddOpp)).unwrap();
    const data = {
      ...applyData,
      businessPlan,
      demo,
      pitchDeck,
      additionalInformation,
    };
    setApplyData(data);
    setStep(9);
  };
  const back = () => {
    setStep(7);
    const data = {
      ...applyData,
    };
    setApplyData(data);
  };
  const uploadBusinessPlan = (file) => {
    setBusinessPlan(file);
  };
  const uploadPitchDeck = (file) => {
    setPitchDeck(file);
  };
  const uploadDemo = (file) => {
    setDemo(file);
  };
  return (
    <Card>
      <Heading size="sm" mb="2">
        Attachments & Additional Information
      </Heading>
      <Flex mt="2" flexDirection={"column"}>
        <Text>Attach your detailed business plan</Text>
        <UploadFile
          setFiles={uploadBusinessPlan}
          allowedTypes={acceptedMimeTypes}
          title={"Attach your detailed business plan"}
        />
      </Flex>
      <Flex mt="2" flexDirection={"column"}>
        <Text>Attach your pitch deck</Text>
        <UploadFile
          setFiles={uploadPitchDeck}
          allowedTypes={acceptedMimeTypes}
          title={"Attach your pitch deck"}
        />
      </Flex>
      <Flex mt="2" flexDirection={"column"}>
        <Text>Attach your demo deck</Text>
        <UploadFile
          setFiles={uploadDemo}
          allowedTypes={acceptedMimeTypes}
          title={"Attach your demo deck"}
        />
      </Flex>
      <Divider mb="2" />
      <Flex mt="2" flexDirection={"column"}>
        <Text>
          Provide any other information that you believe would be of interest to
          potential investors
        </Text>
        <Textarea
          mb="2"
          placeholder=""
          value={additionalInformation}
          onChange={(e) => {
            setadditionalInformation(e.target.value);
          }}
        />
      </Flex>
      <Flex justifyContent="end" mt="4">
        <Button
          colorScheme="blue"
          variant="outline"
          size="md"
          onClick={back}
          mr="2"
        >
          Back
        </Button>
        <Button colorScheme="blue" size="md" onClick={confirm}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};

export default Step8;
