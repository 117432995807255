import React from "react";
import {
  Divider,
  Flex,
  Icon,
  IconButton,
  OrderedList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import TextCard from "components/card/TextCard";
import { FaPlus } from "react-icons/fa";
import EditRule from "../editHackathon/EditRule";
import RuleItem from "./RuleItem";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const HackathonRules = ({ hackathon, onReload, canEdit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userType, setUserType] = useState("");
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  useEffect(() => {
    if (currentUser) {
      setUserType(currentUser.userType);
    }
  }, [currentUser]);
  return (
    <>
      <Flex alignItems="center" mt="3" justify={'space-between'}>
        <Text fontWeight="bold" mb="3">
          {hackathon.title} (the “Hackathon”) Official Rules
        </Text>
        {userType == "Admin" && (
          <IconButton
            ml="2"
            aria-label="Add"
            icon={<Icon as={FaPlus} />}
            colorScheme="blue"
            size="sm"
            onClick={onOpen}
          />
        )}
      </Flex>
      <Divider my="2" />
      <Flex direction={"column"}>
        <OrderedList>
          {hackathon &&
            hackathon.rules &&
            hackathon.rules.map((item, index) => (
              <RuleItem
                item={item}
                hackathon={hackathon}
                onReload={onReload}
                canEdit={canEdit}
              />
            ))}
        </OrderedList>
      </Flex>
      {hackathon && hackathon.rules.length == 0 && (
        <TextCard text="There is no rule" />
      )}
      <EditRule
        isOpen={isOpen}
        onClose={onClose}
        initialData={hackathon}
        item={null}
        onReload={onReload}
      />
    </>
  );
};

export default HackathonRules;
