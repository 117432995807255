import React from "react";
import {
  Box,
  Flex,
  IconButton,
  ListItem,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import DeleteConfirmationModal from "components/modals/DeleteConfirmationModal";
import { useDispatch } from "react-redux";
import EditRule from "../editHackathon/EditRule";
import { update } from "reducers/hackathon";

const RuleItem = ({ item, hackathon, onReload, canEdit }) => {
  const dispatch = useDispatch();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  const onDeleteHandler = async () => {
    const rules = hackathon.rules.filter((i) => i._id !== item._id);
    const data = new FormData();

    data.append("rules", JSON.stringify(rules));

    const hackathonData = {
      data,
      id: hackathon._id,
    };
    await dispatch(update(hackathonData)).unwrap();
    onCloseDelete();
    onReload();
  };

  return (
    <>
      <ListItem key={item.id} p={2} borderRadius="md">
        <Flex align="center">
          <Box>
            <Text fontWeight="bold">{item.title}</Text>
            <Text fontSize="sm" color="gray.500">
              {item.description}
            </Text>
          </Box>
          <Spacer />
          {canEdit && (
            <IconButton
              icon={<EditIcon />}
              onClick={onOpenEdit}
              aria-label={`Edit ${item.title}`}
              size="sm"
              mr="2px"
              colorScheme="blue"
              _hover={{ color: "teal.500" }}
              transition="color 0.3s"
            />
          )}
          {canEdit && (
            <IconButton
              icon={<DeleteIcon />}
              onClick={onOpenDelete}
              aria-label={`Delete ${item.title}`}
              size="sm"
              colorScheme="red"
              _hover={{ color: "red.500" }}
              transition="color 0.3s"
            />
          )}
        </Flex>
        <EditRule
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          initialData={hackathon}
          item={item}
          onReload={onReload}
        />
        <DeleteConfirmationModal
          isOpen={isOpenDelete}
          onClose={onCloseDelete}
          headerContent="Confirm Deletion"
          bodyContent={`Are you sure you want to delete this rule? This action cannot be undone.`}
          handleDelete={onDeleteHandler}
        />
      </ListItem>
    </>
  );
};

export default RuleItem;
