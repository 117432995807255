import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HistoricReviewDetailsAdminService } from "services/historicReviewService";
import { HistoricReviewAdminService } from "services/historicReviewService";



export const historicReducer = createAsyncThunk(
  "user/HistoricReviewAdminReducer",
  async (dataReview, thunkAPI) => {
    try {
      const { status, data } = await HistoricReviewAdminService(
        dataReview
      );
      if (status === 200) {
        console.log("akakakakakakalaallaal",data)
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const historicdetailsReducer = createAsyncThunk(
  "user/historicdetailsReducer",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await HistoricReviewDetailsAdminService(
        id
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  historyreview: {
    status: "idle",
    data: {},
    error: {},
  },
  datailshistoryreview: {
    status: "idle",
    data: {},
    error: {},
  },
};
const ReviewSlice = createSlice({
  name: "historyreview-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [historicdetailsReducer.pending.type]: (state) => {
      state.datailshistoryreview = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [historicdetailsReducer.fulfilled.type]: (state, action) => {
      state.datailshistoryreview = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [historicdetailsReducer.rejected.type]: (state, action) => {
      state.datailshistoryreview = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [historicReducer.pending.type]: (state) => {
      state.historyreview = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [historicReducer.fulfilled.type]: (state, action) => {
      state.historyreview = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [historicReducer.rejected.type]: (state, action) => {
      state.historyreview = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  
  },
});

export default ReviewSlice.reducer;
