import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
  FormLabel,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { update } from "reducers/hackathon";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditHackathonInfos = ({ isOpen, onClose, initialData, onReload }) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const [title, setTitle] = useState(initialData.title);
  const [description, setDescription] = useState(initialData.description);
  const [dates, setDates] = useState(
    initialData.start
      ? [
          moment(new Date(initialData.start)).format("MM/DD/YYYY HH:mm"),
          moment(new Date(initialData.end)).format("MM/DD/YYYY HH:mm"),
        ]
      : ""
  );

  const [start, setStart] = useState(
    initialData.start
      ? moment(new Date(initialData.start)).format("MM/DD/YYYY HH:mm")
      : ""
  );
  const [end, setEnd] = useState(
    initialData.end
      ? moment(new Date(initialData.end)).format("MM/DD/YYYY HH:mm")
      : ""
  );
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [datesError, setDatesError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("The title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("The description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!start || !end) {
      setDatesError("The start & end dates are required!");
      valid = false;
    } else {
      setDatesError("");
    }

    return valid;
  };
  useEffect(() => {
  console.log(initialData,"fififififififififfi")
  }, [end, start,initialData]);
  const editHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("start", start);
    data.append("end", end);
    console.log(data,'testtest')
    const hackathonData = {
      data,
      id: id,
    };
    await dispatch(update(hackathonData)).unwrap();
    onReload();
  };

  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [title, description, end, start, submitted]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Hackathon Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Title <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Input
                isInvalid={titleError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Title"
                borderRadius="5px"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {titleError && (
                <Text mt="3" color="red">
                  {titleError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Description <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Textarea
                isInvalid={descriptionError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Description"
                borderRadius="5px"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {descriptionError && (
                <Text mt="3" color="red">
                  {descriptionError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Dates <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <DatePicker
                inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
                format="MM/DD/YYYY HH:mm"
                range
                plugins={[
                  <TimePicker position="bottom" hideSeconds={true} />,
                  <DatePanel markFocused header="Start - End" />,
                ]}
                placeholder="Start & End Dates"
                id="dates"
                name="dates"
                value={dates}
                onChange={(ev) => {
                  console.log(ev[0]);
                  setDates(ev);
                  if (ev[0]) {
                    setStart(
                      ev[0].year +
                        "/" +
                        (ev[0].month.index + 1).toString() +
                        "/" +
                        ev[0].day +
                        " " +
                        ev[0].hour +
                        ":" +
                        ev[0].minute
                    );
                  }

                  if (ev[1]) {
                    setEnd(
                      ev[1].year +
                        "/" +
                        (ev[1].month.index + 1).toString() +
                        "/" +
                        ev[1].day +
                        " " +
                        ev[1].hour +
                        ":" +
                        ev[1].minute
                    );
                  }
                }}
                onClose={() => true}
              />
              {datesError && (
                <Text mt="3" color="red">
                  {datesError}
                </Text>
              )}
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={editHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditHackathonInfos;
