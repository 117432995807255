import {
  Text,
  Box,
  Flex,
  Button,
  Input,
  Textarea,
  Heading,
  Divider,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import BasicButton from "components/button/BasicButton";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { updategrants } from "reducers/grants";

export default function EditFormGrant(props) {
  const { grant, setShowEditInfos, onRelaod } = props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dates, setDates] = useState([]);

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [maxBudget, setmaxBudget] = useState();
  const [maxBudgeterror, setmaxBudgeterror] = useState();

  const [minBudget, setminBudget] = useState();
  const [minBudgeterror, setminBudgeterror] = useState();

  const [maximumperproject, setmaximumperproject] = useState();
  const [totalfundvalue, settotalfundvalue] = useState();
  const [maximumperprojecterror, setmaximumperprojecterror] = useState();
  const [totalfundvalueerror, settotalfundvalueerror] = useState();
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [datesError, setDatesError] = useState("");
  const dispatch = useDispatch();
  const validateForm = () => {
    let valid = true;

    if (!title) {
      setTitleError("The title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("The description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!start || !end) {
      setDatesError("The start & end dates are required!");
      valid = false;
    } else {
      setDatesError("");
    }
    if (!maxBudget) {
      setmaxBudgeterror("The max budget are required!");
      valid = false;
    } else {
      setmaxBudgeterror("");
    }
    if (!minBudget) {
      setminBudgeterror("The min budget are required!");
      valid = false;
    } else {
      setminBudgeterror("");
    }
    if (!maximumperproject) {
      valid = false;
    } else {
      setmaximumperprojecterror("");
    }
    if (!totalfundvalue) {
      valid = false;
    } else {
      settotalfundvalueerror("");
    }
    return valid;
  };
  const updateGrantAction = async () => {
    if (!validateForm()) {
      return;
    }

    const data = new FormData();

    data.append("name", title);
    data.append("description", description);
    data.append("maxAllowedBudget", maxBudget);
    data.append("minAllowedBudget", minBudget);
    data.append("maxPerProject", maximumperproject);
    data.append("totalFundValue", totalfundvalue);
    data.append("startDate", start);
    data.append("endDate", end);

    const GrantData = {
      data,
      id: grant._id,
    };
    await dispatch(updategrants(GrantData)).unwrap();

    setShowEditInfos(false);
    onRelaod();
    toast.success("grant has been updated successfully!", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });
  };
  useEffect(() => {
    if (grant) {
      setTitle(grant.name);
      setDescription(grant.description);
      setStart(grant.tags);
      setEnd(grant.tags);
      setmaxBudget(grant.maxAllowedBudget);
      setminBudget(grant.minAllowedBudget);
      setmaximumperproject(grant.maxPerProject);
      settotalfundvalue(grant.totalFundValue);
      setDates([grant.startDate, grant.endDate]);
      setStart(grant.startDate);
      setEnd(grant.endDate);
    }
  }, [grant]);

  return (
    <Box>
      <ToastContainer />
      <Card>
        <Heading size="sm" mb="2">
          General Informations
        </Heading>
        <Divider mb="2" />

        <Flex mt="2" direction={"column"}>
          <Input
            isInvalid={titleError}
            marginTop={"5px!important"}
            w={"100%"}
            marginRight="10px"
            placeholder="grants name"
            borderRadius="5px"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {titleError && (
            <Text mt="3" color="red">
              {titleError}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <Textarea
            isInvalid={descriptionError}
            marginTop={"5px!important"}
            w={"100%"}
            marginRight="10px"
            placeholder="Description"
            borderRadius="5px"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {descriptionError && (
            <Text mt="3" color="red">
              {descriptionError}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <Text color={"grey"}>What is your maximum allowed budget !</Text>

          <NumberInput
            onChange={(valueString) => {
              setmaxBudget(valueString);
            }}
            value={maxBudget}
            placeholder="Max Allowed Budget"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {maxBudgeterror && (
            <Text mt="3" color="red">
              {maxBudgeterror}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <Text color={"grey"}>What is your minimum allowed budget !</Text>

          <NumberInput
            onChange={(valueString) => {
              setminBudget(valueString);
            }}
            value={minBudget}
            placeholder="minimum allowed budget"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {minBudgeterror && (
            <Text mt="3" color="red">
              {minBudgeterror}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <Text color={"grey"}>maximum per project !</Text>

          <NumberInput
            onChange={(valueString) => {
              setmaximumperproject(valueString);
            }}
            value={maximumperproject}
            placeholder="maximum per project "
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {maximumperprojecterror && (
            <Text mt="3" color="red">
              {maximumperprojecterror}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <Text color={"grey"}>total fund value !</Text>

          <NumberInput
            onChange={(valueString) => {
              settotalfundvalue(valueString);
            }}
            value={totalfundvalue}
            placeholder="total fund value"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {totalfundvalueerror && (
            <Text mt="3" color="red">
              {totalfundvalueerror}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <DatePicker
            inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
            format="MM/DD/YYYY HH:mm"
            range
            plugins={[
              <TimePicker position="bottom" hideSeconds={true} />,
              <DatePanel markFocused header="Start - End" />,
            ]}
            placeholder="Start & End Dates"
            id="dates"
            name="dates"
            value={dates}
            onChange={(ev) => {
              setDates(ev);
              console.log(ev, ev[0].year, ev[1], "ev");
              if (ev[0]) {
                setStart(
                  ev[0].year +
                    "/" +
                    (ev[0].month.index + 1).toString() +
                    "/" +
                    ev[0].day +
                    " " +
                    ev[0].hour +
                    ":" +
                    ev[0].minute
                );
              }

              if (ev[1]) {
                setEnd(
                  ev[1].year +
                    "/" +
                    (ev[1].month.index + 1).toString() +
                    "/" +
                    ev[1].day +
                    " " +
                    ev[1].hour +
                    ":" +
                    ev[1].minute
                );
              }
            }}
            onClose={() => true}
          />
          {datesError && (
            <Text mt="3" color="red">
              {datesError}
            </Text>
          )}
        </Flex>
        <Flex justifyContent="end" mt="4">
          <Button colorScheme="blue" size="md" onClick={updateGrantAction}>
            Confirm
          </Button>
        </Flex>
      </Card>
    </Box>
  );
}
