import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Link,
  Badge,
  Image,
  Flex,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { KonnectReducer } from "reducers/Konnect";
import { useDispatch } from "react-redux";
import { Link as Linkk } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function MeetingItem(props) {
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const { item } = props;
  const startDate = new Date(item.start);
  const endDate = new Date(item.end);
  const currentDate = new Date();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const userData = jwt_decode(localStorage.getItem("token"));
    setUserId(userData ? userData._id : "");
  }, []);
  const handleSubmit = async (prix, expert, idmeet) => {
    console.log("button on ");
    await dispatch(
      KonnectReducer({
        amount: prix,
        expert: expert,
        projectholder: userId,
        idmeet: idmeet,
      })
    )
      .unwrap()
      .then((res) => {
        window.location.href = res.value;
      });
  };

  return (
    <Card p="20px" cursor="pointer" bg={"white"}>
      <Image
        src={config.apiUrl + "/project/file/" + item.project.images}
        w={{ base: "100%", "2xl": "100%" }}
        mb="5px"
        h="225px"
        borderRadius="20px"
        fallbackSrc={placeholder}
      />

      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Topic : {item.title}
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Project :{" "}
        <Linkk
          fontWeight="500"
          color={brandColor}
          to={"/admin/project/" + item.project._id}
          fontSize="sm"
        >
          {item.project && item.project.title ? item.project.title : ""}
        </Linkk>
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Expert :{" "}
        <Linkk
          fontWeight="500"
          color={brandColor}
          to={"/admin/expert/profile/" + item.expert._id}
          fontSize="sm"
        >
          {item.expert && item.expert.lastName && item.expert.firstName
            ? item.expert.firstName + " " + item.expert.lastName
            : ""}
        </Linkk>
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Start Date :{" "}
        {item.start ? moment(item.start).format("llll") : ""}
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        End Date : {item.end ? moment(item.end).format("llll") : ""}
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Price : {item.price ? item.price : 0} HBAR
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        <Flex direction="column">
          <Flex justify="space-between" alignItems={"center"}>
            {currentDate >= startDate && currentDate <= endDate && (
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                Status :{" "}
                <Badge className="description" colorScheme="green" mb="5px">
                  Open
                </Badge>
              </Text>
            )}
            {currentDate < startDate && (
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                Status :{" "}
                <Badge className="description" colorScheme="purple" mb="5px">
                  Upcoming
                </Badge>
              </Text>
            )}
            {currentDate > endDate && (
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                Status :{" "}
                <Badge className="description" colorScheme="red" mb="5px">
                  Closed
                </Badge>
              </Text>
            )}
          </Flex>
        </Flex>
      </Text>
      {item.status === "accepted" && (
        <Text
          fontWeight="500"
          color={textColorSecondary}
          fontSize="sm"
          me="4px"
        >
          Link :{" "}
          <Link
            fontWeight="500"
            color={brandColor}
            href={item.url ? item.url : ""}
            target={"_blank"}
            fontSize="sm"
          >
            Click here
          </Link>
        </Text>
      )}
      <Flex
        align="start"
        alignItems={"baseline"}
        justify="space-between"
        mt="25px"
      >
        {item && item.price && item.payed === "false" && (
          <Button
            variant={"brand"}
            onClick={() => handleSubmit(item.price, item.expert._id, item._id)}
          >
            Pay Me
          </Button>
        )}{" "}
        <Button colorScheme="green" size="md">
          <Linkk to={"/admin/add-Review/" + item._id}>Review</Linkk>
        </Button>
      </Flex>
    </Card>
  );
}
