import React from "react";
import Card from "components/card/Card.js";
import Pagination from "components/pagination/Pagination";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getallAdmins } from "../../../reducers/userInformation";
import { useState } from "react";
import { useColorModeValue } from "@chakra-ui/system";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import AdminsListItem from "./components/AdminsListItem";
import { AddIcon } from "@chakra-ui/icons";
import AddAdminModal from "./components/AddAdminModal";

const Administrators = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const dataAdmin = useSelector((state) =>
    state.UserInformation.adminsInformation.data
      ? state.UserInformation.adminsInformation.data.admins
      : []
  );
  const total_Pages = useSelector((state) =>
    state.UserInformation.adminsInformation.data
      ? state.UserInformation.adminsInformation.data.totalPages
      : []
  );
  const fetchUsers = async () => {
    const Admindata = {
      page: page,
      limit: 4,
    };
    dispatch(getallAdmins(Admindata));
    setPage(page);
  };

  useEffect(() => {
    fetchUsers();
  }, [page]);

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <ToastContainer />
        <AddAdminModal isOpen={isOpen} onClose={onClose} />
        <Card p="20px" bg={"#E2E8F0"}>
          <Flex direction={"column"} gap={{ base: "20px", xl: "20px" }}>
            <Flex w="100%" justifyContent="space-between">
              <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
                List of Administrators
              </Text>
              <Button
                ml={"24px"}
                bgColor={"#7551FF"}
                color="white"
                onClick={onOpen}
              >
                <Icon as={AddIcon} mr="10px" borderRadius={"20px"} /> Add Admin
              </Button>
            </Flex>
            <>
              {dataAdmin && dataAdmin.length > 0 ? (
                <SimpleGrid columns="1" gap="10px" mb="10px">
                  {dataAdmin.map((user) => (
                    <AdminsListItem key={user._id} user={user} />
                  ))}
                  {total_Pages > 1 && (
                    <Center pt="5">
                      <Pagination
                        page={page}
                        totalPages={total_Pages}
                        setPage={setPage}
                        setLoader={setLoader}
                      />
                    </Center>
                  )}
                </SimpleGrid>
              ) : (
                <Center fontSize="5xl" textAlign={"center"}>
                  There are no admin to show
                </Center>
              )}
            </>
          </Flex>
        </Card>
      </Box>
    </>
  );
};

export default Administrators;
