import React from "react";
import {
  useColorModeValue,
  Flex,
  Box,
  Text,
  Avatar,
  Spacer,
} from "@chakra-ui/react";
import config from "config/axios/config";
import Card from "components/card/Card.js";
import UsersStatus from "./UserStatus";
import UserInformationModal from "./UserInformationModal";

export default function UsersListItem({ user, onRelaod }) {
  const { Image, firstName, lastName, email, userType } = user;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  const getUserType = () => {
    switch (userType) {
      case "Expert":
        return "Expert";
      case "community":
        return "Community Member";
      case "ProjectHolder":
        return "Project Holder";
      default:
        return "";
    }
  };

  return (
    <Card py="15px">
      <Flex>
        <Avatar
          alt={`${firstName} Avatar`}
          mr="4"
          src={config.apiUrl + "/api/getfile/" + Image}
        />

        <Box w="100%" h="10">
          {" "}
          <Text fontSize="xl" color={textColor}>
            {firstName + " " + lastName}
          </Text>{" "}
          <Spacer />
          <Text fontSize="sm" color={textColorSecondary}>
            {getUserType()}
          </Text>{" "}
        </Box>
        <Box w="100%" h="10">
          {" "}
          <Text fontSize="l" color={textColor}>
            Email{" "}
          </Text>{" "}
          <Text mr="2" fontSize="sm" color={textColorSecondary}>
            {email}
          </Text>{" "}
        </Box>
        <Spacer />
        <UsersStatus user={user} onRelaod={onRelaod} />
        <UserInformationModal user={user} />
      </Flex>
    </Card>
  );
}
