import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as Linkk } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Card from "components/card/Card";
import config from "config/axios/config";
import { historicReducer } from "reducers/historicReview";
import moment from "moment";
import Pagination from "components/pagination/Pagination";
const Index = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [ToExpert, setToExpert] = useState("");
  const [typeReview, settypeReview] = useState("");

  const HistoricReview = useSelector((state) =>
    state.HistoricReview.historyreview.data
      ? state.HistoricReview.historyreview.data.meetingForms
      : null
  );
  const total_Pages = useSelector(
    (state) => state.HistoricReview.historyreview.data.totalPages
  );
  const HistoricReviewAdminReducerAction = async () => {
    const DataTeview = {
      pageNumber: page,
      recentyl: "true",
      typeReview: typeReview,
      ToExpert: ToExpert,
    };
    setLoader(false);

    await dispatch(historicReducer(DataTeview)).unwrap();
  };
  useEffect(() => {
    HistoricReviewAdminReducerAction();
  }, [page,ToExpert,typeReview]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <>
        <Card mb="20px">
        <HStack spacing={2} mb="3">

          <Select
          mt="3"
            mb="3"
            onChange={(ev) => {
              setToExpert(ev.target.value)
            }}
            name="category"
            id="category"
            borderColor={"#E0E5F2"}
            width="100%"
          >
              <option value="">-----</option>
              <option value="false">Expert to Project Holder</option>
              <option value="true">Project Holder to Expert</option>

          </Select>
          <Select
            mb="3"
            onChange={(ev) => {
              settypeReview(ev.target.value)
            }}
            name="category"
            id="category"
            borderColor={"#E0E5F2"}
            width="100%"
          >
              <option value="">-----</option>
              <option value="Complaint">Complaint</option>
              <option value="Review">Review</option>

          </Select>
       </HStack>
        </Card>
        {HistoricReview && HistoricReview.length > 0 && (
          <Card direction="column" w="100%" px="0px" overflowX={"auto"}>
            <Flex direction="column" w="100%">
              <Flex
                align={{ sm: "flex-start", lg: "center" }}
                justify="space-between"
                w="100%"
                px="22px"
                pb="20px"
                mb="10px"
                boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
              >
                <Table color="gray.500">
                  <Thead>
                    <Tr>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Meeting
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Project Holder
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Expert
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Created Date
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Type
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          who
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Details
                        </Flex>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {HistoricReview.map((item, index) => {
                      return (
                        <Tr>
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Flex align="center">
                              <Avatar
                                src={
                                  config.apiUrl +
                                  "/project/file/" +
                                  item.idMeet.project.images
                                }
                                w="40px"
                                h="40px"
                                me="8px"
                              />
                              <Text fontSize="sm" fontWeight="600">
                                <Linkk>{item.idMeet.title}</Linkk>
                              </Text>
                            </Flex>
                          </Td>
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Flex align="center">
                              <Avatar
                                src={
                                  config.apiUrl +
                                  "/api/getfile/" +
                                  item.user.Image
                                }
                                w="40px"
                                h="40px"
                                me="8px"
                              />
                              <Text fontSize="sm" fontWeight="600">
                                <Linkk>{item.user.firstName}</Linkk>
                              </Text>
                            </Flex>
                          </Td>
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Flex align="center">
                              <Avatar
                                src={
                                  config.apiUrl +
                                  "/api/getfile/" +
                                  item.expert.Image
                                }
                                w="40px"
                                h="40px"
                                me="8px"
                              />
                              <Text fontSize="sm" fontWeight="600">
                                <Linkk
                                  to={
                                    "/admin/expert/profile/" + item.expert._id
                                  }
                                >
                                  {item.expert.firstName}
                                </Linkk>
                              </Text>
                            </Flex>
                          </Td>
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Text fontSize="sm" fontWeight="600">
                              {moment(item.updatedAt).format(
                                "YYYY-MM-DD hh:mm"
                              )}
                            </Text>
                          </Td>
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Text fontSize="sm" fontWeight="600">
                              {item.typeReview}{" "}
                            </Text>
                          </Td>
                          {item.ToExpert == true && (
                            <Td
                              fontSize={{ sm: "14px" }}
                              minW={{ sm: "150px", md: "200px", lg: "auto" }}
                              borderColor="transparent"
                            >
                              <Text fontSize="sm" fontWeight="600">
                                Project Holder to Expert{" "}
                              </Text>
                            </Td>
                          )}{" "}
                          {item.ToExpert == false && (
                            <Td
                              fontSize={{ sm: "14px" }}
                              minW={{ sm: "150px", md: "200px", lg: "auto" }}
                              borderColor="transparent"
                            >
                              <Text fontSize="sm" fontWeight="600">
                                Expert to Project Holder{" "}
                              </Text>
                            </Td>
                          )}
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Button bgColor={"#7551FF"} color="white">
                              <Linkk to={"/admin/ReviewDetails/" + item._id}>
                                <Text>View</Text>
                              </Linkk>
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Flex>
              {total_Pages > 1 && (
                <Center pt="5">
                  <Pagination
                    page={page}
                    totalPages={total_Pages}
                    setPage={setPage}
                    setLoader={setLoader}
                  />
                </Center>
              )}
              {/* {total_Pages > 1 && (
                <Center pt="5">
                  <Pagination
                    page={page}
                    totalPages={total_Pages}
                    setPage={setPage}
                    setLoader={setLoader}
                  />
                </Center>
              )} */}
            </Flex>
          </Card>
        )}
      </>
      {HistoricReview && HistoricReview.length === 0 && (
        <Card mb="20px">
          <Text textAlign={"center"} fontSize="large">
            No Review
          </Text>
        </Card>
      )}
    </Box>
  );
};

export default Index;
