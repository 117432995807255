import React, { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Spinner,
  Text,
  Box,
  Center,
  Select,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/pagination/Pagination";
import { getallProjects } from "reducers/project";
import { sectors } from "utils/constants";
import ProjectItem from "views/admin/projects/components/ProjectItem";

const ChooseProject = (props) => {
  const { project, setProject, projectError } = props;
  const dispatch = useDispatch();
  const [categories, setCategories] = useState("");
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const projects = useSelector((state) =>
    state.Project.projects.data ? state.Project.projects.data.result : null
  );
  const totalPages = useSelector(
    (state) => state.Project.projects.data.totalPages
  );
  const status = useSelector((state) => state.Project.projects.status);
//   const currentUser = useSelector(
//     (state) => state.UserInformation.currentUser.data
//   );
//   const currentUserProjects = useSelector((state) =>
//     state.Project.projects.data
//       ? state.Project.projectsbyUser.data.message
//       : null
//   );
//   useEffect(() => {
//     if (currentUser) {
//       const filterData = {
//         id: currentUser._id,
//         isConfirmed: true,
//         page: page,
//         limit: 10,
//       };
//       dispatch(getProjectsbyUser(filterData));
//     }
//   }, [currentUser]);
  useEffect(() => {
    const filterData = {
      categories: categories === "Categories" ? "" : categories,
      isConfirmed: true,
      page: page,
      limit: 10,
      name: ""
    };
    dispatch(getallProjects(filterData));
  }, [categories, page]);

  return (
    <Card>
      <Heading size="sm" mt="4" mb="2">
        Choose a project
      </Heading>
      {!projects && status === "loading" && (
        <Flex flexDirection="column" mt="3" mb="3">
          <Spinner my="auto!important" mx="auto" />
        </Flex>
      )}
      {projects && !projects.length && (
        <Flex flexDirection="column">
          <Card mt="3" mb="3">
            <Text fontSize={"2xl"} textAlign={"center"}>
              There is no project
            </Text>
          </Card>
        </Flex>
      )}
      {projects && projects.length > 0 && (
        <Flex flexDirection={"column"}>
          <Flex justifyContent={"flex-end"}>
            <Select
              mt="3"
              mb="3"
              onChange={(ev) => {
                setCategories(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              width="100%"
            >
              {sectors.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </Select>
          </Flex>
          <Box>
            {projects.map((item) => (
              <ProjectItem
                project={item}
                selectedProject={project}
                setSelectedProject={setProject}
              />
            ))}
            {totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={setLoader}
                />
              </Center>
            )}
          </Box>
          {projectError && (
            <Text mt="3" color="red">
              {projectError}
            </Text>
          )}
        </Flex>
      )}

    </Card>
  );
};

export default ChooseProject;
