import React from "react";
import { Text, Box, Icon } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { FaQuestionCircle } from "react-icons/fa";

export default function AskManager(props) {
  const {managerEmail} = props
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Box
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      height="100%"
      backgroundColor="#9e9e9e14"
      textAlign={"center"}
    >
      <Icon as={FaQuestionCircle} color="blue.500" boxSize={10} mr={2} />
      <Text fontSize="xl" color={textColor} mt="4">
        Got questions? Email Asissting programs manager for answers.
      </Text>
      <Text fontSize="lg" color={"blue"} mt="4">
        {managerEmail}
      </Text>
    </Box>
  );
}
