import {
  SimpleGrid,
  Text,
  useColorModeValue,
  Box,
  Flex,
  Image,
  Spinner,
  Badge,
  Heading,
  Button,
  Divider,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "config/axios/config";
import moment from "moment";
import placeholder from "assets/img/placeholder.jpg";
import { getOrganization } from "reducers/organization";
import { useState } from "react";
import { Link as Linkk } from "react-router-dom";
import "./Organization.css";
import CallForStartupsItem from "../callofStartups/components/CallForStartupsItem";
import BannerImage from "components/image/BannerImage";

const OrganizationDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const [userType, setUserType] = useState("");

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const organization = useSelector((state) =>
    state.Organization.organization.data
      ? state.Organization.organization.data.result
      : null
  );
  const opportunities = useSelector((state) =>
    state.Organization.organization.data
      ? state.Organization.organization.data.opportunities
      : null
  );
  const statusOrganizationDetails = useSelector(
    (state) => state.Organization.organization.status
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const getOrganizationByIdAction = async () => {
    await dispatch(getOrganization(id)).unwrap();
  };
  useEffect(() => {
    getOrganizationByIdAction();
  }, [id]);
  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {organization && userType == "Investor" && (
        <Flex w="100%" justifyContent="flex-end" mb="20px">
          <Button ml={"24px"} bgColor={"#7551FF"} color="white" my="auto">
            <Linkk to={"/admin/Add-Opportunity/" + organization._id}>
              <Text>Add a call For Startups </Text>
            </Linkk>
          </Button>
        </Flex>
      )}
      {!organization && statusOrganizationDetails === "loading" && (
        <Flex flexDirection="column" h="100vh">
          <Spinner my="auto!important" mx="auto" />
        </Flex>
      )}
      {!organization && statusOrganizationDetails === "failed" && (
        <Flex flexDirection="column" h="100vh">
          <Card mt="3" mb="3">
            <Text fontSize={"2xl"} textAlign={"center"}>
              Organization does not exist
            </Text>
            <Text fontSize="sm" textAlign={"center"}>
              Organization has been deleted
            </Text>
          </Card>
        </Flex>
      )}
      {organization && (
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <BannerImage
            src={config.apiUrl + "/org/file/" + organization.image}
          />
          <Flex align="center" direction={{ base: "column", md: "row" }}>
            <Box mt={{ base: "10px", md: "0" }} w="100%" mb="5">
              <Flex
                mt="2"
                w="100%"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text
                  color={textColorPrimary}
                  fontWeight="bold"
                  fontSize="2xl"
                  mt="10px"
                  mb="4px"
                  display="flex"
                  alignItems={"center"}
                >
                  {organization.name}
                </Text>
                <Badge colorScheme="purple" p="2px 0px" mr="1">
                  <Text
                    fontSize="10px"
                    width="100%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {organization.type}
                  </Text>
                </Badge>
              </Flex>
              <Flex mt="5px" mb="5px">
                {organization.categories.map((category) => (
                  <Badge
                    colorScheme="pink"
                    p="2px 0px"
                    mr="1"
                    variant="outline"
                  >
                    <Text
                      fontSize="10px"
                      width="100%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {category}
                    </Text>
                  </Badge>
                ))}
              </Flex>
              <Text color={textColorSecondary} fontSize="md">
                {organization.description
                  ? organization.description
                  : "There is no description"}
              </Text>
            </Box>
          </Flex>

          <SimpleGrid columns="2" gap="20px">
            <Information
              boxShadow={cardShadow}
              title="Start Date"
              value={moment(organization.start).format("LLLL")}
            />
            <Information
              boxShadow={cardShadow}
              title="End Date"
              value={moment(organization.end).format("LLLL")}
            />
            <Information
              boxShadow={cardShadow}
              title="Investement Range"
              value={organization.range}
            />
            <Information
              boxShadow={cardShadow}
              title="Exit Strategy"
              value={organization.exitStrategy}
            />
            <Information
              boxShadow={cardShadow}
              title="Expected Return"
              value={organization.expectedReturn + " HBAR"}
            />
            <Information
              boxShadow={cardShadow}
              title="Investor Role"
              value={organization.investorRole}
            />
            <Information
              boxShadow={cardShadow}
              title="Risk Tolerance"
              value={organization.riskTolerance}
            />
            {organization.owner && (
              <Information
                boxShadow={cardShadow}
                title="Owner"
                value={
                  organization.owner.firstName +
                  " " +
                  organization.owner.lastName
                }
              />
            )}
          </SimpleGrid>
          {organization.requirements && (
            <Card mt="3" boxShadow={cardShadow}>
              <Heading size="sm" mb="3">
                Requirements
              </Heading>
              <Text>{organization.requirements}</Text>
            </Card>
          )}
          {organization.documents && (
            <Card mt="3" boxShadow={cardShadow}>
              <Heading size="sm" mb="3">
                Documents
              </Heading>
              <a
                href={
                  organization.documents
                    ? config.apiUrl + "/org/file/" + organization.documents
                    : ""
                }
                target="_blank"
                className="me-2 link-file"
              >
                {organization.documents}
              </a>
            </Card>
          )}
          {opportunities && (
            <Card mt="3" boxShadow={cardShadow}>
              <Heading size="sm" mt="4" mb="2">
                Call for a Startups{" "}
              </Heading>
              <Divider mb="2" />
              <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="2px">
                {opportunities.map((item, index) => (
                  <CallForStartupsItem opportunity={item}></CallForStartupsItem>
                ))}
              </SimpleGrid>
              {opportunities && opportunities.length == 0 && (
                <p> There is no Call for Startups yet</p>
              )}
            </Card>
          )}
        </Card>
      )}
    </Box>
  );
};

export default OrganizationDetails;
