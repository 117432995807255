import React from "react";
import {
  Box,
  Text,
  Flex,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";

const VotingPercentage = ({
  expertPercentage,
  communityPercentage,
  partnersPercentage,
}) => {
  return (
    <Box mb="4" mt="4">
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Voting Percentages
      </Text>
      <Flex justifyContent="space-around">
        <Box textAlign="center">
          <CircularProgress
            value={expertPercentage}
            size="60px"
            thickness="12px"
            color="teal.500"
          >
            <CircularProgressLabel>{expertPercentage}%</CircularProgressLabel>
          </CircularProgress>
          <Text mt={2}>Experts</Text>
        </Box>

        <Box textAlign="center">
          <CircularProgress
            value={communityPercentage}
            size="60px"
            thickness="12px"
            color="blue.500"
          >
            <CircularProgressLabel>
              {communityPercentage}%
            </CircularProgressLabel>
          </CircularProgress>
          <Text mt={2}>Community</Text>
        </Box>

        <Box textAlign="center">
          <CircularProgress
            value={partnersPercentage}
            size="60px"
            thickness="12px"
            color="orange.500"
          >
            <CircularProgressLabel>{partnersPercentage}%</CircularProgressLabel>
          </CircularProgress>
          <Text mt={2}>Partners</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default VotingPercentage;
