/* eslint-disable */
import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FormErrorMessage, Image, Spinner } from "@chakra-ui/react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import logoCov from "assets/img/logoCov.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultAuth from "layouts/auth/Default";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { restPasswordUser } from "reducers/userInformation";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import logo from "assets/img/logo.png";

function resetPassword() {
  const { token } = useParams();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [topSCreenErr, setTopScreenErr] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const submitHandler = async (password) => {
    await dispatch(
      restPasswordUser({ password: password, token: token })
    ).unwrap();
  };

  const schema = Yup.object().shape({
    password: Yup.string()
      .required("This field is required")
      .min(
        8,
        "Your password must be at least 8 characters and should include a combination of numbers, letters and special characters (!$@%)"
      )
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
  });
  const status = useSelector(
    (state) => state.UserInformation.restTokenPassword.data.success
  );

  useEffect(() => {
    if (status === true) {
      window.location.href = "/#/auth/sign-in";
      toast.info(status, {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
    }
  }, [status]);
  return (
    <DefaultAuth illustrationBackground={logoCov} image={logoCov}>
      {loader === true ? (
        <Flex
          width={"96"}
          my="auto"
          alignItems={"center"}
          justifyContent="center"
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Flex
            width="100%"
            flexDirection="column"
            justifyContent={"center"}
            alignItems={{ base: "center", lg: "unset" }}
            display={{ base: "flex", lg: "none" }}
            mb="20px"
          >
            <Image src={logo} alt="Logo" maxW={{ base: "70%", md: "50%" }} />
          </Flex>
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Update your password
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={"red"}
              fontWeight="400"
              fontSize="md"
            >
              {topSCreenErr !== "" && topSCreenErr}
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <Flex align="center" mb="25px"></Flex>
            <Formik
              validationSchema={schema}
              initialValues={{ password: "" }}
              onSubmit={(values) => {
                submitHandler(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form>
                  <Field name="password">
                    {({ field }) => (
                      <FormControl
                        isInvalid={errors.password && touched.password}
                      >
                        <FormLabel
                          htmlFor="password"
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          Password<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size="md">
                          <Input
                            id="password"
                            isRequired={true}
                            fontSize="sm"
                            placeholder="Insert your password here."
                            mb={
                              errors.password && touched.password
                                ? "0px"
                                : "24px"
                            }
                            size="lg"
                            type={show ? "text" : "password"}
                            {...field}
                          />

                          <InputRightElement
                            display="flex"
                            alignItems="center"
                            mt="4px"
                          >
                            <Icon
                              color={textColorSecondary}
                              _hover={{ cursor: "pointer" }}
                              as={show ? MdOutlineRemoveRedEye : RiEyeCloseLine}
                              onClick={handleClick}
                            />
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                    type="submit"
                  >
                    Update password
                  </Button>
                </Form>
              )}
            </Formik>
            <NavLink to="/auth/sign-in">
              <Text color={textColorBrand} as="span" ms="5px" mt="16px">
                Back to Sign In
              </Text>
            </NavLink>
          </Flex>
        </Flex>
      )}
      <ToastContainer />
    </DefaultAuth>
  );
}

export default resetPassword;
