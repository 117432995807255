import React from "react";
import { Button, Icon } from "@chakra-ui/react";

export default function IconButton(props) {
  const {
    icon,
    onClick,
    style = null,
    color="brand.500",
    cssClass = "",
    disabled = false,
  } = props;
  return (
    <Button
      position="absolute"
      bg="white"
      _hover={{ bg: "whiteAlpha.900" }}
      _active={{ bg: "white" }}
      _focus={{ bg: "white" }}
      p="0px !important"
      bottom="10px"
      left="10px"
      borderRadius="50%"
      minW="40px"
      h="40px"
      onClick={onClick}
      style={style}
      className={cssClass}
      disabled={disabled}
    >
      <Icon
        transition="0.2s linear"
        w="25px"
        h="25px"
        as={icon}
        color={color}
      />
    </Button>
  );
}
