import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  Flex,
  Textarea,
  Input,
  Button,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { updateCohort } from "reducers/cohort";
import BasicButton from "components/button/BasicButton";
import { update } from "reducers/assistingProgram";

export default function EditAssistingProgramForm(props) {
  const {
    isOpen,
    onClose,
    assistingProgram,
    setShowEditInfos,
    getCohortByIdAction,
    onReload
  } = props;
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [dates, setDates] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [datesError, setDatesError] = useState("");
  const [enddateSubmission, setenddateSubmission] = useState("");
  const [enddateSubmissionerror, setenddateSubmissionerror] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("The title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("The description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!start || !end) {
      setDatesError("The start & end dates are required!");
      valid = false;
    } else {
      setDatesError("");
    }
    return valid;
  };
  const editAssistingProgramAction = async () => {
    if (!validateForm()) {
      return;
    }
    const data = new FormData();

    data.append("end", end);
    data.append("start", start);
    data.append("title", title);
    data.append("description", description);
    data.append("endDateSubmission", enddateSubmission);
    data.append("managerEmail", email);

    const dataUpdate = {
      data,
      id: assistingProgram._id,
    };

    await dispatch(update(dataUpdate)).unwrap();
    toast.success("Assisting Program Overview has been updated successfully!", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });
    onClose();
    onReload()
  };
  useEffect(() => {
    if (assistingProgram) {
      setTitle(assistingProgram.title);
      setDescription(assistingProgram.description);
      setStart(assistingProgram.start);
      setEnd(assistingProgram.end);
      setDates([assistingProgram.start, assistingProgram.end]);
      setenddateSubmission([assistingProgram.endDateSubmission]);
      setEmail(assistingProgram.managerEmail);
    }
  }, [assistingProgram]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Assisting Program Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Card p="2" direction="column" w="100%">
            <ToastContainer />
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Title <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Input
                isInvalid={titleError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Title"
                borderRadius="5px"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {titleError && (
                <Text mt="3" color="red">
                  {titleError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Description <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Textarea
                isInvalid={descriptionError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Description"
                borderRadius="5px"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {descriptionError && (
                <Text mt="3" color="red">
                  {descriptionError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Start and End Date <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <DatePicker
                inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
                format="MM/DD/YYYY HH:mm"
                range
                plugins={[
                  <TimePicker position="bottom" hideSeconds={true} />,
                  <DatePanel markFocused header="Start - End" />,
                ]}
                placeholder="Start & End Dates"
                id="dates"
                name="dates"
                value={dates}
                onChange={(ev) => {
                  setDates(ev);
                  if (ev[0]) {
                    setStart(
                      ev[0].year +
                        "/" +
                        (ev[0].month.index + 1).toString() +
                        "/" +
                        ev[0].day +
                        " " +
                        ev[0].hour +
                        ":" +
                        ev[0].minute
                    );
                  }

                  if (ev[1]) {
                    setEnd(
                      ev[1].year +
                        "/" +
                        (ev[1].month.index + 1).toString() +
                        "/" +
                        ev[1].day +
                        " " +
                        ev[1].hour +
                        ":" +
                        ev[1].minute
                    );
                  }
                }}
                onClose={() => true}
              />
              {datesError && (
                <Text mt="3" color="red">
                  {datesError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                End Date Submission <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <DatePicker
                inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
                format="MM/DD/YYYY HH:mm"
                plugins={[
                  <TimePicker position="bottom" hideSeconds={true} />,

                  <DatePanel markFocused header="Date Submission" />,
                ]}
                placeholder="End Date Submission"
                id="date"
                name="date"
                value={enddateSubmission[0]}
                onChange={(ev) =>
                  setenddateSubmission(
                    ev.year +
                      "/" +
                      (ev.month.index + 1).toString() +
                      "/" +
                      ev.day +
                      " " +
                      ev.hour +
                      ":" +
                      ev.minute
                  )
                }

                // onClose={() => true}
              />
              {enddateSubmissionerror && (
                <Text mt="3" color="red">
                  {enddateSubmissionerror}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Email Manager <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Input
                isInvalid={emailError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Email"
                borderRadius="5px"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <Text mt="3" color="red">
                  {emailError}
                </Text>
              )}
            </Flex>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={editAssistingProgramAction}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
