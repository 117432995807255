import React from "react";
import { Text, Box, Icon, IconButton, useDisclosure } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { FaEdit, FaQuestionCircle } from "react-icons/fa";
import EditManager from "../editHackathon/EditManager";

export default function AskManager(props) {
  const { managerEmail, hackathon, onReload, canEdit } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      height="100%"
      backgroundColor="#9e9e9e14"
      textAlign={"center"}
    >
      <Icon as={FaQuestionCircle} color="blue.500" boxSize={10} mr={2} />
      <Text fontSize="xl" color={textColor} mt="4">
        Got questions? Email the hackathon manager for answers.
      </Text>
      <Text fontSize="lg" color={"blue"} mt="4">
        {managerEmail}{" "}
        {canEdit && (
          <IconButton
            icon={<FaEdit />}
            size="sm"
            ml="2"
            colorScheme="blue"
            onClick={onOpen}
          />
        )}
      </Text>
      <EditManager
        isOpen={isOpen}
        onClose={onClose}
        initialData={hackathon}
        onReload={onReload}
      />
    </Box>
  );
}
