import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getallProjects } from "reducers/project";
import ProjectFilters from "./ProjectFilters";
import ProjectCardList from "./ProjectCardList";

export default function Projects() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState("");
  const projects = useSelector((state) => state.Project.projects.data.result);
  const totalPages = useSelector(
    (state) => state.Project.projects.data.totalPages
  );

  useEffect(() => {
    const filterData = {
      categories: categories == "Categories" ? "" : categories,
      isConfirmed: true,
      limit: "6",
      page: page,
      name:""
    };
    dispatch(getallProjects(filterData));
  }, [categories, page]);

  return (
    <Box pt={"30px"}>
      <ToastContainer />
      <ProjectFilters setCategories={setCategories} />
      <ProjectCardList
        projects={projects}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        bg={"white"}
        colorText={"black"}
      />
    </Box>
  );
}
