import { SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  MdHourglassTop,
  MdGroup,
  MdFolderOpen,
  MdOutlineAssistant,
  MdEmojiEvents,
  MdOutlineEvent,
  MdOutlineMoodBad,
  MdOutlineRateReview,
} from "react-icons/md";
import { FaDonate, FaRegBuilding } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getStatics } from "reducers/dashboard";
import MiniStatItem from "./MiniStatItem";

export default function MiniStatCard(props) {
  const { userType } = props;
  const dispatch = useDispatch();
  const [stats, setStats] = useState([]);
  const statics = useSelector((state) => state.Dashboard.statics.data);

  const getStaticsAction = async () => {
    await dispatch(getStatics()).unwrap();
  };

  useEffect(() => {
    getStaticsAction();
  }, []);

  useEffect(() => {
    if (statics) {
      setStats([
        // {
        //   title: "Sessions",
        //   value: statics.session,
        //   icon: MdHourglassTop,
        //   hide: userType !== "Admin" && userType !== "SuperAdmin",
        // },
        {
          title: "Events",
          value: statics.events,
          icon: MdOutlineEvent,
          hide: false,
        },
        {
          title: "Projects",
          value: statics.project,
          icon: MdFolderOpen,
          hide: false,
        },
        {
          title: "Experts",
          value: statics.experts,
          icon: MdGroup,
          hide: userType !== "Admin" && userType !== "SuperAdmin",
        },
        {
          title: "Community members",
          value: statics.community,
          icon: MdGroup,
          hide: userType !== "Admin" && userType !== "SuperAdmin",
        },
        {
          title: "Project Holder",
          value: statics.ProjectHolder,
          icon: MdGroup,
          hide: userType !== "Admin" && userType !== "SuperAdmin",
        },
        // {
        //   title: "Assisting Programs",
        //   value: statics.assistingPrograms,
        //   icon: MdOutlineAssistant,
        //   hide: userType !== "Admin" && userType !== "SuperAdmin",
        // },
        // {
        //   title: "Numbers of Reviews",
        //   value: statics.Reviews,
        //   icon: MdOutlineRateReview,
        //   hide: userType !== "Admin" && userType !== "SuperAdmin",
        // },
        // {
        //   title: "Numbers of Complaints",
        //   value: statics.Complaints,
        //   icon: MdOutlineMoodBad,
        //   hide: userType !== "Admin" && userType !== "SuperAdmin",
        // },
        // {
        //   title: "Organizations",
        //   value: statics.organizations,
        //   icon: FaRegBuilding,
        //   hide:
        //     userType !== "Investor" &&
        //     userType !== "ProjectHolder" &&
        //     userType !== "Admin",
        // },
        {
          title: "Hackathons",
          value: statics.hackathon,
          icon: MdEmojiEvents,
          hide: false,
        },
        // {
        //   title: "Grants",
        //   value: statics.grants,
        //   icon: FaDonate,
        //   hide: false,
        // },
      ]);
    }
  }, [statics]);

  return (
    <>
      {statics && (
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
          gap="20px"
          mb="20px"
        >
          {stats.map((item, index) => (
            <>
              {!item.hide && (
                <MiniStatItem
                  key={index}
                  title={item.title}
                  value={item.value}
                  icon={item.icon}
                />
              )}
            </>
          ))}
        </SimpleGrid>
      )}
    </>
  );
}
