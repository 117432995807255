import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RTLLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import store from "app/store";
import { Provider } from "react-redux";
import { SocketProvider, socket } from "./contexts/SocketContext";
import TeamInvitationAcceptance from "views/auth/teamInvitationAcceptance";
import MeetInvitationAcceptance from "views/auth/meetInvitationAcceptance";
import ProjectInvitationAcceptance from "views/auth/projectInvitationAcceptance";
ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Provider store={store}>
        <ThemeEditorProvider>
          <SocketProvider>
            <HashRouter>
              {localStorage.getItem("token") &&
              localStorage.getItem("CurrentUserData") ? (
                <Switch>
                  <Route
                    path={`/team-invitation-acceptance/:token`}
                    component={TeamInvitationAcceptance}
                    exact
                  />
                  <Route
                    path={`/project-invitation-acceptance/:token`}
                    component={ProjectInvitationAcceptance}
                    exact
                  />
                  <Route
                    path={`/meet-invitation-acceptance/:token`}
                    component={MeetInvitationAcceptance}
                    exact
                  />
                  <Route path={`/admin`} component={AdminLayout} />
                  <Route path={`/rtl`} component={RTLLayout} />
                  <Redirect  to="/admin/default" />
                </Switch>
              ) : (
                <Switch>
                  <Route
                    path={`/meet-invitation-acceptance/:token`}
                    component={MeetInvitationAcceptance}
                    exact
                  />
                  <Route
                    path={`/project-invitation-acceptance/:token`}
                    component={ProjectInvitationAcceptance}
                    exact
                  />
                  <Route
                    path={`/team-invitation-acceptance/:token`}
                    component={TeamInvitationAcceptance}
                    exact
                  />
                  <Route path={`/auth`} component={AuthLayout} />
                  <Redirect  to="/auth/landing" />
                </Switch>
              )}
            </HashRouter>
          </SocketProvider>
        </ThemeEditorProvider>
      </Provider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
