import React from "react";
import {
  Box,
  Button,
  Text,
  Flex,
  Image,
  Avatar,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import logo from "assets/img/logo.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { acceptMeetInvitation } from "reducers/meeting";
import moment from "moment";
import config from "config/axios/config";
import placeholder from "assets/img/placeholder.jpg";

const MeetInvitationAcceptance = () => {
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isAccepted, setIsAccepted] = useState(0);
  const [meetingData, setMeetingData] = useState({});
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");

  const handleAccept = () => {
    const data = { token, status: "accepted" };
    dispatch(acceptMeetInvitation(data))
      .unwrap()
      .then(() => {
        setIsAccepted(1);
      });
  };
  const handleRefuse = () => {
    const data = { token, status: "refused" };
    dispatch(acceptMeetInvitation(data))
      .unwrap()
      .then(() => {
        setIsAccepted(2);
      });
  };

  useEffect(() => {
    const data = jwt_decode(token);
    if (data && data.meetings) {
      setMeetingData(data.meetings);
    }
    console.log(data, "token data...");
  }, [token]);

  return (
    <Flex direction={"column"} justifyContent={"center"} alignItems={"center"}>
      <Image src={logo} alt="Logo" maxW="140px" />
      {isAccepted === 0 && meetingData && (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="lg"
          bg="white"
          p={4}
          mt={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Meet Invitation
          </Text>
          <Text mb={2}>You have been invited to join a meeting.</Text>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            {meetingData.title}
          </Text>
          <Text mb={2}>{meetingData.description}</Text>
          <Flex direction="column">
            <Text mb={2}>
              <b>Date: </b>
              {moment(new Date(meetingData.start)).format(
                "MM/DD/YYYY HH:mm"
              )} -{" "}
              {moment(new Date(meetingData.end)).format("MM/DD/YYYY HH:mm")}
            </Text>
            <Text mb={2}>
              <b>Creator: </b>
              {meetingData.createdby && (
                <Avatar
                  color="white"
                  name={
                    meetingData.createdby.firstName +
                    " " +
                    meetingData.createdby.lastName
                  }
                  size="xs"
                  src={
                    meetingData.createdby.Image
                      ? config.apiUrl +
                        "/api/getfile/" +
                        meetingData.createdby.Image
                      : ""
                  }
                  mr="1"
                />
              )}
              {meetingData.createdby &&
                `${meetingData.createdby.firstName} ${meetingData.createdby.lastName}`}
            </Text>
            <Text mb={2}>
              <b>Expert: </b>
              {meetingData.expert && (
                <Avatar
                  color="white"
                  name={
                    meetingData.expert.firstName +
                    " " +
                    meetingData.expert.lastName
                  }
                  size="xs"
                  src={
                    meetingData.expert.Image
                      ? config.apiUrl +
                        "/api/getfile/" +
                        meetingData.expert.Image
                      : ""
                  }
                  mr="1"
                />
              )}
              {meetingData.expert &&
                `${meetingData.expert.firstName} ${meetingData.expert.lastName}`}
            </Text>
            {meetingData.online && (
              <Text mb={2}>
                <b>Link of meet: </b>
                <Link
                  fontWeight="500"
                  color={brandColor}
                  href={meetingData.onlineUrl}
                  target={"_blank"}
                  fontSize="sm"
                >
                  {meetingData.onlineUrl}
                </Link>
              </Text>
            )}
            {!meetingData.online && (
              <Text mb={2}>Location: {meetingData.location}</Text>
            )}
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Projects Participating in the Meeting:
            </Text>
            {meetingData.projects &&
              meetingData.projects.map((project) => (
                <Flex
                  width="100%"
                  height="100%"
                  padding="0.4rem"
                  fontWeight="bold"
                  color={textColor}
                  fontSize="md"
                  textAlign="start"
                >
                  <Image
                    fallbackSrc={placeholder}
                    src={config.apiUrl + "/api/getfile/" + project.images}
                    alt="project"
                    w="50px"
                  />

                  <Box ml="3">
                    <Text fontWeight="normal">{project.title}</Text>
                    <Text fontSize="sm">{project.category}</Text>
                  </Box>
                </Flex>
              ))}
          </Flex>
          <Flex justify="space-between" mt="3" gap="2">
            <Button colorScheme="blue" size="md" onClick={handleAccept}>
              Accept Invitation
            </Button>
            <Button colorScheme="red" size="md" onClick={handleRefuse}>
              Refuse Invitation
            </Button>
          </Flex>
        </Box>
      )}
      {isAccepted === 1 && (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="lg"
          bg="white"
          p={4}
          mt={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text fontSize="lg" fontWeight="bold" mb={2}>
             Meet Invitation
          </Text>
          <Text mb={2}>You've successfully accepted the meet invitation.</Text>
          <Text mb={2}>Thank you</Text>
          <Flex justify="space-between" mt="3">
            <Button
              colorScheme="blue"
              size="md"
              onClick={() => history.push("/auth/sign-in")}
            >
              Go To Dashboard
            </Button>
          </Flex>
        </Box>
      )}
      {isAccepted === 2 && (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="lg"
          bg="white"
          p={4}
          mt={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text fontSize="lg" fontWeight="bold" mb={2}>
             Meet Invitation
          </Text>
          <Text mb={2}>You've successfully refused the meet invitation.</Text>
          <Text mb={2}>Thank you</Text>
          <Flex justify="space-between" mt="3">
            <Button
              colorScheme="blue"
              size="md"
              onClick={() => history.push("/auth/sign-in")}
            >
              Go To Dashboard
            </Button>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default MeetInvitationAcceptance;
