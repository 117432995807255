import React from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Divider,
  Flex,
  ButtonGroup,
  Button,
  Image,
  Box,
  Badge,
} from "@chakra-ui/react";
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";
import { useState } from "react";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { Link as Linkk } from "react-router-dom";

export default function OrganizationItem(props) {
  const { item } = props;

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [userType, setUsetType] = useState("");

  return (
    <Card boxShadow={cardShadow} key={item._id}>
      <Flex direction="column">
        <Box>
          <Image
            src={config.apiUrl + `/org/file/` + item.image}
            mb="5px"
            boxSize="150px"
            width="100%"
            objectFit="cover"
            alt=""
            borderRadius="lg"
            fallbackSrc={placeholder}
          />
        </Box>
        <Flex mt="2px" justify="space-between" alignItems={"center"}>
          <Text
            color={textColor}
            mr="4px"
            fontSize={{
              base: "xl",
              md: "lg",
              lg: "lg",
              xl: "lg",
              "2xl": "md",
              "3xl": "lg",
            }}
            mb="5px"
            fontWeight="bold"
            me="14px"
          >
            {item.name}
          </Text>
          <Badge colorScheme="purple" p="2px 0px" mr="1">
            <Text
              fontSize="10px"
              width="100%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {item.type}
            </Text>
          </Badge>
        </Flex>
        <Flex mt="5px" mb="5px">
          {item.categories.map((category) => (
            <Badge colorScheme="pink" p="2px 0px" mr="1" variant="outline">
              <Text
                fontSize="10px"
                width="100%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {category}
              </Text>
            </Badge>
          ))}
        </Flex>
        <Text className="description" color={textColor} mb="5px" me="14px">
          {item.description}{" "}
        </Text>
      </Flex>
      <Divider mt="2" mb="2" />
      <Flex color={textColor} direction="row" alignItems={"center"}>
        <Text color="gray.400" fontWeight={"700"}>
          {item.range}
        </Text>
      </Flex>
      <Divider mt="2" mb="2" />
      <Flex color={textColor} direction="row" alignItems={"center"}>
        <BiTimeFive w="20px" h="20px" color="#3b82f6" />
        <Text color="#3b82f6" fontWeight={"700"} ml="2">
          {moment(item.start).format("ll")} - {moment(item.end).format("ll")}
        </Text>
      </Flex>
      <Divider mt="2" mb="2" />

      <Flex direction="column">
        <ButtonGroup spacing="2" display={"flex"} justifyContent="flex-end">
          <Button variant="outline" colorScheme="blue" size="sm">
            <Linkk to={"/admin/organization/" + item._id}>View</Linkk>
          </Button>
        </ButtonGroup>
      </Flex>
    </Card>
  );
}
