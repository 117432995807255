import React, { createContext } from "react";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { getNotificationsSettings } from "reducers/notificationsSettings";
import { useEffect } from "react";
import { getCurrentUser } from "reducers/userInformation";
import config from "../config/axios/config";

const socket = io(config.apiUrl),
  SocketContext = createContext(socket);
const user = JSON.parse(localStorage.getItem("CurrentUserData"));

socket.on("connect", () => {
  console.log("connected to socket");
  socket.emit("newUser", user);
  if (user) {
    socket.emit("joinRoom", {
      userIds: [user._id],
    });
  }
});

const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      getNotificationsSettingsAction();
    }
    getCurrentUserAction();
  }, [user]);
  const getNotificationsSettingsAction = async () => {
    await dispatch(getNotificationsSettings()).unwrap();
  };
  const getCurrentUserAction = async () => {
    await dispatch(getCurrentUser()).unwrap();
  };

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider, socket };
