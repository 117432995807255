import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { commentNewService } from "services/newsService";
import { editNewsService } from "services/newsService";
import { deleteNewService } from "services/newsService";
import { likeNewService } from "services/newsService";
import { getDetailsNew } from "services/newsService";
import { addNewService } from "services/newsService";
import { getNewsService } from "services/newsService";

export const getAllNewsR = createAsyncThunk(
  "new/all",
  async (filterdata, thunkAPI) => {
    try {
      const { status, data } = await getNewsService(
        filterdata.limit,
        filterdata.page
      );
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getDetailsNewsR = createAsyncThunk(
  "new/details",
  async (idNew, thunkAPI) => {
    try {
      const { status, data } = await getDetailsNew(idNew);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addNew = createAsyncThunk("new/add", async (dataNews, thunkAPI) => {
  try {
    const { status, data } = await addNewService(dataNews.newData);
    if (status === 200 || status === 201) {
      if (
        data &&
        data.news &&
        dataNews &&
        dataNews.roles &&
        dataNews.currentUserId
      ) {
        dataNews.socket.emit("sendNotificationToAll", {
          type: "AddNew",
          data: data.news,
          roles: dataNews.roles,
          sender: dataNews.currentUserId,
        });
      }
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
export const commentNew = createAsyncThunk(
  "new/comment",
  async (commentData, thunkAPI) => {
    try {
      const { status, data } = await commentNewService(commentData);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const likeNew = createAsyncThunk("new/like", async (id, thunkAPI) => {
  try {
    const { status, data } = await likeNewService(id);
    if (status === 200 || status === 201) {
      console.log(data);
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const editNews = createAsyncThunk(
  "new/edit",
  async (newsData, thunkAPI) => {
    try {
      const { status, data } = await editNewsService(newsData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteNews = createAsyncThunk(
  "new/delete",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await deleteNewService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  deleteNew: {
    status: "idle",
    data: {},
    error: {},
  },
  getNews: {
    status: "idle",
    data: {},
    error: {},
  },
  addNew: {
    status: "idle",
    data: {},
    error: {},
  },
  detailsNew: {
    status: "idle",
    data: {},
    error: {},
  },
  commentNew: {
    status: "idle",
    data: {},
    error: {},
  },
  likeNew: {
    status: "idle",
    data: {},
    error: {},
  },
  editNew: {
    status: "idle",
    data: {},
    error: {},
  },
};

const EventSlice = createSlice({
  name: "news",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [deleteNews.pending.type]: (state) => {
      state.deleteNew = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deleteNews.fulfilled.type]: (state, action) => {
      state.deleteNew = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [deleteNews.rejected.type]: (state, action) => {
      state.deleteNew = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [editNews.pending.type]: (state) => {
      state.editNew = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [editNews.fulfilled.type]: (state, action) => {
      state.editNew = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [editNews.rejected.type]: (state, action) => {
      state.editNew = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [commentNew.pending.type]: (state) => {
      state.commentNew = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [commentNew.fulfilled.type]: (state, action) => {
      state.commentNew = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [commentNew.rejected.type]: (state, action) => {
      state.commentNew = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [likeNew.pending.type]: (state) => {
      state.likeNew = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [likeNew.fulfilled.type]: (state, action) => {
      state.likeNew = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [likeNew.rejected.type]: (state, action) => {
      state.likeNew = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAllNewsR.pending.type]: (state) => {
      state.getNews = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllNewsR.fulfilled.type]: (state, action) => {
      state.getNews = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllNewsR.rejected.type]: (state, action) => {
      state.getNews = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getDetailsNewsR.pending.type]: (state) => {
      state.detailsNew = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getDetailsNewsR.fulfilled.type]: (state, action) => {
      state.detailsNew = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getDetailsNewsR.rejected.type]: (state, action) => {
      state.detailsNew = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addNew.pending.type]: (state) => {
      state.addNew = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addNew.fulfilled.type]: (state, action) => {
      state.addNew = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addNew.rejected.type]: (state, action) => {
      state.addNew = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default EventSlice.reducer;
