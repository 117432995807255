import { Text, Box, Flex, Avatar, Button } from "@chakra-ui/react";
import React from "react";
import Card from "components/card/Card.js";
import { useColorModeValue } from "@chakra-ui/system";
import config from "config/axios/config";
import { obfuscateEmail } from "utils/functions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MemberItem(props) {
  const { item, currentUserType, onInvit } = props;
  const history = useHistory();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const hackathon = useSelector((state) =>
    state.Cohort.cohort.data ? state.Cohort.cohort.data.cohort : null
  );
  return (
    <Card p="1" mb="2" boxShadow={cardShadow} key={item._id}>
      <Flex>
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
        >
          <Avatar
            w="50px"
            h="50px"
            src={config.apiUrl + "/api/getfile/" + item.Image}
          />
          <Box ml="3">
            <Text
              fontWeight="normal"
              _hover={{ color: "blue.500", cursor: "pointer" }}
              onClick={() => history.push("/admin/expert/profile/" + item._id)}
            >
              {item.firstName + " " + item.lastName}
            </Text>
            <Text fontSize="sm">{obfuscateEmail(item.email)}</Text>
          </Box>
        </Flex>
        {currentUserType === "ProjectHolder" && (
          <Flex>
            <Button
              mt={"15px"}
              mr={"20px"}
              size="sm"
              variant="outline"
              colorScheme="green"
              onClick={onInvit}
              disabled={
                hackathon ? new Date(hackathon.end) < new Date() : false
              }
            >
              Invite to Team
            </Button>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
