import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  FormLabel,
  FormControl,
  Textarea,
  Flex,
  Text,
  Heading,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import { funds } from "utils/constants";
import { users } from "utils/constants";
import { team } from "utils/constants";
import UploadFile from "components/button/UploadFile";
import { acceptedMimeTypes } from "utils/constants";
import { AddgrantsApplication } from "reducers/grants";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const Step5 = (props) => {
  const { grantsdata, setgrantdata, setStep } = props;
  const dispatch = useDispatch();

  const [repository, setRepository] = useState("");
  const [hearaboutus, sethearaboutus] = useState("");
  const [demo, setDemo] = useState("");
  const [pitchDeck, setPitchDeck] = useState("");

  const [repositoryerror, setRepositoryerror] = useState("");
  const [hearaboutuserror, sethearaboutuserror] = useState("");
  const [demoerror, setDemoerror] = useState("");
  const [pitchDeckerror, setPitchDeckerror] = useState("");

  const addApplicationgrants = useSelector((state) =>
  state.Grants.addapplicationgrant
    ? state.Grants.addapplicationgrant.status
    : 0
);
  const validForm = () => {
    let valid = true;
    const date = new Date();
   
    if (!pitchDeck) {
      setPitchDeckerror("Pitch Deck is required!");
      valid = false;
    } else {
      setPitchDeckerror("");
    }
    if (!demo) {
      setDemoerror("Demo is required!");
      valid = false;
    } else {
      setDemoerror("");
    }
    if (!hearaboutus) {
      sethearaboutuserror("is required!");
      valid = false;
    } else {
      sethearaboutuserror("");
    }
    if (!repository) {
      setRepositoryerror("Code repository is required!");
      valid = false;
    } else {
      setRepositoryerror("");
    }
    return valid;
  };

  const confirm = async () => {
    if (!validForm()) {
      return;
    }
    console.log(pitchDeck[0],demo[0],'jajajajakaakakakakakakka')
    
    console.log(repository,'fofofofofofofof')
    const grantsdataForm = new FormData();
    grantsdataForm.append("id", grantsdata.id);
    grantsdataForm.append("project", grantsdata.project);
    grantsdataForm.append("fund", grantsdata.fund);
    grantsdataForm.append("proposalSentence", grantsdata.proposalSentence);
    grantsdataForm.append("proposalSummary", grantsdata.proposalSummary);
    grantsdataForm.append("choiceToBuildOnHedera", grantsdata.choiceToBuildOnHedera);
    grantsdataForm.append("customerCountQuestion", grantsdata.customerCountQuestion);
    grantsdataForm.append("competitors", grantsdata.competitors);
    grantsdataForm.append("teamSize", grantsdata.teamSize);
    grantsdataForm.append("teamExperience", grantsdata.teamExperience);
    grantsdataForm.append("startedStatus", grantsdata.startedStatus);
    grantsdataForm.append("developmentTimeline", grantsdata.developmenttimeline);
    grantsdataForm.append("estimatedLaunchDate", grantsdata.estimatedLaunchDate);
    grantsdataForm.append("fundingRequestedHBAR", grantsdata.allocatedTowards);
    grantsdataForm.append("allocatedTowards", grantsdata.allocatedTowards);
    grantsdataForm.append("codeRepository", repository);
    grantsdataForm.append("previousFunding", grantsdata.previousFunding);
    grantsdataForm.append("fromWhere", grantsdata.fromWhere);
    grantsdataForm.append("hearAboutUs", hearaboutus);
    grantsdataForm.append("video", demo[0]);
    grantsdataForm.append("pdfPresentation", pitchDeck[0]);
    await dispatch(AddgrantsApplication(grantsdataForm)).unwrap();
    
  };
  const uploadPitchDeck = (file) => {
    setPitchDeck(file);
  };
  const uploadDemo = (file) => {
    setDemo(file);
  };
  useEffect(() => {
    if(addApplicationgrants=="success"){
      window.location.href = "/#/admin/grants/";
    }if(addApplicationgrants=="failed")
    {
      toast.info("check your data!", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
    }
  }, [addApplicationgrants]);
  const back = () => {
    setStep(4);
  };
  return (
    <Card>
      <Box>
        <ToastContainer />
        <Card>
          <Heading size="sm" mb="2">
            Attachments
          </Heading>
          <Flex mt="2" flexDirection={"column"}>
            <Text>Attach your pitch deck</Text>
            <UploadFile
              setFiles={uploadPitchDeck}
              allowedTypes={acceptedMimeTypes}
              title={"Attach your pitch deck"}
            />
             {pitchDeckerror && (
              <Text color="red">{pitchDeckerror}</Text>
            )}
          </Flex>
          <Flex mt="2" flexDirection={"column"}>
            <Text>Attach your demo deck</Text>
            <UploadFile
              setFiles={uploadDemo}
              allowedTypes={acceptedMimeTypes}
              title={"Attach your demo deck"}
            />
             {demoerror && (
              <Text color="red">{demoerror}</Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Textarea
              marginTop={"5px!important"}
              w={"100%"}
              marginRight="10px"
              placeholder="Code Repository.*"
              borderRadius="5px"
              value={repository}
              onChange={(e) => setRepository(e.target.value)}
            />
             {repositoryerror && (
              <Text color="red">{repositoryerror}</Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>Where did you hear about us?*</Text>
            <Select
              mb="3"
              onChange={(ev) => {
                sethearaboutus(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              width="100%"
            >
              <option value="">-----</option>
              <option value="A Blog post">A Blog post</option>
              <option value="Discord">Discord</option>
              <option value="Facebook">Facebook</option>
              <option value="Friends">Friends</option>
              <option value="Instagram">Instagram</option>
              <option value="Investors">Investors</option>
              <option value="Podcast">Podcast</option>
              <option value="Search engine">Search engine</option>
              <option value="Telegram">Telegram</option>
              <option value="Twitter">Twitter</option>
              <option value="Youtube">Youtube</option>
            </Select>
            {hearaboutuserror && (
              <Text color="red">{hearaboutuserror}</Text>
            )}
          </Flex>
          <Flex justifyContent="end" mt="4">
            <Button
              colorScheme="blue"
              variant="outline"
              size="md"
              // onClick={back}
              mr="2"
              onClick={back}
            >
              Back
            </Button>
            <Button colorScheme="blue" size="md" onClick={confirm}>
              Confirm
            </Button>
          </Flex>
        </Card>
      </Box>
    </Card>
  );
};

export default Step5;
