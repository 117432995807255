import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import CustomSelectField from "components/fields/CustomSelectField";
import CustomNumberInput from "components/fields/CustomNumberInput";
import TextareaField from "components/fields/TextareaField";
import { sectors , projectMaturityLevel } from "utils/constants";
import BasicButton from "components/button/BasicButton";
import config from "config/axios/config";
import axios from "axios";
import { useEffect } from "react";
import { updateProject } from "reducers/project";
import { useDispatch } from "react-redux";

const EditDetailsForm = ({ isOpen, onClose, initialData, onReload }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState(initialData.title);
  const [category, setCategory] = useState(initialData.category);
  const [description, setDescription] = useState(initialData.description);
  const [projectMaturity, setProjectMaturity] = useState(
    initialData.projectMaturity
  );
  const [titleError, setTitleError] = useState("");
  const [projectMaturityError, setProjectMaturityError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const validateTitle = async (title) => {
    const res = await axios.get(
      config.apiUrl + "/project/verifyedProjectTitle/" + title
    );
    return res.data.success;
  };
  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else if (!validateTitle(title)) {
      setTitleError("This title is already in use!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!projectMaturity) {
      setProjectMaturityError("Project Maturity is required!");
      valid = false;
    } else {
      setProjectMaturityError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!category) {
      setCategoryError("Category is required!");
      valid = false;
    } else {
      setCategoryError("");
    }
    return valid;
  };
  const onEditHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const data = new FormData();
    data.append("title", title);
    data.append("category", category);
    data.append("description", description);
    data.append("projectMaturity", projectMaturity);

    const projectData = {
      data,
      id: initialData._id,
    };
    await dispatch(updateProject(projectData)).unwrap();
    onReload();
  };
  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [title, category, description, projectMaturity, submitted]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Project Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mb="2">
              <InputField
                label="Title"
                placeholder="Title"
                value={title}
                setValue={setTitle}
                error={titleError}
              />
              <CustomSelectField
                label="Sector"
                value={category}
                setValue={setCategory}
                error={categoryError}
                options={sectors}
              />
            </Flex>
            <Flex mb="2">
              <CustomSelectField
                label="Project Maturity"
                value={projectMaturity}
                setValue={setProjectMaturity}
                error={projectMaturityError}
                options={projectMaturityLevel}
              />
            </Flex>
            <TextareaField
              label="Description"
              placeholder="Description"
              value={description}
              setValue={setDescription}
              error={descriptionError}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={onEditHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditDetailsForm;
