import React from "react";
import Card from "components/card/Card.js";
import { useColorModeValue } from "@chakra-ui/react";
import { useEffect } from "react";
import { getRequestsByCOS } from "reducers/callofStartups";
import CallOfStartupsRequestItem from "./CallOfStartupsRequestItem";
import { useSelector, useDispatch } from "react-redux";
import { Text, Heading, SimpleGrid } from "@chakra-ui/react";
import jwt_decode from "jwt-decode";
import { useState } from "react";

export default function CallOfStartupsRequests(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("");

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const requests = useSelector((state) =>
    state.CallofStartups.requests.data
      ? state.CallofStartups.requests.data.result
      : []
  );
  const getCOSRequestsAction = async () => {
    await dispatch(getRequestsByCOS(id)).unwrap();
  };
  useEffect(() => {
    const USER_TYPE = jwt_decode(localStorage.getItem("token")).userType;
    setUserType(USER_TYPE);
    getCOSRequestsAction();
  }, []);

  return (
    <>
    {userType == "Investor" && requests && requests.length > 0 && 
    <Card p="20px" boxShadow={cardShadow} mt="3">
      <Heading size="sm" mb="2">
        Requests
      </Heading>
      {requests && requests.length > 0 && (
        <SimpleGrid
          columns={{ base: 1, lg: 2, xl: 3, md: 2 }}
          gap="20px"
          mb="20px"
          mt="20px"
        >
          {requests.map((request) => (
            <>{request.status !== "accepted" && request.state == "completed" &&
            <CallOfStartupsRequestItem item={request} />
          }</>
          ))}
        </SimpleGrid>
      )}

      {requests && !requests.length && <Text>There is no request</Text>}
    </Card>
    }</>
  );
}
