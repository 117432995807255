import Card from "components/card/Card.js";
import {
  Text,
  Textarea,
  Flex,
  Button,
  Heading,
  Divider,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import UploadFile from "components/button/UploadFile";
import { excelMimeTypes } from "utils/constants";
import { acceptedMimeTypes } from "utils/constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { applyOpportunity } from "reducers/callofStartups";
import { getRequestCallofstartupById } from "reducers/callofStartups";
import { useSelector } from "react-redux";

const Step9 = (props) => {
  const { applyData, setApplyData, setStep ,requestCall} = props;
  const { idrequest, id } = useParams();
  const [signature, setsignature] = useState(requestCall ? requestCall.signature :applyData.signature);

  const dispatch = useDispatch();
  const confirm = async() => {
    const Oppdata = new FormData();
    Oppdata.append("callOfStartup", id);
    Oppdata.append("project", requestCall ? requestCall.project._id : applyData.project);
    Oppdata.append("signature", signature[0]);
    Oppdata.append("step", 9);

        const dataaddOpp = {
      Oppdata,
    };
    await dispatch(applyOpportunity(dataaddOpp)).unwrap().then(()=>{window.location.href = "/#/admin/CallForStartups/" + id; });
    const data = {
      ...applyData,
      signature
    };
    setApplyData(data);
  };
  const back = () => {
    setStep(8);
    const data = {
      ...applyData,
    };
    setApplyData(data);
  };
  const uploadSignature = (file) => {
    setsignature(file);
  };
  return (
    <Card>
      <Heading size="sm" mb="2">
      Declaration      </Heading>
      <Flex mt="2" flexDirection={"column"}>
        <Text mb="5px">We hereby declare that all the information provided in this form is accurate and true to the best of my/our knowledge (on a form PDF) </Text>
        <UploadFile
          setFiles={uploadSignature}
          allowedTypes={acceptedMimeTypes}
          title={"Attach your Signature "}
        />
      </Flex>
      <Flex justifyContent="end" mt="4">
        <Button
          colorScheme="blue"
          variant="outline"
          size="md"
          onClick={back}
          mr="2"
        >
          Back
        </Button>
        <Button colorScheme="blue" size="md" onClick={confirm}>
          Confirm
        </Button>
      </Flex>
    </Card>
  );
};

export default Step9;
