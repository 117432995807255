import React, { useState } from "react";
import { Flex, Text, Textarea, FormLabel } from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import MySelect from "react-select";
import { whatToSubmit } from "utils/constants";

const AboutHackathonForm = ({
  activeStep,
  setActiveStep,
  setSelectedWhatToSubmit,
  selectedWhatToSubmit,
  whatToBuild,
  setWhatToBuild,
}) => {
  const [whatToBuildError, setWhatToBuildError] = useState("");
  const [whatToSubmitError, setWhatToSubmitError] = useState("");
  const validateForm = () => {
    let valid = true;
    if (!whatToBuild) {
      setWhatToBuildError("champ is required!");
      valid = false;
    } else {
      setWhatToBuildError("");
    }
    if (selectedWhatToSubmit.length === 0) {
      setWhatToSubmitError("champ is required!");
      valid = false;
    } else {
      setWhatToSubmitError("");
    }

    return valid;
  };

  const handleAboutHackathon = async () => {
    if (!validateForm()) {
      return;
    }

    setActiveStep(activeStep + 1);
  };

  return (
    <Flex flexDirection={"column"}>
      <FormLabel>
        What to Submit <span style={{ color: "red" }}>*</span>
      </FormLabel>{" "}
      <MySelect
        className="InputGroupy"
        placeholder="What to Submit"
        options={whatToSubmit}
        value={selectedWhatToSubmit}
        onChange={setSelectedWhatToSubmit}
        isMulti
      />
      {whatToSubmitError && (
        <Text mt="3" color="red">
          {whatToSubmitError}
        </Text>
      )}
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          What To Build <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Textarea
          isInvalid={whatToBuildError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="What to build"
          borderRadius="5px"
          value={whatToBuild}
          onChange={(e) => setWhatToBuild(e.target.value)}
        />
        {whatToBuildError && (
          <Text mt="3" color="red">
            {whatToBuildError}
          </Text>
        )}
      </Flex>
      <Flex justifyContent={"space-between"} mt="3">
        <BasicButton
          onClick={() => setActiveStep(activeStep - 1)}
          title={"Previous"}
          variant="solid"
        />

        <BasicButton
          onClick={handleAboutHackathon}
          title={"Next"}
          variant="solid"
        />
      </Flex>
    </Flex>
  );
};

export default AboutHackathonForm;
