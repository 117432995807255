import React, { useState } from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import CustomNumberInput from "components/fields/CustomNumberInput";
import InputField from "components/fields/InputField";
import TextareaField from "components/fields/TextareaField";
import BasicButton from "components/button/BasicButton";

const GeneralInformationsForm = (props) => {
  const { programPhaseData, setProgramPhaseData, activeStep, setActiveStep } =
    props;
  const [start, setStart] = useState(programPhaseData.start);
  const [end, setEnd] = useState(programPhaseData.end);
  const [title, setTitle] = useState(programPhaseData.title);
  const [description, setDescription] = useState(programPhaseData.description);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [startError, setStartError] = useState("");
  const [maxSelectedProjectsError, setMaxSelectedProjectsError] = useState("");
  const [maxSelectedProjects, setMaxSelectedProjects] = useState(
    programPhaseData.maxSelectedProjects
  );
  const [votingStartDateError, setVotingStartDateError] = useState("");
  const [votingStartDate, setVotingStartDate] = useState(
    programPhaseData.votingStartDate
  );
  const [endError, setEndError] = useState("");

  const validForm = () => {
    let valid = true;
    const date = new Date();
    if (!start) {
      setStartError("You should enter a start date");
      valid = false;
    } else if (new Date(start) < date) {
      setStartError("Start date must be greater than current date");
    } else {
      setStartError("");
    }
    if (!end) {
      setEndError("You should enter an end date");
      valid = false;
    } else if (end < start) {
      setEndError("End date should be greater than start date");
    } else {
      setEndError("");
    }
    if (!votingStartDate) {
      setVotingStartDateError("You should enter a voting start date");
      valid = false;
    } else if (
      new Date(votingStartDate) > new Date(end) ||
      new Date(votingStartDate) < new Date(start)
    ) {
      setVotingStartDateError(
        "Voting Start Date must be between start and end date"
      );
    } else {
      setVotingStartDateError("");
    }
    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!maxSelectedProjects) {
      setMaxSelectedProjectsError("Max Selected Projects is required!");
      valid = false;
    } else {
      setMaxSelectedProjectsError("");
    }
    return valid;
  };

  const confirm = async () => {
    if (!validForm()) {
      return;
    }

    const data = {
      ...programPhaseData,
      start,
      end,
      title,
      description,
      votingStartDate,
      maxSelectedProjects,
    };
    setProgramPhaseData(data);
    setActiveStep(activeStep + 1);
  };

  return (
    <Box p={6} borderWidth="1px" borderRadius="lg" width="100%" boxShadow="md">
      <Heading as="h2" size="lg" mb={4} textAlign="center">
        General Informations
      </Heading>{" "}
      <Flex mb="3">
        <InputField
          label="Title"
          placeholder="Title"
          value={title}
          setValue={setTitle}
          error={titleError}
          type="text"
        />
      </Flex>
      <Flex mb="3">
        <TextareaField
          label="Description"
          placeholder="Description"
          value={description}
          setValue={setDescription}
          error={descriptionError}
        />
      </Flex>
      <Flex mb="3">
        <InputField
          label="Start Date"
          placeholder="Start Date"
          value={start}
          setValue={setStart}
          error={startError}
          type="date"
        />
        <InputField
          label="End Date"
          placeholder="End Date"
          value={end}
          setValue={setEnd}
          error={endError}
          type="date"
        />
      </Flex>
      <Flex mb="3">
        <InputField
          label="Voting Start Date"
          placeholder="Voting Start Date"
          value={votingStartDate}
          setValue={setVotingStartDate}
          error={votingStartDateError}
          type="date"
        />
      </Flex>
      <Flex mb="3">
        <CustomNumberInput
          label={"Max Selected Projects"}
          min={1}
          step={1}
          precision={0}
          required={true}
          error={maxSelectedProjectsError}
          initialValue={1}
          value={maxSelectedProjects}
          setValue={setMaxSelectedProjects}
        />
      </Flex>
      <Flex mt="6" justifyContent={"flex-end"}>
        <BasicButton
          onClick={confirm}
          title={"Next"}
          variant="solid"
          size="md"
        />
      </Flex>
    </Box>
  );
};

export default GeneralInformationsForm;
