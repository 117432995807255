import React, { useState, useEffect } from "react";
import { Box, SimpleGrid, Center, Flex, useDisclosure } from "@chakra-ui/react";
import ExpertListItem from "./components/ExpertListItem";
import Pagination from "components/pagination/Pagination";
import { getAllExperts } from "reducers/expert";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import TextCard from "components/card/TextCard";
import ExpertFilters from "./components/ExpertFilters";
import BasicButton from "components/button/BasicButton";
import AddExpertModal from "./AddExpertModal";

export default function Experts() {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userType, setUserType] = useState("");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [reload, setReload] = useState(false);

  const experts = useSelector((state) => state.Expert.allExperts.data.Experts);
  const status = useSelector((state) => state.Expert.allExperts.status);
  const haveMeet = useSelector(
    (state) => state.Expert.allExperts.data.haveMeet
  );
  const totalPages = useSelector(
    (state) => state.Expert.allExperts.data.totalPages
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );

  const getAllExpertsAction = async () => {
    const selectedSectors = selectedCategories.map((i) => i.value);
    const filterdata = {
      page: page,
      sector: selectedSectors.length > 0 ? selectedSectors : [],
      priceOrder: filter == "descH" ? "desc" : filter == "ascH" ? "asc" : "",
      ratingOrder: filter == "descR" ? "desc" : filter == "ascR" ? "asc" : "",
      name: searchText,
      limit: 6,
    };
    await dispatch(getAllExperts(filterdata)).unwrap();
    setReload(false);
  };

  useEffect(() => {
    getAllExpertsAction();
  }, [selectedCategories, filter, page, searchText, reload]);

  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
  }, [currentUser]);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {(userType === "Admin" || userType === "SuperAdmin") && (
        <Flex w="100%" justifyContent="flex-end" mb="4">
          <BasicButton
            title={"Add Expert"}
            onClick={onOpen}
            variant={"solid"}
          />
        </Flex>
      )}
      <ExpertFilters
        searchText={searchText}
        setSearchText={setSearchText}
        setFilter={setFilter}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      {!experts && status === "loading" && <Loader />}
      {experts && experts.length === 0 && (
        <TextCard text={"There is no expert"} />
      )}
      {experts && experts.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
          {experts.map((expert) => (
            <ExpertListItem
              haveMeet={haveMeet}
              key={expert._id}
              expert={expert}
            />
          ))}
        </SimpleGrid>
      )}
      {totalPages > 1 && (
        <Center pt="5">
          <Pagination
            page={page}
            totalPages={totalPages}
            setPage={setPage}
            setLoader={() => {}}
          />
        </Center>
      )}
      <AddExpertModal
        isOpen={isOpen}
        onClose={onClose}
        onReload={() => setReload(true)}
      />
    </Box>
  );
}
