import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTrackService,
  deleteTrackService,
  getHackathonTracksTitlesService,
  getProjectsByTrackService,
  getTrackByIdService,
  getTracksByHackathonService,
  getVotersByTrackService,
  updateTrackService,
} from "../services/trackService";
export const createTrack = createAsyncThunk(
  "track/create",
  async (dataTrack, thunkAPI) => {
    try {
      const { status, data } = await createTrackService(dataTrack);
      if (status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteTrack = createAsyncThunk(
  "track/delete",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await deleteTrackService(id);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getHackathonTracksTitles = createAsyncThunk(
  "tracks/by-hackathon",
  async (dataTracks, thunkAPI) => {
    try {
      const { status, data } = await getHackathonTracksTitlesService(dataTracks);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateTrack = createAsyncThunk(
  "track/update",
  async (updatedata, thunkAPI) => {
    try {
      const { status, data } = await updateTrackService(updatedata);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getVotersByTrack = createAsyncThunk(
  "track/voters",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getVotersByTrackService(filterData);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getTrackById = createAsyncThunk(
  "track/track-By-Id",
  async (idTrack, thunkAPI) => {
    try {
      const { status, data } = await getTrackByIdService(idTrack);
      if (status === 200 || status === 201) {
        console.log(data,"data track")
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getProjectsByTrack = createAsyncThunk(
  "track/getProjectsByTrack",
  async (dataTrack, thunkAPI) => {
    try {
      const { status, data } = await getProjectsByTrackService(dataTrack);
      if (status === 200 || status === 201) {
        console.log(data,"data track")
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  projectsByTrack: {
    status: "idle",
    data: {},
    error: {},
  },
  trackById: {
    status: "idle",
    data: {},
    error: {},
  },
  create: {
    status: "idle",
    data: {},
    error: {},
  },
  delete: {
    status: "idle",
    data: {},
    error: {},
  },
  tracksByHackathon: {
    status: "idle",
    data: {},
    error: {},
  },
  updateTrack: {
    status: "idle",
    data: {},
    error: {},
  },
  voters: {
    status: "idle",
    data: {},
    error: {},
  },
};
const TrackSlice = createSlice({
  name: "track",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getProjectsByTrack.pending.type]: (state) => {
      state.projectsByTrack = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getProjectsByTrack.fulfilled.type]: (state, action) => {
      state.projectsByTrack = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getProjectsByTrack.rejected.type]: (state, action) => {
      state.projectsByTrack = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getTrackById.pending.type]: (state) => {
      state.trackById = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getTrackById.fulfilled.type]: (state, action) => {
      state.trackById = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getTrackById.rejected.type]: (state, action) => {
      state.trackById = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [createTrack.pending.type]: (state) => {
      state.create = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [createTrack.fulfilled.type]: (state, action) => {
      state.create = {
        status: "success",
        data: {},
        error: {},
      };
    },
    [createTrack.rejected.type]: (state, action) => {
      state.create = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [deleteTrack.pending.type]: (state) => {
      state.delete = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deleteTrack.fulfilled.type]: (state, action) => {
      state.delete = {
        status: "success",
        data: {},
        error: {},
      };
    },
    [deleteTrack.rejected.type]: (state, action) => {
      state.delete = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getHackathonTracksTitles.pending.type]: (state) => {
      state.tracksByHackathon = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getHackathonTracksTitles.fulfilled.type]: (state, action) => {
      state.tracksByHackathon = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getHackathonTracksTitles.rejected.type]: (state, action) => {
      state.tracksByHackathon = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateTrack.pending.type]: (state) => {
      state.updateTrack = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateTrack.fulfilled.type]: (state, action) => {
      state.updateTrack = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateTrack.rejected.type]: (state, action) => {
      state.updateTrack = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getVotersByTrack.pending.type]: (state) => {
      state.voters = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getVotersByTrack.fulfilled.type]: (state, action) => {
      state.voters = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getVotersByTrack.rejected.type]: (state, action) => {
      state.voters = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default TrackSlice.reducer;
