import Card from "components/card/Card.js";
import {
  Text,
  Textarea,
  Flex,
  Button,
  Heading,
  Divider,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { applyOpportunity } from "reducers/callofStartups";
import { getRequestCallofstartupById } from "reducers/callofStartups";
import { useSelector } from "react-redux";

const Step2 = (props) => {
  const { applyData, setApplyData, setStep ,requestCall } = props;
  const { idrequest, id } = useParams();
  const [businessDescription, setBusinessDescription] = useState(
    requestCall
      ? requestCall.businessDescription
      : applyData.businessDescription
  );
  const [targetMarket, setTargetMarket] = useState(
    requestCall ? requestCall.targetMarket : applyData.targetMarket
  );
  const [uniqueSellingProposition, setUniqueSellingProposition] = useState(
    requestCall ? requestCall.targetMarket : applyData.uniqueSellingProposition
  );
  const [errorbusinessDescription, setErrorbusinessDescription] = useState("");
  const [errortargetMarket, setErrorTargetMarket] = useState("");
  const [erroruniqueSellingProposition, setErrorUniqueSellingProposition] =
    useState("");
  const dispatch = useDispatch();

  const validateForm = () => {
    let valid = true;
    if (!businessDescription) {
      setErrorbusinessDescription("This filed are required");
      valid = false;
    } else {
      setErrorbusinessDescription("");
    }
    if (!targetMarket) {
      setErrorTargetMarket("This filed are required");
      valid = false;
    } else {
      setErrorTargetMarket("");
    }
    if (!uniqueSellingProposition) {
      setErrorUniqueSellingProposition("This filed are required");
      valid = false;
    } else {
      setErrorUniqueSellingProposition("");
    }
    return valid;
  };
  const confirm = async () => {
    if (!validateForm()) {
      return;
    }
    const Oppdata = new FormData();
    Oppdata.append("callOfStartup", id);
    Oppdata.append("project", requestCall ? requestCall.project._id : applyData.project);
    Oppdata.append("businessDescription", businessDescription);
    Oppdata.append("targetMarket", targetMarket);
    Oppdata.append("UniqueSellingProposition", uniqueSellingProposition);
    Oppdata.append("step", 3);

    const dataaddOpp = {
      Oppdata,
      
    };
    await dispatch(applyOpportunity(dataaddOpp)).unwrap();
    const data = {
      ...applyData,
      businessDescription,
      targetMarket,
      uniqueSellingProposition,
    };
    setApplyData(data);
    setStep(3);
  };
  const back = () => {
    setStep(1);
    setBusinessDescription("");
    setTargetMarket("");
    setUniqueSellingProposition("");
    const data = {
      ...applyData,
      businessDescription: "",
      targetMarket: "",
      uniqueSellingProposition: "",
    };
    setApplyData(data);
  };
  return (
    <Card>
      <Heading size="sm" mb="2">
        Business Overview
      </Heading>
      <Divider mb="2" />
      <Flex mt="2" flexDirection={"column"}>
        <Text>
          Business Description (Briefly describe the nature of your business and
          the problem it aims to solve.)
        </Text>
        <Textarea
          mb="2"
          onChange={(e) => {
            setBusinessDescription(e.target.value);
          }}
          value={businessDescription}
          placeholder="Business Description"
        />
        {errorbusinessDescription && (
          <Text color="red">{errorbusinessDescription}</Text>
        )}

        <Text>Target Market: (Who are your primary customers?)</Text>
        <Textarea
          mb="2"
          onChange={(e) => {
            setTargetMarket(e.target.value);
          }}
          value={targetMarket}
          placeholder="Target Market"
        />
        {errortargetMarket && <Text color="red">{errortargetMarket}</Text>}
        <Text>
          Unique Selling Proposition (USP):** (What makes your startup unique or
          different from competitors?)
        </Text>
        <Textarea
          mb="2"
          onChange={(e) => {
            setUniqueSellingProposition(e.target.value);
          }}
          value={uniqueSellingProposition}
          placeholder="Unique Selling Proposition"
        />
        {erroruniqueSellingProposition && (
          <Text color="red">{erroruniqueSellingProposition}</Text>
        )}
      </Flex>

      <Flex justifyContent="end" mt="4">
        <Button
          colorScheme="blue"
          variant="outline"
          size="md"
          onClick={back}
          mr="2"
        >
          Back
        </Button>
        <Button colorScheme="blue" size="md" onClick={confirm}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};

export default Step2;
