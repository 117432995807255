import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  HStack,
  Input,
  Center,
  Button,
  Icon,
  Text,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Card from "components/card/Card";
import Pagination from "components/pagination/Pagination";
import { SearchIcon } from "@chakra-ui/icons";
import { getAllMeetings } from "reducers/meeting";
import WorkshopItem from "./components/WorkshopItem";
import AddWorkshop from "./AddWorkshop";
import { ToastContainer } from "react-toastify";

const Workshops = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projectName, setProjectName] = useState("");
  const [expertName, setExpertName] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [page, setPage] = useState(1);
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const totalPages = useSelector((state) =>
    state.Meeting.getAll.data ? state.Meeting.getAll.data.totalPages : 0
  );
  const workshops = useSelector((state) =>
    state.Meeting.getAll.data ? state.Meeting.getAll.data.meetings : []
  );
  const getWorkshopsAction = async (filterData) => {
    await dispatch(getAllMeetings(filterData)).unwrap();
  };
  useEffect(() => {
    const filterData = {
      page,
      limit: 12,
    };
    getWorkshopsAction(filterData);
  }, [page]);

  const filterHandler = () => {
    setPage(1);
    const filterData = {
      from,
      to,
      projectName,
      expertName,
      page,
      limit: 4,
    };
    getWorkshopsAction(filterData);
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer />
      {currentUser &&
        ["Admin", "SuperAdmin"].includes(currentUser.userType) && (
          <Flex mb="4" justifyContent={"flex-end"}>
            <Button colorScheme={"blue"} onClick={onOpen}>
              Add Workshop
            </Button>
          </Flex>
        )}
      <Card mb="20px">
        <HStack spacing={2}>
          <Input
            placeholder="From"
            size="md"
            type="date"
            onChange={(e) => {
              setTo(e.target.value);
              setFrom(e.target.value);
            }}
            value={from}
          />
          <Input
            placeholder="To"
            size="md"
            type="date"
            onChange={(e) => setTo(e.target.value)}
            value={to}
          />
          <Input
            variant="outline"
            placeholder="Project Name"
            onChange={(e) => setProjectName(e.target.value)}
            value={projectName}
          />
          <Input
            variant="outline"
            placeholder="Expert Name"
            onChange={(e) => setExpertName(e.target.value)}
            value={expertName}
          />
          <Button colorScheme="blue" onClick={filterHandler}>
            <Icon as={SearchIcon} />
          </Button>
        </HStack>
      </Card>
      {workshops && workshops.length > 0 && (
        <>
          <SimpleGrid
            columns={{ base: 1, lg: 3, xl: 3, md: 2 }}
            gap="20px"
            mb="20px"
            mt="20px"
          >
            {workshops.map((item, index) => (
              <WorkshopItem item={item} />
            ))}
          </SimpleGrid>
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
        </>
      )}
      {workshops && workshops.length === 0 && (
        <Card mb="20px">
          <Text textAlign={"center"} fontSize="large">
            No Workshop
          </Text>
        </Card>
      )}
      <AddWorkshop isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default Workshops;
