import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Box,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import IndividualTrainingForm from "./addMeetingForm/IndividualTrainingForm";
import ChooseExpert from "./addMeetingForm/ChooseExpert";
import ChooseProject from "./addMeetingForm/ChooseProject";

const InterestedToHackathonModal = (props) => {
  const { isOpen, onClose, onConfirm, isInterested } = props;
  const history = useHistory();
 
  const [meetingData, setMeetingData] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
    expertId: "",
    projectId: [],
    hackathonId:"",
    online: true,
    onlineUrl: "",
    location: ""
  });
  const [step, setStep] = useState(1);
  const [allProjects, setAllProjects] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader fontSize="md">
          Dive In: Join a Team or Submit Your Own Project!
        </ModalHeader> */}
        <ModalCloseButton />
        <ModalBody>
          <Box mt="35px">
            {step === 1 && (
              <IndividualTrainingForm
                meetingData={meetingData}
                setMeetingData={setMeetingData}
                setStep={setStep}
                allProjects={allProjects}
                setAllProjects={setAllProjects}
              />
            )}
            {step === 2 && (
              <ChooseExpert
                meetingData={meetingData}
                setMeetingData={setMeetingData}
                setStep={setStep}
                allProjects={allProjects}
                setAllProjects={setAllProjects}
              />
            )}
            {step === 3 && (
              <ChooseProject
                meetingData={meetingData}
                setMeetingData={setMeetingData}
                setStep={setStep}
                onClose={onClose}
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InterestedToHackathonModal;
