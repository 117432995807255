import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Button,
  Text,
  useColorModeValue,
  Spinner,
  SimpleGrid,
  Center,
  HStack,
  Input,
  Select,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { getAllOrganizations } from "reducers/organization";
import Pagination from "components/pagination/Pagination";
import Card from "components/card/Card";
import OrganizationItem from "./components/OrganizationItem";
import { Link as Linkk } from "react-router-dom";
import { sectors } from "../../../utils/constants";
import MySelect from "react-select";

export default function Organizations() {
  const dispatch = useDispatch();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [userType, setUserType] = useState("");
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [investmentRange, setInvestmentRange] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [name, setName] = useState("");

  const status = useSelector(
    (state) => state.Organization.allOrganizations.status
  );
  const organizations = useSelector(
    (state) => state.Organization.allOrganizations.data?.result?.organizations
  );
  const totalPages = useSelector(
    (state) => state.Organization.allOrganizations.data?.result?.totalPages
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const getOrganizations = async () => {
    const categories = selectedOptions.map((item) => item.value);
    const filterdata = {
      page: page,
      limit: 6,
      name: name,
      investmentRange: investmentRange,
      categories: categories,
    };
    await dispatch(getAllOrganizations(filterdata)).unwrap();
  };

  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
    getOrganizations();
  }, [currentUser, page, name, selectedOptions, investmentRange]);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Flex
        w="100%"
        mb="20px"
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }}
      >
        {userType === "Investor" && (
          <Button variant={"brand"}>
            <Linkk to={"/admin/Add-Organization"}>Add Organization</Linkk>
          </Button>
        )}
      </Flex>
      <Card mb="20px">
        <HStack spacing={2} mb="3">
          <Input
            variant="outline"
            onBlur={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <Select
            justifyContent={"end"}
            onChange={(ev) => {
              setInvestmentRange(ev.target.value);
            }}
            borderColor={"#E0E5F2"}
            backgroundColor="white"
            placeholder="Investment Range"
          >
            <option value="Less than $50,000">Less than $50,000</option>
            <option value="$50,000 - $100,000">$50,000 - $100,000</option>
            <option value="$100,000 - $500,000">$100,000 - $500,000</option>
            <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
            <option value="More than $1,000,000">More than $1,000,000</option>
          </Select>
        </HStack>
        <Flex mt="2" direction={"column"}>
          <MySelect
            className="InputGroupy"
            placeholder="Select Categories"
            options={sectors}
            value={selectedOptions}
            onChange={setSelectedOptions}
            isMulti
          />
        </Flex>
      </Card>
      {!organizations && status === "loading" && (
        <Center pt="5">
          <Spinner my="auto!important" mx="auto" mt="4" mb="4" />
        </Center>
      )}
      {organizations && organizations.length > 0 && (
        <>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
            {organizations.map((item, index) => (
              <OrganizationItem item={item} />
            ))}
          </SimpleGrid>
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={setLoader}
              />
            </Center>
          )}
        </>
      )}
      {organizations && organizations.length === 0 && (
        <Card mb="20px">
          <Text textAlign={"center"} fontSize="large">
            There is no organization to show
          </Text>
        </Card>
      )}
    </Box>
  );
}
