import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  useNumberInput,
} from "@chakra-ui/react";
import React from "react";

const CustomNumberInput = ({
  label,
  min,
  max,
  step,
  precision,
  required,
  setValue,
  error,
  initialValue,
  value,
}) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: step,
      defaultValue: initialValue,
      min: min,
      max: max,
      precision: precision,
      value: value,
      onChange: (value) => setValue(value),
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <FormControl mr="2">
      <FormLabel>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </FormLabel>
      <HStack>
        <Button {...dec}>-</Button>
        <Input {...input} />
        <Button {...inc}>+</Button>
      </HStack>
      {error && <Text color="red">{error}</Text>}
    </FormControl>
  );
};
export default CustomNumberInput;
