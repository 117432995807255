import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  Flex,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import config from "config/axios/config";
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";
import placeholder from "assets/img/placeholder.jpg";
import CountdownTimer from "components/countdown/CountdownTimer";

const HackathonCard = (props) => {
  const { item, onClick } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      bg="white"
      transition="transform 0.2s"
      _hover={{ transform: "scale(1.05)" }}
      display="flex"
      flexDirection="column"
    >
      <Image
        src={config.apiUrl + `/hackathons/file/` + item.image}
        alt="Hackathon Image"
        borderTopRadius="lg"
        fallbackSrc={placeholder}
      />
      <CountdownTimer targetDate={new Date(item.end)} />
      <Box p="4" pb="1" flexGrow="1">
        <Heading color="gray.600" fontSize="xl" mb="2">
          {item.title}
        </Heading>
        <Text color="gray.600" className="description" mb="auto">
          {item.description}
        </Text>
      </Box>
      <Flex p="4" direction="column">
        <Flex direction="column" mb="2">
          <Divider mt="2" mb="2" />
          <Flex color={textColor} direction="row" alignItems={"center"}>
            <BiTimeFive w="20px" h="20px" color="#3b82f6" />
            <Text color="#3b82f6" fontWeight={"700"} ml="2">
              {moment(item.start).format("ll")} -{" "}
              {moment(item.end).format("ll")}
            </Text>
          </Flex>
        </Flex>
        <Flex justify="flex-end">
          <Button
            variant="solid"
            colorScheme="blue"
            size="sm"
            onClick={onClick}
          >
            Show more
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HackathonCard;
