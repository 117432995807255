import React from "react";
import {
  Text,
  Flex,
  Heading,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { FaEdit, FaStar } from "react-icons/fa";
import EditBuildGuide from "../editHackathon/EditBuildGuide";

export default function BuildGuide(props) {
  const { whatToBuild, hackathon, onReload, canEdit } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex direction={"column"} color={textColor}>
      <Flex align="center" mb="4" justify={'space-between'}>
        <Flex>
          <Text fontSize="2xl" mr={2}>
            <FaStar color="blue" />
          </Text>
          <Heading fontSize="2xl" color={textColor}>
            What to Build{" "}
          </Heading>
        </Flex>
        {canEdit && (
          <IconButton
            icon={<FaEdit />}
            size="sm"
            mr="4px"
            colorScheme="blue"
            onClick={onOpen}
          />
        )}
      </Flex>
      <Text>{whatToBuild}</Text>
      <EditBuildGuide
        isOpen={isOpen}
        onClose={onClose}
        initialData={hackathon}
        onReload={onReload}
      />
    </Flex>
  );
}
