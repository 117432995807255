import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AcceptedRequestTeamService } from "services/grantsService";
import { AddgrantsApplicationService } from "services/grantsService";
import { ratingGrantsApplicationService } from "services/grantsService";
import { updateGrantsService } from "services/grantsService";
import { deleteGrantService } from "services/grantsService";
import { getGrantsApplicationService } from "services/grantsService";
import { getProjectInGrantsService } from "services/grantsService";
import { getperIdGrantsService } from "services/grantsService";
import { getAllGrantsService } from "services/grantsService";
import { addGrantsService } from "services/grantsService";

export const addgrants = createAsyncThunk(
  "grants/add",
  async (grantsData, thunkAPI) => {
    try {
      const { status, data } = await addGrantsService(grantsData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllGrants = createAsyncThunk(
  "grants/getAll",
  async (grantsData, thunkAPI) => {
    try {
      const { status, data } = await getAllGrantsService(grantsData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getbyidGrants = createAsyncThunk(
  "grants/getbyid",
  async (grantsData, thunkAPI) => {
    try {
      const { status, data } = await getperIdGrantsService(grantsData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const AcceptedRequestTeam = createAsyncThunk(
  "grants/AcceptedRequestTeam",
  async (grantsData, thunkAPI) => {
    try {
      const { status, data } = await AcceptedRequestTeamService(grantsData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getProjectsInGrants = createAsyncThunk(
  "grants/ProjectsInGrants",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getProjectInGrantsService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const AddgrantsApplication = createAsyncThunk(
  "grants/AddgrantsApplication",
  async (dataGrants, thunkAPI) => {
    try {
      const { status, data } = await AddgrantsApplicationService(dataGrants);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getGrantsApplication = createAsyncThunk(
  "grants/getGrantsApplication",
  async (dataGrants, thunkAPI) => {
    try {
      const { status, data } = await getGrantsApplicationService(dataGrants);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const ratingGrantsApplication = createAsyncThunk(
  "grants/ratingGrantsApplication",
  async (dataGrants, thunkAPI) => {
    try {
      const { status, data } = await ratingGrantsApplicationService(dataGrants);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteGrant = createAsyncThunk(
  "grant/delete",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await deleteGrantService(id);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updategrants = createAsyncThunk(
  "grants/update",
  async (grantsData, thunkAPI) => {
    try {
      const { status, data } = await updateGrantsService(grantsData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  editgrantsState: {
    status: "idle",
    data: {},
    error: {},
  },
  addgrantsState: {
    status: "idle",
    data: {},
    error: {},
  },
  getgrantsState: {
    status: "idle",
    data: {},
    error: {},
  },
  getbyidgrantsState: {
    status: "idle",
    data: {},
    error: {},
  },
  acceptedRequest: {
    status: "idle",
    data: {},
    error: {},
  },
  projectsgrants: {
    status: "idle",
    data: {},
    error: {},
  },
  addapplicationgrant: {
    status: "idle",
    data: {},
    error: {},
  },
  applicationgrant: {
    status: "idle",
    data: {},
    error: {},
  },
  applicationrating: {
    status: "idle",
    data: {},
    error: {},
  },
  deleteGrant: {
    status: "idle",
    data: {},
    error: {},
  },
};

const GrantsSlice = createSlice({
  name: "grants",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [updategrants.pending.type]: (state) => {
      state.editgrantsState = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updategrants.fulfilled.type]: (state, action) => {
      state.editgrantsState = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updategrants.rejected.type]: (state, action) => {
      state.editgrantsState = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addgrants.pending.type]: (state) => {
      state.addgrantsState = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addgrants.fulfilled.type]: (state, action) => {
      state.addgrantsState = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addgrants.rejected.type]: (state, action) => {
      state.addgrantsState = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAllGrants.pending.type]: (state) => {
      state.getgrantsState = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllGrants.fulfilled.type]: (state, action) => {
      state.getgrantsState = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllGrants.rejected.type]: (state, action) => {
      state.getgrantsState = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getbyidGrants.pending.type]: (state) => {
      state.getbyidgrantsState = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getbyidGrants.fulfilled.type]: (state, action) => {
      state.getbyidgrantsState = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getbyidGrants.rejected.type]: (state, action) => {
      state.getbyidgrantsState = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [AcceptedRequestTeam.pending.type]: (state) => {
      state.acceptedRequest = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [AcceptedRequestTeam.fulfilled.type]: (state, action) => {
      state.acceptedRequest = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [AcceptedRequestTeam.rejected.type]: (state, action) => {
      state.acceptedRequest = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getProjectsInGrants.pending.type]: (state) => {
      state.projectsgrants = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getProjectsInGrants.fulfilled.type]: (state, action) => {
      state.projectsgrants = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getProjectsInGrants.rejected.type]: (state, action) => {
      state.projectsgrants = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [AddgrantsApplication.pending.type]: (state) => {
      state.addapplicationgrant = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [AddgrantsApplication.fulfilled.type]: (state, action) => {
      state.addapplicationgrant = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [AddgrantsApplication.rejected.type]: (state, action) => {
      state.addapplicationgrant = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getGrantsApplication.pending.type]: (state) => {
      state.applicationgrant = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getGrantsApplication.fulfilled.type]: (state, action) => {
      state.applicationgrant = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getGrantsApplication.rejected.type]: (state, action) => {
      state.applicationgrant = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [ratingGrantsApplication.pending.type]: (state) => {
      state.applicationrating = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [ratingGrantsApplication.fulfilled.type]: (state, action) => {
      state.applicationrating = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [ratingGrantsApplication.rejected.type]: (state, action) => {
      state.applicationrating = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [deleteGrant.pending.type]: (state) => {
      state.deleteGrant = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deleteGrant.fulfilled.type]: (state, action) => {
      state.deleteGrant = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [deleteGrant.rejected.type]: (state, action) => {
      state.deleteGrant = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default GrantsSlice.reducer;
