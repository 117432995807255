import Card from "components/card/Card.js";
import Pagination from "components/pagination/Pagination";
import { useColorModeValue } from "@chakra-ui/system";
import {
  Center,
  Select,
  Text,
  Box,
  Flex,
  Button,
  Input,
  Textarea,
  Heading,
  Divider,
  Checkbox,
  Image,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getallProjects } from "reducers/project";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { sectors } from "utils/constants";
import BasicButton from "components/button/BasicButton";

const SelectProjects = ({
  activeStep,
  setActiveStep,
  addAssistingProgramsAction,
  setSelectedProjects,
  selectedProjects,
}) => {
  const [categories, setCategories] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [projectsError, setProjectsError] = useState("");

  const projects = useSelector((state) =>
    state.Project.projects.data ? state.Project.projects.data.result : null
  );
  const totalPages = useSelector(
    (state) => state.Project.projects.data.totalPages
  );
  const [loader, setLoader] = useState(true);

  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const toggleProject = (project) => {
    const x = [...selectedProjects];
    let exist = false;
    x.map((item) => {
      if (item._id === project._id) {
        exist = true;
      }
    });
    if (exist) {
      const res = x.filter((item) => item._id !== project._id);
      setSelectedProjects(res);
    } else {
      x.push(project);
      setSelectedProjects(x);
    }
  };
  useEffect(() => {
    const filterData = {
      categories: categories == "Categories" ? "" : categories,
      isConfirmed: true,
      page: page,
    };
    dispatch(getallProjects(filterData));
  }, [categories, page]);
  return (
    <Flex flexDirection={"column"}>
      <Card p="2" direction="column" w="100%">
        <Flex justifyContent={"flex-end"}>
          <Select
            mb="3"
            onChange={(ev) => {
              setCategories(ev.target.value);
            }}
            name="category"
            id="category"
            borderColor={"#E0E5F2"}
            width="100%"
          >
            {sectors.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </Select>
        </Flex>
        {projects && projects.length > 0 && (
          <Box>
            {projects.map((item) => (
              <Card p="1" mb="2" boxShadow={cardShadow}>
                <Flex>
                  <Flex
                    width="100%"
                    height="100%"
                    padding="0.6rem"
                    fontWeight="bold"
                    color={textColor}
                    fontSize="md"
                    textAlign="start"
                  >
                    <Image
                      h="50px"
                      w="50px"
                      src={config.apiUrl + `/project/file/` + item.images}
                      borderRadius="8px"
                      fallbackSrc={placeholder}
                      objectFit="cover"
                    />
                    <Box ml="3">
                      <Text fontWeight="normal">{item.title}</Text>
                      <Text fontSize="sm">{item.category}</Text>
                    </Box>
                  </Flex>
                  <Checkbox
                    me="16px"
                    colorScheme="brandScheme"
                    borderColor={"#3965ff"}
                    onChange={() => toggleProject(item)}
                  />
                </Flex>
              </Card>
            ))}
            {totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={setLoader}
                />
              </Center>
            )}
          </Box>
        )}
        {projectsError && (
          <Text mt="3" color="red">
            {projectsError}
          </Text>
        )}
      </Card>
      <Flex justifyContent={"space-between"} mt="3">
        <BasicButton
          onClick={() => setActiveStep(activeStep - 1)}
          title={"Previous"}
          variant="solid"
        />

        <BasicButton
          onClick={addAssistingProgramsAction}
          title={"Confirm"}
          variant="solid"
        />
      </Flex>
    </Flex>
  );
};

export default SelectProjects;
