// Chakra imports
import { Text, useColorModeValue, Tooltip, Flex } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect } from "react";
import Project from "views/admin/profile/components/Project";
import { getProjectsbyUser } from "reducers/project";
import { useDispatch, useSelector } from "react-redux";
import config from "config/axios/config";
import { CheckCircleIcon } from "@chakra-ui/icons";

export default function MyProjects(props) {
  const { userId } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const dispatch = useDispatch();

  const projects = useSelector((state) =>
    state.Project.projectsbyUser.data
      ? state.Project.projectsbyUser.data.message
      : []
  );

  const getProjectsByUserAction = async () => {
    if (userId) {
      const getconfirmedProjects = {
        id: userId,
        isConfirmed: "",

      };
      await dispatch(getProjectsbyUser(getconfirmedProjects));
    }
  };

  useEffect(() => {
    if (userId) {
      getProjectsByUserAction();
    }
  }, [userId]);
  return (
    <>
      {projects && projects.length > 0 && (
        <Card mb={{ base: "0px", "2xl": "20px" }} mt="3">
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Projects
          </Text>
          <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
            Here you can find more details about projects.
          </Text>
          {projects.map((project, index) => {
            const title =
              project.isConfirmed === true ? (
                <Flex direction="row" alignItems={"center"}>
                  <Text marginRight={"10px"}>{project.title}</Text>
                  <Tooltip label="confirmed" fontSize="md">
                    <CheckCircleIcon color="green" />
                  </Tooltip>
                </Flex>
              ) : (
                <Flex direction="row" alignItems={"center"}>
                  <Text marginRight={"10px"}>{project.title}</Text>
                  <Tooltip label="not confirmed" fontSize="md">
                    <CheckCircleIcon color="red" />
                  </Tooltip>
                </Flex>
              );
            return (
              <Project
                boxShadow={cardShadow}
                mb="20px"
                image={config.apiUrl + `/project/file/` + project.images}
                ranking={index + 1}
                link={"/admin/project/" + project._id}
                title={title}
              />
            );
          })}
        </Card>
      )}
    </>
  );
}
