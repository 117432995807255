import React, { useState, useEffect } from "react";
import { Button, Text, IconButton } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { Input, Stack } from "@chakra-ui/react";
import "./ProfileSettings.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getResume, updateResume } from "reducers/userInformation";
import { useDispatch, useSelector } from "react-redux";
import WorkHistoryItem from "./WorkHistoryItem";

const WorkHistory = (props) => {
  const dispatch = useDispatch();
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [currentWork, setCurrentWork] = useState({
    workingType: "",
    start: "",
    end: "",
    company: "",
  });
  const [workingTypeError, setWorkingTypeError] = useState("");
  const [startError, setStartError] = useState("");
  const [endError, setEndError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [index, setIndex] = useState(0);
  const cancelProfileSettings = () => {};
  const resumeData = useSelector((state) => state.UserInformation.resume.data);
  const updatedResumeStatus = useSelector(
    (state) => state.UserInformation.updatedResume.status
  );
  const showAddHandler = () => {
    if (!showAdd && !showEdit) {
      setShowAdd(true);
    } else if (showAdd) {
      setShowAdd(false);
    } else if (showEdit) {
      setShowEdit(false);
    }
    setCurrentWork({
      workingType: "",
      start: "",
      end: "",
      company: "",
    });
  };
  const notify = () =>
    toast.success("Your work history has been successfully updated!", {
      position: "top-right",
      hideProgressBar: false,
      onClick: () => {
        window.location.href = "/";
      },
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

  const [resume, setResume] = useState({
    Education: [
      {
        diplome: "",
        start: "",
        end: "",
        place: "",
      },
    ],
    workHistory: [
      {
        workingType: "",
        start: "",
        end: "",
        company: "",
      },
    ],
    Language: [""],
  });

  const getResumeHandler = async () => {
    await dispatch(getResume()).unwrap();
  };
  const validateForm = () => {
    let invalidForm = false;
    if (!currentWork.workingType) {
      invalidForm = true;
      setWorkingTypeError("This field is required!");
    } else {
      setWorkingTypeError("");
    }
    if (!currentWork.start) {
      invalidForm = true;
      setStartError("This field is required!");
    } else if (
      currentWork.start &&
      currentWork.start > new Date().toISOString()
    ) {
      setStartError("Start date cannot be in the future");
    } else {
      setStartError("");
    }
    if (!currentWork.end) {
      invalidForm = true;
      setEndError("This field is required!");
    } else {
      setEndError("");
    }
    if (!currentWork.company) {
      invalidForm = true;
      setCompanyError("This field is required!");
    } else {
      setCompanyError("");
    }
    return invalidForm;
  };
  const updateResumeHandler = async () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const updatedWork = [...resume.workHistory];
    if (showAdd) {
      updatedWork[updatedWork.length] = currentWork;
    } else {
      updatedWork[index] = currentWork;
    }
    setResume({ ...resume, workHistory: updatedWork });
    await dispatch(
      updateResume({ ...resume, workHistory: updatedWork })
    ).unwrap();
    setCurrentWork({
      workingType: "",
      start: "",
      end: "",
      company: "",
    });
    setShowAdd(false);
    setShowEdit(false);
    notify();
  };

  useEffect(() => {
    if (
      resumeData &&
      resumeData.message &&
      resumeData.message.resume &&
      resumeData.message.resume[0]
    ) {
      const { Education, workHistory, Language } = resumeData.message.resume[0];
      setResume({ Education, workHistory, Language });
    }
  }, [resumeData]);

  useEffect(() => {
    if (updatedResumeStatus === "success") {
      props.getUser();
      getResumeHandler();
    }
  }, [updatedResumeStatus]);

  useEffect(() => {
    getResumeHandler();
  }, []);

  const handleWorkHistoryChange = (event, index) => {
    const x = { ...currentWork };
    const { name, value } = event.target;
    x[name] = value;
    setCurrentWork(x);
  };

  const handleAddWorkHistory = () => {
    const newWorkHistory = {
      workingType: "",
      start: "",
      end: "",
      company: "",
    };
    const updatedWorkHistory = [...resume.workHistory, newWorkHistory];
    setResume({ ...resume, workHistory: updatedWorkHistory });
  };

  const handleDeleteWorkHistory = async (index) => {
    const updatedworkHistory = [...resume.workHistory];
    console.log(updatedworkHistory);
    updatedworkHistory.splice(index, 1);
    //console.log("dqsf"+updatedEducation.splice(index, 1));
    setResume({ ...resume, workHistory: updatedworkHistory });
    const x = { ...resume, workHistory: updatedworkHistory };
    await dispatch(updateResume(x)).unwrap();
    notify();
  };
  const showEditHandler = (item, index) => {
    setShowEdit(true);
    setCurrentWork(item);
    setIndex(index);
  };
  return (
    <Stack
      padding="8"
      display={"flex"}
      flexDirection={"column"}
      borderRadius={"30px"}
      backgroundColor={"white"}
    >
      <Flex spacing="4">
        <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
          <h3 className="SettingTitle">Work History</h3>
        </Flex>
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="add"
          icon={!showAdd && !showEdit ? <AddIcon /> : <CloseIcon />}
          onClick={showAddHandler}
        />
      </Flex>
      <Text marginBottom={"24px!important"} mt="0" color="gray.500" isTruncated>
        The changes you'll make will appear on your profile
      </Text>
      {!showAdd &&
        !showEdit &&
        resume.workHistory.map((item, index) => (
          <WorkHistoryItem
            item={item}
            index={index}
            handleDelete={() => handleDeleteWorkHistory(index)}
            setCurrentWork={setCurrentWork}
            showEditHandler={() => showEditHandler(item, index)}
          />
        ))}
      {(showAdd || showEdit) && (
        <>
          <label>
            <Input
              type="text"
              name="workingType"
              placeholder="workingType"
              marginTop={"5px!important"}
              borderRadius="16px"
              marginBottom="10px"
              marginRight="10px"
              required
              value={currentWork.workingType}
              onChange={(event) => handleWorkHistoryChange(event, index)}
            />
            {workingTypeError && (
              <Text mb="2" color="red">
                {workingTypeError}
              </Text>
            )}
            <Input
              type="date"
              name="start"
              value={
                currentWork.start
                  ? new Date(currentWork.start).toISOString().substring(0, 10)
                  : Date.now()
              }
              placeholder="start date"
              marginTop={"5px!important"}
              borderRadius="16px"
              marginBottom="10px"
              marginRight="10px"
              required
              max={currentWork.end ? currentWork.end : ""}
              onChange={(event) => handleWorkHistoryChange(event, index)}
            />
            {startError && (
              <Text mb="2" color="red">
                {startError}
              </Text>
            )}
            <Input
              type="date"
              name="end"
              placeholder="end date"
              marginTop={"5px!important"}
              borderRadius="16px"
              marginBottom="10px"
              marginRight="10px"
              value={
                currentWork.end
                  ? new Date(currentWork.end).toISOString().substring(0, 10)
                  : Date.now()
              }
              min={currentWork.start ? currentWork.start : ""}
              onChange={(event) => handleWorkHistoryChange(event, index)}
            />
            {endError && (
              <Text mb="2" color="red">
                {endError}
              </Text>
            )}
            <Input
              type="text"
              name="company"
              placeholder="company"
              required
              marginTop={"5px!important"}
              borderRadius="16px"
              marginBottom="10px"
              marginRight="10px"
              value={currentWork.company}
              onChange={(event) => handleWorkHistoryChange(event, index)}
            />
            {companyError && (
              <Text mb="2" color="red">
                {companyError}
              </Text>
            )}
          </label>
          <Flex justifyContent="flex-end" alignItems={"end"}>
            {false && (
              <Button
                onClick={() => {
                  cancelProfileSettings();
                }}
                size="md"
                marginTop={"24px!important"}
                color="#422AFB"
                border="2px solid #422AFB"
                mr="12px"
                marginLeft={"auto!important"}
                width="180px"
              >
                Cancel changes
              </Button>
            )}
            <Button
              variant="brand"
              onClick={() => {
                updateResumeHandler();
              }}
              marginTop={"24px!important"}
              size="md"
              width="180px"
            >
              Save changes
            </Button>
          </Flex>
        </>
      )}
    </Stack>
  );
};

export default WorkHistory;
