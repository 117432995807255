import React, { useState } from "react";
import {
  Input,
  Button,
  FormLabel,
  FormControl,
  Textarea,
  Flex,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from "moment";
import { updateTrack } from "reducers/track";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const EditTrackForm = (props) => {
  const { track, onEdit } = props;
  const dispatch = useDispatch();
  const [title, setTitle] = useState(track?.voteSession?.title);
  const [start, setStart] = useState(track?.voteSession?.start);
  const [end, setEnd] = useState(track?.voteSession?.end);
  const [description, setDescription] = useState(
    track?.voteSession?.description
  );
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [startError, setStartError] = useState("");
  const [endError, setEndError] = useState("");

  const validForm = () => {
    let valid = true;
    const date = new Date();
    if (!start) {
      setStartError("You should enter a start date");
      valid = false;
    } else if (new Date(start) < date) {
      setStartError("Start date must be greater than current date");
    } else {
      setStartError("");
    }
    if (!end) {
      setEndError("You should enter an end date");
      valid = false;
    } else if (end < start) {
      setEndError("End date should be greater than start date");
    } else {
      setEndError("");
    }
    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    return valid;
  };

  const confirm = async () => {
    if (!validForm()) {
      return;
    }
    const data = {
      id: track?.voteSession?._id,
      start,
      end,
      title,
      description,
    };
    await dispatch(updateTrack(data)).unwrap();
    toast.success("Track has been updated successfully!", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });
    onEdit();
  };

  return (
    <Card>
      <FormControl>
        <FormLabel>Title</FormLabel>
        <Input
          type="text"
          value={title}
          placeholder="Title"
          onChange={(e) => setTitle(e.target.value)}
        />
        {titleError && <Text color="red">{titleError}</Text>}
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Start date</FormLabel>
        <Input
          type="date"
          value={moment(new Date(start)).format("YYYY-MM-DD")}
          placeholder="start"
          onChange={(e) => setStart(e.target.value)}
        />
        {startError && <Text color="red">{startError}</Text>}
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>End date</FormLabel>
        <Input
          placeholder="Last name"
          type="date"
          value={moment(new Date(end)).format("YYYY-MM-DD")}
          onChange={(e) => setEnd(e.target.value)}
        />
        {endError && <Text color="red">{endError}</Text>}
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Description </FormLabel>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
          rows="5"
        />
        {descriptionError && <Text color="red">{descriptionError}</Text>}
      </FormControl>
      <Flex mt="4" justifyContent={"flex-end"}>
        <Button colorScheme={"blue"} onClick={confirm}>
          Confirm
        </Button>
      </Flex>
    </Card>
  );
};

export default EditTrackForm;
