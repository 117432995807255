import Card from "components/card/Card.js";
import {
  Text,
  Flex,
  Button,
  Heading,
  Divider,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import { Chips } from "primereact/chips";
import React, { useState } from "react";
import config from "config/axios/config";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { applyOpportunity } from "reducers/callofStartups";
import { useSelector } from "react-redux";
import { getRequestCallofstartupById } from "reducers/callofStartups";

const Step5 = (props) => {
  const { applyData, setApplyData, setStep, project ,requestCall} = props;
  const { idrequest, id } = useParams();

  const [tags, setTags] = useState(
    requestCall ? requestCall.KeyMilestonesAchieved : applyData.tags
  );
  const [errortags, setErrorTags] = useState();
  const dispatch = useDispatch();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const validateForm = () => {
    let valid = true;
    if (!tags) {
      setErrorTags("This filed are required");
      valid = false;
    } else {
      setErrorTags("");
    }

    return valid;
  };
  const confirm = async () => {
    if (!validateForm()) {
      return;
    }
    const Oppdata = new FormData();
    Oppdata.append("callOfStartup", id);
    Oppdata.append("project", requestCall ? requestCall.project._id : applyData.project);
    Oppdata.append("KeyMilestonesAchieved", tags);
    Oppdata.append("step", 6);
    const dataaddOpp = {
      Oppdata,
    };
    await dispatch(applyOpportunity(dataaddOpp)).unwrap();
    const data = {
      ...applyData,
      tags: tags,
    };
    setApplyData(data);
    setStep(6);
  };

  useEffect(() => {
    console.log(project, "project");
  }, [project]);

  const back = () => {
    setStep(4);
    setTags();
    const data = {
      ...applyData,
      tags: [],
    };
    setApplyData(data);
  };
  return (
    <Card>
      <Heading size="sm" mb="2">
        Team
      </Heading>
      {project &&
        project.teamMemberEmails &&
        project.teamMemberEmails.length > 0 &&
        project.teamMemberEmails.map((member) => (
          <Flex align="center" m="3">
            <Avatar
              w="30px"
              h="30px"
              me="8px"
              src={config.apiUrl + "/api/getfile/" + member.Image}
            />
            <Flex flexDirection={"column"} justifyContent={"center"}>
              <Text
                color={textColor}
                fontSize="sm"
                fontWeight="600"
                onClick={() =>
                  (window.location.href =
                    "/#/admin/expert/profile/" + member._id)
                }
              >
                {member.firstName + " " + member.lastName}
              </Text>
              <Text color={textColorSecondary} fontSize="xs" fontWeight="300">
                {member.email}
              </Text>
            </Flex>
          </Flex>
        ))}
      <Divider mb="2" />
      <Heading size="sm" mb="2">
        Traction
      </Heading>
      <Text>
        Key Milestones Achieved: (List any significant milestones your startup
        has achieved, such as product launches, user growth, partnerships, etc.)
      </Text>
      <Flex flexDirection={"column"} m="3">
        <Chips
          placeholder="Tags (press enter to confirm tag)"
          inputId="tags"
          name="tags"
          value={tags}
          onChange={(e) => setTags(e.value)}
          w="100%"
        />
      </Flex>
      {errortags && <Text color="red">{errortags}</Text>}

      <Flex justifyContent="end" mt="4">
        <Button
          colorScheme="blue"
          variant="outline"
          size="md"
          onClick={back}
          mr="2"
        >
          Back
        </Button>
        <Button colorScheme="blue" size="md" onClick={confirm}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};

export default Step5;
