import React from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  ButtonGroup,
  Divider,
  Badge,
} from "@chakra-ui/react";
import ReactStars from "react-rating-stars-component";
import Card from "components/card/Card.js";
import { Avatar as Avatarr } from "@chakra-ui/react";
import config from "config/axios/config";
import { addExpertRate } from "reducers/expert";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom";

export default function ExpertListItem({
  expert: { _id, firstName, lastName, sector, Rating, hourPrice, Image, job },
  haveMeet,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const textColorSecondary = "gray.400";
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const rateExpert = async (value) => {
    const dataRating = {
      idExpert: _id,
      rating: value,
    };
    await dispatch(addExpertRate(dataRating))
      .unwrap()
      .then((res) => {
        toast.success(res.message, {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
      });
  };

  return (
    <Card p="10px 20px" cardShadow={cardShadow}>
      <ToastContainer />
      <Flex
        direction={{ base: "column" }}
        justifyContent="space-between"
        h="100%"
      >
        <Box
          mb="2"
          display="flex"
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Avatarr
            cursor="pointer"
            h="80px"
            w="80px"
            border="4px solid"
            borderColor={borderColor}
            src={config.apiUrl + "/api/getfile/" + Image}
          />
          {haveMeet.includes(_id) && (
            <Badge colorScheme="purple" variant="outline" p="2px 0px" mr="1">
              <Text
                fontSize="10px"
                width="100%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                can rate
              </Text>
            </Badge>
          )}
          <Text
            cursor={"pointer"}
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="md"
          >
            {firstName + " " + lastName}{" "}
          </Text>
          <Text color={textColorSecondary} fontSize="xs">
            {job ? job : ""}
          </Text>
          <ReactStars
            defaultState={5}
            count={5}
            onChange={rateExpert}
            size={20}
            activeColor="#ffd700"
            value={Rating}
            isHalf
            edit={haveMeet.includes(_id)}
          />
        </Box>

        <Flex flexDirection={"column"} mb="2">
          <Text
            cursor={"pointer"}
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="sm"
          >
            {sector && sector.length > 0 && (
              <>
                {sector.map((sec, i) => {
                  if (i < 4)
                    return (
                      <Badge
                        colorScheme="purple"
                        variant="outline"
                        p="2px 0px"
                        mr="1"
                      >
                        <Text
                          fontSize="10px"
                          width="100%"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          #{sec}
                        </Text>
                      </Badge>
                    );
                })}
                {sector.length > 4 && <>. . .</>}
              </>
            )}{" "}
          </Text>
          <Text color={textColorSecondary} fontSize="xs">
            Sectors
          </Text>
        </Flex>
        <Divider />
        <Flex flexDirection={"column"}>
          <Text
            cursor={"pointer"}
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="sm"
          >
            {hourPrice ? hourPrice + " HBAR" : "No Price"}
          </Text>
          <Text color={textColorSecondary} fontSize="xs">
            Hourly Price
          </Text>
        </Flex>
        <Divider />
        <ButtonGroup spacing="2" justifyContent={"flex-end"} mt="2">
          <BasicButton
            variant="solid"
            title="View Profile"
            onClick={() => history.push("/admin/expert/profile/" + _id)}
          />
        </ButtonGroup>
      </Flex>
    </Card>
  );
}
