import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Progress,
  Icon,
  Badge,
} from "@chakra-ui/react";
import { Avatar as Avatarr } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "react-avatar-edit";
import config from "config/axios/config";
import axios from "axios";
import { BsTwitter, BsFacebook, BsGithub, BsLinkedin } from "react-icons/bs";
import { BsFillStarFill } from "react-icons/bs";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { addExpertRate } from "reducers/expert";
import { useDispatch } from "react-redux";
import ReactStars from "react-rating-stars-component";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom";

export default function Banner(props) {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    banner,
    user,
    haveMeet,
    getUser,
    action,
    icon,
    canBook,
    profilePercentage = null,
    showSendMessage = false,
    sendMessageHandler = () => {},
  } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [profilePic, setProfilePic] = useState();
  const [isCroping, setIsCroping] = useState(false);
  const history = useHistory();

  const [preview, setPreview] = useState(null);
  const [userImage, setUserImage] = useState("");

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  function onCrop(pv) {
    setPreview(pv);
    if (profilePic.type === "image/png") {
      setProfilePic(dataURLtoFile(pv, pv[(0, 19)] + ".png"));
    } else {
      setProfilePic(dataURLtoFile(pv, pv[(0, 19)] + ".jpg"));
    }
  }

  function onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 893928) {
      alert("File is too big!");
      elem.target.value = "";
    }
  }

  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const [profilePicErr, setProfilePicErr] = useState("");
  const [socialUrls, setSocialUrls] = useState({
    facebookUrl: JSON.parse(localStorage.getItem("CurrentUserData"))
      .facebookUrl,
    githubUrl: JSON.parse(localStorage.getItem("CurrentUserData")).githubUrl,
    twitterUrl: JSON.parse(localStorage.getItem("CurrentUserData")).twitterUrl,
    linkedinUrl: JSON.parse(localStorage.getItem("CurrentUserData"))
      .linkedinUrl,
  });

  const notify = () =>
    toast.success("Your profile picture has been updated.", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });

  const errNotif = () =>
    toast.error(profilePicErr, {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });

  const postProfilePic = () => {
    const formData = new FormData();
    formData.append("Image", profilePic);
    axios
      .put(config.apiUrl + "/api/update/profilpic", formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((resp) => {
        localStorage.setItem(
          "CurrentUserData",
          JSON.stringify(resp.data.message)
        );
        notify();
        setIsCroping(false);
        getUser();
        onClose();
      })
      .catch((err) => {
        if (profilePicErr === "") {
          setProfilePicErr("Something went wrong");
        }
        errNotif();
      });
  };
  let content = [];
  for (var i = 0; i < user.Rating; i++) {
    content.push(
      <Icon
        as={BsFillStarFill}
        me="2px"
        width="13px"
        height="13px"
        color="#ffe234"
      />
    );
  }
  const dispatch = useDispatch();
  const [userType, setUsetType] = useState("");

  const [userId, setUserId] = useState("");
  const AddRatingExpert = async (value) => {
    const dataRating = {
      idExpert: id,
      rating: value,
    };
    await dispatch(addExpertRate(dataRating)).unwrap();
    window.location.reload(false);
  };
  useEffect(() => {
    setUserId(jwt_decode(localStorage.getItem("token"))._id);
    const USER_TYPE = jwt_decode(localStorage.getItem("token")).userType;
    setUsetType(USER_TYPE);
  });
  const goTo = () => history.push("/admin/expert/calendar/" + user._id);
  useEffect(() => {
    if (user && user.Image) {
      setUserImage(config.apiUrl + "/api/getfile/" + user.Image);
    }
  }, [user]);
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center">
      <ToastContainer />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton onClick={() => setIsCroping(false)} />
          <ModalBody
            display={"flex"}
            alignItems="center"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Avatar
              label="Choose a file (70mb max)"
              onFileLoad={(file) => {
                setIsCroping(true);
                setProfilePic(file);
              }}
              width={300}
              imageWidth="300"
              imageHeight={"300"}
              height={300}
              onCrop={onCrop}
              onClose={() => {
                setIsCroping(false);
                onClose();
              }}
              onBeforeFileLoad={onBeforeFileLoad}
              src={null}
            />
            <Flex justifyContent={"space-around"}>
              <Button
                my={"3"}
                h="52.67px"
                mr="15"
                variant="outline"
                onClick={() => {
                  setIsCroping(false);
                  onClose();
                }}
              >
                Cancel
              </Button>
              {isCroping && (
                <Button
                  my={"3"}
                  h="52.67px"
                  colorScheme="blue"
                  onClick={postProfilePic}
                >
                  Upload
                </Button>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />

      {user && (
        <Avatarr
          onClick={onOpen}
          cursor="pointer"
          h="87px"
          w="87px"
          border="4px solid"
          mx="auto"
          mt="-43px"
          borderColor={borderColor}
          src={userImage}
        />
      )}
      {(userType === "Admin" || userType === "SuperAdmin") && (
        <Flex justifyContent="center" alignItems={"center"}>
          {haveMeet && haveMeet.haveMeet.includes(id) && (
            <Badge colorScheme="purple" variant="outline" p="2px 0px" mr="1">
              <Text
                fontSize="10px"
                width="100%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                can rate
              </Text>
            </Badge>
          )}
        </Flex>
      )}
      <Text
        onClick={action}
        cursor={"pointer"}
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="xl"
        mt="10px"
      >
        {user.firstName + " " + user.lastName}{" "}
        {icon && (
          <Link to="/admin/settings">
            <Icon as={icon} />
          </Link>
        )}
      </Text>

      <Text color={textColorSecondary} fontSize="sm">
        {user.job && user.job}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {user.email && user.email}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {user.userType && user.userType}
      </Text>
      {haveMeet && userType !== "Admin" && userType !== "SuperAdmin" && (
        <Flex justifyContent="center" alignItems={"center"}>
          {" "}
          <ReactStars
            defaultState={5}
            count={5}
            onChange={AddRatingExpert}
            size={20}
            activeColor="#ffd700"
            value={user.Rating}
            isHalf
            edit={haveMeet.haveMeet.includes(id)}
          />
        </Flex>
      )}

      {/*<Flex w="max-content" mx="auto" mt="26px">
        <Flex
          justifyContent="center"
          alignItems={"center"}
          mx="auto"
          alignSelf={"center"}
        >
          {posts && (
            <Flex mx="auto" me="60px" align="center" direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {posts}
              </Text>

              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Posts
              </Text>
            </Flex>
          )}

          {followers && (
            <Flex mx="auto" me="60px" align="center" direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {followers}
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Followers
              </Text>
            </Flex>
          )}

          {following && (
            <Flex mx="auto" align="center" direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {following}
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Following
              </Text>
            </Flex>
          )}
        </Flex>
          </Flex>*/}
      <Flex w="max-content" mx="auto" mt="5px">
        {showSendMessage && (
          <Flex justifyContent={"center"} mr="2">
            <BasicButton
              title={"Send Message"}
              variant={"brand"}
              onClick={() => history.replace("/admin/chat")}
            />
          </Flex>
        )}
        {canBook && (
          <Flex
            justifyContent="center"
            alignItems={"center"}
            mx="auto"
            alignSelf={"center"}
          >
            <BasicButton variant={"brand"} onClick={goTo} title={"Book now"}>
              Book now
            </BasicButton>
          </Flex>
        )}
      </Flex>

      <Flex
        justifyContent={"space-around"}
        alignItems="center"
        alignSelf={"end"}
        ml="auto"
        mr="6"
      >
        {socialUrls.facebookUrl && (
          <IconButton
            onClick={() => {
              window.open(socialUrls.facebookUrl, "_blank").focus();
            }}
            aria-label="Search database"
            icon={<Icon as={BsFacebook} w={6} h={6} color="#3b5998" />}
          />
        )}
        {socialUrls.githubUrl && (
          <IconButton
            onClick={() => {
              window.open(socialUrls.githubUrl, "_blank").focus();
            }}
            aria-label="Search database"
            icon={<Icon as={BsGithub} w={6} h={6} color="black" />}
          />
        )}
        {socialUrls.twitterUrl && (
          <IconButton
            onClick={() => {
              window.open(socialUrls.twitterUrl, "_blank").focus();
            }}
            aria-label="Search database"
            icon={<Icon as={BsTwitter} w={6} h={6} color="#1D9BF0" />}
          />
        )}
        {socialUrls.linkedinUrl && (
          <IconButton
            onClick={() => {
              window.open(socialUrls.linkedinUrl, "_blank").focus();
            }}
            aria-label="Search database"
            icon={<Icon as={BsLinkedin} w={6} h={6} color="#0077B5" />}
          />
        )}
      </Flex>
      {profilePercentage !== null && (
        <Box mt="4">
          <Progress
            w="100%"
            hasStripe
            value={profilePercentage}
            textAlign={"start"}
          />
          <Text
            color={textColorSecondary}
            fontSize="xs"
            mt="2"
            textAlign={"start"}
          >
            {profilePercentage}/100
          </Text>
        </Box>
      )}
    </Card>
  );
}
