import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

export default function Loader() {
  return (
    <Flex flexDirection="column" h="100%" mt="40px" mb="40px">
      <Spinner my="auto!important" mx="auto" />
    </Flex>
  );
}
