import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
  FormLabel,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { update } from "reducers/hackathon";

const EditBuildGuide = ({ isOpen, onClose, initialData, onReload }) => {
  const dispatch = useDispatch();
  const [whatToBuild, setWhatToBuild] = useState(initialData.whatToBuild);
  const [whatToBuildError, setWhatToBuildError] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const validateForm = () => {
    let valid = true;
    if (!whatToBuild) {
      setWhatToBuildError("What To Build is required!");
      valid = false;
    } else {
      setWhatToBuildError("");
    }
    return valid;
  };

  const editHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const data = new FormData();
    data.append("whatToBuild", whatToBuild);

    const hackathonData = {
      data,
      id: initialData._id,
    };
    await dispatch(update(hackathonData)).unwrap();
    onReload();
  };

  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [whatToBuild, submitted]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit what to build</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                What To Build <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Textarea
                isInvalid={whatToBuildError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="What to build"
                borderRadius="5px"
                value={whatToBuild}
                onChange={(e) => setWhatToBuild(e.target.value)}
              />
              {whatToBuildError && (
                <Text mt="3" color="red">
                  {whatToBuildError}
                </Text>
              )}
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={editHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditBuildGuide;
