import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
  FormLabel,
  Text,
  Input,
  Textarea,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import MySelect from "react-select";
import { whatToSubmit } from "utils/constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateCohort } from "reducers/cohort";
import { update } from "reducers/hackathon";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditJudgingCriteria = ({
  isOpen,
  onClose,
  criteria,
  onReload,
  idhackathon,
  currentjudgingCriteriaToEdit,
  type,
}) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [criteriaError, setCriteriaError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { id } = useParams();

  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    return valid;
  };
  const validateCriteria = () => {
    let valid = true;
    if (criteria.length == 0) {
      setCriteriaError("Criteria is required!");
      valid = false;
    } else {
      setCriteriaError("");
    }

    return valid;
  };

  const handleAddCriteria = async (criteriaOne) => {
    if (!validateForm()) {
      return;
    }
    let selectionCriteria = [];

    if (type == "Edit") {
      selectionCriteria = criteria.map((item) =>
      item._id === currentjudgingCriteriaToEdit._id
          ? {
              _id: currentjudgingCriteriaToEdit._id,
              title: title,
              description: description,
            }
          : item
      );
    } else {
      selectionCriteria = [
        ...criteria,
        { title: title, description: description },
      ];
    }
    const data = new FormData();
    if (selectionCriteria.length && title != "" && description != "") {
      data.append("judgingCriteria", JSON.stringify(selectionCriteria));
      const programData = {
        data,
        id: id,
      };
        await dispatch(update(programData)).unwrap();
    }
    onReload()
    // setTitle("");
    // setDescription("");
  };

  useEffect(() => {
    if (isSubmitted) {
      validateCriteria();
    }
  }, [criteria, isSubmitted]);
  useEffect(() => {
    if (currentjudgingCriteriaToEdit) {
      setTitle(currentjudgingCriteriaToEdit.title);
      setDescription(currentjudgingCriteriaToEdit.description);
    }
  }, [currentjudgingCriteriaToEdit]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Criteria Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Title <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Input
                isInvalid={titleError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Title"
                borderRadius="5px"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {titleError && (
                <Text mt="3" color="red">
                  {titleError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Description <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Textarea
                isInvalid={descriptionError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Description"
                borderRadius="5px"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {descriptionError && (
                <Text mt="3" color="red">
                  {descriptionError}
                </Text>
              )}
            </Flex>
            {criteriaError && (
              <Text mt="3" color="red">
                {criteriaError}
              </Text>
            )}
            <Flex justifyContent={"end"} mt="5">
              <BasicButton
                onClick={handleAddCriteria}
                title={type == "Edit" ? "Edit Criteria " : "Add New Criteria"}
                variant="solid"
              />
            </Flex>
            {/* <CardItem value={criteria} setValue={setCriteria} /> */}
          </Flex>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditJudgingCriteria;
