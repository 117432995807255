import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import TextareaField from "components/fields/TextareaField";
import BasicButton from "components/button/BasicButton";
import { useEffect } from "react";
import { update } from "reducers/programPhase";
import { useDispatch } from "react-redux";

const EditCriterionForm = ({
  isOpen,
  onClose,
  initialData,
  criterion,
  onReload,
}) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const validateForm = () => {
    let valid = true;

    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    return valid;
  };
  const onEditHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    let selectionCriteria = [];
    if (criterion) {
      selectionCriteria = initialData.selectionCriteria.map((item) =>
        item._id === criterion._id
          ? { _id: criterion._id, title: title, description: description }
          : item
      );
    } else {
      selectionCriteria = [
        ...initialData.selectionCriteria,
        { title: title, description: description },
      ];
    }

    const phaseData = {
      data: {
        ...initialData,
        selectionCriteria: selectionCriteria,
      },
      id: initialData._id,
    };
    await dispatch(update(phaseData)).unwrap();
    onReload();
    onClose();
  };
  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [title, description]);

  useEffect(() => {
    if (criterion) {
      setTitle(criterion.title);
      setDescription(criterion.description);
    }
  }, [criterion]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Criterion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mb="3">
              <InputField
                label="Title"
                placeholder="Title"
                value={title}
                setValue={setTitle}
                error={titleError}
                type="text"
              />
            </Flex>
            <Flex mb="3">
              <TextareaField
                label="Description"
                placeholder="Description"
                value={description}
                setValue={setDescription}
                error={descriptionError}
              />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={onEditHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditCriterionForm;
