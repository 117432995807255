import React from "react";
import {
  Tooltip,
  Divider,
  Flex,
  Text,
  IconButton,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import TextCard from "components/card/TextCard";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import EditAssistingProgramRules from "../EditAssistingProgram/EditAssistingProgramRules";
import DeleteConfirmationModal from "components/modals/DeleteConfirmationModal";
import { update } from "reducers/assistingProgram";
import { useDispatch } from "react-redux";

const AssistingProgramsRules = ({ assistingProgram, onReload }) => {
  const [userType, setUserType] = useState("");
  const [currentRule, setCurrentRule] = useState("");
  const [currentRuleToDelete, setCurrentRuleToDeleteCurrentRule] = useState("");
  const dispatch = useDispatch();

  const [editType, setEditType] = useState("");
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const OnOpenAddRuleModalHandler = async (rule) => {
    onOpenModal();
    setEditType("Add");
  };
  const OnOpenModalHandler = async (rule) => {
    onOpenModal();
    setCurrentRule(rule);
    setEditType("Edit");
  };
  const OnOpenModalDeleteHandler = async (rule) => {
    onOpenDelete();
    setCurrentRuleToDeleteCurrentRule(rule);
  };
  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
    console.log(userType, "kfkfkfkfkfkfk");
  }, [currentUser]);
  const handleRemoveRule = (ruleToRemove) => {
    const updatedRulesArray = assistingProgram.rules.filter(
      (rule) => rule !== currentRuleToDelete
    );

    const data = new FormData();
    data.append("rules", JSON.stringify(updatedRulesArray));

    const programData = {
      data,
      id: assistingProgram._id,
    };

    dispatch(update(programData)).unwrap();
    onReload(false)
    onCloseDelete()
  };
  return (
    <>
      {userType && (
        <Flex justifyContent={"flex-end"}>
          {(userType == "Admin" || userType == "SuperAdmin") && (
            <Tooltip label="Add Rule" hasArrow placement="top-end">
              <IconButton
                icon={<FaPlus />}
                size="sm"
                colorScheme="blue"
                onClick={OnOpenAddRuleModalHandler}
              />
            </Tooltip>
          )}
        </Flex>
      )}
      <Text fontWeight="bold" mb="3">
        {assistingProgram.title} (the “Assisting Program”) Official Rules
      </Text>

      <Divider my="2" />

      <Flex direction={"column"}>
        {assistingProgram &&
          assistingProgram.rules &&
          assistingProgram.rules.map((item, index) => (
            <>
              <Flex justifyContent={"space-between"}>
                <Text fontWeight={"bold"}>
                  {index + 1 + " - " + item.title}{" "}
                </Text>
                {(userType == "Admin" || userType == "SuperAdmin") && (
                  <Flex>
                    {/* <Tooltip label="Edit Rule">
                      <IconButton
                        mr="10px"
                        icon={<FaEdit />}
                        size="sm"
                        colorScheme="blue"
                        onClick={() => OnOpenModalHandler(item)}
                      />
                    </Tooltip> */}
                    <Tooltip label="Delete Rule">
                      <IconButton
                        icon={<FaTrash />}
                        size="sm"
                        colorScheme="red"
                        onClick={() => OnOpenModalDeleteHandler(item)}
                      />
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
              <Text mb="3">{item.description}</Text>
            </>
          ))}
      </Flex>
      <DeleteConfirmationModal
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        headerContent="Confirm Deletion"
        bodyContent={`Are you sure you want to delete the Assisting Program with the name <b>${currentRuleToDelete.title}</b>? This action cannot be undone.`}
        handleDelete={handleRemoveRule}
      />
      <EditAssistingProgramRules
        currentRule={currentRule}
        editType={editType}
        id={assistingProgram._id}
        assistingProgram={assistingProgram}
        isOpen={isOpenModal}
        onClose={onCloseModal}
        onReload={onReload}
      />
      {assistingProgram && assistingProgram.rules.length == 0 && (
        <TextCard text="There is no rule" />
      )}
    </>
  );
};

export default AssistingProgramsRules;
