import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  Flex,
  Box,
  Image,
  Button,
  Center,
  Heading,
  Divider,
  Badge,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import placeholder from "assets/img/placeholder.jpg";
import { Link as Linkk } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/pagination/Pagination";
import { getPendingProjectsByCohort } from "reducers/cohort";
import { updateCohortApplication } from "reducers/cohort";
import TextCard from "components/card/TextCard";
import { updateHackathonApplication } from "reducers/hackathon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function HackathonApplyRequests(props) {
  const { id, onReload, requests, page, setPage, totalPages } = props;
  const dispatch = useDispatch();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const history = useHistory();

  useEffect(() => {
    console.log(requests, "fofofo abdou");
  }, [requests]);
  const updateStatusHackathonApplication = async (applicationId, status) => {
    const updateData = {
      id: applicationId,
      status: status,
    };
    await dispatch(updateHackathonApplication(updateData)).unwrap();
    onReload();
  };

  return (
    <Box>
      <Heading size="sm" mt="4" mb="2">
        Requests for Join Hackathon
      </Heading>
      <Divider mb="2" />
      {requests && requests.length > 0 && (
        <Card p="2" direction="column" w="100%">
          {requests.map((item) => (
            <Card p="1" mb="2" boxShadow={cardShadow}>
              <Flex>
                <Flex
                  width="100%"
                  height="100%"
                  padding="0.6rem"
                  fontWeight="bold"
                  color={textColor}
                  fontSize="md"
                  textAlign="start"
                >
                  <Flex flex="1" gap="2" alignItems="center" flexWrap="wrap">
                    <Image
                      h="50px"
                      w="50px"
                      borderRadius="8px"
                      fallbackSrc={placeholder}
                      objectFit="cover"
                    />
                    <Box>
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Text
                          fontWeight="normal"
                          mr="2"
                          _hover={{ color: "blue.500", cursor: "pointer" }}
                        >
                          <Linkk to={"/admin/project/" + item.project._id}>
                            {item.project.title}
                          </Linkk>{" "}
                        </Text>
                        <Badge colorScheme="green">{item.track}</Badge>
                      </Flex>
                      <Text
                        mt="2"
                        color="#9e9e9ea1"
                        fontSize={"xs"}
                        fontWeight={"200"}
                      >
                        CREATED BY:{"  "}
                        <b>
                          {item.project.createdby.firstName +
                            " " +
                            item.project.createdby.lastName}
                        </b>
                      </Text>{" "}
                    </Box>
                  </Flex>
                </Flex>
                {item.status === "pending" && (
                  <Flex alignItems={"center"}>
                    <Button
                      colorScheme={"blue"}
                      size="sm"
                      mr="2"
                      onClick={() => history.replace("/admin/chat")}
                    >
                      Send Message
                    </Button>
                    <Button
                      colorScheme={"red"}
                      size="sm"
                      mr="2"
                      onClick={() =>
                        updateStatusHackathonApplication(item.id, "rejected")
                      }
                    >
                      Refuse
                    </Button>
                    <Button
                      colorScheme={"green"}
                      size="sm"
                      onClick={() =>
                        updateStatusHackathonApplication(item.id, "accepted")
                      }
                    >
                      Accept
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Card>
          ))}
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
        </Card>
      )}
      {requests && requests.length === 0 && (
        <TextCard text="There is no request" />
      )}
    </Box>
  );
}
