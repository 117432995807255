import Api from "config/axios/api";
import config from "../config/axios/config";

export const getPhasesByIdAssistingProgramService = async (phase) => {
  const url =
    config.apiUrl +
    `/assisting-programs/${phase.id}/phases?limit=${phase.limit}&page=${phase.page}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const createProgramPhaseService = async (phaseData) => {
  const url = config.apiUrl + `/program-phases/`;
  try {
    return await Api.post(url, phaseData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const programPhaseDetailsService = async (id) => {
  const url = config.apiUrl + `/program-phases/` + id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const programPhaseProjectsService = async (filterData) => {
  const url = config.apiUrl + `/program-phases/` + filterData.id + `/projects`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const programPhaseVotersService = async (filterData) => {
  const url = config.apiUrl + `/program-phases/` + filterData.id + `/voters`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const programPhasePartnersService = async (filterData) => {
  const url = config.apiUrl + `/program-phases/${filterData.id}/partners`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const rateProjectService = async (rateData) => {
  const url =
    config.apiUrl + `/program-phases/${rateData.programPhaseId}/rate-project`;
  try {
    const ratingData = {
      projectId: rateData.projectId,
      rate: rateData.rate,
      assistingProgramId: rateData.assistingProgramId,
    };
    return await Api.post(url, ratingData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const NextPhaseProjectService = async (dataNextPhase) => {
  const url =
    config.apiUrl +
    `/program-phases/${dataNextPhase.id}/projects/${dataNextPhase.idProject}/next-phase`;
  try {
    return await Api.put(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateService = async (phaseData) => {
  const url = config.apiUrl + `/program-phases/` + phaseData.id;
  try {
    return await Api.put(url, phaseData.data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getPhasesByIdAssistingProgramService,
  createProgramPhaseService,
  programPhaseDetailsService,
  programPhaseProjectsService,
  programPhaseVotersService,
  programPhasePartnersService,
  NextPhaseProjectService,
  updateService
};
