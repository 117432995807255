import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Api from "config/axios/api";
import config from "config/axios/config";
import axios from "axios";
import * as dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Textarea,
  Button,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { bookExpert } from "reducers/expert";
import { NumToTime } from "utils/functions";
import SwitchField from "components/fields/SwitchField";
import ChooseProject from "./components/ChooseProject";
import { addBookMeetingExpert } from "reducers/meeting";
import { getAllEventMeetings } from "reducers/meeting";

const ExpertCalendar = () => {
  const dispatch = useDispatch();
  const [userType, setUsetType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [events, setEvents] = useState([]);
  const { id } = useParams();
  const [eventInfo, setEventInfo] = useState({});
  const [expertInfo, setExpertInfo] = useState();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");
  const [titleError, setTitleError] = useState("");
  const status = useSelector((state) => state.Meeting.addBookMeeting.status);
  const [online, setOnline] = useState(false);
  const [emplacement, setEmplacement] = useState("");
  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState("");
  const [emplacementError, setEmplacementError] = useState("");
  const [step, setStep] = useState(1);
  const [project, setProject] = useState("");
  const [projectError, setProjectError] = useState("");
  const events = useSelector((state) =>
    state.Meeting.eventMeetings.data ? state.Meeting.eventMeetings.data : []
  );
  const notify = () =>
    toast.success(
      "An invitation was sent to the expert wait for confirmation",
      {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      }
    );
  const getAllTypeEvents = async () => {
    await dispatch(getAllEventMeetings(id)).unwrap();
  };
  useEffect(() => {
    const USER_TYPE = jwt_decode(localStorage.getItem("token")).userType;
    setUsetType(USER_TYPE);
    getAllTypeEvents();

    // let url = config.apiUrl + "/event/getEventByUserId/" + id;
    // Api.get(url, {
    //   headers: {
    //     Authorization: localStorage.getItem("token"),
    //   },
    // })
    //   .then((resp) => {
    //     if (resp.status !== 400) {
    //       const eventList = resp.data.message;
    //       eventList.map((event) => {
    //         if (event.typeActivity === "publicActivity") {
    //           event.color = "#378006";
    //         }
    //         return event;
    //       });
    //       setEvents(resp.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // axios
    //   .get(config.apiUrl + "/api/profileInfo/" + id)
    //   .then((resp) => {
    //     if (resp.message === "user not found") {
    //     } else {
    //       setExpertInfo(resp.data.message.hourPrice);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  const handleEventClick = (info) => {
    setEventInfo({
      eventID: info.event._def.extendedProps._id,
      startDate: info.event.start,
      endDate: info.event.end,
      numHours: "0h:00",
      totalPrice: 0,
      description: "",
      title: "",
    });
    setFrom("");
    setTo("");
    setFromError("");
    setToError("");
    setTitleError("");
    onOpen();
  };

  const validForm = () => {
    let valid = true;
    if (!from) {
      setFromError("You should enter a start time");
      valid = false;
    } else if (fromError) {
      valid = false;
    }
    if (!to) {
      setToError("You should enter an end time");
      valid = false;
    } else if (toError) {
      valid = false;
    }
    if (!eventInfo.title) {
      setTitleError("Title is required");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!online && !emplacement) {
      setEmplacementError("The emplacement is required!");
      valid = true;
    } else {
      setEmplacementError("");
    }
    if (online && !url) {
      setUrlError("The url is required!");
      valid = false;
    } else {
      setUrlError("");
    }
    if (!project) {
      setProjectError("You should select a project!");
      valid = false;
    } else {
      setProjectError("");
    }

    return valid;
  };

  const postEvent = async () => {
    if (!validForm()) {
      return;
    }
    console.log(eventInfo, "eventInfo");

    const bookData = {
      avId: eventInfo.eventID,
      start: eventInfo.start,
      end: eventInfo.end,
      title: eventInfo.title,
      description: eventInfo.description,
      online: online,
      location: !online ? emplacement : "",
      onlineUrl: online ? url : "",
      projects: project ? [project] : [],
    };
    console.log(bookData, "bookData");
    await dispatch(addBookMeetingExpert(bookData)).unwrap();
  };

  useEffect(() => {
    if (status === "success") {
      notify();
      onClose();
      setFromError("");
      setToError("");
      setTitleError("");
      setFrom("");
      setTo("");
    }
  }, [status]);

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };
  const onBlurFromHandler = (e) => {
    const startTime = convertTime12to24(
      moment(eventInfo.startDate).format("hh:mm a")
    );
    const endTime = convertTime12to24(
      moment(eventInfo.endDate).format("hh:mm a")
    );
    if (!e.target.value) {
      setFromError("You should enter a start time");
      return;
    } else if (
      e.target.value < startTime ||
      (endTime !== "00:00" && e.target.value > endTime)
    ) {
      setFromError("From should be between " + startTime + " and " + endTime);
      return;
    } else if (to && to !== "00:00" && e.target.value >= to) {
      setFromError("From should be less than to");
      return;
    } else {
      setFromError("");
    }
  };
  const onBlurToHandler = (e) => {
    const startTime = convertTime12to24(
      moment(eventInfo.startDate).format("hh:mm a")
    );
    const endTime = convertTime12to24(
      moment(eventInfo.endDate).format("hh:mm a")
    );
    if (!e.target.value) {
      setToError("You should enter an end time");
      return;
    } else if (
      (e.target.value !== "00:00" && e.target.value < startTime) ||
      (e.target.value !== "00:00" &&
        endTime !== "00:00" &&
        e.target.value > endTime)
    ) {
      setToError("To should be between " + startTime + " and " + endTime);
      return;
    } else if (e.target.value !== "00:00" && from && e.target.value <= from) {
      setToError("To should be greater than from");
      return;
    } else {
      setToError("");
    }
  };

  useEffect(() => {
    if (from && to) {
      const startDate = new Date(eventInfo.startDate);
      let fromDate = new Date(
        startDate.setHours(from.substring(0, 2), from.substring(3, 5), 0)
      );
      const endDate = new Date(
        to === "00:00" ? eventInfo.endDate : eventInfo.startDate
      );
      let toDate = new Date(
        endDate.setHours(to.substring(0, 2), to.substring(3, 5))
      );
      const dayEnd = dayjs(toDate);
      const dayStart = dayjs(fromDate);
      const dur = dayEnd.diff(dayStart, "hours", true);
      setEventInfo({
        ...eventInfo,
        start: fromDate,
        end: toDate,
        totalPrice: (dur * expertInfo).toFixed(2),
        numHours: NumToTime(dur),
      });
    }
  }, [from, to]);

  return (
    <Box mt={{ md: "16px", xl: "16px" }}>
      {typeof eventInfo !== "undefined" && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Book expert</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection={"column"}>
              {step === 1 && (
                <>
                  {" "}
                  <SimpleGrid spacing={1} mb="18px">
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems="center"
                    >
                      <Text>Hourly price</Text>
                      <Box>
                        <Text>{`${expertInfo} TND`}</Text>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems="center"
                    >
                      <Text>Number of Hours</Text>
                      <Box>
                        <Text>{eventInfo.numHours}</Text>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems="center"
                    >
                      <Text>Total Price</Text>
                      <Box>
                        <Text>{`${eventInfo.totalPrice} TND`}</Text>
                      </Box>
                    </Box>
                  </SimpleGrid>
                  <Flex flexDirection={"column"}>
                    <Text>Title</Text>
                    <Input
                      onChange={(e) => {
                        setEventInfo({ ...eventInfo, title: e.target.value });
                      }}
                      placeholder="Title"
                    />
                    {titleError && <Text color="red">{titleError}</Text>}

                    <Text>Note</Text>
                    <Textarea
                      onChange={(e) => {
                        setEventInfo({
                          ...eventInfo,
                          description: e.target.value,
                        });
                      }}
                      placeholder="Leave a note to the expert"
                    />
                    <label for="meeting-time">
                      Choose a time for your appointment:
                    </label>
                    <Text>From</Text>
                    <Input
                      placeholder="From"
                      size="md"
                      type="time"
                      onChange={(e) => {
                        setFrom(e.target.value);
                      }}
                      onBlur={onBlurFromHandler}
                    />
                    {fromError && <Text color="red">{fromError}</Text>}
                    <Text>To</Text>
                    <Input
                      placeholder="To"
                      size="md"
                      type="time"
                      onChange={(e) => setTo(e.target.value)}
                      onBlur={onBlurToHandler}
                    />
                    {toError && <Text color="red">{toError}</Text>}
                    <Flex mt="2" direction={"column"}>
                      <SwitchField
                        reversed={true}
                        fontSize="sm"
                        id="6"
                        label="Online"
                        isChecked={online}
                        onChange={() => {
                          setOnline((c) => (c ? false : true));
                        }}
                      />
                    </Flex>
                    <Flex mt="2" direction={"column"}>
                      {!online && (
                        <>
                          <Input
                            isInvalid={emplacementError}
                            type="text"
                            placeholder="Emplacement"
                            id="emplacement"
                            name="emplacement"
                            value={emplacement}
                            onChange={(ev) => setEmplacement(ev.target.value)}
                          />
                          {emplacementError && (
                            <Text mt="3" color="red">
                              {emplacementError}
                            </Text>
                          )}
                        </>
                      )}

                      {online && (
                        <>
                          <Input
                            isInvalid={urlError}
                            type="text"
                            placeholder="Link"
                            id="link"
                            name="link"
                            value={url}
                            onChange={(ev) => setUrl(ev.target.value)}
                          />
                          {urlError && (
                            <Text mt="3" color="red">
                              {urlError}
                            </Text>
                          )}
                        </>
                      )}
                    </Flex>
                  </Flex>
                </>
              )}
              {step === 2 && (
                <ChooseProject
                  project={project}
                  setProject={setProject}
                  setStep={setStep}
                  projectError={projectError}
                />
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                variant="outline"
                mr={3}
                onClick={() => (step === 2 ? setStep(1) : onClose())}
              >
                {step === 1 ? "Precedent" : "Close"}
              </Button>

              <Button
                onClick={() => {
                  if (step === 1) {
                    setStep(2);
                  } else {
                    postEvent();
                  }
                }}
                colorScheme="blue"
              >
                {step === 1 ? "Next" : "Submit"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <ToastContainer />
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex justifyContent={"space-between"}>
          <FullCalendar
            plugins={[timeGridPlugin]}
            initialView="timeGridWeek"
            events={events}
            selectable={true}
            eventClick={handleEventClick}
          />
          {/* <Flex ml="5" direction={"column"}>
            <Box
              px="20px"
              py="22px"
              borderRadius={"30px"}
              backgroundColor={"white"}
            >
              <Text fontSize={"24px"} fontWeight={"700"} color="#1B2559">
                Timeline
              </Text>
              <Text fontSize={"16px"} fontWeight={"500"} color="#A3AED0">
                See your products in our timelines:
              </Text>
            </Box>
          </Flex> */}
        </Flex>
      </Box>
    </Box>
  );
};

export default ExpertCalendar;
