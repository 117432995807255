import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signUpService } from "../services/signUpService";

export const signUpUser = createAsyncThunk(
  "user/signUp",
  async (userData, thunkAPI) => {
    try {
      const { status, data } = await signUpService(userData.userData,userData.params);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  signup: {
    status: "idle",
    data: {},
    error: {},
  },
};
const SignUpSlice = createSlice({
  name: "signup-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [signUpUser.pending.type]: (state) => {
      state.signup = {
        status: "loading",
        data: {
          email: "",
          name: "",
        },
        error: {},
      };
    },
    [signUpUser.fulfilled.type]: (state, action) => {
      state.signup = {
        status: "success",
        data: {
          name: action.payload.name,
          email: action.payload.email,
        },

        error: {},
      };
    },
    [signUpUser.rejected.type]: (state, action) => {
      state.signup = {
        status: "failed",
        data: {
          email: "",
          name: "",
        },
        error: action.payload.message ? action.payload.message : "",
      };
    },
  },
});

export default SignUpSlice.reducer;
