import { Box, Text, VStack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate }) => {
  const [isExpired, setIsExpired] = useState(false);
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      setIsExpired(true);
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <VStack spacing={1} align="center" mt={2}>
      <Box display="flex" justifyContent="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={"center"}
          ml={2}
        >
          {" "}
          <Box
            width="50px"
            border={isExpired ? "2px solid #f56565" : "2px solid #4299e1"}
            p={2}
            borderRadius="md"
            textAlign="center"
          >
            <Text fontSize="lg" color={isExpired ? "red.400" : "blue.400"}>
              {timeLeft.days}
            </Text>
          </Box>
          <Text fontSize="xs" color={isExpired ? "red.400" : "blue.400"}>
            Days
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={"center"}
          ml={2}
        >
          {" "}
          <Box
            width="50px"
            border={isExpired ? "2px solid #f56565" : "2px solid #4299e1"}
            p={2}
            borderRadius="md"
            textAlign="center"
          >
            <Text fontSize="lg" color={isExpired ? "red.400" : "blue.400"}>
              {timeLeft.hours}
            </Text>
          </Box>
          <Text fontSize="xs" color={isExpired ? "red.400" : "blue.400"}>
            Hours
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={"center"}
          ml={2}
        >
          <Box
            width="50px"
            border={isExpired ? "2px solid #f56565" : "2px solid #4299e1"}
            p={2}
            borderRadius="md"
            textAlign="center"
          >
            <Text fontSize="lg" color={isExpired ? "red.400" : "blue.400"}>
              {timeLeft.minutes}
            </Text>
          </Box>
          <Text fontSize="xs" color={isExpired ? "red.400" : "blue.400"}>
            Minutes
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={"center"}
          ml={2}
        >
          {" "}
          <Box
            width="50px"
            border={isExpired ? "2px solid #f56565" : "2px solid #4299e1"}
            p={2}
            borderRadius="md"
            textAlign="center"
          >
            <Text fontSize="lg" color={isExpired ? "red.400" : "blue.400"}>
              {timeLeft.seconds}
            </Text>
          </Box>
          <Text fontSize="xs" color={isExpired ? "red.400" : "blue.400"}>
            Seconds
          </Text>
        </Box>
      </Box>
    </VStack>
  );
};

export default CountdownTimer;
