import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addProject } from "../../../reducers/project";
import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import Stepper from "react-stepper-horizontal";
import ProjectInfoForm from "./components/addProject/ProjectInfoForm";
import ProjectMediaUpload from "./components/addProject/ProjectMediaUpload";
import ProjectMembersForm from "./components/addProject/ProjectMembersForm";
import { useHistory } from "react-router-dom";
import { generateAuthTokenService } from "services/signInService";
import { getCurrentUser } from "reducers/userInformation";
import { useEffect } from "react";
import { getstatisticpages } from "reducers/dashboard";

export default function AddProject() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const steps = [
    { title: "Project details", onClick: () => setActiveStep(0) },
    { title: "Media", onClick: () => setActiveStep(1) },
    { title: "Members", onClick: () => setActiveStep(2) },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [members, setMembers] = useState([]);
  const [image, setImage] = useState([]);
  const [video, setVideo] = useState([]);
  const [projectData, setProjectData] = useState({
    title: "",
    category: "",
    description: "",
    funds: null,
    projectMaturity: "",
    linkedinUrl: "",
    website: "",
    otherLink: "",
  });
  const data = useSelector((state) =>
    state.Dashboard.statisticpages.data
      ? state.Dashboard.statisticpages.data
      : []
  );
  const status = useSelector((state) => state.Project.addProjectbyUser.status);
  const addProjectHandler = async () => {
    const data = new FormData();
    data.append("title", projectData.title);
    data.append("category", projectData.category);
    data.append("description", projectData.description);
    data.append("linkedinUrl", projectData.linkedin);
    data.append("website", projectData.website);
    data.append("otherlink", projectData.otherLink);
    data.append("funds", 0);
    data.append("projectMaturity", projectData.projectMaturity);
    data.append("emails", JSON.stringify(members));
    data.append("image", image[0]);
    data.append("video", video[0]);
    await dispatch(addProject(data))
      .unwrap()
      .then(async (response) => {
        if (response && response.project) {
          toast.success("Project uploaded successfully", {
            position: "top-right",
            hideProgressBar: true,
            theme: "light",
          });
          //If the current user is community we must change token and currentUserData because its role becomes projectHolder
          if (currentUser && currentUser.userType === "community") {
            const { status, data } = await generateAuthTokenService();
            localStorage.setItem("token", data.token);
            localStorage.setItem(
              "CurrentUserData",
              JSON.stringify(data.currentUserData)
            );
            await dispatch(getCurrentUser()).unwrap();
          }
        }
        return response;
      })
      .then((response) => {
        if (response && response.project) {
          history.push("/admin/project/" + response.project._id);
        }
      })
      .catch((error) => {
        toast.info(error.message, {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
      });
  };
  const statisticpagesAction = async () => {
    await dispatch(getstatisticpages("project")).unwrap();
  };
  useEffect(() => {
    statisticpagesAction();
  }, []);
  return (
    <Flex
      flexDirection={"column"}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <Card>
        <Stepper steps={steps} activeStep={activeStep} />
        <div style={{ padding: "20px" }}>
          {activeStep === 0 && (
            <ProjectInfoForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={steps}
              setProjectData={setProjectData}
              projectData={projectData}
            />
          )}
          {activeStep === 1 && (
            <ProjectMediaUpload
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={steps}
              setImage={setImage}
              image={image}
              setVideo={setVideo}
              video={video}
            />
          )}
          {activeStep === 2 && (
            <ProjectMembersForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              members={members}
              setMembers={setMembers}
              addProject={addProjectHandler}
              isLoading={status === "loading"}
            />
          )}
        </div>
      </Card>
    </Flex>
  );
}
