import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Flex,
  Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { applyForCohort } from "reducers/cohort";
import { getProjectsbyUser } from "reducers/project";
import ProjectsSelector from "components/project/ProjectsSelector";
import BasicButton from "components/button/BasicButton";
import { applyForHackathon } from "reducers/hackathon";
import { getavailableprojectsToApply } from "reducers/hackathon";

export default function JoinHackathonModal(props) {
  const { hackathon, isOpen, onClose, onReload, tracks } = props;
  const dispatch = useDispatch();
  const [track, setTrack] = useState("");
  const [page, setPage] = useState(1);
  const [projectsError, setProjectsError] = useState("");
  const [trackError, setTrackError] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const currentUserProjects = useSelector((state) =>
    state.Hackathon.availableprojects.data
      ? state.Hackathon.availableprojects.data.projects
      : null
  );
  const totalPages = useSelector(
    (state) => state.Hackathon.availableprojects.data.totalPages
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  useEffect(() => {
    if (currentUser) {
      const getconfirmedProjects = {
        id: hackathon._id,
        page: page,
        limit: 4,
      };
      dispatch(getavailableprojectsToApply(getconfirmedProjects));
    }
  }, [page, currentUser]);

  useEffect(() => {
    if (hackathon.general) {
      setTrack("general track");
    }
    if (currentUserProjects && currentUserProjects.length) {
      let x = [];
      currentUserProjects.map((p) => {
        if (hackathon.projects && !hackathon.projects.includes(p._id)) {
          x.push(p);
        }
      });
      setProjects(x);
    }
  }, [hackathon, currentUserProjects]);

  const applyForHackathonAction = async () => {
    if (!track) {
      setTrackError("You should select a track!");
      return;
    }
    if (!selectedProjects.length) {
      setProjectsError("You should select a project!");
      return;
    }
    console.log(hackathon,track,'aoaaoaoaoaoaoo')
    const ids = selectedProjects.map((item) => item._id);
    const dataApply = {
      idHackathon: hackathon._id,
      idProject: ids,
      idTrack: track == "general track" ? hackathon.tracks[0] :track,
    };
    await dispatch(applyForHackathon(dataApply)).unwrap();
    onReload();
    onClose();
  };

  return (
    <Modal
      size="yo"
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      key={hackathon._id}
    >
      <ModalOverlay />
      <ModalContent w="65rem">
        <ModalHeader>Join Hackathon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {hackathon.general && (
            <Text fontWeight="300" mb="1rem">
              To join this hackathon, you need to choose one or more of your
              projects.
            </Text>
          )}
          {!hackathon.general && (
            <Text fontWeight="300" mb="1rem">
              To join this hackathon, you need to choose a track and one or more
              of your projects.
            </Text>
          )}
          <Card p="2" direction="column" w="100%">
            <ToastContainer />
            {!hackathon.general && (
              <>
                <Flex justifyContent={"flex-end"}>
                  <Select
                    mb="3"
                    onChange={(ev) => {
                      setTrack(ev.target.value);
                    }}
                    name="track"
                    id="track"
                    borderColor={"#E0E5F2"}
                    width="100%"
                  >
                    <option value={""} key={"track"}>
                      {"Choose a track"}
                    </option>
                    {tracks &&
                      tracks.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.title}
                        </option>
                      ))}
                  </Select>
                </Flex>
                {trackError && (
                  <Text mb="2" color="red">
                    {trackError}
                  </Text>
                )}
              </>
            )}
            <ProjectsSelector
              projects={projects}
              error={projectsError}
              page={page}
              setPage={setPage}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              totalPages={totalPages}
            />
            <Flex justifyContent="end" mt="4">
              <BasicButton title={"Apply"} onClick={applyForHackathonAction} />
            </Flex>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
