import React from "react";
import {
  Box,
  Heading,
  Text,
  Icon,
  Container,
  Badge,
  Flex,
} from "@chakra-ui/react";
import HeadingTitle from "../HeadingTitle";

import { GiCrystalGrowth } from "react-icons/gi";
import { AiFillThunderbolt } from "react-icons/ai";
import { RiAwardLine } from "react-icons/ri";
import { IoPeopleSharp } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Values = () => {
  const history = useHistory();

  return (
    <>
      <HeadingTitle
        badgeTitle={"Values"}
        title={"A software that put forward your values !"}
        subTitle={
          "            Build strong relationships with your ecosystem partners (entrepreneurs, investors, coaches, judges, donors…)"
        }
        bgColor={"white"}
      />

      <Flex justify="center" flexWrap="wrap" maxW="800px" mx="auto">
        <Box
          p="2rem"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          m="1rem"
          flex="1"
          textAlign="center"
          transition="transform 0.2s ease-in-out" // Smooth transition for scale
          _hover={{
            transform: "scale(1.05)", // Scales up the box by 5% on hover
          }}
        >
          <Icon as={GiCrystalGrowth} color="blue.500" boxSize={8} mb="1rem" />
          <Heading as="h2" fontSize="1.5rem" fontWeight="bold" mb="1rem">
            Innovation
          </Heading>
          <Text fontSize="1rem">
            Fostering a culture of innovation by providing platforms for
            hackathons where creativity thrives and groundbreaking solutions
            emerge.
          </Text>
        </Box>
        <Box
          p="2rem"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          m="1rem"
          flex="1"
          textAlign="center"
          transition="transform 0.2s ease-in-out" // Smooth transition for scale
          _hover={{
            transform: "scale(1.05)", // Scales up the box by 5% on hover
          }}
        >
          <Icon
            as={AiFillThunderbolt}
            color="green.500"
            boxSize={8}
            mb="1rem"
          />
          <Heading as="h2" fontSize="1.5rem" fontWeight="bold" mb="1rem">
            Empowerment
          </Heading>
          <Text fontSize="1rem">
            Offering grants and assistance programs to empower individuals and
            organizations, enabling them to turn their ideas into impactful
            projects that drive positive change.
          </Text>
        </Box>
        <Box
          p="2rem"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          m="1rem"
          flex="1"
          textAlign="center"
          transition="transform 0.2s ease-in-out" // Smooth transition for scale
          _hover={{
            transform: "scale(1.05)", // Scales up the box by 5% on hover
          }}
        >
          <Icon as={RiAwardLine} color="purple.500" boxSize={8} mb="1rem" />
          <Heading as="h2" fontSize="1.5rem" fontWeight="bold" mb="1rem">
            Excellence
          </Heading>
          <Text fontSize="1rem">
            Striving for excellence in all aspects of our platform, from the
            quality of hackathons and grants offered to the educational content
            provided through our academy portal, ensuring participants have
            access to the best resources to succeed.
          </Text>
        </Box>
        <Box
          p="2rem"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          m="1rem"
          flex="1"
          textAlign="center"
          transition="transform 0.2s ease-in-out" // Smooth transition for scale
          _hover={{
            transform: "scale(1.05)", // Scales up the box by 5% on hover
          }}
        >
          <Icon as={IoPeopleSharp} color="purple.500" boxSize={8} mb="1rem" />
          <Heading as="h2" fontSize="1.5rem" fontWeight="bold" mb="1rem">
            Community
          </Heading>
          <Text fontSize="1rem">
            Building a supportive community where participants can collaborate,
            learn, and grow through hackathons, assistance programs, and access
            to an academy portal filled with educational resources.
          </Text>
        </Box>
        <Box
          p="2rem"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          m="1rem"
          flex="1"
          textAlign="center"
          transition="transform 0.2s ease-in-out" // Smooth transition for scale
          _hover={{
            transform: "scale(1.05)", // Scales up the box by 5% on hover
          }}
        >
          <Icon as={BsPeopleFill} color="purple.500" boxSize={8} mb="1rem" />
          <Heading as="h2" fontSize="1.5rem" fontWeight="bold" mb="1rem">
            Collaboration
          </Heading>
          <Text fontSize="1rem">
            Collaboration: Facilitating collaborative opportunities among
            participants, fostering a culture of teamwork and knowledge sharing
            across hackathons, grants, assistance programs, and the academy
            portal.
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default Values;
