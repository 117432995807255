import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  Input,
} from "@chakra-ui/react";
import { addAdmin } from "reducers/userInformation";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { checkIsValidEmail } from "utils/functions";

const AddAdminModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const status = useSelector((state) => state.UserInformation.addAdmin.status);

  const validateForm = () => {
    let valid = true;
    if (!firstName) {
      setFirstNameError("First name is required!");
      valid = false;
    } else {
      setFirstNameError("");
    }
    if (!lastName) {
      setLastNameError("Last name is required!");
      valid = false;
    } else {
      setLastNameError("");
    }
    if (!email) {
      setEmailError("Email is required!");
      valid = false;
    } else if (!checkIsValidEmail(email)) {
      setEmailError("Invalid email!");
      valid = false;
    } else {
      setEmailError("");
    }
    return valid;
  };

  const addAdminAction = async () => {
    if (!validateForm()) {
      return;
    }
    const admin = {
      email: email,
      lastName: lastName,
      firstName: firstName,
    };
    await dispatch(addAdmin(admin)).unwrap();
  };

  useEffect(() => {
    if (status === "success") {
      onClose();
      const notify = () =>
        toast.success("Admin has been added successfully!", {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
      notify();
    }
  }, [status]);
  const onCloseHandler = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setEmailError("");
    setLastNameError("");
    setFirstNameError("");
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onCloseHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader id="nvmh">Add Administrator</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction={"column"}>
            <Flex>
              <label className="nvl" style={{ marginRight: "10px" }}>
                First name
                <Input
                  onChange={(e) => setFirstName(e.target.value)}
                  className="nvi"
                />
                {firstNameError && (
                  <span style={{ color: "red" }}>{firstNameError}</span>
                )}
              </label>
              <label className="nvl">
                Last name
                <Input
                  className="nvi"
                  onChange={(e) => setLastName(e.target.value)}
                />
                {lastNameError && (
                  <span style={{ color: "red" }}>{lastNameError}</span>
                )}
              </label>
            </Flex>
            <Flex direction={"column"}>
              <label onChange={(e) => setEmail(e.target.value)} className="nvl">
                Email
                <Input className="nvi" />
                {emailError && (
                  <span style={{ color: "red" }}>{emailError}</span>
                )}
              </label>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => addAdminAction()} colorScheme="blue">
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddAdminModal;
