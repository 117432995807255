import Api from "config/axios/api";
import config from "../config/axios/config";
export const getNewsService = async (limit, page) => {
  const url = config.apiUrl + `/news?page=${page}&limit=${limit}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getDetailsNew = async (idNew) => {
  const url = config.apiUrl + `/news/` + idNew;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const addNewService = async (newData) => {
  const url = config.apiUrl + `/news`;
  try {
    return await Api.post(url, newData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const commentNewService = async (commentData) => {
  const url = config.apiUrl + `/news/` + commentData.id + `/comments`;
  try {
    return await Api.post(
      url,
      { comment: commentData.comment },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const likeNewService = async (id) => {
  const url = config.apiUrl + `/news/` + id + `/likes`;
  try {
    return await Api.post(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const editNewsService = async (dataUpdated) => {
  const url = config.apiUrl + "/news/" + dataUpdated.id;
  try {
    return await Api.put(url, dataUpdated.data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const deleteNewService = async (id) => {
  const url = config.apiUrl + "/news/" + id;
  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getNewsService,
  addNewService,
  getDetailsNew,
  commentNewService,
  likeNewService,
  editNewsService,
  deleteNewService,
};
