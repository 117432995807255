import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCohortCreationCountByMonthService } from "services/dashboardService";
import { getReviewCountByMonthService } from "services/dashboardService";
import { topvotedcohortService } from "services/dashboardService";
import { getstatisticpagesService } from "services/dashboardService";
import { getStaticsEcoSystemMembersService } from "services/dashboardService";
import { gettopcategorymeetService } from "services/dashboardService";
import { getvotesessionCountByMonthService } from "services/dashboardService";
import { getuserAccountCreationCountByMonthService } from "services/dashboardService";
import { getprojectperMonthService } from "services/dashboardService";
import { getStaticsService } from "services/dashboardService";

export const getStatics = createAsyncThunk(
  "dashboard/getStatics",
  async (thunkAPI) => {
    try {
      const { status, data } = await getStaticsService();
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getStaticsEcoSystemMembers = createAsyncThunk(
  "dashboard/getStaticsEcoSystemMembers",
  async (thunkAPI) => {
    try {
      const { status, data } = await getStaticsEcoSystemMembersService();
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getprojectperMonth = createAsyncThunk(
  "dashboard/getprojectperMonth",
  async (yearMonth, thunkAPI) => {
    try {
      const { status, data } = await getprojectperMonthService(
        yearMonth.monthyear
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getuserAccountCreationCountByMonth = createAsyncThunk(
  "dashboard/getuserAccountCreationCountByMonth",
  async (yearMonth, thunkAPI) => {
    try {
      const { status, data } = await getuserAccountCreationCountByMonthService(
        yearMonth.monthyear
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getCohortCreationCountByMonth = createAsyncThunk(
  "dashboard/getCohortCreationCountByMonth",
  async (yearMonth, thunkAPI) => {
    try {
      const { status, data } = await getCohortCreationCountByMonthService(
        yearMonth.monthyear
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getvotesessionCountByMonth = createAsyncThunk(
  "dashboard/getvotesessionCountByMonth",
  async (yearMonth, thunkAPI) => {
    try {
      const { status, data } = await getvotesessionCountByMonthService(
        yearMonth.monthyear
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getReviewCountByMonth = createAsyncThunk(
  "dashboard/getReviewCountByMonth",
  async (yearMonth, thunkAPI) => {
    try {
      const { status, data } = await getReviewCountByMonthService(
        yearMonth.monthyear
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const gettopcategorymeet = createAsyncThunk(
  "dashboard/gettopcategorymeet",
  async (yearMonth, thunkAPI) => {
    try {
      const { status, data } = await gettopcategorymeetService();
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const gettopvotedcohort = createAsyncThunk(
  "dashboard/topvotedcohort",
  async (yearMonth, thunkAPI) => {
    try {
      const { status, data } = await topvotedcohortService();
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getstatisticpages = createAsyncThunk(
  "dashboard/statisticpages",
  async (page, thunkAPI) => {
    try {
      const { status, data } = await getstatisticpagesService(page);
      if (status === 200 || status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  statics: {
    status: "idle",
    data: {},
    error: {},
  },
  staticsEcoSystemMembers: {
    status: "idle",
    data: {},
    error: {},
  },
  projectperMonth: {
    status: "idle",
    data: {},
    error: {},
  },
  accountCreationCountByMonth: {
    status: "idle",
    data: {},
    error: {},
  },
  CohortCreationCountByMonth: {
    status: "idle",
    data: {},
    error: {},
  },
  votesessionCountByMonth: {
    status: "idle",
    data: {},
    error: {},
  },
  ReviewCountByMonth: {
    status: "idle",
    data: {},
    error: {},
  },
  topcategorymeet: {
    status: "idle",
    data: {},
    error: {},
  },
  topvotedcohort: {
    status: "idle",
    data: {},
    error: {},
  },
  statisticpages: {
    status: "idle",
    data: {},
    error: {},
  },
};
const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getstatisticpages.pending.type]: (state) => {
      state.statisticpages = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getstatisticpages.fulfilled.type]: (state, action) => {
      state.statisticpages = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getstatisticpages.rejected.type]: (state, action) => {
      state.statisticpages = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getStatics.pending.type]: (state) => {
      state.statics = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getStatics.fulfilled.type]: (state, action) => {
      state.statics = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getStatics.rejected.type]: (state, action) => {
      state.statics = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getStaticsEcoSystemMembers.pending.type]: (state) => {
      state.staticsEcoSystemMembers = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getStaticsEcoSystemMembers.fulfilled.type]: (state, action) => {
      state.staticsEcoSystemMembers = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getStaticsEcoSystemMembers.rejected.type]: (state, action) => {
      state.staticsEcoSystemMembers = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getprojectperMonth.pending.type]: (state) => {
      state.projectperMonth = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getprojectperMonth.fulfilled.type]: (state, action) => {
      state.projectperMonth = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getprojectperMonth.rejected.type]: (state, action) => {
      state.projectperMonth = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getuserAccountCreationCountByMonth.pending.type]: (state) => {
      state.accountCreationCountByMonth = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getuserAccountCreationCountByMonth.fulfilled.type]: (state, action) => {
      state.accountCreationCountByMonth = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getuserAccountCreationCountByMonth.rejected.type]: (state, action) => {
      state.accountCreationCountByMonth = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getCohortCreationCountByMonth.pending.type]: (state) => {
      state.CohortCreationCountByMonth = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getCohortCreationCountByMonth.fulfilled.type]: (state, action) => {
      state.CohortCreationCountByMonth = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getCohortCreationCountByMonth.rejected.type]: (state, action) => {
      state.CohortCreationCountByMonth = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getvotesessionCountByMonth.pending.type]: (state) => {
      state.votesessionCountByMonth = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getvotesessionCountByMonth.fulfilled.type]: (state, action) => {
      state.votesessionCountByMonth = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getvotesessionCountByMonth.rejected.type]: (state, action) => {
      state.votesessionCountByMonth = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getReviewCountByMonth.pending.type]: (state) => {
      state.ReviewCountByMonth = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getReviewCountByMonth.fulfilled.type]: (state, action) => {
      state.ReviewCountByMonth = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getReviewCountByMonth.rejected.type]: (state, action) => {
      state.ReviewCountByMonth = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [gettopcategorymeet.pending.type]: (state) => {
      state.topcategorymeet = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [gettopcategorymeet.fulfilled.type]: (state, action) => {
      state.topcategorymeet = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [gettopcategorymeet.rejected.type]: (state, action) => {
      state.topcategorymeet = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [gettopvotedcohort.pending.type]: (state) => {
      state.topvotedcohort = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [gettopvotedcohort.fulfilled.type]: (state, action) => {
      state.topvotedcohort = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [gettopvotedcohort.rejected.type]: (state, action) => {
      state.topvotedcohort = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default DashboardSlice.reducer;
