import React from "react";
import { Box, Select } from "@chakra-ui/react";
import Card from "components/card/Card";
import { sectors } from "utils/constants";

export default function ProjectFilters(props) {
  const { setCategories } = props;
  return (
    <Box p={6}>
      <Card width={{ base: "100%", md: "100%", lg: "100%" }}>
        <Select
          justifyContent={"end"}
          onChange={(ev) => {
            setCategories(ev.target.value);
          }}
          name="category"
          id="category"
          borderColor={"#E0E5F2"}
          background={"white"}
          color={"black"}
        >
          {sectors.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </Select>
      </Card>
    </Box>
  );
}
