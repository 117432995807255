import Api from "config/axios/api";
import config from "../config/axios/config";

export const deleteTrackService = async (id) => {
  const url = config.apiUrl + "/track/" + id;

  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getHackathonTracksTitlesService = async (filterData) => {
  const url = config.apiUrl + "/hackathons/" + filterData.hackathonId + "/tracks/";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateTrackService = async (updatedata) => {
  const url = config.apiUrl + "/admin/updatetrack/" + updatedata.id;
  try {
    return await Api.put(url, updatedata, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getVotersByTrackService = async (filterData) => {
  const url = config.apiUrl + "/cohort/idsUsersByIdTrack/" + filterData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getTrackByIdService = async (id) => {
  const url = config.apiUrl + "/track/" + id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const createTrackService = async (TrackData) => {
  const url = config.apiUrl + `/track/`;
  try {
    return await Api.post(url, TrackData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getProjectsByTrackService = async (trackData) => {
  const url = config.apiUrl + "/track/projects/" + trackData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: trackData,

    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  deleteTrackService,
  getHackathonTracksTitlesService,
  updateTrackService,
  getVotersByTrackService,
  createTrackService,
  getTrackByIdService,
};
