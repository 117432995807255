import React, { useState } from "react";
import {
  Input,
  Button,
  FormLabel,
  FormControl,
  Textarea,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const AddTrackForm = (props) => {
  const { trackData, activeStep, setActiveStep, setTrackData } = props;

  const [title, setTitle] = useState(trackData.title);
  const [description, setDescription] = useState(trackData.description);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [err, setErr] = useState("");

  const validForm = () => {
    let valid = true;
    const date = new Date();

    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }

    return valid;
  };
  const next = () => {
    if (!validForm()) {
      return;
    }
    const data = {
      title: title,
      description: description,
    };
    setTrackData(data);
    setActiveStep(activeStep + 1);
  };
  return (
    <Flex direction={"column"} p="4">
      <FormControl>
        <FormLabel>
          Title <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Input
          type="text"
          value={title}
          placeholder="Title"
          onChange={(e) => setTitle(e.target.value)}
        />
        {titleError && <Text color="red">{titleError}</Text>}
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>
          Description <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />
        {descriptionError && <Text color="red">{descriptionError}</Text>}
      </FormControl>
      {/* <FormControl mt={4}>
        <FormLabel>
          Start date <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Input
          type="date"
          value={start}
          placeholder="start"
          onChange={(e) => setStart(e.target.value)}
        />
        {startError && <Text color="red">{startError}</Text>}
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>
          End date <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Input
          placeholder="Last name"
          type="date"
          value={end}
          onChange={(e) => setEnd(e.target.value)}
        />
        {endError && <Text color="red">{endError}</Text>}
      </FormControl> */}
      {/* <FormControl mt={4}>
        <FormLabel>
          End date submission <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Input
          type="date"
          value={endDateSubmission}
          onChange={(e) => setEndDateSubmission(e.target.value)}
        />
        {endDateSubmissionError && (
          <Text color="red">{endDateSubmissionError}</Text>
        )}
      </FormControl> */}

      {err && (
        <Flex mt="3">
          <Text color="red">{err}</Text>
        </Flex>
      )}
      <Flex mt="4" justifyContent={"flex-end"}>
        <Button colorScheme={"blue"} onClick={next}>
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default AddTrackForm;
