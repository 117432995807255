import React from "react";
import { Text, Flex, Divider, Box } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { useSelector } from "react-redux";

export default function WhatToSubmit(props) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const programPhase = useSelector(
    (state) => state.ProgramPhase.programPhaseDetails.data
  );
  return (
    <Flex direction={"column"} color={textColor}>
      <Text fontWeight="bold" mt="3">
        What to submit
      </Text>
      <Divider my="2" />
      <Flex direction={"column"}>
        {programPhase.whatToSubmit &&
          programPhase.whatToSubmit.map((item, index) => (
            <Box key={index}>
              <Text fontWeight={"bold"}>{item.title}</Text>
              <Text mb="3">{item.description}</Text>
            </Box>
          ))}
      </Flex>
    </Flex>
  );
}
