import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  Flex,
  Box,
  Image,
  Button,
  Center,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { Link as Linkk } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/pagination/Pagination";
import { getRequestInAssistingPrograms } from "reducers/assistingProgram";
import { updateAssistingProgramApplication } from "reducers/assistingProgram";

export default function AssistinProgramsApplyRequests(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const pendingAssistingProgramsProjects = useSelector((state) =>
    state.AssistingProgram.getRequest.data
      ? state.AssistingProgram.getRequest.data.applications
      : []
  );
  const totalPages = useSelector((state) =>
    state.AssistingProgram.getRequest.data
      ? state.AssistingProgram.getRequest.data.totalPages
      : 0
  );

  const getRequestInAssistingProgramsAction = async () => {
    const filterData = {
      page,
      limit: 4,
      id,
      status: "pending",
    };
    await dispatch(getRequestInAssistingPrograms(filterData)).unwrap();
  };

  useEffect(() => {
    getRequestInAssistingProgramsAction();
    setReload(false);
  }, [id, page, reload]);

  const updateAssistingProgramApplicationAction = async (
    applicationId,
    status
  ) => {
    const updateData = {
      id: applicationId,
      status: status,
    };
    await dispatch(updateAssistingProgramApplication(updateData)).unwrap();
    setReload(true);
  };

  return (
    <Box>
      <Heading size="sm" mt="4" mb="2">
        Requests for apply{" "}
      </Heading>
      <Divider mb="2" />
      {pendingAssistingProgramsProjects &&
        pendingAssistingProgramsProjects.length > 0 && (
          <Card p="2" direction="column" w="100%">
            {pendingAssistingProgramsProjects.map((item) => (
              <Card p="1" mb="2" boxShadow={cardShadow}>
                <Flex>
                  <Flex
                    width="100%"
                    height="100%"
                    padding="0.6rem"
                    fontWeight="bold"
                    color={textColor}
                    fontSize="md"
                    textAlign="start"
                  >
                    <Image
                      h="50px"
                      w="50px"
                      src={
                        config.apiUrl + `/project/file/` + item.project.images
                      }
                      borderRadius="8px"
                      fallbackSrc={placeholder}
                      objectFit="cover"
                    />
                    <Box ml="3">
                      <Text fontWeight="normal" color="blue">
                        <Linkk to={"/admin/project/" + item.project._id}>
                          {item.project.title}
                        </Linkk>
                      </Text>
                      <Text fontSize="sm">{item.project.category}</Text>
                    </Box>
                  </Flex>
                  {item.status === "pending" && (
                    <Flex alignItems={"center"}>
                      <Button
                        colorScheme={"red"}
                        size="sm"
                        mr="2"
                        onClick={() =>
                          updateAssistingProgramApplicationAction(
                            item._id,
                            "rejected"
                          )
                        }
                      >
                        Refuse
                      </Button>
                      <Button
                        colorScheme={"green"}
                        size="sm"
                        onClick={() =>
                          updateAssistingProgramApplicationAction(
                            item._id,
                            "accepted"
                          )
                        }
                      >
                        Accept
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </Card>
            ))}
            {totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={() => {}}
                />
              </Center>
            )}
          </Card>
        )}
      {pendingAssistingProgramsProjects &&
        pendingAssistingProgramsProjects.length === 0 && (
          <Card p="2" direction="column" w="100%">
            <Text>There is no request</Text>
          </Card>
        )}
    </Box>
  );
}
