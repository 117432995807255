import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import config from "config/axios/config";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { getCohortById } from "reducers/cohort";
import HackathonApplyRequests from "./components/hackathonDetails/HackathonApplyRequests";
import Loader from "components/loader/Loader";
import DeletedItemCard from "components/card/DeletedItemCard";
import HackathonTracks from "./components/hackathonDetails/HackathonTracks";
import BannerImage from "components/image/BannerImage";
import HackathonRules from "./components/hackathonDetails/HackathonRules";
import HackathonProjects from "./components/hackathonDetails/HackathonProjects";
import { hackathonProjects } from "reducers/hackathon";
import HackathonOverview from "./components/hackathonDetails/HackathonOverview";
import HackathonParticipants from "./components/hackathonDetails/HackathonParticipants";
import { hackathonParticipants } from "reducers/hackathon";
import { getPendingProjectsByCohort } from "reducers/cohort";
import CurrentUserProjects from "./components/hackathonDetails/CurrentUserProjects";
import { update } from "reducers/hackathon";
import { getHackathonById } from "reducers/hackathon";
import { getPendingProjectsByHackathon } from "reducers/hackathon";
import { currentUserProjects } from "reducers/hackathon";

const HackathonDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState("");
  const [track, setTrack] = useState("");
  const [search, setSearch] = useState(false);
  const [page2, setPage2] = useState(1);
  const [pageMyProjects, setPageMyProjects] = useState(1);

  const [page3, setPage3] = useState(1);
  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState("");

  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const hackathon = useSelector((state) =>
    state.Hackathon.hackathon.data
      ? state.Hackathon.hackathon.data.result
      : null
  );

  const canApply = useSelector((state) =>
    state.Hackathon.hackathon.data.result
      ? state.Hackathon.hackathon.data.result.canApply
      : null
  );
  const intrested = useSelector((state) =>
    state.Hackathon.hackathon.data.result
      ? state.Hackathon.hackathon.data.result.canInterest
      : null
  );
  const hackathonPrize = useSelector((state) =>
    state.Hackathon.hackathon.data.result
      ? state.Hackathon.hackathon.data.result.prize
      : null
  );
  const status = useSelector((state) => state.Hackathon.hackathon.data.status);
  const tracks = useSelector((state) =>
    state.Hackathon.hackathon.data.result
      ? state.Hackathon.hackathon.data.result.track
      : null
  );
  const projects = useSelector((state) =>
    state.Hackathon.hackathonProjects.data
      ? state.Hackathon.hackathonProjects.data.result
      : null
  );
  const totalProjects = useSelector((state) =>
    state.Hackathon.hackathonProjects.data
      ? state.Hackathon.hackathonProjects.data.totalProjects
      : null
  );
  const participants = useSelector((state) =>
    state.Hackathon.hackathonParticipants.data
      ? state.Hackathon.hackathonParticipants.data.participants
      : []
  );
  const totalUser = useSelector((state) =>
    state.Hackathon.hackathonParticipants.data
      ? state.Hackathon.hackathonParticipants.data.participants
      : []
  );
  const totalPages = useSelector((state) =>
    state.Hackathon.hackathonProjects.data
      ? state.Hackathon.hackathonProjects.data.totalPages
      : 0
  );
  const totalPages2 = useSelector((state) =>
    state.Hackathon.hackathonParticipants.data
      ? state.Hackathon.hackathonParticipants.data.totalPages
      : 0
  );
  const currentUserProjectsx = useSelector((state) =>
    state.Hackathon.currentUserProjects.data
      ? state.Hackathon.currentUserProjects.data.projects
      : []
  );
  const currentUserProjectsTotalePage = useSelector((state) =>
    state.Hackathon.currentUserProjects.data
      ? state.Hackathon.currentUserProjects.data.totalProjects
      : []
  );
  const getHackathonByIdAction = async () => {
    await dispatch(getHackathonById(id)).unwrap();
  };
  const hackathonProjectsAction = async () => {
    const data = {
      id: id,
      page: page,
      limit: 6,
      projectTitle: title,
      trackTitle: track,
    };
    await dispatch(hackathonProjects(data)).unwrap();
  };

  const hackathonParticipantsAction = async () => {
    const data = {
      id: id,
      page: page2,
      limit: 4,
    };
    await dispatch(hackathonParticipants(data)).unwrap();
  };

  const currentUserProjectsAction = async () => {
    const data = {
      id: id,
      page: pageMyProjects,
      limit: 6,
    };
    await dispatch(currentUserProjects(data)).unwrap();
  };

  useEffect(() => {
    getHackathonByIdAction();
  }, [id]);
  useEffect(() => {
    hackathonParticipantsAction();
  }, [id, page2]);
  useEffect(() => {
    hackathonProjectsAction();
  }, [id, page]);
  useEffect(() => {
    if (currentUser && currentUser.userType === "ProjectHolder") {
      currentUserProjectsAction();
    }
  }, [id, currentUser, pageMyProjects]);

  useEffect(() => {
    if (reload) {
      getHackathonByIdAction();
      hackathonProjectsAction();
      hackathonParticipantsAction();
      hackathonRequestsAction();
      currentUserProjectsAction();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (search) {
      setPage(1);
      hackathonProjectsAction();
      setSearch(false);
    }
  }, [search]);

  useEffect(() => {
    if (hackathon && currentUser) {
      if (
        hackathon.createdBy === currentUser._id ||
        currentUser.userType === "SuperAdmin"
      ) {
        setCanEdit(true);
      } else {
        setCanEdit(false);
      }
    } else {
      setCanEdit(false);
    }
  }, [hackathon, currentUser]);

  const requests = useSelector((state) =>
    state.Hackathon.pendingHackathonProjects.data
      ? state.Hackathon.pendingHackathonProjects.data.result
      : []
  );
  const totalRequest = useSelector((state) =>
    state.Hackathon.pendingHackathonProjects.data
      ? state.Hackathon.pendingHackathonProjects.data.total
      : []
  );
  const totalPages3 = useSelector((state) =>
    state.Hackathon.pendingHackathonProjects.data
      ? state.Hackathon.pendingHackathonProjects.data.totalPages
      : 0
  );

  const hackathonRequestsAction = async () => {
    const filterData = {
      page:page3,
      limit: 4,
      id,
    };
    await dispatch(getPendingProjectsByHackathon(filterData)).unwrap();
  };
  const updateImageHandler = async () => {
    if (!image.length) {
      setImageError("The image is required!");
      return;
    } else {
      setImageError("");
      const data = new FormData();
      data.append("image", image[0]);
      const hackathonData = {
        data,
        id: hackathon._id,
      };
      await dispatch(update(hackathonData)).unwrap();
      setReload(true);
    }
  };

  useEffect(() => {
    hackathonRequestsAction();
  }, [id, page3]);

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {!hackathon && status === "loading" && <Loader />}
        {!hackathon && status === "failed" && (
          <DeletedItemCard item={"Hackathon"} />
        )}
        {hackathon && (
          <>
            <BannerImage
              src={config.apiUrl + `/hackathons/file/` + hackathon.image}
              h={"180px"}
              canEdit={canEdit}
              image={image}
              setImage={setImage}
              imageError={imageError}
              onEdit={updateImageHandler}
            />
            <Tabs isManual variant="enclosed">
              <TabList>
                <Tab _selected={{ bg: "white" }}>Overview</Tab>
                <Tab _selected={{ bg: "white" }}>Rules </Tab>
                {!hackathon.general && (
                  <Tab _selected={{ bg: "white" }}>
                    Tracks ({tracks ? tracks.length : 0})
                  </Tab>
                )}
                {currentUser.userType === "ProjectHolder" && (
                  <Tab _selected={{ bg: "white" }}>
                    My Projects (
                    {currentUserProjectsTotalePage
                      ? currentUserProjectsTotalePage
                      : 0}
                    )
                  </Tab>
                )}
                <Tab _selected={{ bg: "white" }}>
                  Projects ({totalProjects ? totalProjects : 0})
                </Tab>
                <Tab _selected={{ bg: "white" }}>
                  Participants ({totalUser ? totalUser.length : 0})
                </Tab>
                {canEdit && (
                  <Tab _selected={{ bg: "white" }}>
                    Requests ({totalRequest ? totalRequest : 0})
                  </Tab>
                )}
              </TabList>
              <TabPanels backgroundColor={"white"} pr="2" pl="2">
                <TabPanel>
                  <HackathonOverview
                    hackathon={hackathon}
                    hackathonPrize={hackathonPrize}
                    canEdit={canEdit}
                    canApply={canApply}
                    intrested={intrested}
                    onReload={() => setReload(true)}
                    nbParticipants={participants ? participants.length : 0}
                  />
                </TabPanel>
                <TabPanel>
                  <HackathonRules
                    hackathon={hackathon}
                    onReload={() => setReload(true)}
                    canEdit={canEdit}
                  />
                </TabPanel>
                {!hackathon.general && (
                  <TabPanel>
                    <HackathonTracks
                      canEdit={canEdit}
                      onReload={() => setReload(true)}
                      tracks={tracks}
                      hackathon={hackathon}
                    />
                  </TabPanel>
                )}
                {currentUser.userType === "ProjectHolder" && (
                  <TabPanel>
                    <CurrentUserProjects
                      projects={currentUserProjectsx}
                      onReload={() => setReload(true)}
                      showSubmit={true}
                      totalPages={totalPages}
                      page={pageMyProjects}
                      setPage={setPageMyProjects}
                    />
                  </TabPanel>
                )}
                <TabPanel>
                  <HackathonProjects
                    hackathon={hackathon}
                    projects={projects}
                    onReload={() => setReload(true)}
                    showFilter={true}
                    totalPages={totalPages}
                    page={page}
                    setPage={setPage}
                    title={title}
                    setTitle={setTitle}
                    track={track}
                    setTrack={setTrack}
                    onSearch={() => setSearch(true)}
                    hackathonId={id}
                  />
                </TabPanel>
                <TabPanel>
                  <HackathonParticipants
                    id={id}
                    participants={participants}
                    onReload={() => setReload(true)}
                    totalPages={totalPages2}
                    page={page2}
                    setPage={setPage2}
                  />
                </TabPanel>
                {requests && canEdit && (
                  <TabPanel>
                    <HackathonApplyRequests
                      id={id}
                      onReload={() => setReload(true)}
                      totalPages={totalPages3}
                      page={page3}
                      setPage={setPage3}
                      requests={requests}
                    />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </>
        )}
      </Box>
    </>
  );
};

export default HackathonDetails;
