import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Heading,
  Spinner,
  Text,
  Box,
  Center,
  Select,
  Checkbox,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "components/pagination/Pagination";
import { getallProjects } from "reducers/project";
import ProjectItem from "./ProjectItem";
import { hackathonProjects } from "reducers/hackathon";

import { sectors } from "utils/constants";
import { addMeetingGeneralInHackathon } from "reducers/meeting";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import InputField from "components/fields/InputField";
import { getHackathonTracksTitles } from "reducers/track";
import BasicButton from "components/button/BasicButton";

const ChooseProject = (props) => {
  const { meetingData, setMeetingData, setStep, onClose } = props;
  const dispatch = useDispatch();
  const [categories, setCategories] = useState("");
  const [project, setProject] = useState(meetingData.projectId);
  const [projectError, setProjectError] = useState("");
  const statusAdd = useSelector(
    (state) => state.IndividualTraining.addMeet.status
  );
  const error = useSelector((state) => state.IndividualTraining.addMeet.error);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [addConfirm, setAddConfirm] = useState(false);
  const [title, setTitle] = useState("");

  const totalPages = useSelector((state) =>
    state.Hackathon.hackathonProjects.data
      ? state.Hackathon.hackathonProjects.data.totalPages
      : null
  );
  const status = useSelector((state) => state.Project.projects.status);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const { id } = useParams();
  const projects = useSelector((state) =>
    state.Hackathon.hackathonProjects.data
      ? state.Hackathon.hackathonProjects.data.result
      : null
  );
  const validForm = () => {
    let valid = true;
    if (!selectedProjects) {
      setProjectError("Project is required!");
      valid = false;
    } else {
      setProjectError("");
    }
    return valid;
  };

  const confirm = async () => {
    setAddConfirm(true);
    if (!validForm()) {
      return;
    }
    console.log(selectedProjects, "lalalalala");
    const data = {
      ...meetingData,
      projects: selectedProjects,
      hackathonId: id,
    };
    setMeetingData(data);
    await dispatch(addMeetingGeneralInHackathon(data)).then(() => {
      onClose();
    });
  };

  const back = () => {
    setStep(2);
    setProject("");
    setAddConfirm(false);
    const data = { ...meetingData, projectId: "" };
    setMeetingData(data);
  };

  const notify = () =>
    toast.success("Meeting was added successfully", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });

  useEffect(() => {
    if (statusAdd === "success") {
      notify();
    }
    if (statusAdd === "failed" && error && addConfirm) {
      toast.error(error, {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
    }
  }, [statusAdd, error, addConfirm]);
  const [track, setTrack] = useState("");

  const onSearch = async () => {
    const data = {
      id: id,
      page: page,
      limit: 6,
      projectTitle: title,
      trackTitle: track,
    };
    await dispatch(hackathonProjects(data)).unwrap();
  };

  const toggleProject = (projectId) => {
    setSelectedProjects((prev) =>
      prev.includes(projectId)
        ? prev.filter((id) => id !== projectId)
        : [...prev, projectId]
    );
  };

  const handleSelectAll = () => {
    if (selectedProjects.length === projects.length) {
      setSelectedProjects([]);
    } else {
      setSelectedProjects(projects.map((project) => project._id));
    }
  };

  const tracks = useSelector((state) =>
    state.Track.tracksByHackathon.data
      ? state.Track.tracksByHackathon.data.result
      : []
  );
  const getTracksByHackathonAction = async () => {
    const data = {
      hackathonId: id,
    };
    await dispatch(getHackathonTracksTitles(data));
  };
  useEffect(() => {
    if (id) {
      getTracksByHackathonAction();
    }
  }, [id]);
  return (
    <Card>
      <ToastContainer />
      <Heading size="sm" mt="4" mb="2">
        Choose a project
      </Heading>
      <Flex mb="4" alignItems={"center"}>
        {" "}
        <InputField
          label=""
          placeholder="Search by Title"
          value={title}
          setValue={setTitle}
          required={false}
        />
        {/* <Select
              mt="3"
              mb="3"
              onChange={(ev) => {
                setCategories(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              width="100%"
            >
              {sectors.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </Select> */}
        {tracks && (
          <Select
            onChange={(ev) => {
              setTrack(ev.target.value);
            }}
            name="track"
            id="track"
            borderColor={"#E0E5F2"}
            width="100%"
            mr="2"
            placeholder="Search By Track"
            className="custom-select"
            value={track}
            styles={{
              menu: (provided) => ({
                ...provided,
                color: "gray.500", // Change the color to your desired color
              }),
              option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "white" : "gray.500", // Change the color to your desired color
                backgroundColor: state.isSelected ? "blue.500" : "white", // Change the background color when selected
              }),
            }}
          >
            {tracks.map((item, index) => (
              <option value={item.title} key={index}>
                {item.title}
              </option>
            ))}
          </Select>
        )}
        <BasicButton
          style={{ width: "180px" }}
          title="Search"
          variant="solid"
          onClick={onSearch}
        />
      </Flex>
      {!projects && status === "loading" && (
        <Flex flexDirection="column" mt="3" mb="3">
          <Spinner my="auto!important" mx="auto" />
        </Flex>
      )}
      {projects && !projects.length && (
        <Flex flexDirection="column">
          <Card mt="3" mb="3">
            <Text fontSize={"2xl"} textAlign={"center"}>
              There is no project
            </Text>
          </Card>
        </Flex>
      )}

      {projects && projects.length > 0 && (
        <Flex flexDirection={"column"}>
          <Flex align="center" justifyContent="space-between" p="1">
            <Text fontWeight="bold">
              {selectedProjects.length === projects.length
                ? "Deselect All"
                : "Select All"}
            </Text>
            <Flex align="center" fontWeight="bold" fontSize="md">
              <Checkbox
                colorScheme="brandScheme"
                borderColor="#3965ff"
                isChecked={selectedProjects.length === projects.length}
                onChange={handleSelectAll}
                isIndeterminate={
                  selectedProjects.length > 0 &&
                  selectedProjects.length < projects.length
                }
              />
            </Flex>
          </Flex>
          <Box>
            {projects.map((project) => (
              <ProjectItem
                key={project._id}
                project={project}
                selectedProjects={selectedProjects}
                toggleProject={toggleProject}
              />
            ))}
            {totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={setLoader}
                />
              </Center>
            )}
          </Box>
          {projectError && (
            <Text mt="3" color="red">
              {projectError}
            </Text>
          )}
        </Flex>
      )}
      <Flex mt="4" justifyContent={"flex-end"}>
        <Button colorScheme={"blue"} variant="outline" onClick={back} mr="2">
          Back
        </Button>
        <Button colorScheme={"blue"} onClick={confirm}>
          confirm
        </Button>
      </Flex>
    </Card>
  );
};

export default ChooseProject;
