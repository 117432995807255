import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import MySelect from "react-select";
import { whatToSubmit } from "utils/constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateCohort } from "reducers/cohort";
import { update } from "reducers/hackathon";

const EditHackathonWhatToSubmit = ({
  isOpen,
  onClose,
  initialData,
  onReload,
  idhackathon,
}) => {
  const dispatch = useDispatch();
  const [whatToBuildError, setWhatToBuildError] = useState("");
  const [whatToSubmitError, setWhatToSubmitError] = useState("");
  const [selectedSubmit, setSelectedSubmit] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const validateForm = () => {
    let valid = true;
    if (!initialData) {
      setWhatToBuildError("champ is required!");
      valid = false;
    } else {
      setWhatToBuildError("");
    }
    if (initialData.length === 0) {
      setWhatToSubmitError("champ is required!");
      valid = false;
    } else {
      setWhatToSubmitError("");
    }

    return valid;
  };

  useEffect(() => {
    setSelectedSubmit(
      initialData.map((item) => ({ label: item, value: item }))
    );
  }, [initialData]);
  const editSubmitHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const data = new FormData();
    const selectedSubmitVar = selectedSubmit.map((i) => i.value);

    data.append("submitTypes", JSON.stringify(selectedSubmitVar));

    const hackathonData = {
      data,
      id: idhackathon,
    };
    await dispatch(update(hackathonData)).unwrap();
    onReload();
  };
  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [selectedSubmit, submitted]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit What To Submit</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <FormLabel>
              What to Submit <span style={{ color: "red" }}>*</span>
            </FormLabel>{" "}
            <MySelect
              className="InputGroupy"
              placeholder="What to Submit"
              options={whatToSubmit}
              value={selectedSubmit}
              onChange={setSelectedSubmit}
              isMulti
            />
            {whatToSubmitError && (
              <Text mt="3" color="red">
                {whatToSubmitError}
              </Text>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={editSubmitHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditHackathonWhatToSubmit;
