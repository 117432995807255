import { Text, Box, Flex, Checkbox, Avatar, Badge } from "@chakra-ui/react";
import React from "react";
import Card from "components/card/Card.js";
import { useColorModeValue } from "@chakra-ui/system";
import config from "config/axios/config";
import { obfuscateEmail } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function WideUserCard(props) {
  const {
    user,
    isChecked = false,
    toggleUser = () => {},
    showCheckbox = false,
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const history = useHistory();

  return (
    <Card p="1" mb="2" boxShadow={cardShadow} key={user._id}>
      <Flex>
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
        >
          <Avatar
            w="50px"
            h="50px"
            src={config.apiUrl + "/api/getfile/" + user.Image}
            onClick={() => history.push("/admin/expert/profile/" + user._id)}
          />
          <Box ml="3">
            <Text
              fontWeight="normal"
              onClick={() => history.push("/admin/expert/profile/" + user._id)}
              _hover={{ color: "blue.500", cursor: "pointer" }}
            >
              {user.firstName + " " + user.lastName}
              <Badge ml="1" colorScheme="green">
                {user.userType}
              </Badge>
            </Text>
            <Text fontSize="sm">{obfuscateEmail(user.email)}</Text>
          </Box>
        </Flex>
        {showCheckbox && (
          <Checkbox
            me="16px"
            colorScheme="brandScheme"
            borderColor={"#3965ff"}
            onChange={toggleUser}
            isChecked={isChecked}
          />
        )}
      </Flex>
    </Card>
  );
}
