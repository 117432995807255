import React from "react";
import config from "config/axios/config";
import {
  Flex,
  Text,
  useColorModeValue,
  Box,
  Divider,
  Badge,
  Grid,
  GridItem,
  IconButton,
  Icon,
  Heading,
  Progress,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import placeholder from "assets/img/placeholder.jpg";
import BannerImage from "components/image/BannerImage";
import BasicButton from "components/button/BasicButton";
import {
  FaCalendar,
  FaEdit,
  FaFacebook,
  FaGlobe,
  FaHeart,
  FaLink,
  FaLinkedin,
  FaRegHeart,
  FaTachometerAlt,
  FaTwitter,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import moment from "moment";
import VideoPlayer from "components/video/VideoPlayer";
import ProjectStatus from "../ProjectStatus";
import { joinTeamR } from "reducers/cohort";
import { useDispatch } from "react-redux";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { shareUrl } from "utils/constants";
import { likeProject } from "reducers/project";
import TeamMember from "./TeamMember";
import { useState } from "react";
import { updateProject } from "reducers/project";
import EditDetailsForm from "../editProject/EditDetailsForm";
import EditLinksForm from "../editProject/EditLinksForm";
import TextCard from "components/card/TextCard";

const ProjectInfos = (props) => {
  const dispatch = useDispatch();
  const { project, isOwner, userType, userId, onReload } = props;
  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();
  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState("");
  const [video, setVideo] = useState([]);
  const [videoError, setVideoError] = useState("");
  const getColor = () => {
    if (project.TRL >= 0 && project.TRL < 3) {
      return "red";
    } else if (project.TRL >= 3 && project.TRL < 6) {
      return "yellow";
    } else {
      return "green";
    }
  };
  const joinTeam = async () => {
    await dispatch(joinTeamR(project._id)).unwrap();
    onReload();
  };
  const likeHandler = async () => {
    await dispatch(likeProject(project._id)).unwrap();
    onReload();
  };

  const updateImageHandler = async () => {
    if (!image.length) {
      setImageError("The image is required!");
      return;
    } else {
      setImageError("");
      const data = new FormData();
      data.append("image", image[0]);
      const projectData = {
        data,
        id: project._id,
      };
      await dispatch(updateProject(projectData)).unwrap();
      onReload();
    }
  };
  const updateVideoHandler = async () => {
    if (!video.length) {
      setVideoError("The video is required!");
      return;
    } else {
      setVideoError("");
      const data = new FormData();
      data.append("video", video[0]);
      const projectData = {
        data,
        id: project._id,
      };
      await dispatch(updateProject(projectData)).unwrap();
      onReload();
    }
  };
  return (
    <Flex mt="3" mb="3" direction={"column"}>
      <BannerImage
        src={
          project.images == null
            ? placeholder
            : config.apiUrl + "/project/file/" + project.images
        }
        h={"180px"}
        canEdit={isOwner}
        image={image}
        setImage={setImage}
        imageError={imageError}
        onEdit={updateImageHandler}
      />
      <Flex direction={"column"} m="3">
        <Flex direction={"column"}>
          <Flex justifyContent={"space-between"}>
            <Box>
              <Text
                color={textColor}
                fontSize={"3xl"}
                mr="2"
                fontWeight={"bold"}
              >
                {project.title}{" "}
              </Text>
            </Box>
            <Flex mt="2">
              {(userType === "Admin" || userType === "SuperAdmin") && (
                <ProjectStatus project={project} />
              )}
              {userType === "community" && (
                <BasicButton
                  title={"Join Team"}
                  variant="solid"
                  onClick={joinTeam}
                  icon={FaUsers}
                />
              )}
              <BasicButton
                title={"Like | " + (project.likes ? project.likes.length : 0)}
                icon={project.likes.includes(userId) ? FaHeart : FaRegHeart}
                bg={"#1DA1F2"}
                color="white"
                hover={{ bg: "#0E71C8" }}
                iconColor="white"
                style={{ marginLeft: "10px" }}
                onClick={likeHandler}
              />
            </Flex>
          </Flex>
          <Flex justifyContent={"space-between"} mt="2">
            <Flex width="60%" className="description">
              <Text fontSize={"l"} color={textColor}>
                {project.description}
              </Text>
            </Flex>
            <Flex
              direction={"column"}
              width="40%"
              ml="4"
              fontSize={"md"}
              height="max-content"
              position="relative"
            >
              {isOwner && (
                <Tooltip
                  label="Edit Project Details"
                  hasArrow
                  placement="top-end"
                >
                  <IconButton
                    icon={<FaEdit />}
                    size="sm"
                    colorScheme="blue"
                    position="absolute"
                    top="10px"
                    right="10px"
                    onClick={onOpenModal1}
                  />
                </Tooltip>
              )}
              <Box
                p={4}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                height="100%"
                backgroundColor="white"
                textAlign={"start"}
              >
                {project.createdAt && (
                  <>
                    <Flex direction="column">
                      <Flex
                        fontWeight={"700"}
                        direction="row"
                        alignItems={"center"}
                        color="#11047a"
                      >
                        <FaCalendar />
                        <Text ml="2" fontWeight={"bold"}>
                          Created At
                        </Text>
                      </Flex>
                      <Text fontWeight={"400"}>
                        {moment(project.createdAt).format("llll")}
                      </Text>
                    </Flex>
                  </>
                )}{" "}
                <Flex direction="column" mt="2">
                  <Flex
                    fontWeight={"700"}
                    direction="row"
                    alignItems={"center"}
                    color="#11047a"
                  >
                    <FaUser />
                    <Text ml="2" fontWeight={"bold"}>
                      Created By
                    </Text>
                  </Flex>
                  <Text fontWeight={"400"}>
                    {project.createdby.firstName} {project.createdby.lastName}
                  </Text>
                </Flex>
                {project && project.projectMaturity && (
                  <Flex direction="column" mt="2">
                    <Flex
                      fontWeight={"700"}
                      direction="row"
                      alignItems={"center"}
                      color="#11047a"
                    >
                      <FaTachometerAlt />{" "}
                      <Text ml="2" fontWeight={"bold"}>
                        Project Maturity
                      </Text>
                    </Flex>
                    <Text fontWeight={"400"}>
                      {project.projectMaturity
                        ? project.projectMaturity
                        : "Not exist"}
                    </Text>
                  </Flex>
                )}
                <Divider my="3" />
                <Badge colorScheme="blue" fontSize={"md"} fontWeight={"400"}>
                  {project.category}
                </Badge>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Divider my="4" />
        <Box>
          <Grid
            templateColumns={{ base: "1fr", md: "2fr 1fr", lg: "2fr 1fr" }}
            gap={4}
          >
            <GridItem>
              <VideoPlayer
                videoSrc={config.apiUrl + "/project/file/" + project.video}
                width="100%"
                height="200px"
                canEdit={isOwner}
                video={video}
                setVideo={setVideo}
                videoError={videoError}
                onEdit={updateVideoHandler}
                noVideoMessage="No video available for this project."
              />
            </GridItem>
            <GridItem>
              <Box
                p={4}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                height="100%"
                backgroundColor="white"
                textAlign={"center"}
                display={"flex"}
                flexDirection={"column"}
                alignContent={"center"}
                position="relative"
              >
                {isOwner && (
                  <Tooltip
                    label="Edit Social Media Links"
                    hasArrow
                    placement="top-end"
                  >
                    <IconButton
                      icon={<FaEdit />}
                      size="sm"
                      colorScheme="blue"
                      position="absolute"
                      top="10px"
                      right="10px"
                      onClick={onOpenModal2}
                    />
                  </Tooltip>
                )}
                <Flex justifyContent={"center"} mt="4" mb="2">
                  {project.linkedinUrl && (
                    <Tooltip label="Linkedin" hasArrow placement="top-end">
                      <IconButton
                        mr="10px"
                        onClick={() => {
                          window.open(project.linkedinUrl, "_blank").focus();
                        }}
                        aria-label="Search database"
                        icon={
                          <Icon as={FaLinkedin} w={6} h={6} color="#0077B5" />
                        }
                      />
                    </Tooltip>
                  )}
                  {project.website && (
                    <Tooltip label="WebSite" hasArrow placement="top-end">
                      <IconButton
                        mr="10px"
                        onClick={() => {
                          window.open(project.website, "_blank").focus();
                        }}
                        aria-label="Search database"
                        icon={
                          <Icon as={FaGlobe} w={6} h={6} color="teal.500" />
                        }
                      />
                    </Tooltip>
                  )}
                  {project.otherlink && (
                    <Tooltip label="Other Link" hasArrow placement="top-end">
                      <IconButton
                        mr="10px"
                        onClick={() => {
                          window.open(project.otherlink, "_blank").focus();
                        }}
                        aria-label="Search database"
                        icon={<Icon as={FaLink} w={6} h={6} color="blue.500" />}
                      />
                    </Tooltip>
                  )}
                </Flex>

                <Divider my="2" />
                <TwitterShareButton url={shareUrl + project._id}>
                  <BasicButton
                    title={"Share on Twitter"}
                    mt="2"
                    icon={FaTwitter}
                    bg={"#1DA1F2"}
                    color="white"
                    hover={{ bg: "#0E71C8" }}
                    iconColor="white"
                    style={{ width: "200px" }}
                  />
                </TwitterShareButton>
                <FacebookShareButton
                  url={shareUrl + project._id}
                  style={{ marginRight: "5px" }}
                >
                  <BasicButton
                    title={"Share on Facebook"}
                    mt="2"
                    mb="2"
                    icon={FaFacebook}
                    colorScheme="facebook"
                    _hover={{ bg: "facebook.700" }}
                    iconColor="white"
                    variant="solid"
                    style={{ width: "200px" }}
                  />{" "}
                </FacebookShareButton>
              </Box>
            </GridItem>
          </Grid>
        </Box>
        <Divider my="4" />
        <Flex direction={"column"}>
          <Heading size="md" mb="2">
            Team Members
          </Heading>
          {project.teamMemberEmails &&
            project.teamMemberEmails.length > 0 &&
            project.teamMemberEmails.map((member) => (
              <TeamMember
                member={member}
                canDelete={isOwner}
                projectId={project._id}
                onReload={onReload}
              />
            ))}
          {project.teamMemberEmails &&
            project.teamMemberEmails.length === 0 && (
              <TextCard text="There is no member" />
            )}
        </Flex>
      </Flex>
      <EditDetailsForm
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        initialData={project}
        onReload={onReload}
      />
      <EditLinksForm
        isOpen={isOpenModal2}
        onClose={onCloseModal2}
        initialData={project}
        onReload={onReload}
      />
    </Flex>
  );
};

export default ProjectInfos;
