import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addAvabilityExpert,
  addEventService,
  deleteEventService,
  editEventService,
  getTimeLineCalanderService,
  participateToEventService,
} from "../services/eventService";
import { getEventsService } from "services/eventService";
import { getEventByIdService } from "services/eventService";
import { getUpComingEventsService } from "services/eventService";
import { getLastUpComingEventsService } from "services/eventService";
import { getAllEventCalanderService } from "services/eventService";
export const deleteEvent = createAsyncThunk(
  "event/deleteEvent",
  async (eventid,thunkAPI) => {
    try {
      const { status, data } = await deleteEventService(eventid);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getTimelineCalander = createAsyncThunk(
  "event/getTimelineCalander",
  async (thunkAPI) => {
    try {
      const { status, data } = await getTimeLineCalanderService();
      if (status === 200) {
        console.log(data, "22222222");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllEventCalander = createAsyncThunk(
  "event/getAllEventCalander",
  async (thunkAPI) => {
    try {
      const { status, data } = await getAllEventCalanderService();
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addEvent = createAsyncThunk(
  "event/add",
  async (eventData, thunkAPI) => {
    try {
      const { status, data } = await addEventService(eventData.data);
      if (status === 200 || status === 201) {
        console.log(data);
        if (
          data &&
          data.event &&
          eventData &&
          eventData.roles &&
          eventData.currentUserId
        ) {
          eventData.socket.emit("sendNotificationToAll", {
            type: "AddEvent",
            data: data.event,
            roles: eventData.roles,
            sender: eventData.currentUserId,
          });
        }
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addAvabilityExeprtinCalander = createAsyncThunk(
  "event/addAvability",
  async (dataExpert, thunkAPI) => {
    try {
      const { status, data } = await addAvabilityExpert(dataExpert);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getEvents = createAsyncThunk(
  "event/getEvents",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getEventsService(filterData);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUpComingEvents = createAsyncThunk(
  "event/getUpComingEvents",
  async (page, thunkAPI) => {
    try {
      const { status, data } = await getUpComingEventsService(page);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getLastUpComingEvents = createAsyncThunk(
  "event/getLastUpComingEvents",
  async (thunkAPI) => {
    try {
      const { status, data } = await getLastUpComingEventsService();
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getEventById = createAsyncThunk(
  "event/getEventById",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getEventByIdService(id);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const participateToEvent = createAsyncThunk(
  "event/participate",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await participateToEventService(id);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const edittheevent = createAsyncThunk(
  "event/editevent",
  async (dataUpdated, thunkAPI) => {
    try {
      const { status, data } = await editEventService(dataUpdated);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  editevent: {
    status: "idle",
    data: {},
    error: {},
  },
  deleteevent: {
    status: "idle",
    data: {},
    error: {},
  },
  addevent: {
    status: "idle",
    data: {},
    error: {},
  },
  events: {
    status: "idle",
    data: {},
    error: {},
  },
  event: {
    status: "idle",
    data: {},
    error: {},
  },
  avability: {
    status: "idle",
    data: {},
    error: {},
  },
  upComingEvents: {
    status: "idle",
    data: {},
    error: {},
  },
  lastUpComingEvents: {
    status: "idle",
    data: {},
    error: {},
  },
  allEventCalender: {
    status: "idle",
    data: {},
    error: {},
  },
  timeLineEvent: {
    status: "idle",
    data: {},
    error: {},
  },
  participateToEvent: {
    status: "idle",
    data: {},
    error: {},
  },
};

const EventSlice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [edittheevent.pending.type]: (state) => {
      state.editevent = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [edittheevent.fulfilled.type]: (state, action) => {
      state.editevent = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [edittheevent.rejected.type]: (state, action) => {
      state.editevent = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addAvabilityExeprtinCalander.pending.type]: (state) => {
      state.avability = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addAvabilityExeprtinCalander.fulfilled.type]: (state, action) => {
      state.avability = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addAvabilityExeprtinCalander.rejected.type]: (state, action) => {
      state.avability = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [deleteEvent.pending.type]: (state) => {
      state.deleteevent = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deleteEvent.fulfilled.type]: (state, action) => {
      state.deleteevent = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [deleteEvent.rejected.type]: (state, action) => {
      state.deleteevent = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [participateToEvent.pending.type]: (state) => {
      state.participateToEvent = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [participateToEvent.fulfilled.type]: (state, action) => {
      state.participateToEvent = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [participateToEvent.rejected.type]: (state, action) => {
      state.participateToEvent = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getTimelineCalander.pending.type]: (state) => {
      state.timeLineEvent = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getTimelineCalander.fulfilled.type]: (state, action) => {
      state.timeLineEvent = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getTimelineCalander.rejected.type]: (state, action) => {
      state.timeLineEvent = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAllEventCalander.pending.type]: (state) => {
      state.allEventCalender = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllEventCalander.fulfilled.type]: (state, action) => {
      state.allEventCalender = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllEventCalander.rejected.type]: (state, action) => {
      state.allEventCalender = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getEvents.pending.type]: (state) => {
      state.events = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getEvents.fulfilled.type]: (state, action) => {
      state.events = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getEvents.rejected.type]: (state, action) => {
      state.events = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addEvent.pending.type]: (state) => {
      state.addevent = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addEvent.fulfilled.type]: (state, action) => {
      state.addevent = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addEvent.rejected.type]: (state, action) => {
      state.addevent = {
        status: "failed",
        data: {},
        error: action.payload.message ? action.payload.message : "",
      };
    },
    [getEventById.pending.type]: (state) => {
      state.event = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getEventById.fulfilled.type]: (state, action) => {
      state.event = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getEventById.rejected.type]: (state, action) => {
      state.event = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getUpComingEvents.pending.type]: (state) => {
      state.upComingEvents = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getUpComingEvents.fulfilled.type]: (state, action) => {
      state.upComingEvents = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getUpComingEvents.rejected.type]: (state, action) => {
      state.upComingEvents = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getLastUpComingEvents.pending.type]: (state) => {
      state.lastUpComingEvents = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getLastUpComingEvents.fulfilled.type]: (state, action) => {
      state.lastUpComingEvents = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getLastUpComingEvents.rejected.type]: (state, action) => {
      state.lastUpComingEvents = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default EventSlice.reducer;
