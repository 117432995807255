import React, { useState, useEffect, useContext } from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { UpdateStatusP } from "reducers/project";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "contexts/SocketContext";
import BasicButton from "components/button/BasicButton";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

export default function ProjectStatus(props) {
  const { project, onReload } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [options, setOptions] = useState({});
  const notificationsSettings = useSelector((state) =>
    state.NotificationsSettings.notificationsSettings.data
      ? state.NotificationsSettings.notificationsSettings.data.message
      : []
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );

  const toggleUserStatus = async () => {
    const updateData = {
      id: project._id,
      roles: notificationsSettings
        ? notificationsSettings.activateProject
        : null,
      currentUserId: currentUser ? currentUser._id : null,
      socket: socket,
    };
    await dispatch(UpdateStatusP(updateData)).unwrap();
    onClose();
    onReload();
  };

  useEffect(() => {
    setOptions(
      project.isConfirmed
        ? {
            badgeText: "Unverify",
            badgeColor: "red",
            badgeIcon: FaTimesCircle,
            text: ` ${project.title} is verified. Are you sure that you want to unverify this project?`,
            buttonColor: "red",
            buttonText: "Unverify",
          }
        : {
            badgeText: "Verify",
            badgeColor: "green",
            badgeIcon: FaCheckCircle,
            text: `${project.title} is unverified. Do you want to verify this project?`,
            buttonColor: "green",
            buttonText: "Verify",
          }
    );
  }, [project.isConfirmed]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Project Verification</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{options.text}</Text>
          </ModalBody>
          <ModalFooter>
            <BasicButton
              onClick={onClose}
              title={"Cancel"}
              variant="solid"
              style={{ marginRight: "5px" }}
            />
            <BasicButton
              colorScheme={options.buttonColor}
              onClick={toggleUserStatus}
              title={options.buttonText}
              variant="solid"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <BasicButton
        colorScheme={options.badgeColor}
        onClick={onOpen}
        title={options.badgeText}
        variant="solid"
        icon={options.badgeIcon}
      />
    </>
  );
}
