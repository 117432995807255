import React from "react";
import { Text, Flex, Box, Image, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
export default function VotersItem(props) {
  const { item } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card p="1" mb="2" boxShadow={cardShadow}>
      <Flex>
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
        >
          <Image
            h="50px"
            w="50px"
            src={config.apiUrl + "/org/file/" + item.Image}
            borderRadius="8px"
            fallbackSrc={placeholder}
            objectFit="cover"
          />
          <Box ml="3">
            <Text fontWeight="normal" color="blue">
              {item.firstName + " " + item.lastName}
            </Text>
            <Text fontWeight="normal">{item.email}</Text>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
}
