/* eslint-disable */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Image, Spinner } from "@chakra-ui/react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultAuth from "layouts/auth/Default";
import { forgotPasswordUser } from "reducers/userInformation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import logoCov from "assets/img/logoCov.jpg";
import logo from "assets/img/logo.png";

function forgetPassword() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [inputEmail, setinputEmail] = useState();
  const [error, setError] = useState("");
  const [loader, setLoaderState] = useState(false);
  const dispatch = useDispatch();
  const status = useSelector(
    (state) => state.UserInformation.EmailPassword.error.message
  );
  const submitHandler = async () => {
    await dispatch(forgotPasswordUser({ email: inputEmail })).unwrap();
  };
  useEffect(() => {
    if (status === "An Email sent to your account please verify") {
      toast.info(status, {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
    }
    if (status === "User with given email not Exist!") {
      toast.info("User with given email not Exist!", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
    }
  }, [status]);

  return (
    <DefaultAuth illustrationBackground={logoCov} image={logoCov}>
      {loader === true ? (
        <Flex
          width={"96"}
          my="auto"
          alignItems={"center"}
          justifyContent="center"
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "20px", lg: "14vh" }}
          flexDirection="column"
        >
          <Flex
            width="100%"
            flexDirection="column"
            justifyContent={"center"}
            alignItems={{ base: "center", lg: "unset" }}
            display={{ base: "flex", lg: "none" }}
            mb="20px"
          >
            <Image src={logo} alt="Logo" maxW={{ base: "70%", md: "50%" }} />
          </Flex>
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Forgot your password?
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              {error !== "" ? (
                error
              ) : (
                <>
                  No problem. Just let us know your email address and we'll
                  email you <br />a password reset link that will allow you to
                  choose a new one.
                </>
              )}
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="member@mail.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                onChange={(e) => setinputEmail(e.target.value)}
              />
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                onClick={submitHandler}
              >
                Send email
              </Button>
            </FormControl>
            <NavLink to="/auth/sign-in">
              <Text color={textColorBrand} as="span" ms="5px" mt="16px">
                Back to Sign In
              </Text>
            </NavLink>
          </Flex>
        </Flex>
      )}
      <ToastContainer />
    </DefaultAuth>
  );
}

export default forgetPassword;
