import React, { useState, useContext, useEffect } from "react";
import {
  VStack,
  Box,
  Text,
  Flex,
  Avatar,
  Badge,
  AvatarBadge,
  Divider,
  InputGroup,
  InputLeftElement,
  IconButton,
  Input,
} from "@chakra-ui/react";
import config from "config/axios/config";
import { scrollbarStyle } from "utils/constants";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import { SocketContext } from "contexts/SocketContext";

const OnlineUsers = (props) => {
  const { setReceiver, sender, receiver, setMessages } = props;
  const socket = useContext(SocketContext);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [name, setName] = useState("");
  const [all, setAll] = useState(false);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setReceiver(user);
    setMessages([]);
    socket.emit("joinRoom", { userIds: [sender._id] });
    socket.emit("joinRoom", { userIds: [sender._id, receiver._id] });
  };
  useEffect(() => {
    socket.on("allusers", (data) => {
      setOnlineUsers(data);
    });
    if (sender && receiver) {
      socket.emit("joinRoom", { userIds: [sender._id, receiver._id] });
      socket.emit("update-message-status", {
        sender: sender._id,
        receiver: receiver._id,
      });
    }
    if (sender) {
      socket.emit("get-all-users", {
        userId: sender._id,
        name: name,
        all: all,
      });
    }
  }, [socket, sender, receiver, name, all]);
  useEffect(() => {
    if (!name) {
      setAll(true);
    }
  }, [name, onlineUsers]);
  return (
    <VStack
      align="stretch"
      spacing={2}
      pr={{ base: 0, md: 2 }}
      borderColor="gray.200"
      minWidth="120px"
      width={{ base: "100%", md: "unset" }}
    >
      <Text fontSize="xl" fontWeight="semibold" mb={2}>
        Chats
      </Text>

      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <IconButton
            variant="ghost"
            aria-label="Search"
            icon={<FaSearch color="gray" />}
          />
        </InputLeftElement>
        <Input
          type="text"
          placeholder="Search..."
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </InputGroup>
      <Flex
        height="400px"
        css={scrollbarStyle}
        overflowY="auto"
        direction="column"
      >
        {onlineUsers.map((user) => (
          <>
            {" "}
            <Flex
              key={user._id}
              align="center"
              p={2}
              _hover={{
                bg: "gray.200",
                cursor: "pointer",
              }}
              boxShadow="sm"
              onClick={() => handleUserSelect(user)}
              fontWeight={"normal"}
              bg={
                selectedUser && selectedUser._id === user._id
                  ? "gray.200"
                  : "white"
              }
              width="100%"
            >
              <Avatar src={config.apiUrl + "/api/getfile/" + user.Image}>
                <AvatarBadge
                  boxSize="1em"
                  bg={user.status === "offline" ? "tomato" : "green.500"}
                />
              </Avatar>

              <Box ml={2} flex="1">
                <Flex
                  direction="column"
                  p={2}
                  flex="1"
                  bg={user.unreadCount > 0 ? "blue.100" : "white"}
                  borderWidth="1px"
                  borderColor={user.unreadCount > 0 ? "blue.200" : "gray.200"}
                  borderRadius="lg"
                  transition="background 0.3s, border-color 0.3s"
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    mb={user.firstName.length > 20 ? 2 : 0}
                  >
                    <Text fontWeight="semibold" fontSize="md">
                      {user.firstName.length > 20
                        ? user.firstName.substring(0, 20) + "..."
                        : user.firstName}
                      {user.firstName.length <= 20 &&
                        user.lastName &&
                        ` ${user.lastName}`}{" "}
                    </Text>
                    {user.unreadCount > 0 && (
                      <Badge colorScheme="gray" ml={1}>
                        {user.unreadCount}
                      </Badge>
                    )}
                  </Flex>
                  <Box>
                    <Text
                      fontSize="sm"
                      color={user.unreadCount > 0 ? "blue.800" : "gray.700"}
                      maxWidth="150px"
                      className="message-content"
                    >
                      {user.lastMessage}
                    </Text>
                  </Box>
                  <Flex justify="space-between" align="center">
                    <Text fontSize="xs" color="gray.500">
                      {user.createdAt
                        ? moment(user.createdAt).fromNow()
                        : "Let's start a conversation"}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <Divider />
          </>
        ))}
      </Flex>
    </VStack>
  );
};

export default OnlineUsers;
