import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Link,
  Badge,
  Image,
  Flex,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { KonnectReducer } from "reducers/Konnect";
import { useDispatch } from "react-redux";
import { Link as Linkk } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useParams } from "react-router-dom";
import { checkoutReducer } from "reducers/Konnect";

export default function FelecitationPayment() {
    const params = useParams();
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        if (params.id) {
    
            var result = jwt_decode(params.id);    
            console.log("kalalalalalala",{"amount":result.amount,"expert":result.expert,"projectholder":result.projectholder,"idmeet":result.idmeet})
            await dispatch(checkoutReducer({"amount":result.amount,"expert":result.expert,"projectholder":result.projectholder,"idmeet":result.idmeet})).unwrap()
            console.log("dispatch")
        }}

    useEffect(() => {
        handleSubmit()
    }, []);
  return (
    <Card mb="20px" mt="100px">
        
      <Text textAlign={"center"} fontSize="large" color={"purple"}>
      Congratulations Payment      </Text>
      <Text textAlign={"center"}>Your payment is successfully completed!</Text>
    </Card>
  );
}
