import React, { useState } from "react";
import { Flex, Text, Textarea, Button, Input, Heading } from "@chakra-ui/react";
import Card from "components/card/Card";
import SwitchField from "components/fields/SwitchField";

const IndividualTrainingForm = (props) => {
  const { meetingData, setMeetingData, setStep } =
    props;
  const [title, setTitle] = useState(meetingData.title);
  const [note, setNote] = useState(meetingData.description);
  const [from, setFrom] = useState(meetingData.start);
  const [to, setTo] = useState(meetingData.end);
  const [titleError, setTitleError] = useState("");
  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");
  const [online, setOnline] = useState(false);

  const [emplacement, setEmplacement] = useState("");
  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState("");
  const [emplacementError, setEmplacementError] = useState("");
  const validForm = () => {
    let valid = true;
    const date = new Date();
    if (!from) {
      setFromError("You should enter a start time");
      valid = false;
    } else if (new Date(from) < date) {
      setFromError("Start time must be greater than current time");
    } else {
      setFromError("");
    }
    if (!to) {
      setToError("You should enter an end time");
      valid = false;
    } else if (to < from) {
      setToError("End time should be greater than start time");
    } else {
      setToError("");
    }
    if (!title) {
      setTitleError("Title is required");
      valid = false;
    } else {
      setTitleError("");
    }
    if (online && !url) {
      setUrlError("The url is required!");
      valid = false;
    } else {
      setUrlError("");
    }
    if (!online && !emplacement) {
      setEmplacementError("The emplacement is required!");
      valid = false;
    } else {
      setEmplacementError("");
    }
    return valid;
  };

  const confirm = () => {
    if (!validForm()) {
      return;
    }
    
    const data = {
      start: from,
      end: to,
      title: title,
      description: note,
      online: online,
      onlineUrl: url,
      location: emplacement
    };
    setMeetingData(data);
    setStep(2);
  };

  return (
    <Card>
      <Heading size="sm" mt="4" mb="2">
        General Informations
      </Heading>
      <Flex flexDirection={"column"}>
        <Text>Title</Text>
        <Input
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          placeholder="Title"
          value={title}
        />
        {titleError && <Text color="red">{titleError}</Text>}

        <Text>Note</Text>
        <Textarea
          mb="2"
          onChange={(e) => {
            setNote(e.target.value);
          }}
          value={note}
          placeholder="Leave a note to the expert"
        />

        <Text mt="2">From</Text>
        <Input
          placeholder="From"
          size="md"
          type="datetime-local"
          onChange={(e) => {
            setFrom(e.target.value);
          }}
          value={from}
        />
        {fromError && <Text color="red">{fromError}</Text>}
        <Text>To</Text>
        <Input
          placeholder="To"
          size="md"
          type="datetime-local"
          onChange={(e) => setTo(e.target.value)}
          value={to}
        />
        {toError && <Text color="red">{toError}</Text>}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <SwitchField
          reversed={true}
          fontSize="sm"
          id="6"
          label="Online"
          isChecked={online}
          onChange={() => {
            setOnline((c) => (c ? false : true));
          }}
        />
      </Flex>
      <Flex mt="2" direction={"column"}>
        {!online && (
          <>
            <Input
              isInvalid={emplacementError}
              type="text"
              placeholder="Emplacement"
              id="emplacement"
              name="emplacement"
              value={emplacement}
              onChange={(ev) => setEmplacement(ev.target.value)}
            />
            {emplacementError && (
              <Text mt="3" color="red">
                {emplacementError}
              </Text>
            )}
          </>
        )}

        {online && (
          <>
            <Input
              isInvalid={urlError}
              type="text"
              placeholder="Link"
              id="link"
              name="link"
              value={url}
              onChange={(ev) => setUrl(ev.target.value)}
            />
            {urlError && (
              <Text mt="3" color="red">
                {urlError}
              </Text>
            )}
            
          </>
        )}
      </Flex>

      <Flex mt="4" justifyContent={"flex-end"}>
        <Button colorScheme={"blue"} onClick={confirm}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};

export default IndividualTrainingForm;
