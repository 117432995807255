import Api from "config/axios/api";
import config from "../config/axios/config";
export const addCallOfVotingService = async (data) => {
  const url = config.apiUrl + "/admin/addvoteSession/";

  try {
    return await Api.post(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const deleteCallForVotingService = async (id) => {
  const url = config.apiUrl + "/admin/deleteSession/" + id;
  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAllCallOfVotingService = async () => {
  const url = config.apiUrl + "/admin/getvoteSession/";

  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getDetailsCallOfVotingService = async (id) => {
  const url = config.apiUrl + "/cohort/ByVoteSession/" + id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getProjectsinCallOfVotingService = async (idCallForVoting) => {
  const url = config.apiUrl + "/cohort/ProjectByIdCallVoting/" + idCallForVoting;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const ProjectsNextCallForVotingService = async (data) => {
  const url = config.apiUrl + "/cohort/nextVoteSession/";
  try {
    return await Api.put(url,{"idCallForVoting":data.idCallForVoting,"idProject":data.idProject,"Status":data.Status,"nextCallOfVote":data.nextCallOfVote} ,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const RatingProjectInCallForVotingService = async (data) => {
  const url = config.apiUrl + "/rt/ratproject/" + data.Id;
  try {
    return await Api.post(url,{"rating":data.rating,"votesSession":data.votesSession} ,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const MeetingReviewService = async (dataReview) => {
  const url = config.apiUrl + "/meetingReview/";
  try {
    return await Api.post(url,dataReview,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  addCallOfVotingService,
  getAllCallOfVotingService,
  deleteCallForVotingService,
  getDetailsCallOfVotingService,
  getProjectsinCallOfVotingService,
  ProjectsNextCallForVotingService,
  RatingProjectInCallForVotingService,
  MeetingReviewService
};
