import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as Linkk } from "react-router-dom";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Divider,
  Flex,
  ButtonGroup,
  Button,
  Image,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { deleteCohort } from "reducers/cohort";
import { applyForHackathon } from "reducers/cohort";
import JoinHackathonModal from "./JoinHackathonModal";
import DeleteConfirmationModal from "components/modals/DeleteConfirmationModal";
import InterestedToHackathonModal from "./InterestedToHackathonModal";
import CountdownTimer from "components/countdown/CountdownTimer";
import { getHackathonTracksTitles } from "reducers/track";
import { deleteHackathon } from "reducers/hackathon";
import { interstedInHackathon } from "reducers/hackathon";

const HackathonItem = (props) => {
  const {
    item,
    currentUser = null,
    onRelaod = () => {},
    canJoin = false,
  } = props;
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentUserId, setCurrentUserId] = useState("");
  const [currentUserType, setCurrentUserType] = useState("");
  const [tracks, setTracks] = useState([]);
  const {
    isOpen: isOpenM2,
    onOpen: onOpenM2,
    onClose: onCloseM2,
  } = useDisclosure();
  const {
    isOpen: isOpenM3,
    onOpen: onOpenM3,
    onClose: onCloseM3,
  } = useDisclosure();
  const deleteHackathonAction = async () => {
    await dispatch(deleteHackathon(item._id)).unwrap();
    toast.success("Hackathon was deleted successfully!", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });
    onRelaod();
  };

  const interstedInHackathonAction = async () => {
    
    await dispatch(interstedInHackathon(props.item._id))
      .unwrap()
      .then((res) => {
        toast.success(res.message, {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
        onRelaod();
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    if (currentUser) {
      setCurrentUserId(currentUser._id);
      setCurrentUserType(currentUser.userType);
    }
  }, [currentUser]);
  const getTracksByHackathonAction = async () => {
    const payload = {
      hackathonId : item._id,
    }
    await dispatch(getHackathonTracksTitles(payload))
      .unwrap()
      .then((result) => {
        setTracks(result.result);
      });
      if(tracks){
      console.log(tracks,'tracks')}
  };
  useEffect(() => {
    getTracksByHackathonAction();
  }, [item]);
  const targetDate = new Date(item.end);

  return (
    <Card
      boxShadow={cardShadow}
      display="flex"
      justifyContent="space-between"
      transition="transform 0.2s"
      _hover={{ transform: "scale(1.05)" }}
    >
      <ToastContainer />
      {isOpen && (
        <JoinHackathonModal
          hackathon={item}
          isOpen={isOpen}
          onClose={onClose}
          onReload={onRelaod}
          tracks={tracks}
        />
      )}
      <Flex direction="column">
        <Flex direction="column">
          <Box>
            <Image
              src={config.apiUrl + `/hackathons/file/` + item.image}
              mb="5px"
              boxSize="150px"
              width="100%"
              objectFit="cover"
              alt=""
              borderRadius="lg"
              fallbackSrc={placeholder}
            />
          </Box>
          <CountdownTimer targetDate={targetDate} />
          <Flex mt="2px" justify="space-between" alignItems={"center"}>
            <Text
              color={textColor}
              mr="4px"
              fontSize={{
                base: "xl",
                md: "lg",
                lg: "lg",
                xl: "lg",
                "2xl": "md",
                "3xl": "lg",
              }}
              mb="5px"
              fontWeight="bold"
              me="14px"
            >
              {item.title}
            </Text>
          </Flex>

          <Text className="description" color={textColor} mb="5px" me="14px">
            {item.description}{" "}
          </Text>
        </Flex>
        <Divider mt="2" mb="2" />
        <Flex color={textColor} direction="row" alignItems={"center"}>
          <BiTimeFive w="20px" h="20px" color="#3b82f6" />
          <Text color="#3b82f6" fontWeight={"700"} ml="2">
            {moment(item.start).format("ll")} - {moment(item.end).format("ll")}
          </Text>
        </Flex>

      </Flex>
      <Flex direction="column">
        <Divider mt="2" mb="2" />
        <ButtonGroup spacing="2" display={"flex"} justifyContent="flex-end">
          <Button variant="solid" colorScheme="blue" size="sm">
            <Linkk to={"/admin/HackathonDetails/" + item._id}>Details</Linkk>
          </Button>
          {(currentUserId === item.createdBy ||
            currentUserType === "SuperAdmin") && (
            <Button
              variant="solid"
              colorScheme="red"
              size="sm"
              onClick={onOpenM2}
            >
              Delete
            </Button>
          )}
          {canJoin && (
            <Button
              size="sm"
              variant="solid"
              colorScheme="green"
              onClick={onOpen}
              disabled={!item.canApply || (tracks && tracks.length === 0)}
            >
              Join Hackathon
            </Button>
          )}
          {currentUserType == "community" && !canJoin && (
            <Button
              size="sm"
              variant="solid"
              colorScheme="green"
              onClick={onOpenM3}
              disabled={!item.canInterest || (new Date(item.end) < new Date())}

            >
              Interested
            </Button>
          )}
        </ButtonGroup>
      </Flex>
      <DeleteConfirmationModal
        isOpen={isOpenM2}
        onClose={onCloseM2}
        headerContent="Confirm Deletion"
        bodyContent={`Are you sure you want to delete the hackathon with the name <b>${item.title}</b>? This action cannot be undone.`}
        handleDelete={deleteHackathonAction}
      />
      <InterestedToHackathonModal
        isOpen={isOpenM3}
        onClose={onCloseM3}
        onConfirm={interstedInHackathonAction}
        // isInterested={!item.intrested}
      />
    </Card>
  );
};

export default HackathonItem;
