import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getVotesByCategoryService } from "services/projectService";
import { getTopProjectsByVoteService } from "services/projectService";
import { getNumberProjectsByCategoryService } from "services/projectService";
import {
  getProjectByUserService,
  getallprojectService,
  addProjectService,
  getProjectByIdService,
  RateProjectService,
  updateStatusProject,
  canJoinTeamService,
  likeProjectService,
  updateProjectService,
  acceptTeamInvitationService,
  acceptTeamInvitationByProjectService
} from "../services/projectService";
export const addProject = createAsyncThunk(
  "project/create",
  async (filterdata, thunkAPI) => {
    try {
      const { status, data } = await addProjectService(filterdata);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateProject = createAsyncThunk(
  "project/update",
  async (projectData, thunkAPI) => {
    try {
      const { status, data } = await updateProjectService(projectData);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getTopProjectsByVote = createAsyncThunk(
  "project/getTopProjectsByVote",
  async (thunkAPI) => {
    try {
      const { status, data } = await getTopProjectsByVoteService();
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getNumberProjectsByCategory = createAsyncThunk(
  "project/getNumberProjectsByCategory",
  async (thunkAPI) => {
    try {
      const { status, data } = await getNumberProjectsByCategoryService();
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getVotesByCategory = createAsyncThunk(
  "project/getVotesByCategory",
  async (thunkAPI) => {
    try {
      const { status, data } = await getVotesByCategoryService();
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getallProjects = createAsyncThunk(
  "project/getAllProjects",
  async (filterdata, thunkAPI) => {
    try {
      const { status, data } = await getallprojectService(filterdata);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getProjectsbyUser = createAsyncThunk(
  "project/getProjectsbyUser",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getProjectByUserService(id);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getProjectById = createAsyncThunk(
  "project/get-by-id",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getProjectByIdService(id);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addProjectRate = createAsyncThunk(
  "expert/addProjectRate",
  async (dataRating, thunkAPI) => {
    try {
      const { status, data } = await RateProjectService(dataRating);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const UpdateStatusP = createAsyncThunk(
  "project/id",
  async (updateData, thunkAPI) => {
    try {
      const { status, data } = await updateStatusProject(updateData.id);
      if (status === 200) {
        console.log(data, "data");
        if (
          data &&
          data.project &&
          data.project.isConfirmed == true &&
          updateData &&
          updateData.roles &&
          updateData.currentUserId
        ) {
          updateData.socket.emit("sendNotificationToAll", {
            type: "AddProject",
            data: data.project,
            roles: updateData.roles,
            sender: updateData.currentUserId,
          });
        }

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const canJoinTeam = createAsyncThunk(
  "project/can-join-team",
  async (email, thunkAPI) => {
    try {
      const { status, data } = await canJoinTeamService(email);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const likeProject = createAsyncThunk(
  "project/like",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await likeProjectService(id);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const acceptTeamInvitation = createAsyncThunk(
  "project/accept-team-invitation",
  async (token, thunkAPI) => {
    try {
      const { status, data } = await acceptTeamInvitationService(token);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const acceptTeamInvitationByProject = createAsyncThunk(
  "project/accept-team-invitationByProject",
  async (token, thunkAPI) => {
    try {
      const { status, data } = await acceptTeamInvitationByProjectService(token);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  updateProject: {
    status: "idle",
    data: {},
    error: {},
  },
  projectstatus: {
    status: "idle",
    data: {},
    error: {},
  },
  topProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  projectRate: {
    status: "idle",
    data: {},
    error: {},
  },
  numberProjectsByCategory: {
    status: "idle",
    data: {},
    error: {},
  },
  votesByCategory: {
    status: "idle",
    data: {},
    error: {},
  },
  projects: {
    loading: false,
    data: [],
    error: {},
  },
  projectsbyUser: {
    loading: false,
    data: [],
    error: {},
  },
  addProjectbyUser: {
    loading: false,
    data: [],
    error: {},
  },
  project: {
    loading: false,
    data: {},
    error: {},
  },
  canJoinTeam: {
    loading: false,
    data: {},
    error: {},
  },
  like: {
    loading: false,
    data: {},
    error: {},
  },
  acceptTeamInvitation: {
    loading: false,
    data: {},
    error: {},
  },
  acceptTeamByProjectInvitation: {
    loading: false,
    data: {},
    error: {},
  },
};
const ProjectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [addProjectRate.pending.type]: (state) => {
      state.projectRate = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addProjectRate.fulfilled.type]: (state, action) => {
      state.projectRate = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addProjectRate.rejected.type]: (state, action) => {
      state.projectRate = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateProject.pending.type]: (state) => {
      state.updateProject = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateProject.fulfilled.type]: (state, action) => {
      state.updateProject = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateProject.rejected.type]: (state, action) => {
      state.updateProject = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [UpdateStatusP.pending.type]: (state) => {
      state.projectstatus = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [UpdateStatusP.fulfilled.type]: (state, action) => {
      state.projectstatus = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [UpdateStatusP.rejected.type]: (state, action) => {
      state.projectstatus = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getProjectById.pending.type]: (state) => {
      state.project = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getProjectById.fulfilled.type]: (state, action) => {
      state.project = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getProjectById.rejected.type]: (state, action) => {
      state.project = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getTopProjectsByVote.pending.type]: (state) => {
      state.topProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getTopProjectsByVote.fulfilled.type]: (state, action) => {
      state.topProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getTopProjectsByVote.rejected.type]: (state, action) => {
      state.topProjects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getNumberProjectsByCategory.pending.type]: (state) => {
      state.numberProjectsByCategory = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getNumberProjectsByCategory.fulfilled.type]: (state, action) => {
      state.numberProjectsByCategory = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getNumberProjectsByCategory.rejected.type]: (state, action) => {
      state.numberProjectsByCategory = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getVotesByCategory.pending.type]: (state) => {
      state.votesByCategory = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getVotesByCategory.fulfilled.type]: (state, action) => {
      state.votesByCategory = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getVotesByCategory.rejected.type]: (state, action) => {
      state.votesByCategory = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getallProjects.pending]: (state, action) => {
      state.projects.loading = true;
    },
    [getallProjects.fulfilled]: (state, action) => {
      state.projects.loading = false;
      state.projects.data = action.payload;
    },
    [getallProjects.rejected]: (state, action) => {
      state.projects.loading = false;
      state.projects.error = action.payload;
    },
    [getProjectsbyUser.pending.type]: (state) => {
      state.projectsbyUser = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getProjectsbyUser.fulfilled.type]: (state, action) => {
      state.projectsbyUser = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getProjectsbyUser.rejected.type]: (state, action) => {
      state.projectsbyUser = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addProject.pending.type]: (state) => {
      state.addProjectbyUser = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addProject.fulfilled.type]: (state, action) => {
      state.addProjectbyUser = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addProject.rejected.type]: (state, action) => {
      state.addProjectbyUser = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [canJoinTeam.pending.type]: (state) => {
      state.canJoinTeam = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [canJoinTeam.fulfilled.type]: (state, action) => {
      state.canJoinTeam = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [canJoinTeam.rejected.type]: (state, action) => {
      state.canJoinTeam = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [likeProject.pending.type]: (state) => {
      state.like = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [likeProject.fulfilled.type]: (state, action) => {
      state.like = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [likeProject.rejected.type]: (state, action) => {
      state.like = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [acceptTeamInvitation.pending.type]: (state) => {
      state.acceptTeamInvitation = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [acceptTeamInvitation.fulfilled.type]: (state, action) => {
      state.acceptTeamInvitation = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [acceptTeamInvitation.rejected.type]: (state, action) => {
      state.acceptTeamInvitation = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [acceptTeamInvitationByProject.pending.type]: (state) => {
      state.acceptTeamByProjectInvitation = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [acceptTeamInvitationByProject.fulfilled.type]: (state, action) => {
      state.acceptTeamByProjectInvitation = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [acceptTeamInvitationByProject.rejected.type]: (state, action) => {
      state.acceptTeamByProjectInvitation = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default ProjectSlice.reducer;
