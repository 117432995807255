import React from "react";
import moment from "moment";
import { deleteTrack } from "reducers/track";
import { useDispatch } from "react-redux";
import { Box, Flex, Text, Button, Badge, Divider } from "@chakra-ui/react";
import { FaCalendar } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export default function TrackItem(props) {
  const { track, canEdit = false, onChange = () => {} } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const endDateSubmission = new Date(track.endDateSubmission);
  const currentDate = new Date();
  const deleteTrackAction = async () => {
    await dispatch(deleteTrack(track._id)).unwrap();
    onChange();
  };
  const goTo = () => history.push("/admin/Track-Details/" + track._id);

  return (
    <Box p={2} flex={1}>
      <Flex
        maxW="100%"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        bg="white"
        boxShadow="md"
        direction="column"
        backgroundColor={"#9e9e9e12"}
        justifyContent={"space-between"}
        h="100%"
      >
        <Box
          pl="4"
          pr="4"
          pt="2"
          pb="2"
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          height={"100%"}
        >
          <Flex flexDirection={"column"}>
            <Flex
              mt="3"
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <Text
                _hover={{ color: "blue.500", cursor: "pointer" }}
                fontWeight="bold"
                fontSize="xl"
                onClick={goTo}
              >
                {track.title}
              </Text>
              {/* {endDateSubmission >= currentDate && (
                <Badge colorScheme="green" mb="5px">
                  Open
                </Badge>
              )}
              {endDateSubmission < currentDate && (
                <Badge colorScheme="red" mb="5px">
                  Closed
                </Badge>
              )} */}
            </Flex>
            <Text color="gray.600" className="description">
              {track.description}
            </Text>
          </Flex>
          {/* <Flex flexDirection={"column"}>
            <Flex
              fontWeight={"700"}
              color={"blackAlpha.700"}
              direction="row"
              alignItems={"center"}
              mt="2"
            ></Flex>
            {/* <FaCalendar />
              <Text ml="2">Deadline </Text>
            </Flex>
            <Text color={"blackAlpha.700"}>
              {moment(track.endDateSubmission).format("llll")}
            </Text> 
          </Flex> */}
        </Box>
        <Flex flexDirection={"column"}>
          <Divider my="2" />
          <Flex justify="space-between" pl="4" pr="4" pb="2">
            {canEdit && (
              <Button
                variant="solid"
                colorScheme="red"
                size="sm"
                onClick={deleteTrackAction}
              >
                Delete
              </Button>
            )}
            {/* {!canEdit && (
              <Button
                variant="solid"
                colorScheme="green"
                size="sm"
                onClick={() => {}}
              >
                Join
              </Button>
            )} */}
            <Button onClick={goTo} colorScheme="blue" variant="solid" size="sm">
              Details
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
