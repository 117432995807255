import React from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import "./ProfileSettings.css";
import "react-toastify/dist/ReactToastify.css";
import Card from "components/card/Card";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import moment from "moment";

const EducationItem = (props) => {
  const { item, index, handleDeleteEducation, showEditHandler, canEdit = true } = props;
  return (
    <Card backgroundColor={"#f8f9fa"} key={index}>
      <Heading size="sm" mb="2">
        {item.diplome}
      </Heading>
      <Text>
        {moment(item.start).format("llll")} -{" "}
        {moment(item.end).format("llll")}
      </Text>
      <Text>{item.place}</Text>
      {canEdit && <Stack
        spacing={2}
        direction="row"
        align="center"
        justifyContent={"flex-end"}
      >
        <Button colorScheme="blue" size="xs" onClick={showEditHandler}>
          <EditIcon />
        </Button>
        <Button colorScheme="red" size="xs" onClick={handleDeleteEducation}>
          <DeleteIcon />
        </Button>
      </Stack>}
    </Card>
  );
};

export default EducationItem;
