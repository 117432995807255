import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Flex,
  Box,
  Button,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getProjectMembersHackathon } from "reducers/cohort";
import { addProjectMembersHackathon } from "reducers/cohort";
import ProjectsSelector from "components/project/ProjectsSelector";
import { unjoinedHackathonProjects } from "reducers/hackathon";
import { addProjectMembersHackathons } from "reducers/hackathon";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function InviteMembersModal(props) {
  const { memberId, isOpen, onClose, hackathonId } = props;
  const { idHackathon } = useParams();
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState();
  const projects = useSelector((state) =>
    state.Hackathon.unjoinedHackathonProjects.data
      ? state.Hackathon.unjoinedHackathonProjects.data.projects
      : null
  );
  const totalPages = useSelector((state) =>
    state.Hackathon.unjoinedHackathonProjects.data
      ? state.Hackathon.unjoinedHackathonProjects.data.totalPages
      : 0
  );
  const getPendingProjectsByHackathonAction = async () => {
    const filterData = {
      idMember: memberId,
      page: page,
      limit: 4,
      idHackathon: hackathonId,
    };
    await dispatch(unjoinedHackathonProjects(filterData)).unwrap();
  };

  const addProjectMembersHackathonAction = async () => {
    const filterData = {
      idMember: memberId,
      idProject: selectedProject._id,
      idHackathon: hackathonId,
    };
    await dispatch(addProjectMembersHackathons(filterData)).unwrap();
    onClose();
  };

  useEffect(() => {
    if (memberId) {
      getPendingProjectsByHackathonAction();
    }
  }, [memberId, page, hackathonId]);

  useEffect(() => {
    console.log("pppp", projects);
  }, [projects]);
  return (
    <Modal
      size="yo"
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent w="65rem">
        <ModalHeader>Choose a project to invite this member to</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ToastContainer />
          <Card p="2" direction="column" w="100%">
            <ProjectsSelector
              projects={projects}
              error={error}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              selectedProjects={selectedProject}
              setSelectedProjects={setSelectedProject}
              multiple={false}
            />
            {projects && projects.length == 0 && (
              <Box>
                <Text textAlign="center">
                  This member was added in all your projects!
                </Text>
              </Box>
            )}
          </Card>{" "}
        </ModalBody>
        <ModalFooter>
          {projects && projects.length > 0 && (
            <Flex justifyContent="end" mt="4">
              <Button
                colorScheme="blue"
                size="md"
                onClick={addProjectMembersHackathonAction}
              >
                Confirm
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
