import React from "react";
import { Box, Accordion } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import QAItem from "./components/QAItem";
import { faqList } from "utils/constants";

export default function Guide() {
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Card mb="20px">
        <Accordion defaultIndex={[0]} allowMultiple>
          {faqList.map((item, index) => (
            <QAItem key={index} item={item} index={index} />
          ))}
        </Accordion>
      </Card>
    </Box>
  );
}
