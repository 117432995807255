import React, { useState } from "react";
import {
  Input,
  IconButton,
  VStack,
  HStack,
  CloseButton,
  Heading,
  useColorModeValue,
  Flex,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { checkIsValidEmail } from "utils/functions";
import BasicButton from "components/button/BasicButton";
import { canJoinTeam } from "reducers/project";
import { useDispatch } from "react-redux";

const ProjectMembersForm = ({
  activeStep,
  setActiveStep,
  members,
  setMembers,
  addProject,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const textColor = useColorModeValue("secondaryGray.800", "black");

  const handleAddEmail = async () => {
    if (!validateForm()) {
      return;
    }
    await dispatch(canJoinTeam(email))
      .unwrap()
      .then((response) => {
        if (response.success === false) {
          setError(
            "Sorry, the email provided is not eligible for team membership."
          );
          return;
        } else {
          setError("");
          setMembers([...members, email]);
          setEmail("");
        }
      });
  };

  const handleRemoveEmail = (index) => {
    const updatedEmailList = [...members];
    updatedEmailList.splice(index, 1);
    setMembers(updatedEmailList);
  };
  const validateForm = () => {
    let valid = true;
    if (members && members.length === 4) {
      setError("Max 4 emails allowed!");
      valid = false;
    } else if (members.includes(email)) {
      setError("This email is in the list!");
      valid = false;
    } else if (!email) {
      setError("You should add an email!");
      valid = false;
    } else if (email && !checkIsValidEmail(email)) {
      setError("Invalid Email!");
      valid = false;
    } else {
      setError("");
    }
    return valid;
  };
  return (
    <Flex flexDirection={"column"}>
      <Heading size="sm" mb="3" color={textColor}>
        Add Members
      </Heading>
      <VStack spacing={4} align="stretch" mb="4">
        <HStack>
          <Input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <IconButton
            colorScheme="blue"
            aria-label="Add Email"
            icon={<AddIcon />}
            onClick={handleAddEmail}
          />
        </HStack>
        {error && <Text color="red">{error}</Text>}
        {members.length > 0 && (
          <VStack align="stretch" w="100%">
            {members.map((addedEmail, index) => (
              <HStack
                key={index}
                justify="space-between"
                w="100%"
                p={2}
                bg="gray.100"
                borderRadius="md"
              >
                <div>{addedEmail}</div>
                <CloseButton onClick={() => handleRemoveEmail(index)} />
              </HStack>
            ))}
          </VStack>
        )}
      </VStack>
      <Flex justifyContent={"space-between"} mt="5">
        <BasicButton
          onClick={() => setActiveStep(activeStep - 1)}
          title={"Previous"}
          variant="solid"
        />
        <BasicButton
          onClick={addProject}
          title={isLoading ? "Adding..." : "Confirm"}
          variant="solid"
          disabled={isLoading}
        />
      </Flex>
    </Flex>
  );
};

export default ProjectMembersForm;
