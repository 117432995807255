import React, { useState } from "react";
import {
  Input,
  useColorModeValue,
  Flex,
  Text,
  Textarea,
  FormLabel,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import InputField from "components/fields/InputField";
import { useEffect } from "react";
import CardItem from "views/admin/hackathons/components/addHackathon/CardItem";

const PrizesForm = ({
  activeStep,
  setActiveStep,
  prizes,
  setPrizes,
  confirm,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [value, setValue] = useState(0);
  const [valueError, setValueError] = useState("");
  const [prizesError, setPrizesError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleAddPrize = async () => {
    setIsSubmitted(true);

    setPrizes([
      ...prizes,
      {
        title: title,
        description: description,
        value: value,
        order: prizes.length + 1,
      },
    ]);
    setTitle("");
    setDescription("");
    setValue(0);
  };
  return (
    <Flex flexDirection={"column"}>
      <Flex mt="2" direction={"column"}>
        <Text mb={"10px"}>
          If the track does not include a prize, the confirmation button will
          proceed directly. However, if a prize is available, a prompt will
          elegantly guide you to add prizes before proceeding.
        </Text>
        <FormLabel>
          Title <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          isInvalid={titleError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Title"
          borderRadius="5px"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {titleError && (
          <Text mt="3" color="red">
            {titleError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Description <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Textarea
          isInvalid={descriptionError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Description"
          borderRadius="5px"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {descriptionError && (
          <Text mt="3" color="red">
            {descriptionError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"} mb="5px">
        <InputField
          label="value"
          placeholder="value"
          value={value}
          setValue={setValue}
          error={valueError}
          rightElement="Hbar"
          type="number"
        />
      </Flex>
      {prizesError && (
        <Text mt="3" color="red">
          {prizesError}
        </Text>
      )}
      <Flex justifyContent={"end"} mt="5">
        <BasicButton
          onClick={handleAddPrize}
          title={"Add New Prize"}
          variant="solid"
        />
      </Flex>
      <CardItem value={prizes} setValue={setPrizes} type={"prizes"} />

      <Flex justifyContent={"space-between"} mt="3">
        <BasicButton
          onClick={() => setActiveStep(activeStep - 1)}
          title={"Previous"}
          variant="solid"
        />

        <BasicButton onClick={confirm} title={"confirm"} variant="solid" />
      </Flex>
    </Flex>
  );
};

export default PrizesForm;
