import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import { Text, Box, Center } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/pagination/Pagination";
import ProjectsItem from "./ProjectsItem";
import { getAcceptedProjectsInAssistingPrograms } from "reducers/assistingProgram";

export default function AssistingProgramProjects(props) {
  const { id } = props;
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const acceptedAssistingProgramsProjects = useSelector((state) =>
    state.AssistingProgram.acceptedProjects.data
      ? state.AssistingProgram.acceptedProjects.data.projects
      : []
  );
  const totalPages = useSelector((state) =>
  state.AssistingProgram.acceptedProjects.data
  ? state.AssistingProgram.acceptedProjects.data.totalPages
  : null
  );

  const getProjectsAssistingProgramAction = async () => {
    const payload={
      id:id,
      page:page,
      limit:"10"
    }
    await dispatch(getAcceptedProjectsInAssistingPrograms(payload)).unwrap();
  };

  useEffect(() => {
    getProjectsAssistingProgramAction()
   }, [page]);
  return (
    <Card p="2" direction="column" w="100%">
      {acceptedAssistingProgramsProjects && acceptedAssistingProgramsProjects.length > 0 && (
        <Box>
          {acceptedAssistingProgramsProjects.map((item) => (
            <ProjectsItem item={item}></ProjectsItem>
          ))}
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={()=>{}}
              />
            </Center>
          )}
        </Box>
      )}
      {acceptedAssistingProgramsProjects && acceptedAssistingProgramsProjects.length === 0 && (
        <Text>There is no project</Text>
      )}
    </Card>
  );
}
