import Api from "config/axios/api";
import config from "../config/axios/config";
export const getNotificationsSettingsService = async () => {
  const url = config.apiUrl + `/notifications_settings`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const updateNotificationsSettingsService = async (settingsData) => {
  const url = config.apiUrl + `/notifications_settings`;
  try {
    return await Api.put(url, settingsData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAllNotificationService = async () => {
  const url = config.apiUrl + `/notification/getMyNotif`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateNotificationService = async (idnotif) => {
  const url = config.apiUrl + `/notification/upateNotif/`+idnotif;
  try {
    return await Api.put(url,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getNotificationsSettingsService,
  updateNotificationsSettingsService,
  getAllNotificationService,
  updateNotificationService,
};
