import Api from "config/axios/api";
import config from "../config/axios/config";
export const createHackathonService = async (hackathonData) => {
  const url = config.apiUrl + `/hackathons/`;
  try {
    return await Api.post(url, hackathonData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getHackathonService = async (filterData) => {
  const url = config.apiUrl + `/hackathons/`;
  try {
    const headers = {};
    // If the user is logged in, include the authentication token in the headers
    if (localStorage.getItem("token")) {
      headers["Authorization"] = localStorage.getItem("token");
    }
    return await Api.get(url, {
      headers,
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getHackathonNotAuthService = async (filterData) => {
  const url = config.apiUrl + `/hackathons/get/`;
  try {
    const headers = {};
    // If the user is logged in, include the authentication token in the headers
    if (localStorage.getItem("token")) {
      headers["Authorization"] = localStorage.getItem("token");
    }
    return await Api.get(url, {
      headers,
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getHackathonByIdService = async (id) => {
  const url = config.apiUrl + `/hackathons/` + id;
  try {
    const headers = {};
    // If the user is logged in, include the authentication token in the headers
    if (localStorage.getItem("token")) {
      headers["Authorization"] = localStorage.getItem("token");
    }
    return await Api.get(url, {
      headers,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getPendingProjectsByHackathonService = async (filterData) => {
  const url = config.apiUrl + `/request-hackathon/byIdHack/` + filterData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const updateService = async (hackathonData) => {
  const url = config.apiUrl + `/hackathons/` + hackathonData.id;
  try {
    return await Api.put(url, hackathonData.data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const hackathonProjectsService = async (filterData) => {
  const url = config.apiUrl + `/hackathons/projects/` + filterData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const currentUserProjectsService = async (filterData) => {
  const url = config.apiUrl + `/hackathons/myProject/` + filterData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const SubmissionForHackathonService = async (hackathonData) => {
  const url =
    config.apiUrl +
    "/hackathons/" +
    hackathonData.idHackathon +
    "/submissions/";
  try {
    return await Api.post(url, hackathonData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const hackathonParticipantsService = async (filterData) => {
  const url = config.apiUrl + `/hackathons/participants/` + filterData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const unjoinedHackathonProjectsService = async (filterData) => {
  const url =
    config.apiUrl +
    `/hackathons/` +
    filterData.idHackathon +
    "/" +
    filterData.idMember +
    "/available-projects-to-invite";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const announceResultsService = async (id) => {
  const url = config.apiUrl + "/hackathons/" + id + "/announce-winner";
  try {
    return await Api.post(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const addPrizeService = async (prizeData) => {
  const url =
    config.apiUrl + `/hackathons/` + prizeData.hackathonId + `/prizes`;
  try {
    return await Api.post(url, prizeData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updatePrizeService = async (prizeData) => {
  const url =
    config.apiUrl + `/hackathons/` + prizeData.id + `/prize`;
  try {
    return await Api.put(url, prizeData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const deletePrizeService = async (prizeId) => {
  const url = config.apiUrl + `/hackathons/` + prizeId + `/prize/`;
  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getTrackByIdHackathonService = async (filterData) => {
  const url = config.apiUrl + `/track/byIdHackathon/` + filterData.hackathonId;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateHackathonApplicationService = async (updateData) => {
  const url = config.apiUrl + "/hackathons/" + updateData.id + "/request/";
  try {
    return await Api.put(
      url,
      { status: updateData.status },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const applyForHackathonService = async (dataApply) => {
  const url = config.apiUrl + `/hackathons/request/`;
  try {
    return await Api.post(url, dataApply, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const deleteHackathonService = async (id) => {
  const url = config.apiUrl + `/hackathons/` + id;
  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const interstedForHackathonService = async (id) => {
  const url = config.apiUrl + "/hackathons/" + id + "/interested-members";
  try {
    return await Api.post(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getInterstedMembersForHackathonService = async (filterData) => {
  const url =
    config.apiUrl + "/hackathons/" + filterData.id + "/interested-members";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const RatingProjectsInHackathonService = async (hackathonData) => {
  const url = config.apiUrl + `/hackathons/vote`;
  try {
    return await Api.post(url, hackathonData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getavailableprojectsToApplyService = async (filterData) => {
  const url =
    config.apiUrl + "/hackathons/" + filterData.id + "/available-projects/";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const addProjectMembersHackathonsService = async (dataAdd) => {
  const url =
    config.apiUrl +
    `/hackathons/` +
    dataAdd.idHackathon +
    "/" +
    dataAdd.idMember +
    "/" +
    dataAdd.idProject +
    "/invite-team-member";
  try {
    return await Api.post(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  createHackathonService,
  updateService,
  hackathonProjectsService,
  currentUserProjectsService,
  SubmissionForHackathonService,
  hackathonParticipantsService,
  unjoinedHackathonProjectsService,
  announceResultsService,
  addPrizeService,
  updatePrizeService,
  deletePrizeService,
};
