import {
  Text,
  Box,
  Flex,
  Button,
  Input,
  Textarea,
  Heading,
  Divider,
  HStack,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { BsX } from "react-icons/bs";
import { SocketContext } from "contexts/SocketContext";
import { editNews } from "reducers/news";
import { toast } from "react-toastify";
import BasicButton from "components/button/BasicButton";

export default function EditFormNew(props) {
  const { news, setShowEditInfos, onRelaod } = props;

  const dispatch = useDispatch();

  const status = useSelector((state) => state.News.editNew.status);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [tags, settags] = useState();

  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [tagsError, settagsError] = useState();

  const validateForm = () => {
    let inValid = false;

    if (!title) {
      setTitleError("The title is required!");
      inValid = true;
    } else {
      setTitleError("");
    }

    if (!description) {
      setDescriptionError("The description is required!");
      inValid = true;
    } else {
      setDescriptionError("");
    }
    if (tags.length <= 0) {
      settagsError("The tags is required!");
      inValid = true;
    } else {
      settagsError("");
    }

    return inValid;
  };
  const updateNewsAction = async () => {
    const inValid = validateForm();
    if (inValid) {
      return;
    }

    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    for (let i = 0; i < tags.length; i++) {
      data.append("tags", tags[i]);
    }

    const NewsData = {
      data,
      id: news._id,
    };
    await dispatch(editNews(NewsData)).unwrap();

    setShowEditInfos(false);
    onRelaod();
    toast.success("New has been updated successfully!", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });
  };
  const [currentTag, setCurrentTag] = useState("");
  const addTag = () => {
    settags((prevtag) => [...prevtag, currentTag]);
  };

  useEffect(() => {
    if (news) {
      setTitle(news.title);
      setDescription(news.description);
      settags(news.tags);
    }
  }, [news]);
  const removetags = (tag) => {
    const updatedData = tags.filter((item) => item !== tag);
    settags(updatedData);
  };

  return (
    <Box>
      <Card>
        <Heading size="sm" mb="2">
          Edit Informations
        </Heading>
        <Divider mb="2" />
        <Flex mt="2" direction={"column"}>
          <Input
            isInvalid={titleError}
            marginTop={"5px!important"}
            w={"100%"}
            marginRight="10px"
            placeholder="Title"
            borderRadius="5px"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {titleError && (
            <Text mt="3" color="red">
              {titleError}
            </Text>
          )}
        </Flex>
        {tags && tags.length > 0 && (
          <Flex direction={"column"} mt="15px">
            <HStack spacing={4}>
              {tags.map((i) => (
                <>
                  <Tag
                    size="lg"
                    colorScheme="blue"
                    variant={"outline"}
                    borderRadius="full"
                  >
                    <TagLabel mr="2">{i}</TagLabel>
                    <BsX
                      color="#343a40"
                      cursor={"pointer"}
                      onClick={() => removetags(i)}
                    />
                  </Tag>
                </>
              ))}
            </HStack>
            {tagsError && (
              <Text mt="3" color="red">
                {tagsError}
              </Text>
            )}
          </Flex>
        )}
        <Flex mt="2" direction={"column"}>
          <HStack spacing="4px" mb="2">
            <Input
              type="text"
              placeholder="Tags"
              name="Tags"
              value={currentTag}
              onChange={(ev) => {
                setCurrentTag(ev.target.value);
              }}
            />
            <Button
              variant={"outline"}
              onClick={() => {
                addTag();
              }}
            >
              +
            </Button>
          </HStack>
        </Flex>
        <Flex mt="2" direction={"column"}>
          <Textarea
            marginTop={"5px!important"}
            w={"100%"}
            marginRight="10px"
            placeholder="Description"
            borderRadius="5px"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {descriptionError && (
            <Text mt="3" color="red">
              {descriptionError}
            </Text>
          )}
        </Flex>

        <Flex justifyContent="end" mt="4">
          <BasicButton onClick={() => updateNewsAction()} title={"Confirm"} />
        </Flex>
      </Card>
    </Box>
  );
}
