import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCountOrganizationbycategoryService } from "services/organizationService";
import {
  addOrganizationService,
  getOrganizationsService,
  getOrganizationService
} from "services/organizationService";

export const addOrganization = createAsyncThunk(
  "organization/add",
  async (OrgData, thunkAPI) => {
    try {
      const { status, data } = await addOrganizationService(
        OrgData.Organizationdata
      );
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllOrganizations = createAsyncThunk(
  "organization/all",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getOrganizationsService(filterData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getOrganization = createAsyncThunk(
  "organization/by-id",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getOrganizationService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getCountOrganizationbycategory = createAsyncThunk(
  "organization/getCountOrganizationbycategory",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getCountOrganizationbycategoryService();
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  addorg: {
    status: "idle",
    data: {},
    error: {},
  },
  allOrganizations: {
    status: "idle",
    data: {},
    error: {},
  },
  organization: {
    status: "idle",
    data: {},
    error: {},
  },
  countOrganization: {
    status: "idle",
    data: {},
    error: {},
  },
};

const CohortSlice = createSlice({
  name: "cohorts",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [addOrganization.pending.type]: (state) => {
      state.addorg = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addOrganization.fulfilled.type]: (state, action) => {
      state.addorg = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addOrganization.rejected.type]: (state, action) => {
      state.addorg = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAllOrganizations.pending.type]: (state) => {
      state.allOrganizations = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllOrganizations.fulfilled.type]: (state, action) => {
      state.allOrganizations = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllOrganizations.rejected.type]: (state, action) => {
      state.allOrganizations = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getOrganization.pending.type]: (state) => {
      state.organization = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getOrganization.fulfilled.type]: (state, action) => {
      state.organization = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getOrganization.rejected.type]: (state, action) => {
      state.organization = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getCountOrganizationbycategory.pending.type]: (state) => {
      state.countOrganization = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getCountOrganizationbycategory.fulfilled.type]: (state, action) => {
      state.countOrganization = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getCountOrganizationbycategory.rejected.type]: (state, action) => {
      state.countOrganization = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default CohortSlice.reducer;
