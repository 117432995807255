import Card from "components/card/Card.js";
import {
  Text,
  Textarea,
  Flex,
  Button,
  Heading,
  Divider,
  InputGroup,
  InputLeftElement,
  Input,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import UploadFile from "components/button/UploadFile";
import { excelMimeTypes } from "utils/constants";
import { applyOpportunity } from "reducers/callofStartups";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getRequestCallofstartupById } from "reducers/callofStartups";

const Step7 = (props) => {
  const { applyData, setApplyData, setStep, requestCall } = props;
  const { idrequest, id } = useParams();
  const [fundingRequestedAmount, setFundingRequestedAmount] = useState(
    requestCall
      ? requestCall.FundingRequestedAmount
      : applyData.fundingRequestedAmount
  );
  const [fundingPurposeDescription, setFundingPurposeDescription] = useState(
    requestCall
      ? requestCall.FundingPurposeDescription
      : applyData.fundingPurposeDescription
  );
  const [fundingPurposeFile, setFundingPurposeFile] = useState(
    requestCall ? requestCall.FundingPurposeFile : applyData.fundingPurposeFile
  );
  // const [PreviousFunding, setPreviousFunding] = useState( requestCall ? requestCall.PreviousFunding : applyData.PreviousFunding);
  const [errorfundingRequestedAmount, setErrorFundingRequestedAmount] =
    useState("");
  const [PreviousFunding, setPreviousFunding] = useState([]);

  const [PreviousFundingTest, setPreviousFundingTest] = useState([]);
  const [inputCount, setInputCount] = useState(0);

  const [errorfundingPurposeDescription, setErrorFundingPurposeDescription] =
    useState("");
  const [errorfundingPurposeFile, setErrorFundingPurposeFile] = useState("");
  const [errorPreviousFunding, setErrorPreviousFunding] = useState("");
  const dispatch = useDispatch();
  const validateForm = () => {
    let valid = true;
    if (!fundingRequestedAmount) {
      setErrorFundingRequestedAmount("This filed are required");
      valid = false;
    } else {
      setErrorFundingRequestedAmount("");
    }
    if (!fundingPurposeDescription) {
      setErrorFundingPurposeDescription("This filed are required");
      valid = false;
    } else {
      setErrorFundingPurposeDescription("");
    }
    if (!fundingPurposeFile) {
      setErrorFundingPurposeFile("This filed are required");
      valid = false;
    } else {
      setErrorFundingPurposeFile("");
    }
    if (inputCount == 0) {
      setErrorPreviousFunding("This filed are required");
      valid = false;
    } else {
      setErrorPreviousFunding("");
    }

    return valid;
  };
  const confirm = async () => {
    if (!validateForm()) {
      return;
    }
    const Oppdata = new FormData();
    Oppdata.append("callOfStartup", id);
    Oppdata.append(
      "project",
      requestCall ? requestCall.project._id : applyData.project
    );
    Oppdata.append("FundingRequestedAmount", fundingRequestedAmount);
    Oppdata.append("FundingPurposeDescription", fundingPurposeDescription);
    Oppdata.append("FundingPurposeFile", fundingPurposeFile[0]);
    Oppdata.append("PreviousFunding", PreviousFunding);
    Oppdata.append("step", 8);

    const dataaddOpp = {
      Oppdata,
    };
    console.log(fundingPurposeFile[0], "fundingPurposeFile[0]");
    await dispatch(applyOpportunity(dataaddOpp)).unwrap();
    const data = {
      ...applyData,
      fundingRequestedAmount,
      fundingPurposeDescription,
      fundingPurposeFile,
      PreviousFunding,
    };
    setApplyData(data);
    setStep(8);
  };
  const back = () => {
    setStep(6);
    const data = {
      ...applyData,
      fundingRequestedAmount: "",
      fundingPurposeDescription: "",
      fundingPurposeFile: "",
      PreviousFunding: "",
    };
    setApplyData(data);
  };
  const Upfiles = (file) => {
    setFundingPurposeFile(file);
  };

  const handleAddInput = () => {
    setInputCount((prevCount) => prevCount + 1);
    PreviousFunding.push(PreviousFundingTest);
    console.log(PreviousFunding, "PreviousFunding");
  };
  useEffect(() => {
    console.log(PreviousFundingTest, "PreviousFundingTest");
  }, [PreviousFundingTest]);
  return (
    <Card>
      <Heading size="sm" mb="2">
        Funding
      </Heading>
      <Divider mb="2" />
      <Flex mt="2" flexDirection={"column"}>
        <Text>Amount of Funding Requested</Text>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children="$"
          />
          <Input
            value={fundingRequestedAmount}
            onChange={(e) => {
              setFundingRequestedAmount(e.target.value);
            }}
            placeholder="Amount of Funding Requested"
          />
        </InputGroup>
        {errorfundingRequestedAmount && (
          <Text color="red">{errorfundingRequestedAmount}</Text>
        )}
      </Flex>
      <Flex mt="2" flexDirection={"column"}>
        <Text>Purpose of Funding</Text>
        <Textarea
          mb="2"
          value={fundingPurposeDescription}
          onChange={(e) => {
            setFundingPurposeDescription(e.target.value);
          }}
          placeholder="Purpose of Funding"
        />
        {errorfundingPurposeDescription && (
          <Text color="red">{errorfundingPurposeDescription}</Text>
        )}

        <Text>You can add XLS file that includes financial projections</Text>

        <UploadFile
          setFiles={Upfiles}
          allowedTypes={excelMimeTypes}
          title={"upload XLS file"}
        />
        {errorfundingPurposeFile && (
          <Text color="red">{errorfundingPurposeFile}</Text>
        )}
      </Flex>
      {/* 
      <Flex mt="2" flexDirection={"column"}>
        <Text>Previous Funding</Text>
        <Textarea
          mb="2"
          value={PreviousFunding}
          onChange={(e) => {
            setPreviousFunding(e.target.value);
          }}
          placeholder="Previous Funding"
        />
        {errorPreviousFunding && (
          <Text color="red">{errorPreviousFunding}</Text>
        )}
      </Flex> */}
      <Text>Previous Funding</Text>

      <VStack spacing={4}>
        {Array.from({ length: inputCount }, (_, index) => (
          <Input
            key={index}
            placeholder={`Previous Funding ${index + 1}`}
            onChange={(e) => {
              setPreviousFundingTest(e.target.value);
            }}
          />
        ))}
      
        <Button onClick={handleAddInput}>Add Previous Funding</Button>
      </VStack>
      {errorPreviousFunding && (
          <Text color="red">{errorPreviousFunding}</Text>
        )}
      <Flex justifyContent="end" mt="4">
        <Button
          colorScheme="blue"
          variant="outline"
          size="md"
          onClick={back}
          mr="2"
        >
          Back
        </Button>
        <Button colorScheme="blue" size="md" onClick={confirm}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};

export default Step7;
