import Card from "components/card/Card.js";
import {
  Text,
  Box,
  Flex,
  SimpleGrid,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import config from "config/axios/config";
import { getbyidGrants } from "reducers/grants";
import Information from "../ExpertProfile/components/Information";
import moment from "moment";
import GrantApplication from "./components/GrantApplication";
import BannerImage from "components/image/BannerImage";
import Loader from "components/loader/Loader";
import { useState } from "react";
import { CloseIcon, EditIcon } from "@chakra-ui/icons";
import EditFormGrant from "./components/editGrant/EditFormGrant";
import { updategrants } from "reducers/grants";
const GrantsDetails = () => {
  const { id } = useParams();
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const [userType, setUsetType] = useState("");
  const [photo, setPhoto] = useState([]);
  const [photoError, setPhotoError] = useState("");
  const [showEditInfos, setShowEditInfos] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const grants = useSelector((state) =>
    state.Grants.getbyidgrantsState.data
      ? state.Grants.getbyidgrantsState.data.result
      : null
  );
  const grantsApp = useSelector((state) =>
    state.Grants.getbyidgrantsState.data
      ? state.Grants.getbyidgrantsState.data.grantApplications
      : null
  );

  const getCohortByIdAction = async () => {
    await dispatch(getbyidGrants(id)).unwrap();
  };

  useEffect(() => {
    const userData = jwt_decode(localStorage.getItem("token"));
    setUsetType(userData ? userData.userType : "");
    getCohortByIdAction();
    setReload(false);
  }, [id, reload]);

  const validateForm = () => {
    let inValid = false;
    if (!photo) {
      setPhotoError("The image is required!");
      inValid = true;
    } else {
      setPhotoError("");
    }
    return inValid;
  };

  const updateImageAction = async () => {
    const inValid = validateForm();
    if (inValid) {
      return;
    }
    const data = new FormData();
    data.append("image", photo[0]);
    const GrantData = {
      data,
      id: id,
    };
    await dispatch(updategrants(GrantData)).unwrap();
    getCohortByIdAction();
  };
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {!grants && <Loader />}

        {grants && (
          <Card mt="3" mb="3">
            <BannerImage
              src={config.apiUrl + "/grant/" + grants.Image}
              onEdit={updateImageAction}
              image={photo}
              setImage={setPhoto}
              imageError={photoError}
              canEdit={userType == "Admin" ? true : false}
            />
            <Flex justifyContent={"flex-end"} mt={"15px"}>
              {!showEditInfos && (
                <Icon as={EditIcon} onClick={() => setShowEditInfos(true)} />
              )}
              {showEditInfos && (
                <Icon
                  as={CloseIcon}
                  fontSize="12px"
                  onClick={() => setShowEditInfos(false)}
                />
              )}
            </Flex>
            {showEditInfos && (
              <EditFormGrant
                grant={grants}
                setShowEditInfos={setShowEditInfos}
                onRelaod={() => setReload(true)}
              />
            )}
            {!showEditInfos && (
              <>
                {" "}
                <Flex
                  mt="2"
                  w="100%"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Text
                    color={textColorPrimary}
                    fontWeight="bold"
                    fontSize="2xl"
                    mt="10px"
                    mb="4px"
                  >
                    {grants.name}{" "}
                  </Text>
                </Flex>
                <Text color={textColorSecondary} fontSize="md" mb="20px">
                  {grants.description}{" "}
                </Text>
                <SimpleGrid columns="2" gap="20px">
                  <Information
                    boxShadow={cardShadow}
                    title="Max Allowed Budget"
                    value={grants.maxAllowedBudget}
                  />
                  <Information
                    boxShadow={cardShadow}
                    title="Min Allowed Budget"
                    value={grants.minAllowedBudget}
                  />
                  <Information
                    boxShadow={cardShadow}
                    title="Start Date"
                    value={moment(grants.startDate).format("ll")}
                  />
                  <Information
                    boxShadow={cardShadow}
                    title="End Date"
                    value={moment(grants.endDate).format("ll")}
                  />
                  <Information
                    boxShadow={cardShadow}
                    title="Max Per Project"
                    value={grants.maxPerProject}
                  />
                  <Information
                    boxShadow={cardShadow}
                    title="total Fund Value"
                    value={grants.totalFundValue}
                  />
                </SimpleGrid>
              </>
            )}
            <GrantApplication grantsApp={grantsApp}></GrantApplication>
          </Card>
        )}
      </Box>
    </>
  );
};

export default GrantsDetails;
