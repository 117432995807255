import React from "react";
import { useParams } from "react-router-dom";
import config from "config/axios/config";
import {
  Flex,
  Spinner,
  Text,
  Icon,
  Image,
  Avatar,
  useColorModeValue,
  SimpleGrid,
  Heading,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Link,
  Badge,
} from "@chakra-ui/react";
import { Link as Linkk } from "react-router-dom";

import Card from "components/card/Card";
import { useEffect, useState } from "react";
import Information from "views/admin/profile/components/Information";
import { BsTwitter, BsGithub, BsLinkedin } from "react-icons/bs";
import { getProjectById } from "reducers/project";
import { useDispatch, useSelector } from "react-redux";
import placeholder from "assets/img/placeholder.jpg";
import jwt_decode from "jwt-decode";
import { getRequestCallofstartupById } from "reducers/callofStartups";

const CallOfStartupRequestDetails = () => {
  const brandColor = useColorModeValue("brand.500", "white");
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [userType, setUsetType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const requestCall = useSelector((state) =>
  state.CallofStartups.requestCallofstartupById.data
  ? state.CallofStartups.requestCallofstartupById.data.result
  : []  );
  const status = useSelector((state) => state.CallofStartups.requestCallofstartupById.status);
  const Vote = useSelector((state) =>
    state.Project.project.data ? state.Project.project.data : null
  );
  const getRequestCallofstartupByIdAction = async () => {
    await dispatch(getRequestCallofstartupById(id)).unwrap();
  };
  useEffect(() => {
    const userData = jwt_decode(localStorage.getItem("token"));
    setUsetType(userData ? userData.userType : "");
    getRequestCallofstartupByIdAction();
    console.log(requestCall,'zzzzzz')
  }, []);
    const download = async(FichierName) => {
      console.log(config.apiUrl + "/prcs/file/" + FichierName)
    window.open(config.apiUrl + "/prcs/file/" + FichierName);

  };

  
  return (
    <>
      <Flex
        flexDirection={"column"}
        pt={{ base: "130px", md: "80px", xl: "80px" }}
      >
              {requestCall && (

          <>
            <Card mt="3" mb="3">
             
              <Image
                w={"100%"}
                h={"220px"}
                borderRadius="20px"
                objectFit="cover"
                bg={"white"}
                border="3px solid"
                mb="10px"
                borderColor={borderColor}
                boxShadow={"md"}
                fallbackSrc={placeholder}
                src={
                 config.apiUrl + "/project/file/" + requestCall.project.images
                }
              />
              <Flex
                mt="2"
                w="100%"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
             
              </Flex>
         
            <SimpleGrid columns="2" gap="20px">
                <Information
                  boxShadow={cardShadow}
                  title="Project Name"
                  value={  <Text fontWeight="normal" color="blue">
                  <Linkk to={"/admin/project/" + requestCall.project._id} >{requestCall.projecName} </Linkk>
                </Text>}
                />
                 <Information
                  boxShadow={cardShadow}
                  title="business Description"
                  value={requestCall.businessDescription}
                />
                  <Information
                  boxShadow={cardShadow}
                  title="Target Market"
                  value={requestCall.targetMarket}
                />
                  <Information
                  boxShadow={cardShadow}
                  title="Unique Selling Proposition"
                  value={requestCall.UniqueSellingProposition}
                />
                  <Information
                  boxShadow={cardShadow}
                  title="Market Size"
                  value={requestCall.MarketSize}
                />
                  <Information
                  boxShadow={cardShadow}
                  title="Competitive Landscape"
                  value={requestCall.CompetitiveLandscape}
                />
                 <Information
                  boxShadow={cardShadow}
                  title="Business Description"
                  value={requestCall.businessDescription}
                />
                 <Information
                  boxShadow={cardShadow}
                  title="Stage of Development"
                  value={requestCall.DevelopmentStage}
                />
                 <Information
                  boxShadow={cardShadow}
                  title="Key Milestones Achieved"
                  value={requestCall.KeyMilestonesAchieved.map((Key) => (
                    <Badge
                      colorScheme="blue"
                      p="2px 0px"
                      mr="1"
                      variant="outline"
                    >
                      <Text
                        fontSize="10px"
                        width="100%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {Key}
                      </Text>
                    </Badge>
                  ))}
                />
                 <Information
                  boxShadow={cardShadow}
                  title="Current Annual Revenue"
                  value={requestCall.currentAnnualRevenue}
                />
                 <Information
                  boxShadow={cardShadow}
                  title="Current Number of Customers"
                  value={requestCall.CurrentCustomersNumber}
                />
                {/* <Information
                  boxShadow={cardShadow}
                  title="Monthly Burn Rate"
                  value={requestCall.ProjectedAnnualRevenue.map((Key) => (
                    <Badge
                      colorScheme="blue"
                      p="2px 0px"
                      mr="1"
                      variant="outline"
                    >
                      <Text
                        fontSize="10px"
                        width="100%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {Key.year} <br></br>
                        {Key.amount}

                      </Text>
                    </Badge>
                  ))}
                />*/}
                <Information 
                  boxShadow={cardShadow}
                  title="Monthly Burn Rate"
                  value={requestCall.MonthlyBurnRate}
                />
                 <Information 
                  boxShadow={cardShadow}
                  title="Funding Requested Amount"
                  value={requestCall.FundingRequestedAmount}
                />
                 <Information 
                  boxShadow={cardShadow}
                  title="Funding Purpose Description"
                  value={requestCall.FundingPurposeDescription}
                />
                 <Information 
                  boxShadow={cardShadow}
                  title="Monthly Burn Rate"
                  value={requestCall.MonthlyBurnRate}
                />
                <Information 
                  boxShadow={cardShadow}
                  title="Attachments"
                  value={<>
                   <Text fontSize="sm" fontWeight="600" color="blue">
                                <Linkk onClick={() => download(requestCall.demo)} >{requestCall.demo}</Linkk>
                              </Text>
                              <Text fontSize="sm" fontWeight="600" color="blue">
                                <Linkk onClick={() => download(requestCall.pitchDeck)}>{requestCall.pitchDeck}</Linkk>
                              </Text>
                              <Text fontSize="sm" fontWeight="600" color="blue">
                                <Linkk onClick={() => download(requestCall.businessPlan)}>{requestCall.businessPlan}</Linkk>
                              </Text>
                              <Text fontSize="sm" fontWeight="600" color="blue">
                                <Linkk onClick={() => download(requestCall.signature)}>{requestCall.signature}</Linkk>
                              </Text>
                  </>}

                />
              </SimpleGrid> 
            </Card>
      
        
          </>
              )}
      </Flex>
    </>
  );
};

export default CallOfStartupRequestDetails;
