import React, { useState } from "react";
import {
  Input,
  useColorModeValue,
  Flex,
  Text,
  Textarea,
  FormLabel,
  Box,
  Image,
  Checkbox,
  Badge,
  Select,
  HStack,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import MySelect from "react-select";
import placeholder from "assets/img/placeholder.jpg";

import BasicButton from "components/button/BasicButton";
import InputField from "components/fields/InputField";
import CardItem from "./CardItem";
import { useEffect } from "react";
import { getAllOrganizations } from "reducers/organization";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import Pagination from "components/pagination/Pagination";
import { sectors } from "utils/constants";
import TextCard from "components/card/TextCard";
import { getAvailablePartnersInAssistingProgramById } from "reducers/assistingProgram";
import { update } from "reducers/assistingProgram";

const AddPartnersModal = ({ isOpen, onClose, id, partners, onReload }) => {
  const dispatch = useDispatch();
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [name, setName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedStackHolders, setSelectedStackHolders] = useState([]);

  const organizations = useSelector((state) =>
    state.AssistingProgram.getAvailablePartners.data
      ? state.AssistingProgram.getAvailablePartners.data
      : []
  );
  const totalPages = useSelector(
    (state) => state.AssistingProgram.getAvailablePartners.data.totalPages
  );
  const togglePartners = (stackHolder) => {
    const x = [...selectedStackHolders];
    let exist = false;
    x.map((item) => {
      if (item._id === stackHolder._id) {
        exist = true;
      }
    });
    if (exist) {
      const res = x.filter((item) => item._id !== stackHolder._id);
      setSelectedStackHolders(res);
    } else {
      x.push(stackHolder);
      setSelectedStackHolders(x);
    }
  };
  const getAvailablePartners = async () => {
    // const categories = selectedOptions.map((item) => item.value);
    // const filterdata = {
    //   page: page,
    //   limit: 5,
    //   name: name,
    //   investmentRange: "",
    //   categories: categories,
    // };
    await dispatch(getAvailablePartnersInAssistingProgramById(id)).unwrap();
  };

  useEffect(() => {
    getAvailablePartners();
  }, [name, selectedOptions, page]);

  const updatePartnersHandler = async () => {
    const partnerid = partners.map((item) => item._id);
    const idsStackHolder = selectedStackHolders.map((item) => item._id);
    const combinedArray = [...partnerid, ...idsStackHolder];

    const data = new FormData();
    data.append("partners", JSON.stringify(combinedArray));
    const programData = {
      data,
      id: id,
    };
    await dispatch(update(programData)).unwrap();
    onReload();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Partner</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            {/* <Card>
              <HStack spacing={2}>
                <Input
                  variant="outline"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                />
              </HStack>
              <Flex mt="2" direction={"column"}>
                <MySelect
                  className="InputGroupy"
                  placeholder="Select Categories"
                  options={sectors}
                  value={selectedOptions}
                  onChange={setSelectedOptions}
                  isMulti
                />
              </Flex>
            </Card> */}
            <Flex direction={"column"}>
              {organizations && organizations.length > 0 && (
                <Box>
                  {organizations.map((item) => (
                    <Card p="1" mb="2" boxShadow={cardShadow}>
                      <Flex>
                        <Flex
                          width="100%"
                          height="100%"
                          padding="0.6rem"
                          fontWeight="bold"
                          color={textColor}
                          fontSize="md"
                          textAlign="start"
                        >
                          <Image
                            h="50px"
                            w="50px"
                            //   src={config.apiUrl + `/project/file/` + item.images}
                            borderRadius="8px"
                            fallbackSrc={placeholder}
                            objectFit="cover"
                          />
                          <Box ml="3">
                            <Text fontWeight="normal">{item.name}</Text>
                            <Text fontSize="sm">
                              {" "}
                              {item.categories.map((category) => (
                                <Badge
                                  colorScheme="pink"
                                  p="2px 0px"
                                  mr="1"
                                  variant="outline"
                                >
                                  <Text
                                    fontSize="10px"
                                    width="100%"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                  >
                                    {category}
                                  </Text>
                                </Badge>
                              ))}
                            </Text>
                          </Box>
                        </Flex>
                        <Checkbox
                          me="16px"
                          colorScheme="brandScheme"
                          borderColor={"#3965ff"}
                          onChange={() => togglePartners(item)}
                        />
                      </Flex>
                    </Card>
                  ))}
                  {totalPages > 1 && (
                    <Center pt="5">
                      <Pagination
                        page={page}
                        totalPages={totalPages}
                        setPage={setPage}
                        setLoader={() => {}}
                      />
                    </Center>
                  )}
                </Box>
              )}
              {organizations && organizations.length == 0 && (
                <TextCard text="There is no Partners" />
              )}
            </Flex>
          </Flex>{" "}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={updatePartnersHandler}>
            Add Partner
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddPartnersModal;
