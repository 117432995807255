import React from "react";
import jwt_decode from "jwt-decode";
import { Icon } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { BiGroup, BiHistory } from "react-icons/bi";
import {
  MdFolderOpen,
  MdPerson,
  MdOutlineAssistant,
  MdHome,
  MdLock,
  MdGroup,
  MdGroups,
  MdModelTraining,
  MdOutbox,
  MdPayment,
  MdEmojiEvents,
  MdOutlineEvent,
  MdOutlineRateReview,
  MdOutlineSchool,
  MdNotificationsNone,
  MdSettings,
  MdOutlineHelp,
} from "react-icons/md";
import {
  FaDonate,
  FaRegBuilding,
  FaRegNewspaper,
  FaChalkboardTeacher,
} from "react-icons/fa";
import ProfileSettings from "views/admin/profile/profileSettings";
import Users from "views/admin/users";
import Profile from "views/admin/profile";
import News from "views/admin/news";
import RTL from "views/admin/rtl";
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";
import Experts from "views/admin/experts";
import forgetPassword from "views/auth/forgetPassword/forgetPassword";
import TwoAuth from "views/auth/TwoAuth/TwoAuth";
import VerifyCallback from "views/auth/verifyCallback/verifyCallback";
import resetPassword from "views/auth/resetPassword/resetPassword";
import CalendarContainer from "views/admin/profile/Calendar";
import Events from "views/admin/events";
import ExpertCalendar from "views/admin/ExpertCalendar";
import EventDetails from "views/admin/events/eventDetails";
import HistoriqueMeetings from "views/admin/HistoriqueMeetings";
import HistoriqueMeetingsExpert from "views/admin/HistoriqueMeetingsExpert";
import NotificationsSettings from "views/admin/NotificationsSettings";
import AddEvent from "views/admin/events/addEvent";
import NewDetails from "views/admin/news/NewDetails";
import Administrators from "views/admin/administrators";
import FelecitationPayment from "views/admin/HistoriqueMeetings/FelecitationPayment";
import FailPayment from "views/admin/HistoriqueMeetings/FailPayment";
import AddReview from "views/admin/HistoriqueMeetings/addReview";
import AddReviewProjectHolder from "views/admin/HistoriqueMeetingsExpert/addReviewProjectHolder";
import Index from "views/admin/HistoriqueReview";
import ViewHistoriqueReview from "views/admin/HistoriqueReview/viewHistoriqueReview";
import Organizations from "views/admin/organizations";
import OrganizationDetails from "views/admin/organizations/OrganizationDetails";
import ApplyForOpportunity from "views/admin/callofStartups/ApplyForOpportunity";
import CallForStartupsDetails from "views/admin/callofStartups/CallForStartupsDetails";
import CallOfStartupRequestDetails from "views/admin/callofStartups/CallOfStartupRequestDetails";
import AddCallOfStartups from "views/admin/callofStartups/AddCallOfStartups";
import AddGrants from "views/admin/grants/AddGrants";
import Grants from "views/admin/grants";
import GrantsDetails from "views/admin/grants/GrantsDetails";
import ApplyGrants from "views/admin/grants/ApplyGrants";
import GrantsApplicationsDetails from "views/admin/grants/GrantsApplicationsDetails";
import Academy from "views/admin/academy";
import AddTrack from "views/admin/tracks/AddTrack";
import TrackDetails from "views/admin/tracks/TrackDetails";
import AddHackathon from "views/admin/hackathons/AddHackathon";
import HackathonDetails from "views/admin/hackathons/HackathonDetails";
import Hackathons from "views/admin/hackathons";
import Guide from "views/admin/guide";
import Dashboard from "views/admin/dashboard";
import AddOrganization from "views/admin/organizations/AddOrganization";
import Projects from "views/admin/projects";
import ProjectDetails from "views/admin/projects/ProjectDetails";
import AddProject from "views/admin/projects/AddProject";
import NewMemberConfirmation from "views/admin/projects/NewMemberConfirmation";
import AssistingProgramDetails from "views/admin/assistingPrograms/AssistingProgramDetails";
import AddAssistingProgram from "views/admin/assistingPrograms/AddAssistingProgram";
import AssistingPrograms from "views/admin/assistingPrograms";
import ProgramPhaseDetails from "views/admin/programPhases/ProgramPhaseDetails";
import AddProgramPhase from "views/admin/programPhases/AddProgramPhase";
import LandingPage from "views/auth/landingPage";
import ChatMessages from "views/admin/chat";
import Workshops from "views/admin/workshops";

let USER_TYPE = "";

if (localStorage.getItem("token")) {
  USER_TYPE = jwt_decode(localStorage.getItem("token")).userType;
}

const routes =
  USER_TYPE === "Admin" || USER_TYPE === "SuperAdmin"
    ? [
        {
          name: "Dashboard",
          layout: "/admin",
          path: "/default",
          icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
          component: Dashboard,
        },
        {
          name: "Ecosystem Members",
          layout: "/admin",
          path: "/users",
          component: Users,
          icon: (
            <Icon as={MdGroups} width="22px" height="22px" color="inherit" />
          ),
        },
        USER_TYPE === "SuperAdmin" && {
          name: "Administrators",
          layout: "/admin",
          path: "/admins",
          component: Administrators,
          icon: (
            <Icon as={BiGroup} width="20px" height="20px" color="inherit" />
          ),
        },
        {
          name: "Experts",
          layout: "/admin",
          path: "/experts",
          icon: (
            <Icon
              width="20px"
              height="20px"
              color="inherit"
              as={MdGroup}
            ></Icon>
          ),
          component: Experts,
          secondary: true,
        },
        {
          name: "Projects",
          layout: "/admin",
          path: "/projects",
          icon: (
            <Icon
              as={MdFolderOpen}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: Projects,
        },
        {
          // name will be updated with redux to current expert Profile
          name: "Expert profile",
          layout: "/admin",
          path: "/expert/profile/:id",
          secondary: true,
          // component is expert profile
          component: Profile,
        },

        {
          name: "Project",
          layout: "/admin",
          path: "/project/:id",
          component: ProjectDetails,
        },
        {
          name: "Assisting Programs",
          layout: "/admin",
          path: "/assisting-programs",
          icon: (
            <Icon
              as={MdOutlineAssistant}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: AssistingPrograms,
        },
        {
          name: "Hackathons",
          layout: "/admin",
          path: "/hackathons",
          icon: (
            <Icon
              as={MdEmojiEvents}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: Hackathons,
        },
        {
          name: "add Grants",
          layout: "/admin",
          path: "/addGrants",
          icon: (
            <Icon as={MdOutbox} width="20px" height="20px" color="inherit" />
          ),
          component: AddGrants,
        },
        // {
        //   name: "Grants",
        //   layout: "/admin",
        //   path: "/grants",
        //   icon: (
        //     <Icon as={FaDonate} width="20px" height="20px" color="inherit" />
        //   ),
        //   component: Grants,
        // },
        {
          name: "Grants Details",
          layout: "/admin",
          path: "/grantsdetails/:id",
          icon: (
            <Icon as={MdOutbox} width="20px" height="20px" color="inherit" />
          ),
          component: GrantsDetails,
        },
        {
          name: "Grant Application Details",
          layout: "/admin",
          path: "/grantsApplicationdetails/:id",
          icon: (
            <Icon as={MdOutbox} width="20px" height="20px" color="inherit" />
          ),
          component: GrantsApplicationsDetails,
        },
        {
          name: "Workshops",
          layout: "/admin",
          path: "/workshops",
          icon: (
            <Icon
              as={FaChalkboardTeacher}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: Workshops,
        },
        {
          name: "Meeting Histories",
          layout: "/admin",
          path: "/meeting-hostories",
          secondary: true,
          component: Index,
          icon: (
            <Icon
              as={MdOutlineRateReview}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        {
          name: "Review Details",
          layout: "/admin",
          path: "/ReviewDetails/:id",
          secondary: true,
          component: ViewHistoriqueReview,
          icon: (
            <Icon as={BiHistory} width="20px" height="20px" color="inherit" />
          ),
        },
        {
          name: "Events",
          layout: "/admin",
          path: "/events/",
          component: Events,
          icon: (
            <Icon
              as={MdOutlineEvent}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },

        {
          name: "Event",
          layout: "/admin",
          path: "/event/:id",
          component: EventDetails,
        },
        {
          name: "Add Event",
          layout: "/admin",
          path: "/add-event",
          secondary: true,
          component: AddEvent,
        },
        {
          name: "Program Phase Details",
          layout: "/admin",
          path: "/program-phases/:id",
          component: ProgramPhaseDetails,
        },
        {
          name: "Track Details",
          layout: "/admin",
          path: "/Track-Details/:id",
          component: TrackDetails,
        },
        {
          name: "News",
          layout: "/admin",
          path: "/news",
          component: News,
          icon: (
            <Icon
              as={FaRegNewspaper}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        // {
        //   name: " Calls for voting",
        //   layout: "/admin",
        //   path: "/session",
        //   component: Session,
        //   icon: (
        //     <Icon
        //       as={MdOutlineCallToAction}
        //       width="20px"
        //       height="20px"
        //       color="inherit"
        //     />
        //   ),
        // },
        {
          name: "Add Assisting Program",
          layout: "/admin",
          path: "/add-assisting-program",
          secondary: true,
          component: AddAssistingProgram,
        },
        {
          name: "Add Hackathon",
          layout: "/admin",
          path: "/add-Hackathon",
          secondary: true,
          component: AddHackathon,
        },
        {
          name: "Assisting Program Details",
          layout: "/admin",
          path: "/assisting-program/:id",
          component: AssistingProgramDetails,
        },
        {
          name: "Hackathon Details",
          layout: "/admin",
          path: "/HackathonDetails/:id",
          component: HackathonDetails,
        },
        {
          name: "",
          layout: "/admin",
          path: "/add-program-phase",
          secondary: true,
          component: AddProgramPhase,
        },
        {
          name: "",
          layout: "/admin",
          path: "/add-call-for-track",
          secondary: true,
          component: AddTrack,
        },

        {
          name: "Organizations",
          layout: "/admin",
          path: "/organizations",
          icon: (
            <Icon
              as={FaRegBuilding}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: Organizations,
        },
        {
          name: "Academy",
          layout: "/admin",
          path: "/academy",
          icon: (
            <Icon
              as={MdOutlineSchool}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: Academy,
        },
        {
          name: "Organization Details",
          layout: "/admin",
          path: "/organization/:id",
          secondary: true,
          component: OrganizationDetails,
        },
        {
          name: "Call For Startups Details",
          layout: "/admin",
          path: "/CallForStartups/:id",
          secondary: true,
          component: CallForStartupsDetails,
        },
        {
          name: "Notifications Settings",
          layout: "/admin",
          path: "/notifications-settings",
          component: NotificationsSettings,
          icon: (
            <Icon
              as={MdNotificationsNone}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        {
          name: "News",
          layout: "/admin",
          path: "/new/:id",
          component: NewDetails,
        },
        {
          name: "Chat",
          layout: "/admin",
          path: "/chat/",
          component: ChatMessages,
        },
        {
          name: "Platform Guide",
          layout: "/admin",
          path: "/platform-guide",
          component: Guide,
          icon: (
            <Icon
              as={MdOutlineHelp}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
      ]
    : [
        (USER_TYPE === "Investor" || USER_TYPE === "ProjectHolder") && {
          name: "Call Of Startup Details",
          layout: "/admin",
          path: "/CallOfStartupDetails/:id",
          secondary: true,
          component: CallOfStartupRequestDetails,
        },
        USER_TYPE === "ProjectHolder" && {
          name: "Session Review",
          layout: "/admin",
          path: "/add-Review/:id",
          secondary: true,
          component: AddReview,
        },
        USER_TYPE === "ProjectHolder" && {
          name: "Apply Grants",
          layout: "/admin",
          path: "/ApplyGrants/:id",
          secondary: true,
          component: ApplyGrants,
        },
        USER_TYPE === "ProjectHolder" && {
          name: "Request accepted",
          layout: "/admin",
          path: "/Requestaccepted/:id",
          secondary: true,
          component: NewMemberConfirmation,
        },
        USER_TYPE === "Expert" && {
          name: "Session Review",
          layout: "/admin",
          path: "/add-Review-Expert/:id",
          secondary: true,
          component: AddReviewProjectHolder,
        },
        USER_TYPE === "Investor" && {
          name: "Add Organization",
          layout: "/admin",
          path: "/Add-Organization",
          secondary: true,
          component: AddOrganization,
        },
        USER_TYPE === "ProjectHolder" && {
          name: "Apply",
          layout: "/admin",
          path: "/Apply-Opportunity/:id/:idrequest",
          secondary: true,
          component: ApplyForOpportunity,
        },
        USER_TYPE === "ProjectHolder" && {
          name: "Apply",
          layout: "/admin",
          path: "/NewApply-Opportunity/:id",
          secondary: true,
          component: ApplyForOpportunity,
        },
        USER_TYPE === "Investor" && {
          name: "Add a call for Startups",
          layout: "/admin",
          path: "/Add-Opportunity/:id",
          secondary: true,
          component: AddCallOfStartups,
        },
        {
          name: "Dashboard",
          layout: "/admin",
          path: "/default",
          icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
          component: Dashboard,
        },
        {
          name: "Program Phase Details",
          layout: "/admin",
          path: "/program-phases/:id",
          component: ProgramPhaseDetails,
        },
        {
          name: "Track Details",
          layout: "/admin",
          path: "/Track-Details/:id",
          component: TrackDetails,
        },
        // (USER_TYPE === "Investor" || USER_TYPE === "ProjectHolder") && {
        //   name: "Calendar",
        //   layout: "/admin",
        //   path: "/my-calendar",
        //   component: Calendar,
        //   icon: (
        //     <Icon
        //       as={CalendarIcon}
        //       width="20px"
        //       height="20px"
        //       color="inherit"
        //     />
        //   ),
        // },
        {
          name: "Profile",
          layout: "/admin",
          path: "/profile",
          icon: (
            <Icon as={MdPerson} width="20px" height="20px" color="inherit" />
          ),
          component: Profile,
        },
        {
          name: "Projects",
          layout: "/admin",
          path: "/projects",
          icon: (
            <Icon
              as={MdFolderOpen}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: Projects,
        },
        (USER_TYPE === "community" || USER_TYPE === "ProjectHolder") && {
          name: "Add Project",
          layout: "/admin",
          path: "/add-project",
          component: AddProject,
        },
        (USER_TYPE === "Investor" || USER_TYPE === "ProjectHolder") && {
          name: "Organization Details",
          layout: "/admin",
          path: "/organization/:id",
          secondary: true,
          component: OrganizationDetails,
        },
        (USER_TYPE === "Investor" || USER_TYPE === "ProjectHolder") && {
          name: "Call For Startups Details",
          layout: "/admin",
          path: "/CallForStartups/:id",
          secondary: true,
          component: CallForStartupsDetails,
        },
        {
          name: "Profile Settings",
          layout: "/admin",
          path: "/settings",
          component: ProfileSettings,
        },
        {
          // name will be updated with redux to current expert Profile
          name: "Expert profile",
          layout: "/admin",
          path: "/expert/profile/:id",
          secondary: true,
          // component is expert profile
          component: Profile,
        },
        {
          name: "Expert Calendar",
          layout: "/admin",
          path: "/expert/calendar/:id",
          secondary: true,
          component: ExpertCalendar,
        },
        {
          name: "Project",
          layout: "/admin",
          path: "/project/:id",
          component: ProjectDetails,
        },
        {
          name: "News",
          layout: "/admin",
          path: "/new/:id",
          component: NewDetails,
        },

        {
          name: "Experts",
          layout: "/admin",
          path: "/experts",
          icon: (
            <Icon
              width="20px"
              height="20px"
              color="inherit"
              as={MdGroup}
            ></Icon>
          ),
          component: Experts,
          secondary: true,
        },
        {
          name: "Calendar",
          layout: "/admin",
          path: "/calendar",
          component: CalendarContainer,
          icon: (
            <Icon
              as={CalendarIcon}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        {
          name: "Grants",
          layout: "/admin",
          path: "/grants",
          icon: (
            <Icon as={FaDonate} width="20px" height="20px" color="inherit" />
          ),
          component: Grants,
        },
        {
          name: "Grants Details",
          layout: "/admin",
          path: "/grantsdetails/:id",
          icon: (
            <Icon as={MdOutbox} width="20px" height="20px" color="inherit" />
          ),
          component: GrantsDetails,
        },
        {
          name: "Grant Application Details",
          layout: "/admin",
          path: "/grantsApplicationdetails/:id",
          icon: (
            <Icon as={MdOutbox} width="20px" height="20px" color="inherit" />
          ),
          component: GrantsApplicationsDetails,
        },
        {
          name: "Assisting Programs",
          layout: "/admin",
          path: "/assisting-programs",
          icon: (
            <Icon
              as={MdOutlineAssistant}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: AssistingPrograms,
        },
        {
          name: "Hackathons",
          layout: "/admin",
          path: "/hackathons",
          icon: (
            <Icon
              as={MdEmojiEvents}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: Hackathons,
        },
        {
          name: "Cohort Details",
          layout: "/admin",
          path: "/assisting-program/:id",
          component: AssistingProgramDetails,
        },
        {
          name: "Hackathon Details",
          layout: "/admin",
          path: "/HackathonDetails/:id",
          component: HackathonDetails,
        },
        {
          name: "Events",
          layout: "/admin",
          path: "/events/",
          component: Events,
          icon: (
            <Icon
              as={MdOutlineEvent}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        USER_TYPE === "ProjectHolder" && {
          name: "Historique Meetings",
          layout: "/admin",
          path: "/Meetings",
          icon: (
            <Icon
              as={MdModelTraining}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: HistoriqueMeetings,
        },
        {
          name: "Event",
          layout: "/admin",
          path: "/event/:id",
          component: EventDetails,
        },
        {
          name: "News",
          layout: "/admin",
          path: "/news",
          component: News,
          icon: (
            <Icon
              as={FaRegNewspaper}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        {
          name: "Sign In",
          layout: "/auth",
          path: "/landing",
          icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
          component: LandingPage,
        },
        {
          name: "Sign In",
          layout: "/auth",
          path: "/sign-in",
          icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
          component: SignInCentered,
        },
        {
          name: "Sign Up",
          layout: "/auth",
          path: "/sign-Up",
          icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
          component: SignUpCentered,
        },
        {
          name: "Verifying your account",
          layout: "/auth",
          path: "/verify/:token",
          icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
          component: VerifyCallback,
        },
        {
          name: "Forget password",
          layout: "/auth",
          path: "/forgot-password",
          icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
          component: forgetPassword,
        },
        {
          name: "Reset password",
          layout: "/auth",
          path: "/reset-password/:token",
          icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
          component: resetPassword,
        },
        {
          name: "2-Step Verification",
          layout: "/auth",
          path: "/2-Step-Verification",
          icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
          component: TwoAuth,
        },
        {
          name: "RTL Admin",
          layout: "/rtl",
          path: "/rtl-default",
          icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
          component: RTL,
        },
        {
          name: "Felecitation",
          layout: "/admin",
          path: "/Felecitation/:id",
          component: FelecitationPayment,
        },
        {
          name: "Fail Payment",
          layout: "/admin",
          path: "/FailPayment",
          component: FailPayment,
        },
        USER_TYPE === "Expert" && {
          name: "Expert Payments",
          layout: "/admin",
          path: "/MeetingsExpert",
          icon: (
            <Icon as={MdPayment} width="20px" height="20px" color="inherit" />
          ),
          component: HistoriqueMeetingsExpert,
        },
        (USER_TYPE === "Investor" || USER_TYPE === "ProjectHolder") && {
          name: "Organizations",
          layout: "/admin",
          path: "/organizations",
          icon: (
            <Icon
              as={FaRegBuilding}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: Organizations,
        },
        {
          name: "Academy",
          layout: "/admin",
          path: "/academy",
          icon: (
            <Icon
              as={MdOutlineSchool}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          component: Academy,
        },
        {
          name: "Chat",
          layout: "/admin",
          path: "/chat/",
          component: ChatMessages,
        },
        {
          name: "Platform Guide",
          layout: "/admin",
          path: "/platform-guide",
          component: Guide,
          icon: (
            <Icon
              as={MdOutlineHelp}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
      ];

export default routes;
