import Card from "components/card/Card.js";
import {
  Text,
  Textarea,
  Flex,
  Button,
  Heading,
  Divider,
  InputGroup,
  InputLeftElement,
  Stack,
  Input,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { applyOpportunity } from "reducers/callofStartups";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getRequestCallofstartupById } from "reducers/callofStartups";
import { useSelector } from "react-redux";

const Step6 = (props) => {
  const { applyData, setApplyData, setStep,requestCall } = props;
  const { idrequest, id } = useParams();


  const [projectedAnnualRevenue, setProjectedAnnualRevenue] = useState([]);

  const [currentAnnualRevenue, setCurrentAnnualRevenue] = useState( requestCall ? requestCall.currentAnnualRevenue : applyData.currentAnnualRevenue);
  const [currentCustomersNumber, setCurrentCustomersNumber] = useState(requestCall ? requestCall.CurrentCustomersNumber : applyData.currentCustomersNumber);
  const [monthlyBurnRate, setMonthlyBurnRate] = useState(requestCall ? requestCall.MonthlyBurnRate :  applyData.monthlyBurnRate);
  const [firstAnnualRevenue, setFirstAnnualRevenue] = useState(!applyData.projectedAnnualRevenue ? "" : applyData.projectedAnnualRevenue[0].amount);
  const [secondAnnualRevenue, setSecondAnnualRevenue] = useState(!applyData.projectedAnnualRevenue ? "" : applyData.projectedAnnualRevenue[1].amount);
  const [thirdAnnualRevenue, setThirdAnnualRevenue] = useState(!applyData.projectedAnnualRevenue ? "" : applyData.projectedAnnualRevenue[2].amount);
  const [errorcurrentAnnualRevenue, setErrorCurrentAnnualRevenue] =
    useState("");
  const [errorcurrentCustomersNumber, setErrorCurrentCustomersNumber] =
    useState("");
  const [errormonthlyBurnRate, setErrorMonthlyBurnRate] = useState("");
  const [errorfirstAnnualRevenue, setErrorFirstAnnualRevenue] = useState("");
  const [errorsecondAnnualRevenue, setErrorSecondAnnualRevenue] = useState("");
  const [errorthirdAnnualRevenue, setErrorThirdAnnualRevenue] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date().getFullYear());
  const dispatch = useDispatch();
  const validateForm = () => {
    projectedAnnualRevenue.push(
      { year: currentDate + 1, amount: firstAnnualRevenue },
      { year: currentDate + 2, amount: secondAnnualRevenue },
      { year: currentDate + 3, amount: thirdAnnualRevenue }
    )
    console.log(projectedAnnualRevenue,'CurrentDate')

    let valid = true;
    if (!currentAnnualRevenue) {
      setErrorCurrentAnnualRevenue("This filed are required");
      valid = false;
    } else {
      setErrorCurrentAnnualRevenue("");
    }
    if (!currentCustomersNumber) {
      setErrorCurrentCustomersNumber("This filed are required");
      valid = false;
    } else {
      setErrorCurrentCustomersNumber("");
    }
    if (!monthlyBurnRate) {
      setErrorMonthlyBurnRate("This filed are required");
      valid = false;
    } else {
      setErrorMonthlyBurnRate("");
    }
    if (!firstAnnualRevenue) {
      setErrorFirstAnnualRevenue("This filed are required");
      valid = false;
    } else {
      setErrorFirstAnnualRevenue("");
    }
    if (!secondAnnualRevenue) {
      setErrorSecondAnnualRevenue("This filed are required");
      valid = false;
    } else {
      setErrorSecondAnnualRevenue("");
    }
    if (!thirdAnnualRevenue) {
      setErrorThirdAnnualRevenue("This filed are required");
      valid = false;
    } else {
      setErrorThirdAnnualRevenue("");
    }
    setProjectedAnnualRevenue([])
    return valid;
    
  };

  const confirm = async() => {
    if (!validateForm()) {
      return;
    }
    const ProjectAnnual=[] 
    for (var i = 0; i < projectedAnnualRevenue.length; i++) {
      ProjectAnnual.push(projectedAnnualRevenue[i]);
    }
    const Oppdata = new FormData();
    Oppdata.append("callOfStartup", id);
    Oppdata.append("project", requestCall ? requestCall.project._id : applyData.project);
    Oppdata.append("currentAnnualRevenue", currentAnnualRevenue);
    Oppdata.append("CurrentCustomersNumber", currentCustomersNumber);
    Oppdata.append("MonthlyBurnRate", monthlyBurnRate);
    Oppdata.append("ProjectedAnnualRevenue", ProjectAnnual[0].amount);
    Oppdata.append("ProjectedAnnualRevenue", ProjectAnnual[0].year);
    Oppdata.append("ProjectedAnnualRevenue", ProjectAnnual[1].amount);
    Oppdata.append("ProjectedAnnualRevenue", ProjectAnnual[1].year);
    Oppdata.append("ProjectedAnnualRevenue", ProjectAnnual[2].amount);
    Oppdata.append("ProjectedAnnualRevenue", ProjectAnnual[2].year);
    Oppdata.append("step", 7);

    console.log(ProjectAnnual,'projectedAnnualRevenue')
    const dataaddOpp = {
      Oppdata,
    };
    await dispatch(applyOpportunity(dataaddOpp)).unwrap();
    const data = {
      ...applyData,
      projectedAnnualRevenue,
      currentAnnualRevenue,
      currentCustomersNumber,
      monthlyBurnRate
    };
    setApplyData(data);
    setStep(7);
  };
  const back = () => {
    setStep(5);
    const data = {
      ...applyData,
    };
    setApplyData(data);
  };
  return (
    <Card>
      <Heading size="sm" mb="2">
        Financial Information
      </Heading>
      <Divider mb="2" />
      <Flex mt="2" flexDirection={"column"}>
        <Text>Current Annual Revenue</Text>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children="$"
          />
          <Input
            onChange={(e) => {
              setCurrentAnnualRevenue(e.target.value);
            }}
            value={currentAnnualRevenue}
            placeholder="Current Annual Revenue"
          />
         
        </InputGroup>
        {errorcurrentAnnualRevenue && (
            <Text color="red">{errorcurrentAnnualRevenue}</Text>
          )}
      </Flex>
      <Flex mt="2" flexDirection={"column"}>
        <Text>Projected Annual Revenue (Next 3 Years):</Text>
        <InputGroup mb="2">
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children="$"
          />
          <Input
            onChange={(e) => {
              setFirstAnnualRevenue(e.target.value);
            }}
            value={firstAnnualRevenue}
            placeholder="First Year"
          />
        
        </InputGroup>
        {errorfirstAnnualRevenue && (
            <Text color="red">{errorfirstAnnualRevenue}</Text>
          )}
        <InputGroup mb="2">
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children="$"
          />
          <Input
            onChange={(e) => {
              setSecondAnnualRevenue(e.target.value);
            }}
            value={secondAnnualRevenue}
            placeholder="Second Year"
          />
         
        </InputGroup>
        {errorsecondAnnualRevenue && (
            <Text color="red">{errorsecondAnnualRevenue}</Text>
          )}
        <InputGroup mb="2">
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children="$"
          />
          <Input
            onChange={(e) => {
              setThirdAnnualRevenue(e.target.value);
            }}
            value={thirdAnnualRevenue}
            placeholder="Third Year"
          />
        </InputGroup>
        {errorthirdAnnualRevenue && (
            <Text color="red">{errorthirdAnnualRevenue}</Text>
          )}
      </Flex>
      <Flex mt="2" flexDirection={"column"}>
        <Text>Current Number of Customers</Text>
        <InputGroup>
          <Input
            placeholder="Current Number of Customers"
            onChange={(e) => {
              setCurrentCustomersNumber(e.target.value);
            }}
            value={currentCustomersNumber}
          />
        </InputGroup>
        {errorcurrentCustomersNumber && (
            <Text color="red">{errorcurrentCustomersNumber}</Text>
          )}
      </Flex>
      <Flex mt="2" flexDirection={"column"}>
        <Text>
          Monthly Burn Rate (How much money are you spending monthly?)
        </Text>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children="$"
          />
          <Input
            placeholder="Monthly Burn Rate"
            onChange={(e) => {
              setMonthlyBurnRate(e.target.value);
            }}
            value={monthlyBurnRate}
          />
        </InputGroup>
        {errormonthlyBurnRate && (
            <Text color="red">{errormonthlyBurnRate}</Text>
          )}
      </Flex>

      <Flex justifyContent="end" mt="4">
        <Button
          colorScheme="blue"
          variant="outline"
          size="md"
          onClick={back}
          mr="2"
        >
          Back
        </Button>
        <Button colorScheme="blue" size="md" onClick={confirm}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};

export default Step6;
