import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import Card from "components/card/Card";
import { Link as Linkk } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLastUpComingEvents } from "reducers/event";
import { useEffect } from "react";
import EventItem from "views/admin/events/components/EventItem";
import { useState } from "react";

function LastUpComingEvents() {
  const textColor = useColorModeValue("navy.700", "white");
  const [reload, setReload] = useState(false);

  const dispatch = useDispatch();
  const events = useSelector((state) =>
    state.Event.lastUpComingEvents.data
      ? state.Event.lastUpComingEvents.data.meet
      : []
  );
  const getLastUpComingEventsAction = async () => {
    await dispatch(getLastUpComingEvents()).unwrap();
  };

  useEffect(() => {
    getLastUpComingEventsAction();

    setReload(false);
  }, [reload]);

  return (
    <>
      {events && (
        <Card
          direction="column"
          w="100%"
          px="15px"
          overflowX={"auto"}
          py="15px"
          mt="20px"
        >
          <Flex direction="column" w="100%">
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify="space-between"
              w="100%"
              px="22px"
              pb="20px"
              mb="10px"
              boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
            >
              <Text color={textColor} fontSize="xl" fontWeight="600">
                Next Events
              </Text>
              {events.length > 0 && (
                <Button variant="action">
                  <Linkk to={"/admin/events"}>See all</Linkk>
                </Button>
              )}
            </Flex>
            {events.length > 0 && (
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
                gap="20px"
                mb="20px"
              >
                {events.map((item, index) => {
                  return (
                    <EventItem
                      event={item}
                      getEvents={getLastUpComingEventsAction}
                      onReload={() => setReload(true)}
                    />
                  );
                })}
              </SimpleGrid>
            )}

            {events.length === 0 && (
              <Flex px="25px" justify="space-between" mb="20px" align="center">
                <Text>There is no data to show</Text>
              </Flex>
            )}
          </Flex>
        </Card>
      )}
    </>
  );
}

export default LastUpComingEvents;
