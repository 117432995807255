import Card from "components/card/Card.js";
import { Box, Flex } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { SocketContext } from "contexts/SocketContext";
import Stepper from "react-stepper-horizontal";
import AssistingProgramsInfoForm from "./components/addAssistingProgram/AssistingProgramsInfoForm";
import AssistingProgramsMediaUpload from "./components/addAssistingProgram/AssistingProgramsMediaUpload";
import SelectProjects from "./components/addAssistingProgram/SelectProjects";
import { useHistory } from "react-router-dom";
import RulesAssistingProgramsForm from "./components/addAssistingProgram/RulesAssistingProgramsForm";
import StackHoldersAssistingProgram from "./components/addAssistingProgram/StackHoldersAssistingProgram";
import { addAssistingPrograms } from "reducers/assistingProgram";

const AddAssistingProgram = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [ProgramAssistantData, setProgramAssistantData] = useState({
    title: "",
    description: "",
    end: null,
    start: null,
    endDateSubmition: null,
    typeOfCohort: "ProgramAssistant",
  });
  const ConstSteps = [
    { title: "Assisting program details", onClick: () => setActiveStep(0) },
    { title: "Media", onClick: () => setActiveStep(1) },
    { title: "Rules", onClick: () => setActiveStep(2) },
    { title: "StackHolders", onClick: () => setActiveStep(3) },
    { title: "Projects", onClick: () => setActiveStep(4) },
  ];
  const [steps, setSteps] = useState(ConstSteps);
  const [rules, setRules] = useState([]);
  const [selectedStackHolders, setSelectedStackHolders] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [Video, setVideo] = useState("");
  const [photo, setPhoto] = useState("");
  const history = useHistory();
  const notificationsSettings = useSelector((state) =>
    state.NotificationsSettings.notificationsSettings.data
      ? state.NotificationsSettings.notificationsSettings.data.message
      : []
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const assistingProgramId = useSelector((state) =>
    state.AssistingProgram.addAssistingProgram.data
      ? state.AssistingProgram.addAssistingProgram.data._id
      : null
  );
  const addAssistingProgramsAction = async () => {
    const ids = selectedProjects.map((item) => item._id);
    const idsStackHolder = selectedStackHolders.map((item) => item._id);

    const assistingProgram = new FormData();
    assistingProgram.append("end", ProgramAssistantData.end);
    assistingProgram.append("start", ProgramAssistantData.start);
    assistingProgram.append("title", ProgramAssistantData.title);
    assistingProgram.append("description", ProgramAssistantData.description);
    assistingProgram.append("image", photo[0]);
    assistingProgram.append("video", Video[0]);
    assistingProgram.append(
      "endDateSubmission",
      ProgramAssistantData.endDateSubmition
    );
    assistingProgram.append("managerEmail", ProgramAssistantData.managerEmail);

    assistingProgram.append("projects", JSON.stringify(ids));
    assistingProgram.append("rules", JSON.stringify(rules));
    assistingProgram.append("partners", JSON.stringify(idsStackHolder));

    const data = {
      assistingProgram,
      roles: notificationsSettings
        ? notificationsSettings.AddingNewCohorte
        : null,
      currentUserId: currentUser ? currentUser._id : null,
      socket: socket,
    };
    await dispatch(addAssistingPrograms(data)).unwrap();
      history.push({
        pathname: "/admin/assisting-programs",
        state: { add: true },
      });
    
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <ToastContainer />

        <Flex
          flexDirection={"column"}
          pt={{ base: "130px", md: "80px", xl: "80px" }}
        >
          <Card>
            <Stepper steps={steps} activeStep={activeStep} />
            <div style={{ padding: "20px" }}>
              {activeStep === 0 && (
                <AssistingProgramsInfoForm
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  steps={steps}
                  setProgramAssistantData={setProgramAssistantData}
                  ProgramAssistantData={ProgramAssistantData}
                />
              )}
              {activeStep === 1 && (
                <AssistingProgramsMediaUpload
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  steps={steps}
                  setImage={setPhoto}
                  image={photo}
                  setVideo={setVideo}
                  video={Video}
                />
              )}

              {activeStep === 2 && (
                <RulesAssistingProgramsForm
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  setRules={setRules}
                  rules={rules}
                  setProgramAssistantData={setProgramAssistantData}
                  ProgramAssistantData={ProgramAssistantData}
                />
              )}
              {activeStep === 3 && (
                <StackHoldersAssistingProgram
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  setRules={setRules}
                  rules={rules}
                  setSelectedStackHolders={setSelectedStackHolders}
                  selectedStackHolders={selectedStackHolders}
                />
              )}
              {activeStep === 4 && (
                <SelectProjects
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  steps={steps}
                  setSelectedProjects={setSelectedProjects}
                  selectedProjects={selectedProjects}
                  addAssistingProgramsAction={addAssistingProgramsAction}
                />
              )}
            </div>
          </Card>
        </Flex>
      </Box>
    </>
  );
};
export default AddAssistingProgram;
