import React from "react";
import { Box, Flex, Grid, SimpleGrid, Center } from "@chakra-ui/react";
import Pagination from "components/pagination/Pagination";
import Loader from "components/loader/Loader";
import TextCard from "components/card/TextCard";
import ProjectCard from "components/project/ProjectCard";
import placeholder from "assets/img/placeholder.jpg";

export default function ProjectCardList(props) {
  const { projects, page, setPage, totalPages, onReload } = props;
  return (
    <Grid
      mb="20px"
      gridTemplateColumns={{ xl: "repeat(3, 1fr)", "1xl": "1fr 0.46fr" }}
      gap={{ base: "20px", xl: "20px" }}
      display={{ base: "block", xl: "grid" }}
    >
      <Flex flexDirection="column">
        <Flex direction="column">
          {!projects && <Loader />}
          {projects?.length > 0 && (
            <>
              <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                {projects?.map((project) => (
                  <ProjectCard
                    key={project._id}
                    project={project}
                    onReload={onReload}
                  />
                ))}
              </SimpleGrid>
              {totalPages > 1 && (
                <Center pt="5">
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    setPage={setPage}
                    setLoader={() => {}}
                  />
                </Center>
              )}
            </>
          )}
          {projects?.length === 0 && (
            <Box mb={"30px"} mt={"30px"}>
              <TextCard
                text="There are no projects to show"
                subText={
                  "Try selecting a different project category from the options above"
                }
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Grid>
  );
}
