import React, { useState } from "react";
import {
  Input,
  useColorModeValue,
  Flex,
  Text,
  Textarea,
  FormLabel,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import InputField from "components/fields/InputField";
import CardItem from "./CardItem";
import { useEffect } from "react";

const RulesAssistingProgramsForm = ({
  activeStep,
  setActiveStep,
  rules,
  setRules,
  addHackathonHandler,
  HackathonData,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [rulesError, setRulesError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }

    return valid;
  };
  const validateRules = () => {
    let valid = true;
    if (rules.length == 0) {
      setRulesError("Rules is required!");
      valid = false;
    } else {
      setRulesError("");
    }

    return valid;
  };
  const handleAddPrize = async () => {
    setIsSubmitted(true)

    if (!validateForm()) {
      return;
    }
    setRules([
      ...rules,
      {
        title: title,
        description: description,
      },
    ]);
    setTitle("");
    setDescription("");
  };
  const next = () => {
    if (!validateRules()) {
      return;
    }
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if(isSubmitted){
      validateRules()
    }
   
  }, [rules,isSubmitted]);
  return (
    <Flex flexDirection={"column"}>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Title <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          isInvalid={titleError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Title"
          borderRadius="5px"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {titleError && (
          <Text mt="3" color="red">
            {titleError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Description <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Textarea
          isInvalid={descriptionError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Description"
          borderRadius="5px"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {descriptionError && (
          <Text mt="3" color="red">
            {descriptionError}
          </Text>
        )}
      </Flex>
      {rulesError && (
        <Text mt="3" color="red">
          {rulesError}
        </Text>
      )}
      <Flex justifyContent={"end"} mt="5">
        <BasicButton
          onClick={handleAddPrize}
          title={"Add Rules"}
          variant="solid"
        />
      </Flex>
      <CardItem value={rules} setValue={setRules} />
     
      <Flex justifyContent={"space-between"} mt="3">
        <BasicButton
          onClick={() => setActiveStep(activeStep - 1)}
          title={"Previous"}
          variant="solid"
        />
        <BasicButton
          onClick={next}
          title={"Next"}
          variant="solid"
        />
      </Flex>
    </Flex>
  );
};

export default RulesAssistingProgramsForm;
