import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Card from "components/card/Card.js";
import { Box, Flex, SimpleGrid, Center } from "@chakra-ui/react";
import HackathonItem from "./components/HackathonItem";
import Pagination from "components/pagination/Pagination";
import { getAllCohorts } from "reducers/cohort";
import Loader from "components/loader/Loader";
import TextCard from "components/card/TextCard";
import BasicButton from "components/button/BasicButton";
import { getProjectsbyUser } from "reducers/project";
import { getAllHackathons } from "reducers/hackathon";

const Hackathons = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [userType, setUserType] = useState("");
  const [reload, setReload] = useState(false);
  const status = useSelector((state) => state.Hackathon.hackathons.status);
  const hackathons = useSelector((state) =>
    state.Hackathon.hackathons.data
      ? state.Hackathon.hackathons.data.result
      : []
  );
  const totalPages = useSelector((state) =>
    state.Hackathon.hackathons.data
      ? state.Hackathon.hackathons.data.totalPages
      : 0
  );

  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const currentUserProjects = useSelector((state) =>
    state.Project.projects.data
      ? state.Project.projectsbyUser.data.message
      : null
  );
  const fetchHackathons = async () => {
    const filterData = {
      page: page,
      limit: 4,
    };
    await dispatch(getAllHackathons(filterData)).unwrap();
  };

  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
    fetchHackathons();
    setReload(false);
  }, [currentUser, page, reload]);
  useEffect(() => {
    if (currentUser) {
      const getconfirmedProjects = {
        id: currentUser._id,
        isConfirmed: true,
      };
      dispatch(getProjectsbyUser(getconfirmedProjects));
    }
  }, [currentUser]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer />
      <Card p="20px">
        {(userType == "Admin" || userType == "SuperAdmin") && (
          <Flex w="100%" justifyContent="flex-end" mb="4">
            <BasicButton
              title={"Add Hackathon"}
              variant="solid"
              onClick={() => history.push("/admin/add-Hackathon")}
            />
          </Flex>
        )}
        {!hackathons && status === "loading" && <Loader />}
        {hackathons && hackathons.length === 0 && (
          <TextCard text={"There is no Hackathon"} />
        )}
        {hackathons && hackathons.length > 0 && (
          <>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
              {hackathons.map((item, index) => (
                <HackathonItem
                  key={index}
                  item={item}
                  canJoin={
                    currentUserProjects && currentUserProjects.length > 0
                  }
                  currentUser={currentUser}
                  onRelaod={() => setReload(true)}
                />
              ))}
            </SimpleGrid>
            {totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={() => {}}
                />
              </Center>
            )}
          </>
        )}
      </Card>
    </Box>
  );
};

export default Hackathons;
