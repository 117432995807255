import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import AddTrackForm from "./components/addTrack/AddTrackForm";
import Stepper from "react-stepper-horizontal";
import Card from "components/card/Card";
import { useEffect } from "react";
import PrizesForm from "./components/addTrack/PrizesForm";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { createTrack } from "reducers/track";

const AddTrack = () => {
  const location = useLocation();
  const hackathonId =
    location && location.state ? location.state.hackathonId : "";
  const [activeStep, setActiveStep] = useState(0);
  const [prizes, setPrizes] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const ConstSteps = [
    { title: "Track details", onClick: () => setActiveStep(0) },
    { title: "Prize", onClick: () => setActiveStep(1) },
  ];

  const [step, setStep] = useState(ConstSteps);

  const [trackData, setTrackData] = useState({
    title: "",
    description: "",
    hackathon: hackathonId,
  });
  useEffect(() => {
    console.log(hackathonId, "hackathon id");
  }, []);
  const confirm = async () => {
    const data = {
      title: trackData.title,
      description: trackData.description,
      hackathon: hackathonId,
      hackathonPrizes: prizes,
    };
    setTrackData(data);
    await dispatch(createTrack(data))
      .unwrap()
      .then(() => {
        console.log("in");
        history.push({
          pathname: "/admin/HackathonDetails/" + hackathonId,
          state: { add: true },
        });
      });
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Stepper steps={step} activeStep={activeStep} mb={"5px"} />
        {activeStep === 0 && (
          <AddTrackForm
            trackData={trackData}
            setTrackData={setTrackData}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            hackathonId={hackathonId}
          />
        )}
        {activeStep === 1 && (
          <PrizesForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            prizes={prizes}
            setPrizes={setPrizes}
            confirm={confirm}
            // addHackathonHandler={addHackathonHandler}
            // isLoading={status === "loading"}
          />
        )}
      </Card>
    </Box>
  );
};

export default AddTrack;
