import React, { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import axios from "axios";
import config from "config/axios/config";
import { sectors, projectMaturityLevel } from "utils/constants";
import BasicButton from "components/button/BasicButton";
import { checkIsValidLinkedinUrl } from "utils/functions";
import { checkIsValidSiteWeb } from "utils/functions";
import InputField from "components/fields/InputField";
import TextareaField from "components/fields/TextareaField";
import CustomSelectField from "components/fields/CustomSelectField";
import CustomNumberInput from "components/fields/CustomNumberInput";

const ProjectInfoForm = (props) => {
  const { projectData, setProjectData, activeStep, steps, setActiveStep } =
    props;
  const [title, setTitle] = useState(projectData?.title);
  const [projectMaturity, setProjectMaturity] = useState(
    projectData?.projectMaturity
  );
  const [category, setCategory] = useState(projectData?.category);
  const [funds, setFunds] = useState(projectData?.funds);
  const [description, setDescription] = useState(projectData?.description);
  const [linkedin, setLinkedin] = useState(projectData?.linkedin);
  const [siteWeb, setSiteWeb] = useState(projectData?.website);
  const [otherLink, setOtherLink] = useState(projectData?.otherLink);

  const [titleError, setTitleError] = useState("");
  const [projectMaturityError, setProjectMaturityError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [linkedinError, setLinkedinError] = useState("");
  const [siteWebError, setSiteWebError] = useState("");
  const [otherLinkError, setOtherLinkError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isTitleAvailable, setIsTitleAvailable] = useState(true);

  const validateTitle = async () => {
    const res = await axios.get(
      config.apiUrl + "/project/verify-title/" + title
    );
    if (!res.data.success) {
      setIsTitleAvailable(false);
    } else {
      setIsTitleAvailable(true);
    }
  };

  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else if (!isTitleAvailable) {
      setTitleError("This title is already in use!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!projectMaturity) {
      setProjectMaturityError("Project Maturity is required!");
      valid = false;
    } else {
      setProjectMaturityError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!category) {
      setCategoryError("Category is required!");
      valid = false;
    } else {
      setCategoryError("");
    }
    if (linkedin && !checkIsValidLinkedinUrl(linkedin)) {
      setLinkedinError("Invalid LinkedIn URL!");
      valid = false;
    } else {
      setLinkedinError("");
    }
    if (siteWeb & !checkIsValidSiteWeb(siteWeb)) {
      setSiteWebError("Invalid website URL!");
      valid = false;
    } else {
      setSiteWebError("");
    }
    if (otherLink && !checkIsValidSiteWeb(otherLink)) {
      setOtherLinkError("Invalid URL!");
      valid = false;
    } else {
      setOtherLinkError("");
    }
    return valid;
  };

  const next = () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const data = {
      title: title,
      category: category,
      description: description,
      funds: 0,
      projectMaturity: projectMaturity,
      linkedin: linkedin,
      website: siteWeb,
      otherLink: otherLink,
    };
    setProjectData(data);
    setActiveStep(activeStep + 1);
  };
  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [
    title,
    category,
    description,
    funds,
    projectMaturity,
    linkedin,
    siteWeb,
    otherLink,
    submitted,
  ]);
  return (
    <Flex flexDirection={"column"}>
      <Flex mb="2">
        <InputField
          label="Title"
          placeholder="Title"
          value={title}
          setValue={setTitle}
          error={titleError}
          onBlur={validateTitle}
        />
        <CustomSelectField
          label="Sector"
          value={category}
          setValue={setCategory}
          error={categoryError}
          options={sectors}
        />
      </Flex>
      <Flex mb="2">
        <CustomSelectField
          label="Project Maturity"
          value={projectMaturity}
          setValue={setProjectMaturity}
          error={projectMaturityError}
          options={projectMaturityLevel}
        />
      </Flex>
      <TextareaField
        label="Description"
        placeholder="Description"
        value={description}
        setValue={setDescription}
        error={descriptionError}
      />
      <Flex mt="2" mb="2">
        <InputField
          label="Linkedin"
          placeholder="Linkedin"
          value={linkedin}
          setValue={setLinkedin}
          error={linkedinError}
          required={false}
        />
        <InputField
          label="Web Site"
          placeholder="Web Site"
          value={siteWeb}
          setValue={setSiteWeb}
          error={siteWebError}
          required={false}
        />
      </Flex>
      <InputField
        label="Other Link"
        placeholder="Other Link"
        value={otherLink}
        setValue={setOtherLink}
        error={otherLinkError}
        required={false}
      />
      <Flex justifyContent={"flex-end"} mt="3">
        {activeStep !== 0 && activeStep !== steps.length - 1 && (
          <BasicButton
            onClick={() => setActiveStep(activeStep - 1)}
            title={"Previous"}
            variant="solid"
          />
        )}
        {activeStep !== steps.length - 1 && (
          <BasicButton onClick={next} title={"Next"} variant="solid" />
        )}
      </Flex>
    </Flex>
  );
};

export default ProjectInfoForm;
