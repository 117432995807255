import React, { useState, useEffect, useContext, useRef } from "react";
import {
  VStack,
  Input,
  Button,
  Box,
  Text,
  Avatar,
  Flex,
  Divider,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import config from "config/axios/config";
import { scrollbarStyle } from "utils/constants";
import { FaArrowLeft, FaPaperPlane } from "react-icons/fa";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { SocketContext } from "contexts/SocketContext";
import { getRoomName } from "utils/functions";

export const Chat = (props) => {
  const { sender, receiver, backHandler, messages, setMessages } = props;
  const history = useHistory();
  const socket = useContext(SocketContext);
  const [newMessage, setNewMessage] = useState("");
  const chatBoxRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    socket.on("messageReceived", (message, room) => {
      if (room === getRoomName([sender._id, receiver._id])) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });
    socket.emit("getMessages", { sender: sender._id, receiver: receiver._id });
    socket.on("messages", (messages) => {
      setMessages(messages);
    });
    return () => {
      // Clean up event listeners
      socket.off("messageReceived");
      socket.off("messages");
      socket.off("getMessages");
    };
  }, [sender, receiver]);

  const handleSendMessage = () => {
    socket.emit("joinRoom", { userIds: [sender._id, receiver._id] });
    socket.emit("sendMessage", {
      sender: sender._id,
      receiver: receiver._id,
      content: newMessage,
    });
    setNewMessage("");
  };
  // Scroll to bottom when new messages are added
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <VStack align="stretch" p={4} bg="gray.100" flex="1">
      <Box textAlign="center" display={"flex"}>
        <IconButton
          icon={<FaArrowLeft />}
          aria-label="Go back"
          onClick={backHandler}
          size={{ base: "sm", md: "md" }}
          variant="ghost"
          mr={{ base: "2", md: "3" }}
        />
        <Avatar
          src={config.apiUrl + "/api/getfile/" + receiver.Image}
          alt={receiver.firstName}
          size="md"
          mr={{ base: "2", md: "3" }}
        />
        <Flex direction="column" alignItems={"flex-start"}>
          <Text
            fontSize={{ base: "sm", md: "md" }}
            fontWeight="bold"
            _hover={{ color: "blue.500", cursor: "pointer" }}
            onClick={() =>
              history.push("/admin/expert/profile/" + receiver._id)
            }
            textAlign={"start"}
          >
            {receiver.firstName + " " + receiver.lastName}
          </Text>
          <Text fontSize={{ base: "xs", md: "sm" }}>{receiver.userType}</Text>
        </Flex>
      </Box>
      <Divider my="2" />
      <Box
        borderRadius="md"
        overflowY="auto"
        height="300px"
        width="100%"
        display={"flex"}
        flexDirection={"column"}
        css={scrollbarStyle}
        ref={chatBoxRef}
      >
        {messages && messages.length ? (
          messages.map((message, index) => (
            <Box
              key={index}
              p={2}
              mb={2}
              display={"flex"}
              alignSelf={
                message.sender === sender._id ? "flex-end" : "flex-start"
              }
              width="50%"
            >
              <Avatar
                src={
                  config.apiUrl + "/api/getfile/" + message.sender ===
                  sender._id
                    ? sender.Image
                    : receiver.Image
                }
                width="20px"
                height="20px"
                mr="2"
              />
              <Flex
                flexDirection={"column"}
                display={"flex"}
                alignSelf={
                  message.sender === sender._id ? "flex-end" : "flex-start"
                }
                width="100%"
              >
                <Flex
                  flexDirection={"column"}
                  borderRadius="lg"
                  p={2}
                  display={"flex"}
                  bg={message.sender === sender._id ? "blue.100" : "green.100"}
                  alignSelf={
                    message.sender === sender._id ? "flex-end" : "flex-start"
                  }
                  width="100%"
                >
                  {message.content}
                </Flex>
                <Text
                  fontSize="xs"
                  color="gray.500"
                  mb={1}
                  display="flex"
                  justifyContent={"flex-end"}
                >
                  {moment(message.createdAt).fromNow()}
                </Text>
              </Flex>
            </Box>
          ))
        ) : (
          <Flex justifyContent={"center"} alignItems={"center"} h="100%">
            <Text fontSize="sm" color="gray.500" textAlign="center">
              No messages yet. Start the conversation!
            </Text>
          </Flex>
        )}
      </Box>
      <Divider />
      <Flex>
        <Input
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          mr="2"
        />
        <Button
          leftIcon={<FaPaperPlane />}
          colorScheme="blue"
          onClick={handleSendMessage}
        >
          {!isMobile ? "Send" : ""}
        </Button>
      </Flex>
    </VStack>
  );
};
