import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  forgotPasswordService,
  getAllUserService,
  getUserByIdService,
  getResumeService,
  resetPasswordService,
  updateHourlyPriceService,
  updatePasswordService,
  updateResumeService,
  updateSocialProfilesService,
  updateUserService,
  getCurrentUserService,
  getAllAdminService,
  addAdminService,
  AdminAddUserService,
  getHistoricMeetings,
  getExcelProfilbyIdService,
} from "../services/userService";
import { VerfiedUserService } from "../services/userService";

export const getallUsers = createAsyncThunk(
  "users/getallUser",
  async (filterdata, thunkAPI) => {
    try {
      const { status, data } = await getAllUserService(
        filterdata.page,
        filterdata.limit,
        filterdata.type,
        filterdata.name,
      );
      if (status === 200) {
        console.log(data, "here");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getallAdmins = createAsyncThunk(
  "users/getallAdmin",
  async (Admindata, thunkAPI) => {
    try {
      const { status, data } = await getAllAdminService(
        Admindata.page,
        Admindata.limit
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const changeStatusUser = createAsyncThunk(
  "users/verfiedUser",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await VerfiedUserService(id);
      if (status === 200) {
        console.log(data, "here");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const forgotPasswordUser = createAsyncThunk(
  "users/forgotPassword",
  async (email, thunkAPI) => {
    try {
      const { status, data } = await forgotPasswordService(email);
      if (status === 200) {
        console.log(data, "here");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const restPasswordUser = createAsyncThunk(
  "users/restPasswordUser",
  async (dataRest, thunkAPI) => {
    try {
      const { status, data } = await resetPasswordService(
        dataRest.password,
        dataRest.token
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateUser = createAsyncThunk(
  "user/update",
  async (userSettings, thunkAPI) => {
    try {
      const { status, data } = await updateUserService(userSettings);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateSocialProfiles = createAsyncThunk(
  "user/update-social-profiles",
  async (socialProfiles, thunkAPI) => {
    try {
      const { status, data } = await updateSocialProfilesService(
        socialProfiles
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updatePassword = createAsyncThunk(
  "user/update-password",
  async (pwdData, thunkAPI) => {
    try {
      const { status, data } = await updatePasswordService(pwdData);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getResume = createAsyncThunk(
  "user/get-resume",
  async (thunkAPI) => {
    try {
      const { status, data } = await getResumeService();
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateResume = createAsyncThunk(
  "user/update-resume",
  async (resume, thunkAPI) => {
    try {
      const { status, data } = await updateResumeService(resume);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateHourlyPrice = createAsyncThunk(
  "user/update-hourly-price",
  async (hourlyRate, thunkAPI) => {
    try {
      const { status, data } = await updateHourlyPriceService(hourlyRate);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getUserById = createAsyncThunk(
  "user/get-user-by-id",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getUserByIdService(id);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addAdmin = createAsyncThunk(
  "user/add-admin",
  async (adminData, thunkAPI) => {
    try {
      const { status, data } = await addAdminService(adminData);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const AdminAddUser= createAsyncThunk(
  "user/UserData",
  async (UserData, thunkAPI) => {
    try {
      const { status, data } = await AdminAddUserService(UserData);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "user/get-current-user",
  async (thunkAPI) => {
    try {
      return getCurrentUserService();
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const GetHistoricMeetings= createAsyncThunk(
  "user/GetHistoricMeetings",
  async (idUser, thunkAPI) => {
    try {
      const { status, data } = await getHistoricMeetings(idUser);
      if (status === 200) {
        console.log(data,'dataserv')
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getExcelProfilbyId= createAsyncThunk(
  "user/getExcelProfilbyId",
  async (idUser, thunkAPI) => {
    try {
      const { status, data } = await getExcelProfilbyIdService(idUser);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  getdateExcel: {
    loading: false,
    data: [],
    error: {},
  },
  adminsInformation: {
    loading: false,
    data: [],
    error: {},
  },
  addAdmin: {
    loading: false,
    data: {},
    error: {},
  },
  usersInformation: {
    loading: false,
    data: [],
    error: {},
  },
  VerfiedUser: {
    loading: false,
    data: [],
    error: {},
  },
  EmailPassword: {
    loading: false,
    data: [],
    error: {},
  },
  restTokenPassword: {
    loading: false,
    data: [],
    error: {},
  },
  updatedUser: {
    loading: false,
    data: {},
    error: {},
  },
  updatedSocialProfiles: {
    loading: false,
    data: {},
    error: {},
  },
  updatePwd: {
    loading: false,
    data: {},
    error: {},
  },
  resume: {
    loading: false,
    data: {},
    error: {},
  },
  updatedResume: {
    loading: false,
    data: {},
    error: {},
  },
  updateHourlyPrice: {
    loading: false,
    data: {},
    error: {},
  },
  user: {
    loading: false,
    data: {},
    error: {},
  },
  currentUser: {
    loading: false,
    data: null,
    error: {},
  },
  userbyAdmin: {
    loading: false,
    data: null,
    error: {},
  },
  MeetingHistoric: {
    loading: false,
    data: null,
    error: {},
  },
};
const UserInformation = createSlice({
  name: "getall-USERS",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [GetHistoricMeetings.pending.type]: (state) => {
      state.MeetingHistoric = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [GetHistoricMeetings.fulfilled.type]: (state, action) => {
      state.MeetingHistoric = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [GetHistoricMeetings.rejected.type]: (state, action) => {
      state.MeetingHistoric = {
        status: "failed",
        data: null,
        error: {},
      };
    },
    [AdminAddUser.pending.type]: (state) => {
      state.userbyAdmin = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [AdminAddUser.fulfilled.type]: (state, action) => {
      state.userbyAdmin = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [AdminAddUser.rejected.type]: (state, action) => {
      state.userbyAdmin = {
        status: "failed",
        data: null,
        error: {},
      };
    },
    [getallAdmins.pending.type]: (state) => {
      state.adminsInformation = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getallAdmins.fulfilled.type]: (state, action) => {
      state.adminsInformation = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getallAdmins.rejected.type]: (state, action) => {
      state.adminsInformation = {
        status: "failed",
        data: null,
        error: {},
      };
    },
    [addAdmin.pending.type]: (state) => {
      state.addAdmin = {
      status: "loading",
      data: null,
      error: {},
      };
      },
      [addAdmin.fulfilled.type]: (state, action) => {
      state.addAdmin = {
      status: "success",
      data: action.payload,
      error: {},
      };
      },
      [addAdmin.rejected.type]: (state, action) => {
      state.addAdmin = {
      status: "failed",
      data: null,
      error: {},
      };
      },
    [getCurrentUser.pending.type]: (state) => {
      state.currentUser = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getCurrentUser.fulfilled.type]: (state, action) => {
      state.currentUser = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getCurrentUser.rejected.type]: (state, action) => {
      state.currentUser = {
        status: "failed",
        data: null,
        error: {},
      };
    },
    [getallUsers.pending]: (state, action) => {
      state.usersInformation.loading = true;
    },
    [getallUsers.fulfilled]: (state, action) => {
      state.usersInformation.loading = false;
      state.usersInformation.data = action.payload;
    },
    [getallUsers.rejected]: (state, action) => {
      state.usersInformation.loading = false;
      state.usersInformation.error = action.payload;
    },
    [changeStatusUser.pending]: (state, action) => {
      state.VerfiedUser.loading = true;
    },
    [changeStatusUser.fulfilled]: (state, action) => {
      state.VerfiedUser.loading = false;
      state.VerfiedUser.data = action.payload;
    },
    [changeStatusUser.rejected]: (state, action) => {
      state.VerfiedUser.loading = false;
      state.VerfiedUser.error = action.payload;
    },
    [forgotPasswordUser.pending]: (state, action) => {
      state.EmailPassword.loading = true;
    },
    [forgotPasswordUser.fulfilled]: (state, action) => {
      state.EmailPassword.loading = false;
      state.EmailPassword.data = action.payload;
    },
    [forgotPasswordUser.rejected]: (state, action) => {
      state.EmailPassword.loading = false;
      state.EmailPassword.error = action.payload;
    },
    [restPasswordUser.pending]: (state, action) => {
      state.restTokenPassword.loading = true;
    },
    [restPasswordUser.fulfilled]: (state, action) => {
      state.restTokenPassword.loading = false;
      state.restTokenPassword.data = action.payload;
    },
    [restPasswordUser.rejected]: (state, action) => {
      state.restTokenPassword.loading = false;
      state.restTokenPassword.error = action.payload;
    },
    [updateUser.pending.type]: (state) => {
      state.updatedUser = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateUser.fulfilled.type]: (state, action) => {
      state.updatedUser = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateUser.rejected.type]: (state, action) => {
      state.updatedUser = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateSocialProfiles.pending.type]: (state) => {
      state.updatedSocialProfiles = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateSocialProfiles.fulfilled.type]: (state, action) => {
      state.updatedSocialProfiles = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateSocialProfiles.rejected.type]: (state, action) => {
      state.updatedSocialProfiles = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updatePassword.pending.type]: (state) => {
      state.updatePwd = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updatePassword.fulfilled.type]: (state, action) => {
      state.updatePwd = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updatePassword.rejected.type]: (state, action) => {
      state.updatePwd = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getResume.pending.type]: (state) => {
      state.resume = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getResume.fulfilled.type]: (state, action) => {
      state.resume = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getResume.rejected.type]: (state, action) => {
      state.resume = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateResume.pending.type]: (state) => {
      state.updatedResume = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateResume.fulfilled.type]: (state, action) => {
      state.updatedResume = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateResume.rejected.type]: (state, action) => {
      state.updatedResume = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateHourlyPrice.pending.type]: (state) => {
      state.updateHourlyPrice = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateHourlyPrice.fulfilled.type]: (state, action) => {
      state.updateHourlyPrice = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateHourlyPrice.rejected.type]: (state, action) => {
      state.updateHourlyPrice = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getUserById.pending.type]: (state) => {
      state.user = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getUserById.fulfilled.type]: (state, action) => {
      state.user = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getUserById.rejected.type]: (state, action) => {
      state.user = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getExcelProfilbyId.pending.type]: (state) => {
      state.getdateExcel = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getExcelProfilbyId.fulfilled.type]: (state, action) => {
      state.getdateExcel = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getExcelProfilbyId.rejected.type]: (state, action) => {
      state.getdateExcel = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default UserInformation.reducer;
