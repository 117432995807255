import React from "react";
import {
  Text,
  Box,
  Flex,
  Link,
  IconButton,
  useBreakpointValue,
  Image,
  Stack,
  Input,
  Button,
} from "@chakra-ui/react";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaMobileAlt,
  FaEnvelope,
} from "react-icons/fa";
import logo from "assets/img/logo.png";
import { CalendarIcon } from "@chakra-ui/icons";

const Footer = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      as="footer"
      backgroundColor="brand.primary"
      p="4"
      flexDirection="column"
      color="#11047a"
      borderTop="1px"
      borderColor="#e2e8f0"
      >
      <Flex
        justify="space-between"
        flexDirection={isMobile ? "column" : "row"}
        ml="3"
        mr="3"
        wrap="wrap"
      >
        {/* Logo */}
        <Flex
          mb={isMobile ? "4" : "0"}
          direction="column"
          justify="start"
          alignItems="start"
        >
          <Image src={logo} alt="Logo" h="100px" mb="2" />{" "}
          {/* Adjust the height as needed */}
          <Flex mb="2">
            <FaEnvelope w={3} h={3} />

            <Text fontSize="sm" ml="2">
              darblockchain@contact.io
            </Text>
          </Flex>
          <Flex mb="2">
            <FaMobileAlt w={3} h={3} />

            <Text fontSize="sm" ml="2">
              +216 56 234 567
            </Text>
          </Flex>
        </Flex>
        <Flex mb={isMobile ? "4" : "0"} direction="column">
          <Text mb={2} fontWeight="bold" fontSize="lg">
            Pages
          </Text>
          <Link href="#" mr={4}>
            Home
          </Link>
          <Link href="#" mr={4}>
            Hackathons
          </Link>
          <Link href="#" mr={4}>
            Projects
          </Link>
        </Flex>
        <Flex mb={isMobile ? "4" : "0"} direction="column">
          <Text mb={2} fontWeight="bold" fontSize="lg">
            Legal
          </Text>
          <Link href="#" mr={4}>
            Terms of Service
          </Link>
          <Link href="#">Privacy Policy</Link>
        </Flex>
        <Flex mb={isMobile ? "4" : "0"} direction="column">
          <Text mb={2} fontWeight="bold" fontSize="lg">
            Subscribe
          </Text>
          <Text mb={2} fontWeight="100" fontSize="sm">
            Get the latest news and articles to your inbox every month.
          </Text>
          <Stack direction={isMobile ? "column" : "row"} spacing={4} mt="4">
            <Input placeholder="Your work email" size="md" bg="white" />
            <Button
              bg="#11047a"
              color="white"
              _hover={{ bg: "white", color: "#11047a" }}
              size="md"
            >
              Start
            </Button>
          </Stack>
        </Flex>
      </Flex>
      <Flex
        mt="6"
        ml="3"
        mr="3"
        pt="4"
        justify="space-between"
        borderTop="1px"
        borderColor="#e2e8f0"
        wrap="wrap"
      >
        <Text fontSize="sm">
          © {new Date().getFullYear()} EcoSystem Dao. All rights reserved.
        </Text>
        <Flex alignItems="center">
          <IconButton
            as={Link}
            href="#"
            aria-label="Facebook"
            icon={<FaFacebook />}
            variant="ghost"
            colorScheme="black"
            mr={2}
          />
          <IconButton
            as={Link}
            href="#"
            aria-label="Twitter"
            icon={<FaTwitter />}
            variant="ghost"
            colorScheme="black"
            mr={2}
          />
          <IconButton
            as={Link}
            href="#"
            aria-label="LinkedIn"
            icon={<FaLinkedin />}
            variant="ghost"
            colorScheme="black"
            mr={2}
          />
          <IconButton
            as={Link}
            href="#"
            aria-label="Instagram"
            icon={<FaInstagram />}
            variant="ghost"
            colorScheme="black"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
