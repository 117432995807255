import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNumberProjectsByCategory } from "reducers/project";
import StatsChart from "./StatsChart";

export default function ProjectsByCategoryStats() {
  const dispatch = useDispatch();
  const [xValues, setxValues] = useState([]);
  const [yValues, setyValues] = useState([]);

  const data = useSelector((state) =>
    state.Project.numberProjectsByCategory.data
      ? state.Project.numberProjectsByCategory.data.result
      : []
  );

  const getNumberProjectsByCategoryAction = async () => {
    await dispatch(getNumberProjectsByCategory()).unwrap();
  };

  useEffect(() => {
    getNumberProjectsByCategoryAction();
  }, []);

  useEffect(() => {
    if (data) {
      const categories = data.map((item) => item._id);
      setxValues(categories);
      const nbProjects = data.map((item) => item.count);
      setyValues(nbProjects);
    }
  }, [data]);

  const config = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: xValues,
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
    },
    series: [
      {
        name: "Projects",
        data: yValues,
      },
    ],
  };

  return (
    <StatsChart
      title={"Projects by category"}
      config={config}
      data={data}
      noDataText={"There is no project"}
      showFilter={false}
      setMonthYearFilter={() => {}}
      type={"bar"}
    />
  );
}
