import React from "react";

// Chakra imports
import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import logo from "assets/img/logo.png";
import logo2 from "assets/img/logo.png";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
     {<Image src={logo2} px='auto' w='100%' pb='10px' maxW={"80%"}/>}
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
