import React from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import "./ProfileSettings.css";
import "react-toastify/dist/ReactToastify.css";
import Card from "components/card/Card";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import moment from "moment";

const WorkHistoryItem = (props) => {
  const { item, index, handleDelete, showEditHandler, canEdit = true } = props;
  return (
    <Card backgroundColor={"#f8f9fa"} key={index}>
      <Heading size="sm" mb="2">
        {item.workingType}
      </Heading>
      <Text>
        {moment(item.start).format("llll")} -{" "}
        {moment(item.end).format("llll")}
      </Text>
      <Text>{item.company}</Text>
      {canEdit && (
        <Stack
          spacing={2}
          direction="row"
          align="center"
          justifyContent={"flex-end"}
        >
          <Button colorScheme="blue" size="xs" onClick={showEditHandler}>
            <EditIcon />
          </Button>
          <Button colorScheme="red" size="xs" onClick={handleDelete}>
            <DeleteIcon />
          </Button>
        </Stack>
      )}
    </Card>
  );
};

export default WorkHistoryItem;
