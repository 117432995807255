import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addIndividualTrainingService } from "services/individualTrainingService";
import { getAllIndividualTrainingsService } from "services/individualTrainingService";

export const getAllIndividualTrainings = createAsyncThunk(
  "training/getAllIndividualTrainings",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getAllIndividualTrainingsService(
        filterData
      );
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addIndividualTraining = createAsyncThunk(
  "training/add",
  async (meetData, thunkAPI) => {
    try {
      const { status, data } = await addIndividualTrainingService(
        meetData
      );
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  trainings: {
    status: "idle",
    data: {},
    error: {},
  },
  addMeet: {
    status: "idle",
    data: {},
    error: {},
  },
};
const IndividualTrainingSlice = createSlice({
  name: "individualTraining",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [addIndividualTraining.pending.type]: (state) => {
      state.addMeet = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addIndividualTraining.fulfilled.type]: (state, action) => {
      state.addMeet = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addIndividualTraining.rejected.type]: (state, action) => {
      state.addMeet = {
        status: "failed",
        data: {},
        error: action.payload ? action.payload.message : "",
      };
    },
    [getAllIndividualTrainings.pending.type]: (state) => {
      state.trainings = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllIndividualTrainings.fulfilled.type]: (state, action) => {
      state.trainings = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllIndividualTrainings.rejected.type]: (state, action) => {
      state.trainings = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default IndividualTrainingSlice.reducer;
