import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import OnlineUsers from "./components/OnlineUsers";
import { useSelector } from "react-redux";
import { Chat } from "components/chat";
import { useLocation } from "react-router-dom";

const ChatMessages = () => {
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const sender = location && location.state ? location.state.sender : null;
  const [receiver, setReceiver] = useState(sender);
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const [messages, setMessages] = useState([]);

  const backHandler = () => {
    setReceiver(null);
  };

  useEffect(() => {
    if (sender) {
      setReceiver(sender);
    }
  }, [sender]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex p={4} height="100%" bg="gray.50" borderRadius="xl" boxShadow="lg">
        {((!receiver && isMobile) || !isMobile) && (
          <OnlineUsers
            sender={currentUser}
            setMessages={setMessages}
            setReceiver={setReceiver}
            receiver={receiver}
          />
        )}
        {(!isMobile || (receiver && isMobile)) && (
          <>
            {receiver && currentUser && (
              <Chat
                sender={currentUser}
                receiver={receiver}
                backHandler={backHandler}
                messages={messages}
                setMessages={setMessages}
              />
            )}
            {!receiver && (
              <Flex
                justifyContent="center"
                alignItems="center"
                minH={{ base: "50vh", md: "70vh", lg: "80vh" }}
                textAlign="center"
                flex="1"
                flexDirection="column"
              >
                <Text fontSize="2xl" color="blue.500" fontWeight="bold" mb={4}>
                  Start the conversation!
                </Text>
                <Text fontSize="sm" color="gray.500">
                  Select a user from the list on the left to begin chatting.
                </Text>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default ChatMessages;
