import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
  Badge,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import config from "config/axios/config";
import Card from "components/card/Card.js";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import placeholder from "assets/img/placeholder.jpg";
import { shareUrl } from "utils/constants";
import ProjectStatus from "views/admin/projects/components/ProjectStatus";
import IconButton from "components/button/IconButton";
import BasicButton from "components/button/BasicButton";
import { likeProject } from "reducers/project";
import AccountRequiredModal from "views/auth/landingPage/components/AccountRequiredModal";

export default function ProjectCard(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    project,
    onReload,
    canLike = true,
    bg,
    colorText,
    modal = false,
  } = props;
  const [userType, setUserType] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  console.log(colorText, "colorText");
  const likefunc = async () => {
    await dispatch(likeProject(project._id)).unwrap();
    onReload();
  };

  useEffect(() => {
    if (currentUser) {
      setUserType(currentUser.userType);
      if (project) {
        setIsLiked(
          project.likes.length && project.likes.includes(currentUser._id)
        );
      }
    }
  }, [currentUser, project]);

  return (
    <Card
      p="20px"
      bg="white"
      borderRadius="3xl"
      transition="transform 0.2s"
      _hover={{ transform: "scale(1.05)" }}
    >
      <Flex direction={{ base: "column" }} justify="center" h="100%">
        <Box mb={{ base: "20px", "3xl": "20px" }} position="relative">
          <Image
            src={config.apiUrl + "/project/file/" + project.images}
            w={{ base: "100%", "2xl": "100%" }}
            h="225px"
            borderRadius="20px"
            fallbackSrc={placeholder}
          />
          {canLike && userType !== "Admin" && userType !== "SuperAdmin" && (
            <IconButton
              icon={isLiked ? IoHeart : IoHeartOutline}
              onClick={likefunc}
              color="red.500"
            />
          )}
          <Flex top="10px" right="10px" position="absolute">
            <LinkedinShareButton
              url={shareUrl + project._id}
              style={{ marginRight: "5px" }}
            >
              <LinkedinIcon size={25} round />
            </LinkedinShareButton>
            <FacebookShareButton
              url={shareUrl + project._id}
              style={{ marginRight: "5px" }}
            >
              <FacebookIcon size={25} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl + project._id}>
              <TwitterIcon size={25} round />
            </TwitterShareButton>
          </Flex>
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb="auto"
          >
            <Flex direction="column">
              <Flex justify="space-between" alignItems={"center"}>
                <Text
                  color={colorText != "undefined" ? colorText : textColor}
                  mr="4px"
                  fontSize={{
                    base: "xl",
                    md: "lg",
                    lg: "lg",
                    xl: "lg",
                    "2xl": "md",
                    "3xl": "lg",
                  }}
                  mb="5px"
                  fontWeight="bold"
                  me="14px"
                >
                  {project.title}
                </Text>
                <Badge className="description" colorScheme="green" mb="5px">
                  {project.category}
                </Badge>
              </Flex>
              <Text
                className="description"
                color={colorText != "undefined" ? colorText : textColor}
                mb="5px"
                me="14px"
              >
                {project.description}
              </Text>
            </Flex>
            <Text fontWeight="600" fontSize="sm" color={textColorBid}></Text>
          </Flex>
          <Flex
            align="start"
            alignItems={"flex-end"}
            justify="space-between"
            mt="25px"
          >
            {userType !== "Admin" &&
              userType !== "SuperAdmin" &&
              project.likes && (
                <Text fontSize="md" fontWeight="300" color="gray.500">
                  {project.likes.length} Likes
                </Text>
              )}
            {(userType === "Admin" || userType === "SuperAdmin") && (
              <ProjectStatus project={project} onReload={onReload} />
            )}
            <BasicButton
              variant={"brand"}
              bg={bg != "undefined" ? bg : ""}
              title="Details"
              onClick={() =>
                modal == true
                  ? onOpen()
                  : history.push("/admin/project/" + project._id)
              }
            />
          </Flex>
        </Flex>
      </Flex>
      <AccountRequiredModal
        message={
          "Welcome to the vibrant community of Ecosystem DAO! We're delighted to have you as part of our community. To discover the intriguing details of our latest project and maximize your engagement. <b>take a moment to create your account.</b><br/> <br/>Your venture into the realm of innovation and collaboration begins right here. Join us, and let's accomplish remarkable feats together!"
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </Card>
  );
}
