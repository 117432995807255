import React from "react";
import { Input, Select, HStack } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import MySelect from "react-select";
import { sectors } from "utils/constants";
import { expertFilters } from "utils/constants";

export default function ExpertFilters(props) {
  const {
    searchText,
    selectedCategories,
    setSearchText,
    setFilter,
    setSelectedCategories,
  } = props;

  return (
    <Card mb="20px">
      <HStack spacing={2} mb="3">
        <Input
          variant="outline"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          placeholder="First Name or Last Name or Email"
        />
        <Select
          mr="3"
          mb="2"
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        >
          {expertFilters.map((item, index) => (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>
      </HStack>
      <MySelect
        className="InputGroupy"
        placeholder="Select Categories"
        options={sectors}
        value={selectedCategories}
        onChange={setSelectedCategories}
        isMulti
      />
    </Card>
  );
}
