import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Flex,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "components/card/Card.js";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import BasicButton from "components/button/BasicButton";
import { isGitHubUrlValid } from "utils/functions";
import { addHackathonSubmission } from "reducers/hackathon";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function SubmissionModal(props) {
  const { isOpen, onClose, onReload, project, trackId, idHackathon } = props;
  const dispatch = useDispatch();
  const { id } = useParams();

  const [github, setGithub] = useState("");
  const [presentation, setPresentation] = useState("");
  const [demoVideo, setDemoVideo] = useState("");
  const [githuberror, setGithubError] = useState("");
  const [presentationerror, setPresentationError] = useState("");
  const [demoVideoerror, setDemoVideoError] = useState("");
  const validateForm = () => {
    let valid = true;
    if (!github) {
      setGithubError("GitHub url is required!");
      valid = false;
    } else if (!isGitHubUrlValid(github)) {
      setGithubError("Invalid GitHub Url!");
      valid = false;
    } else {
      setGithubError("");
    }

    return valid;
  };
  const submitForHackathon = async () => {
    if (!validateForm()) {
      return;
    }
    const data = {
      idHackathon: id,
      project: project._id,
      track: trackId,
      videoUrl: demoVideo,
      githubUrl: github,
      pptUrl: presentation,
    };
    await dispatch(addHackathonSubmission(data)).unwrap();
    onReload();
    onClose();
  };
  useEffect(() => {
    setGithub(project.hasSubmitted ? project.submit.githubUrl : github);
    setPresentation(
      project.hasSubmitted ? project.submit.pptUrl : github
    );
    setDemoVideo(project.hasSubmitted ? project.submit.videoUrl : github);
  }, [project]);
  useEffect(() => {
    console.log(idHackathon, "fofofofofof");
  }, [idHackathon]);
  return (
    <Modal
      size="yo"
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent w="65rem">
        <ModalHeader>Join Hackathon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight="300" mb="1rem">
            To complete your submission for our Hackathon, please provide the
            URLs for your project's demo video and presentation. The demo video,
            ideally hosted on YouTube, showcases your project's features. Share
            the YouTube link, ensuring it's accessible for our judges.
            Additionally, your project presentation, created on platforms like
            SlideShare, is vital for explaining your solution. Share the
            presentation link for our review. These elements are crucial for
            communicating the excellence of your work and boosting your chances
            of winning. Thank you for your commitment, and best of luck!
          </Text>

          <Card p="2" direction="column" w="100%">
            <ToastContainer />

            <Flex mt="2" direction={"column"}>
              <FormLabel>
                GitHub Url <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Input
                isInvalid={githuberror}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="https://github.com/username/repo"
                borderRadius="5px"
                value={github}
                onChange={(e) => setGithub(e.target.value)}
              />
              {githuberror && (
                <Text mt="3" color="red">
                  {githuberror}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>Presentation Url</FormLabel>{" "}
              <Input
                isInvalid={presentationerror}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="https://www.youtube.com/watch?v=your_video_id"
                borderRadius="5px"
                value={presentation}
                onChange={(e) => setPresentation(e.target.value)}
              />
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>Video Url</FormLabel>{" "}
              <Input
                isInvalid={demoVideoerror}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="https://www.slideshare.net/your_username/your_presentation_title"
                borderRadius="5px"
                value={demoVideo}
                onChange={(e) => setDemoVideo(e.target.value)}
              />
            </Flex>
            <Flex justifyContent="end" mt="4">
              <BasicButton title={"Submit"} onClick={submitForHackathon} />
            </Flex>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
