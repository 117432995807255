import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import { Text, Box, Flex, Checkbox, Avatar, Badge } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import config from "config/axios/config";
import { obfuscateEmail } from "utils/functions";

export default function UserItem(props) {
  const { user, selectedUsers, setSelectedUsers } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [isChecked, setIsChecked] = useState(false);

  const toggleUser = (user) => {
    const x = [...selectedUsers];
    if (x.includes(user._id)) {
      const res = x.filter((item) => item !== user._id);
      setSelectedUsers(res);
    } else {
      x.push(user._id);
      setSelectedUsers(x);
    }
  };

  useEffect(() => {
    setIsChecked(selectedUsers.includes(user._id));
  }, [selectedUsers, user]);
  const getBadgeColor = () => {
    switch (user.userType) {
      case "community":
        return "gray";
      case "Expert":
        return "green";
      default:
        return "gray";
    }
  };
  return (
    <Card p="1" mb="2" boxShadow={cardShadow}>
      <Flex>
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
        >
          <Avatar
            w="50px"
            h="50px"
            src={config.apiUrl + "/api/getfile/" + user.Image}
          />
          <Box ml="3">
            <Text fontWeight="normal">
              {user.firstName + " " + user.lastName}
              <Badge
                ml="1"
                colorScheme={getBadgeColor()}
                variant="solid"
                size={"sm"}
              >
                {user.userType}
              </Badge>
            </Text>
            <Text fontSize="sm">{obfuscateEmail(user.email)}</Text>
          </Box>
        </Flex>
        <Checkbox
          me="16px"
          colorScheme="brandScheme"
          borderColor={"#3965ff"}
          onChange={() => toggleUser(user)}
          isChecked={isChecked}
        />
      </Flex>
    </Card>
  );
}
