import React from "react";
import { useParams } from "react-router-dom";
import config from "config/axios/config";
import {
  Flex,
  Spinner,
  Text,
  Avatar,
  useColorModeValue,
  Heading,
  Button,
  Badge,
  Box,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { getDetailsNewsR } from "reducers/news";
import { commentNew } from "reducers/news";
import { BiLike, BiChat } from "react-icons/bi";
import { likeNew } from "reducers/news";
import { Icon, EditIcon, CloseIcon } from "@chakra-ui/icons";
import BannerImage from "components/image/BannerImage";
import TextCard from "components/card/TextCard";
import { editNews } from "reducers/news";
import EditFormNew from "./components/editNew/EditFormNew";

const NewDetails = () => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const { id } = useParams();
  const dispatch = useDispatch();
  const [userType, setUsetType] = useState("");
  const [userId, setUserId] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showEditInfos, setShowEditInfos] = useState(false);
  const [photo, setPhoto] = useState([]);
  const [photoError, setPhotoError] = useState("");
  const [comment, setComment] = useState("");
  const [showAddComment, setShowAddComment] = useState(false);
  const NumberOfLikes = useSelector(
    (state) => state.News.detailsNew.data.NumberOfLikes
  );
  const NumberOfViews = useSelector(
    (state) => state.News.detailsNew.data.NumberOfViews
  );
  const status = useSelector((state) => state.News.detailsNew.status);
  const newData = useSelector(
    (state) => state.News.detailsNew.data.news
  );
  const [reload, setReload] = useState(false);

  const getNewByIdAction = async () => {
    await dispatch(getDetailsNewsR(id)).unwrap();
  };

  useEffect(() => {
    const userData = jwt_decode(localStorage.getItem("token"));
    setUsetType(userData ? userData.userType : "");
    setUserId(userData ? userData._id : "");
    getNewByIdAction();
  }, []);

  const addComment = async () => {
    const commentData = {
      comment: comment,
      id: id,
    };
    await dispatch(commentNew(commentData)).unwrap();
    setShowAddComment(false);
    getNewByIdAction();
  };

  const likeNewAction = async () => {
    await dispatch(likeNew(id)).unwrap();
    getNewByIdAction();
  };
  useEffect(() => {
    getNewByIdAction();
    setReload(false);
  }, [reload]);

  const validateForm = () => {
    let inValid = false;

    if (!photo.length) {
      setPhotoError("The image is required!");
      inValid = true;
    } else {
      setPhotoError("");
    }
    return inValid;
  };

  const updateNewsAction = async () => {
    const inValid = validateForm();
    if (inValid) {
      return;
    }
    const data = new FormData();
    data.append("image", photo[0]);

    const NewsData = {
      data,
      id: id,
    };
    await dispatch(editNews(NewsData)).unwrap();
    getNewByIdAction();
  };
  return (
    <>
      <Flex
        flexDirection={"column"}
        pt={{ base: "130px", md: "80px", xl: "80px" }}
      >
        {!newData && status === "loading" && (
          <Flex flexDirection="column" h="100vh">
            <Spinner my="auto!important" mx="auto" />
          </Flex>
        )}
        {!newData && status === "failed" && (
          <Flex flexDirection="column" h="100vh">
            <Card mt="3" mb="3">
              <Text fontSize={"2xl"} textAlign={"center"}>
                New does not exist
              </Text>
              <Text fontSize="sm" textAlign={"center"}>
                New has been deleted
              </Text>
            </Card>
          </Flex>
        )}
        {newData && (
          <>
            <Card mt="3" mb="3">
              <BannerImage
                src={config.apiUrl + "/news/file/" + newData.image}
                onEdit={updateNewsAction}
                image={photo}
                setImage={setPhoto}
                imageError={photoError}
                canEdit={userType == "Admin" ? true : false}
              />
              <Flex justifyContent={"flex-end"} mt={"15px"}>
                {!showEditInfos && (
                  <Icon as={EditIcon} onClick={() => setShowEditInfos(true)} />
                )}
                {showEditInfos && (
                  <Icon
                    as={CloseIcon}
                    fontSize="12px"
                    onClick={() => setShowEditInfos(false)}
                  />
                )}
              </Flex>
              {!showEditInfos && (
                <>
                  {" "}
                  <Flex
                    mt="2"
                    w="100%"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      color={textColorPrimary}
                      fontWeight="bold"
                      fontSize="2xl"
                      mt="10px"
                      mb="4px"
                      mr="4"
                    >
                      {newData.title}
                    </Text>
                    <Button
                      size="sm"
                      variant="outline"
                      leftIcon={<BiLike />}
                      onClick={likeNewAction}
                      backgroundBlendMode={newData.likes.includes(userId)}
                      backgroundColor={
                        newData.likes.includes(userId) ? "lightblue" : ""
                      }
                    >
                      Like
                    </Button>
                  </Flex>
                  <Flex
                    mt="2"
                    mb="2"
                    flexDirection={"row"}
                    justifyContent="space-between"
                  >
                    <Text color={textColorSecondary} fontSize="sm">
                      Created At {moment(newData.createdAt).format("LLL")}
                    </Text>
                    <div>
                      {" "}
                      <Badge fontSize="0.8em" mb="5px" mr="2px" s>
                        <Text color={textColorSecondary} fontSize="sm" ml="2">
                          {newData.likes.length} Likes
                        </Text>
                      </Badge>
                      <Badge mb="5px" mr="2px">
                        <Text color={textColorSecondary} fontSize="sm" ml="2">
                          {newData.views.length} Views
                        </Text>
                      </Badge>
                    </div>
                  </Flex>
                  <Flex flexDirection={"column"}>
                    {" "}
                    {newData &&
                      newData.tags &&
                      newData.tags.length > 0 && (
                        <div>
                          {newData.tags.map((tag, i) => (
                            <Badge colorScheme="green" mb="5px" mr="2px">
                              <Box key={tag + i} float="left">
                                <Text
                                  color={textColorSecondary}
                                  width="100%"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                >
                                  #{tag}
                                </Text>
                              </Box>
                            </Badge>
                          ))}{" "}
                        </div>
                      )}{" "}
                    <Text color={textColorSecondary} fontSize="md" mb="20px">
                      {newData.description}
                    </Text>
                  </Flex>
                </>
              )}
              {showEditInfos && (
                <EditFormNew
                  news={newData}
                  setShowEditInfos={setShowEditInfos}
                  onRelaod={() => setReload(true)}
                ></EditFormNew>
              )}
            </Card>
            <Card>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Heading size="sm" mb="4">
                  Comments (
                  {newData.comments && newData.comments.length})
                </Heading>{" "}
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<BiChat />}
                  onClick={() => setShowAddComment(true)}
                >
                  Comment
                </Button>
              </Flex>
              {showAddComment && (
                <>
                  <Flex mt="2" direction={"column"}>
                    <Textarea
                      marginTop={"5px!important"}
                      w={"100%"}
                      marginRight="10px"
                      placeholder="Put your comment here"
                      borderRadius="5px"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Flex>
                  <Flex justifyContent="end" mt="4" mb="2">
                    <Button colorScheme="blue" size="sm" onClick={addComment}>
                      Add Comment
                    </Button>
                  </Flex>
                </>
              )}
              {newData.comments &&
                newData.comments.length > 0 &&
                newData.comments.map((comment) => (
                  <Flex align="center" mb="3">
                    <Avatar
                      w="50px"
                      h="50px"
                      me="8px"
                      src={config.apiUrl + "/api/getfile/" + comment.user.Image}
                    />
                    <Flex flexDirection={"column"} justifyContent={"center"}>
                      <Text color={textColor} fontSize="sm" fontWeight="600">
                        {comment.user.firstName + " " + comment.user.lastName}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="xs"
                        fontWeight="300"
                      >
                        {comment.comment}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              {newData.comments && newData.comments.length === 0 && (
                <TextCard text="There is no comments" />
              )}
            </Card>
          </>
        )}
      </Flex>
    </>
  );
};

export default NewDetails;
