import React from "react";
import { Flex, Heading, useColorModeValue } from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import FileUpload from "components/upload/FileUpload";
import { useEffect, useState } from "react";
import { maxVideoFileSize } from "utils/constants";

const AssistingProgramsMediaUpload = (props) => {
  const { image, setImage, video, setVideo, activeStep, steps, setActiveStep } =
    props;
  const textColor = useColorModeValue("secondaryGray.700", "white");
  const [imageError, setImageError] = useState("");
  const [videoError, setVideoError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const validateForm = () => {
    let valid = true;
    if (!image.length) {
      setImageError("Image is required!");
      valid = false;
    } else {
      setImageError("");
    }
    if (!video.length) {
      setVideoError("Video is required!");
      valid = false;
    } else if (video[0].size > maxVideoFileSize) {
      setVideoError("File size must be less than 25MB!");
      valid = false;
    } else {
      setVideoError("");
    }
    return valid;
  };

  const next = () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [image, video, submitted]);

  return (
    <Flex flexDirection={"column"} mt="3">
      <Flex justifyContent={"flex-start"} flexDirection={"column"} mb="4">
        <Heading size="sm" mb="3" color={textColor}>
          1- Upload an image:
        </Heading>
        <FileUpload
          multiple={false}
          files={image}
          setFiles={setImage}
          error={imageError}
        />
      </Flex>
      <Flex justifyContent={"flex-start"} flexDirection={"column"} mb="4">
        <Heading size="sm" mb="3" color={textColor}>
          2- Upload a video (Max 25MB):
        </Heading>
        <FileUpload
          multiple={false}
          acceptedFileTypes="video/*"
          files={video}
          setFiles={setVideo}
          error={videoError}
        />
      </Flex>
      <Flex justifyContent={"space-between"} mt="3">
        {activeStep !== 0 && activeStep !== steps.length - 1 && (
          <BasicButton
            onClick={() => setActiveStep(activeStep - 1)}
            title={"Previous"}
            variant="solid"
          />
        )}
        {activeStep !== steps.length - 1 && (
          <BasicButton onClick={next} title={"Next"} variant="solid" />
        )}
      </Flex>
    </Flex>
  );
};

export default AssistingProgramsMediaUpload;
