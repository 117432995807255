import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
  Text,
  Button,
  FormControl,
  Input,
  Textarea,
  SimpleGrid,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { Formik, Form, Field } from "formik";
import { Chips } from "primereact/chips";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addNew } from "reducers/news";
import { BsDownload, BsFillFileEarmarkImageFill, BsX } from "react-icons/bs";
import { SocketContext } from "contexts/SocketContext";
import FileUpload from "components/upload/FileUpload";

const allowedImageTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml",
];

const UploadBox = ({ setFiles, files }) => {
  const handleDrop = (acceptedFiles) => {
    if (files.length === 0) {
      setFiles(
        acceptedFiles.filter((file) => {
          if (allowedImageTypes.includes(file.type)) {
            return true;
          } else {
            alert("File type not allowed!");
            return false;
          }
        })
      );
    }
  };

  const deleteFile = (file, e) => {
    e.preventDefault();
    setFiles(files.filter((f) => f !== file));
  };

  const [isDragging, setIsDragging] = useState(false);

  const onDragEnter = () => {
    setIsDragging(true);
  };

  const onDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <Box
      border={isDragging ? "2px dotted #11047A" : "3px solid gray"}
      m="auto"
      w="100%"
      p="20px"
      mb="10px"
      borderRadius="20px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Dropzone
        onDragLeave={onDragLeave}
        onDragEnter={onDragEnter}
        onDrop={handleDrop}
        multiple={false}
        accept={allowedImageTypes}
        maxFiles={0}
      >
        {({ getRootProps, getInputProps }) => (
          <Box {...getRootProps()} p="20px">
            <input {...getInputProps()} />
            {files.length === 0 ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Icon as={BsDownload} w={6} h={6} color="grey" />
                <Text color="grey">Upload an image</Text>
              </Flex>
            ) : (
              <Flex flexDirection="column">
                {files.map((file) => (
                  <Flex
                    justifyContent={"center"}
                    alignContent="center"
                    alignItems="center"
                    key={file.name}
                    p="10px"
                  >
                    <Icon
                      as={BsFillFileEarmarkImageFill}
                      w={8}
                      h={8}
                      color="black"
                      mr="2"
                    />

                    <Text mr="10px">{file.name.slice(0, 12) + ".."}</Text>
                    <IconButton
                      variant={"outline"}
                      size="sm"
                      onClick={(e) => deleteFile(file, e)}
                      icon={<Icon as={BsX} w={6} h={6} color="black" />}
                    />
                  </Flex>
                ))}
              </Flex>
            )}
          </Box>
        )}
      </Dropzone>
    </Box>
  );
};

export default function AddNewModal({
  isOpen,
  onClose,
  getNews,
  hackathon = false,
  onReload

}) {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const notificationsSettings = useSelector((state) =>
    state.NotificationsSettings.notificationsSettings.data
      ? state.NotificationsSettings.notificationsSettings.data.message
      : []
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const [image, setImage] = useState([]);
  const [input, setInput] = useState({ title: "", description: "", tags: [] });
  const status = useSelector((state) => state.News.addNew.status);

  useEffect(() => {
    if (status === "success") {
      setImage([]);
      // toast.success("New has been added successfully!", {
      //   position: "top-right",
      //   hideProgressBar: true,
      //   theme: "light",
      // });
      onClose();
      if (!hackathon) {
        getNews();
      }
    }
  }, [status]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("The title is required!"),
    description: Yup.string().required("The description is required!"),
    tags: Yup.array(),
  });

  const addNews = async (values) => {
    const data = new FormData();

    data.append("title", values.title);
    data.append("description", values.description);
    data.append("image", image[0]);
    for (const tag of values.tags) {
      data.append("tags", tag);
    }
    console.log(notificationsSettings, "notifS");
    const dataNews = {
      newData: data,
      roles: notificationsSettings
        ? notificationsSettings.NewsAnnouncement
        : null,
      currentUserId: currentUser ? currentUser._id : null,
      socket: socket,
    };
    await dispatch(addNew(dataNews)).unwrap();
    if(hackathon)
    {
      onReload()
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* <UploadBox
            setFiles={setImage}
            files={image}
            allowedTypes={allowedImageTypes}
          /> */}
          <FileUpload multiple={false} files={image} setFiles={setImage} />
          <Formik
            initialValues={{
              title: "",
              description: "",
              tags: [],
            }}
            mt='2px'
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setInput(values);
              addNews(values);
            }}
          >
            {({
              isSubmitting,
              setFieldValue,
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <SimpleGrid columns="1" gap="10px" mb="4" mt='10px'>
                  <FormControl display="flex" flexDirection="column" isRequired>
                    <Field name="title">
                      {({ field, form }) => (
                        <div>
                          <Input
                            {...field}
                            type="text"
                            placeholder="Title"
                            id="title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                          />
                          {errors.title && touched.title && (
                            <Text color="red.500" my="1.5">
                              {errors.title}
                            </Text>
                          )}
                        </div>
                      )}
                    </Field>
                  </FormControl>
                  <FormControl display="flex" flexDirection="column">
                    <Flex flexDirection={"column"}>
                      <Chips
                        placeholder="Tags (press enter to confirm tag)"
                        inputId="tags"
                        name="tags"
                        value={values.tags}
                        onChange={handleChange}
                        w="100%"
                      />
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <Flex flexDirection={"column"}>
                      <Field
                        w="380px"
                        borderColor={"#E0E5F2"}
                        name="description"
                        mb="-1.5"
                      >
                        {({ field, form }) => (
                          <Textarea
                            h="160px"
                            placeholder="Description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </Field>
                      {errors.description && touched.description ? (
                        <Text color="red.500" my="1.5">
                          {errors.description}
                        </Text>
                      ) : null}
                    </Flex>
                  </FormControl>
                </SimpleGrid>
                <Flex justifyContent="end" mt="2" mb="2">
                  <Button
                    mr={3}
                    onClick={onClose}
                    variant="outline"
                    colorScheme={"blue"}
                    size="sm"
                  >
                    Cancel
                  </Button>
                  <Button size="sm" colorScheme="blue" type="submit">
                    Add New
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
