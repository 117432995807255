import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import TextareaField from "components/fields/TextareaField";
import BasicButton from "components/button/BasicButton";
import { useEffect } from "react";
import { update } from "reducers/programPhase";
import { useDispatch } from "react-redux";
import { updateCohort } from "reducers/cohort";
import { updatePrize } from "reducers/hackathon";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditPrize = ({ isOpen, onClose, initialData, prize, onReload }) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [value, setValue] = useState();

  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [valueError, setValueError] = useState("");

  const validateForm = () => {
    let valid = true;

    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!value) {
      setValueError("Value is required!");
      valid = false;
    } else {
      setValueError("");
    }
    return valid;
  };
  const onEditHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const payload = {
      id: prize._id,
      title: title,
      description: description,
      value: value,
    };
    await dispatch(updatePrize(payload)).unwrap();

    onReload();
    onClose();
  };
  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [title, description, value]);

  useEffect(() => {
    if (prize) {
      setTitle(prize.title);
      setDescription(prize.description);
      setValue(prize.value);
    }
  }, [prize]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Prize</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mb="3">
              <InputField
                label="Title"
                placeholder="Title"
                value={title}
                setValue={setTitle}
                error={titleError}
                type="text"
              />
            </Flex>
            <Flex mb="3">
              <TextareaField
                label="Description"
                placeholder="Description"
                value={description}
                setValue={setDescription}
                error={descriptionError}
              />
            </Flex>
            <Flex mt="2" direction={"column"} mb="5px">
              <InputField
                label="value"
                placeholder="value"
                value={value}
                setValue={setValue}
                error={valueError}
                rightElement="Hbar"
                type="number"
              />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={onEditHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPrize;
