import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ProjectsSelector from "components/project/ProjectsSelector";
import BasicButton from "components/button/BasicButton";
import { getUserProjectInAssistingPrograms } from "reducers/assistingProgram";
import { applyForAssistingProgram } from "reducers/assistingProgram";
import Loader from "components/loader/Loader";
import { useHistory } from "react-router-dom";

export default function ApplyAssistingProgramsModal(props) {
  const { assistingProgram, isOpen, onClose, onReload } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [projectsError, setProjectsError] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);

  const currentUserProjects = useSelector((state) =>
    state.AssistingProgram.getProjectAssistingPrograms.data
      ? state.AssistingProgram.getProjectAssistingPrograms.data
          .availableProjects
      : null
  );
  const status = useSelector((state) =>
    state.AssistingProgram.getProjectAssistingPrograms
      ? state.AssistingProgram.getProjectAssistingPrograms.status
      : null
  );
  const totalPages = useSelector(
    (state) =>
      state.AssistingProgram.getProjectAssistingPrograms.data.totalPages
  );

  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const applyForCohortAction = async () => {
    if (!selectedProjects.length) {
      setProjectsError("You should select a project!");
      return;
    }
    const ids = selectedProjects.map((item) => item._id);
    const dataApply = {
      assistingProgramId: assistingProgram._id,
      projectIds: ids,
    };
    await dispatch(applyForAssistingProgram(dataApply)).unwrap();
    onClose();
  };

  useEffect(() => {
    if (currentUser) {
      const getconfirmedProjects = {
        id: assistingProgram._id,
        limit: "4",
        page: page,
      };
      dispatch(getUserProjectInAssistingPrograms(getconfirmedProjects));
    }
  }, [page, currentUser]);

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Apply For Assisting Program</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {currentUserProjects && currentUserProjects.length > 0 && (
            <Card p="2" direction="column" w="100%">
              <ToastContainer />
              <Text fontWeight="300" mb="1rem">
                To apply for this assisting program, you need to choose one or
                more of your projects.
              </Text>
              <ProjectsSelector
                projects={currentUserProjects}
                error={projectsError}
                page={page}
                setPage={setPage}
                selectedProjects={selectedProjects}
                setSelectedProjects={setSelectedProjects}
                totalPages={totalPages}
              />
            </Card>
          )}
          {currentUserProjects &&
            currentUserProjects.length == 0 &&
            currentUser.userType === "ProjectHolder" && (
              <Card p="2" direction="column" w="100%">
                <Text mb="2">
                  If you have already submitted all your projects to this
                  assistance program and wish to apply again, it is crucial to
                  come up with a brilliant new idea.
                </Text>
                <Text mb="2">
                  This new concept should be innovative, unique, and capable of
                  standing out among the multitude of projects. It should
                  demonstrate your creativity, problem-solving skills, and
                  ability to contribute positively to the program.
                </Text>
                <Text mb="2">
                  Remember, a brilliant idea is the cornerstone of a successful
                  application. So, take your time, brainstorm, and let your
                  creativity flow! Good luck with your next application.
                </Text>
              </Card>
            )}
          {!currentUserProjects && status === "loading" && <Loader />}
          {currentUser.userType === "community" && (
            <Card p="2" direction="column" w="100%">
              <Text mb="2">
                Got a brilliant idea? Don't hesitate to add your project and
                apply for this assisting program. Just click on 'Add your
                Project' to get started.
              </Text>
            </Card>
          )}
        </ModalBody>
        <ModalFooter display={"flex"} justifyContent={"space-between"}>
          <BasicButton
            title={"Close"}
            variant="solid"
            colorScheme="gray"
            onClick={onClose}
          />
          {(currentUser.userType === "community" ||
            (currentUserProjects &&
              currentUserProjects.length == 0 &&
              currentUser.userType === "ProjectHolder")) && (
            <BasicButton
              variant="solid"
              colorScheme="blue"
              title="Add your Project"
              onClick={() => history.push("/admin/add-project")}
            />
          )}
          {currentUserProjects &&
            currentUserProjects.length > 0 &&
            currentUser.userType === "ProjectHolder" && (
              <BasicButton
                title={"Apply"}
                variant="solid"
                colorScheme="blue"
                onClick={applyForCohortAction}
              />
            )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
