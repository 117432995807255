import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaUpload, FaTimes } from "react-icons/fa";
import {
  HStack,
  VStack,
  Text,
  Flex,
  IconButton,
  Box,
  Image,
  Progress,
} from "@chakra-ui/react";

const FileUpload = ({
  multiple = true,
  acceptedFileTypes = "image/*",
  files,
  setFiles,
  error,
  previewSectionTitle,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploading(true);
      setUploadProgress(0);
      const totalFiles = acceptedFiles.length;

      acceptedFiles.forEach(async (file, index) => {
        const formData = new FormData();
        formData.append("file", file);

        // Simulating a delay in the file upload
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Simulating progress (update progress bar)
        setUploadProgress(((index + 1) / totalFiles) * 100);

        // Handle the actual file upload logic here
        // For a real implementation, send the formData to your server

        // Once the file is uploaded, you can update the UI
        setFiles((prevFiles) => [...prevFiles, file]);
      });

      // Reset upload state when done
      setUploading(false);
    },
    [setFiles, setUploading, setUploadProgress]
  );

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    setUploadProgress(0); // Reset progress when files are removed
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple,
  });

  return (
    <VStack spacing="4" align="center" width="100%">
      {files.length > 0 && !uploading && (
        <VStack spacing="4" align="left" width="max-content">
          {previewSectionTitle && (
            <Text fontSize="md" fontWeight="500">
              {previewSectionTitle}
            </Text>
          )}
          <VStack spacing="2" align="left" width="100%">
            {files.map((file, index) => (
              <Box
                key={file.name}
                borderWidth="1px"
                rounded="md"
                p="2"
                width="100%"
              >
                {file.type.startsWith("image/") ? (
                  <Image
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    maxW="200px"
                    rounded="md"
                  />
                ) : file.type.startsWith("video/") ? (
                  <Box
                    as="video"
                    maxW="200px"
                    rounded="md"
                    controls
                    autoPlay
                    muted
                  >
                    <source src={URL.createObjectURL(file)} type={file.type} />
                  </Box>
                ) : (
                  <Text>Preview not available</Text>
                )}
                <Box mt="2" borderTopWidth="1px" pt="2" maxW="200px">
                  <Flex justify="space-between" align="center">
                    <Text fontSize="sm">{file.name}</Text>
                    <HStack spacing="2">
                      <IconButton
                        icon={<FaTimes />}
                        size="sm"
                        aria-label="Remove"
                        variant="outline"
                        colorScheme="red"
                        onClick={() => removeFile(index)}
                      />
                    </HStack>
                  </Flex>
                </Box>
              </Box>
            ))}
          </VStack>
        </VStack>
      )}
      <Flex
        {...getRootProps()}
        p="6"
        borderWidth="2px"
        borderStyle="dashed"
        rounded="md"
        cursor="pointer"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <input {...getInputProps()} />
        {uploading ? (
          <Progress value={uploadProgress} size="sm" isAnimated mb="2" />
        ) : (
          <Flex align="center">
            <IconButton
              icon={<FaUpload />}
              size="lg"
              aria-label="Upload"
              variant="outline"
              colorScheme="blue"
            />
            <Text ml="2" fontSize="sm">
              {`Drag & drop ${
                multiple ? "files" : "a file"
              } here, or click to select ${multiple ? "files" : "a file"}`}
            </Text>
          </Flex>
        )}
      </Flex>

      {error && <Text color="red">{error}</Text>}
    </VStack>
  );
};

export default FileUpload;
