import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex, Divider, Grid, GridItem, Box } from "@chakra-ui/react";
import config from "config/axios/config";
import { update } from "reducers/hackathon";
import HackathonForm from "./HackathonForm";
import VideoPlayer from "components/video/VideoPlayer";
import Prizes from "./Prizes";
import JudgingCriteria from "./JudgingCriteria";
import SubmissionGuide from "./SubmissionGuide";
import BuildGuide from "./BuildGuide";
import AskManager from "./AskManager";
import HackathonInfos from "./HackathonInfos";
import { getProjectsbyUser } from "reducers/project";

export default function HackathonOverview(props) {
  const {
    hackathon,
    canEdit,
    onReload,
    hackathonPrize,
    canApply,
    intrested,
    nbParticipants,
  } = props;
  const dispatch = useDispatch();
  const [showEditInfos, setShowEditInfos] = useState(false);
  const [userType, setUserType] = useState("");
  const [video, setVideo] = useState([]);
  const [videoError, setVideoError] = useState("");
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const currentUserProjects = useSelector((state) =>
    state.Project.projects.data
      ? state.Project.projectsbyUser.data.message
      : null
  );
  const updateVideoHandler = async () => {
    if (!video.length) {
      setVideoError("The video is required!");
      return;
    } else {
      setVideoError("");
      const data = new FormData();
      data.append("video", video[0]);
      const hackathonData = {
        data,
        id: hackathon._id,
      };
      await dispatch(update(hackathonData)).unwrap();
      onReload();
    }
  };
  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
  }, [currentUser]);
  useEffect(() => {
    if (currentUser) {
      const getconfirmedProjects = {
        id: currentUser._id,
        isConfirmed: true,
      };
      dispatch(getProjectsbyUser(getconfirmedProjects));
    }
  }, [currentUser]);
  return (
    <>
      {!showEditInfos && (
        <Flex direction={"column"} mt="2">
          <HackathonInfos
            hackathon={hackathon}
            hackathonPrize={hackathonPrize}
            canJoin={currentUserProjects && currentUserProjects.length > 0}
            userType={userType}
            canApply={canApply}
            onReload={onReload}
            intrested={intrested}
            nbParticipants={nbParticipants}
            canEdit={canEdit}
          />
          <Divider my="2" />
          <Box p={2}>
            <Grid
              templateColumns={{ base: "1fr", md: "2fr 1fr", lg: "2fr 1fr" }}
              gap={4}
            >
              <GridItem>
                <VideoPlayer
                  videoSrc={config.apiUrl + `/hackathons/file/` + hackathon.video}
                  width="100%"
                  canEdit={canEdit}
                  video={video}
                  setVideo={setVideo}
                  videoError={videoError}
                  onEdit={updateVideoHandler}
                  noVideoMessage="No video available for this project."
                />
              </GridItem>
              <GridItem>
                <AskManager
                  managerEmail={hackathon.managerEmail}
                  hackathon={hackathon}
                  onReload={onReload}
                  canEdit={canEdit}
                />
              </GridItem>
            </Grid>
          </Box>
          {hackathon && hackathon.whatToBuild && (
            <>
              <Divider my="4" />
              <BuildGuide
                whatToBuild={hackathon.whatToBuild}
                hackathon={hackathon}
                onReload={onReload}
                canEdit={canEdit}
              />
            </>
          )}{" "}
          <Divider my="4" />
          {hackathon && hackathon.submitTypes && (
            <SubmissionGuide
              submitTypes={hackathon.submitTypes}
              idhackathon={hackathon._id}
              onReload={onReload}
            />
          )}
          <Divider my="4" />
          <Prizes
            hackathonPrize={hackathonPrize}
            hackathon={hackathon}
            onReload={onReload}
            canEdit={canEdit}
          />
          <Divider my="4" />
          
          <JudgingCriteria
            hackathonJudgingCriteria={hackathon.judgingCriteria}
            idhackathon={hackathon._id}
            onReload={onReload}

          />
        </Flex>
      )}
      {showEditInfos && (
        <HackathonForm
          hackathon={hackathon}
          setShowEditInfos={setShowEditInfos}
          onReload={onReload}
        />
      )}
    </>
  );
}
