import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import BasicButton from "components/button/BasicButton";
import { useEffect } from "react";
import { updateProject } from "reducers/project";
import { useDispatch } from "react-redux";
import { checkIsValidLinkedinUrl } from "utils/functions";
import { checkIsValidSiteWeb } from "utils/functions";

const EditLinksForm = ({ isOpen, onClose, initialData, onReload }) => {
  const dispatch = useDispatch();
  const [linkedin, setLinkedin] = useState(initialData?.linkedinUrl);
  const [siteWeb, setSiteWeb] = useState(initialData?.website);
  const [otherLink, setOtherLink] = useState(initialData?.otherlink);

  const [linkedinError, setLinkedinError] = useState("");
  const [siteWebError, setSiteWebError] = useState("");
  const [otherLinkError, setOtherLinkError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const validateForm = () => {
    let valid = true;
    if (!linkedin) {
      setLinkedinError("Linkedin is required!");
      valid = false;
    } else if (!checkIsValidLinkedinUrl(linkedin)) {
      setLinkedinError("Invalid LinkedIn URL!");
      valid = false;
    } else {
      setLinkedinError("");
    }
    if (!siteWeb) {
      setSiteWebError("Web site is required!");
      valid = false;
    } else if (!checkIsValidSiteWeb(siteWeb)) {
      setSiteWebError("Invalid website URL!");
      valid = false;
    } else {
      setSiteWebError("");
    }
    if (otherLink && !checkIsValidSiteWeb(otherLink)) {
      setOtherLinkError("Invalid URL!");
      valid = false;
    } else {
      setOtherLinkError("");
    }
    return valid;
  };
  const onEditHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const data = new FormData();
    data.append("linkedinUrl", linkedin);
    data.append("website", siteWeb);
    data.append("otherlink", otherLink);

    const projectData = {
      data,
      id: initialData._id,
    };
    await dispatch(updateProject(projectData)).unwrap();
    onReload();
  };
  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [linkedin, siteWeb, otherLink, submitted]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Social Media Links</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <InputField
              label="Linkedin"
              placeholder="Linkedin"
              value={linkedin}
              setValue={setLinkedin}
              error={linkedinError}
            />
            <InputField
              label="Web Site"
              placeholder="Web Site"
              value={siteWeb}
              setValue={setSiteWeb}
              error={siteWebError}
            />
            <InputField
              label="Other Link"
              placeholder="Other Link"
              value={otherLink}
              setValue={setOtherLink}
              error={otherLinkError}
              required={false}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={onEditHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditLinksForm;
