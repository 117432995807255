import React from "react";
import { Text, Flex, Divider, Box, useDisclosure } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import moment from "moment";
import BasicButton from "components/button/BasicButton";
import { useState, useEffect } from "react";
import { applyForHackathon } from "reducers/cohort";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import JoinHackathonModal from "../JoinHackathonModal";
import { FaCalendar } from "react-icons/fa";
import { getTracksByHackathon } from "reducers/track";
import InterestedToHackathonModal from "../InterestedToHackathonModal";
import CountdownTimer from "components/countdown/CountdownTimer";
import { announceResults } from "reducers/hackathon";
import { useHistory } from "react-router-dom";
import AddNewModal from "views/admin/news/components/AddNewModal";
import EditHackathonInfos from "../editHackathon/EditHackathonInfos";
import { getHackathonTracksTitles } from "reducers/track";
import { interstedInHackathon } from "reducers/hackathon";
import MeetingModal from "../MeetingModal";

export default function HackathonInfos(props) {
  const {
    hackathon,
    hackathonPrize,
    canJoin,
    userType,
    canApply,
    onReload,
    intrested,
    nbParticipants,
    canEdit,
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [sumPrize, setSumPrize] = useState("");
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenM1,
    onOpen: onOpenM1,
    onClose: onCloseM1,
  } = useDisclosure();
  const {
    isOpen: isOpenNew,
    onOpen: onOpenNew,
    onClose: onCloseNew,
  } = useDisclosure();
  const {
    isOpen: isOpenModalEdit,
    onOpen: onOpenModalEdit,
    onClose: onCloseModalEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenModalMeeting,
    onOpen: onOpenModalMeeting,
    onClose: onCloseModalMeeting,
  } = useDisclosure();
  const tracks = useSelector((state) =>
    state.Track.tracksByHackathon.data
      ? state.Track.tracksByHackathon.data.result
      : []
  );
  const showedResults = useSelector((state) =>
    state.Hackathon.announceResults.data
      ? state.Hackathon.announceResults.data.showedResults
      : []
  );
  const getSumOfPrizes = () => {
    const sum = hackathonPrize.reduce((total, prize) => total + prize.value, 0);
    setSumPrize(sum);
    return sum;
  };
  const interstedInHackathonAction = async () => {
    await dispatch(interstedInHackathon(hackathon._id))
      .unwrap()
      .then((res) => {
        toast.success(res.message, {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
        onReload();
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
      });
  };
  useEffect(() => {
    if (hackathonPrize) {
      getSumOfPrizes();
    }
  }, []);
  const getTracksByHackathonAction = async () => {
    const payload = {
      hackathonId: hackathon._id,
    };
    await dispatch(getHackathonTracksTitles(payload)).unwrap();
  };
  useEffect(() => {
    getTracksByHackathonAction();
  }, [hackathon]);
  const targetDate = new Date(hackathon && hackathon.end);

  const announceResultsAction = async () => {
    await dispatch(announceResults(hackathon._id)).unwrap();
    onOpenNew();
  };
  return (
    <Flex width="100%">
      {isOpen && (
        <JoinHackathonModal
          hackathon={hackathon}
          isOpen={isOpen}
          onClose={onClose}
          onReload={onReload}
          tracks={tracks}
        />
      )}
      <InterestedToHackathonModal
        isOpen={isOpenM1}
        onClose={onCloseM1}
        onConfirm={interstedInHackathonAction}
        isInterested={!intrested}
      />
      <MeetingModal
        isOpen={isOpenModalMeeting}
        onClose={onCloseModalMeeting}
        onConfirm={interstedInHackathonAction}
        isInterested={!intrested}
      />
      <AddNewModal
        isOpen={isOpenNew}
        onClose={onCloseNew}
        hackathon={true}
        onReload={onReload}
      />
      <EditHackathonInfos
        isOpen={isOpenModalEdit}
        onClose={onCloseModalEdit}
        initialData={hackathon}
        onReload={onReload}
      />
      <Flex direction={"column"} width="100%">
        {" "}
        <Flex justifyContent={"space-between"}>
          <Text
            color={textColor}
            mr="4px"
            fontSize={"2xl"}
            mb="5px"
            fontWeight="bold"
            me="14px"
          >
            {hackathon.title}
          </Text>
          <Flex>
            {canJoin && (
              <BasicButton
                onClick={onOpen}
                variant="solid"
                title={"Join Hackathon"}
                size="md"
                colorScheme="green"
                disabled={!canApply || (tracks && tracks.length === 0)}
                style={{ borderRadius: "5px" }}
              />
            )}

            {userType == "Admin" && (
              <BasicButton
                onClick={onOpenModalMeeting}
                variant="solid"
                title={"Announce Meeting"}
                size="md"
                colorScheme="green"
                style={{ borderRadius: "5px", marginRight: "5px" }}
              />
            )}
            {userType == "Admin" && new Date(hackathon.end) < new Date() && (
              <BasicButton
                onClick={announceResultsAction}
                variant="solid"
                title={"Announce Results"}
                size="md"
                colorScheme="green"
                style={{ borderRadius: "5px" }}
                disabled={hackathon.showedResults}
              />
            )}
            {userType == "community" && !canJoin && (
              <BasicButton
                onClick={onOpenM1}
                variant="solid"
                title={"Interested"}
                colorScheme="green"
                size="md"
                style={{ borderRadius: "5px" }}
                disabled={!intrested || new Date(hackathon.end) < new Date()}
              />
            )}
            {canEdit && (
              <BasicButton
                onClick={onOpenModalEdit}
                variant="solid"
                title={"Edit"}
                size="md"
                colorScheme="blue"
                style={{ borderRadius: "5px", marginLeft: "5px" }}
              />
            )}
          </Flex>
        </Flex>
        <Flex justifyContent={"space-between"} mt="3">
          <Text fontSize={"md"} color={textColor} me="14px" width="60%">
            {hackathon.description}
          </Text>
          <Flex direction={"column"} fontSize={"md"} width="40%">
            <Box
              p={4}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              boxShadow="md"
              height="max-content"
              backgroundColor="#9e9e9e14"
              textAlign={"start"}
            >
              <Flex direction="column" mb="2">
                <CountdownTimer targetDate={targetDate} />
              </Flex>
              <Flex direction="column">
                <Flex
                  fontWeight={"700"}
                  direction="row"
                  alignItems={"center"}
                  color="#11047a"
                >
                  <FaCalendar />
                  <Text ml="2" fontWeight={"bold"}>
                    Start Date
                  </Text>
                </Flex>
                <Text fontWeight={"400"}>
                  {moment(hackathon.start).format("llll")}
                </Text>
              </Flex>
              <Divider borderColor="lightGray" my={2} />
              <Flex direction="column">
                <Flex
                  fontWeight={"700"}
                  direction="row"
                  alignItems={"center"}
                  color="#11047a"
                >
                  <FaCalendar />
                  <Text ml="2" fontWeight={"bold"}>
                    End Date
                  </Text>
                </Flex>
                <Text fontWeight={"400"}>
                  {moment(hackathon.end).format("llll")}
                </Text>
              </Flex>
              <Divider borderColor="lightGray" my={2} />
              <Flex direction="column">
                <Text>
                  <b style={{ color: "#11047a" }}>{sumPrize} USD</b> in prizes
                </Text>
                <Text>
                  <b style={{ color: "#11047a" }}>{nbParticipants}</b>{" "}
                  participants
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
