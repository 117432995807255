import {
  Box,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { Link as Linkk } from "react-router-dom";
import placeholder from "assets/img/placeholder.jpg";

export default function Project(props) {
  const { title, ranking, link, image, ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p="14px">
      <Flex align="center" direction={{ base: "column", md: "row" }}>
        <Image
          h="80px"
          w="80px"
          src={image}
          borderRadius="8px"
          me="20px"
          fallbackSrc={placeholder}
          objectFit="cover"
        />
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight="500"
            fontSize="md"
            mb="4px"
          >
            {title}
          </Text>
          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="sm"
            me="4px"
          >
            Project #{ranking} •{" "}
            <Linkk
              fontWeight="500"
              fontSize="sm"
              to={link}
              style={{ color: "blue" }}
            >
              See project details
            </Linkk>
          </Text>
        </Box>
        <Link
          href={link}
          variant="no-hover"
          me="16px"
          ms="auto"
          p="0px !important"
        >
          {/*<Icon as={MdEdit} color='secondaryGray.500' h='18px' w='18px' /> */}{" "}
        </Link>
      </Flex>
    </Card>
  );
}
