import React, { useState } from "react";
import {
  CloseButton,
  useColorModeValue,
  Flex,
  Text,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/card/Card";

const CardItem = (props) => {
  const { value, setValue, type } = props;
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.800", "black");
  const handleRemoveCard = (index) => {
    const updatedList = [...value];
    updatedList.splice(index, 1);
    setValue(updatedList);
  };
  return (
    <>
      {value && value.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="2px">
          {value.map((item, index) => (
            <Card p="10px" boxShadow={cardShadow}>
              <Flex direction="column">
                <Flex justify="space-between" alignItems={"center"}>
                  <Text
                    color={textColor}
                    mr="4px"
                    fontSize={{
                      base: "xl",
                      md: "lg",
                      lg: "lg",
                      xl: "lg",
                      "2xl": "md",
                      "3xl": "lg",
                    }}
                    mb="5px"
                    fontWeight="bold"
                    me="14px"
                  >
                    Title : {item.title}{" "}
                  </Text>
                  <CloseButton onClick={() => handleRemoveCard(index)} />
                </Flex>
                <Text
                  className="description"
                  color={textColor}
                  mb="5px"
                  me="14px"
                >
                  {" "}
                  Description : {item.description}
                </Text>
              </Flex>
              {type == "prizes" && (
                <>
                  {" "}
                  <Divider mt="2" mb="2" />
                  <Flex color={textColor} direction="row" alignItems={"center"}>
                    <Text color="#3b82f6" fontWeight={"700"} ml="2">
                      Prize : {item.value}
                    </Text>
                  </Flex>
                </>
              )}
            </Card>
          ))}
        </SimpleGrid>
      )}
    </>
  );
};

export default CardItem;
