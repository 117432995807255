import { Text, Box, Center, Input } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Pagination from "components/pagination/Pagination";
import WideUserCard from "./WideUserCard";
import TextCard from "components/card/TextCard";

export default function UsersSelector(props) {
  const {
    users,
    selectedUsers,
    setSelectedUsers,
    showFilter = false,
    filterData,
    setFilterData,
    page = 1,
    setPage = () => {},
    error,
    totalPages = 0,
    multiple = true,
  } = props;
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const toggleUser = (user) => {
    if (multiple) {
      const x = [...selectedUsers];
      let exist = false;
      x.map((item) => {
        if (item._id === user._id) {
          exist = true;
        }
      });
      if (exist) {
        const res = x.filter((item) => item._id !== user._id);
        setSelectedUsers(res);
      } else {
        x.push(user);
        setSelectedUsers(x);
      }
    } else {
      setSelectedUsers(user);
    }
  };
  useEffect(() => {
    if (multiple) {
      const arrayOfIds = selectedUsers.map((p) => p._id);
      setSelectedUsersIds(arrayOfIds);
    } else {
      setSelectedUsersIds(selectedUsers ? [selectedUsers._id] : []);
    }
  }, [selectedUsers]);
  return (
    <>
      {showFilter && (
        <Input
          variant="outline"
          value={filterData.name}
          onChange={(e) => {
            setFilterData({ ...filterData, name: e.target.value });
          }}
          placeholder="First Name or Last Name or Email"
        />
      )}
      {users && users.length > 0 && (
        <Box>
          {users.map((item) => (
            <WideUserCard
              user={item}
              isChecked={selectedUsersIds.includes(item._id)}
              toggleUser={() => toggleUser(item)}
              showCheckbox={true}
            />
          ))}
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
          {error && (
            <Text mt="3" color="red">
              {error}
            </Text>
          )}
        </Box>
      )}
      {users?.length === 0 && (
        <Box mb={"30px"} mt={"30px"}>
          <TextCard text="There is no expert" subText={""} />
        </Box>
      )}
    </>
  );
}
