import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Step1 from "./components/applySteps/Step1";
import Step2 from "./components/applySteps/Step2";
import Step3 from "./components/applySteps/Step3";
import Step4 from "./components/applySteps/Step4";
import Step5 from "./components/applySteps/Step5";
import Step6 from "./components/applySteps/Step6";
import Step7 from "./components/applySteps/Step7";
import Step8 from "./components/applySteps/Step8";
import Step9 from "./components/applySteps/Step9";
import { getRequestCallofstartupById } from "reducers/callofStartups";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const ApplyForOpportunity = () => {
  const [applyData, setApplyData] = useState({
    project: "",
  });
  const { id } = useParams();
  const { idrequest } = useParams();
  const dispatch = useDispatch();

  const requestCall = useSelector((state) =>
    state.CallofStartups.requestCallofstartupById.data
      ? state.CallofStartups.requestCallofstartupById.data.result
      : []
  );

  const requestStatus = useSelector((state) =>
    state.CallofStartups.requestCallofstartupById.status
      ? state.CallofStartups.requestCallofstartupById.status
      : []
  );
  
  const [step, setStep] = useState(requestCall ? requestCall.step : 1);

  const getRequestCallofstartupByIdAction = async () => {
    await dispatch(getRequestCallofstartupById(idrequest)).unwrap();
    
  };
  useEffect(() => {
    console.log(requestStatus,"requestStatus")
    getRequestCallofstartupByIdAction()
  }, []);
  useEffect(() => {
    setStep(requestCall ? requestCall.step : 1)
  }, [requestCall]);
  const [project, setProject] = useState(null);
  return (
    <>
      <ToastContainer />
      {/* {requestStatus == "success" && 
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {requestCall.step === 1 && (
          <Step1
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            setProject={setProject}
            requestCall={requestCall}

          />
        )}
        {requestCall.step === 2 && (
          <Step2
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {requestCall.step === 3 && (
          <Step3
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {requestCall.step === 4 && (
          <Step4
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {requestCall.step === 5 && (
          <Step5
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            project={project}
            requestCall={requestCall}

          />
        )}
        {requestCall.step === 6 && (
          <Step6
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}
            
          />
        )}
        {requestCall.step === 7 && (
          <Step7
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {requestCall.step === 8 && (
          <Step8
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {requestCall.step === 9 && (
          <Step9
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
      </Box>
} */}
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {step === 1 && (
          <Step1
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            setProject={setProject}
            requestCall={requestCall}

          />
        )}
        {step === 2 && (
          <Step2
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {step === 3 && (
          <Step3
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {step === 4 && (
          <Step4
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {step === 5 && (
          <Step5
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            project={project}
            requestCall={requestCall}

          />
        )}
        {step === 6 && (
          <Step6
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}
            
          />
        )}
        {step === 7 && (
          <Step7
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {step === 8 && (
          <Step8
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
        {step === 9 && (
          <Step9
            applyData={applyData}
            setApplyData={setApplyData}
            setStep={setStep}
            requestCall={requestCall}

          />
        )}
      </Box>

    </>
  );
};

export default ApplyForOpportunity;
