import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom";

const InterestedToHackathonModal = (props) => {
  const { isOpen, onClose, onConfirm, isInterested } = props;
  const history = useHistory();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">
          Dive In: Join a Team or Submit Your Own Project!
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!isInterested && (
            <Flex direction={"column"} fontSize="sm">
              <Text>
                Ready to join into the hackathon? You've got two exciting paths
                to choose from:
              </Text>
              <UnorderedList p="2">
                <ListItem mb="2">
                  {" "}
                  Join a Team: Click on 'Join a Team' and let project owners
                  discover your profile. They can then add you to their dynamic
                  teams.
                </ListItem>
                <ListItem>
                  Lead Your Project: Got an idea? Add your project and jump
                  straight into action as a team leader.
                </ListItem>
              </UnorderedList>
              <Text>
                We're thrilled to have you on board for this adventure!
              </Text>
            </Flex>
          )}
          {isInterested && (
            <Flex direction={"column"} fontSize="sm">
              <Text>
                Your profile is now visible to other teams! To increase your
                chances of joining a team, we recommend completing your profile
                with your skills and strengths. Thank you for your enthusiasm
                and patience!
              </Text>
              <Text mt="2">
                Got a brilliant idea? Don't hesitate to add your project and
                become a part of the hackathon. Just click on 'Add your Project'
                to get started
              </Text>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter display={"flex"} justifyContent={"flex-end"}>
          <BasicButton
            variant="solid"
            colorScheme="blue"
            title="Add your Project"
            onClick={() => history.push("/admin/add-project")}
          />
          {!isInterested && (
            <BasicButton
              variant="solid"
              colorScheme="green"
              onClick={onConfirm}
              title="Join a Team"
              style={{ marginLeft: "10px" }}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InterestedToHackathonModal;
