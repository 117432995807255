import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateCohortApplicationService } from "services/cohortService";
import { addCohortService } from "services/cohortService";
import { announceResultsService } from "services/hackathonService";
import { addPrizeService } from "services/hackathonService";
import { getHackathonService } from "services/hackathonService";
import { getPendingProjectsByHackathonService } from "services/hackathonService";
import { updateHackathonApplicationService } from "services/hackathonService";
import { deleteHackathonService } from "services/hackathonService";
import { getInterstedMembersForHackathonService } from "services/hackathonService";
import { getavailableprojectsToApplyService } from "services/hackathonService";
import { addProjectMembersHackathonsService } from "services/hackathonService";
import { getHackathonNotAuthService } from "services/hackathonService";
import { RatingProjectsInHackathonService } from "services/hackathonService";
import { interstedForHackathonService } from "services/hackathonService";
import { applyForHackathonService } from "services/hackathonService";
import { getTrackByIdHackathonService } from "services/hackathonService";
import { createHackathonService } from "services/hackathonService";
import { getHackathonByIdService } from "services/hackathonService";
import { deletePrizeService } from "services/hackathonService";
import { updatePrizeService } from "services/hackathonService";
import { unjoinedHackathonProjectsService } from "services/hackathonService";
import { SubmissionForHackathonService } from "services/hackathonService";
import { hackathonParticipantsService } from "services/hackathonService";
import {
  hackathonProjectsService,
  currentUserProjectsService,
  updateService,
} from "services/hackathonService";
export const getAllHackathons = createAsyncThunk(
  "hackathon/all",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getHackathonService(filterData);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllHackathonsNotAuth = createAsyncThunk(
  "hackathon/allNotAuth",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getHackathonNotAuthService(filterData);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getHackathonById = createAsyncThunk(
  "hackathon/by-id",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getHackathonByIdService(id);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getPendingProjectsByHackathon = createAsyncThunk(
  "hackathon/getPendingProjectsByHackathon",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getPendingProjectsByHackathonService(id);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addHackathon = createAsyncThunk(
  "hackathon/add",
  async (hackathonData, thunkAPI) => {
    try {
      const { status, data } = await createHackathonService(
        hackathonData.hackathon
      );
      if (status === 200 || status === 201) {
        // if (
        //   data &&
        //   data.cohort &&
        //   cohortData &&
        //   cohortData.roles &&
        //   cohortData.currentUserId
        // ) {
        //   cohortData.socket.emit("sendNotificationToAll", {
        //     type: "AddCohort",
        //     data: data.cohort,
        //     roles: cohortData.roles,
        //     sender: cohortData.currentUserId,
        //   });
        // }
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const update = createAsyncThunk(
  "hackathon/update",
  async (hackathonData, thunkAPI) => {
    try {
      const { status, data } = await updateService(hackathonData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const hackathonProjects = createAsyncThunk(
  "hackathon/projects",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await hackathonProjectsService(filterData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const hackathonParticipants = createAsyncThunk(
  "hackathon/participants",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await hackathonParticipantsService(filterData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const currentUserProjects = createAsyncThunk(
  "hackathon/current-user-projects",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await currentUserProjectsService(filterData);
      console.log("ddddddddddddd");
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addHackathonSubmission = createAsyncThunk(
  "hackathon/addSubmission",
  async (SubmissionData, thunkAPI) => {
    try {
      const { status, data } = await SubmissionForHackathonService(
        SubmissionData
      );
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const unjoinedHackathonProjects = createAsyncThunk(
  "hackathon/unjoined-projects",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await unjoinedHackathonProjectsService(
        filterData
      );
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const announceResults = createAsyncThunk(
  "cohort/announce-results",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await announceResultsService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addPrize = createAsyncThunk(
  "hackathon/add-prize",
  async (prizeData, thunkAPI) => {
    try {
      const { status, data } = await addPrizeService(prizeData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updatePrize = createAsyncThunk(
  "hackathon/update-prize",
  async (prizeData, thunkAPI) => {
    try {
      const { status, data } = await updatePrizeService(prizeData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deletePrize = createAsyncThunk(
  "hackathon/delete-prize",
  async (prizeData, thunkAPI) => {
    try {
      const { status, data } = await deletePrizeService(prizeData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getTrackByIdHackathon = createAsyncThunk(
  "hackathon/track",
  async (hackathonData, thunkAPI) => {
    try {
      const { status, data } = await getTrackByIdHackathonService(
        hackathonData
      );
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateHackathonApplication = createAsyncThunk(
  "hackathon/update-application",
  async (updateData, thunkAPI) => {
    try {
      const { status, data } = await updateHackathonApplicationService(
        updateData
      );
      if (status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const applyForHackathon = createAsyncThunk(
  "hackathon/applyForHackathon",
  async (HackathonData, thunkAPI) => {
    try {
      const { status, data } = await applyForHackathonService(HackathonData);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteHackathon = createAsyncThunk(
  "Hackathon/delete",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await deleteHackathonService(id);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const interstedInHackathon = createAsyncThunk(
  "Hackathon/interested",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await interstedForHackathonService(id);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getInterstedMembersForHackathon = createAsyncThunk(
  "Hackathon/getinterested",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getInterstedMembersForHackathonService(id);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const RatingProjectsInHackathon = createAsyncThunk(
  "Hackathon/Rating",
  async (hackathonData, thunkAPI) => {
    try {
      const { status, data } = await RatingProjectsInHackathonService(
        hackathonData
      );
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getavailableprojectsToApply = createAsyncThunk(
  "Hackathon/availableprojects",
  async (hackathonData, thunkAPI) => {
    try {
      const { status, data } = await getavailableprojectsToApplyService(
        hackathonData
      );
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addProjectMembersHackathons = createAsyncThunk(
  "hackathon/Member-hackathon",
  async (MemberData, thunkAPI) => {
    try {
      const { status, data } = await addProjectMembersHackathonsService(MemberData);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  addMemberHackathon: {
    status: "idle",
    data: {},
    error: {},
  },
  availableprojects: {
    status: "idle",
    data: {},
    error: {},
  },
  rating: {
    status: "idle",
    data: {},
    error: {},
  },
  getInterested: {
    status: "idle",
    data: {},
    error: {},
  },
  interested: {
    status: "idle",
    data: {},
    error: {},
  },
  applyHackathon: {
    status: "idle",
    data: {},
    error: {},
  },
  updateApplication: {
    status: "idle",
    data: {},
    error: {},
  },
  trackByIdHackathon: {
    status: "idle",
    data: {},
    error: {},
  },
  pendingHackathonProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  hackathon: {
    status: "idle",
    data: {},
    error: {},
  },
  hackathons: {
    status: "idle",
    data: {},
    error: {},
  },
  hackathonNotAuth: {
    status: "idle",
    data: {},
    error: {},
  },
  addSubmission: {
    status: "idle",
    data: {},
    error: {},
  },
  addHackathon: {
    status: "idle",
    data: {},
    error: {},
  },
  update: {
    status: "idle",
    data: {},
    error: {},
  },
  hackathonProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  hackathonParticipants: {
    status: "idle",
    data: {},
    error: {},
  },
  currentUserProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  unjoinedHackathonProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  announceResults: {
    status: "idle",
    data: {},
    error: {},
  },
  addPrize: {
    status: "idle",
    data: {},
    error: {},
  },
  updatePrize: {
    status: "idle",
    data: {},
    error: {},
  },
  deletePrize: {
    status: "idle",
    data: {},
    error: {},
  },
  deleteHack: {
    status: "idle",
    data: {},
    error: {},
  },
};

const HackathonSlice = createSlice({
  name: "hackathon",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [addProjectMembersHackathons.pending.type]: (state) => {
      state.addMemberHackathon = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addProjectMembersHackathons.fulfilled.type]: (state, action) => {
      state.addMemberHackathon = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addProjectMembersHackathons.rejected.type]: (state, action) => {
      state.addMemberHackathon = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAllHackathonsNotAuth.pending.type]: (state) => {
      state.hackathonNotAuth = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllHackathonsNotAuth.fulfilled.type]: (state, action) => {
      state.hackathonNotAuth = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllHackathonsNotAuth.rejected.type]: (state, action) => {
      state.hackathonNotAuth = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getavailableprojectsToApply.pending.type]: (state) => {
      state.availableprojects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getavailableprojectsToApply.fulfilled.type]: (state, action) => {
      state.availableprojects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getavailableprojectsToApply.rejected.type]: (state, action) => {
      state.availableprojects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [RatingProjectsInHackathon.pending.type]: (state) => {
      state.rating = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [RatingProjectsInHackathon.fulfilled.type]: (state, action) => {
      state.rating = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [RatingProjectsInHackathon.rejected.type]: (state, action) => {
      state.rating = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getInterstedMembersForHackathon.pending.type]: (state) => {
      state.getInterested = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getInterstedMembersForHackathon.fulfilled.type]: (state, action) => {
      state.getInterested = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getInterstedMembersForHackathon.rejected.type]: (state, action) => {
      state.getInterested = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [interstedInHackathon.pending.type]: (state) => {
      state.interested = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [interstedInHackathon.fulfilled.type]: (state, action) => {
      state.interested = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [interstedInHackathon.rejected.type]: (state, action) => {
      state.interested = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [deleteHackathon.pending.type]: (state) => {
      state.deleteHack = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deleteHackathon.fulfilled.type]: (state, action) => {
      state.deleteHack = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [deleteHackathon.rejected.type]: (state, action) => {
      state.deleteHack = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [applyForHackathon.pending.type]: (state) => {
      state.applyHackathon = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [applyForHackathon.fulfilled.type]: (state, action) => {
      state.applyHackathon = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [applyForHackathon.rejected.type]: (state, action) => {
      state.applyHackathon = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateHackathonApplication.pending.type]: (state) => {
      state.updateApplication = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateHackathonApplication.fulfilled.type]: (state, action) => {
      state.updateApplication = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateHackathonApplication.rejected.type]: (state, action) => {
      state.updateApplication = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getTrackByIdHackathon.pending.type]: (state) => {
      state.trackByIdHackathon = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getTrackByIdHackathon.fulfilled.type]: (state, action) => {
      state.trackByIdHackathon = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getTrackByIdHackathon.rejected.type]: (state, action) => {
      state.trackByIdHackathon = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getPendingProjectsByHackathon.pending.type]: (state) => {
      state.pendingHackathonProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getPendingProjectsByHackathon.fulfilled.type]: (state, action) => {
      state.pendingHackathonProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getPendingProjectsByHackathon.rejected.type]: (state, action) => {
      state.pendingHackathonProjects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getHackathonById.pending.type]: (state) => {
      state.hackathon = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getHackathonById.fulfilled.type]: (state, action) => {
      state.hackathon = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getHackathonById.rejected.type]: (state, action) => {
      state.hackathon = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAllHackathons.pending.type]: (state) => {
      state.hackathons = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllHackathons.fulfilled.type]: (state, action) => {
      state.hackathons = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllHackathons.rejected.type]: (state, action) => {
      state.hackathons = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addHackathonSubmission.pending.type]: (state) => {
      state.addSubmission = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addHackathonSubmission.fulfilled.type]: (state, action) => {
      state.addSubmission = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addHackathonSubmission.rejected.type]: (state, action) => {
      state.addSubmission = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addHackathon.pending.type]: (state) => {
      state.addHackathon = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addHackathon.fulfilled.type]: (state, action) => {
      state.addHackathon = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addHackathon.rejected.type]: (state, action) => {
      state.addHackathon = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [update.pending.type]: (state) => {
      state.update = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [update.fulfilled.type]: (state, action) => {
      state.update = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [update.rejected.type]: (state, action) => {
      state.update = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [hackathonProjects.pending.type]: (state) => {
      state.hackathonProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [hackathonProjects.fulfilled.type]: (state, action) => {
      state.hackathonProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [hackathonProjects.rejected.type]: (state, action) => {
      state.hackathonProjects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [currentUserProjects.pending.type]: (state) => {
      state.currentUserProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [currentUserProjects.fulfilled.type]: (state, action) => {
      state.currentUserProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [currentUserProjects.rejected.type]: (state, action) => {
      state.currentUserProjects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [hackathonParticipants.pending.type]: (state) => {
      state.hackathonParticipants = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [hackathonParticipants.fulfilled.type]: (state, action) => {
      state.hackathonParticipants = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [hackathonParticipants.rejected.type]: (state, action) => {
      state.hackathonParticipants = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [unjoinedHackathonProjects.pending.type]: (state) => {
      state.unjoinedHackathonProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [unjoinedHackathonProjects.fulfilled.type]: (state, action) => {
      state.unjoinedHackathonProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [unjoinedHackathonProjects.rejected.type]: (state, action) => {
      state.unjoinedHackathonProjects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [announceResults.pending.type]: (state) => {
      state.announceResults = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [announceResults.fulfilled.type]: (state, action) => {
      state.announceResults = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [announceResults.rejected.type]: (state, action) => {
      state.announceResults = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addPrize.pending.type]: (state) => {
      state.addPrize = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addPrize.fulfilled.type]: (state, action) => {
      state.addPrize = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addPrize.rejected.type]: (state, action) => {
      state.addPrize = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updatePrize.pending.type]: (state) => {
      state.updatePrize = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updatePrize.fulfilled.type]: (state, action) => {
      state.updatePrize = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updatePrize.rejected.type]: (state, action) => {
      state.updatePrize = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [deletePrize.pending.type]: (state) => {
      state.deletePrize = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deletePrize.fulfilled.type]: (state, action) => {
      state.deletePrize = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [deletePrize.rejected.type]: (state, action) => {
      state.deletePrize = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default HackathonSlice.reducer;
