import Card from "components/card/Card.js";
import {
  Box,
  Flex,
  Spinner,
  TabList,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import config from "config/axios/config";
import BannerImage from "components/image/BannerImage";
import AssistingProgramOverview from "./components/DetailsAssistingProgram/AssistingProgramOverview";
import AssistingProgramsRules from "./components/DetailsAssistingProgram/AssistingProgramsRules";
import { getDetailsAssistingPrograms } from "reducers/assistingProgram";
import Phases from "./components/DetailsAssistingProgram/Phases";
import AssistinProgramsApplyRequests from "./components/DetailsAssistingProgram/AssistinProgramsApplyRequests";
import AssistingProgramProjects from "./components/DetailsAssistingProgram/AssistingProgramProjects";
import { update } from "reducers/assistingProgram";
import BasicButton from "components/button/BasicButton";
import DeletedItemCard from "components/card/DeletedItemCard";

const AssistingProgramDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [userType, setUserType] = useState("");
  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState("");
  const assistingProgram = useSelector((state) =>
    state.AssistingProgram.getAssistingProgramsById.data
      ? state.AssistingProgram.getAssistingProgramsById.data.result
      : null
  );
  const status = useSelector(
    (state) => state.AssistingProgram.getAssistingProgramsById.status
  );
  const totalPhases = useSelector((state) =>
    state.ProgramPhase.getPhasesByIdAssisting.data
      ? state.ProgramPhase.getPhasesByIdAssisting.data.totalPhases
      : 0
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const getAssistingProgramByIdAction = async () => {
    await dispatch(getDetailsAssistingPrograms(id)).unwrap();
  };

  useEffect(() => {
    getAssistingProgramByIdAction();
  }, [id, reload]);

  useEffect(() => {
    if (currentUser && assistingProgram) {
      setIsOwner(currentUser._id == assistingProgram.createdBy);
      setUserType(currentUser.userType);
    }
  }, [currentUser, assistingProgram]);
  const updateImageHandler = async () => {
    if (!image.length) {
      setImageError("The image is required!");
      return;
    } else {
      setImageError("");
      const data = new FormData();
      data.append("image", image[0]);
      const programData = {
        data,
        id: assistingProgram._id,
      };
      await dispatch(update(programData)).unwrap();
      setReload(true);
    }
  };
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {!assistingProgram && status === "loading" && (
          <Flex flexDirection="column" h="100vh">
            <Spinner my="auto!important" mx="auto" />
          </Flex>
        )}
        {!assistingProgram && status === "failed" && (
          <DeletedItemCard item={"Assisting Program"} />
        )}
        {assistingProgram && (
          <Card>
            {isOwner && (
              <Flex w="100%" justifyContent="flex-end" mb="4">
                <BasicButton
                  title={"Add Phase"}
                  variant="solid"
                  onClick={() =>
                    history.push({
                      pathname: "/admin/add-program-phase",
                      state: { assistingProgramId: id },
                    })
                  }
                />
              </Flex>
            )}
            <BannerImage
              src={
                config.apiUrl +
                `/assisting-programs/file/` +
                assistingProgram.image
              }
              h={"180px"}
              canEdit={isOwner}
              image={image}
              setImage={setImage}
              imageError={imageError}
              onEdit={updateImageHandler}
            />
            <Tabs isManual variant="enclosed">
              <TabList>
                <Tab _selected={{ bg: "white" }}>Overview</Tab>
                <Tab _selected={{ bg: "white" }}>Rules </Tab>
                <Tab _selected={{ bg: "white" }}>Phases ({totalPhases})</Tab>

                <Tab _selected={{ bg: "white" }}>
                  Projects ({assistingProgram.totalProjects})
                </Tab>
                {isOwner && (
                  <Tab _selected={{ bg: "white" }}>
                    Requests ({assistingProgram.MyApplication})
                  </Tab>
                )}
              </TabList>
              <TabPanels backgroundColor={"white"} pr="2" pl="2">
                <TabPanel>
                  <AssistingProgramOverview
                    assistingProgram={assistingProgram}
                    onReload={() => setReload(true)}
                    getAssistingProgramByIdAction={
                      getAssistingProgramByIdAction
                    }
                  ></AssistingProgramOverview>
                </TabPanel>
                <TabPanel>
                  <AssistingProgramsRules
                    assistingProgram={assistingProgram}
                    onReload={() => setReload(true)}
                  />
                </TabPanel>
                <TabPanel>
                  <Phases id={id} userType={userType} />
                </TabPanel>
                <TabPanel>
                  <AssistingProgramProjects id={id} />
                </TabPanel>
                <TabPanel>
                  <AssistinProgramsApplyRequests id={id} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        )}
      </Box>
    </>
  );
};

export default AssistingProgramDetails;
