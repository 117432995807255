import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as Linkk } from "react-router-dom";
import MySelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Card from "components/card/Card";
import config from "config/axios/config";
import moment from "moment";
import { Months } from "./Month";
import { historyPaymentReducer } from "reducers/Konnect";
import { historymeetReducer } from "reducers/Konnect";
import Pagination from "components/pagination/Pagination";

const Index = () => {
  const dispatch = useDispatch();
  const meetings = useSelector((state) =>
    state.Konnect.historymeet.data
      ? state.Konnect.historymeet.data.historyPayment
      : null
  );
  const total_Pages = useSelector(
    (state) => state.Konnect.historymeet.data.totalPage
  );

  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const textColor = useColorModeValue("navy.700", "white");

  const GetHistoricMeetingsAction = async () => {
    var Months1 = [];

    for (var i = 0; i < selectedOptions.length; i++) {
      Months1.push(selectedOptions[i].value);
    }
    await dispatch(historyPaymentReducer(Months1)).then((response) => {
      console.log(response);
      if (response.payload.filename) {
        window.open(
          config.apiUrl + "/Konnect/getPaymentfile/" + response.payload.filename
        );
      }
    });
  };

  const GetMeetingsAction = async () => {
    var Months1 = [];
    for (var i = 0; i < selectedOptions.length; i++) {
      Months1.push(selectedOptions[i].value);
    }

    const Payload = {
      monthss: Months1,
      page: page,
    };
    setLoader(false);

    await dispatch(historymeetReducer(Payload));
  };
  useEffect(() => {
    GetMeetingsAction();
    console.log(page, "jajajajakakakalalalalamamamama");
  }, [selectedOptions, page]);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <>
        <Card mb="20px">
          <MySelect
            mb="10px"
            className="InputGroupy"
            placeholder="choose month"
            options={Months}
            value={selectedOptions}
            onChange={setSelectedOptions}
            isMulti
          ></MySelect>
          <Button
            mt="10px"
            variant="action"
            onClick={GetHistoricMeetingsAction}
          >
            <Linkk> PDF</Linkk>
          </Button>
        </Card>

        {meetings && meetings.length > 0 && (
          <Card direction="column" w="100%" px="0px" overflowX={"auto"}>
            <Flex direction="column" w="100%">
              <Flex
                align={{ sm: "flex-start", lg: "center" }}
                justify="space-between"
                w="100%"
                px="22px"
                pb="20px"
                mb="10px"
                boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
              >
                <Table color="gray.500">
                  <Thead>
                    <Tr>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Title
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Project Name
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Date Payment
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Price
                        </Flex>
                      </Th>
                      <Th pe="10px" borderColor="transparent">
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Review
                        </Flex>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {meetings.map((item, index) => {
                      return (
                        <Tr key={item.id}>
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Flex align="center">
                              <Avatar
                                src={
                                  config.apiUrl +
                                  "/project/file/" +
                                  item.idmeet.project.images
                                }
                                w="40px"
                                h="40px"
                                me="8px"
                              />
                              <Text fontSize="sm" fontWeight="600">
                                <Linkk>{item.idmeet.title}</Linkk>
                              </Text>
                            </Flex>
                          </Td>
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Text fontSize="sm" fontWeight="600">
                              {item.idmeet.project.title}
                            </Text>
                          </Td>
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Text fontSize="sm" fontWeight="600">
                              {moment(item.createdAt).format(
                                "llll"
                              )}
                            </Text>
                          </Td>

                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Text fontSize="sm" fontWeight="600">
                              {item.amount}
                            </Text>
                          </Td>
                          <Td
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            <Button
                              bgColor={"#7551FF"}
                              color="white"
                              my="auto"
                            >
                                        <Linkk to={"/admin/add-Review-Expert/" + item.idmeet._id}>Review</Linkk>

                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Flex>
              {total_Pages > 1 && (
                <Center pt="5">
                  <Pagination
                    page={page}
                    totalPages={total_Pages}
                    setPage={setPage}
                    setLoader={setLoader}
                  />
                </Center>
              )}
            </Flex>
          </Card>
        )}
      </>
      {meetings && meetings.length === 0 && (
        <Card mb="20px">
          <Text textAlign={"center"} fontSize="large">
            No Payments
          </Text>
        </Card>
      )}
    </Box>
  );
};

export default Index;
