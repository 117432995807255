import React from "react";
import {
  useColorModeValue,
  Flex,
  Box,
  Image,
  Text,
  Button,
  useDisclosure,
  Badge,
  ButtonGroup,
} from "@chakra-ui/react";

import Card from "components/card/Card.js";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { TwitterShareButton, TwitterIcon } from "react-share";
import { Link as Linkk } from "react-router-dom";

import config from "config/axios/config";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import placeholder from "assets/img/placeholder.jpg";
import { deleteNews } from "reducers/news";
import { useDispatch } from "react-redux";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function NewListItem(props) {
  const {
    article: { image, title, tags, _id, description },
    getNews,
    userType,
    onRelaod,
  } = props;
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const dispatch = useDispatch();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const handleDelete = async () => {
    await dispatch(deleteNews(_id)).unwrap();
    onRelaod();
    getNews();
    onClose();
  };

  return (
    <Card p="20px">
      <Flex direction={{ base: "column" }} justify="center" h="100%">
        <Box mb={{ base: "20px", "3xl": "20px" }} position="relative">
          <Image
            src={config.apiUrl + "/news/file/" + image}
            w={{ base: "100%", "2xl": "100%" }}
            h={{ base: "100%", "2xl": "100%" }}
            borderRadius="20px"
            fallbackSrc={placeholder}
          />
          <Button
            position="absolute"
            bg="white"
            _hover={{ bg: "whiteAlpha.900" }}
            _active={{ bg: "white" }}
            _focus={{ bg: "white" }}
            p="0px !important"
            top="14px"
            right="10px"
            borderRadius="50%"
            minW="30px"
            h="30px"
          >
            <FacebookShareButton
              url={"https://di.darblockchain.africa:3003/#/admin/news"}
            >
              <FacebookIcon size={25} round />
            </FacebookShareButton>
          </Button>
          <Button
            position="absolute"
            bg="white"
            _hover={{ bg: "whiteAlpha.900" }}
            _active={{ bg: "white" }}
            _focus={{ bg: "white" }}
            p="0px !important"
            top="14px"
            right="42px"
            borderRadius="50%"
            minW="30px"
            h="30px"
          >
            <TwitterShareButton
              url={"https://di.darblockchain.africa:3003/#/admin/news"}
            >
              <TwitterIcon size={25} round />
            </TwitterShareButton>
          </Button>
          <Button
            position="absolute"
            bg="white"
            _hover={{ bg: "whiteAlpha.900" }}
            _active={{ bg: "white" }}
            _focus={{ bg: "white" }}
            p="0px !important"
            top="14px"
            right="74px"
            borderRadius="50%"
            minW="30px"
            h="30px"
          >
            <LinkedinShareButton
              url={"https://di.darblockchain.africa:3003/#/admin/news"}
            >
              <LinkedinIcon size={25} round />
            </LinkedinShareButton>
          </Button>
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb="auto"
          >
            <Flex direction="column">
              <Flex justify="space-between" alignItems={"center"}>
                <Text
                  color={textColor}
                  mr="4px"
                  fontSize={{
                    base: "xl",
                    md: "lg",
                    lg: "lg",
                    xl: "lg",
                    "2xl": "md",
                    "3xl": "lg",
                  }}
                  mb="5px"
                  fontWeight="bold"
                  me="14px"
                >
                  {title}
                </Text>{" "}
              </Flex>
              {tags && tags.length > 0 && (
                <div>
                  {tags.map((tag, i) => (
                    <Badge colorScheme="green" mb="5px" mr="2px">
                      <Box key={tag + i} float="left">
                        <Text
                          color={textColorSecondary}
                          width="100%"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          #{tag}
                        </Text>
                      </Box>
                    </Badge>
                  ))}{" "}
                </div>
              )}
              <Text
                className="description"
                color={textColor}
                mb="5px"
                me="14px"
              >
                {description}
              </Text>
            </Flex>

            <Text fontWeight="600" fontSize="sm"></Text>
          </Flex>
          <Flex
            align="start"
            alignItems={"baseline"}
            justify="space-between"
            mt="25px"
          >
            <Text
              color={textColor}
              mr="4px"
              fontSize={{
                base: "xl",
                md: "lg",
                lg: "lg",
                xl: "lg",
                "2xl": "md",
                "3xl": "lg",
              }}
              mb="5px"
              fontWeight="bold"
              me="14px"
            >
              {""}
            </Text>
            <Flex direction="column">
              <ButtonGroup
                spacing="2"
                display={"flex"}
                justifyContent="flex-end"
              >
                <BasicButton
                  title={"View New"}
                  onClick={() => history.push("/admin/new/" + _id)}
                ></BasicButton>
                {(userType === "Admin" || userType === "SuperAdmin") && (
                  <BasicButton
                    onClick={onOpen}
                    title={"Delete"}
                    colorScheme={"red"}
                    bgColor={"red"}
                  ></BasicButton>
                )}
                {/* <Button colorScheme="red" onClick={onOpen}>
              Delete
            </Button> */}
              </ButtonGroup>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Customer
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? wanna delete this news "{title}"
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
}
