import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Badge,
  Grid,
  Container,
  Heading,
} from "@chakra-ui/react";
import HeadingTitle from "../HeadingTitle";

import hackathon from "assets/img/hackathon.png";
import dao from "assets/img/dao.png";
import academy from "assets/img/Academy.png";
import Grants from "assets/img/grant.png";
import assisting from "assets/img/Assisting.png";

const Benefits = () => {
  return (
    <>
      <Box bg="white" pb="8">
        <HeadingTitle
          badgeTitle={"WHY"}
          title={"Why EcoSystem Dao ?"}
          subTitle={"The Benefits of using EcoSystem Dao"}
          bgColor={"white"}
        />

        <Flex
          templateColumns={{
            base: "1fr",
            md: "repeat(3, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          justify="center"
          flexWrap="wrap"
          mx="auto"
        >
          <Box
            p="5"
            maxW="320px"
            borderWidth="1px"
            m="4" // Uniform margin around each card
            bg="rgba(238, 242, 255, 0.8)"
            display="flex"
            flexDirection="column"
            alignItems="center"
            transition="transform 0.2s ease-in-out" // Smooth transition for scale
            _hover={{
              transform: "scale(1.05)", // Scales up the box by 5% on hover
            }}
          >
            <Image
              borderRadius="md"
              src={hackathon}
              width="100px" // Sets the width of the image
              height="auto" // Maintains the aspect ratio of the image
              m="0 auto" // Margin auto for horizontal centering
              mb="10px"
            />
            <Badge colorScheme="blue" mt={2}>
              Hackathons
            </Badge>
            <Text mt={2} textAlign="center">
              Join our hackathons to innovate, collaborate, and solve real-world
              challenges in a high-energy environment. Bring your skills to life
              in just a few days of intense teamwork!
            </Text>
            <Box mt={2} color="orange.400" />
          </Box>
          <Box
            p="5"
            maxW="320px"
            borderWidth="1px"
            m="4" // Uniform margin around each card
            bg="rgba(238, 242, 255, 0.8)"
            display="flex"
            flexDirection="column"
            alignItems="center"
            transition="transform 0.2s ease-in-out" // Smooth transition for scale
            _hover={{
              transform: "scale(1.05)", // Scales up the box by 5% on hover
            }}
          >
            <Image
              borderRadius="md"
              src={dao}
              width="100px" // Sets the width of the image
              height="auto" // Maintains the aspect ratio of the image
              m="0 auto" // Margin auto for horizontal centering
              mb="10px"
            />
            <Flex align="baseline" mt={2} justify={"center"}>
              <Badge colorScheme="blue">dao</Badge>
            </Flex>
            {/*  */}
            <Text mt={2}>
              decentralized, autonomous organizations reshaping collaboration
              and decision-making. Join the future of governance and innovation.
            </Text>
          </Box>
          <Box
            p="5"
            maxW="320px"
            borderWidth="1px"
            m="4" // Uniform margin around each card
            bg="rgba(238, 242, 255, 0.8)"
            display="flex"
            flexDirection="column"
            alignItems="center"
            transition="transform 0.2s ease-in-out" // Smooth transition for scale
            _hover={{
              transform: "scale(1.05)", // Scales up the box by 5% on hover
            }}
          >
            <Image
              borderRadius="md"
              src={academy}
              width="100px" // Sets the width of the image
              height="auto" // Maintains the aspect ratio of the image
              m="0 auto" // Margin auto for horizontal centering
              mb="10px"
            />
            <Flex align="baseline" mt={2} justify={"center"}>
              <Badge colorScheme="blue">academy</Badge>
            </Flex>

            <Text mt={2}>
              Welcome to our Academy: a hub of learning and growth. Unlock your
              potential and expand your horizons with our diverse range of
              educational resources and opportunities.
            </Text>
          </Box>
          <Box
            p="5"
            maxW="320px"
            borderWidth="1px"
            m="4" // Uniform margin around each card
            bg="rgba(238, 242, 255, 0.8)"
            display="flex"
            flexDirection="column"
            alignItems="center"
            transition="transform 0.2s ease-in-out" // Smooth transition for scale
            _hover={{
              transform: "scale(1.05)", // Scales up the box by 5% on hover
            }}
          >
            <Image
              borderRadius="md"
              src={Grants}
              width="100px" // Sets the width of the image
              height="auto" // Maintains the aspect ratio of the image
              m="0 auto" // Margin auto for horizontal centering
              mb="10px"
            />
            <Flex align="baseline" mt={2} justify={"center"}>
              <Badge colorScheme="blue">grants</Badge>
            </Flex>

            <Text mt={2}>
              Discover our grants: fuel for your impactful projects. Empower
              your ideas and drive positive change.
            </Text>
          </Box>
          <Box
            p="5"
            maxW="320px"
            borderWidth="1px"
            m="4" // Uniform margin around each card
            bg="rgba(238, 242, 255, 0.8)"
            display="flex"
            flexDirection="column"
            alignItems="center"
            transition="transform 0.2s ease-in-out" // Smooth transition for scale
            _hover={{
              transform: "scale(1.05)", // Scales up the box by 5% on hover
            }}
          >
            <Image
              borderRadius="md"
              src={assisting}
              width="100px" // Sets the width of the image
              height="auto" // Maintains the aspect ratio of the image
              m="0 auto" // Margin auto for horizontal centering
              mb="10px"
            />
            <Flex align="baseline" mt={2} justify={"center"}>
              <Badge colorScheme="blue">assisting program</Badge>
            </Flex>

            <Text mt={2}>
              Explore our assisting program for expert guidance and support on
              your journey to success.
            </Text>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Benefits;
