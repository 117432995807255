import Api from "config/axios/api";
import config from "../config/axios/config";
export const signUpService = async (userData,params) => {
  console.log(typeof(params),'typeofffff')
  const url = config.apiUrl + (typeof(params) !== "undefined" ? "/api/signup?token="+params : "/api/signup/") ;
  
  try {
    return await Api.post(url, userData);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default { signUpService };
