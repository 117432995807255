import Api from "config/axios/api";
import config from "../config/axios/config";

export const getAllIndividualTrainingsService = async (filterData) => {
  const url = config.apiUrl + "/cm/getallmeet";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const addIndividualTrainingService = async (meetData) => {
  const url = config.apiUrl + "/admin/createMeetbettweenExpertAndPh";
  try {
    return await Api.post(url, meetData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default {
  getAllIndividualTrainingsService,
  addIndividualTrainingService,
};
