import React, { useState, useEffect } from "react";
import {
  Button,
  FormErrorMessage,
  InputGroup,
  useColorModeValue,
  FormLabel,
  Icon,
  InputRightElement,
  Text,
  FormControl,
} from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { Input, Stack } from "@chakra-ui/react";
import "./ProfileSettings.css";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { updatePassword } from "reducers/userInformation";
import { useDispatch, useSelector } from "react-redux";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const showOldC = () => setShowOld(!showold);
  const showNewC = () => setShowNew(!shownew);
  const showConfC = () => setShowConf(!showconf);
  const [cancelBtnPS, setCancelBtnPS] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const errnotif = () =>
    toast.error("Something went wrong", {
      position: "top-right",
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
    });
  const [shownew, setShowNew] = React.useState(false);
  const [showold, setShowOld] = React.useState(false);
  const [showconf, setShowConf] = React.useState(false);
  const [erro, setErr] = useState(false);
  const status = useSelector((state) => state.UserInformation.updatePwd.status);

  const handleSubmit = async (values) => {
    const dataPwd = {
      password: values.password,
      oldPassword: values.oldPassword,
    };
    await dispatch(updatePassword(dataPwd)).unwrap();
  };

  useEffect(() => {
    if (status === "success") {
      toast("Your password has been successfully updated!");
    }
    if (status === "failed") {
      errnotif();
    }
  }, [status]);

  return (
    <Stack
      padding="8"
      display={"flex"}
      flexDirection={"column"}
      borderRadius={"30px"}
      backgroundColor={"white"}
    >
      <h3 className="SettingTitle">Change password</h3>
      <Text marginBottom={"24px!important"} color="gray.500" isTruncated>
        Here you can set your new password
      </Text>

      <Formik
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required("This field is required")
            .min(
              8,
              "Your password must be at least 8 characters and should include a combination of numbers, letters and special characters (!$@%)"
            )
            .matches(/[0-9]/, "Password requires a number")
            .matches(/[a-z]/, "Password requires a lowercase letter")
            .matches(/[A-Z]/, "Password requires an uppercase letter")
            .matches(/[^\w]/, "Password requires a symbol"),

          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Password doesn’t match"
          ),

          oldPassword: Yup.string().required("This field is required"),
        })}
        initialValues={{ password: "", oldPassword: "" }}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        }) => (
          <Form
            className="passwordform"
            onChange={() => {
              setCancelBtnPS(true);
            }}
          >
            <Field name="oldPassword">
              {({ field }) => (
                <FormControl
                  isInvalid={
                    (errors.oldPassword && touched.oldPassword) ||
                    erro === "Incorrect password"
                  }
                >
                  <FormLabel
                    htmlFor="oldPassword"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Old Password<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      borderRadius="16px"
                      id="oldPassword"
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb={erro === "Incorrect password" ? "0px" : "24px"}
                      size="lg"
                      type={showold ? "text" : "password"}
                      {...field}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={showold ? MdOutlineRemoveRedEye : RiEyeCloseLine}
                        onClick={showOldC}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {erro === "Incorrect password" && (
                    <FormErrorMessage> {erro}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>

            <Field name="password">
              {({ field }) => (
                <FormControl isInvalid={errors.password && touched.password}>
                  <FormLabel
                    htmlFor="password"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    New Password<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      borderRadius="16px"
                      id="password"
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb={errors.password && touched.password ? "0px" : "24px"}
                      size="lg"
                      type={shownew ? "text" : "password"}
                      {...field}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={shownew ? MdOutlineRemoveRedEye : RiEyeCloseLine}
                        onClick={showNewC}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="passwordConfirmation">
              {({ field }) => (
                <FormControl
                  isInvalid={
                    errors.passwordConfirmation && touched.passwordConfirmation
                  }
                >
                  <FormLabel
                    htmlFor="passwordConfirmation"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Password Confirmation<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      borderRadius="16px"
                      id="passwordConfirmation"
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb={
                        errors.passwordConfirmation &&
                        touched.passwordConfirmation
                          ? "0px"
                          : "24px"
                      }
                      size="lg"
                      type={showconf ? "text" : "password"}
                      {...field}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={showconf ? MdOutlineRemoveRedEye : RiEyeCloseLine}
                        onClick={showConfC}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.passwordConfirmation}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex justifyContent="flex-end" alignItems={"end"}>
              {cancelBtnPS && (
                <Button
                  onClick={() => {
                    setCancelBtnPS(false);
                    resetForm();
                  }}
                  size="md"
                  marginTop={"24px!important"}
                  color="#422AFB"
                  border="2px solid #422AFB"
                  mr="12px"
                  marginLeft={"auto!important"}
                  width="180px"
                >
                  Cancel changes
                </Button>
              )}
              <Button
                type="submit"
                variant="brand"
                size="md"
                marginTop={"24px!important"}
                width="180px"
              >
                Save changes
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default ChangePassword;
