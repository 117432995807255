import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { historymeet } from "services/KonnectService";
import { balanceService } from "services/KonnectService";
import { historyPayment } from "services/KonnectService";
import { CheckoutService } from "services/KonnectService";
import {KonnectService} from "services/KonnectService";

export const KonnectReducer = createAsyncThunk(
  "user/KonnectReducer",
  async (prix, thunkAPI) => {
    try {
        console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaa',prix)
      const { status, data } = await KonnectService(prix);

      if (status === 200) {
      console.log(data,'data')
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
        console.log('data2')

      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const checkoutReducer = createAsyncThunk(
  "user/checkoutReducer",
  async (payload, thunkAPI) => {
    try {
      const { status, data } = await CheckoutService(payload);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const historyPaymentReducer = createAsyncThunk(
  "user/checkoutReducer",
  async (payload, thunkAPI) => {
    try {
      const { status, data } = await historyPayment(payload);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const historymeetReducer = createAsyncThunk(
  "user/historymeet",
  async (payload, thunkAPI) => {
    try {
      const { status, data } = await historymeet(payload);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const balanceReducer = createAsyncThunk(
  "user/balanceReducer",
  async (payload, thunkAPI) => {
    try {
      const { status, data } = await balanceService();
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  PaymentKonnect: {
    status: "idle",
    data: {},
    error: {},
  },
  CheckoutKonnect: {
    status: "idle",
    data: {},
    error: {},
  },
  historyPayment: {
    status: "idle",
    data: {},
    error: {},
  },
  historymeet: {
    status: "idle",
    data: {},
    error: {},
  },
  balance: {
    status: "idle",
    data: {},
    error: {},
  },
};
const KonnectSlice = createSlice({
  name: "konnect-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [balanceReducer.pending.type]: (state) => {
      state.balance = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [balanceReducer.fulfilled.type]: (state, action) => {
      state.balance = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [balanceReducer.rejected.type]: (state, action) => {
      state.balance = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [historymeetReducer.pending.type]: (state) => {
      state.historymeet = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [historymeetReducer.fulfilled.type]: (state, action) => {
      state.historymeet = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [historymeetReducer.rejected.type]: (state, action) => {
      state.historymeet = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [KonnectReducer.pending.type]: (state) => {
      state.PaymentKonnect = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [KonnectReducer.fulfilled.type]: (state, action) => {
      state.PaymentKonnect = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [KonnectReducer.rejected.type]: (state, action) => {
      state.PaymentKonnect = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [historyPaymentReducer.pending.type]: (state) => {
      state.historyPayment = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [historyPaymentReducer.fulfilled.type]: (state, action) => {
      state.historyPayment = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [historyPaymentReducer.rejected.type]: (state, action) => {
      state.historyPayment = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [checkoutReducer.pending.type]: (state) => {
      state.CheckoutKonnect = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [checkoutReducer.fulfilled.type]: (state, action) => {
      state.CheckoutKonnect = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [checkoutReducer.rejected.type]: (state, action) => {
      state.CheckoutKonnect = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default KonnectSlice.reducer;
