import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getvotesessionCountByMonth } from "reducers/dashboard";
import StatsChart from "./StatsChart";

export default function VoteSessionsStats() {
  const dispatch = useDispatch();
  const [xValues, setxValues] = useState([]);
  const [yValues, setyValues] = useState([]);
  const [monthYearFilter, setMonthYearFilter] = useState("month");

  const data = useSelector((state) =>
    state.Dashboard.votesessionCountByMonth.data
      ? state.Dashboard.votesessionCountByMonth.data.result
      : []
  );
  const status = useSelector((state) =>
    state.Dashboard.votesessionCountByMonth.status
      ? state.Dashboard.votesessionCountByMonth.status
      : []
  );

  const getVotesSession = async () => {
    const payload = {
      monthyear: monthYearFilter,
    };
    await dispatch(getvotesessionCountByMonth(payload)).unwrap();
  };

  useEffect(() => {
    if (status == "success") {
      const month = data.map((item) => (item.month ? item.month : item.year));
      setxValues(month);
      const Count = data.map((item) => item.count);
      setyValues(Count);
    }
  }, [status, data]);

  const config = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: xValues,
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      fill: {
        colors: ["#008000a3"],
      },
    },
    series: [
      {
        name: "Vote Session",
        data: yValues,
      },
    ],
  };

  useEffect(() => {
    getVotesSession();
  }, [monthYearFilter]);
  
  return (
    <StatsChart
      title={"Vote Session"}
      config={config}
      data={data}
      noDataText={"There is no Vote Session"}
      setMonthYearFilter={setMonthYearFilter}
      type={"bar"}
    />
  );
}
