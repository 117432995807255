import React, { useState, useEffect } from "react";
import { Button, Text, Textarea } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { Input, Stack } from "@chakra-ui/react";
import "./ProfileSettings.css";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "reducers/userInformation";
import { toast } from "react-toastify";

const AccountSettings = () => {
  const dispatch = useDispatch();
  const profileDefault = JSON.parse(localStorage.getItem("CurrentUserData"));
  const [userSettings, setUserSettings] = useState({
    bio: profileDefault.bio ? profileDefault.bio : "",
    userName: profileDefault.userName,
    lastName: profileDefault.lastName,
    firstName: profileDefault.firstName,
    email: profileDefault.email,
    job: profileDefault.job ? profileDefault.job : "",
    image: "",
  });
  const [prevSettings, setPrevSettings] = useState(userSettings);
  const [userNameError, setUserNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [bioError, setBioError] = useState("");

  const cancelProfileSettings = () => {
    setUserSettings(prevSettings);
  };
  const updatedUser = useSelector(
    (state) => state.UserInformation.updatedUser.data
  );
  const validateForm = () => {
    let invalidForm = false;
    if (!userSettings.userName) {
      invalidForm = true;
      setUserNameError("This field is required!");
    } else {
      setUserNameError("");
    }
    if (!userSettings.lastName) {
      invalidForm = true;
      setLastNameError("This field is required!");
    } else {
      setLastNameError("");
    }
    if (!userSettings.firstName) {
      invalidForm = true;
      setFirstNameError("This field is required!");
    } else {
      setFirstNameError("");
    }
    if (userSettings.bio && userSettings.bio.length < 10) {
      invalidForm = true;
      setBioError("Min 10 characters");
    } else {
      setBioError("");
    }
    return invalidForm;
  };
  const updateUserAction = async () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    await dispatch(updateUser(userSettings)).unwrap();
  };
  useEffect(() => {
    if (updatedUser && updatedUser.message) {
      localStorage.setItem(
        "CurrentUserData",
        JSON.stringify(updatedUser.message)
      );
      toast("Your account settings has been successfully updated!");
      setPrevSettings(userSettings);
    }
  }, [updatedUser]);

  return (
    <Stack
      padding="8"
      display={"flex"}
      flexDirection={"column"}
      borderRadius={"30px"}
      backgroundColor={"white"}
      mt="20px"
    >
      <h3 className="SettingTitle">Account Settings</h3>
      <Text marginBottom={"24px!important"} mt="0" color="gray.500" isTruncated>
        Here you can change user account information
      </Text>
      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          Username
        </Text>
        <Input
          isInvalid={userNameError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Username"
          borderRadius="16px"
          value={userSettings.userName}
          onChange={(e) => {
            setUserSettings({
              ...userSettings,
              userName: e.target.value,
            });
          }}
        />
        {userNameError && (
          <Text mt="3" color="red">
            {userNameError}
          </Text>
        )}
      </label>
      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          Email address
        </Text>
        <Input
          marginTop={"5px!important"}
          w={"100%"}
          placeholder="email@mail.di"
          borderRadius="16px"
          value={userSettings.email}
          disabled={true}
        />
      </label>
      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          First name
        </Text>
        <Input
          isInvalid={firstNameError}
          marginTop={"5px!important"}
          placeholder="first name"
          borderRadius="16px"
          value={userSettings.firstName}
          onChange={(e) => {
            setUserSettings({
              ...userSettings,
              firstName: e.target.value,
            });
          }}
        />
        {firstNameError && (
          <Text mt="3" color="red">
            {firstNameError}
          </Text>
        )}
      </label>

      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          Last name
        </Text>
        <Input
          isInvalid={lastNameError}
          marginTop={"5px!important"}
          placeholder="Last name"
          borderRadius="16px"
          w={"100%"}
          value={userSettings.lastName}
          onChange={(e) => {
            setUserSettings({
              ...userSettings,
              lastName: e.target.value,
            });
          }}
        />
        {lastNameError && (
          <Text mt="3" color="red">
            {lastNameError}
          </Text>
        )}
      </label>
      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          Job
        </Text>
        <Input
          marginTop={"5px!important"}
          placeholder="Web developer"
          borderRadius="16px"
          value={userSettings.job}
          onChange={(e) => {
            setUserSettings({ ...userSettings, job: e.target.value });
          }}
        />
      </label>
      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          About me
        </Text>
        <Textarea
          isInvalid={bioError}
          placeholder="About me"
          borderRadius="16px"
          value={userSettings.bio}
          onChange={(e) => {
            setUserSettings({ ...userSettings, bio: e.target.value });
          }}
        />

        {bioError && (
          <Text mt="3" color="red">
            {bioError}
          </Text>
        )}
      </label>
      <Flex justifyContent="flex-end" alignItems={"end"}>
        {userSettings !== prevSettings && (
          <Button
            onClick={() => {
              cancelProfileSettings();
            }}
            size="md"
            marginTop={"24px!important"}
            color="#422AFB"
            border="2px solid #422AFB"
            mr="12px"
            marginLeft={"auto!important"}
            width="180px"
          >
            Cancel changes
          </Button>
        )}
        <Button
          variant="brand"
          onClick={() => {
            updateUserAction();
          }}
          marginTop={"24px!important"}
          size="md"
          width="180px"
        >
          Save changes
        </Button>
      </Flex>
    </Stack>
  );
};

export default AccountSettings;
