import React from "react";
import {
  FormLabel,
  FormControl,
  Text,
  Input,
  InputLeftElement,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

const InputField = (props) => {
  const {
    label,
    required = true,
    placeholder,
    error,
    value,
    setValue,
    onBlur,
    type = "text",
    leftElement = "",
    rightElement = "",
    variant="outline"
  } = props;
  const handleInputChange = (event) => {
    let value = event.target.value;
    // Allow only numeric values
    if (type === "number") {
      value = event.target.value.replace(/\D/g, "");
    }
    setValue(value);
  };
  return (
    <FormControl mr="2">
      {label && (
        <FormLabel>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      <InputGroup>
        {leftElement && (
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="0.8em"
            children={leftElement}
          />
        )}
        <Input
          isInvalid={error}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={handleInputChange}
          variant={variant}
          onBlur={onBlur}
        />
        {rightElement && (
          <InputRightElement
            pointerEvents="none"
            color="gray.300"
            fontSize="0.8em"
            children={rightElement}
          />
        )}
      </InputGroup>
      {error && <Text color="red">{error}</Text>}
    </FormControl>
  );
};

export default InputField;
