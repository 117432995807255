import React from "react";
import { Spinner, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "config/axios/config";
const VerifyCallback = () => {
  const { token } = useParams();
  localStorage.setItem("token", token);
  const userToken = localStorage.getItem("token");

  axios
    .post(config.apiUrl + "/api/verify/" + token)
    .then((resp) => {
      window.location.href = "/#/auth/sign-in";
    })
    .catch((err) => {
      console.log(err);
    });

  return (
    <Flex w={"100"} h="100" justifyContent="center" alignItems="center">
      <Spinner mx="auto" my="auto" size="xl" />
    </Flex>
  );
};

export default VerifyCallback;
