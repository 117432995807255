import React from "react";
import {
  useColorModeValue,
  Flex,
  Box,
  Text,
  Avatar,
  Spacer,
} from "@chakra-ui/react";
import config from "config/axios/config";
import Card from "components/card/Card.js";
import AdminsStatus from "./AdminsStatus";


export default function AdminsListItem({ user }) {
  const { Image, firstName, lastName, email, userType, projects } =
    user;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  

  return (
    <Card py="15px">
      <Flex>
        <Avatar alt={`${firstName} Avatar`} mr="4" src={config.apiUrl + "/api/getfile/" + Image} />

        <Box w="100%" h="10">
          {" "}
          <Text fontSize="xl" color={textColor}>
            {firstName + " " + lastName}
          </Text>{" "}
          <Text fontSize="sm" color={textColorSecondary}>
            {email}
          </Text>{" "}
          <Spacer />
         
        </Box>
       
        <Spacer />
        <AdminsStatus user={user} />

      </Flex>
    </Card>
  );
}
