import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FaCamera } from "react-icons/fa";
import BasicButton from "components/button/BasicButton";
import FileUpload from "components/upload/FileUpload";

const VideoPlayer = ({
  width = "100%",
  height = "300px",
  videoSrc,
  onEdit = () => {},
  video,
  setVideo,
  videoError,
  canEdit = false,
  noVideoMessage,
}) => {
  const [isValidVideoSrc, setIsValidVideoSrc] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const checkVideoExistence = async () => {
      try {
        const response = await fetch(videoSrc);
        if (response.ok) {
          setIsValidVideoSrc(true);
        }
      } catch (error) {
        console.error("Error checking video existence:", error);
        setIsValidVideoSrc(false);
      }
    };

    if (videoSrc) {
      checkVideoExistence();
    }
  }, [videoSrc]);

  return (
    <Box style={{ position: "relative", height: height }}>
      {canEdit && (
        <Tooltip label="Change Video" hasArrow placement="top-end">
          <IconButton
            icon={<FaCamera />}
            size="sm"
            colorScheme="blue"
            position="absolute"
            top="10px"
            right="10px"
            onClick={onOpen}
          />
        </Tooltip>
      )}

      {isValidVideoSrc ? (
        <Box
          as="iframe"
          title={"video"}
          src={videoSrc}
          width={width}
          height={height}
          allowFullScreen
          borderRadius="lg"
        />
      ) : (
        <Box
          width={width}
          height={height}
          borderRadius="md"
          border="1px solid rgb(227, 227, 227)"
          textAlign="center"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {noVideoMessage && (
            <Text fontSize="2xl" fontWeight="bold" color="gray.500">
              {noVideoMessage}
            </Text>
          )}
        </Box>
      )}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FileUpload
              multiple={false}
              files={video}
              setFiles={setVideo}
              error={videoError}
              acceptedFileTypes="video/*"
            />
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <BasicButton
                title={"Cancel"}
                onClick={onClose}
                colorScheme="gray"
                variant="solid"
                borderRadius="5px"
              />
              <BasicButton
                title={"Confirm"}
                colorScheme="blue"
                variant="solid"
                onClick={onEdit}
                borderRadius="5px"
              />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default VideoPlayer;
