import React, { useState } from "react";
import {
  Box,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Input,
  Alert,
  AlertIcon,
  Flex,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";

const VotingPercentageForm = (props) => {
  const { activeStep, setActiveStep, programPhaseData, setProgramPhaseData } =
    props;
  const [expertsPercentage, setExpertsPercentage] = useState(
    programPhaseData.votingPercentage.experts
  );
  const [communityPercentage, setCommunityPercentage] = useState(
    programPhaseData.votingPercentage.community
  );
  const [partnersPercentage, setPartnersPercentage] = useState(
    programPhaseData.votingPercentage.partners
  );
  const [error, setError] = useState("");

  const submitForm = () => {
    const totalPercentage =
      expertsPercentage + communityPercentage + partnersPercentage;

    if (totalPercentage !== 100) {
      setError("Total percentage must be 100%. Please adjust the values.");
      return;
    }
    const data = {
      ...programPhaseData,
      votingPercentage: {
        experts: expertsPercentage,
        community: communityPercentage,
        partners: partnersPercentage,
      },
    };
    setProgramPhaseData(data);
    setActiveStep(activeStep + 1);
  };
  const back = () => {
    setActiveStep(activeStep - 1);
    setError("");
  };
  return (
    <Box p={6} borderWidth="1px" borderRadius="lg" width="100%" boxShadow="md">
      <Heading as="h2" size="lg" mb={4} textAlign="center">
        Voting Percentage
      </Heading>
      {error && (
        <Alert status="error" mb={4} borderRadius="md">
          <AlertIcon />
          {error}
        </Alert>
      )}
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Experts Percentage:</FormLabel>
          <Slider
            value={expertsPercentage}
            onChange={(value) => setExpertsPercentage(value)}
            min={0}
            max={100}
            step={1}
          >
            <SliderTrack>
              <SliderFilledTrack bg="blue.300" />
            </SliderTrack>
            <SliderThumb boxSize={6} />
          </Slider>
          <Input
            type="number"
            min="0"
            max="100"
            value={expertsPercentage}
            onChange={(e) => setExpertsPercentage(Number(e.target.value))}
            placeholder="Enter percentage"
            mt={2}
            required
          />
        </FormControl>

        <FormControl>
          <FormLabel>Community Percentage:</FormLabel>
          <Slider
            value={communityPercentage}
            onChange={(value) => setCommunityPercentage(value)}
            min={0}
            max={100}
            step={1}
          >
            <SliderTrack>
              <SliderFilledTrack bg="blue.300" />
            </SliderTrack>
            <SliderThumb boxSize={6} />
          </Slider>
          <Input
            type="number"
            min="0"
            max="100"
            value={communityPercentage}
            onChange={(e) => setCommunityPercentage(Number(e.target.value))}
            placeholder="Enter percentage"
            mt={2}
            required
          />
        </FormControl>

        <FormControl>
          <FormLabel>Partners Percentage:</FormLabel>
          <Slider
            value={partnersPercentage}
            onChange={(value) => setPartnersPercentage(value)}
            min={0}
            max={100}
            step={1}
          >
            <SliderTrack>
              <SliderFilledTrack bg="blue.300" />
            </SliderTrack>
            <SliderThumb boxSize={6} />
          </Slider>
          <Input
            type="number"
            min="0"
            max="100"
            value={partnersPercentage}
            onChange={(e) => setPartnersPercentage(Number(e.target.value))}
            placeholder="Enter percentage"
            mt={2}
            required
          />
        </FormControl>
        <Flex justifyContent={"space-between"} mt="3">
          <BasicButton
            onClick={back}
            title={"Previous"}
            variant="outline"
            size="md"
          />
          <BasicButton
            onClick={submitForm}
            title={"Next"}
            variant="solid"
            size="md"
          />
        </Flex>
      </Stack>
    </Box>
  );
};

export default VotingPercentageForm;
