import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountOrganizationbycategory } from "reducers/organization";
import StatsChart from "./StatsChart";

export default function OrganizationsByCategoryStats() {
  const dispatch = useDispatch();
  const [xValues, setxValues] = useState([]);
  const [yValues, setyValues] = useState([]);

  const data = useSelector((state) =>
    state.Organization.countOrganization.data
      ? state.Organization.countOrganization.data.result
      : []
  );
  const status = useSelector((state) =>
    state.Organization.countOrganization.status
      ? state.Organization.countOrganization.status
      : []
  );

  const getNumberOfORganizationsByCategoryAction = async () => {
    await dispatch(getCountOrganizationbycategory()).unwrap();
  };

  useEffect(() => {
    if (status == "success") {
      const categories = data.map((item) => item.category);
      setxValues(categories);
      const nbOrganization = data.map((item) => item.count);
      setyValues(nbOrganization);
    }
  }, [status, data]);

  const config = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: xValues,
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      fill: {
        colors: ["#008000a3"],
      },
    },
    series: [
      {
        name: "Organization",
        data: yValues,
      },
    ],
  };

  useEffect(() => {
    getNumberOfORganizationsByCategoryAction();
  }, []);

  return (
    <StatsChart
      title={"Organization by category"}
      config={config}
      data={data}
      noDataText={"There is no Organization"}
      showFilter={false}
      setMonthYearFilter={() => {}}
      type={"bar"}
    />
  );
}
