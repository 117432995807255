import React from "react";
import { SimpleGrid, Text, Flex } from "@chakra-ui/react";
import TrackItem from "views/admin/tracks/components/TrackItem";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const HackathonTracks = (props) => {
  const { tracks, canEdit, onReload, hackathon } = props;
  const history = useHistory();

  useEffect(() => {
    console.log(tracks, "tracks")
  },[tracks])

  return (
    <>
      {tracks && tracks.length > 0 && (
        <>
          <Flex justifyContent="flex-end" mb="3">
            {canEdit && !hackathon.general && (
              <BasicButton
                title="Add Track"
                variant="solid"
                size="sm"
                onClick={() =>
                  history.push({
                    pathname: "/admin/add-call-for-track",
                    state: { hackathonId: hackathon._id },
                  })
                }
              />
            )}
          </Flex>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} mt="2px" gap="10px">
            {tracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                canEdit={canEdit}
                onChange={onReload}
              />
            ))}
          </SimpleGrid>
        </>
      )}
      {tracks && tracks.length === 0 && (
        <Flex direction={"column"} alignItems={"center"} p="4">
          <Text textAlign={"center"} fontSize="large" m="2">
            There is no track yet{" "}
          </Text>
          {canEdit && !hackathon.general && (
            <BasicButton
              title="Add Track"
              variant="solid"
              size="md"
              onClick={() =>
                history.push({
                  pathname: "/admin/add-call-for-track",
                  state: { hackathonId: hackathon._id },
                })
              }
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default HackathonTracks;
