import React from "react";
import { Box, SimpleGrid, Center } from "@chakra-ui/react";
import Pagination from "components/pagination/Pagination";
import Loader from "components/loader/Loader";
import TextCard from "components/card/TextCard";
import ProjectCard from "components/project/ProjectCard";

export default function ProjectCardList(props) {
  const { projects, page, setPage, totalPages } = props;
  return (
    <Box p={6}>
      {!projects && <Loader />}
      {projects?.length > 0 && (
        <>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
            {projects?.map((project) => (
              <ProjectCard
                key={project._id}
                project={project}
                onReload={() => {}}
                canLike={false}
                bg={"#422AFB"}
                colorText={"black"}
                modal={true}
              />
            ))}
          </SimpleGrid>
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
                color="black"
                colorScheme="blue"
              />
            </Center>
          )}
        </>
      )}
      {projects?.length === 0 && (
        <Box mb={"30px"} mt={"30px"}>
          <TextCard
            text="There are no projects to show"
            subText={
              "Try selecting a different project category from the options above"
            }
          />
        </Box>
      )}
    </Box>
  );
}
