import Api from "config/axios/api";
import config from "../config/axios/config";

export const addMeetingGeneralInHackathonService = async (dataAdd) => {
  const url = config.apiUrl + "/meet/create";
  try {
    return await Api.post(url, dataAdd, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAllMeetingsService = async (filterData) => {
  const url = config.apiUrl + "/meet/getAll";

  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const addBookMeetingExpertService = async (dataAdd) => {
  const url = config.apiUrl + "/meet/book";
  try {
    return await Api.post(url, dataAdd, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const acceptMeetInvitationService = async (status, token) => {
  const url = config.apiUrl + "/meet/" + status + "/" + token;
  try {
    return await Api.put(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAllEventMeetingsService = async (id) => {
  const url = config.apiUrl + "/meet/calendar/" + id;

  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  addMeetingGeneralInHackathonService,
  addBookMeetingExpertService,
  getAllEventMeetingsService
};
