import React, { useState } from "react";
import {
  Input,
  CloseButton,
  useColorModeValue,
  Flex,
  Text,
  Textarea,
  Divider,
  SimpleGrid,
  FormLabel,
} from "@chakra-ui/react";

import BasicButton from "components/button/BasicButton";
import { useDispatch } from "react-redux";
import Card from "components/card/Card";
import PrizeItem from "./CardItem";
import CardItem from "./CardItem";
import { useEffect } from "react";

const EligibilityconditionsForm = ({
  activeStep,
  setActiveStep,
  addHackathonHandler,
  setCriteria,
  HackathonData,
  criteria,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [criteriaError, setCriteriaError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    return valid;
  };
  const validateCriteria = () => {
    let valid = true;
    if (criteria.length == 0) {
      setCriteriaError("Criteria is required!");
      valid = false;
    } else {
      setCriteriaError("");
    }

    return valid;
  };
  const handleAddCriteria = async () => {
    if (!validateForm()) {
      return;
    }
    setCriteria([
      ...criteria,
      {
        title: title,
        description: description,
      },
    ]);
    setTitle("");
    setDescription("");
  };

  const next = () => {
    if (!validateCriteria()) {
      return;
    }
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (isSubmitted) {
      validateCriteria();
    }
  }, [criteria, isSubmitted]);
  return (
    <Flex flexDirection={"column"}>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Title <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          isInvalid={titleError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Title"
          borderRadius="5px"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {titleError && (
          <Text mt="3" color="red">
            {titleError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Description <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Textarea
          isInvalid={descriptionError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Description"
          borderRadius="5px"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {descriptionError && (
          <Text mt="3" color="red">
            {descriptionError}
          </Text>
        )}
      </Flex>
      {criteriaError && (
        <Text mt="3" color="red">
          {criteriaError}
        </Text>
      )}
      <Flex justifyContent={"end"} mt="5">
        <BasicButton
          onClick={handleAddCriteria}
          title={"Add New Criteria"}
          variant="solid"
        />
      </Flex>
      <CardItem value={criteria} setValue={setCriteria} />

      <Flex justifyContent={"space-between"} mt="3">
        <BasicButton
          onClick={() => setActiveStep(activeStep - 1)}
          title={"Previous"}
          variant="solid"
        />

        <BasicButton onClick={next} title={"Next"} variant="solid" />
      </Flex>
    </Flex>
  );
};

export default EligibilityconditionsForm;
