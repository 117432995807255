import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  FormLabel,
  FormControl,
  Textarea,
  Flex,
  Text,
  Heading,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import { ToastContainer } from "react-toastify";
import { funds } from "utils/constants";
import { users } from "utils/constants";
import { team } from "utils/constants";

const Step4 = (props) => {
  const { grantsdata, setgrantdata, setStep } = props;
  const [allocatedtowards, setAllocatedtowards] = useState("");
  const [fundingrequested, setFundingrequested] = useState("");
  const [launchDate, setLaunchDate] = useState("");
  const [startedProjects, setstartedProjects] = useState("");
  const [previousfunding, setpreviousfunding] = useState("");
  const [developmenttimeline, setdevelopmenttimeline] = useState("");
  const [fromwhere, setfromwhere] = useState("");

  
  const [allocatedtowardserror, setAllocatedtowardserror] = useState("");
  const [fundingrequestederror, setFundingrequestederror] = useState("");
  const [launchDateerror, setLaunchDateerror] = useState("");
  const [startedProjectserror, setstartedProjectserror] = useState("");
  const [previousfundingerror, setpreviousfundingerror] = useState("");

  const validForm = () => {
    let valid = true;
    const date = new Date();
    if (!launchDate) {
      setLaunchDateerror("You should enter a launch date");
      valid = false;
    } else if (new Date(launchDate) < date) {
      setLaunchDateerror("Launch date must be greater than current date");
    } else {
      setLaunchDateerror("");
    }
  
    if (!allocatedtowards) {
      setAllocatedtowardserror("required!");
      valid = false;
    } else {
      setAllocatedtowardserror("");
    }
    if (!fundingrequested) {
      setFundingrequestederror("funding is required!");
      valid = false;
    } else {
      setFundingrequestederror("");
    }
    if (!startedProjects) {
      setstartedProjectserror("Started project is required!");
      valid = false;
    } else {
      setstartedProjectserror("");
    }
    if (!previousfunding) {
      setpreviousfundingerror("Previous funding is required!");
      valid = false;
    } else {
      setpreviousfundingerror("");
    }
    return valid;
  };

  const confirm = async () => {
    if (!validForm()) {
      return;
    }
    const data = {
      id:grantsdata.id,
      project: grantsdata.project,
      fund:grantsdata.fund,
      proposalSentence: grantsdata.proposalSentence,
      proposalSummary:grantsdata.proposalSummary,
      choiceToBuildOnHedera: grantsdata.choiceToBuildOnHedera,
      customerCountQuestion: grantsdata.customerCountQuestion,
      competitors: grantsdata.competitors,
      teamSize: grantsdata.teamSize,
      teamExperience:grantsdata.teamExperience,
      startedStatus: startedProjects,
      developmentTimeline: developmenttimeline,
      estimatedLaunchDate: launchDate,
      fundingRequestedHBAR: fundingrequested,
      allocatedTowards: allocatedtowards,
      codeRepository: "",
      previousFunding: previousfunding,
      fromWhere: fromwhere,
      hearAboutUs: "",
      video: "",
      pdfPresentation: "",
      
    };
    setgrantdata(data);
    setStep(5);
  };

  const back = () => {
    setStep(3);
  };
  return (
    <Card>
      <Box>
        <ToastContainer />
        <Card>
          <Heading size="sm" mb="2">
            Funding Informations
          </Heading>
          <Divider mb="2" />
          <Flex mt="2" direction={"column"}>
            <Text> Have you already started the project?* </Text>
            <Select
              mb="3"
              onChange={(ev) => {
                setstartedProjects(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              width="100%"
            >
              <option value="">-----</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {startedProjectserror && (
              <Text color="red">{startedProjectserror}</Text>
            )}
          </Flex>
          {startedProjects == "No" && (
            <Flex mt="2" direction={"column"}>
              <Text>What is your development timeline?*</Text>
              <Select
                onChange={(ev) => {
                  setdevelopmenttimeline(ev.target.value);
                }}
                name="category"
                id="category"
                borderColor={"#E0E5F2"}
                width="100%"
              >
                <option value="">--select an item--</option>
                <option value="less than 3 months">less than 3 months</option>
                <option value="3-6 months">3-6 months</option>
                <option value="6-9 months">6-9 months</option>
                <option value="9-12 months">9-12 months</option>
                <option value="greater than 12 months">
                  greater than 12 months
                </option>
              </Select>
            </Flex>
          )}
          <Flex mt="2" direction={"column"}>
            <Text>Estimated Launch Date*</Text>

            <Input
              type="date"
              value={launchDate}
              placeholder="end"
               onChange={(e) => setLaunchDate(e.target.value)}
            />
             {launchDateerror && (
              <Text color="red">{launchDateerror}</Text>
            )}
          </Flex>{" "}
          <Flex mt="2" direction={"column"}>
            <Text>Funding requested in HBAR*</Text>
            <Select
              onChange={(ev) => {
                setFundingrequested(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              width="100%"
            >
              <option value="">--select an item--</option>
              <option value="10,000">10,000</option>
              <option value="20,000">20,000</option>
              <option value="30,000">30,000</option>
              <option value="40,000">40,000</option>
              <option value="50,000">50,000</option>
              <option value="60,000">60,000</option>
              <option value="70,000">70,000</option>
              <option value="80,000">80,000</option>
              <option value="90,000">90,000</option>
              <option value="100,000">100,000</option>
              <option value="110,000">110,000</option>
              <option value="120,000">120,000</option>
              <option value="130,000">130,000</option>
              <option value="140,000">140,000</option>
              <option value="150,000">150,000</option>
            </Select>
            {fundingrequestederror && (
              <Text color="red">{fundingrequestederror}</Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
              <Textarea
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="What will funds be allocated towards?*"
                borderRadius="5px"
                value={allocatedtowards}
                onChange={(e) => setAllocatedtowards(e.target.value)}
              />
                {allocatedtowardserror && (
              <Text color="red">{allocatedtowardserror}</Text>
            )}
            </Flex>
            <Flex mt="2" direction={"column"}>
            <Text> Have you received previous funding? * </Text>
            <Select
              mb="3"
              onChange={(ev) => {
                setpreviousfunding(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              width="100%"
            >
              <option value="">-----</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {previousfundingerror && (
              <Text color="red">{previousfundingerror}</Text>
            )}
          </Flex>
          {previousfunding == "Yes" && (
            <Flex mt="2" direction={"column"}>
            <Textarea
              // isInvalid={descriptionError}
              marginTop={"5px!important"}
              w={"100%"}
              marginRight="10px"
              placeholder="Specify how much and from where.*"
              borderRadius="5px"
              value={fromwhere}
              onChange={(e) => setfromwhere(e.target.value)}
            />
          </Flex>
          )}
        </Card>
        <Flex justifyContent="end" mt="4">
            <Button
              colorScheme="blue"
              variant="outline"
              size="md"
              // onClick={back}
              mr="2"
              onClick={back}
            >
              Back
            </Button>
            <Button colorScheme="blue" size="md" onClick={confirm}>
              Next
            </Button>
          </Flex>
      </Box>
    </Card>
  );
};

export default Step4;
