import { configureStore } from "@reduxjs/toolkit";
import expert from "../reducers/expert";
import dashboard from "../reducers/dashboard";
import signIn from "../reducers/signIn";
import UserToken from "../reducers/User";
import signUp from "../reducers/signUp";
import userInformation from "../reducers/userInformation";
import project from "../reducers/project";
import event from "../reducers/event";
import callOfVoting from "../reducers/callOfVoting";
import individualTraining from "../reducers/individualTraining";
import news from "../reducers/news";
import notificationsSettings from "../reducers/notificationsSettings";
import cohort from "../reducers/cohort";
import Konnect from "../reducers/Konnect";
import historicReview from "../reducers/historicReview"
import organization from "../reducers/organization"
import callofStartups from "../reducers/callofStartups";
import grants from "../reducers/grants";
import track from "../reducers/track";
import academy from "../reducers/academy";
import hackathon from "../reducers/hackathon";
import assistingProgram from "../reducers/assistingProgram";
import programPhase from "../reducers/programPhase";
import meeting from "reducers/meeting";

export default configureStore({
  reducer: {
    SignIn: signIn,
    SignUp: signUp,
    UserInformation: userInformation,
    Dashboard: dashboard,
    Expert: expert,
    Project: project,
    Event: event,
    User:UserToken,
    callOfVoting:callOfVoting,
    User: UserToken,
    IndividualTraining: individualTraining,
    News:news,
    NotificationsSettings: notificationsSettings,
    Cohort: cohort,
    Konnect: Konnect,
    HistoricReview: historicReview,
    Organization: organization,
    CallofStartups: callofStartups,
    Grants:grants,
    Track: track,
    Academy : academy,
    Hackathon: hackathon,
    AssistingProgram: assistingProgram,
    ProgramPhase: programPhase,
    Meeting:meeting
  },
});
