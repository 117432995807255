import Card from "components/card/Card.js";
import Pagination from "components/pagination/Pagination";
import { useColorModeValue } from "@chakra-ui/system";
import {
  Center,
  Select,
  Text,
  Box,
  Flex,
  Button,
  Input,
  Textarea,
  Heading,
  Divider,
  Checkbox,
  Image,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { toast, ToastContainer } from "react-toastify";
import { SocketContext } from "contexts/SocketContext";
import { getallProjects } from "reducers/project";
import placeholder from "assets/img/placeholder.jpg";

import config from "config/axios/config";
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";
import { addCohort } from "reducers/cohort";
import { Link as Linkk } from "react-router-dom";
import MySelect from "react-select";
import { MeetingReviewReducer } from "reducers/callOfVoting";
import { useParams } from "react-router-dom";
import SidebarDocs from "components/sidebar/components/SidebarCard";
import UploadPDF from "views/admin/events/components/UploadPDF";
import { addOrganization } from "reducers/organization";
import { sectors } from "utils/constants";
import UploadComponent from "./UploadFile";

const AddOrganization = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  const [title, settitle] = useState();
  const [Description, setDescription] = useState();
  const [type, settype] = useState();
  const [Investmentrange, setInvestmentrange] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [Role, setRole] = useState();
  const [ExpectedReturn, setExpectedReturn] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [RiskTolerance, setRiskTolerance] = useState();
  const [ExitStrategy, setExitStrategy] = useState();
  const [requirements, setrequirements] = useState();
  const [photo, setPhoto] = useState("");
  const [documents, setdocuments] = useState("");

  const [titleerror, settitleerror] = useState();
  const [Descriptionerror, setDescriptionerror] = useState();
  const [typeerror, settypeerror] = useState();
  const [Investmentrangeerror, setInvestmentrangeerror] = useState();
  const [selectedOptionserror, setSelectedOptionserror] = useState([]);
  const [Roleerror, setRoleerror] = useState();
  const [ExpectedReturnerror, setExpectedReturnerror] = useState();
  const [starterror, setStarterror] = useState();
  const [enderror, setEnderror] = useState();
  const [RiskToleranceerror, setRiskToleranceerror] = useState();
  const [ExitStrategyerror, setExitStrategyerror] = useState();
  const [photoerror, setPhotoerror] = useState("");
  const OrganizationStatus = useSelector((state) =>
    state.Organization.addorg ? state.Organization.addorg.status : []
  );
  const Upfiles = (file) => {
    setPhoto(file);
  };
  const Upfiles2 = (file) => {
    setdocuments(file);
  };
  const validateForm = () => {
    let valid = true;
    if (!title) {
      settitleerror("The title is required!");
      valid = false;
    } else {
      settitleerror("");
    }
    if (!Description) {
      setDescriptionerror("The description is required!");
      valid = false;
    } else {
      setDescriptionerror("");
    }
    if (!type) {
      settypeerror("The type is required!");
      valid = false;
    } else {
      settypeerror("");
    }
    if (!Investmentrange) {
      setInvestmentrangeerror("Investment range is required!");
      valid = false;
    } else {
      setInvestmentrangeerror("");
    }
    if (!selectedOptions.length > 0) {
      setSelectedOptionserror("Preferred Industry is required!");
      valid = false;
    } else {
      setSelectedOptionserror("");
    }
    if (!Role) {
      setRoleerror("The role is required!");
      valid = false;
    } else {
      setRoleerror("");
    }
    if (!ExpectedReturn) {
      setExpectedReturnerror("Expected Return is required!");
      valid = false;
    } else {
      setExpectedReturnerror("");
    }
    if (!start) {
      setStarterror("Start date is required!");
      valid = false;
    } else {
      setStarterror("");
    }
    if (!end) {
      setEnderror("End date is required!");
      valid = false;
    } else {
      setEnderror("");
    }
    if (!RiskTolerance) {
      setRiskToleranceerror("Risk Tolerance is required!");
      valid = false;
    } else {
      setRiskToleranceerror("");
    }
    if (!ExitStrategy) {
      setExitStrategyerror("Exit Strategy is required!");
      valid = false;
    } else {
      setExitStrategyerror("");
    }
    if (!photo) {
      setPhotoerror("Image is required!");
      valid = false;
    } else {
      setPhotoerror("");
    }
    return valid;
  };
  const OrganizationReducerAction = async () => {
    if (!validateForm()) {
      return;
    }
    const categories = [];
    for (var i = 0; i < selectedOptions.length; i++) {
      categories.push(selectedOptions[i].value);
    }
    console.log(categories, selectedOptions, "categories");
    const Organizationdata = new FormData();
    Organizationdata.append("description", Description);
    Organizationdata.append("type", type);
    Organizationdata.append("name", title);
    Organizationdata.append("range", Investmentrange);
    Organizationdata.append("expectedReturn", ExpectedReturn);
    Organizationdata.append("riskTolerance", RiskTolerance);
    Organizationdata.append("exitStrategy", ExitStrategy);
    Organizationdata.append("start", start);
    Organizationdata.append("end", end);
    Organizationdata.append("requirements", requirements);
    Organizationdata.append("categories", categories);
    Organizationdata.append("image", photo[0]);
    Organizationdata.append("investorRole", Role);
    Organizationdata.append("document", documents[0]);
    const data = {
      Organizationdata,
    };
    await dispatch(addOrganization(data)).unwrap();
    console.log(OrganizationStatus, "status");
    if (OrganizationStatus == "success") {
      toast.info("Organization added", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
      window.location.href = "/#/admin/organizations";
    }
    if (OrganizationStatus == "failed") {
      toast.error("check your data", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    console.log(OrganizationStatus, "OrganizationStatus");
  }, [OrganizationStatus]);
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <ToastContainer />

        <Card>
          <Heading size="sm" mb="2">
            General Informations
          </Heading>
          <Divider mb="2" />
          <Flex mt="2" alignItems={"center"}>
            <UploadComponent setFiles={Upfiles} />

            <Flex direction={"column"}>
              <UploadPDF setFiles={Upfiles2} />
            </Flex>
          </Flex>
          {photoerror && (
            <Text mt="3" color="red">
              {photoerror}
            </Text>
          )}
          <Flex mt="2" direction={"column"}>
            <Input
              justifyContent={"end"}
              onChange={(ev) => {
                settitle(ev.target.value);
              }}
              name="Title"
              placeholder="Organization Name"
              id="Title"
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            ></Input>
            {titleerror && (
              <Text mt="3" color="red">
                {titleerror}
              </Text>
            )}
          </Flex>{" "}
          <Flex mt="2" direction={"column"}>
            <Textarea
              marginTop={"5px!important"}
              w={"100%"}
              marginRight="10px"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              placeholder="Description"
              borderRadius="5px"
            />
            {Descriptionerror && (
              <Text mt="3" color="red">
                {Descriptionerror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>What is the type of investor !</Text>
            <Select
              justifyContent={"end"}
              onChange={(ev) => {
                // handleFilter(ev.target.value);
                settype(ev.target.value);
              }}
              name="Type "
              id="Type "
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            >
              <option value="">---</option>
              <option value="Retail/Individual Investors">
                Retail/Individual Investors
              </option>
              <option value="Angel Investors">Angel Investors</option>
              <option value="Venture Capitalists">Venture Capitalists</option>
              <option value="Private Equity Investors">
                Private Equity Investors
              </option>
              <option value="Institutional Investors">
                Institutional Investors
              </option>
              <option value="Impact Investors">Impact Investors</option>
              <option value="Silent Investors">Silent Investors</option>
              <option value="Crowdfunding Investors">
                Crowdfunding Investors
              </option>
            </Select>
            {typeerror && (
              <Text mt="3" color="red">
                {typeerror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>Investment Range</Text>
            <Select
              justifyContent={"end"}
              onChange={(ev) => {
                setInvestmentrange(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            >
              <option value="">---</option>
              <option value="Less than $50,000">Less than $50,000</option>
              <option value="$50,000 - $100,000">$50,000 - $100,000</option>
              <option value="$100,000 - $500,000">$100,000 - $500,000</option>
              <option value="$500,000 - $1,000,000">
                $500,000 - $1,000,000
              </option>
              <option value="More than $1,000,000">More than $1,000,000</option>
            </Select>
            {Investmentrangeerror && (
              <Text mt="3" color="red">
                {Investmentrangeerror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>Preferred Industry</Text>

            <MySelect
              className="InputGroupy"
              placeholder="Select Categories"
              options={sectors}
              value={selectedOptions}
              onChange={setSelectedOptions}
              isMulti
            />
            {selectedOptionserror && (
              <Text mt="3" color="red">
                {selectedOptionserror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>Role in Invester Company</Text>
            <Select
              justifyContent={"end"}
              onChange={(ev) => {
                setRole(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            >
              <option value="">---</option>
              <option value="Silent Investor">Silent Investor</option>
              <option value="Advisory Role">Advisory Role</option>
              <option value="Active Management Role">
                Active Management Role
              </option>
            </Select>
            {Roleerror && (
              <Text mt="3" color="red">
                {Roleerror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>Expected Return</Text>

            <NumberInput
              onChange={(valueString) => {
                setExpectedReturn(valueString);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {ExpectedReturnerror && (
              <Text mt="3" color="red">
                {ExpectedReturnerror}
              </Text>
            )}
          </Flex>{" "}
          <Flex mt="2" direction={"column"}>
            <Text>Start date</Text>

            <Input
              type="date"
              value={start}
              placeholder="start"
              onChange={(e) => setStart(e.target.value)}
            />
            {starterror && (
              <Text mt="3" color="red">
                {starterror}
              </Text>
            )}
          </Flex>{" "}
          <Flex mt="2" direction={"column"}>
            <Text>End date</Text>

            <Input
              type="date"
              value={end}
              placeholder="end"
              onChange={(e) => setEnd(e.target.value)}
            />
            {enderror && (
              <Text mt="3" color="red">
                {enderror}
              </Text>
            )}
          </Flex>{" "}
          <Flex mt="2" direction={"column"}>
            <Text>Risk Tolerance</Text>
            <Select
              justifyContent={"end"}
              onChange={(ev) => {
                setRiskTolerance(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            >
              <option value="">---</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High </option>
            </Select>
            {RiskToleranceerror && (
              <Text mt="3" color="red">
                {RiskToleranceerror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>Preferred Exit Strategy</Text>
            <Select
              justifyContent={"end"}
              onChange={(ev) => {
                setExitStrategy(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            >
              <option value="">---</option>
              <option value="Sale of Shares">Sale of Shares</option>
              <option value="Initial Public Offering">
                Initial Public Offering
              </option>
              <option value="Merger or Acquisition">
                Merger or Acquisition{" "}
              </option>
              <option value="Other">Other</option>
            </Select>
            {ExitStrategyerror && (
              <Text mt="3" color="red">
                {ExitStrategyerror}
              </Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Textarea
              marginTop={"5px!important"}
              w={"100%"}
              marginRight="10px"
              placeholder="Any other preferences or requirements for potential investment opportunities?              "
              borderRadius="5px"
              onChange={(ev) => {
                setrequirements(ev.target.value);
              }}
            />
          </Flex>
          <Flex justifyContent="end" mt="4">
            <Button
              colorScheme="blue"
              size="md"
              onClick={OrganizationReducerAction}
            >
              Confirm
            </Button>
          </Flex>
        </Card>
      </Box>
    </>
  );
};

export default AddOrganization;
