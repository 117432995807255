import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getprojectperMonth } from "reducers/dashboard";
import StatsChart from "./StatsChart";

export default function ProjectsStats() {
  const dispatch = useDispatch();
  const [xValues, setxValues] = useState([]);
  const [yValues, setyValues] = useState([]);
  const [monthYearFilter, setMonthYearFilter] = useState("month");

  const data = useSelector((state) =>
    state.Dashboard.projectperMonth.data
      ? state.Dashboard.projectperMonth.data.result
      : []
  );
  const status = useSelector((state) =>
    state.Dashboard.projectperMonth.status
      ? state.Dashboard.projectperMonth.status
      : []
  );
  const config = {
    options: {
      chart: {
        id: "basic-bar",
      },
      colors: ["#008000a3"],

      xaxis: {
        categories: xValues,
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
    },
    series: [
      {
        name: "Project per Month",
        data: yValues,
      },
    ],
  };
  const getProjectsPerPeriodAction = async () => {
    const payload = {
      monthyear: monthYearFilter,
    };
    await dispatch(getprojectperMonth(payload)).unwrap();
  };
  useEffect(() => {
    if (status == "success") {
      const month = data.map((item) => (item.month ? item.month : item.year));
      setxValues(month);
      const projectCount = data.map((item) => item.count);
      setyValues(projectCount);
    }
  }, [data, status]);

  useEffect(() => {
    getProjectsPerPeriodAction();
  }, [monthYearFilter]);

  return (
    <StatsChart
      title={"Projects"}
      config={config}
      data={data}
      noDataText={"There is no project"}
      setMonthYearFilter={setMonthYearFilter}
      type={"line"}
    />
  );
}
