import React from "react";
import {
  useColorModeValue,
  Flex,
  Box,
  Image,
  Text,
  Badge,
  ButtonGroup,
} from "@chakra-ui/react";

import Card from "components/card/Card.js";

import placeholder from "assets/img/placeholder.jpg";

import BasicButton from "components/button/BasicButton";

export default function CourseItem(props) {
  const { course } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  function navigateToAnotherPage() {
    window.location.href = 'https://academy.darblockchain.io/login/index.php';
}
  return (
    <>
      {course && course.format == "topics" && (
        <Card p="20px">
          <Flex direction={{ base: "column" }} justify="center" h="100%">
            <Box mb={{ base: "20px", "3xl": "20px" }} position="relative">
              <Image
                src={
                  course.overviewfiles[0].fileurl +
                  "?token=d7906798818d70e7b7161436d395b954"
                }
                w={{ base: "100%", "2xl": "100%" }}
                h={{ base: "100%", "2xl": "100%" }}
                borderRadius="20px"
                fallbackSrc={placeholder}
              />
            </Box>
            <Flex flexDirection="column" justify="space-between" h="100%">
              <Flex
                justify="space-between"
                direction={{
                  base: "row",
                  md: "column",
                  lg: "row",
                  xl: "column",
                  "2xl": "row",
                }}
                mb="auto"
              >
                <Flex direction="column">
                  <Flex justify="space-between" alignItems={"center"}>
                    <Text
                      color={textColor}
                      mr="4px"
                      fontSize={{
                        base: "xl",
                        md: "lg",
                        lg: "lg",
                        xl: "lg",
                        "2xl": "md",
                        "3xl": "lg",
                      }}
                      mb="5px"
                      fontWeight="bold"
                      me="14px"
                    >
                      {course.fullname}
                      <Badge
                        colorScheme="green"
                        variant="outline"
                        p="2px 0px"
                        mr="1"
                        ml="5px"
                      >
                        <Text>{course.categoryname}</Text>
                      </Badge>{" "}
                    </Text>{" "}
                  </Flex>
                  <Text
                    className="description"
                    color={textColor}
                    mb="5px"
                    me="14px"
                  >
                    {course.summary.replace(/<[^>]*>/g, "")}
                  </Text>
                </Flex>

                <Text fontWeight="600" fontSize="sm"></Text>
              </Flex>
              <Flex
                align="start"
                alignItems={"baseline"}
                justify="space-between"
                mt="25px"
              >
                <Text
                  color={textColor}
                  mr="4px"
                  fontSize={{
                    base: "xl",
                    md: "lg",
                    lg: "lg",
                    xl: "lg",
                    "2xl": "md",
                    "3xl": "lg",
                  }}
                  mb="5px"
                  fontWeight="bold"
                  me="14px"
                >
                  {""}
                </Text>
                <Flex direction="column">
                  <ButtonGroup
                    spacing="2"
                    display={"flex"}
                    justifyContent="flex-end"
                  >
                    <BasicButton
                      title={"View Course"}
                      // href="https://academy.darblockchain.io/"
                      onClick={navigateToAnotherPage}
                    ></BasicButton>
                  </ButtonGroup>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      )}
    </>
  );
}
