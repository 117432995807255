import {
  Avatar,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import config from "config/axios/config";
import Card from "components/card/Card";
import { Link as Linkk } from "react-router-dom";
import { BsFillStarFill } from "react-icons/bs";
import { getTopExpertsByRating } from "reducers/expert";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function TopExperts() {
  const dispatch = useDispatch();
  const textColor = useColorModeValue("navy.700", "white");
  const experts = useSelector((state) =>
    state.Expert.topExpertsByRating.data
      ? state.Expert.topExpertsByRating.data.result
      : []
  );
  const getTopExpertsByRatingAction = async () => {
    await dispatch(getTopExpertsByRating()).unwrap();
  };
  useEffect(() => {
    getTopExpertsByRatingAction();
  }, []);
  return (
    <>
      {experts && (
        <Card direction="column" w="100%" px="0px" overflowX={"auto"}>
          <Flex direction="column" w="100%">
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify="space-between"
              w="100%"
              px="22px"
              pb="20px"
              mb="10px"
              boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
            >
              <Text color={textColor} fontSize="xl" fontWeight="600">
                Top Experts
              </Text>
              {experts.length > 0 && (
                <Button variant="action">
                  <Linkk to={"/admin/experts"}>See all</Linkk>
                </Button>
              )}
            </Flex>
            {experts.length > 0 && (
              <Table variant="simple" color="gray.500">
                <Thead>
                  <Tr>
                    <Th pe="10px" borderColor="transparent">
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        NAME
                      </Flex>
                    </Th>
                    <Th pe="10px" borderColor="transparent">
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        RATING
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {experts.map((item, index) => {
                    let content = [];
                    for (var i = 0; i < item.rating; i++) {
                      content.push(
                        <Icon
                          as={BsFillStarFill}
                          me="2px"
                          width="13px"
                          height="13px"
                          color="#ffe234"
                        />
                      );
                    }
                    return (
                      <Tr key={item.id}>
                        <Td
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          <Flex align="center">
                            <Avatar
                              src={config.apiUrl + "/api/getfile/" + item.image}
                              w="40px"
                              h="40px"
                              me="8px"
                            />
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="600"
                            >
                              <Linkk to={"/admin/expert/profile/" + item.id}>
                                {item.name}
                              </Linkk>
                            </Text>
                          </Flex>
                        </Td>
                        <Td
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          <Flex align="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="600"
                            >
                              {content}
                            </Text>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
            {experts.length === 0 && (
              <Flex px="25px" justify="space-between" mb="20px" align="center">
                <Text>There is no data to show</Text>
              </Flex>
            )}
          </Flex>
        </Card>
      )}
    </>
  );
}

export default TopExperts;
