import React, { useState } from "react";
import {
  Input,
  useColorModeValue,
  Flex,
  Text,
  Textarea,
  FormLabel,
  Box,
  Image,
  Checkbox,
  Badge,
  Select,
  HStack,
  Center,
} from "@chakra-ui/react";
import MySelect from "react-select";
import placeholder from "assets/img/placeholder.jpg";

import BasicButton from "components/button/BasicButton";
import InputField from "components/fields/InputField";
import CardItem from "./CardItem";
import { useEffect } from "react";
import { getAllOrganizations } from "reducers/organization";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import Pagination from "components/pagination/Pagination";
import { sectors } from "utils/constants";
import TextCard from "components/card/TextCard";

const StackHoldersAssistingProgram = ({
  activeStep,
  setActiveStep,
  setSelectedStackHolders,
  selectedStackHolders,
}) => {
  const dispatch = useDispatch();
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [name, setName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const organizations = useSelector(
    (state) => state.Organization.allOrganizations.data?.result?.organizations
  );
  const totalPages = useSelector(
    (state) => state.Organization.allOrganizations.data?.result?.totalPages
  );
  const toggleProject = (stackHolder) => {
    const x = [...selectedStackHolders];
    let exist = false;
    console.log(x,'xxxxxxxxxxx')
    x.map((item) => {
        console.log(item,'akakakakakakakakakakkalalalala')
      if (item._id === stackHolder._id) {
        exist = true;
      }
    });
    if (exist) {
      const res = x.filter((item) => item._id !== stackHolder._id);
      setSelectedStackHolders(res);
    } else {
      x.push(stackHolder);
      setSelectedStackHolders(x);
    }
  };
  const getOrganizations = async () => {
    const categories = selectedOptions.map((item) => item.value);
    const filterdata = {
      page: page,
      limit: 5,
      name: name,
      investmentRange: "",
      categories: categories,
    };
    await dispatch(getAllOrganizations(filterdata)).unwrap();
  };

  useEffect(() => {
    getOrganizations();
  }, [name, selectedOptions, page]);

  const next = () => {
    setActiveStep(activeStep + 1);
  };
  return (
    <Flex flexDirection={"column"}>
      <Card>
        <HStack spacing={2}>
          <Input
            variant="outline"
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </HStack>
        <Flex mt="2" direction={"column"}>
          <MySelect
            className="InputGroupy"
            placeholder="Select Categories"
            options={sectors}
            value={selectedOptions}
            onChange={setSelectedOptions}
            isMulti
          />
        </Flex>
      </Card>
      <Flex direction={"column"}>
        {organizations && organizations.length > 0 && (
          <Box>
            {organizations.map((item) => (
              <Card p="1" mb="2" boxShadow={cardShadow}>
                <Flex>
                  <Flex
                    width="100%"
                    height="100%"
                    padding="0.6rem"
                    fontWeight="bold"
                    color={textColor}
                    fontSize="md"
                    textAlign="start"
                  >
                    <Image
                      h="50px"
                      w="50px"
                      //   src={config.apiUrl + `/project/file/` + item.images}
                      borderRadius="8px"
                      fallbackSrc={placeholder}
                      objectFit="cover"
                    />
                    <Box ml="3">
                      <Text fontWeight="normal">{item.name}</Text>
                      <Text fontSize="sm">
                        {" "}
                        {item.categories.map((category) => (
                          <Badge
                            colorScheme="pink"
                            p="2px 0px"
                            mr="1"
                            variant="outline"
                          >
                            <Text
                              fontSize="10px"
                              width="100%"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                            >
                              {category}
                            </Text>
                          </Badge>
                        ))}
                      </Text>
                    </Box>
                  </Flex>
                  <Checkbox
                    me="16px"
                    colorScheme="brandScheme"
                    borderColor={"#3965ff"}
                    onChange={() => toggleProject(item)}
                  />
                </Flex>
                {organizations && organizations.length == 0 && (
                  <TextCard text="There is no Organization" />
                )}
              </Card>
            ))}
            {totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={() => {}}
                />
              </Center>
            )}
          </Box>
        )}
        {/* {projectsError && (
          <Text mt="3" color="red">
            {projectsError}
          </Text>
        )} */}
      </Flex>
      <Flex justifyContent={"space-between"} mt="3">
        <BasicButton
          onClick={() => setActiveStep(activeStep - 1)}
          title={"Previous"}
          variant="solid"
        />
        <BasicButton onClick={next} title={"Next"} variant="solid" />
      </Flex>
    </Flex>
  );
};

export default StackHoldersAssistingProgram;
