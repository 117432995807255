import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Box, Flex, SimpleGrid, Center } from "@chakra-ui/react";
import AssistingProgramsItem from "./components/AssistingProgramsItem";
import Loader from "components/loader/Loader";
import TextCard from "components/card/TextCard";
import { getallAssistingPrograms } from "reducers/assistingProgram";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom";
import Pagination from "components/pagination/Pagination";

const AssistingPrograms = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const [userType, setUserType] = useState("");
  const status = useSelector((state) => state.Cohort.cohorts.status);
  const assistingPrograms = useSelector((state) =>
    state.AssistingProgram.getAssistingPrograms.data.result
      ? state.AssistingProgram.getAssistingPrograms.data.result
          .assistingPrograms
      : []
  );
  const created = useSelector((state) =>
    state.Cohort.cohorts.data ? state.Cohort.cohorts.data.created : []
  );
  const totalPages = useSelector((state) =>
    state.AssistingProgram.getAssistingPrograms.data.result
      ? state.AssistingProgram.getAssistingPrograms.data.result.totalPages
      : 0
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const fetchAssistingPrograms = async () => {
    const filterData = {
      page: page,
      limit: 4,
    };
    await dispatch(getallAssistingPrograms(filterData)).unwrap();
  };
  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
    fetchAssistingPrograms();
    setReload(false);
  }, [currentUser, page, reload]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer />
      <Card p="20px">
        <Flex direction={"column"} gap={{ base: "20px", xl: "20px" }}>
          {(userType == "Admin" || userType == "SuperAdmin") && (
            <Flex w="100%" justifyContent="flex-end" mb="4">
              <BasicButton
                title={"Add Assisting Program"}
                variant="solid"
                onClick={() => history.push("/admin/add-assisting-program")}
              />
            </Flex>
          )}
          {assistingPrograms && assistingPrograms.length > 0 && (
            <>
              <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                {assistingPrograms.map((item, index) => (
                  <AssistingProgramsItem
                    item={item}
                    created={created}
                    page={page}
                    onRelaod={() => setReload(true)}
                  />
                ))}
              </SimpleGrid>{" "}
              {totalPages > 1 && (
                <Center pt="5">
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    setPage={setPage}
                    setLoader={() => {}}
                  />
                </Center>
              )}
            </>
          )}
          {assistingPrograms && assistingPrograms.length === 0 && (
            <TextCard text={"There is no Assisting Program"} />
          )}
          {!assistingPrograms && status === "loading" && <Loader />}
        </Flex>
      </Card>
    </Box>
  );
};

export default AssistingPrograms;
