import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Banner from "views/admin/profile/components/Banner";
import { Box, SimpleGrid } from "@chakra-ui/react";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar.png";
import "./ProfileSettings.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountSettings from "./AccountSettings";
import SocialProfiles from "./SocialProfiles";
import ChangePassword from "./ChangePassword";
import Education from "./Education";
import WorkHistory from "./WorkHistory";
import Languages from "./Languages";
import ExpertHourlyPrice from "./ExpertHourlyPrice";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "reducers/userInformation";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [profilePercentage, setProfilePercentage] = useState(null);
  const [userType, setUserType] = useState("");
  const status = useSelector((state) => state.UserInformation.user.status);
  const currentUser = useSelector((state) => state.UserInformation.user.data);

  const getUserByIdAction = async () => {
    const id = jwt_decode(localStorage.getItem("token"))._id;
    await dispatch(getUserById(id)).unwrap();
  };

  useEffect(() => {
    setUserType(jwt_decode(localStorage.getItem("token")).userType);
    getUserByIdAction();
  }, []);

  useEffect(() => {
    if (status === "success") {
      setUser(currentUser && currentUser.message ? currentUser.message : {});
      setProfilePercentage(
        currentUser && currentUser.percentage !== null
          ? currentUser.percentage
          : null
      );
    }
  }, [status]);

  return (
    <>{user && <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer />
       <Banner
        banner={banner}
        user={user}
        avatar={avatar}
        name={user.firstName + " " + user.lastName}
        job={user.job}
        profilePercentage={profilePercentage}
      />
      <AccountSettings />
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
        mt="20px"
      >
        <SocialProfiles />
        <ChangePassword />
        <Education getUser={getUserByIdAction} user={currentUser} />
        <WorkHistory getUser={getUserByIdAction} />
        <Languages getUser={getUserByIdAction} />
        {userType === "Expert" && (
          <ExpertHourlyPrice getUser={getUserByIdAction} user={currentUser} />
        )}
      </SimpleGrid>
    </Box>}</>
  );
};

export default ProfileSettings;
