import Api from "config/axios/api";
import config from "../config/axios/config";

export const addOpportunityService = async (cosupData) => {
  const url = config.apiUrl + `/cosup/`;
  try {
    return await Api.post(url, cosupData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const GetOpportunityDetailsService = async (id) => {
  const url = config.apiUrl + `/cosup/` + id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getRequestsByCOSService = async (id) => {
  const url = config.apiUrl + `/prcs/getbyidCallofstartup/` + id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateCOSRequestStatusService = async (updateData) => {
  const url = config.apiUrl + `/prcs/` + updateData.id;
  try {
    return await Api.put(
      url,
      { status: updateData.status },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const applyOpportunityService = async (prcsData) => {
  const url = config.apiUrl + `/prcs/`;
  try {
    return await Api.post(url, prcsData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const RequestOpportunityService = async (prcsData) => {
  const url = config.apiUrl + `/prcs/myRequest`;
  try {
    return await Api.post(url,prcsData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getRequestCallofstartupByIdService = async (id) => {
  const url = config.apiUrl + `/prcs/getRequestCallofstartupById/` + id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default {
  addOpportunityService,
  GetOpportunityDetailsService,
  getRequestsByCOSService,
  updateCOSRequestStatusService,
  applyOpportunityService,
  RequestOpportunityService,
  getRequestCallofstartupByIdService,
};
