import {
  Text,
  useColorModeValue,
  Flex,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Image,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useEffect } from "react";
import config from "config/axios/config";
import { Link as Linkk } from "react-router-dom";
import placeholder from "assets/img/placeholder.jpg";
import { getVotesByCategory } from "reducers/project";
import { useDispatch, useSelector } from "react-redux";

export default function TopVotesbyAssistingPrograms(props) {
  const dispatch = useDispatch();
  const textColor = useColorModeValue("navy.700", "white");
  const projects = useSelector((state) =>
    state.Dashboard.topvotedcohort.data
      ? state.Dashboard.topvotedcohort.data.result
      : []
  );

  const getVotesByCategoryAction = async () => {
    await dispatch(getVotesByCategory()).unwrap();
  };

  useEffect(() => {
    getVotesByCategoryAction();
  }, []);
  return (
    <>
      {projects && (
        <Card direction="column" w="100%" px="0px" overflowX={"auto"}>
          <Flex direction="column" w="100%" overflowX={"auto"}>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify="space-between"
              w="100%"
              px="22px"
              pb="20px"
              mb="10px"
              boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
            >
              <Text color={textColor} fontSize="xl" fontWeight="600">
                Top Votes by Assisting Programs
              </Text>
              {projects.length > 0 && (
                <Button variant="action">
                  <Linkk to={"/admin/cohorts"}>See all</Linkk>
                </Button>
              )}
            </Flex>
            {projects.length > 0 && (
              <Table variant="simple" color="gray.500">
                <Thead>
                  <Tr>
                    <Th pe="10px" borderColor="transparent">
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        NAME
                      </Flex>
                    </Th>
                    <Th pe="10px" borderColor="transparent">
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        VOTES
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {projects.map((item, index) => {
                    return (
                      <Tr key={item.id}>
                        <Td
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          <Flex align="center">
                            <Image
                              src={
                                config.apiUrl + "/cohort/image/" + item.image
                              }
                              w="40px"
                              h="40px"
                              me="8px"
                              borderRadius={"5px"}
                              fallbackSrc={placeholder}
                              objectFit="cover"
                            />
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="600"
                            >
                              <Linkk to={"/admin/cohort/" + item.id}>
                                {item.title}
                              </Linkk>
                            </Text>
                          </Flex>
                        </Td>
                        <Td
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          <Flex align="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="600"
                            >
                              {item.totalLikes}
                            </Text>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
            {projects.length === 0 && (
              <Flex px="25px" justify="space-between" mb="20px" align="center">
                <Text>There is no data to show</Text>
              </Flex>
            )}
          </Flex>
        </Card>
      )}
    </>
  );
}
