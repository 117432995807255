import React, { useState } from "react";
import { SimpleGrid, Icon, useColorModeValue } from "@chakra-ui/react";
import {
  AtSignIcon,
  CheckIcon,
  CloseIcon,
  PhoneIcon,
  StarIcon,
  ViewIcon,
} from "@chakra-ui/icons";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

import { useSelector } from "react-redux";
import { getStaticsEcoSystemMembers } from "reducers/dashboard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export default function UserStats(props) {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const dispatch = useDispatch();
  const [stats, setStats] = useState([]);

  const statics = useSelector(
    (state) => state.Dashboard.staticsEcoSystemMembers.data
  );
  const status = useSelector(
    (state) => state.Dashboard.staticsEcoSystemMembers.status
  );
  const [reload, setReload] = useState(false);
  const getStaticsEcoSystemMembersAction = async () => {
    await dispatch(getStaticsEcoSystemMembers()).unwrap();
    setReload(true);
  };

  useEffect(() => {
    getStaticsEcoSystemMembersAction();
  }, [reload]);
  useEffect(() => {
    if (statics) {
      setStats([
        {
          name: "Total Users",
          value: statics.users,
          color: brandColor,
          icon: AtSignIcon,
        },
        {
          name: "Verified",
          value: statics.verifiedUser,
          color: "green",
          icon: CheckIcon,
        },
        {
          name: "Not Verified",
          value: statics.notVerifiedUser,
          color: "red",
          icon: CloseIcon,
        },
        {
          name: "Experts",
          value: statics.experts,
          color: brandColor,
          icon: PhoneIcon,
        },
        {
          name: "Project Holders",
          value: statics.ProjectHolder,
          color: brandColor,
          icon: StarIcon,
        },
        {
          name: "Community Members",
          value: statics.community,
          color: brandColor,
          icon: ViewIcon,
        },
      ]);
    }
  }, [statics]);

  return (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
      gap="20px"
      mb="20px"
    >
      {stats &&
        status &&
        status == "success" && (
          <>
            {stats.map((stat, i) => (
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon
                        w="22px"
                        h="22px"
                        as={stat.icon}
                        color={stat.color}
                      />
                    }
                  />
                }
                name={stat.name}
                value={stat.value}
                key={stat.name + i}
              />
            ))}
          </>
        )}
    </SimpleGrid>
  );
}
