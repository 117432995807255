import React from "react";
import { Box, Flex, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import { FaEdit, FaStar, FaTrash } from "react-icons/fa";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import EditPrize from "../editHackathon/EditPrize";
import DeleteConfirmationModal from "components/modals/DeleteConfirmationModal";
import { deletePrize } from "reducers/hackathon";
import { useDispatch } from "react-redux";

const PrizeItem = ({ prize, hackathon, onReload, canEdit }) => {
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const onDeleteHandler = async () => {
    await dispatch(deletePrize(prize._id)).unwrap();
    onReload();
  };
  return (
    <Box
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      width="300px"
      m={2}
    >
      <Flex mt="3">
        <Text fontSize="lg" mr={2} fontWeight="bold">
          <FaStar color="#ffd333" />
        </Text>
        <Text>{prize.title}</Text>
      </Flex>
      <Text mt={2} fontSize="lg" color="gray.600" fontWeight="bold">
        {prize.value + " " +"USD"}
      </Text>
      <Text mt={2} color="gray.600">
        {prize.description}
      </Text>
      {canEdit && (
        <Flex justifyContent={"flex-end"}>
          <IconButton
            icon={<FaEdit />}
            size="sm"
            aria-label={`Edit ${prize.title}`}
            mr="4px"
            colorScheme="blue"
            onClick={onOpen}
          />
          <IconButton
            icon={<FaTrash />}
            size="sm"
            colorScheme="red"
            aria-label={`Delete ${prize.title}`}
            onClick={onOpenDelete}
          />
        </Flex>
      )}
      <EditPrize
        isOpen={isOpen}
        onClose={onClose}
        initialData={hackathon}
        prize={prize}
        onReload={onReload}
      />
      <DeleteConfirmationModal
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        headerContent="Confirm Deletion"
        bodyContent={`Are you sure you want to delete this prize? This action cannot be undone.`}
        handleDelete={onDeleteHandler}
      />
    </Box>
  );
};

export default PrizeItem;
