import React from "react";
import { useParams } from "react-router-dom";
import config from "config/axios/config";
import {
  Flex,
  Spinner,
  Text,
  Icon,
  Image,
  Avatar,
  useColorModeValue,
  SimpleGrid,
  Heading,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import Information from "views/admin/profile/components/Information";
import { BsTwitter, BsGithub, BsLinkedin } from "react-icons/bs";
import { getProjectById } from "reducers/project";
import { useDispatch, useSelector } from "react-redux";
import placeholder from "assets/img/placeholder.jpg";
import jwt_decode from "jwt-decode";
import { historicdetailsReducer } from "reducers/historicReview";
import moment from "moment";

const ViewHistoriqueReview = () => {
  const brandColor = useColorModeValue("brand.500", "white");
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [userType, setUsetType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const project = useSelector((state) =>
  //   state.Project.project.data ? state.Project.project.data.message : null
  // );
  // const status = useSelector((state) => state.Project.project.status);
  // const Vote = useSelector((state) =>
  //   state.Project.project.data ? state.Project.project.data : null
  // );
  const HistoricReviewDetails = useSelector((state) =>
    state.HistoricReview.datailshistoryreview.data
      ? state.HistoricReview.datailshistoryreview.data.message
      : null
  );
  const historicdetailsReducerAction = async () => {
    await dispatch(historicdetailsReducer(id)).unwrap();
  };
  useEffect(() => {
    const userData = jwt_decode(localStorage.getItem("token"));
    setUsetType(userData ? userData.userType : "");
    historicdetailsReducerAction();
  }, []);

  return (
    <>
      <Flex
        flexDirection={"column"}
        pt={{ base: "130px", md: "80px", xl: "80px" }}
      >
        <>
          {HistoricReviewDetails && (
            <Card mt="3" mb="3">
              <Image
                src={
                  HistoricReviewDetails.idMeet.project.images == null
                    ? placeholder
                    : config.apiUrl +
                      "/project/file/" +
                      HistoricReviewDetails.idMeet.project.images
                }
                w={"100%"}
                h={"220px"}
                borderRadius="20px"
                objectFit="cover"
                bg={"white"}
                border="3px solid"
                mb="10px"
                borderColor={borderColor}
                boxShadow={"md"}
                fallbackSrc={placeholder}
              />
               {HistoricReviewDetails.questions.Whathappen && (
                <Text color={textColorSecondary} fontSize="md" mb="20px">
                  {HistoricReviewDetails.questions.Whathappen} 
                </Text>
              )}
              {HistoricReviewDetails.questions.summary && (
                <Text color={textColorSecondary} fontSize="md" mb="20px">
                  {HistoricReviewDetails.questions.summary}
                </Text>
              )}
              {HistoricReviewDetails.questions.comments && (
                <Text color={textColorSecondary} fontSize="md" mb="20px">
                  {HistoricReviewDetails.questions.comments}
                </Text>
              )}
              <SimpleGrid columns="2" gap="20px">
                {HistoricReviewDetails.expert && (
                  <Information
                    boxShadow={cardShadow}
                    title="Expert Name"
                    value={HistoricReviewDetails.expert.firstName +" "+ HistoricReviewDetails.expert.lastName }
                  />
                )}
                {HistoricReviewDetails.user && (
                  <Information
                    boxShadow={cardShadow}
                    title="Project Holder Name"
                    value={HistoricReviewDetails.user.firstName +" "+ HistoricReviewDetails.user.lastName }
                  />
                )}
                {HistoricReviewDetails.idMeet && (
                  <Information
                    boxShadow={cardShadow}
                    title="Meeting title"
                    value={HistoricReviewDetails.idMeet.title}
                  />
                )}
                 {HistoricReviewDetails.idMeet.project && (
                  <Information
                    boxShadow={cardShadow}
                    title="Project title"
                    value={HistoricReviewDetails.idMeet.project.title}
                  />
                )}
                 {HistoricReviewDetails.idMeet.start && (
                  <Information
                    boxShadow={cardShadow}
                    title="Meeting start date"
                    value={moment(HistoricReviewDetails.idMeet.start).format("llll")}
                  />
                )}
                 {HistoricReviewDetails.idMeet.end && (
                  <Information
                    boxShadow={cardShadow}
                    title="Meeting end date"
                    value={moment(HistoricReviewDetails.idMeet.end).format("llll")}
                  />
                )}
                {HistoricReviewDetails.questions.rateOverall && (
                  <Information
                    boxShadow={cardShadow}
                    title="Rate over all"
                    value={HistoricReviewDetails.questions.rateOverall}
                  />
                )}
                {HistoricReviewDetails.questions.rate && (
                  <Information
                    boxShadow={cardShadow}
                    title="Rate over all"
                    value={HistoricReviewDetails.questions.rate}
                  />
                )}
                {HistoricReviewDetails.questions.keyareas && (
                  <Information
                    boxShadow={cardShadow}
                    title="key areas"
                    value={HistoricReviewDetails.questions.keyareas}
                  />
                )}
                {HistoricReviewDetails.questions.tasks && HistoricReviewDetails.questions.tasks.length > 0  && (
                  <Information
                    boxShadow={cardShadow}
                    title="tasks"
                    value={HistoricReviewDetails.questions.tasks}
                  />
                )}
                {HistoricReviewDetails.questions.ratethesession && (
                  <Information
                    boxShadow={cardShadow}
                    title="Rate the session"
                    value={HistoricReviewDetails.questions.ratethesession}
                  />
                )}
                {HistoricReviewDetails.questions.meetings &&
                  HistoricReviewDetails.questions.meetings.meeting == "Yes" && (
                    <Information
                      boxShadow={cardShadow}
                      title="Next Session
                    "
                      value={
                        HistoricReviewDetails.questions.meetings.NextSession
                      }
                    />
                  )}
                   {HistoricReviewDetails.questions.meetings &&
                  HistoricReviewDetails.questions.meetings.meeting == "No" && (
                    <Information
                      boxShadow={cardShadow}
                      title="Next Session
                    "
                      value={"No next Session"}
                    />
                  )}
                {HistoricReviewDetails.questions.meetings &&
                  HistoricReviewDetails.questions.meetings.meeting == "Yes" && (
                    <Information
                      boxShadow={cardShadow}
                      title="what are the condition of next session"
                      value={
                        HistoricReviewDetails.questions.meetings.conditions
                      }
                    />
                  )}
                  
                {HistoricReviewDetails.questions.followupsession &&
                  HistoricReviewDetails.questions.followupsession.followup ==
                    "Yes" && (
                    <Information
                      boxShadow={cardShadow}
                      title="Follow up Session"
                      value={
                        HistoricReviewDetails.questions.followupsession
                          .NextSession
                      }
                    />
                  )}
                {HistoricReviewDetails.questions.followupsession &&
                  HistoricReviewDetails.questions.followupsession.followup ==
                    "No" && (
                    <Information
                      boxShadow={cardShadow}
                      title="Follow up Session"
                      value={"No Follow up Session"}
                    />
                  )}
                {HistoricReviewDetails.questions.tasks && HistoricReviewDetails.questions.tasks.task == "Yes" && (
                  <Information
                    boxShadow={cardShadow}
                    title="Tasks"
                    value={HistoricReviewDetails.questions.tasks.yestasks}
                  />
                )}
                {HistoricReviewDetails.questions.tasks && HistoricReviewDetails.questions.tasks.task == "No" && (
                  <Information
                    boxShadow={cardShadow}
                    title="Tasks"
                    value={"No Tasks"}
                  />
                )}
                
              </SimpleGrid>
            </Card>
          )}
        </>
      </Flex>
    </>
  );
};

export default ViewHistoriqueReview;
