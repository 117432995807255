import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  SimpleGrid,
  Center,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { ToastContainer } from "react-toastify";

import Card from "components/card/Card.js";
import Pagination from "components/pagination/Pagination";
import UsersListItem from "./components/UsersListItem";
import UserStats from "./components/UserStats";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getallUsers } from "../../../reducers/userInformation";
import TextCard from "components/card/TextCard";
import Loader from "components/loader/Loader";
import UserFilters from "./components/UserFilters";

const Users = () => {
  const [userType, setUserType] = useState([]);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const dispatch = useDispatch();

  const users = useSelector(
    (state) => state.UserInformation.usersInformation.data.Users
  );
  const status = useSelector(
    (state) => state.UserInformation.usersInformation.status
  );
  const totalPages = useSelector(
    (state) => state.UserInformation.usersInformation.data.totalPages
  );

  const fetchUsers = async () => {
    const filterdata = {
      limit: 10,
      page: page,
      type: userType,
      name: "",
    };
    dispatch(getallUsers(filterdata));
    setReload(false);
  };
  useEffect(() => {
    fetchUsers();
  }, [userType, page, reload]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer />
      <UserStats />
      <Card p="20px" bg={"#E2E8F0"}>
        <Flex direction={"column"} gap={{ base: "20px", xl: "20px" }}>
          <Flex w="100%" justifyContent="space-between">
            <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
              List of users
            </Text>
            <UserFilters
              setUserType={setUserType}
              onRelaod={() => setReload(true)}
              setPage={setPage}
            />
          </Flex>
          <>
            {users && users.length > 0 && (
              <SimpleGrid columns="1" gap="10px" mb="10px">
                {users.map((user) => (
                  <UsersListItem
                    key={user._id}
                    user={user}
                    onRelaod={() => setReload(true)}
                  />
                ))}
                {totalPages > 1 && (
                  <Center pt="5">
                    <Pagination
                      page={page}
                      totalPages={totalPages}
                      setPage={setPage}
                      setLoader={() => {}}
                    />
                  </Center>
                )}
              </SimpleGrid>
            )}
            {status && status == "failed" && (
              <>
                <TextCard text="There are no users to show" />
                <TextCard text="Try modifying the parameters of your filter" />
              </>
            )}
            {users && users.length == 0 && <Loader />}
          </>
        </Flex>
      </Card>
    </Box>
  );
};

export default Users;
