import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import {
  Flex,
  Box,
  Grid,
  SimpleGrid,
  GridItem,
  Spinner,
  Center,
  Text,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import NewListItem from "./components/NewListItem";
import Pagination from "components/pagination/Pagination";
import AddNewModal from "./components/AddNewModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllNewsR } from "reducers/news";
import TextCard from "components/card/TextCard";
import BasicButton from "components/button/BasicButton";

export default function News() {
  const [userType, setUserType] = useState("");
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reload, setReload] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const allNewsData = useSelector((state) => state.News.getNews.data.news);
  const statusNews = useSelector((state) => state.News.getNews.status);
  const totalePage = useSelector((state) => state.News.getNews.data.totalPages);
  const dispatch = useDispatch();

  const getNews = async () => {
    const filterdata = {
      page: page,
      limit: 4,
    };
    await dispatch(getAllNewsR(filterdata)).unwrap();
  };

  useEffect(() => {
    const USER_TYPE = jwt_decode(localStorage.getItem("token")).userType;
    setUserType(USER_TYPE);
    getNews();
    setReload(false);
  }, [reload, page]);
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <ToastContainer />
      <AddNewModal isOpen={isOpen} onClose={onClose} getNews={getNews} />

      <Flex
        w="100%"
        mb="20px"
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }}
      >
        <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
          List of news
        </Text>
        {(userType === "Admin" || userType === "SuperAdmin") && (
          <BasicButton onClick={onOpen} title={"Add New"}></BasicButton>
        )}
      </Flex>
        <div>
          {statusNews && statusNews === "loading" && (
            <Center>
              <Spinner />
            </Center>
          )}
          {allNewsData && allNewsData.length > 0  && (
            <Grid>
              <GridItem>
                {/* List of News */}
                <SimpleGrid
                  columns={{ base: 1, lg: 2, xl: 3, md: 2 }}
                  gap="20px"
                  mb="20px"
                  mt="20px"
                >
                  {" "}
                  {allNewsData &&
                    allNewsData.length > 0 &&
                    allNewsData.map((article, index) => {
                      return (
                        <NewListItem
                          key={article._id}
                          article={article}
                          getNews={getNews}
                          onRelaod={() => setReload(true)}
                          index={index}
                          userType={userType}
                        />
                      );
                    })}
                  {}
                </SimpleGrid>
                {totalePage > 1 && (
                  <Center pt="5">
                    <Pagination
                      page={page}
                      totalPages={totalePage}
                      setPage={setPage}
                      setLoader={setLoader}
                    />
                  </Center>
                )}
              </GridItem>
            </Grid>
          )}
          {allNewsData && allNewsData.length === 0 && (
            <>
              <TextCard text="There are no news to show" />
              {userType === "Admin" ||
                (userType === "SuperAdmin" && (
                  <>
                    <TextCard text="You can add some news using the 'Add News' button" />
                    <TextCard
                      text=" Come back soon to checkout any news and new information
                      that has been added"
                    />
                  </>
                ))}
            </>
          )}
        </div>
    </Box>
  );
}
