import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import {
  Flex,
  Box,
  Spinner,
  Center,
  Grid,
  GridItem,
  Button,
  ButtonGroup,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import MiniCalendar from "components/calendar/MiniCalendar";
import EventItem from "views/admin/events/components/EventItem";
import { getEvents } from "reducers/event";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/pagination/Pagination";
import { Link as Linkk } from "react-router-dom";
import Card from "components/card/Card.js";

const Events = () => {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("");
  const [selectedDates, setSelectedDates] = useState(new Set());
  const [filter, setFilter] = useState({
    label: "All",
    value: "",
  });
  const [reload, setReload] = useState(false);

  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const totalPages = useSelector((state) =>
    state.Event.events.data ? state.Event.events.data.totalPages : 0
  );
  const events = useSelector((state) =>
    state.Event.events.data ? state.Event.events.data.events : []
  );
  const filters = [
    { label: "All", value: "" },
    { label: "Upcoming", value: "upcoming" },
    { label: "In Progress", value: "active" },
  ];

  const getEventsAction = async (filterData) => {
    await dispatch(getEvents(filterData)).unwrap();
  };

  useEffect(() => {
    setUserType(jwt_decode(localStorage.getItem("token")).userType);
    if (reload === true) {
      const filterData = {
        page,
        limit: 6,
        type: filter.value,
      };
      getEventsAction(filterData);
      setReload(false);
    }
  }, [page, filter, reload]);
  useEffect(() => {
    const filterData = {
      page,
      limit: 6,
      type: filter.value,
    };
    getEventsAction(filterData);
  }, [page]);
  return (
    <Box
      pt={{ base: "180px", md: "80px", xl: "80px" }}
      pl={{ md: "20px", xl: "20px" }}
    >
      <Grid
        gridTemplateColumns={{ xxl: "repeat(3, 1fr)" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
      >
        <GridItem colSpan={2}>
          <Flex justifyContent={"space-between"}>
            <ButtonGroup isAttached>
              {filters.map((tab, index) => {
                const isSelected = tab.label === filter.label;
                return (
                  <Button
                    key={tab.label + index}
                    colorScheme="gray"
                    bg="transparent"
                    color={isSelected && "#6b46c1"}
                    borderBottom={isSelected && "2px solid #6b46c1"}
                    rounded="0px"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    onClick={() => {
                      console.log(tab);
                      setReload(true);
                      setFilter(tab);
                    }}
                  >
                    {tab.label}
                  </Button>
                );
              })}
            </ButtonGroup>
            
            {(userType === "Admin" || userType === "SuperAdmin") && (
              <Button variant={"brand"}>
                <Linkk to={"/admin/add-event"}>Add Event</Linkk>
              </Button>
            )}
          </Flex>
          <Box>
            {reload === true && (
              <Flex flexDirection="column" h="100vh">
                <Spinner my="auto!important" mx="auto" />
              </Flex>
            )}
            {events && events.length > 0 && (
              <>
                {" "}
                <SimpleGrid
                  columns={{ base: 1, lg: 2, xl: 3, md: 2 }}
                  gap="20px"
                  mb="20px"
                  mt="20px"
                >
                  {events.map((item, index) => {
                    return (
                      <EventItem
                        event={item}
                        getEvents={getEvents}
                        onReload={() => setReload(true)}
                      />
                    );
                  })}
                </SimpleGrid>
                {totalPages > 1 && (
                  <Center pt="5">
                    <Pagination
                      page={page}
                      totalPages={totalPages}
                      setPage={setPage}
                      setLoader={setLoader}
                    />
                  </Center>
                )}
              </>
            )}
            {events && events.length == 0 && (
              <Card mt="4">
                <Center fontSize="xl" textAlign={"center"} color={"grey"} p="3">
                  There are no events to show
                </Center>
              </Card>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Events;
