import {
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import { SocketContext } from "contexts/SocketContext";
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addNewExpert } from "reducers/expert";
import { sectors } from "utils/constants";
import MySelect from "react-select";
import { checkIsValidEmail } from "utils/functions";

export default function AddExpertModal(props) {
  const { isOpen, onClose, onReload } = props;
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [email, setEmail] = useState();
  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sectorsError, setSectorsError] = useState("");
  const status = useSelector((state) => state.Expert.addExpert.status);
  const error = useSelector((state) => state.Expert.addExpert.error);
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const notificationsSettings = useSelector((state) =>
    state.NotificationsSettings.notificationsSettings.data
      ? state.NotificationsSettings.notificationsSettings.data.message
      : []
  );

  useEffect(() => {
    if (status === "success") {
      toast.success("expert has been added successfully!", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
      onClose();
      setSelectedCategories([]);
      onReload();
    }
    if (error === "this user is an expert") {
      toast.error("Already there is an expert with this email", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
    }
  }, [status, error]);

  const validateForm = () => {
    let valid = true;
    if (!firstName) {
      setFirstNameError("First name is required!");
      valid = false;
    } else {
      setFirstNameError("");
    }
    if (!lastName) {
      setLastNameError("Last name is required!");
      valid = false;
    } else {
      setLastNameError("");
    }
    if (!email) {
      setEmailError("Email is required!");
      valid = false;
    } else if (!checkIsValidEmail(email)) {
      setEmailError("Invalid email!");
      valid = false;
    } else {
      setEmailError("");
    }
    if (!selectedCategories.length) {
      setSectorsError("You should select at least one sector!");
      valid = false;
    } else {
      setSectorsError("");
    }
    return valid;
  };
  const addExpert = async () => {
    if (!validateForm()) {
      return;
    }
    const selectedSectors = selectedCategories.map((i) => i.value);
    const DataExperts = {
      expertData: {
        email: email,
        lastName: lastName,
        firstName: firstName,
        sector: selectedSectors,
      },
      roles: notificationsSettings ? notificationsSettings.addExpert : null,
      currentUserId: currentUser ? currentUser._id : null,
      socket: socket,
    };
    await dispatch(addNewExpert(DataExperts)).unwrap();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader id="nvmh">Expert Informations</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction={"column"}>
            <label className="nvl">
              First Name
              <Input
                onChange={(e) => setFirstName(e.target.value)}
                className="nvi"
              />
            </label>
            {firstNameError && (
              <span style={{ color: "red" }}>{firstNameError}</span>
            )}
          </Flex>
          <Flex direction={"column"}>
            <label className="nvl">
              Last Name
              <Input
                className="nvi"
                onChange={(e) => setLastName(e.target.value)}
              />
            </label>
            {lastNameError && (
              <span style={{ color: "red" }}>{lastNameError}</span>
            )}
          </Flex>
          <Flex direction={"column"}>
            <label onChange={(e) => setEmail(e.target.value)} className="nvl">
              Email
              <Input className="nvi" />
            </label>
            {emailError && <span style={{ color: "red" }}>{emailError}</span>}
          </Flex>
          <Flex direction={"column"}>
            <Text className="nvl">Expertise Sectors</Text>
            <MySelect
              className="InputGroupy"
              placeholder="Select sector(s)"
              options={sectors}
              value={selectedCategories}
              onChange={setSelectedCategories}
              isMulti
            />
            {sectorsError && (
              <span style={{ color: "red" }}>{sectorsError}</span>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <BasicButton
            title={"Confirm"}
            onClick={addExpert}
            variant={"solid"}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
