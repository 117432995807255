import React from "react";
import { Divider, Heading, SimpleGrid, Center } from "@chakra-ui/react";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getPhasesByIdAssistingProgram } from "reducers/programPhase";
import { useState } from "react";
import PhasesItem from "./PhasesItem";
import Pagination from "components/pagination/Pagination";
import Loader from "components/loader/Loader";

export default function Phases(props) {
  const { id, userType } = props;
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const phase = useSelector((state) =>
    state.ProgramPhase.getPhasesByIdAssisting.data
      ? state.ProgramPhase.getPhasesByIdAssisting.data.phases
      : null
  );
  const status = useSelector(
    (state) => state.ProgramPhase.getPhasesByIdAssisting.status
  );
  const totalPages = useSelector((state) =>
    state.ProgramPhase.getPhasesByIdAssisting.data
      ? state.ProgramPhase.getPhasesByIdAssisting.data.totalPages
      : 0
  );

  const getPhasesByIdAssistingProgramAction = async () => {
    const payload = {
      limit: "6",
      page: page,
      id: id,
    };
    await dispatch(getPhasesByIdAssistingProgram(payload)).unwrap();
  };
  useEffect(() => {
    getPhasesByIdAssistingProgramAction();
  }, [id, page]);

  return (
    <>
      <Heading size="sm" mt="4" mb="2">
        Phases{" "}
      </Heading>
      <Divider mb="2" />
      {phase && phase.length > 0 && (
        <>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="2px">
            {phase.map((item, index) => (
              <PhasesItem
                voteSession={item}
                getPhasesByIdAssistingProgramAction={
                  getPhasesByIdAssistingProgramAction
                }
                userType={userType}
              ></PhasesItem>
            ))}
          </SimpleGrid>
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
        </>
      )}
      {phase && phase.length == 0 && <p> There is no phase yet</p>}
      {!phase && status === "loading" && <Loader />}
    </>
  );
}
