import React, { useState, useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getallProjects } from "../../../reducers/project";
import BasicButton from "components/button/BasicButton";
import ProjectFilters from "./components/allProjects/ProjectFilters";
import ProjectCardList from "./components/allProjects/ProjectCardList";
import { useHistory } from "react-router-dom";

export default function Projects() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userType, setUserType] = useState("");
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState("");
  const [confirmed, setConfirmed] = useState("");
  const [searchText, setSearchText] = useState("");

  const [reload, setReload] = useState(false);
  const projects = useSelector((state) => state.Project.projects.data.result);
  const totalPages = useSelector(
    (state) => state.Project.projects.data.totalPages
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );

  useEffect(() => {
    const filterData = {
      categories: categories == "Categories" ? "" : categories,
      name: searchText,

      isConfirmed: ["Admin", "SuperAdmin"].includes(userType)
        ? confirmed === ""
          ? ""
          : confirmed
        : true,
      page: page,
      limit:"8"
    };
    dispatch(getallProjects(filterData));
    setReload(false);
  }, [categories, confirmed, page, userType, reload, searchText]);

  useEffect(() => {
    if (currentUser) {
      setUserType(currentUser.userType);
    }
  }, [currentUser]);

  return (
    <Box pt={{ base: "180px", md: "90px", xl: "80px" }}>
      <ToastContainer />
      {(userType === "community" || userType === "ProjectHolder") && (
        <Flex justifyContent={"flex-end"}>
          <BasicButton
            title={"Create Project"}
            variant={"brand"}
            onClick={() => history.push("/admin/add-project")}
          />
        </Flex>
      )}
      <ProjectFilters
        userType={userType}
        setCategories={setCategories}
        setConfirmed={setConfirmed}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <ProjectCardList
        projects={projects}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        onReload={() => setReload(true)}
      />
    </Box>
  );
}
