import Api from "config/axios/api";
import config from "../config/axios/config";

export const getcoursesService = async () => {
  const url = config.apiUrl + `/moodle/courses/`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};


export default {
    getcoursesService
};
