import React, { useState, useEffect } from "react";
import {
  Text,
  Badge,
  Center,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { changeStatusUser } from "../../../../reducers/userInformation";
import { useDispatch } from "react-redux";
import { getallUsers } from "../../../../reducers/userInformation";

export default function UsersStatus({ user, onRelaod }) {
  const { _id, firstName, lastName, verified } = user;

  const [options, setOptions] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const toggleUserStatus = () => {
    dispatch(changeStatusUser(_id))
      .then((response) => {
        toast.success("Account status updated successfully", {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
        const filterdata = {
          page: 1,
          type: "",
        };
        dispatch(getallUsers(filterdata));
        onClose();
        onRelaod(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setOptions(
      verified
        ? {
            badgeText: "Active",
            badgeColor: "green",
            text: `${firstName} ${lastName} is active. Are you sure that you want to block them?`,
            buttonColor: "red",
            buttonText: "Block",
          }
        : {
            badgeText: "Not Active",
            badgeColor: "red",
            text: `${firstName} ${lastName} is not active. Do you want to active them?`,
            buttonColor: "green",
            buttonText: "active",
          }
    );
  }, [verified]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Account Status</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{options.text}</Text>
          </ModalBody>
          <ModalFooter>
            <Center>
              <Button mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={options.buttonColor}
                onClick={toggleUserStatus}
              >
                {options.buttonText}
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Badge
        colorScheme={options.badgeColor}
        h="25px"
        cursor="pointer"
        onClick={onOpen}
      >
        {options.badgeText}
      </Badge>
    </>
  );
}
