import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addNewExpertService } from "services/expertService";
import { RateExpertService } from "services/expertService";
import { bookExpertService } from "services/expertService";
import {
  getTopExpertsByRatingService,
  getAllExpertService,
} from "services/expertService";

export const getTopExpertsByRating = createAsyncThunk(
  "expert/getTopExpertsByRating",
  async (thunkAPI) => {
    try {
      const { status, data } = await getTopExpertsByRatingService();
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllExperts = createAsyncThunk(
  "expert/getAllExperts",
  async (filterdata, thunkAPI) => {
    try {
      const { status, data } = await getAllExpertService(filterdata);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addExpertRate = createAsyncThunk(
  "expert/addExpertRate",
  async (dataRating, thunkAPI) => {
    try {
      const { status, data } = await RateExpertService(dataRating);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const bookExpert = createAsyncThunk(
  "expert/book",
  async (bookData, thunkAPI) => {
    try {
      const { status, data } = await bookExpertService(bookData);
      if (status === 200) {
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addNewExpert = createAsyncThunk(
  "expert/add",
  async (DataExperts, thunkAPI) => {
    try {
      const { status, data } = await addNewExpertService(
        DataExperts.expertData
      );
      if (status === 200 || status === 201) {
        console.log(data);
        if (
          data &&
          data.expert &&
          DataExperts &&
          DataExperts.roles &&
          DataExperts.currentUserId
        ) {
          DataExperts.socket.emit("sendNotificationToAll", {
            type: "AddExpert",
            data: data.expert,
            roles: DataExperts.roles,
            sender: DataExperts.currentUserId,
          });
        }
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  topExpertsByRating: {
    status: "idle",
    data: {},
    error: {},
  },
  allExperts: {
    status: "idle",
    data: {},
    error: {},
  },
  bookExpert: {
    status: "idle",
    data: {},
    error: {},
  },
  addRate: {
    status: "idle",
    data: {},
    error: {},
  },
  addExpert: {
    status: "idle",
    data: {},
    error: {},
  },
};
const ExpertSlice = createSlice({
  name: "expert",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [addNewExpert.pending.type]: (state) => {
      state.addExpert = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addNewExpert.fulfilled.type]: (state, action) => {
      state.addExpert = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addNewExpert.rejected.type]: (state, action) => {
      state.addExpert = {
        status: "failed",
        data: {},
        error: action.payload.message ? action.payload.message : "",
      };
    },
    [getTopExpertsByRating.pending.type]: (state) => {
      state.topExpertsByRating = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getTopExpertsByRating.fulfilled.type]: (state, action) => {
      state.topExpertsByRating = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getTopExpertsByRating.rejected.type]: (state, action) => {
      state.topExpertsByRating = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addExpertRate.pending.type]: (state) => {
      state.addRate = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addExpertRate.fulfilled.type]: (state, action) => {
      state.addRate = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addExpertRate.rejected.type]: (state, action) => {
      state.addRate = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAllExperts.pending.type]: (state) => {
      state.allExperts = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllExperts.fulfilled.type]: (state, action) => {
      state.allExperts = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllExperts.rejected.type]: (state, action) => {
      state.allExperts = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [bookExpert.pending.type]: (state) => {
      state.bookExpert = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [bookExpert.fulfilled.type]: (state, action) => {
      state.bookExpert = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [bookExpert.rejected.type]: (state, action) => {
      state.bookExpert = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default ExpertSlice.reducer;
