import React from "react";
import {
  Text,
  Flex,
  Heading,
  Image,
  Box,
  SimpleGrid,
  Tooltip,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { FaPlus, FaTrash, FaUserFriends } from "react-icons/fa";
import placeholder from "assets/img/placeholder.jpg";
import Card from "components/card/Card";
import config from "config/axios/config";
import DeleteConfirmationModal from "components/modals/DeleteConfirmationModal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import AddPartnersModal from "../addAssistingProgram/AddPartnersModal";
import { useDispatch } from "react-redux";
import { update } from "reducers/assistingProgram";

export default function StackHolder(props) {
  const { organization, id, assistingProgram, onReload } = props;
  const [userType, setUserType] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const dispatch = useDispatch();

  const OnOpenAddPartnerModalHandler = async (rule) => {
    onOpenAdd();
  };
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
  }, [currentUser]);
  const removeIdFromArray = async (idToRemove) => {
    // Use filter to create a new array excluding the specified ID
    const newArray = organization.filter((item) => item._id !== idToRemove);
    const data = new FormData();
    data.append("partners", JSON.stringify(newArray));
    const programData = {
      data,
      id: id,
    };
    await dispatch(update(programData)).unwrap();
    onReload();
  };
  return (
    <>
      {organization && (
        <Flex direction={"column"} color={textColor}>
          <Flex align="center" mb="4">
            <Text fontSize="2xl" mr={2}>
              <FaUserFriends color="blue" />
            </Text>
            <Heading fontSize="2xl" color={textColor}>
              Partners
            </Heading>
          </Flex>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="2px">
            {organization &&
              organization.map((item) => (
                <Card p="10px" boxShadow={cardShadow}>
                  {(userType == "Admin" || userType == "SuperAdmin") && (
                    <Tooltip label="Delete partner">
                      <IconButton
                        icon={<FaTrash />}
                        size="sm"
                        colorScheme="red"
                        position="absolute"
                        right="2"
                        onClick={onOpenDelete}
                      />
                    </Tooltip>
                  )}
                  <Box m="4" p="4" align="center">
                    <Image
                      h="120px"
                      w="120px"
                      src={config.apiUrl + "/org/file/" + item.image}
                      borderRadius="8px"
                      fallbackSrc={placeholder}
                      objectFit="cover"
                    />
                    <Text fontSize="lg" fontWeight="bold">
                      {item.name}
                    </Text>
                  </Box>
                  <DeleteConfirmationModal
                    isOpen={isOpenDelete}
                    onClose={onCloseDelete}
                    headerContent="Confirm Deletion"
                    bodyContent={`Are you sure you want to delete this partner with the name <b>${item.name}</b>? This action cannot be undone.`}
                    handleDelete={() => removeIdFromArray(item._id)}
                  />
                </Card>
              ))}
              {userType === "Admin" &&
            <Card
              p="10px"
              boxShadow={cardShadow}
              _hover={{ backgroundColor: "#f0f0f0", cursor: "pointer" }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                onClick={() => OnOpenAddPartnerModalHandler()}
              >
                <Box>
                  <FaPlus size={30} color="#888" />
                </Box>
              </Box>
            </Card>}
            <AddPartnersModal
              isOpen={isOpenAdd}
              onClose={onCloseAdd}
              id={id}
              partners={organization}
              assistingProgram={assistingProgram}
              onReload={() => onReload(true)}
              />
          </SimpleGrid>
        </Flex>
      )}
    </>
  );
}
