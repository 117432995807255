import {
  Badge,
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { FaCalendar, FaEdit, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import moment from "moment";
import EditGeneralInfosForm from "../editProgramPhase/EditGeneralInfosForm";

export default function GeneralInformations(props) {
  const { onReload, isOwner } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const programPhase = useSelector(
    (state) => state.ProgramPhase.programPhaseDetails.data
  );

  return (
    <Flex direction={"column"}>
      <Flex direction={"column"}>
        <Flex justifyContent={"space-between"} mt="2">
          <Flex width="60%" direction={"column"}>
            <Text color={textColor} fontSize={"3xl"} mr="2" fontWeight={"bold"}>
              {programPhase.title}{" "}
            </Text>
            <Text fontSize={"l"} color={textColor} className="description">
              {programPhase.description}
            </Text>
          </Flex>
          <Flex
            direction={"column"}
            width="40%"
            ml="4"
            fontSize={"md"}
            height="max-content"
            position="relative"
          >
            {isOwner && (
              <Tooltip label="Edit Phase Details" hasArrow placement="top-end">
                <IconButton
                  icon={<FaEdit />}
                  size="sm"
                  colorScheme="blue"
                  position="absolute"
                  top="10px"
                  right="10px"
                  onClick={onOpen}
                />
              </Tooltip>
            )}
            <Box
              p={4}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              height="100%"
              backgroundColor="white"
              textAlign={"start"}
            >
              {programPhase.createdAt && (
                <>
                  <Flex direction="column">
                    <Flex
                      fontWeight={"700"}
                      direction="row"
                      alignItems={"center"}
                      color="#11047a"
                    >
                      <FaCalendar />
                      <Text ml="2" fontWeight={"bold"}>
                        Created At
                      </Text>
                    </Flex>
                    <Text fontWeight={"400"}>
                      {moment(programPhase.createdAt).format("llll")}
                    </Text>
                  </Flex>
                </>
              )}{" "}
              {programPhase.createdBy && (
                <Flex direction="column" mt="2">
                  <Flex
                    fontWeight={"700"}
                    direction="row"
                    alignItems={"center"}
                    color="#11047a"
                  >
                    <FaUser />
                    <Text ml="2" fontWeight={"bold"}>
                      Created By
                    </Text>
                  </Flex>
                  <Text fontWeight={"400"}>
                    {programPhase.createdBy.firstName}{" "}
                    {programPhase.createdBy.lastName}
                  </Text>
                </Flex>
              )}
              <Flex direction="column">
                <Flex
                  fontWeight={"700"}
                  direction="row"
                  alignItems={"center"}
                  color="#11047a"
                >
                  <FaCalendar />
                  <Text ml="2" fontWeight={"bold"}>
                    Start Date
                  </Text>
                </Flex>
                <Text fontWeight={"400"}>
                  {moment(programPhase.start).format("llll")}
                </Text>
              </Flex>
              <Flex direction="column">
                <Flex
                  fontWeight={"700"}
                  direction="row"
                  alignItems={"center"}
                  color="#11047a"
                >
                  <FaCalendar />
                  <Text ml="2" fontWeight={"bold"}>
                    End Date
                  </Text>
                </Flex>
                <Text fontWeight={"400"}>
                  {moment(programPhase.end).format("llll")}
                </Text>
              </Flex>
              <Flex direction="column">
                <Flex
                  fontWeight={"700"}
                  direction="row"
                  alignItems={"center"}
                  color="#11047a"
                >
                  <FaCalendar />
                  <Text ml="2" fontWeight={"bold"}>
                    Voting Start Date
                  </Text>
                </Flex>
                <Text fontWeight={"400"}>
                  {moment(programPhase.votingStartDate).format("llll")}
                </Text>
              </Flex>
              <Badge colorScheme="red" mt="2" fontWeight={"bold"}>
                Max {programPhase.maxSelectedProjects} projects
              </Badge>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <EditGeneralInfosForm
        isOpen={isOpen}
        onClose={onClose}
        initialData={programPhase}
        onReload={onReload}
      />
    </Flex>
  );
}
