import Api from "config/axios/api";
import config from "../config/axios/config";

export const addProjectService = async (data) => {
  const url = config.apiUrl + "/project/addProjectOpt";

  try {
    return await Api.post(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateProjectService = async (projectData) => {
  const url = config.apiUrl + "/project/" + projectData.id;
  try {
    return await Api.put(url, projectData.data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getTopProjectsByVoteService = async () => {
  const url = config.apiUrl + "/admin/topPorject";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getNumberProjectsByCategoryService = async () => {
  const url = config.apiUrl + "/admin/Projectbyindustry";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getVotesByCategoryService = async () => {
  const url = config.apiUrl + "/admin/numberVotebyCategory";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getallprojectService = async (filterData) => {
  const url = config.apiUrl + "/api/getAllProject";
  try {
    return await Api.get(url, {
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getProjectByUserService = async (data) => {
  const url = config.apiUrl + "/api/projectsByUser/" + data.id;
  try {
    return await Api.post(
      url,
      { isConfirmed: data.isConfirmed ? "true" : "" },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const updateStatusProject = async (projectId) => {
  const url = config.apiUrl + "/admin/confirmProjectAndUnc/" + projectId;

  try {
    return await Api.put(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getProjectByIdService = async (id) => {
  const url = config.apiUrl + "/project/" + id;
  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const RateProjectService = async (dataRating) => {
  const url = config.apiUrl + "/rt/ratproject/" + dataRating.idProject;
  try {
    return await Api.post(
      url,
      { rating: dataRating.rating },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const canJoinTeamService = async (email) => {
  const url = config.apiUrl + "/project/teamVerification/" + email;
  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const likeProjectService = async (id) => {
  const url = config.apiUrl + "/project/like/" + id;
  try {
    return await Api.put(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const acceptTeamInvitationByProjectService = async (token) => {
  const url = config.apiUrl + "/project/teamMemberInvitationAddProject/" + token;
  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const acceptTeamInvitationService = async (token) => {
  const url = config.apiUrl + "/hackathons/" + token + "/accept-invitation";
  try {
    return await Api.put(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default {
  RateProjectService,
  getTopProjectsByVoteService,
  getNumberProjectsByCategoryService,
  getVotesByCategoryService,
  getallprojectService,
  getProjectByUserService,
  addProjectService,
  getProjectByIdService,
  updateStatusProject,
  canJoinTeamService,
  likeProjectService,
  acceptTeamInvitationService,
  acceptTeamInvitationByProjectService,
};
