import React from "react";
import {
  Text,
  Flex,
  Divider,
  Box,
  HStack,
  VStack,
  IconButton,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { useSelector } from "react-redux";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import DeleteConfirmationModal from "components/modals/DeleteConfirmationModal";
import EditCriterionForm from "../editProgramPhase/EditCriterionForm";
import { useState } from "react";
import { update } from "reducers/programPhase";
import { useDispatch } from "react-redux";
import { FaPlus } from "react-icons/fa";
const MotionBox = motion(Box);

export default function SelectionCriteria(props) {
  const { isOwner, onReload } = props;
  const dispatch = useDispatch();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const programPhase = useSelector(
    (state) => state.ProgramPhase.programPhaseDetails.data
  );
  const [currentCriterion, setCurrentCriterion] = useState();
  const deleteCriterion = async () => {
    const selectionCriteria = programPhase.selectionCriteria.filter(
      (item) => item._id !== currentCriterion._id
    );
    const phaseData = {
      data: {
        ...programPhase,
        selectionCriteria: selectionCriteria,
      },
      id: programPhase._id,
    };
    await dispatch(update(phaseData)).unwrap();
    onCloseDelete();
    onReload();
  };

  const onOpenModalDelete = (item) => {
    onOpenDelete();
    setCurrentCriterion(item);
  };
  const onOpenModalEdit = (item) => {
    onOpen();
    setCurrentCriterion(item);
  };
  const onOpenModalAdd = (item) => {
    onOpen();
    setCurrentCriterion(null);
  };
  return (
    <Flex direction={"column"} color={textColor}>
      <Flex justifyContent={"space-between"} alignItems="center" mt="3">
        <Text fontWeight="bold">
          Selection criteria for {programPhase.title}
        </Text>
        <IconButton
          aria-label="Add"
          icon={<Icon as={FaPlus} />}
          colorScheme="blue"
          size="xs"
          onClick={onOpenModalAdd}
        />
      </Flex>
      <Divider my="2" />
      <Flex direction={"column"}>
        <VStack align="start" spacing={4}>
          {programPhase.selectionCriteria &&
            programPhase.selectionCriteria.map((criterion, index) => (
              <MotionBox
                key={index}
                p={4}
                borderRadius="lg"
                borderWidth="1px"
                boxShadow="md"
                w="100%"
              >
                <HStack justifyContent="space-between" w="100%">
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold">{criterion.title}</Text>
                    <Text>{criterion.description}</Text>
                  </VStack>
                  {isOwner && (
                    <HStack>
                      <IconButton
                        icon={<EditIcon />}
                        aria-label={`Edit ${criterion.title}`}
                        variant="ghost"
                        colorScheme="teal"
                        onClick={() => onOpenModalEdit(criterion)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        aria-label={`Delete ${criterion.title}`}
                        variant="ghost"
                        colorScheme="red"
                        onClick={() => onOpenModalDelete(criterion)}
                      />
                    </HStack>
                  )}
                </HStack>
              </MotionBox>
            ))}
        </VStack>
      </Flex>
      <DeleteConfirmationModal
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        headerContent="Confirm Deletion"
        bodyContent={`Are you sure you want to delete this criterion? This action cannot be undone.`}
        handleDelete={deleteCriterion}
      />
      <EditCriterionForm
        isOpen={isOpen}
        onClose={onClose}
        initialData={programPhase}
        criterion={currentCriterion}
        onReload={onReload}
      />
    </Flex>
  );
}
