import React, { useState, useEffect } from "react";
import { Select } from "@chakra-ui/react";
import { userTypeFilter } from "utils/constants";

const UserFilters = (props) => {
  const { setUserType, onRelaod, setPage } = props;

  const handleFilter = (type) => {
    setPage(1);
    setUserType(type);
    onRelaod();
  };

  return (
    <Select
      justifyContent={"end"}
      onChange={(ev) => {
        handleFilter(ev.target.value);
      }}
      w="385px"
      name="category"
      id="category"
      borderColor={"#E0E5F2"}
      backgroundColor="white"
    >
      {userTypeFilter.map((item, index) => (
        <option value={item.value}>{item.name}</option>
      ))}
    </Select>
  );
};

export default UserFilters;
