import React, { useState } from "react";
import { Box, Flex, Text, IconButton, Avatar } from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { ToastContainer } from "react-toastify";
import { BsDownload } from "react-icons/bs";
import { useEffect } from "react";

const allowedImageTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml",
];

const UploadBox = ({ allowedTypes, setFile, file, settf, show }) => {
  const [files, setFiles] = useState([]);
  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles.filter((file) => allowedTypes.includes(file.type)));
    setFile(acceptedFiles);
    settf(file);
  };

  const [isDragging, setIsDragging] = useState(false);
  const onDragEnter = () => {
    setIsDragging(true);
  };
  const onDragLeave = () => {
    setIsDragging(false);
  };
  return (
    <Dropzone
      onDragLeave={onDragLeave}
      onDragEnter={onDragEnter}
      onDrop={handleDrop}
      multiple={false}
      accept={allowedTypes}
    >
      {({ getRootProps, getInputProps }) => (
        <Box {...getRootProps()}>
          <input {...getInputProps()} />
          {files.length === 0 || !show ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <IconButton
                variant="outline"
                borderRadius={"100%"}
                icon={<BsDownload />}
                w="45px"
                h="45px"
              />
            </Flex>
          ) : (
            <>
              {show &&
                files.map((file) => (
                  <Avatar src={URL.createObjectURL(file)} w="45px" h="45px" />
                ))}
            </>
          )}
        </Box>
      )}
    </Dropzone>
  );
};

const UploadImageSpeakers = (props) => {
  const { f, ms, sf, show, setShow } = props;
  const [files, setFiles] = useState([]);
  useEffect(() => {
    setShow(false);
  }, []);
  const PuttingFiles = (f) => {
    setShow(true);
    setFiles([...files, ...f]);
    props.settingPhoto(f, props.index);
  };
  return (
    <Flex flexDirection="column">
      <ToastContainer />
      <Flex>
        <Flex flexDirection={"column"} mr="3">
          <Text color="#1B2559" fontWeight="700" fontSize="20px"></Text>
          <UploadBox
            setFile={PuttingFiles}
            settf={sf}
            file={files}
            allowedTypes={allowedImageTypes}
            show={show}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default UploadImageSpeakers;
