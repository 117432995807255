import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import { Text, Flex, Box, Image, Button, Center, Heading } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { Link as Linkk } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/pagination/Pagination";
import { getAcceptedProjectsByCohort } from "reducers/cohort";
import { getRequestsByCOS } from "reducers/callofStartups";

export default function AceptedRequest(props) {
  const [userType, setUserType] = useState("");
  const { id } = props;

  const dispatch = useDispatch();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const requestsAccepted = useSelector((state) =>
    state.CallofStartups.requests.data
      ? state.CallofStartups.requests.data.result
      : []
  );
  const getCOSRequestsAction = async () => {
    await dispatch(getRequestsByCOS(id)).unwrap();
  };
  useEffect(() => {
    getCOSRequestsAction();
  }, []);

  return (
 
    <Card p="2" direction="column" w="100%" mt="3">
      <Heading size="sm" mb="2">
        Project Accepted
      </Heading>
      {requestsAccepted && requestsAccepted.length > 0 && (
        <Box>
          {requestsAccepted.map((item) => (
            <>
              {item.status == "accepted"  && (
                <Card p="1" mb="2" boxShadow={cardShadow}>
                  <Flex>
                    <Flex
                      width="100%"
                      height="100%"
                      padding="0.6rem"
                      fontWeight="bold"
                      color={textColor}
                      fontSize="md"
                      textAlign="start"
                    >
                      <Image
                        h="50px"
                        w="50px"
                        src={
                          config.apiUrl + `/project/file/` + item.project.images
                        }
                        borderRadius="8px"
                        fallbackSrc={placeholder}
                        objectFit="cover"
                      />
                      <Box ml="3">
                        <Text fontWeight="normal" color="blue">
                          <Linkk to={"/admin/CallOfStartupDetails/" + item._id} >{item.project.title} </Linkk>
                        </Text>
                        <Text fontSize="sm">{item.status}</Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Card>
              )}
            </>
          ))}
        </Box>
      )}
      {requestsAccepted && requestsAccepted.length === 0 && (
        <Text>There is no project</Text>
      )}
    </Card>
  );
}
