import {
  Box,
  Flex,
  Icon,
  Text,
  Grid,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import "./Calendar.css";
import { ArrowForwardIcon, TimeIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAllEventCalander } from "reducers/event";
import { useSelector } from "react-redux";
import { getTimelineCalander } from "reducers/event";
import { addAvabilityExeprtinCalander } from "reducers/event";
import { getAllEventMeetings } from "reducers/meeting";
import jwt_decode from "jwt-decode";

const CalendarContainer = () => {
  const AvabilityCalander = useSelector((state) =>
    state.Event.avability.data ? state.Event.avability.data.success : []
  );
  const addAvabilityExpert = async (start, end) => {
    await dispatch(addAvabilityExeprtinCalander(start, end)).unwrap();
  };
  const dispatch = useDispatch();
  // const allEventC = useSelector(
  //   (state) => state.Event.allEventCalender.data.message
  // );
  const timeLineEvent = useSelector(
    (state) => state.Event.timeLineEvent.data.message
  );
  const allEventC = useSelector((state) =>
    state.Meeting.eventMeetings.data
      ? state.Meeting.eventMeetings.data
      : []
  );
  // const getAllEventCalanderAction = async () => {
  //   await dispatch(getAllEventCalander()).unwrap();
  // };
  const getTimelineCalanderAction = async () => {
    await dispatch(getTimelineCalander()).unwrap();
  };
  const getAllTypeEvents = async () => {
    const id = jwt_decode(localStorage.getItem("token"))._id;

    await dispatch(getAllEventMeetings(id)).unwrap();
  };
  useEffect(() => {
    // getAllEventCalanderAction();
    getTimelineCalanderAction();
    getAllTypeEvents();

    if (AvabilityCalander == true) {
      toast.success("availability added successfully", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
    }
  }, [AvabilityCalander]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer />
      <Flex className="calendarTimeline">
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          events={allEventC}
          onClick={(e) => console.log("click wlh")}
          selectable={
            JSON.parse(localStorage.getItem("CurrentUserData")).userType ===
            "Expert"
              ? true
              : false
          }
          select={(selectionInfo) => {
            if (
              JSON.parse(localStorage.getItem("CurrentUserData")).userType !==
              "Expert"
            ) {
              return;
            } else if (
              moment(moment(selectionInfo.start).format("llll")).isAfter(
                moment().format("llll")
              )
            ) {
              addAvabilityExpert({
                start: selectionInfo.start,
                end: selectionInfo.end,
              });
            }
          }}
        />
        <Box
          px="20px"
          py="22px"
          borderRadius={"30px"}
          backgroundColor={"white"}
          className="timeline"
        >
          <Text fontSize={"24px"} fontWeight={"700"} color="#1B2559">
            Timeline
          </Text>
          <Text fontSize={"16px"} fontWeight={"500"} color="#A3AED0">
            See your products in our timelines
          </Text>
          <Box>
            <>
              {" "}
              {timeLineEvent &&
                timeLineEvent.length > 0 &&
                timeLineEvent.map((timeline, index) => (
                  <Box width={"100%"} py="3">
                    <Flex
                      borderRadius={"20px"}
                      backgroundColor={"#3b82f6a6"}
                      color="white"
                      alignItems="center"
                    >
                      <Box
                        display={"flex"}
                        backgroundColor="#3b82f6"
                        borderRadius="15px"
                        py="0.5"
                        px="2.5"
                        m="1.5"
                        flexDirection={"column"}
                      >
                        <Text fontWeight={"500"} fontSize="15px">
                          {moment(timeline.start).format("YYYY-MM")}
                        </Text>
                        <Text fontWeight={"700"} fontSize="18px">
                          {moment(timeline.start).format("DD")}
                        </Text>
                      </Box>
                      <Flex direction={"column"}>
                        <Text fontWeight={"700"} fontSize="15px">
                          {timeline.title.split(" ").length > 3
                            ? timeline.title.split(" ").slice(0, 3).join(" ") +
                              " ..."
                            : timeline.title}
                        </Text>

                        <Text fontSize="12px">
                          <Icon as={TimeIcon} />{" "}
                          {moment(timeline.start).format("hh:mm")} -{" "}
                          {moment(timeline.end).format("hh:mm")}
                        </Text>
                      </Flex>
                      {/*<Icon
                          alignSelf={"end"}
                          as={ArrowForwardIcon}
                          mb="20px"
                  />*/}
                    </Flex>
                  </Box>
                ))}
              {timeLineEvent && timeLineEvent.length === 0 && (
                <Box
                  backgroundColor={"transparent"}
                  borderRadius="20px"
                  my="25px"
                  px="19px"
                  py="17"
                  width={"100%"}
                  display={"flex"}
                >
                  <Text textAlign="center">There is no Dates Available</Text>
                </Box>
              )}{" "}
            </>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default CalendarContainer;
