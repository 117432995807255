import React from "react";
import {
  Text,
  Flex,
  Heading,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import {
  FaCheck,
  FaEdit,
  FaExclamationTriangle,
  FaLaptop,
  FaLink,
  FaVideo,
} from "react-icons/fa";
import EditHackathonWhatToSubmit from "../editHackathon/EditHackathonWhatToSubmit";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function SubmissionGuide(props) {
  const { submitTypes, idhackathon, onReload } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userType, setUserType] = useState("");
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  useEffect(() => {
    if (currentUser) {
      setUserType(currentUser.userType);
    }
  }, [currentUser]);
  return (
    <Flex direction={"column"} color={textColor}>
      <EditHackathonWhatToSubmit
        isOpen={isOpen}
        onClose={onClose}
        initialData={submitTypes}
        idhackathon={idhackathon}
        onReload={onReload}
      />
      <Flex align="center" mb="4" justify="space-between">
        <Flex>
          <Text fontSize="2xl" mr={2}>
            <FaCheck color="green" />
          </Text>
          <Heading fontSize="2xl" color={textColor}>
            What to Submit
          </Heading>
        </Flex>
        {userType == "Admin" && (
          <IconButton
            icon={<FaEdit />}
            size="sm"
            mr="4px"
            colorScheme="blue"
            onClick={onOpen}
          />
        )}
      </Flex>
      <Text>
        At the end of the hackathon, participants are required to submit the
        following:
      </Text>
      {submitTypes.includes("projecturl") && (
        <Flex mt="3">
          <Text fontSize="xl" mr={2}>
            <FaLink color="blue" />
          </Text>

          <Text>
            Provide a URL for your working app and testing instructions for the
            judges to review and test your project.
          </Text>
        </Flex>
      )}
      {submitTypes.includes("presentation") && (
        <Flex mt="3">
          <Text fontSize="xl" mr={2}>
            <FaLaptop color="blue" />
          </Text>{" "}
          <Text>Provide a URL to your presentation.</Text>
        </Flex>
      )}
      {submitTypes.includes("demovideo") && (
        <Flex mt="3">
          <Text fontSize="xl" mr={2}>
            <FaVideo color="blue" />
          </Text>{" "}
          <Text>
            Provide a URL for your Demo Video app and testing instructions for
            the judges to review your project.
          </Text>
        </Flex>
      )}
      <Flex mt="3" color="gray.500">
        <Text fontSize="xl" mr={2}>
          <FaExclamationTriangle color="red" />
        </Text>{" "}
        <Text>
          Please note that the initial application should include only the
          project overview, which you can create in the project section on the
          platform.
        </Text>
      </Flex>
    </Flex>
  );
}
