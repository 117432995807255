import { Text, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import MeetingItem from "./MeetingItem";

export default function MeetingsHistoric(props) {
  const { meetings } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  return (
    <>
      {meetings && meetings.length > 0 && (
        <Card mb={{ base: "0px", "2xl": "20px" }} mt="3">
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Meetings
          </Text>
          <SimpleGrid
            columns={{ base: 1, lg: 2, xl: 2, md: 2 }}
            gap="20px"
            mb="20px"
            mt="20px"
          >
            {meetings.map((meeting, index) => {
              return <MeetingItem meeting={meeting} />;
            })}
          </SimpleGrid>
        </Card>
      )}
    </>
  );
}
