import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  useDisclosure,
  Heading,
  Divider,
  Center,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getAcceptedMembersByCohort } from "reducers/cohort";
import InviteMembersModal from "./InviteMembersModal";
import Participant from "./Participant";
import Pagination from "components/pagination/Pagination";
import MemberItem from "./MemberItem";
import TextCard from "components/card/TextCard";
import { getInterstedMembersForHackathon } from "reducers/hackathon";

export default function HackathonParticipants(props) {
  const { id, participants, page, setPage, totalPages } = props;
  const [currentUserType, setCurrentUserType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [memberId, setMemberId] = useState("");
  const [pageMembers, setPageMembers] = useState(1);
  const totalPagesMembers = useSelector((state) =>
    state.Hackathon.getInterested.data ? state.Hackathon.getInterested.data.totalPages : 0
  );
  const members = useSelector((state) =>
    state.Hackathon.getInterested.data
      ? state.Hackathon.getInterested.data.result
      : []
  );
  const totalCount = useSelector((state) =>
    state.Hackathon.getInterested.data
      ? state.Hackathon.getInterested.data.total
      : []
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const OnOpenandSetMembers = (id) => {
    onOpen();
    setMemberId(id);
  };
  const getAcceptedMembersByHackathonAction = async () => {
    const filterData = {
      id,
      page: pageMembers,
      limit: 6,
    };
    await dispatch(getInterstedMembersForHackathon(filterData)).unwrap();
  };

  useEffect(() => {
    getAcceptedMembersByHackathonAction();
  }, [id, pageMembers]);

  useEffect(() => {
    if (currentUser) {
      setCurrentUserType(currentUser.userType);
    }
  }, [currentUser]);

  return (
    <Card p="2" direction="column" w="100%">
      <Heading size="sm" mt="4" mb="2">
        {members && <> Members who want to join a project ({totalCount})</>}
      </Heading>
      <Divider mb="2" />
      <InviteMembersModal
        memberId={memberId}
        isOpen={isOpen}
        onClose={onClose}
        hackathonId={id}
      />
      {members && members.length > 0 && (
        <Box>
          {members.map((item, index) => (
            <MemberItem
              onInvit={() => OnOpenandSetMembers(item.member._id)}
              item={item.member}
              currentUserType={currentUserType}
            />
          ))}
        </Box>
      )}
      {totalPagesMembers > 1 && (
        <Center pt="5">
          <Pagination
            page={pageMembers}
            totalPages={totalPagesMembers}
            setPage={setPageMembers}
            setLoader={() => {}}
          />
        </Center>
      )}
      {members && members.length === 0 && (
        <TextCard text="There is no member" />
      )}
      <Heading size="sm" mt="4" mb="2">
        Members who participate in a project
      </Heading>
      <Divider mb="4" />
      {participants && participants.length > 0 && (
        <Box>
          {participants.map((item, index) => (
            <Participant
              item={item}
              nbProjects={item.projectIds.length}
            />
          ))}
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
        </Box>
      )}
      {participants && participants.length === 0 && (
        <TextCard text="There is no member" />
      )}
    </Card>
  );
}
