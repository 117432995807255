import Api from "config/axios/api";
import config from "../config/axios/config";
export const getCohortsService = async (filterData) => {
  const url = config.apiUrl + `/cohort/`;
  try {
    const headers = {};
    // If the user is logged in, include the authentication token in the headers
    if (localStorage.getItem("token")) {
      headers["Authorization"] = localStorage.getItem("token");
    }
    return await Api.get(url, {
      headers,
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const deleteCohortsService = async (id) => {
  const url = config.apiUrl + `/cohort/` + id;
  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const addCohortService = async (cohortData) => {
  const url = config.apiUrl + `/cohort/`;
  try {
    return await Api.post(url, cohortData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getCohortByIdService = async (id) => {
  const url = config.apiUrl + `/cohort/` + id;
  try {
    const headers = {};
    // If the user is logged in, include the authentication token in the headers
    if (localStorage.getItem("token")) {
      headers["Authorization"] = localStorage.getItem("token");
    }
    return await Api.get(url, {
      headers,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateCohortService = async (updateData) => {
  const url = config.apiUrl + `/cohort/` + updateData.id;
  try {
    return await Api.put(url, updateData.data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const applyForCohortService = async (dataApply) => {
  const url = config.apiUrl + `/cohort/apply/` + dataApply.id;
  try {
    return await Api.post(
      url,
      { projects: dataApply.projects, track: dataApply.track },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const applyForHackathonService = async (dataApply) => {
  const url = config.apiUrl + `/catm/`;
  try {
    return await Api.post(
      url,
      { cohort: dataApply.cohort },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAcceptedProjectsByCohortService = async (filterData) => {
  const url = config.apiUrl + `/cohort/applicationAccpected/` + filterData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAcceptedMembersByCohortService = async (filterData) => {
  const url =
    config.apiUrl +
    `/catm/${filterData.id}?page=${filterData.page}&limit=${filterData.limit}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getPendingProjectsByCohortService = async (filterData) => {
  const url = config.apiUrl + `/cohort/applicationPending/` + filterData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateCohortApplicationService = async (updateData) => {
  const url = config.apiUrl + `/cohort/application/` + updateData.id;
  try {
    return await Api.put(
      url,
      { status: updateData.status },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getProjectMembersHackathonService = async (filterData) => {
  const url = config.apiUrl + `/catm/inviterTeamInsideCohort/` + filterData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const addProjectMembersHackathonService = async (dataAdd) => {
  const url = config.apiUrl + `/catm/` + dataAdd.id + "/" + dataAdd.idproject;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const deleteMembersService = async (datamember) => {
  const url =
    config.apiUrl + `/catm/` + datamember.idproject + "/" + datamember.idmember;
  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const joinTeamMembersService = async (id) => {
  const url = config.apiUrl + `/requestJoinProject/` + id;
  try {
    return await Api.post(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getCohortsService,
  addCohortService,
  getCohortByIdService,
  applyForCohortService,
  getAcceptedProjectsByCohortService,
  getPendingProjectsByCohortService,
  updateCohortApplicationService,
  applyForHackathonService,
  getAcceptedMembersByCohortService,
  getProjectMembersHackathonService,
  addProjectMembersHackathonService,
  deleteMembersService,
  joinTeamMembersService,
};
