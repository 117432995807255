import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import { Text, Box, Flex, Checkbox, Avatar } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import config from "config/axios/config";

export default function ProjectItem(props) {
  const {
    project,
    selectedProject,
    setSelectedProject,
    setProject = null,
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [isChecked, setIsChecked] = useState(false);

  const toggleProject = (project) => {
    setSelectedProject(project._id);
    if (setProject) {
      setProject(project);
    }
  };

  useEffect(() => {
    setIsChecked(selectedProject === project._id);
  }, [selectedProject, project]);

  return (
    <Card p="1" mb="2" boxShadow={cardShadow}>
      <Flex>
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
        >
          <Avatar
            w="50px"
            h="50px"
            src={config.apiUrl + "/api/getfile/" + project.images}
          />
          <Box ml="3">
            <Text fontWeight="normal">{project.title}</Text>
            <Text fontSize="sm">{project.category}</Text>
          </Box>
        </Flex>
        <Checkbox
          me="16px"
          colorScheme="brandScheme"
          borderColor={"#3965ff"}
          onChange={() => toggleProject(project)}
          isChecked={isChecked}
        />
      </Flex>
    </Card>
  );
}
