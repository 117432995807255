import React, { useState, useEffect } from "react";
import { Circle } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export default function Pagination({ page, totalPages, setPage, setLoader ,color}) {
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const nextPage = async () => {
    if (!isLastPage) {
      setLoader(true);
      setPage(page + 1);
    }
  };

  const prevPage = async () => {
    if (!isFirstPage) {
      setLoader(true);
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setLoader(true);
    setPage(pageNumber);
  };

  const renderCircleOne = () => {
    const pageNumber = isFirstPage
      ? page
      : totalPages === 2
      ? page - 1
      : isLastPage
      ? page - 2
      : page - 1;

    return (
      <Circle
        size="35px"
        color={color}
        bg="white"
        mr="10px"
        fontWeight={page === pageNumber && "bold"}
        cursor={page !== pageNumber ? "pointer" : "default"}
        onClick={page !== pageNumber ? () => goToPage(pageNumber) : undefined}
      >
        {pageNumber}
      </Circle>
    );
  };

  const renderCircleTwo = () => {
    const pageNumber = isFirstPage
      ? page + 1
      : totalPages === 2
      ? page
      : isLastPage
      ? page - 1
      : page;

    return (
      totalPages > 1 && (
        <>
          <Circle
            size="35px"
            bg="white"
            mr="10px"
            color={color}
            fontWeight={page === pageNumber && "bold"}
            cursor={page !== pageNumber ? "pointer" : "default"}
            onClick={page !== pageNumber ? () => goToPage(pageNumber) : undefined}
          >
            {pageNumber}
          </Circle>
        </>
      )
    );
  };

  const renderCircleThree = () => {
    const pageNumber = isFirstPage ? page + 2 : isLastPage ? page : page + 1;
    return (
      totalPages > 2 && (
        <Circle
          size="35px"
          bg="white"
          mr="10px"
          color={color}
          fontWeight={page === pageNumber && "bold"}
          cursor={page !== pageNumber ? "pointer" : "default"}
          onClick={page !== pageNumber ? () => goToPage(pageNumber) : undefined}
        >
          {pageNumber}
        </Circle>
      )
    );
  };

  useEffect(() => {
    if (totalPages === 1) {
      setIsFirstPage(true);
      setIsLastPage(true);
    } else if (page === 1) {
      setIsFirstPage(true);
      setIsLastPage(false);
    } else if (page === totalPages) {
      setIsFirstPage(false);
      setIsLastPage(true);
    } else {
      setIsFirstPage(false);
      setIsLastPage(false);
    }
  }, [page, totalPages]);

  return (
    <>
      <Circle
        size="35px"
        bg={isFirstPage ? "gray.200" : "white"}
        cursor={isFirstPage ? "auto" : "pointer"}
        mr="10px"
        color={color}
        onClick={prevPage}
      >
        <ChevronLeftIcon />
      </Circle>
      {renderCircleOne()}
      {renderCircleTwo()}
      {renderCircleThree()}
      <Circle
        size="35px"
        bg={isLastPage ? "gray.200" : "white"}
        cursor={isLastPage ? "auto" : "pointer"}
        color={color}

        onClick={nextPage}
      >
        <ChevronRightIcon />
      </Circle>
    </>
  );
}
