import React, { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Grid,
  CloseButton,
  Alert,
  AlertIcon,
  Flex,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";

const SelectionCriteriaForm = (props) => {
  const { programPhaseData, setProgramPhaseData, activeStep, setActiveStep } =
    props;
  const [criteriaList, setCriteriaList] = useState(
    programPhaseData?.selectionCriteria
  );
  const [currentCriterion, setCurrentCriterion] = useState({
    title: "",
    description: "",
  });
  const [showAlert, setShowAlert] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCriterion((prevCriterion) => ({
      ...prevCriterion,
      [name]: value,
    }));
  };

  const handleAddCriterion = () => {
    if (
      currentCriterion.title.trim() !== "" &&
      currentCriterion.description.trim() !== ""
    ) {
      setCriteriaList((prevList) => [...prevList, currentCriterion]);
      setCurrentCriterion({ title: "", description: "" });
    }
  };

  const handleDeleteCriterion = (index) => {
    setCriteriaList((prevList) => prevList.filter((_, i) => i !== index));
  };
  const back = () => {
    setActiveStep(activeStep - 1);
    setShowAlert(false);
  };
  const confirm = async () => {
    if (!criteriaList.length) {
      setShowAlert(true);
      return;
    }
    setShowAlert(false);
    const data = {
      ...programPhaseData,
      selectionCriteria: criteriaList,
    };
    setProgramPhaseData(data);
    setActiveStep(activeStep + 1);
  };
  return (
    <Box p={6} borderWidth="1px" borderRadius="lg" width="100%" boxShadow="md">
      <Heading as="h2" size="lg" mb={4} textAlign="center">
        Selection Criteria
      </Heading>

      <FormControl>
        <FormLabel>
          Title <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          type="text"
          name="title"
          placeholder="Title"
          value={currentCriterion.title}
          onChange={handleInputChange}
        />
      </FormControl>

      <FormControl mt={4} mb={2}>
        <FormLabel>
          Description <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Textarea
          placeholder="Description"
          name="description"
          value={currentCriterion.description}
          onChange={handleInputChange}
        />
      </FormControl>
      <BasicButton
        onClick={handleAddCriterion}
        title={"Add Criterion"}
        variant="solid"
        size="sm"
      />

      <Grid
        mt={4}
        gap={4}
        templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
      >
        {criteriaList.map((criterion, index) => (
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            p={4}
            bg="white"
            boxShadow="md"
            position="relative"
          >
            <Heading as="h3" size="md" mb={2}>
              {criterion.title}
            </Heading>
            <p>{criterion.description}</p>
            <CloseButton
              size="sm"
              colorScheme="red"
              position="absolute"
              top="8px"
              right="8px"
              onClick={() => handleDeleteCriterion(index)}
            />
          </Box>
        ))}
      </Grid>
      {showAlert && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          You should add at least one criterion!
        </Alert>
      )}
      <Flex justifyContent={"space-between"} mt="4">
        <BasicButton
          onClick={back}
          title={"Previous"}
          variant="outline"
          size="md"
        />
        <BasicButton
          onClick={confirm}
          title={"Next"}
          variant="solid"
          size="md"
        />
      </Flex>
    </Box>
  );
};

export default SelectionCriteriaForm;
