import Card from "components/card/Card.js";
import { Text, Flex, Button, Heading, Divider } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsbyUser } from "reducers/project";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { applyOpportunity } from "reducers/callofStartups";
import { getRequestCallofstartupById } from "reducers/callofStartups";
import ProjectItem from "views/admin/projects/components/ProjectItem";

const Step1 = (props) => {
  const { applyData, setApplyData, setStep, setProject ,requestCall } = props;
  const { id } = useParams();
  const { idrequest } = useParams();
  var [selectedProject, setSelectedProject] = useState(
    requestCall ? requestCall.project._id : selectedProject
  );
  const dispatch = useDispatch();
  const projects = useSelector((state) =>
    state.Project.projects.data
      ? state.Project.projectsbyUser.data.message
      : null
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );

  const [error, setError] = useState("");

  useEffect(() => {
    if (requestCall) {
      setSelectedProject(
        requestCall ? requestCall.project._id : applyData.project
      );
      console.log(selectedProject, "selectedProject");
    }
    if (currentUser) {
      const getconfirmedProjects = {
        id: currentUser._id,
        isConfirmed: true,
      };
      dispatch(getProjectsbyUser(getconfirmedProjects));
    }
  }, [currentUser]);

  const confirm = async () => {
    
    // if (!requestCall.project._id) {
    //   setError("You should select a project");
    //   return;
    // }
    // if (!selectedProject) {
    //   setError("You should select a project");
    //   return;
    // }
    const Oppdata = new FormData();
    Oppdata.append("callOfStartup", id);
    Oppdata.append("project", requestCall ? requestCall.project._id : selectedProject);
    Oppdata.append("step", 2);

    const dataaddOpp = {
      Oppdata,
    };
    await dispatch(applyOpportunity(dataaddOpp)).unwrap();
    const data = {
      project: selectedProject,
    };
    setApplyData(data);
    setStep(2);
  };
  return (
    <Card>
      <Heading size="sm" mb="2">
        Choose a project
      </Heading>
      <Divider mb="2" />
      <Flex mt="2" direction={"column"}>
        {projects && projects.length > 0 && (
          <>
            {projects.map((item) => (
              <ProjectItem
                project={item}
                selectedProject={
                  requestCall ? requestCall.project._id : selectedProject
                }
                setSelectedProject={setSelectedProject}
                setProject={setProject}
              />
            ))}
          </>
        )}
        {error && <Text color="red">{error}</Text>}

        {projects && projects.length === 0 && (
          <Text>You should add a project to apply !</Text>
        )}
      </Flex>
      <Flex justifyContent="end" mt="4">
        <Button colorScheme="blue" size="md" onClick={confirm}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};

export default Step1;
