import Api from "config/axios/api";
import config from "../config/axios/config";
export const KonnectService = async (prix) => {
  const url = config.apiUrl + "/Konnect/KonnectAPI/";
  console.log(prix,'jajajajajajajaj')
  try {
    return await Api.post(url,prix);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const CheckoutService = async (payload) => {
  const url = config.apiUrl + "/Konnect/checkout/";
  try {
    return await Api.post(url,payload);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const historyPayment = async (payload) => {
  const url = config.apiUrl + "/Konnect/historyPayment/";
  try {
    return await Api.post(url,{"monthss":payload}, {
      headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const historymeet = async (payload) => {
  const url = config.apiUrl + "/Konnect/historymeet/";
  try {
    return await Api.post(url,{"monthss":payload.monthss,"page":payload.page}, {
      headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const balanceService = async () => {
  const url = config.apiUrl + "/Konnect/balance/";
  try {
    return await Api.get(url,{
      headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default { KonnectService,CheckoutService,historyPayment,historymeet,balanceService };
