/* eslint-disable */
import React, { useState } from "react";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { Spinner } from '@chakra-ui/react'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import logo from "assets/img/logo.png";

function TwoAuth() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  let history = useHistory();
  // Input state
  const [loader, setLoaderState] = useState(false)
  const [error, setError] = useState('')

  return (
    <DefaultAuth illustrationBackground={logo} image={logo}>
    {loader === true ? (
      <Flex width={'96'} my="auto" alignItems={'center'} justifyContent='center' >
        <Spinner />
      </Flex>
    ):(
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
          2-Step Verification
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            {
              error !== '' ? (error):
              (
              <>Enter your 2-Step Verification email code to unlock!
              <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='XX-XX-XX-XX'
              mb='24px'
              fontWeight='500'
              size='lg'
            />
              </>)
            }
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          
          <FormControl>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={
                () => {
                  localStorage.setItem('Verified', JSON.stringify(true))             
                  window.location.href = '/'
                }
              }
              >
              Submit
            </Button>
            <Text>Haven't received it? <>Resend a new code</> </Text>
          </FormControl>
        </Flex>
      </Flex>
    )}

    </DefaultAuth>
  );
}




export default TwoAuth;