import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Link,
  Badge,
  Image,
  Flex,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { KonnectReducer } from "reducers/Konnect";
import { useDispatch } from "react-redux";
import { Link as Linkk } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function FailPayment() {
  return (
    <Card mb="20px" mt="100px">
      <Text textAlign={"center"} fontSize="large" color={"purple"}>
        Fail Payment{" "}
      </Text>
      <Text textAlign={"center"}>
        Your payment has failed, please check your balance and try again later!
      </Text>
    </Card>
  );
}
