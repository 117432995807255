import React from "react";
import { SimpleGrid, Text, Box, Flex, Center, Select } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import ProjectCardVertical from "./ProjectCardVertical";
import Pagination from "components/pagination/Pagination";
import BasicButton from "components/button/BasicButton";
import { useSelector } from "react-redux";
import { getTracksByHackathon } from "reducers/track";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getHackathonTracksTitles } from "reducers/track";

const HackathonProjects = (props) => {
  const dispatch = useDispatch();
  const {
    hackathon,
    projects,
    showFilter = false,
    showSubmit = false,
    totalPages = 0,
    page = 0,
    setPage = () => {},
    title,
    setTitle,
    track,
    setTrack,
    onSearch,
    onReload,
    hackathonId = null,
  } = props;
  const tracks = useSelector((state) =>
    state.Track.tracksByHackathon.data
      ? state.Track.tracksByHackathon.data.result
      : []
  );
  const getTracksByHackathonAction = async () => {
    const data = {
      hackathonId: hackathonId,
    };
    await dispatch(getHackathonTracksTitles(data));
  };
  useEffect(() => {
    if (hackathonId) {
      getTracksByHackathonAction();
    }
    console.log(hackathon,'rrbr');
  }, [hackathonId]);
  return (
    <Flex direction="column" p="4">
      {projects && (
        <>
          {showFilter && (
            <Flex mb="4" alignItems={"center"}>
              <InputField
                label=""
                placeholder="Search by Title"
                value={title}
                setValue={setTitle}
                required={false}
              />
              {tracks && (
                <Select
                  onChange={(ev) => {
                    setTrack(ev.target.value);
                  }}
                  name="track"
                  id="track"
                  borderColor={"#E0E5F2"}
                  width="100%"
                  mr="2"
                  placeholder="Search By Track"
                  className="custom-select"
                  value={track}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      color: "gray.500", // Change the color to your desired color
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? "white" : "gray.500", // Change the color to your desired color
                      backgroundColor: state.isSelected ? "blue.500" : "white", // Change the background color when selected
                    }),
                  }}
                >
                  {tracks.map((item, index) => (
                    <option value={item.title} key={index}>
                      {item.title}
                    </option>
                  ))}
                </Select>
              )}
              <BasicButton
                style={{ width: "180px" }}
                title="Search"
                variant="solid"
                onClick={onSearch}
              />
            </Flex>
          )}
          {projects && projects.length > 0 && (
            <SimpleGrid columns={{ base: 1, md: 3 }} mt="2px" gap="20px">
              {projects.map((item, index) => (
                <ProjectCardVertical
                  hackathon={hackathon}
                  hackathonId={hackathonId}
                  project={item}
                  key={index}
                  track={item.track}
                  showSubmit={showSubmit}
                  onReload={onReload}
                  showRating={true}
                  isWinner={item.winner} // to replace with item.isWinner
                />
              ))}
            </SimpleGrid>
          )}
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
          {projects && projects.length == 0 && (
            <Box
              p={6}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              boxShadow="md"
              height="100%"
              backgroundColor="#9e9e9e14"
              textAlign={"center"}
            >
              <Text fontSize="lg" mb="3">
                There is no project
              </Text>
            </Box>
          )}
        </>
      )}
    </Flex>
  );
};

export default HackathonProjects;
