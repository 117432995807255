import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  Flex,
  Box,
  Image,
  Button,
  Center,
  Heading,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { Link as Linkk } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { ratingGrantsApplication } from "reducers/grants";
import jwt_decode from "jwt-decode";

export default function GrantApplication(props) {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("");

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const RatinggrantAplicationAction = async (idgrantapp, rating) => {
    var DataGrantApplication = {
      idgrantapp: idgrantapp,
      rating: rating,
    };
    await dispatch(ratingGrantsApplication(DataGrantApplication)).unwrap();
    window.location.reload(false);
  };
  useEffect(() => {
    const USER_TYPE = jwt_decode(localStorage.getItem("token")).userType;
    setUserType(USER_TYPE);
  }, []);

  return (
    <Card p="2" direction="column" w="100%" mt="3">
      <Heading size="sm" mb="2">
        Grants Applications
      </Heading>
      {props.grantsApp && props.grantsApp.length > 0 && (
        <Box>
          {props.grantsApp.map((item) => (
            <>
              <Card p="1" mb="2" boxShadow={cardShadow}>
                <Flex>
                  <Flex
                    width="100%"
                    height="100%"
                    padding="0.6rem"
                    fontWeight="bold"
                    color={textColor}
                    fontSize="md"
                    textAlign="start"
                  >
                    <Image
                      h="50px"
                      w="50px"
                      src={
                        config.apiUrl + `/project/file/` + item.project.images
                      }
                      borderRadius="8px"
                      fallbackSrc={placeholder}
                      objectFit="cover"
                    />
                    <Box ml="3">
                      <Text fontWeight="normal" color="blue">
                        <Linkk
                          to={"/admin/grantsApplicationdetails/" + item._id}
                        >
                          {item.project.title}{" "}
                        </Linkk>
                      </Text>
                      <Text fontSize="sm">
                        {" "}
                        {item.estimatedLaunchDate
                          ? moment(item.estimatedLaunchDate).format("llll")
                          : ""}
                      </Text>
                      {(userType == "Admin" || userType == "SuperAdmin") && (
                        <ReactStars
                          defaultState={5}
                          count={5}
                          onChange={(p) =>
                            RatinggrantAplicationAction(item._id, p)
                          }
                          size={20}
                          activeColor="#ffd700"
                          value={item.rating}
                          isHalf
                        />
                      )}
                    </Box>
                  </Flex>
                </Flex>
              </Card>
            </>
          ))}
        </Box>
      )}
      {props.grantsApp && props.grantsApp.length === 0 && (
        <Text>There is no grants applications</Text>
      )}
    </Card>
  );
}
