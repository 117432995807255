// const env = {
//     host:'localhost',
//     port: 3000,
//     http: "http://"
// }

const env = {
    host: "dibackend.darblockchain.io",
    port: 3000,
    http: "https://"
}

export default env;
