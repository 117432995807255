import React, { useState } from "react";
import {
  Box,
  Text,
  Badge,
  Center,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  useColorModeValue,
  Icon,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { getProjectsbyUser } from "reducers/project";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { BsFillStarFill } from "react-icons/bs";
import { Avatar as Avatarr } from "@chakra-ui/react";
import config from "config/axios/config";

export default function UserInformationModal({ user }) {
  const {
    _id,
    firstName,
    lastName,
    Rating,
    email,
    hourRate,
    userType,
    Image,
    sector,
  } = user;
  const [options, setOptions] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const ProjectbyUser = useSelector(
    (state) => state.Project.projectsbyUser.data.message
  );
  const toggleUserStatus = () => {
    onOpen();
    const getconfirmedProjects = {
      id: _id,
      isConfirmed: "",

    };
     dispatch(getProjectsbyUser(getconfirmedProjects));
  };
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  let content = [];
  for (var i = 0; i < Rating; i++) {
    content.push(
      <Icon
        as={BsFillStarFill}
        me="2px"
        width="13px"
        height="13px"
        color="#ffe234"
      />
    );
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>User Details</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              mb="2"
              display="flex"
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Avatarr
                cursor="pointer"
                h="120px"
                w="120px"
                border="4px solid"
                borderColor={borderColor}
                src={config.apiUrl + "/api/getFile/" + Image}
              />
              <Text
                cursor={"pointer"}
                color={textColorPrimary}
                fontWeight="bold"
                fontSize="md"
              >
                {firstName + " " + lastName}{" "}
              </Text>
              <Text>{email}</Text>
              <Text>{content}</Text>
            </Box>
            {userType == "Expert" && (
              <>
                <Flex flexDirection={"column"} mt="2" mb="2">
                  <Text
                    cursor={"pointer"}
                    color={textColorPrimary}
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    {sector ? sector : "No sector"}
                  </Text>
                  <Text color={textColorSecondary} fontSize="xs">
                    Sector
                  </Text>
                </Flex>
                <Divider />
                <Flex flexDirection={"column"} mt="2" mb="2">
                  <Text
                    cursor={"pointer"}
                    color={textColorPrimary}
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    {hourRate ? hourRate + " DT" : "No Price"}
                  </Text>
                  <Text color={textColorSecondary} fontSize="xs">
                    Hourly Price
                  </Text>
                </Flex>
                <Divider />
              </>
            )}
            <Flex flexDirection={"column"} mt="2" mb="2">
              <Text
                cursor={"pointer"}
                color={textColorPrimary}
                fontWeight="bold"
                fontSize="sm"
              >
                {userType}
              </Text>
              <Text color={textColorSecondary} fontSize="xs">
                User Type
              </Text>
            </Flex>
            <Divider />

            {ProjectbyUser &&
              ProjectbyUser.length > 0 &&
              userType == "ProjectHolder" && (
                <>
                  <Flex flexDirection={"column"} mt="2" mb="2">
                    <Text
                      cursor={"pointer"}
                      color={textColorPrimary}
                      fontWeight="bold"
                      fontSize="sm"
                    >
                      {ProjectbyUser[0].title}
                    </Text>
                    <Text color={textColorSecondary} fontSize="xs">
                      Project Title
                    </Text>
                  </Flex>{" "}
                  <Divider />
                </>
              )}
            {ProjectbyUser &&
              ProjectbyUser.length > 0 &&
              userType == "ProjectHolder" && (
                <>
                  <Flex flexDirection={"column"} mt="2" mb="2">
                    <Text
                      cursor={"pointer"}
                      color={textColorPrimary}
                      fontWeight="bold"
                      fontSize="sm"
                    >
                      {ProjectbyUser[0].category}
                    </Text>
                    <Text color={textColorSecondary} fontSize="xs">
                      Project category
                    </Text>
                  </Flex>{" "}
                  <Divider />
                </>
              )}
          </ModalBody>
          <ModalFooter>
            <Center>
              <Button mr={3} variant="action" size="sm" onClick={onClose}>
                Cancel
              </Button>
              <Button
                mr={3}
                colorScheme="blue"
                size="sm"
                onClick={() =>
                  (window.location.href = "/#/admin/expert/profile/" + _id)
                }
              >
                View Profile
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Badge
        colorScheme={options.badgeColor}
        h="25px"
        cursor="pointer"
        onClick={toggleUserStatus}
      >
        View
      </Badge>
    </>
  );
}
