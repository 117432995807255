import Api from "config/axios/api";
import config from "../config/axios/config";
export const getTopExpertsByRatingService = async () => {
  const url = config.apiUrl + "/admin/topExpert";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const addNewExpertService = async (expertData) => {
  const url = config.apiUrl + "/admin/add/expert";
  try {
    return await Api.post(url, expertData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAllExpertService = async (filterData) => {
  const url = config.apiUrl + "/api/getAllExpert";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const bookExpertService = async (data) => {
  const url = config.apiUrl + "/cm/book";
  try {
    return await Api.post(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const RateExpertService = async (dataRating) => {
  const url = config.apiUrl + "/rt/ratExpert/" + dataRating.idExpert;
  try {
    return await Api.post(
      url,
      { rating: dataRating.rating },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getTopExpertsByRatingService,
  getAllExpertService,
  bookExpertService,
  RateExpertService,
  addNewExpertService,
};
