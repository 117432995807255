import React, { useState, useEffect } from "react";
import { Text, Flex, Input, Textarea, FormLabel } from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import moment from "moment";
import { checkIsValidEmail } from "utils/functions";

const AssistingProgramsInfoForm = (props) => {
  const {
    ProgramAssistantData,
    setProgramAssistantData,
    activeStep,
    steps,
    setActiveStep,
  } = props;
  const [email, setEmail] = useState(ProgramAssistantData.managerEmail);
  const [emailError, setEmailError] = useState("");

  const [title, setTitle] = useState(ProgramAssistantData.title);
  const [description, setDescription] = useState(
    ProgramAssistantData.description
  );
  const [dates, setDates] = useState(
    ProgramAssistantData.start
      ? [
          moment(new Date(ProgramAssistantData.start)).format(
            "MM/DD/YYYY HH:mm"
          ),
          moment(new Date(ProgramAssistantData.end)).format("MM/DD/YYYY HH:mm"),
        ]
      : ""
  );
  const [enddateSubmission, setenddateSubmission] = useState(
    ProgramAssistantData.endDateSubmition
      ? [
          moment(new Date(ProgramAssistantData.endDateSubmition)).format(
            "MM/DD/YYYY HH:mm"
          ),
        ]
      : ""
  );
  const [enddateSubmissionerror, setenddateSubmissionerror] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [datesError, setDatesError] = useState("");
  const validateForm = () => {
    let valid = true;

    if (!title) {
      setTitleError("The title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("The description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!email) {
      setEmailError("Manager email is required!");
      valid = false;
    } else if (!checkIsValidEmail(email)) {
      setEmailError("Invalid email!");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!start || !end) {
      setDatesError("The start & end dates are required!");
      valid = false;
    } else {
      setDatesError("");
    }
    if (new Date(enddateSubmission) <= new Date(start)) {
      setenddateSubmissionerror(
        "The end date submission must be after the start date of the Assisting Program"
      );
      valid = false;
    } else {
      setenddateSubmissionerror("");
    }
    return valid;
  };

  const next = () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const data = {
      title: title,
      description: description,
      end: end,
      start: start,
      endDateSubmition: enddateSubmission,
      managerEmail: email,
      typeOfCohort: "ProgramAssistant",
    };
    setProgramAssistantData(data);
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [title, description, enddateSubmission, end, start, email, submitted]);
  return (
    <>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Title <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Input
          isInvalid={titleError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Title"
          borderRadius="5px"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {titleError && (
          <Text mt="3" color="red">
            {titleError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Description <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Textarea
          isInvalid={descriptionError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Description"
          borderRadius="5px"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {descriptionError && (
          <Text mt="3" color="red">
            {descriptionError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Start and End Dates <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <DatePicker
          inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
          format="MM/DD/YYYY HH:mm"
          range
          plugins={[
            <TimePicker position="bottom" hideSeconds={true} />,
            <DatePanel markFocused header="Start - End" />,
          ]}
          placeholder="Start & End Dates"
          id="dates"
          name="dates"
          value={dates}
          onChange={(ev) => {
            setDates(ev);
            if (ev[0]) {
              setStart(
                ev[0].year +
                  "/" +
                  (ev[0].month.index + 1).toString() +
                  "/" +
                  ev[0].day +
                  " " +
                  ev[0].hour +
                  ":" +
                  ev[0].minute
              );
            }

            if (ev[1]) {
              setEnd(
                ev[1].year +
                  "/" +
                  (ev[1].month.index + 1).toString() +
                  "/" +
                  ev[1].day +
                  " " +
                  ev[1].hour +
                  ":" +
                  ev[1].minute
              );
            }
          }}
          onClose={() => true}
        />
        {datesError && (
          <Text mt="3" color="red">
            {datesError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          End Date Submission <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <DatePicker
          inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
          format="MM/DD/YYYY HH:mm"
          plugins={[
            <TimePicker position="bottom" hideSeconds={true} />,

            <DatePanel markFocused header="Date Submission" />,
          ]}
          placeholder="End Date Submission"
          id="date"
          name="date"
          value={enddateSubmission[0]}
          onChange={(ev) =>
            setenddateSubmission(
              ev.year +
                "/" +
                (ev.month.index + 1).toString() +
                "/" +
                ev.day +
                " " +
                ev.hour +
                ":" +
                ev.minute
            )
          }

          // onClose={() => true}
        />
        {enddateSubmissionerror && (
          <Text mt="3" color="red">
            {enddateSubmissionerror}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Email Manager <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Input
          isInvalid={emailError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Email"
          borderRadius="5px"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && (
          <Text mt="3" color="red">
            {emailError}
          </Text>
        )}
      </Flex>
      {/* <Flex mt="2" direction={"column"}>
        <DatePicker
          inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
          format="MM/DD/YYYY HH:mm"
          plugins={[
            <TimePicker position="bottom" hideSeconds={true} />,

            <DatePanel markFocused header="Date Submission" />,
          ]}
          placeholder="End Date Submission"
          id="date"
          name="date"
          value={enddateSubmission[0]}
          onChange={(ev) =>
            setenddateSubmission(
              ev.year +
                "/" +
                (ev.month.index + 1).toString() +
                "/" +
                ev.day +
                " " +
                ev.hour +
                ":" +
                ev.minute
            )
          }

          // onClose={() => true}
        />
        {enddateSubmissionerror && (
          <Text mt="3" color="red">
            {enddateSubmissionerror}
          </Text>
        )}
      </Flex> */}
      <Flex justifyContent={"flex-end"} mt="3">
        {activeStep !== steps.length - 1 && (
          <BasicButton onClick={next} title={"Next"} variant="solid" />
        )}
      </Flex>
    </>
  );
};

export default AssistingProgramsInfoForm;
