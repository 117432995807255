import React from "react";
import {
  Text,
  Flex,
  Heading,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { FaEdit, FaGavel, FaPlus, FaTrash } from "react-icons/fa";
import EditJudgingCriteria from "../editHackathon/EditJudgingCriteria";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import DeleteConfirmationModal from "components/modals/DeleteConfirmationModal";
import { update } from "reducers/hackathon";

export default function JudgingCriteria(props) {
  const { hackathonJudgingCriteria, idhackathon, onReload } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userType, setUserType] = useState("");
  const [type, setType] = useState("");
  const [currentjudgingCriteriaToEdit, setCurrentjudgingCriteriaToEdit] =
    useState("");
  const [currentjudgingCriteriaToDelete, setCurrentToDeletejudgingCriteria] =
    useState("");
  const dispatch = useDispatch();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
  }, [currentUser]);
  const OnOpenModalDeleteHandler = async (judgingCriteria) => {
    onOpenDelete();
    setCurrentToDeletejudgingCriteria(judgingCriteria);
  };
  const OnOpenModalEditHandler = async (judgingCriteria) => {
    onOpen();
    setType("Edit");
    setCurrentjudgingCriteriaToEdit(judgingCriteria);
  };
  const OnOpenModalAddHandler = async (judgingCriteria) => {
    onOpen();
    setType("Add");
  };
  const handleRemovejudgingCriteria = async (judgingCriteriaToRemove) => {
    console.log(
      hackathonJudgingCriteria,
      currentjudgingCriteriaToDelete,
      "hackj"
    );
    const updatedJudgingCriteriaArray = hackathonJudgingCriteria.filter(
      (judgingCriteria) => judgingCriteria !== currentjudgingCriteriaToDelete
    );
    console.log(updatedJudgingCriteriaArray, "updatedJudgingCriteriaArray");
    const data = new FormData();
    data.append("judgingCriteria", JSON.stringify(updatedJudgingCriteriaArray));

    const programData = {
      data,
      id: idhackathon,
    };

    await dispatch(update(programData)).unwrap();
    onReload(false);
    onCloseDelete();
  };
  return (
    <Flex direction={"column"} color={textColor}>
      <EditJudgingCriteria
        isOpen={isOpen}
        onClose={onClose}
        criteria={hackathonJudgingCriteria}
        type={type}
        onReload={onReload}
        currentjudgingCriteriaToEdit={currentjudgingCriteriaToEdit}
        setCurrentjudgingCriteriaToEdit={setCurrentjudgingCriteriaToEdit}
      />
      <Flex align="center" mb="4" justify="space-between">
        <Flex>
          <Text fontSize="2xl" mr={2}>
            <FaGavel color="blue" />
          </Text>
          <Heading fontSize="2xl" color={textColor}>
            JUDGING CRITERIA
          </Heading>
        </Flex>
        {userType == "Admin" && (
          <IconButton
            icon={<FaPlus />}
            size="md"
            ml="2"
            variant="ghost"
            colorScheme="blue"
            onClick={OnOpenModalAddHandler}
          />
        )}
      </Flex>
      <Flex direction={"column"}>
        {hackathonJudgingCriteria &&
          hackathonJudgingCriteria.map((item) => (
            <>
              {" "}
              <Flex justifyContent={"space-between"}>
                <Text fontWeight={"bold"}>{item.title}</Text>
                {/* {(userType == "Admin" || userType == "SuperAdmin") && ( */}

                {userType == "Admin" && (
                  <Flex>
                    <IconButton
                      icon={<FaEdit />}
                      size="sm"
                      mr="4px"
                      colorScheme="blue"
                      onClick={() => OnOpenModalEditHandler(item)}
                    />
                    <IconButton
                      icon={<FaTrash />}
                      size="sm"
                      colorScheme="red"
                      onClick={() => OnOpenModalDeleteHandler(item)}
                    />
                  </Flex>
                )}
                {/* )} */}
              </Flex>
              <Text mb="3">{item.description}</Text>
            </>
          ))}
        {hackathonJudgingCriteria.length === 0 && <Text>there is no judging criteria</Text>}
      </Flex>
      <DeleteConfirmationModal
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        headerContent="Confirm Deletion"
        bodyContent={`Are you sure you want to delete this judging criteria ? This action cannot be undone.`}
        handleDelete={handleRemovejudgingCriteria}
      />
    </Flex>
  );
}
