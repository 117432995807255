export const sectors = [
  { value: "", label: "Sector" },
  { value: "Advertising", label: "Advertising" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Artificial Intelligence", label: "Artificial Intelligence" },
  { value: "Biotechnology", label: "Biotechnology" },
  { value: "Blockchain", label: "Blockchain" },
  { value: "Civic industry", label: "Civic industry" },
  { value: "Consumer Electronics", label: "Consumer Electronics" },
  { value: "Cybersecurity", label: "Cybersecurity" },
  { value: "Data Analytics", label: "Data Analytics" },
  { value: "E-commerce", label: "E-commerce" },
  { value: "Education", label: "Education" },
  { value: "Energy", label: "Energy" },
  { value: "Fashion", label: "Fashion" },
  { value: "Financial Services", label: "Financial Services" },
  { value: "Food and Beverage", label: "Food and Beverage" },
  { value: "Gaming", label: "Gaming" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Industry 4.0", label: "Industry 4.0" },
  { value: "Information Technology", label: "Information Technology" },
  { value: "IoT", label: "IoT" },
  { value: "Insurance", label: "Insurance" },
  { value: "Legal Services", label: "Legal Services" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Marketing", label: "Marketing" },
  { value: "Media and Entertainment", label: "Media and Entertainment" },
  { value: "NFTs", label: "NFTs" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Retail", label: "Retail" },
  { value: "Software", label: "Software" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Transportation", label: "Transportation" },
  { value: "Travel and Tourism", label: "Travel and Tourism" },
];
export const docs = [
  { value: "Business Proposal", label: "Business Proposal" },
  { value: "Legal Contract", label: "Legal Contract" },
  { value: "Invoice", label: "Invoice" },
  { value: "Business Plan", label: "Business Plan" },
  { value: "Sales Pitch", label: "Sales Pitch" },
  { value: "Marketing Plan", label: "Marketing Plan" },
];
export const funds = [
  { value: "", label: "--select an item-- " },
  { value: "Crypto Economy", label: "Crypto Economy" },
  {
    value: "Female Founders Market Development",
    label: "Female Founders Market Development",
  },
  { value: "Metaverse", label: "Metaverse" },
  { value: "Payments & Fintech", label: "Payments & Fintech" },
  { value: "Privacy Market Development", label: "Privacy Market Development" },
  { value: "Sustainable Impact", label: "Sustainable Impact" },
];
export const users = [
  { value: "", label: "--select an item--" },
  { value: "0", label: "0" },
  { value: "< 100", label: "< 100" },
  { value: "< 1000", label: "< 1000" },
  { value: "< 10,000", label: "< 10,000" },
  { value: "< 100,000", label: "< 100,000" },
  { value: "< 200,000", label: "< 200,000" },
  { value: "> 200,001", label: "> 200,001" },
];
export const team = [
  { value: "", label: "--select an item--" },
  { value: "1", label: "0" },
  { value: "< 5", label: "< 100" },
  { value: "< 10", label: "< 1000" },
  { value: "< 25", label: "< 10,000" },
  { value: "< 50", label: "< 100,000" },
  { value: "< 200", label: "< 200,000" },
  { value: "> 200", label: "> 200,001" },
];
export const excelMimeTypes = [
  "application/xls",
  "application/x-xls",
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const allowedImageTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml",
];
const allowedVideoTypes = [
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/x-msvideo",
];
export const acceptedMimeTypes = [
  ...allowedImageTypes,
  ...allowedVideoTypes,
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PowerPoint
  "application/msword",
];
export const tracks = [
  {
    name: "All Tracks",
  },
  {
    name: "Sustainability Track",
  },
  {
    name: "DeFi (Decentralized Finance) Track",
  },
  {
    name: "Governance Track",
  },
  {
    name: "NFT & metaverse Track",
  },
  {
    name: "Security Track",
  },
];
export const confirmedProjects = [
  {
    name: "any",
    value: "",
  },
  {
    name: "Is Confirmed",
    value: "true",
  },
  {
    name: "Not Confirmed",
    value: "false",
  },
];
export const expertFilters = [
  { value: "", name: "Hourly price & rate" },
  { value: "descH", name: "Highest hourly price" },
  { value: "ascH", name: "Lowest hourly price" },
  { value: "descR", name: "Highest rate" },
  { value: "ascR", name: "Lowest rate" },
];
export const faqList = [
  {
    question: "How to use the Academy platform?",
    answer:
      "Your account is automatically created when you sign up in the Ecosystem DAO. To access the Academy platform, navigate to the 'Academy' section. Log in to your account, and on the homepage, you can explore and participate in various courses. Additionally, you'll find a collection of helpful videos and courses to support your learning journey. Assess your understanding with quizzes, and if you encounter any challenges, engage with teachers through chat for assistance. The platform also features a calendar to keep track of upcoming events. Enhance collaboration by chatting with other students or creating study groups for a more interactive learning experience.",
  },
  {
    question: "How can I review an expert or review my meeting?",
    answer:
      "Navigate to the 'Historique Meeting' page, where you can view a list of all your meetings. For each meeting, you will find a 'Review' button. Clicking this button allows you to provide a review for the meeting, sharing your feedback and experience. Additionally, you can access the 'Pay Me' button to initiate payment to the expert for their services.",
  },
  {
    question: "How can I participate in an event?",
    answer:
      "To participate in an event, navigate to the 'Events' page where you can view all available events. Click on the specific event to see its details. With a simple click, you can choose to participate in the event directly. Your participation will be confirmed with this straightforward action.",
  },
  {
    question: "How can I apply for a Hackathon?",
    answer:
      "Once your project has been validated by the admin, proceed to the 'Hackathon' page and view the list of available Hackathons. If you find one that piques your interest, click the 'View' button for more details. You will find an 'Apply' button; click it to choose a project or multiple projects and select the track you wish to participate in. Upon completing the application successfully, your submission will be considered. Note that admin approval is necessary for your participation in the Hackathon.",
  },
  {
    question: "How can I apply for assisting programs?",
    answer:
      "Following the validation of your project by the admin, proceed to the 'Assisting Programs' page. Browse the list of available assisting programs, and if one aligns with your interests, click the 'View' button for more details. You will find an 'Apply' button; click it to select your project and complete additional required information. Once you complete the form, your application will be successfully submitted. Please note that admin approval is necessary to participate in assisting programs.",
  },
  {
    question: "How can I apply for a grant?",
    answer:
      "After your project is validated by the admin, navigate to the 'Grant' page. View the list of available grants, and if one catches your interest, click the 'View' button to learn more. You will find an 'Apply' button, enabling you to choose your project and fill in additional required data. Once you complete the form, your application will be successfully submitted. Note that admin approval is necessary to participate in the grant program.",
  },
  {
    question: "How can I book an expert?",
    answer:
      "After completing the creation of your project, navigate to the 'Expert' page. Filter experts based on their rates or your project budget. Once you find a suitable expert, view their profile, and you will find the 'Book Expert' button. A calendar will be displayed, allowing you to schedule a session based on the expert's availability.",
  },
  {
    question: "How can I add a project?",
    answer:
      "To initiate the project addition process, please access the dedicated 'Projects' page. Subsequently, select the 'Create Project' button and meticulously input all requisite project details. Upon completion, await administrative validation to ensure alignment with project guidelines and standards.",
  },
  {
    question: "How can I edit my profile?",
    answer:
      "To complete or update your profile, navigate to the 'Profile' page. Once there, select the 'Edit' button, and proceed to fill in all the required data for a comprehensive profile.",
  },
];
export const userTypeFilter = [
  { value: "", name: "All" },
  { value: "Expert", name: "Expert" },
  { value: "community", name: "Community Member" },
  { value: "ProjectHolder", name: "Project Holder" },
];
export const confirmedFilter = [
  {
    name: "any",
    value: "",
  },
  {
    name: "Is Confirmed",
    value: "true",
  },
  {
    name: "Not Confirmed",
    value: "false",
  },
];
export const whatToSubmit = [
  { value: "", label: "---" },
  { value: "projecturl", label: "Project Url" },
  { value: "demovideo", label: "Demo Video" },
  { value: "presentation", label: "Presentation" },
];
export const whatToSubmitOptions = [
  { value: "", label: "---" },
  { value: "link", label: "Link" },
  { value: "document", label: "Document" },
  { value: "image", label: "Image" },
  { value: "video", label: "Video" },
];
export const maxVideoFileSize = 1024 * 1024 * 25;
export const shareUrl = "https://di.darblockchain.africa:3003/#/admin/project/";
export const scrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
};
export const projectMaturityLevel = [
  { value: "", label: "Project Maturity" },
  { value: "Initial", label: "Initial" },
  { value: "Developing", label: "Developing" },
  { value: "Stable", label: "Stable" },
  { value: "Mature", label: "Mature" },
  { value: "Obsolete", label: "Obsolete" },

];