import { Text, Box, Center, Input } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Pagination from "components/pagination/Pagination";
import WideProjectCard from "./WideProjectCard";
import TextCard from "components/card/TextCard";

export default function ProjectsSelector(props) {
  const {
    projects,
    selectedProjects,
    setSelectedProjects,
    page = 1,
    setPage = () => {},
    error,
    totalPages = 0,
    multiple = true,
    showFilter = false,
    filterData,
    setFilterData,
  } = props;
  const [selectedProjectsIds, setSelectedProjectsIds] = useState([]);
  const toggleProject = (project) => {
    if (multiple) {
      const x = [...selectedProjects];
      let exist = false;
      x.map((item) => {
        if (item._id === project._id) {
          exist = true;
        }
      });
      if (exist) {
        const res = x.filter((item) => item._id !== project._id);
        setSelectedProjects(res);
      } else {
        x.push(project);
        setSelectedProjects(x);
      }
    } else {
      setSelectedProjects(project);
    }
  };
  useEffect(() => {
    if (multiple) {
      const arrayOfIds = selectedProjects.map((p) => p._id);
      setSelectedProjectsIds(arrayOfIds);
    } else {
      setSelectedProjectsIds(selectedProjects ? [selectedProjects._id] : []);
    }
  }, [selectedProjects]);
  return (
    <>
      {showFilter && (
        <Input
          variant="outline"
          value={filterData.name}
          onChange={(e) => {
            setFilterData({ ...filterData, name: e.target.value });
          }}
          placeholder="Name"
        />
      )}
      {projects && projects.length > 0 && (
        <Box>
          {projects.map((item) => (
            <WideProjectCard
              project={item}
              isChecked={selectedProjectsIds.includes(item._id)}
              toggleProject={() => toggleProject(item)}
              showCheckbox={true}
              key={item._id}
            />
          ))}
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
          {error && (
            <Text mt="3" color="red">
              {error}
            </Text>
          )}
        </Box>
      )}
      {projects?.length === 0 && (
        <Box mb={"30px"} mt={"30px"}>
          <TextCard text="There is no project" subText={""} />
        </Box>
      )}
    </>
  );
}
