// // Chakra imports
import {
  Text,
  Box,
  Flex,
  Avatar,
  HStack,
  Button,
  Input,
  Textarea,
  InputGroup,
  InputLeftElement,
  Heading,
  Divider,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwitchField from "components/fields/SwitchField";
import { addEvent } from "reducers/event";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import { checkIsValidLinkedinUrl } from "utils/functions";
import { checkIsValidSiteWeb } from "utils/functions";
import { checkIsValidEmail } from "utils/functions";
import { validatePhoneNumber } from "utils/functions";
import { BsX } from "react-icons/bs";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useContext } from "react";
import { SocketContext } from "contexts/SocketContext";
import { checkIsValidDiscordUrl } from "utils/functions";
import { useHistory } from "react-router-dom";
import UploadComponent from "../UploadFile";
import UploadImageSpeakers from "../UploadImageSpeakers";
import { edittheevent } from "reducers/event";

export default function EditEventImage(props) {
  const { event } = props;

  const [photo, setPhoto] = useState("");
  const [photoError, setPhotoError] = useState("");

  const validateForm = () => {
    let inValid = false;

    if (!photo) {
      setPhotoError("The image is required!");
      inValid = true;
    } else {
      setPhotoError("");
    }
    return inValid;
  };
  // const dispatch = useDispatch();

  const updateEventAction = async () => {
    // const inValid = validateForm();
    // if (inValid) {
    //   return;
    // }
    // const data = new FormData();
    // data.append("discord", event.discord);
    // data.append("website", event.website);
    // data.append("linkedin", event.linkedin);
    // data.append("contact", event.contact);
    // data.append("phone", event.phone);
    // const eventData = {
    //   data,
    //   id:event._id
    // };
    // console.log("last")
    // await dispatch(edittheevent(eventData)).unwrap();
  };

  const Upfiles = (file) => {
    setPhoto(file);
  };
  return (
    <Box>
      <Card>
        <Flex justifyContent="center">
          <UploadComponent setFiles={Upfiles} />
        </Flex>

        <Flex justifyContent="center" mt="4">
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => updateEventAction()}
          >
            Submit
          </Button>
        </Flex>
      </Card>
    </Box>
  );
}
