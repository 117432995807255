import React from "react";
import { Flex, Select, HStack, Input } from "@chakra-ui/react";
import Card from "components/card/Card";
import { confirmedFilter, sectors } from "utils/constants";

export default function ProjectFilters(props) {
  const { userType, setCategories, setConfirmed , setSearchText , searchText} = props;
  return (
    <Flex justifyContent={"space-between"} mb="4" mt="4">
      <Card>
        <HStack spacing={2}>
          <Input
            variant="outline"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Project title"
          />
          <Select
            justifyContent={"end"}
            onChange={(ev) => {
              setCategories(ev.target.value);
            }}
            name="category"
            id="category"
            borderColor={"#E0E5F2"}
          >
            {sectors.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </Select>
          {(userType === "Admin" || userType === "SuperAdmin") && (
            <Select
              ml="5"
              justifyContent={"end"}
              onChange={(ev) => {
                setConfirmed(ev.target.value);
              }}
              name="confirmed"
              id="confirmed"
              borderColor={"#E0E5F2"}
            >
              {confirmedFilter.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </Select>
          )}
        </HStack>
      </Card>
    </Flex>
  );
}
