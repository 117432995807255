import React, { useState, useEffect } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import SwitchField from "components/fields/SwitchField";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsSettings } from "reducers/notificationsSettings";
import { updateNotificationsSettings } from "reducers/notificationsSettings";

const NotificationsSettings = () => {
  const dispatch = useDispatch();
  const notificationsSettings = useSelector((state) =>
    state.NotificationsSettings.notificationsSettings.data
      ? state.NotificationsSettings.notificationsSettings.data.message
      : []
  );
  const [addingExpert, setAddingExpert] = useState([]);
  const [addingMember, setAddingMember] = useState([]);
  const [addingEvent, setAddingEvent] = useState([]);
  const [newsAnnouncement, setNewsAnnouncement] = useState([]);
  const [addProject, setAddProject] = useState([]);
  const [newCohorte, setNewCohorte] = useState([]);

  useEffect(() => {
    if (notificationsSettings) {
      setAddingExpert(notificationsSettings.addExpert);
      setAddingMember(notificationsSettings.AddingMember);
      setAddingEvent(notificationsSettings.AddingEvent);
      setNewsAnnouncement(notificationsSettings.NewsAnnouncement);
      setAddProject(notificationsSettings.activateProject);
      setNewCohorte(notificationsSettings.AddingNewCohorte);
    }
  }, [notificationsSettings]);

  const getNotificationsSettingsAction = async () => {
    await dispatch(getNotificationsSettings()).unwrap();
  };
  const updateNotificationsSettingsAction = async (settingsData) => {
    await dispatch(updateNotificationsSettings(settingsData)).unwrap();
  };

  useEffect(() => {
    getNotificationsSettingsAction();
  }, []);

  const onChangeAddingExpert = (role) => {
    const x = addingExpert.length > 0 ? [...addingExpert] : [];
    let addExpertSetting;
    if (x.length > 0 && x.includes(role)) {
      addExpertSetting = x.filter((item) => item !== role);
    } else {
      x.push(role);
      addExpertSetting = x;
    }
    setAddingExpert(addExpertSetting);
    const settingsData = {
      addExpert: addExpertSetting,
      AddingEvent: addingEvent,
      AddingMember: addingMember,
      NewsAnnouncement: newsAnnouncement,
      activateProject: addProject,
      AddingNewCohorte: newCohorte,
    };
    updateNotificationsSettingsAction(settingsData);
  };
  const onChangeAddingMember = (role) => {
    const x = addingMember.length > 0 ? [...addingMember] : [];
    let addMemberSetting;
    if (x.length > 0 && x.includes(role)) {
      addMemberSetting = x.filter((item) => item !== role);
    } else {
      x.push(role);
      addMemberSetting = x;
    }
    setAddingMember(addMemberSetting);
    const settingsData = {
      addExpert: addingExpert,
      AddingEvent: addingEvent,
      AddingMember: addMemberSetting,
      NewsAnnouncement: newsAnnouncement,
      activateProject: addProject,
      AddingNewCohorte: newCohorte,
    };
    updateNotificationsSettingsAction(settingsData);
  };
  const onChangeAddingEvent = (role) => {
    const x = addingEvent.length > 0 ? [...addingEvent] : [];
    let addEventSetting;
    if (x.length > 0 && x.includes(role)) {
      addEventSetting = x.filter((item) => item !== role);
    } else {
      x.push(role);
      addEventSetting = x;
    }
    setAddingEvent(addEventSetting);
    const settingsData = {
      addExpert: addingExpert,
      AddingEvent: addEventSetting,
      AddingMember: addingMember,
      NewsAnnouncement: newsAnnouncement,
      activateProject: addProject,
      AddingNewCohorte: newCohorte,
    };
    updateNotificationsSettingsAction(settingsData);
  };
  const onChangeNewsAnnouncement = (role) => {
    const x = newsAnnouncement.length > 0 ? [...newsAnnouncement] : [];
    let addNewsSetting;
    if (x.length > 0 && x.includes(role)) {
      addNewsSetting = x.filter((item) => item !== role);
    } else {
      x.push(role);
      addNewsSetting = x;
    }
    setNewsAnnouncement(addNewsSetting);
    const settingsData = {
      addExpert: addingExpert,
      AddingEvent: addingEvent,
      AddingMember: addingMember,
      NewsAnnouncement: addNewsSetting,
      activateProject: addProject,
      AddingNewCohorte: newCohorte,
    };
    updateNotificationsSettingsAction(settingsData);
  };
  const onChangeActivateProject = (role) => {
    const x = addProject.length > 0 ? [...addProject] : [];
    let addProjectSetting;
    if (x.length > 0 && x.includes(role)) {
      addProjectSetting = x.filter((item) => item !== role);
    } else {
      x.push(role);
      addProjectSetting = x;
    }
    setAddProject(addProjectSetting);
    const settingsData = {
      addExpert: addingExpert,
      AddingEvent: addingEvent,
      AddingMember: addingMember,
      NewsAnnouncement: newsAnnouncement,
      activateProject: addProjectSetting,
      AddingNewCohorte: newCohorte,
    };
    updateNotificationsSettingsAction(settingsData);
  };
  const onChangeAddingNewCohorte = (role) => {
    const x = newCohorte.length > 0 ? [...newCohorte] : [];
    let newCohorteSetting;
    if (x.length > 0 && x.includes(role)) {
      newCohorteSetting = x.filter((item) => item !== role);
    } else {
      x.push(role);
      newCohorteSetting = x;
    }
    setNewCohorte(newCohorteSetting);
    const settingsData = {
      addExpert: addingExpert,
      AddingEvent: addingEvent,
      AddingMember: addingMember,
      NewsAnnouncement: newsAnnouncement,
      activateProject: addProject,
      AddingNewCohorte: newCohorteSetting,
    };
    updateNotificationsSettingsAction(settingsData);
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        {" "}
        <Tabs>
          <TabList>
            <Tab>Admin</Tab>
            <Tab>Expert</Tab>
            <Tab>Community Member</Tab>
          </TabList>

          <TabPanels>
            <TabPanel pt="30px">
              <div>
                <SwitchField
                  isChecked={
                    addingExpert.length > 0 && addingExpert.includes("Admin")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="1"
                  label="Adding expert"
                  onChange={() => onChangeAddingExpert("Admin")}
                />
                <SwitchField
                  isChecked={
                    addingMember.length > 0 && addingMember.includes("Admin")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="2"
                  label="Adding Member"
                  onChange={() => onChangeAddingMember("Admin")}
                />
                <SwitchField
                  isChecked={
                    addingEvent.length > 0 && addingEvent.includes("Admin")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="3"
                  label="Adding Event"
                  onChange={() => onChangeAddingEvent("Admin")}
                />
                <SwitchField
                  isChecked={
                    newsAnnouncement.length > 0 &&
                    newsAnnouncement.includes("Admin")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="4"
                  label="News & Announcement"
                  onChange={() => onChangeNewsAnnouncement("Admin")}
                />
                <SwitchField
                  isChecked={
                    addProject.length > 0 && addProject.includes("Admin")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="5"
                  label="Activate Project"
                  onChange={() => onChangeActivateProject("Admin")}
                />
                <SwitchField
                  isChecked={
                    newCohorte.length > 0 && newCohorte.includes("Admin")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="6"
                  label="Adding New Cohorte"
                  onChange={() => onChangeAddingNewCohorte("Admin")}
                />
              </div>
            </TabPanel>
            <TabPanel pt="30px">
              <div>
                <SwitchField
                  isChecked={
                    addingExpert.length > 0 && addingExpert.includes("Expert")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="1"
                  label="Adding expert"
                  onChange={() => onChangeAddingExpert("Expert")}
                />
                <SwitchField
                  isChecked={
                    addingMember.length > 0 && addingMember.includes("Expert")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="2"
                  label="Adding Member"
                  onChange={() => onChangeAddingMember("Expert")}
                />
                <SwitchField
                  isChecked={
                    addingEvent.length > 0 && addingEvent.includes("Expert")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="3"
                  label="Adding Event"
                  onChange={() => onChangeAddingEvent("Expert")}
                />
                <SwitchField
                  isChecked={
                    newsAnnouncement.length > 0 &&
                    newsAnnouncement.includes("Expert")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="4"
                  label="News & Announcement"
                  onChange={() => onChangeNewsAnnouncement("Expert")}
                />
                <SwitchField
                  isChecked={
                    addProject.length > 0 && addProject.includes("Expert")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="5"
                  label="Activate Project"
                  onChange={() => onChangeActivateProject("Expert")}
                />
                <SwitchField
                  isChecked={
                    newCohorte.length > 0 && newCohorte.includes("Expert")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="6"
                  label="Adding New Cohorte"
                  onChange={() => onChangeAddingNewCohorte("Expert")}
                />
              </div>
            </TabPanel>
            <TabPanel pt="30px">
              <div>
                <SwitchField
                  isChecked={
                    addingExpert.length > 0 &&
                    addingExpert.includes("community")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="1"
                  label="Adding expert"
                  onChange={() => onChangeAddingExpert("community")}
                />
                <SwitchField
                  isChecked={
                    addingMember.length > 0 &&
                    addingMember.includes("community")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="2"
                  label="Adding Member"
                  onChange={() => onChangeAddingMember("community")}
                />
                <SwitchField
                  isChecked={
                    addingEvent.length > 0 && addingEvent.includes("community")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="3"
                  label="Adding Event"
                  onChange={() => onChangeAddingEvent("community")}
                />
                <SwitchField
                  isChecked={
                    newsAnnouncement.length > 0 &&
                    newsAnnouncement.includes("community")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="4"
                  label="News & Announcement"
                  onChange={() => onChangeNewsAnnouncement("community")}
                />
                <SwitchField
                  isChecked={
                    addProject.length > 0 && addProject.includes("community")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="5"
                  label="Activate Project"
                  onChange={() => onChangeActivateProject("community")}
                />
                <SwitchField
                  isChecked={
                    newCohorte.length > 0 && newCohorte.includes("community")
                  }
                  reversed={true}
                  fontSize="sm"
                  mb="20px"
                  id="6"
                  label="Adding New Cohorte"
                  onChange={() => onChangeAddingNewCohorte("community")}
                />
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
};

export default NotificationsSettings;
