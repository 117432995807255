import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  Stack,
  Image,
  Heading,
  Divider,
  ButtonGroup,
  TagLabel,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react";
import { Link as Linkk } from "react-router-dom";
import {
  CheckCircleIcon,
  NotAllowedIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import config from "config/axios/config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { participateToEvent } from "reducers/event";
import Card from "components/card/Card";
import { NumToTime } from "utils/functions";
import placeholder from "assets/img/placeholder.jpg";
import { FacebookShareButton, FacebookIcon } from "react-share";
import { TwitterShareButton, TwitterIcon } from "react-share";
import { deleteEvent } from "reducers/event";
const EventItem = (props) => {
  const { event, getEvents, onChange = () => {}, onReload } = props;
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "none"
  );
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");
  const status = useSelector((state) => state.Event.participateToEvent.status);

  const participateToEventAction = async () => {
    await dispatch(participateToEvent(event._id)).unwrap();
    // toast.success("Participation updated!", {
    //   position: "top-right",
    //   hideProgressBar: true,
    //   theme: "light",
    // });
    onReload();
  };
  const deleteEventAction = async () => {
    await dispatch(deleteEvent(event._id)).unwrap();
    onReload();
  };
  useEffect(() => {
    setUserId(jwt_decode(localStorage.getItem("token"))._id);
    setUserType(jwt_decode(localStorage.getItem("token")).userType);
  });

  return (
    <Card boxShadow={shadow} maxW="100%" p="15px">
      <Stack>
        <Box>
          <Image
            boxSize="150px"
            width="100%"
            objectFit="cover"
            src={config.apiUrl + "/events/getImage/" + event.image}
            alt=""
            borderRadius="lg"
            fallbackSrc={placeholder}
          />
          <Button
            position="absolute"
            bg="white"
            _hover={{ bg: "whiteAlpha.900" }}
            _active={{ bg: "white" }}
            _focus={{ bg: "white" }}
            p="0px !important"
            top="20px"
            right="17px"
            borderRadius="50%"
            minW="36px"
            h="36px"
          >
            <FacebookShareButton
              url={
                "https://di.darblockchain.africa:3003/#/admin/event" + event._id
              }
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </Button>
          <Button
            position="absolute"
            bg="white"
            _hover={{ bg: "whiteAlpha.900" }}
            _active={{ bg: "white" }}
            _focus={{ bg: "white" }}
            p="0px !important"
            top="20px"
            right="55px"
            borderRadius="50%"
            minW="36px"
            h="36px"
          >
            <TwitterShareButton
              url={
                "https://di.darblockchain.africa:3003/#/admin/event" + event._id
              }
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </Button>
        </Box>
        <Stack mt="6" spacing="3">
          <Heading size="md">
            {event.title}{" "}
            {new Date(event.start) < new Date() &&
              new Date(event.end) > new Date() && (
                <Tag size={"sm"} colorScheme="green" ml="8px" my="4px">
                  <CheckCircleIcon mr="6px" />
                  <TagLabel>Live</TagLabel>
                </Tag>
              )}
          </Heading>
          <Text className="threelines">{event.description}</Text>
          <Text color="blue.600" fontSize="md" w="min-content">
            <Flex>
              <Text fontWeight={"500"}>{` ${new Date(
                event.start
              ).toLocaleDateString([], {
                day: "2-digit",
                month: "2-digit",
              })}`}</Text>
              <Text mx="4px" color="#868DA6">
                {"•"}
              </Text>
              <Text color="#868DA6">{`${new Date(
                event.start
              ).toLocaleDateString([], {
                weekday: "short",
              })}`}</Text>
              <Text mx="4px" color="#868DA6">
                {"•"}
              </Text>
              <Text color="#868DA6">{`${new Date(
                event.start
              ).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}`}</Text>
            </Flex>
          </Text>
        </Stack>
      </Stack>
      <Divider />
      <Stack mt="2">
        <ButtonGroup spacing="2" justifyContent={"flex-end"}>
          {(userType === "Admin" || userType === "SuperAdmin") && (
            <Button
              variant="solid"
              size="sm"
              colorScheme="red"
              onClick={() => deleteEventAction()}
            >
              Delete Event
            </Button>
          )}
          <Button variant="solid" colorScheme="blue" size="sm">
            <Linkk to={"/admin/event/" + event._id}>View event</Linkk>
          </Button>
          {userType !== "Admin" &&
            userType !== "SuperAdmin" &&
            event.participants &&
            !event.participants.some(
              (participant) => participant._id === userId
            ) && (
              <Button
                size="sm"
                leftIcon={<CheckCircleIcon />}
                backgroundColor={"#F4F7FE"}
                color="#0000FF"
                onClick={() => {
                  participateToEventAction();
                }}
                disabled={new Date(event.end) < new Date()}
              >
                Participate
              </Button>
            )}

          {userType !== "Admin" &&
            userType !== "SuperAdmin" &&
            event.participants &&
            event.participants.length > 0 &&
            event.participants.some(
              (participant) => participant._id === userId
            ) && (
              <Menu>
                <MenuButton
                  size="sm"
                  as={Button}
                  aria-label="Options"
                  leftIcon={<CheckCircleIcon />}
                  rightIcon={<ChevronDownIcon />}
                  colorScheme="green"
                  disabled={new Date(event.end) < new Date()}
                >
                  Participate
                </MenuButton>
                <MenuList>
                  <MenuItem
                    size="sm"
                    icon={<NotAllowedIcon />}
                    onClick={() => {
                      participateToEventAction();
                    }}
                  >
                    Not Interested
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
        </ButtonGroup>
      </Stack>
    </Card>
  );
};

export default EventItem;
