import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Link,
  Badge,
  Flex,
} from "@chakra-ui/react";
import moment from "moment";
import calculateDiffBetweenTwoDates from "utils/functions";

export default function WorkshopItem(props) {
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const { item } = props;
  const [duration, setDuration] = useState("");
  const startDate = new Date(item.start);
  const endDate = new Date(item.end);
  const currentDate = new Date();
  useEffect(() => {
    if (item && item.start && item.end) {
      const x = calculateDiffBetweenTwoDates(item.start, item.end);
      setDuration(x);
    }
    return () => {
      setDuration("");
    };
  }, [item]);
  return (
    <Card p="20px" cursor="pointer" bg={"white"}>
      {item.status === "pending" && (
        <Badge mb="10px" colorScheme="yellow" textAlign={"center"}>
          {item.status}
        </Badge>
      )}
      {item.status === "accepted" && (
        <Badge mb="10px" colorScheme="green" textAlign={"center"}>
          {item.status}
        </Badge>
      )}
      {item.status === "rejected" && (
        <Badge mb="10px" colorScheme="red" textAlign={"center"}>
          {item.status}
        </Badge>
      )}
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Topic : {item.title ? item.title : ""}
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Project :{" "}
        {item.projects.map((project) => (
          <Badge className="description" colorScheme="blue" ml="2px" p={"1px"}>
            {project.title}
          </Badge>
        ))}
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Expert :{" "}
        <Link fontWeight="500" color={brandColor} href={""} fontSize="sm">
          {item.expert && item.expert.lastName && item.expert.firstName
            ? item.expert.firstName + " " + item.expert.lastName
            : ""}
        </Link>
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Start Date : {item.start ? moment(item.start).format("llll") : ""}
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Duration : {item.start && item.end ? duration : ""}
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        Price : {item.price ? item.price : 0} HBAR
      </Text>
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm" me="4px">
        <Flex direction="column">
          <Flex justify="space-between" alignItems={"center"}>
            {currentDate >= startDate && currentDate <= endDate && (
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                Status :{" "}
                <Badge className="description" colorScheme="green" mb="5px">
                  Open
                </Badge>
              </Text>
            )}
            {currentDate < startDate && (
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                Status :{" "}
                <Badge className="description" colorScheme="purple" mb="5px">
                  Upcoming
                </Badge>
              </Text>
            )}
            {currentDate > endDate && (
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                Status :{" "}
                <Badge className="description" colorScheme="red" mb="5px">
                  Closed
                </Badge>
              </Text>
            )}
          </Flex>
        </Flex>
      </Text>
      {item.online && (
        <Text
          fontWeight="500"
          color={textColorSecondary}
          fontSize="sm"
          me="4px"
        >
          Link :{" "}
          <Link
            fontWeight="500"
            color={brandColor}
            href={item.onlineUrl ? item.onlineUrl : ""}
            target={"_blank"}
            fontSize="sm"
          >
            Click here
          </Link>
        </Text>
      )}
      {!item.online && (
        <Text
          fontWeight="500"
          color={textColorSecondary}
          fontSize="sm"
          me="4px"
        >
          Location : {item.location}
        </Text>
      )}
    </Card>
  );
}
