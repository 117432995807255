import Api from "config/axios/api";
import config from "../config/axios/config";

export const addGrantsService = async (grant) => {
  const url = config.apiUrl + `/grant/`;
  try {
    return await Api.post(url, grant, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getAllGrantsService = async (grantsData) => {
  const url = config.apiUrl + `/grant/all/`;
  try {
    return await Api.post(url, grantsData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getperIdGrantsService = async (grantsData) => {
  const url = config.apiUrl + `/grant/getGrantById/` + grantsData;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const AcceptedRequestTeamService = async (grantsData) => {
  const url =
    config.apiUrl + `/requestJoinProject/acceptRequestToJoin/` + grantsData;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getProjectInGrantsService = async (idgrant) => {
  const url = config.apiUrl + `/grantsApplication/` + idgrant;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const AddgrantsApplicationService = async (grantsData) => {
  const url = config.apiUrl + `/grantsApplication/`;
  try {
    return await Api.post(url, grantsData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getGrantsApplicationService = async (idgrantapp) => {
  const url = config.apiUrl + `/grantsApplication/getbyId/` + idgrantapp;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const ratingGrantsApplicationService = async (dataGrants) => {
  const url =
    config.apiUrl + `/ratingGrantApplication/` + dataGrants.idgrantapp;
  try {
    return await Api.post(
      url,
      { rating: dataGrants.rating },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const deleteGrantService = async (id) => {
  const url = config.apiUrl + `/grant/deleteGrant/` + id;
  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateGrantsService = async (grant) => {
  const url = config.apiUrl + `/grant/updateGrant/` + grant.id ;
  try {
    return await Api.put(url, grant.data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  addGrantsService,
  getAllGrantsService,
  getperIdGrantsService,
  AcceptedRequestTeamService,
  getProjectInGrantsService,
  AddgrantsApplicationService,
  getGrantsApplicationService,
  ratingGrantsApplicationService,
  deleteGrantService,
  updateGrantsService
};
