import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  FormLabel,
  FormControl,
  Textarea,
  Flex,
  Text,
  Heading,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import { ToastContainer } from "react-toastify";
import { funds } from "utils/constants";
import { users } from "utils/constants";
import { team } from "utils/constants";

const Step3 = (props) => {
  const { grantsdata, setgrantdata, setStep } = props;
  const [teamSize, setTeamSize] = useState("");
  const [teamExperience, setTeamExperience] = useState("");
  const [teamSizeerror, setTeamSizeerror] = useState("");
  const [teamExperienceerror, setTeamExperienceerror] = useState("");


  const validForm = () => {
    let valid = true;
    const date = new Date();
    if (!teamSize) {
      setTeamSizeerror("Team Size is required!");
      valid = false;
    } else {
      setTeamSizeerror("");
    }
    if (!teamExperience) {
      setTeamExperienceerror("Team Experience is required!");
      valid = false;
    } else {
      setTeamExperienceerror("");
    }
   
    return valid;
  };

  const confirm = async () => {
    if (!validForm()) {
      return;
    }
    const data = {
      id:grantsdata.id,
      project: grantsdata.project,
      fund:grantsdata.fund,
      proposalSentence: grantsdata.proposalSentence,
      proposalSummary:grantsdata.proposalSummary,
      choiceToBuildOnHedera: grantsdata.choiceToBuildOnHedera,
      customerCountQuestion: grantsdata.customerCountQuestion,
      competitors: grantsdata.competitors,
      teamSize: teamSize,
      teamExperience:teamExperience,
      startedStatus: "",
      developmentTimeline: "",
      estimatedLaunchDate: "",
      fundingRequestedHBAR: 0,
      allocatedTowards: "",
      codeRepository: "",
      previousFunding: "",
      fromWhere: "",
      hearAboutUs: "",
      video: "",
      pdfPresentation: "",
    };
    setgrantdata(data);

    console.log(grantsdata,'gogogogigigiggogogo')
    setStep(4);
  };
  const back = () => {
    setStep(2);
  };
  return (
    <Card>
        <Box >
        <ToastContainer />
          <Card>
            <Heading size="sm" mb="2">
              Teams Informations
            </Heading>
            <Divider mb="2" />
            <Flex mt="2" direction={"column"}>
              <Text>Team Size*?</Text>
                <Select
                  
                  onChange={(ev) => {
                    setTeamSize(ev.target.value);
                  }}
                  name="Team"
                  id="Team"
                  borderColor={"#E0E5F2"}
                  width="100%"
                >
                  {team.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </Select>
                {teamSizeerror && (
              <Text color="red">{teamSizeerror}</Text>
            )}
              </Flex>
            <Flex mt="2" direction={"column"}>
              <Textarea
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Team Experience*"
                borderRadius="5px"
                value={teamExperience}
                onChange={(e) => setTeamExperience(e.target.value)}
              />
                {teamExperienceerror && (
              <Text color="red">{teamExperienceerror}</Text>
            )}
            </Flex>
          </Card>
          <Flex justifyContent="end" mt="4">
            <Button
              colorScheme="blue"
              variant="outline"
              size="md"
              mr="2"
              onClick={back}
            >
              Back
            </Button>
            <Button colorScheme="blue" size="md" onClick={confirm}>
              Next
            </Button>
          </Flex>
      </Box>
      </Card>
  );
};

export default Step3;
