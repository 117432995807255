import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteAssistingProgramService,
  updateService,
} from "services/assistingProgramService";
import { applyForAssistingProgramService } from "services/assistingProgramService";
import { updateAssistingProgramApplicationService } from "services/assistingProgramService";
import { getAcceptedProjectsInAssistingProgramsService } from "services/assistingProgramService";
import { getAvailablePartnersInAssistingProgramByIdService } from "services/assistingProgramService";
import { getRequestInAssistingProgramsService } from "services/assistingProgramService";
import { getAssistingProgramPartnersService } from "services/assistingProgramService";
import { getUserProjectInAssistingProgramsService } from "services/assistingProgramService";
import { addAssistingProgramService } from "services/assistingProgramService";
import { getAssistingProgramByIdService } from "services/assistingProgramService";
import { getAllAssistingProgramService } from "services/assistingProgramService";

export const getallAssistingPrograms = createAsyncThunk(
  "AssistingProgram/getAllAssistingProgramService",
  async (Data, thunkAPI) => {
    try {
      const { status, data } = await getAllAssistingProgramService(Data);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getDetailsAssistingPrograms = createAsyncThunk(
  "AssistingProgram/getDetailsAssistingPrograms",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getAssistingProgramByIdService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addAssistingPrograms = createAsyncThunk(
  "AssistingProgram/addAssistingPrograms",
  async (assistingProgramData, thunkAPI) => {
    try {
      const { status, data } = await addAssistingProgramService(
        assistingProgramData.assistingProgram
      );
      if (status === 200 || status === 201) {
        // if (
        //   data &&
        //   data.cohort &&
        //   cohortData &&
        //   cohortData.roles &&
        //   cohortData.currentUserId
        // ) {
        //   cohortData.socket.emit("sendNotificationToAll", {
        //     type: "AddCohort",
        //     data: data.cohort,
        //     roles: cohortData.roles,
        //     sender: cohortData.currentUserId,
        //   });
        // }
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteAssistingProgram = createAsyncThunk(
  "AssistingProgram/deleteAssistingProgram",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await deleteAssistingProgramService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const update = createAsyncThunk(
  "AssistingProgram/update",
  async (programData, thunkAPI) => {
    try {
      const { status, data } = await updateService(programData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAssistingProgramPartners = createAsyncThunk(
  "AssistingProgram/partners",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getAssistingProgramPartnersService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const applyForAssistingProgram = createAsyncThunk(
  "AssistingProgram/applyForAssistingProgram",
  async (dataApply, thunkAPI) => {
    try {
      const { status, data } = await applyForAssistingProgramService(dataApply);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getUserProjectInAssistingPrograms = createAsyncThunk(
  "AssistingProgram/getUserProjectInAssistingPrograms",
  async (dataApply, thunkAPI) => {
    try {
      const { status, data } = await getUserProjectInAssistingProgramsService(
        dataApply
      );
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getRequestInAssistingPrograms = createAsyncThunk(
  "AssistingProgram/getRequestInAssistingPrograms",
  async (dataRequest, thunkAPI) => {
    try {
      const { status, data } = await getRequestInAssistingProgramsService(
        dataRequest
      );
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateAssistingProgramApplication = createAsyncThunk(
  "assistingProgram/update-application",
  async (updateData, thunkAPI) => {
    try {
      const { status, data } = await updateAssistingProgramApplicationService(
        updateData
      );
      if (status === 201 || status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAcceptedProjectsInAssistingPrograms = createAsyncThunk(
  "AssistingProgram/getAcceptedProjectsInAssistingPrograms",
  async (dataProjects, thunkAPI) => {
    try {
      const { status, data } =
        await getAcceptedProjectsInAssistingProgramsService(dataProjects);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAvailablePartnersInAssistingProgramById = createAsyncThunk(
  "AssistingProgram/getAvailablePartners",
  async (idAssisting, thunkAPI) => {
    try {
      const { status, data } =
        await getAvailablePartnersInAssistingProgramByIdService(idAssisting);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  getAssistingPrograms: {
    status: "idle",
    data: {},
    error: {},
  },
  getAssistingProgramsById: {
    status: "idle",
    data: {},
    error: {},
  },
  addAssistingProgram: {
    status: "idle",
    data: {},
    error: {},
  },
  deleteAssisting: {
    status: "idle",
    data: {},
    error: {},
  },
  assistingProgramPartners: {
    status: "idle",
    data: {},
    error: {},
  },
  applyAssisting: {
    status: "idle",
    data: {},
    error: {},
  },
  getProjectAssistingPrograms: {
    status: "idle",
    data: {},
    error: {},
  },
  getRequest: {
    status: "idle",
    data: {},
    error: {},
  },
  updateApplication: {
    status: "idle",
    data: {},
    error: {},
  },
  acceptedProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  getAvailablePartners: {
    status: "idle",
    data: {},
    error: {},
  },
  update: {
    status: "idle",
    data: {},
    error: {},
  },
};

const AssistingProgramsSlice = createSlice({
  name: "AssistingProgram",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getAvailablePartnersInAssistingProgramById.pending.type]: (state) => {
      state.getAvailablePartners = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAvailablePartnersInAssistingProgramById.fulfilled.type]: (
      state,
      action
    ) => {
      state.getAvailablePartners = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAvailablePartnersInAssistingProgramById.rejected.type]: (
      state,
      action
    ) => {
      state.getAvailablePartners = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getallAssistingPrograms.pending.type]: (state) => {
      state.getAssistingPrograms = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getallAssistingPrograms.fulfilled.type]: (state, action) => {
      state.getAssistingPrograms = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getallAssistingPrograms.rejected.type]: (state, action) => {
      state.getAssistingPrograms = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getDetailsAssistingPrograms.pending.type]: (state) => {
      state.getAssistingProgramsById = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getDetailsAssistingPrograms.fulfilled.type]: (state, action) => {
      state.getAssistingProgramsById = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getDetailsAssistingPrograms.rejected.type]: (state, action) => {
      state.getAssistingProgramsById = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addAssistingPrograms.pending.type]: (state) => {
      state.addAssistingProgram = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addAssistingPrograms.fulfilled.type]: (state, action) => {
      state.addAssistingProgram = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addAssistingPrograms.rejected.type]: (state, action) => {
      state.addAssistingProgram = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAssistingProgramPartners.pending.type]: (state) => {
      state.assistingProgramPartners = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAssistingProgramPartners.fulfilled.type]: (state, action) => {
      state.assistingProgramPartners = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAssistingProgramPartners.rejected.type]: (state, action) => {
      state.assistingProgramPartners = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [deleteAssistingProgram.pending.type]: (state) => {
      state.deleteAssisting = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deleteAssistingProgram.fulfilled.type]: (state, action) => {
      state.deleteAssisting = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [deleteAssistingProgram.rejected.type]: (state, action) => {
      state.deleteAssisting = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [applyForAssistingProgram.pending.type]: (state) => {
      state.applyAssisting = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [applyForAssistingProgram.fulfilled.type]: (state, action) => {
      state.applyAssisting = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [applyForAssistingProgram.rejected.type]: (state, action) => {
      state.applyAssisting = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getUserProjectInAssistingPrograms.pending.type]: (state) => {
      state.getProjectAssistingPrograms = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getUserProjectInAssistingPrograms.fulfilled.type]: (state, action) => {
      state.getProjectAssistingPrograms = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getUserProjectInAssistingPrograms.rejected.type]: (state, action) => {
      state.getProjectAssistingPrograms = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getRequestInAssistingPrograms.pending.type]: (state) => {
      state.getRequest = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getRequestInAssistingPrograms.fulfilled.type]: (state, action) => {
      state.getRequest = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getRequestInAssistingPrograms.rejected.type]: (state, action) => {
      state.getRequest = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateAssistingProgramApplication.pending.type]: (state) => {
      state.updateApplication = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateAssistingProgramApplication.fulfilled.type]: (state, action) => {
      state.updateApplication = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateAssistingProgramApplication.rejected.type]: (state, action) => {
      state.updateApplication = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAcceptedProjectsInAssistingPrograms.pending.type]: (state) => {
      state.acceptedProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAcceptedProjectsInAssistingPrograms.fulfilled.type]: (
      state,
      action
    ) => {
      state.acceptedProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAcceptedProjectsInAssistingPrograms.rejected.type]: (state, action) => {
      state.acceptedProjects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [update.pending.type]: (state) => {
      state.update = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [update.fulfilled.type]: (state, action) => {
      state.update = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [update.rejected.type]: (state, action) => {
      state.update = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default AssistingProgramsSlice.reducer;
