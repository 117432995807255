import Api from "config/axios/api";
import config from "../config/axios/config";

export const getAllAssistingProgramService = async (assistingProgram) => {
  const url =
    config.apiUrl +
    `/assisting-programs/?page=${assistingProgram.page}&limit=${assistingProgram.limit}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAssistingProgramByIdService = async (assistingProgramId) => {
  const url = config.apiUrl + `/assisting-programs/${assistingProgramId}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const addAssistingProgramService = async (assistingProgramData) => {
  const url = config.apiUrl + `/assisting-programs/`;
  try {
    return await Api.post(url, assistingProgramData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAssistingProgramPartnersService = async (filterData) => {
  const url = config.apiUrl + `/assisting-programs/partners/` + filterData.id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const deleteAssistingProgramService = async (id) => {
  const url = config.apiUrl + `/assisting-programs/` + id;
  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const applyForAssistingProgramService = async (dataApply) => {
  const url = config.apiUrl + `/assisting-application/`;
  try {
    return await Api.post(url, dataApply, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getUserProjectInAssistingProgramsService = async (data) => {
  const url =
    config.apiUrl +
    `/assisting-programs/${data.id}/available-Projects?page=${data.page}&limit=${data.limit}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getRequestInAssistingProgramsService = async (data) => {
  const url =
    config.apiUrl +
    `/assisting-application/${data.id}/assisting?limit=${data.limit}&page=${data.page}&status=${data.status}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateAssistingProgramApplicationService = async (updateData) => {
  const url = config.apiUrl + `/assisting-application/` + updateData.id;
  try {
    return await Api.put(
      url,
      { status: updateData.status },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAcceptedProjectsInAssistingProgramsService = async (data) => {
  const url =
    config.apiUrl +
    `/assisting-programs/${data.id}/projects?limit=${data.limit}&page=${data.page}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAvailablePartnersInAssistingProgramByIdService = async (
  assistingProgramId
) => {
  const url =
    config.apiUrl +
    `/assisting-programs/${assistingProgramId}/available-partners/`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const updateService = async (programData) => {
  const url = config.apiUrl + `/assisting-programs/` + programData.id;
  try {
    return await Api.put(url, programData.data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getAllAssistingProgramService,
  getAssistingProgramByIdService,
  addAssistingProgramService,
  getAssistingProgramPartnersService,
  deleteAssistingProgramService,
  applyForAssistingProgramService,
  getUserProjectInAssistingProgramsService,
  getRequestInAssistingProgramsService,
  updateAssistingProgramApplicationService,
  getAcceptedProjectsInAssistingProgramsService,
  getAvailablePartnersInAssistingProgramByIdService,
  updateService,
};
