import { Text, Box, Flex, Avatar, Badge } from "@chakra-ui/react";
import React from "react";
import Card from "components/card/Card.js";
import { useColorModeValue } from "@chakra-ui/system";
import config from "config/axios/config";
import { useHistory } from "react-router-dom";

export default function Participant(props) {
  const { item, nbProjects } = props;
  const history = useHistory();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card p="1" mb="2" boxShadow={cardShadow} key={item._id}>
      <Flex direction="column">
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
          alignItems={"center"}
        >
          <Avatar
            w="50px"
            h="50px"
            src={config.apiUrl + "/api/getfile/" + item.Image}
            onClick={() => history.push("/admin/expert/profile/" + item._id)}
          />
          <Box ml="3">
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text
                fontWeight="normal"
                mr="2"
                _hover={{ color: "blue.500", cursor: "pointer" }}
                onClick={() =>
                  history.push("/admin/expert/profile/" + item._id)
                }
              >
                {item.firstName + " " + item.lastName}
              </Text>
              <Badge colorScheme="green" mb="5px">
                {item.userType}
              </Badge>
            </Flex>
            <Text color="#9e9e9ea1" fontSize={"sm"} fontWeight={"100"}>
              <b>{nbProjects}</b> {nbProjects > 1 ? "PROJECTS" : "PROJECT"}
            </Text>{" "}
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
}
