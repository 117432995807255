import React, { useState, useEffect } from "react";
import { Button, Text } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { Input, Stack } from "@chakra-ui/react";
import "./ProfileSettings.css";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateSocialProfiles } from "reducers/userInformation";
import {
  checkIsValidTwitterUrl,
  checkIsValidLinkedinUrl,
  checkIsValidFacebookUrl,
  checkIsValidGithubUrl,
} from "utils/functions";

const SocialProfiles = () => {
  const dispatch = useDispatch();
  const profileDefault = JSON.parse(localStorage.getItem("CurrentUserData"));
  const [socialProfiles, setSocialProfiles] = useState({
    linkedinUrl: profileDefault.linkedinUrl ? profileDefault.linkedinUrl : "",
    githubUrl: profileDefault.githubUrl ? profileDefault.githubUrl : "",
    facebookUrl: profileDefault.facebookUrl ? profileDefault.facebookUrl : "",
    twitterUrl: profileDefault.twitterUrl ? profileDefault.twitterUrl : "",
  });
  const [prevLinks, setPrevLinks] = useState(socialProfiles);
  const [socialErr, setSocialErr] = useState("");
  const updatedSocialProfiles = useSelector(
    (state) => state.UserInformation.updatedSocialProfiles.data
  );
  const [twitterUrlError, setTwitterUrlError] = useState("");
  const [facebookUrlError, setFacebookUrlError] = useState("");
  const [githubUrlError, setGithubUrlError] = useState("");
  const [linkedinUrlError, setLinkedinUrlError] = useState("");

  const validateForm = () => {
    let invalidForm = false;
    if (
      socialProfiles.twitterUrl &&
      !checkIsValidTwitterUrl(socialProfiles.twitterUrl)
    ) {
      invalidForm = true;
      setTwitterUrlError("Invalid twitter url!");
    } else {
      setTwitterUrlError("");
    }
    if (
      socialProfiles.linkedinUrl &&
      !checkIsValidLinkedinUrl(socialProfiles.linkedinUrl)
    ) {
      invalidForm = true;
      setLinkedinUrlError("Invalid linkedin url!");
    } else {
      setLinkedinUrlError("");
    }
    if (
      socialProfiles.facebookUrl &&
      !checkIsValidFacebookUrl(socialProfiles.facebookUrl)
    ) {
      invalidForm = true;
      setFacebookUrlError("Invalid facebook url!");
    } else {
      setFacebookUrlError("");
    }
    if (
      socialProfiles.githubUrl &&
      !checkIsValidGithubUrl(socialProfiles.githubUrl)
    ) {
      invalidForm = true;
      setGithubUrlError("Invalid github url!");
    } else {
      setGithubUrlError("");
    }
    return invalidForm;
  };
  const updateSocialProfilesAction = async () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    await dispatch(updateSocialProfiles(socialProfiles)).unwrap();
  };
  useEffect(() => {
    if (
      updatedSocialProfiles &&
      updatedSocialProfiles.message === "updated sucessfuly"
    ) {
      let CurrentUserData = JSON.parse(localStorage.getItem("CurrentUserData"));
      toast("Your social networks was successfully updated!");
      setPrevLinks(socialProfiles);
      CurrentUserData["twitterUrl"] =
        socialProfiles.twitterUrl && socialProfiles.twitterUrl;
      CurrentUserData["githubUrl"] =
        socialProfiles.githubUrl && socialProfiles.githubUrl;
      CurrentUserData["facebookUrl"] =
        socialProfiles.facebookUrl && socialProfiles.facebookUrl;
      CurrentUserData["linkedinUrl"] =
        socialProfiles.linkedinUrl && socialProfiles.linkedinUrl;
      localStorage.setItem("CurrentUserData", JSON.stringify(CurrentUserData));
    } else if (
      updatedSocialProfiles &&
      updatedSocialProfiles.message !== "updated sucessfuly"
    ) {
      setSocialErr(updatedSocialProfiles.message);
    }
  }, [updatedSocialProfiles]);

  return (
    <Stack
      padding="8"
      display={"flex"}
      flexDirection={"column"}
      borderRadius={"30px"}
      backgroundColor={"white"}
    >
      <h3 className="SettingTitle">Social Profiles</h3>
      {socialErr !== "" ? (
        <Text color="red"> {socialErr}</Text>
      ) : (
        <Text marginBottom={"24px!important"} color="gray.500" isTruncated>
          Here you can set user social profiles
        </Text>
      )}
      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          Linkedin Link
        </Text>
        <Input
          value={socialProfiles.linkedinUrl}
          onChange={(e) => {
            setSocialProfiles({
              ...socialProfiles,
              linkedinUrl: e.target.value,
            });
          }}
          placeholder="Linkedin"
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          borderRadius="16px"
        />
        {linkedinUrlError && (
          <Text mt="3" color="red">
            {linkedinUrlError}
          </Text>
        )}
      </label>
      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          Twitter Link
        </Text>
        <Input
          value={socialProfiles.twitterUrl}
          onChange={(e) => {
            setSocialProfiles({
              ...socialProfiles,
              twitterUrl: e.target.value,
            });
          }}
          placeholder="Twitter"
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          borderRadius="16px"
        />
        {twitterUrlError && (
          <Text mt="3" color="red">
            {twitterUrlError}
          </Text>
        )}
      </label>
      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          Facebook Link
        </Text>
        <Input
          value={socialProfiles.facebookUrl}
          onChange={(e) => {
            setSocialProfiles({
              ...socialProfiles,
              facebookUrl: e.target.value,
            });
          }}
          marginTop={"5px!important"}
          placeholder="Facebook"
          borderRadius="16px"
        />
        {facebookUrlError && (
          <Text mt="3" color="red">
            {facebookUrlError}
          </Text>
        )}
      </label>
      <label>
        <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
          Github Link
        </Text>
        <Input
          value={socialProfiles.githubUrl}
          onChange={(e) => {
            setSocialProfiles({
              ...socialProfiles,
              githubUrl: e.target.value,
            });
          }}
          marginTop={"5px!important"}
          placeholder="Github"
          borderRadius="16px"
        />
        {githubUrlError && (
          <Text mt="3" color="red">
            {githubUrlError}
          </Text>
        )}
      </label>

      <Flex justifyContent="flex-end" alignItems={"end"}>
        {socialProfiles !== prevLinks && (
          <Button
            size="md"
            marginTop={"24px!important"}
            color="#422AFB"
            border="2px solid #422AFB"
            mr="12px"
            marginLeft={"auto!important"}
            width="180px"
            onClick={() => {
              setSocialProfiles(prevLinks);
            }}
          >
            Cancel changes
          </Button>
        )}
        <Button
          onClick={() => {
            updateSocialProfilesAction();
          }}
          variant="brand"
          size="md"
          marginTop={"24px!important"}
          width="180px"
        >
          Save changes
        </Button>
      </Flex>
    </Stack>
  );
};

export default SocialProfiles;
