import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { programPhaseVotersService } from "services/programPhaseService";
import { rateProjectService } from "services/programPhaseService";
import { updateService } from "services/programPhaseService";
import { NextPhaseProjectService } from "services/programPhaseService";
import { programPhasePartnersService } from "services/programPhaseService";
import { programPhaseProjectsService } from "services/programPhaseService";
import {
  createProgramPhaseService,
  programPhaseDetailsService,
} from "services/programPhaseService";
import { getPhasesByIdAssistingProgramService } from "services/programPhaseService";

export const createProgramPhase = createAsyncThunk(
  "ProgramPhase/create",
  async (Data, thunkAPI) => {
    try {
      const { status, data } = await createProgramPhaseService(Data);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const programPhaseDetails = createAsyncThunk(
  "ProgramPhase/details",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await programPhaseDetailsService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const programPhaseProjects = createAsyncThunk(
  "ProgramPhase/projects",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await programPhaseProjectsService(filterData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const programPhaseVoters = createAsyncThunk(
  "ProgramPhase/voters",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await programPhaseVotersService(filterData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const programPhasePartners = createAsyncThunk(
  "ProgramPhase/partners",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await programPhasePartnersService(filterData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getPhasesByIdAssistingProgram = createAsyncThunk(
  "Phase/getPhasesByIdAssistingProgramService",
  async (Data, thunkAPI) => {
    try {
      const { status, data } = await getPhasesByIdAssistingProgramService(Data);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const rateProject = createAsyncThunk(
  "ProgramPhase/rate-project",
  async (rateData, thunkAPI) => {
    try {
      const { status, data } = await rateProjectService(rateData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const NextPhaseProject = createAsyncThunk(
  "ProgramPhase/Next-Phase-Project",
  async (DataNextPhase, thunkAPI) => {
    try {
      const { status, data } = await NextPhaseProjectService(DataNextPhase);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const update = createAsyncThunk(
  "ProgramPhase/update",
  async (phaseData, thunkAPI) => {
    try {
      const { status, data } = await updateService(phaseData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  getPhasesByIdAssisting: {
    status: "idle",
    data: {},
    error: {},
  },
  createProgramPhase: {
    status: "idle",
    data: {},
    error: {},
  },
  programPhaseDetails: {
    status: "idle",
    data: {},
    error: {},
  },
  programPhaseProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  programPhaseVoters: {
    status: "idle",
    data: {},
    error: {},
  },
  programPhasePartners: {
    status: "idle",
    data: {},
    error: {},
  },
  rateProject: {
    status: "idle",
    data: {},
    error: {},
  },
  nextPhase: {
    status: "idle",
    data: {},
    error: {},
  },
  update: {
    status: "idle",
    data: {},
    error: {},
  },
};

const ProgramPhaseSlice = createSlice({
  name: "ProgramPhase",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [NextPhaseProject.pending.type]: (state) => {
      state.nextPhase = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [NextPhaseProject.fulfilled.type]: (state, action) => {
      state.nextPhase = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [NextPhaseProject.rejected.type]: (state, action) => {
      state.nextPhase = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getPhasesByIdAssistingProgram.pending.type]: (state) => {
      state.getPhasesByIdAssisting = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getPhasesByIdAssistingProgram.fulfilled.type]: (state, action) => {
      state.getPhasesByIdAssisting = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getPhasesByIdAssistingProgram.rejected.type]: (state, action) => {
      state.getPhasesByIdAssisting = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [createProgramPhase.pending.type]: (state) => {
      state.createProgramPhase = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [createProgramPhase.fulfilled.type]: (state, action) => {
      state.createProgramPhase = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [createProgramPhase.rejected.type]: (state, action) => {
      state.createProgramPhase = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [programPhaseDetails.pending.type]: (state) => {
      state.programPhaseDetails = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [programPhaseDetails.fulfilled.type]: (state, action) => {
      state.programPhaseDetails = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [programPhaseDetails.rejected.type]: (state, action) => {
      state.programPhaseDetails = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [programPhaseProjects.pending.type]: (state) => {
      state.programPhaseProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [programPhaseProjects.fulfilled.type]: (state, action) => {
      state.programPhaseProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [programPhaseProjects.rejected.type]: (state, action) => {
      state.programPhaseProjects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [programPhaseVoters.pending.type]: (state) => {
      state.programPhaseVoters = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [programPhaseVoters.fulfilled.type]: (state, action) => {
      state.programPhaseVoters = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [programPhaseVoters.rejected.type]: (state, action) => {
      state.programPhaseVoters = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [programPhasePartners.pending.type]: (state) => {
      state.programPhasePartners = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [programPhasePartners.fulfilled.type]: (state, action) => {
      state.programPhasePartners = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [programPhasePartners.rejected.type]: (state, action) => {
      state.programPhasePartners = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [rateProject.pending.type]: (state) => {
      state.rateProject = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [rateProject.fulfilled.type]: (state, action) => {
      state.rateProject = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [rateProject.rejected.type]: (state, action) => {
      state.rateProject = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [update.pending.type]: (state) => {
      state.update = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [update.fulfilled.type]: (state, action) => {
      state.update = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [update.rejected.type]: (state, action) => {
      state.update = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default ProgramPhaseSlice.reducer;
