import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BasicButton from "components/button/BasicButton";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import TopExperts from "./components/TopExperts";
import TopProjectsByVote from "./components/TopProjectsByVote";
import LastUpComingEvents from "./components/LastUpComingEvents";
import TopVotesbyAssistingPrograms from "./components/TopVotesbyAssistingPrograms";
import AccountCreationStats from "./components/AccountCreationStats";
import ProjectsStats from "./components/ProjectsStats";
import AssistingProgramsStats from "./components/AssistingProgramsStats";
import VoteSessionsStats from "./components/VoteSessionsStats";
import ProjectsByCategoryStats from "./components/ProjectsByCategoryStats";
import VotesByCategoryStats from "./components/VotesByCategoryStats";
import MeetingsByTopicStats from "./components/MeetingsByTopicStats";
import OrganizationsByCategoryStats from "./components/OrganizationsByCategoryStats";
import MiniStatCard from "./components/MiniStatGrid";

export default function Dashboard() {
  const [userType, setUserType] = useState("");
  const history = useHistory();
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
  }, [currentUser]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {userType == "Investor" && (
        <Flex w="100%" justifyContent="flex-end" mb="20px">
          <BasicButton
            title={"Add Organization"}
            onClick={() => history.push("/admin/Add-Organization")}
          />
        </Flex>
      )}
      <MiniStatCard userType={userType} />
      {(userType === "Admin" || userType === "SuperAdmin") && (
        <SimpleGrid
          columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }}
          gap="20px"
          mb="20px"
        >
          <AccountCreationStats />
          <ProjectsStats />
          <AssistingProgramsStats />
          <VoteSessionsStats />
          <ProjectsByCategoryStats />
          <VotesByCategoryStats />
          <MeetingsByTopicStats />
        </SimpleGrid>
      )}
      {userType === "Investor" && (
        <SimpleGrid
          columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }}
          gap="20px"
          mb="20px"
        >
          <OrganizationsByCategoryStats />
        </SimpleGrid>
      )}
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        {(userType === "Admin" || userType === "SuperAdmin") && <TopExperts />}
        <TopProjectsByVote />
        <TopVotesbyAssistingPrograms />
      </SimpleGrid>
      <LastUpComingEvents />
    </Box>
  );
}
