import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ProjectsSelector from "components/project/ProjectsSelector";
import UsersSelector from "components/user/UsersSelector";
import { getallProjects } from "reducers/project";
import { getAllExperts } from "reducers/expert";
import AddWorkshopForm from "./components/AddWorkshopForm";
import { addMeetingGeneralInHackathon } from "reducers/meeting";

const AddWorkshop = (props) => {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [page1, setPage1] = useState(1);
  const [page2, setPage2] = useState(1);
  const [projectsError, setProjectsError] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [usersError, setUsersError] = useState("");
  const [expertFilterData, setExpertFilterData] = useState({ name: "" });
  const [projectFilterData, setProjectFilterData] = useState({
    name: "",
    categories: "",
  });
  const [workshopData, setWorkshopData] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
    expertId: "",
    projects: [],
    online: false,
    location: "",
    onlineUrl: "",
  });
  const [workshopErrors, setWorkshopErrors] = useState({
    titleError: "",
    startError: "",
    endError: "",
    onlineError: "",
    locationError: "",
    onlineUrlError: "",
  });
  const experts = useSelector((state) => state.Expert.allExperts.data.Experts);
  const expertsTotalPages = useSelector(
    (state) => state.Expert.allExperts.data.totalPages
  );
  const projects = useSelector((state) =>
    state.Project.projects.data ? state.Project.projects.data.result : null
  );
  const totalPages = useSelector(
    (state) => state.Project.projects.data.totalPages
  );

  useEffect(() => {
    const filterData = {
      isConfirmed: true,
      page: page1,
      limit: 4,
      ...projectFilterData,
    };
    dispatch(getallProjects(filterData));
  }, [page1, projectFilterData]);

  useEffect(() => {
    const filterdata = {
      page: page2,
      limit: 4,
      ...expertFilterData,
    };
    dispatch(getAllExperts(filterdata));
  }, [page2, expertFilterData]);

  const validateForm = () => {
    const errors = {};
    const date = new Date();

    if (!workshopData.title) {
      errors.titleError = "Title is required";
    }
    if (!workshopData.start) {
      errors.startError = "You should enter a start time";
    } else if (new Date(workshopData.start) < date) {
      errors.startError = "Start time must be greater than current time";
    }
    if (!workshopData.end) {
      errors.endError = "You should enter an end time";
    } else if (workshopData.end < workshopData.start) {
      errors.endError = "End time should be greater than start time";
    }
    if (!workshopData.location && !workshopData.online) {
      errors.locationError = "Location is required";
    }
    if (!workshopData.onlineUrl && workshopData.online) {
      errors.onlineUrlError = "Url is required";
    }

    return errors;
  };
  const nextHandler = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0 && step === 1) {
      setStep(2);
    } else if (step === 2) {
      if (!workshopData.projects.length) {
        setProjectsError("You should select at least one project");
        return;
      }
      setStep(3);
    } else if (step === 3) {
      if (!workshopData.expertId) {
        setUsersError("You should select an expert");
        return;
      }
      await dispatch(addMeetingGeneralInHackathon(workshopData)).unwrap();
      toast.success(
        "The workshop was created successfully. An email was sent to those concerned.",
        {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        }
      );
      onCloseHandler();
    } else {
      setWorkshopErrors(errors);
    }
  };

  const previousHandler = () => {
    setStep((current) => current - 1);
  };

  useEffect(() => {
    let arrayOfIds = selectedProjects.length
      ? selectedProjects.map((project) => project._id)
      : [];

    setWorkshopData({
      ...workshopData,
      projects: arrayOfIds,
      expertId: selectedUser ? selectedUser?._id : "",
    });
  }, [selectedProjects, selectedUser]);

  const onCloseHandler = () => {
    onClose();
    setStep(1);
    setWorkshopData({
      title: "",
      description: "",
      start: "",
      end: "",
      expertId: "",
      projects: [],
      online: false,
      location: "",
      onlineUrl: "",
    });
    setWorkshopErrors({
      titleError: "",
      startError: "",
      endError: "",
      onlineError: "",
      locationError: "",
      onlineUrlError: "",
    });
    setUsersError("");
    setProjectsError("");
    setExpertFilterData({ name: "" });
    setProjectFilterData({ name: "", categories: "" });
    setPage1(1);
    setPage2(1);
  };
  return (
    <Modal isOpen={isOpen} onClose={onCloseHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Workshop</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection={"column"}>
          {step === 1 && (
            <AddWorkshopForm
              workshopData={workshopData}
              setWorkshopData={setWorkshopData}
              workshopErrors={workshopErrors}
            />
          )}
          {step === 2 && (
            <ProjectsSelector
              projects={projects}
              error={projectsError}
              page={page1}
              setPage={setPage1}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              totalPages={totalPages}
              showFilter={true}
              setFilterData={setProjectFilterData}
              filterData={projectFilterData}
            />
          )}
          {step === 3 && (
            <UsersSelector
              multiple={false}
              users={experts}
              error={usersError}
              page={page2}
              setPage={setPage2}
              selectedUsers={selectedUser}
              setSelectedUsers={setSelectedUser}
              showFilter={true}
              setFilterData={setExpertFilterData}
              filterData={expertFilterData}
              totalPages={expertsTotalPages}
            />
          )}
        </ModalBody>

        <ModalFooter justifyContent={"space-between"}>
          <Button
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={() => (step === 1 ? onClose() : previousHandler())}
          >
            {step === 1 ? "Close" : "Previous"}
          </Button>

          <Button onClick={nextHandler} colorScheme="blue" size="sm">
            {step === 3 ? "Submit" : "Next"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddWorkshop;
