import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import { Text, Flex, Textarea, Input } from "@chakra-ui/react";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import BasicButton from "components/button/BasicButton";
import { update } from "reducers/hackathon";

export default function HackathonForm(props) {
  const { hackathon, setShowEditInfos, onReload } = props;
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [dates, setDates] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [datesError, setDatesError] = useState("");
  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("The title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("The description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!start || !end) {
      setDatesError("The start & end dates are required!");
      valid = false;
    } else {
      setDatesError("");
    }
    return valid;
  };
  const editHackathonAction = async () => {
    if (!validateForm()) {
      return;
    }
    const updateData = {
      id: hackathon._id,
      end,
      start,
      title,
      description,
    };
    await dispatch(update(updateData)).unwrap();
    toast.success("Hackathon has been updated successfully!", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });
    setShowEditInfos(false);
    onReload();
  };
  useEffect(() => {
    if (hackathon) {
      setTitle(hackathon.title);
      setDescription(hackathon.description);
      setStart(hackathon.start);
      setEnd(hackathon.end);
      setDates([hackathon.start, hackathon.end]);
    }
  }, [hackathon]);
  return (
    <Card p="2" direction="column" w="100%">
      <ToastContainer />
      <Flex mt="2" direction={"column"}>
        <Input
          isInvalid={titleError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Title"
          borderRadius="5px"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {titleError && (
          <Text mt="3" color="red">
            {titleError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <Textarea
          isInvalid={descriptionError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Description"
          borderRadius="5px"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {descriptionError && (
          <Text mt="3" color="red">
            {descriptionError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <DatePicker
          inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
          format="MM/DD/YYYY HH:mm"
          range
          plugins={[
            <TimePicker position="bottom" hideSeconds={true} />,
            <DatePanel markFocused header="Start - End" />,
          ]}
          placeholder="Start & End Dates"
          id="dates"
          name="dates"
          value={dates}
          onChange={(ev) => {
            setDates(ev);
            if (ev[0]) {
              setStart(
                ev[0].year +
                  "/" +
                  (ev[0].month.index + 1).toString() +
                  "/" +
                  ev[0].day +
                  " " +
                  ev[0].hour +
                  ":" +
                  ev[0].minute
              );
            }

            if (ev[1]) {
              setEnd(
                ev[1].year +
                  "/" +
                  (ev[1].month.index + 1).toString() +
                  "/" +
                  ev[1].day +
                  " " +
                  ev[1].hour +
                  ":" +
                  ev[1].minute
              );
            }
          }}
          onClose={() => true}
        />
        {datesError && (
          <Text mt="3" color="red">
            {datesError}
          </Text>
        )}
      </Flex>
      <Flex justifyContent="end" mt="4">
        <BasicButton title={"Save"} size="md" onClick={editHackathonAction} />
      </Flex>
    </Card>
  );
}
