import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateCohort } from "reducers/cohort";
import { checkIsValidEmail } from "utils/functions";
import { update } from "reducers/hackathon";

const EditManager = ({ isOpen, onClose, initialData, onReload }) => {
  const dispatch = useDispatch();
  const [managerEmail, setManagerEmail] = useState(initialData.managerEmail);
  const [managerEmailError, setManagerEmailError] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const validateForm = () => {
    let valid = true;
    if (!managerEmail) {
      setManagerEmailError("Manager email is required!");
      valid = false;
    } else if (!checkIsValidEmail(managerEmail)) {
      setManagerEmailError("Invalid email!");
      valid = false;
    } else {
      setManagerEmailError("");
    }
    return valid;
  };

  const editHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const data = new FormData();
    data.append("managerEmail", managerEmail);

    const hackathonData = {
      data,
      id: initialData._id,
    };
    await dispatch(update(hackathonData)).unwrap();
    onReload();
  };

  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [managerEmail, submitted]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Manager Email</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Manager Email <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Input
                isInvalid={managerEmailError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Manager Email"
                borderRadius="5px"
                value={managerEmail}
                onChange={(e) => setManagerEmail(e.target.value)}
              />
              {managerEmailError && (
                <Text mt="3" color="red">
                  {managerEmailError}
                </Text>
              )}
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={editHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditManager;
