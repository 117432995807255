import React from "react";
import Card from "components/card/Card.js";
import { Text, Flex } from "@chakra-ui/react";

export default function DeletedItemCard(props) {
  const { item } = props;
  return (
    <Flex flexDirection="column" h="100vh">
      <Card mt="3" mb="3">
        <Text fontSize={"2xl"} textAlign={"center"}>
          {item} does not exist
        </Text>
        <Text fontSize="sm" textAlign={"center"}>
          {item} has been deleted
        </Text>
      </Card>
    </Flex>
  );
}
