import React from "react";
import { Flex, Icon, Heading, IconButton, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { BsTwitter, BsGithub, BsLinkedin } from "react-icons/bs";
import { FaLaptop, FaLink, FaVideo } from "react-icons/fa";

const ProjectSubmission = (props) => {
  const { other, website, linkedin, submission } = props;
  console.log(submission, "submission");
  return (
    <>
      <Card mt="3" mb="3">
        <Flex direction={"column"}>
          {submission && submission.track && (
            <Heading size="md" mb="4">
              {submission.track.cohort.title} :{" "}
              <span style={{ fontWeight: "normal" }}>
                {submission.track.title}{" "}
              </span>
            </Heading>
          )}
          {submission && submission.githubUrl && (
            <Flex
              align="center"
              onClick={() => {
                window.open(submission.githubUrl, "_blank").focus();
              }}
            >
              <Text fontSize="xl" mr={2}>
                <FaLink color="blue" />
              </Text>
              <Text _hover={{ color: "blue.500", cursor: "pointer" }}>
                GitHub URL for your project submission.
              </Text>
            </Flex>
          )}
          {submission && submission.pptUrl && (
            <Flex
              mt="3"
              align="center"
              onClick={() => {
                window.open(submission.pptUrl, "_blank").focus();
              }}
            >
              <Text fontSize="xl" mr={2}>
                <FaLaptop color="blue" />
              </Text>{" "}
              <Text _hover={{ color: "blue.500", cursor: "pointer" }}>
                presentation URL for your project submission.
              </Text>
            </Flex>
          )}
          {submission && submission.videoUrl && (
            <Flex
              mt="3"
              onClick={() => {
                window.open(submission.videoUrl, "_blank").focus();
              }}
            >
              <Text fontSize="xl" mr={2}>
                <FaVideo color="blue" />
              </Text>{" "}
              <Text _hover={{ color: "blue.500", cursor: "pointer" }}>
                Video URL for your project submission.
              </Text>
            </Flex>
          )}
        </Flex>
      </Card>
    </>
  );
};

export default ProjectSubmission;
