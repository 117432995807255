import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Center,
  Spinner,
  Box,
  Stack,
  Badge,
} from "@chakra-ui/react";
import Pagination from "components/pagination/Pagination";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import { programPhaseVoters } from "reducers/programPhase";
import { programPhasePartners } from "reducers/programPhase";
import PartnersItem from "./PartnersItem";
import VotersItem from "./VotersItem";
import InputField from "components/fields/InputField";
import BasicButton from "components/button/BasicButton";
import VotingPercentage from "./VotingPercentage";

const Voters = (props) => {
  const { id } = props;

  const dispatch = useDispatch();
  const [page1, setPage1] = useState(1);
  const [page2, setPage2] = useState(1);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");

  const [userType, setUserType] = useState("Expert");
  const programPhase = useSelector(
    (state) => state.ProgramPhase.programPhaseDetails.data
  );
  const voters = useSelector(
    (state) => state.ProgramPhase.programPhaseVoters.data.result
  );
  const totalPages1 = useSelector(
    (state) => state.ProgramPhase.programPhaseVoters.data.totalPages
  );
  const status1 = useSelector(
    (state) => state.ProgramPhase.programPhaseVoters.status
  );

  const partners = useSelector(
    (state) => state.ProgramPhase.programPhasePartners.data.result
  );
  const totalPages2 = useSelector(
    (state) => state.ProgramPhase.programPhasePartners.data.totalPages
  );
  const status2 = useSelector(
    (state) => state.ProgramPhase.programPhasePartners.status
  );

  const fetchProgramPhaseVoters = async () => {
    const filterdata = {
      id: id,
      page: page1,
      limit: 6,
      userType: userType,
      titleSearch: name,
    };
    dispatch(programPhaseVoters(filterdata));
  };

  const fetchProgramPhasePartners = async () => {
    const filterdataPartners = {
      id: id,
      page: page2,
      limit: 6,
      titleSearch: title,
    };
    dispatch(programPhasePartners(filterdataPartners));
  };
  useEffect(() => {
    if (userType !== "partners") {
      fetchProgramPhaseVoters();
    }
  }, [page1, userType]);
  useEffect(() => {
    if (userType === "partners") {
      fetchProgramPhasePartners();
    }
  }, [page2, userType]);

  useEffect(() => {
    setPage1(1);
    setPage2(1);
  }, [userType, title, name]);
  return (
    <Box p={6} borderWidth="1px" borderRadius="lg" width="100%" boxShadow="md">
      <VotingPercentage
        expertPercentage={programPhase?.votingPercentage?.experts}
        communityPercentage={programPhase?.votingPercentage?.community}
        partnersPercentage={programPhase?.votingPercentage?.partners}
      />
      {!voters && status1 === "loading" && (
        <Flex flexDirection="column" mt="3" mb="3">
          <Spinner my="auto!important" mx="auto" />
        </Flex>
      )}
      {(partners || voters) && (
        <Flex flexDirection={"column"}>
          <Box>
            {userType == "partners" && (
              <Flex mb="4" alignItems={"center"}>
                <InputField
                  label=""
                  placeholder="Search by Title"
                  value={title}
                  setValue={setTitle}
                  required={false}
                />
                <BasicButton
                  style={{ width: "180px" }}
                  title="Search"
                  variant="solid"
                  onClick={fetchProgramPhasePartners}
                />
              </Flex>
            )}
            {userType !== "partners" && (
              <Flex mb="4" alignItems={"center"}>
                <InputField
                  label=""
                  placeholder="Search by Name"
                  value={name}
                  setValue={setName}
                  required={false}
                />
                <BasicButton
                  style={{ width: "180px" }}
                  title="Search"
                  variant="solid"
                  onClick={fetchProgramPhaseVoters}
                />
              </Flex>
            )}
            <Stack direction="row">
              <Badge
                variant={userType === "Expert" ? "solid" : "outline"}
                colorScheme="green"
                cursor={"pointer"}
                onClick={() => setUserType("Expert")}
              >
                Experts
              </Badge>
              <Badge
                variant={userType === "community" ? "solid" : "outline"}
                colorScheme="gray"
                cursor={"pointer"}
                onClick={() => setUserType("community")}
              >
                Community Members
              </Badge>
              <Badge
                variant={userType === "partners" ? "solid" : "outline"}
                colorScheme="blue"
                cursor={"pointer"}
                onClick={() => setUserType("partners")}
              >
                Partners
              </Badge>
            </Stack>
            {userType !== "partners" &&
              voters &&
              voters.length > 0 &&
              voters.map((item, index) => (
                <VotersItem key={index} item={item}></VotersItem>
              ))}
            {userType == "partners" &&
              partners &&
              partners.length > 0 &&
              partners.map((item, index) => (
                <PartnersItem key={index} item={item}></PartnersItem>
              ))}
            {userType !== "partners" && totalPages1 > 1 && (
              <Center pt="5">
                <Pagination
                  page={page1}
                  totalPages={totalPages1}
                  setPage={setPage1}
                  setLoader={() => {}}
                />
              </Center>
            )}
            {userType == "partners" && totalPages2 > 1 && (
              <Center pt="5">
                <Pagination
                  page={page2}
                  totalPages={totalPages2}
                  setPage={setPage2}
                  setLoader={() => {}}
                />
              </Center>
            )}
          </Box>
        </Flex>
      )}
      {userType !== "partners" && voters && !voters.length && (
        <Flex flexDirection="column">
          <Card mt="3" mb="3">
            <Text fontSize={"2xl"} textAlign={"center"}>
              There is no user
            </Text>
          </Card>
        </Flex>
      )}
      {userType === "partners" && partners && !partners.length && (
        <Flex flexDirection="column">
          <Card mt="3" mb="3">
            <Text fontSize={"2xl"} textAlign={"center"}>
              There is no partner
            </Text>
          </Card>
        </Flex>
      )}
    </Box>
  );
};

export default Voters;
