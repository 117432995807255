import React, { useState } from "react";
import { Box } from "@chakra-ui/react";

import { useLocation } from "react-router-dom";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";

const ApplyGrants = () => {
  const location = useLocation();
  const cohortId = location && location.state ? location.state.cohortId : "";
  const [step, setStep] = useState(1);
  const [GrantsData, setGrantsData] = useState({
    project:"",
    fund:"",
    proposalSentence:"",
    proposalSummary:"",
    choiceToBuildOnHedera:"",
    customerCountQuestion:"",
    competitors:"",
    teamSize:0,
    teamExperience:"",
    startedStatus:"",
    developmentTimeline:"",
    estimatedLaunchDate:"",
    fundingRequestedHBAR:0,
    allocatedTowards:"",
    codeRepository:"",
    previousFunding:"",
    fromWhere:"",
    hearAboutUs:"",
    video:"",
    pdfPresentation:""
  });
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {step === 1 && (
        <Step1
          grantsdata={GrantsData}
          setgrantdata={setGrantsData}
          setStep={setStep}
        />
      )}
      {step === 2  && (
        <Step2
        grantsdata={GrantsData}
        setgrantdata={setGrantsData}
        setStep={setStep}
        />
      )}
       {step === 3  && (
        <Step3
        grantsdata={GrantsData}
        setgrantdata={setGrantsData}
        setStep={setStep}
        />
      )}
      {step === 4  && (
        <Step4
        grantsdata={GrantsData}
        setgrantdata={setGrantsData}
        setStep={setStep}
        />
      )}
      {step === 5  && (
        <Step5
        grantsdata={GrantsData}
        setgrantdata={setGrantsData}
        setStep={setStep}
        />
      )}
     
   
    </Box>
  );
};

export default ApplyGrants;
