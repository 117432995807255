import React from "react";
import { Text, Box, Flex, Center } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import Pagination from "components/pagination/Pagination";
import BasicButton from "components/button/BasicButton";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { programPhaseProjects } from "reducers/programPhase";
import WideProjectCard from "components/project/WideProjectCard";
import Loader from "components/loader/Loader";
import { rateProject } from "reducers/programPhase";
import { NextPhaseProject } from "reducers/programPhase";
import VotingPercentage from "./VotingPercentage";

const ProgramPhaseProjects = (props) => {
  const { programPhaseDetailsHandler } = props;
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const programPhase = useSelector(
    (state) => state.ProgramPhase.programPhaseDetails.data
  );
  const status = useSelector(
    (state) => state.ProgramPhase.programPhaseProjects.status
  );
  const projects = useSelector((state) =>
    state.ProgramPhase.programPhaseProjects.data
      ? state.ProgramPhase.programPhaseProjects.data.result
      : []
  );
  const totalPages = useSelector(
    (state) => state.ProgramPhase.programPhaseProjects.data.totalPages
  );
  const canRate = useSelector(
    (state) => state.ProgramPhase.programPhaseProjects.data.canRate
  );

  const programPhaseProjectsAction = async () => {
    const filterData = {
      id: programPhase._id,
      page: page,
      limit: 2,
      titleSearch: title,
    };
    await dispatch(programPhaseProjects(filterData)).unwrap();
  };
  useEffect(() => {
    if (programPhase._id) {
      programPhaseProjectsAction();
      setReload(false);
    }
  }, [programPhase, page, reload]);

  const onSearchHandler = () => {
    setReload(true);
    setPage(1);
  };
  const onRatingHandler = async (projectId, rate) => {
    const data = {
      projectId: projectId,
      assistingProgramId: programPhase.assistingProgram._id,
      programPhaseId: programPhase._id,
      rate: rate,
    };
    await dispatch(rateProject(data)).unwrap();
    setReload(true);
  };
  const NextPhaseHandler = async (idProject) => {
    const data = {
      idProject: idProject,
      id: programPhase._id,
    };
    await dispatch(NextPhaseProject(data)).unwrap();
    programPhaseDetailsHandler()
    // setReload(true);
  };
  return (
    <Flex direction="column" p="4">
      {!projects && status === "loading" && <Loader />}
      {projects && (
        <Box
          p={6}
          borderWidth="1px"
          borderRadius="lg"
          width="100%"
          boxShadow="md"
        >
          <VotingPercentage
            expertPercentage={programPhase?.votingPercentage?.experts}
            communityPercentage={programPhase?.votingPercentage?.community}
            partnersPercentage={programPhase?.votingPercentage?.partners}
          />
          <Flex mb="4" alignItems={"center"}>
            <InputField
              label=""
              placeholder="Search by Title"
              value={title}
              setValue={setTitle}
              required={false}
            />
            <BasicButton
              style={{ width: "180px" }}
              title="Search"
              variant="solid"
              onClick={onSearchHandler}
            />
          </Flex>
          {projects && projects.length > 0 && (
            <Flex w="100%" direction={"column"}>
              {projects.map((item, index) => (
                <WideProjectCard
                  key={index}
                  type={"phase"}
                  project={item}
                  showRating={true}
                  rating={item.rate}
                  canRate={canRate}
                  onRating={onRatingHandler}
                  NextPhaseHandler={NextPhaseHandler}
                  programPhase={programPhase}
                  programPhaseDetailsHandler={programPhaseDetailsHandler}
                />
              ))}
            </Flex>
          )}
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
          {projects && projects.length == 0 && (
            <Box
              p={6}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              boxShadow="md"
              height="100%"
              backgroundColor="#9e9e9e14"
              textAlign={"center"}
            >
              <Text fontSize="lg" mb="3">
                There is no project
              </Text>
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default ProgramPhaseProjects;
