import Card from "components/card/Card.js";
import {
  Text,
  Box,
  Flex,
  Heading,
  Divider,
  Spinner,
  Icon,
  SimpleGrid,
  Image,
  useColorModeValue,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalFooter,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link as Linkk } from "react-router-dom";

import jwt_decode from "jwt-decode";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";

import { getbyidGrants } from "reducers/grants";
import Information from "../ExpertProfile/components/Information";
import moment from "moment";
import GrantApplication from "./components/GrantApplication";
import { getGrantsApplication } from "reducers/grants";
const GrantsApplicationsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const grants = useSelector((state) =>
    state.Grants.applicationgrant.data
      ? state.Grants.applicationgrant.data.result
      : null
  );
  const getGrantsApplicationAction = async () => {
    await dispatch(getGrantsApplication(id)).unwrap();
  };

  useEffect(() => {
    getGrantsApplicationAction();
  }, [id]);

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {!grants && (
          <Flex flexDirection="column" h="100vh">
            <Spinner my="auto!important" mx="auto" />
          </Flex>
        )}

        {grants && (
          <Card mt="3" mb="3">
            <Image
              src={config.apiUrl + "/grant/" + grants.project.images}
              w={"100%"}
              h={"220px"}
              borderRadius="20px"
              objectFit="cover"
              bg={"white"}
              border="3px solid"
              mb="10px"
              borderColor={borderColor}
              boxShadow={"md"}
              fallbackSrc={placeholder}
            />
            <Flex
              mt="2"
              w="100%"
              alignItems={"center"}
              justifyContent={"space-between"}
            ></Flex>

            <SimpleGrid columns="2" gap="20px">
              <Information
                boxShadow={cardShadow}
                title="Allocated Towards"
                value={grants.allocatedTowards}
              />
              <Information
                boxShadow={cardShadow}
                title="Created by"
                value={
                  <Text fontWeight="normal" color="blue">
                    <Linkk to={"/admin/expert/profile/" + grants.requester._id}>
                      {grants.requester.firstName} {""}{" "}
                      {grants.requester.lastName}
                    </Linkk>
                  </Text>
                }
              />
              <Information
                boxShadow={cardShadow}
                title="Competitors
                "
                value={grants.competitors}
              />
              <Information
                boxShadow={cardShadow}
                title="Users Number"
                value={grants.customerCountQuestion}
              />
              <Information
                boxShadow={cardShadow}
                title="Estimated Launch Date"
                value={moment(grants.estimatedLaunchDate).format("ll")}
              />
              <Information
                boxShadow={cardShadow}
                title="Fund"
                value={grants.fund}
              />
              <Information
                boxShadow={cardShadow}
                title="Funding Requested"
                value={grants.fundingRequestedHBAR}
              />
              <Information
                boxShadow={cardShadow}
                title="Hear about us on 
                "
                value={grants.hearAboutUs}
              />
              <Information
                boxShadow={cardShadow}
                title="Proposal Sentence"
                value={grants.proposalSentence}
              />
              <Information
                boxShadow={cardShadow}
                title="Proposal Summary"
                value={grants.proposalSummary}
              />
              <Information
                boxShadow={cardShadow}
                title="Team Size"
                value={grants.teamSize}
              />
              <Information
                boxShadow={cardShadow}
                title="Team Experience"
                value={grants.teamExperience}
              />
              <Information
                boxShadow={cardShadow}
                title="Pitch Deck"
                value={
                  <a
                    href={
                      grants.presentation
                        ? config.apiUrl +
                          "/grantsApplication/getFiles/" +
                          grants.presentation
                        : ""
                    }
                    target="_blank"
                    className="me-2 link-file"
                  >
                    {grants.presentation}
                  </a>
                }
              />
              <Information
                boxShadow={cardShadow}
                title="Demo Deck"
                value={
                  <a
                    href={
                      grants.demo
                        ? config.apiUrl +
                          "/grantsApplication/getFiles/" +
                          grants.demo
                        : ""
                    }
                    target="_blank"
                    className="me-2 link-file"
                  >
                    {grants.demo}
                  </a>
                }
              />
            </SimpleGrid>
          </Card>
        )}
      </Box>
    </>
  );
};

export default GrantsApplicationsDetails;
