import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCohortByIdService } from "services/cohortService";
import { getProjectsByCohortService } from "services/cohortService";
import { getPendingProjectsByCohortService } from "services/cohortService";
import { updateCohortService } from "services/cohortService";
import { getAcceptedMembersByCohortService } from "services/cohortService";
import { addProjectMembersHackathonService } from "services/cohortService";
import { joinTeamMembersService } from "services/cohortService";
import { deleteMembersService } from "services/cohortService";
import { getProjectMembersHackathonService } from "services/cohortService";
import { applyForHackathonService } from "services/cohortService";
import { updateCohortApplicationService } from "services/cohortService";
import { getAcceptedProjectsByCohortService } from "services/cohortService";
import { applyForCohortService } from "services/cohortService";
import { deleteCohortsService } from "services/cohortService";
import { addCohortService } from "services/cohortService";
import { getCohortsService } from "services/cohortService";

export const getAllCohorts = createAsyncThunk(
  "cohort/all",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getCohortsService(filterData);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteCohort = createAsyncThunk(
  "cohort/delete",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await deleteCohortsService(id);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addCohort = createAsyncThunk(
  "cohort/add",
  async (cohortData, thunkAPI) => {
    try {
      const { status, data } = await addCohortService(cohortData.cohort);
      if (status === 200 || status === 201) {
        if (
          data &&
          data.cohort &&
          cohortData &&
          cohortData.roles &&
          cohortData.currentUserId
        ) {
          cohortData.socket.emit("sendNotificationToAll", {
            type: "AddCohort",
            data: data.cohort,
            roles: cohortData.roles,
            sender: cohortData.currentUserId,
          });
        }
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateCohort = createAsyncThunk(
  "cohort/update",
  async (updateData, thunkAPI) => {
    try {
      const { status, data } = await updateCohortService(updateData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getCohortById = createAsyncThunk(
  "cohort/by-id",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getCohortByIdService(id);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// export const applyForCohort = createAsyncThunk(
//   "cohort/apply",
//   async (dataApply, thunkAPI) => {
//     try {
//       const { status, data } = await applyForCohortService(dataApply);
//       if (status === 200 || status === 201) {
//         console.log(data);
//         return data;
//       } else {
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (e) {
//       console.log("Error", e.response.data);
//       return thunkAPI.rejectWithValue(e.response.data);
//     }
//   }
// );
export const applyForHackathon = createAsyncThunk(
  "Hachathon/applyHack",
  async (dataApply, thunkAPI) => {
    try {
      const { status, data } = await applyForHackathonService(dataApply);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAcceptedProjectsByCohort = createAsyncThunk(
  "cohort/get-accepted-projects",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getAcceptedProjectsByCohortService(
        filterData
      );
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAcceptedMembersByCohort = createAsyncThunk(
  "cohort/get-members",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getAcceptedMembersByCohortService(
        filterData
      );
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPendingProjectsByCohort = createAsyncThunk(
  "cohort/get-pending-projects",
  async (filterData, thunkAPI) => {
    try {
      const { status, data } = await getPendingProjectsByCohortService(
        filterData
      );
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateCohortApplication = createAsyncThunk(
  "cohort/update-application",
  async (updateData, thunkAPI) => {
    try {
      const { status, data } = await updateCohortApplicationService(updateData);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getProjectMembersHackathon = createAsyncThunk(
  "Hackathon/invite-members",
  async (dataMembers, thunkAPI) => {
    try {
      const { status, data } = await getProjectMembersHackathonService(dataMembers);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addProjectMembersHackathon = createAsyncThunk(
  "Hackathon/addProjectMembersHackathon",
  async (dataMembers, thunkAPI) => {
    try {
      const { status, data } = await addProjectMembersHackathonService(dataMembers);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteMemberR = createAsyncThunk(
  "member/deleteR",
  async (datamember, thunkAPI) => {
    try {
      const { status, data } = await deleteMembersService(datamember);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const joinTeamR = createAsyncThunk(
  "join/team",
  async (datamember, thunkAPI) => {
    try {
      const { status, data } = await joinTeamMembersService(datamember);
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  cohort: {
    status: "idle",
    data: {},
    error: {},
  },
  cohorts: {
    status: "idle",
    data: {},
    error: {},
  },
  deleteCohort: {
    status: "idle",
    data: {},
    error: {},
  },
  addCohort: {
    status: "idle",
    data: {},
    error: {},
  },
  updateCohort: {
    status: "idle",
    data: {},
    error: {},
  },
  applyForCohort: {
    status: "idle",
    data: {},
    error: {},
  },
  acceptedCohortProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  pendingCohortProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  updateCohortApplication: {
    status: "idle",
    data: {},
    error: {},
  },
  applyHack: {
    status: "idle",
    data: {},
    error: {},
  },
  membersHack: {
    status: "idle",
    data: {},
    error: {},
  },
  invitememberHack: {
    status: "idle",
    data: {},
    error: {},
  },
  addmemberHack: {
    status: "idle",
    data: {},
    error: {},
  },
  deletememberHack: {
    status: "idle",
    data: {},
    error: {},
  },
  joinHack: {
    status: "idle",
    data: {},
    error: {},
  },
};

const CohortSlice = createSlice({
  name: "cohorts",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [deleteMemberR.pending.type]: (state) => {
      state.deletememberHack = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deleteMemberR.fulfilled.type]: (state, action) => {
      state.deletememberHack = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [deleteMemberR.rejected.type]: (state, action) => {
      state.deletememberHack = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addProjectMembersHackathon.pending.type]: (state) => {
      state.addmemberHack = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addProjectMembersHackathon.fulfilled.type]: (state, action) => {
      state.addmemberHack = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addProjectMembersHackathon.rejected.type]: (state, action) => {
      state.addmemberHack = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getProjectMembersHackathon.pending.type]: (state) => {
      state.invitememberHack = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getProjectMembersHackathon.fulfilled.type]: (state, action) => {
      state.invitememberHack = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getProjectMembersHackathon.rejected.type]: (state, action) => {
      state.invitememberHack = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateCohort.pending.type]: (state) => {
      state.updateCohort = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateCohort.fulfilled.type]: (state, action) => {
      state.updateCohort = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateCohort.rejected.type]: (state, action) => {
      state.updateCohort = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateCohortApplication.pending.type]: (state) => {
      state.updateCohortApplication = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateCohortApplication.fulfilled.type]: (state, action) => {
      state.updateCohortApplication = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateCohortApplication.rejected.type]: (state, action) => {
      state.updateCohortApplication = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAcceptedProjectsByCohort.pending.type]: (state) => {
      state.acceptedCohortProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAcceptedProjectsByCohort.fulfilled.type]: (state, action) => {
      state.acceptedCohortProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAcceptedProjectsByCohort.rejected.type]: (state, action) => {
      state.acceptedCohortProjects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getPendingProjectsByCohort.pending.type]: (state) => {
      state.pendingCohortProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getPendingProjectsByCohort.fulfilled.type]: (state, action) => {
      state.pendingCohortProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getPendingProjectsByCohort.rejected.type]: (state, action) => {
      state.pendingCohortProjects = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    // [applyForCohort.pending.type]: (state) => {
    //   state.applyForCohort = {
    //     status: "loading",
    //     data: {},
    //     error: {},
    //   };
    // },
    // [applyForCohort.fulfilled.type]: (state, action) => {
    //   state.applyForCohort = {
    //     status: "success",
    //     data: action.payload,
    //     error: {},
    //   };
    // },
    // [applyForCohort.rejected.type]: (state, action) => {
    //   state.applyForCohort = {
    //     status: "failed",
    //     data: {},
    //     error: {},
    //   };
    // },
    [getAllCohorts.pending.type]: (state) => {
      state.cohorts = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllCohorts.fulfilled.type]: (state, action) => {
      state.cohorts = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllCohorts.rejected.type]: (state, action) => {
      state.cohorts = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getCohortById.pending.type]: (state) => {
      state.cohort = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getCohortById.fulfilled.type]: (state, action) => {
      state.cohort = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getCohortById.rejected.type]: (state, action) => {
      state.cohort = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [deleteCohort.pending.type]: (state) => {
      state.deleteCohort = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deleteCohort.fulfilled.type]: (state, action) => {
      state.deleteCohort = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [deleteCohort.rejected.type]: (state, action) => {
      state.deleteCohort = {
        status: "failed",
        data: {},
        error: {},
      };
    },

    [addCohort.pending.type]: (state) => {
      state.addCohort = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addCohort.fulfilled.type]: (state, action) => {
      state.addCohort = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addCohort.rejected.type]: (state, action) => {
      state.addCohort = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [applyForHackathon.pending.type]: (state) => {
      state.applyHack = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [applyForHackathon.fulfilled.type]: (state, action) => {
      state.applyHack = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [applyForHackathon.rejected.type]: (state, action) => {
      state.applyHack = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAcceptedMembersByCohort.pending.type]: (state) => {
      state.membersHack = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAcceptedMembersByCohort.fulfilled.type]: (state, action) => {
      state.membersHack = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAcceptedMembersByCohort.rejected.type]: (state, action) => {
      state.membersHack = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [joinTeamR.pending.type]: (state) => {
      state.joinHack = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [joinTeamR.fulfilled.type]: (state, action) => {
      state.joinHack = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [joinTeamR.rejected.type]: (state, action) => {
      state.joinHack = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default CohortSlice.reducer;
