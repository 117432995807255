import React from "react";
import {
  Text,
  Flex,
  Box,
  Image,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { Link as Linkk } from "react-router-dom";
export default function ProjectsItem(props) {
  const { item } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p="1" mb="2" boxShadow={cardShadow}>
      <Flex>
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
        >
          <Image
            h="50px"
            w="50px"
            src={config.apiUrl + `/project/file/` + item.images}
            borderRadius="8px"
            fallbackSrc={placeholder}
            objectFit="cover"
          />
          <Box ml="3">
            <Text fontWeight="normal" color="blue">
              <Linkk to={"/admin/project/" + item._id}>{item.title}</Linkk>
            </Text>
            <Text fontSize="sm">{item.category}</Text>
          </Box>
        </Flex>
        {item.status === "pending" && (
          <Flex alignItems={"center"}>
            <Button colorScheme={"green"} size="sm">
              Accept
            </Button>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
