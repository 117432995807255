import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Heading,
  Box,
  Center,
  Text,
  Spinner,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/pagination/Pagination";
import UserItem from "./UserItem";
import { getAllExperts } from "reducers/expert";

const ChooseExpert = (props) => {
  const { meetingData, setMeetingData, setStep, allProjects } = props;
  const dispatch = useDispatch();
  const [expert, setExpert] = useState(meetingData.expertId);
  const [expertError, setExpertError] = useState("");
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const experts = useSelector((state) => state.Expert.allExperts.data.Experts);
  const status = useSelector((state) => state.Expert.allExperts.status);
  const totalPages = useSelector(
    (state) => state.Expert.allExperts.data.totalPages
  );

  const validForm = () => {
    let valid = true;
    if (!expert) {
      setExpertError("Expert is required!");
      valid = false;
    } else {
      setExpertError("");
    }
    return valid;
  };

  const confirm = () => {
    if (!validForm()) {
      return;
    }
    const data = { ...meetingData, expertId: expert };
    setMeetingData(data);
    setStep(3);
  };

  const back = () => {
    setStep(1);
    setExpert("");
    const data = { ...meetingData, expertId: "" };
    setMeetingData(data);
  };
  const Next = () => {
    const data = { ...meetingData, expertId: expert };
    setMeetingData(data);
    setStep(3);
  };
  const getAllExpertsAction = async () => {
    const filterdata = {
      page: page,
      limit: 4,
    };
    await dispatch(getAllExperts(filterdata)).unwrap();
  };

  useEffect(() => {
    getAllExpertsAction();
  }, [page]);

  return (
    <Card>
      <Heading size="sm" mt="4" mb="4">
        Choose an expert
      </Heading>
      {!experts && status === "loading" && (
        <Flex flexDirection="column" mt="3" mb="3">
          <Spinner my="auto!important" mx="auto" />
        </Flex>
      )}
      {experts && !experts.length && (
        <Flex flexDirection="column">
          <Card mt="3" mb="3">
            <Text fontSize={"2xl"} textAlign={"center"}>
              There is no expert
            </Text>
          </Card>
        </Flex>
      )}
      {experts && experts.length > 0 && (
        <Flex flexDirection={"column"}>
          <Box>
            {experts.map((item) => (
              <UserItem
                user={item}
                selectedUser={expert}
                setSelectedUser={setExpert}
              />
            ))}
            {totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={setLoader}
                />
              </Center>
            )}
          </Box>
          {expertError && (
            <Text mt="3" color="red">
              {expertError}
            </Text>
          )}
        </Flex>
      )}
      <Flex mt="4" justifyContent={"flex-end"}>
        <Button colorScheme={"blue"} variant="outline" onClick={back} mr="2">
          Back
        </Button>
        <Button
          colorScheme={"blue"}
          disabled={!experts || !experts.length}
          onClick={allProjects ? confirm : Next}
        >
          {allProjects ? "Confirm" : "Next"}
        </Button>
      </Flex>
    </Card>
  );
};

export default ChooseExpert;
