import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  Box,
  SimpleGrid,
  Text, 
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import Card from "components/card/Card";

import { GetHistoricMeetings } from "reducers/userInformation";
import MeetingItem from "./MeetingItem";

const IndividualTrainings = () => {
  const dispatch = useDispatch();
  const meetings = useSelector((state) =>
  state.UserInformation.MeetingHistoric.data
    ? state.UserInformation.MeetingHistoric.data.MeetingHistory
    : null
);
const GetHistoricMeetingsAction = async () => {
      const x = jwt_decode(localStorage.getItem("token"))._id;
      await dispatch(GetHistoricMeetings(x)).unwrap();
  };

  useEffect(() => {
    GetHistoricMeetingsAction();
    console.log("meeting",meetings)
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <>
        {meetings && meetings.length > 0 && (
          <SimpleGrid
            columns={{ base: 1, lg: 3, xl: 3, md: 2 }}
            gap="20px"
            mb="20px"
            mt="20px"
          >
               {meetings.map((item, index) => (
              <MeetingItem item={item} />
            ))}
          </SimpleGrid>
        )}
        </>
      {meetings && meetings.length === 0 && (
        <Card mb="20px">
          <Text textAlign={"center"} fontSize="large">
            No Meetings
          </Text>
        </Card>
      )}
    </Box>
  );
};

export default IndividualTrainings;
