import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Text,
  Heading,
  Center,
  Spinner,
  Box,
  Select,
  HStack,
  Input,
} from "@chakra-ui/react";
import Pagination from "components/pagination/Pagination";
import Card from "components/card/Card";
import UserItem from "./UserItem";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getallUsers } from "reducers/userInformation";
import Loader from "components/loader/Loader";
import TextCard from "components/card/TextCard";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const SelectVoters = (props) => {
  const dispatch = useDispatch();
  const {
    selectedUsers,
    setSelectedUsers,
    activeStep,
    setActiveStep,
    addHackathonHandler,
  } = props;
  const [page, setPage] = useState(1);
  const [userType, setUserType] = useState("");
  const [selectedUsersError, setSelectedUsersError] = useState("");
  const [nameUser, setnameUser] = useState("");
  const users = useSelector(
    (state) => state.UserInformation.usersInformation.data.Users
  );
  const status = useSelector(
    (state) => state.UserInformation.usersInformation.status
  );
  const totalPages = useSelector(
    (state) => state.UserInformation.usersInformation.data.totalPages
  );

  const fetchUsers = async () => {
    const filterdata = {
      page: page,
      limit: 5,
      type: userType,
      name: nameUser,
    };
    dispatch(getallUsers(filterdata));
  };

  useEffect(() => {
    fetchUsers();
  }, [page, userType, nameUser]);

  return (
    <Card>
      <ToastContainer />
      <Heading size="sm" mt="4" mb="4">
        Choose who can vote
      </Heading>
      {!users && status === "loading" && <Loader />}
      {users && (
        <Flex flexDirection={"column"}>
          <HStack spacing={2} mb="3">
            <Input
              variant="outline"
              onChange={(e) => setnameUser(e.target.value)}
              placeholder="Name"
            />
          </HStack>
          <Box>
            <Select
              justifyContent={"end"}
              onChange={(ev) => {
                setUserType(ev.target.value);
              }}
              mb="4"
            >
              <option value="">All</option>
              <option value="Expert">Expert</option>
              <option value="community">Community Member</option>
              <option value="ProjectHolder">Project Holder</option>
            </Select>
            {users.map((item) => (
              <UserItem
                user={item}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
            ))}
            {totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={() => {}}
                />
              </Center>
            )}
          </Box>
          {selectedUsersError && (
            <Text mt="3" color="red">
              {selectedUsersError}
            </Text>
          )}
        </Flex>
      )}
      {users && !users.length && <TextCard text={"There is no user"} />}
      <Flex justifyContent={"space-between"} mt="3">
        <BasicButton
          onClick={() => setActiveStep(activeStep - 1)}
          title={"Previous"}
          variant="solid"
        />
        <BasicButton
          onClick={addHackathonHandler}
          title={"Confirm"}
          variant="solid"
        />
      </Flex>
    </Card>
  );
};

export default SelectVoters;
