import React from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  Spacer,
  HStack,
  Image,
  Button,
  Heading,
  Text,
  Container,
  extendTheme,
  Stack,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
  IconButton,
} from "@chakra-ui/react";
import logo from "assets/img/logo.png";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import Projects from "./components/projects/Projects";
import Hackathons from "./components/hackathons/Hackathons";
import { HamburgerIcon } from "@chakra-ui/icons";
import Values from "./components/landingPageDetails/Values";
import Advantages from "./components/landingPageDetails/Advantages";
import Benefits from "./components/landingPageDetails/Benefits";
import HowWorks from "./components/landingPageDetails/HowWorks";
import JoinUs from "./components/landingPageDetails/JoinUs";
import Footer from "./components/footer";

const theme = extendTheme({
  colors: {
    brand: {
      primary: "#f4f7fe",
      secondary: "#11047a",
      secondaryB: {
        start: "blue",
        end: "#c72c2c",
        direction: "to-r",
      },
      accent: "#FFBB00",
      text: "#FFFFFF",
    },
  },
});
const NavigationItem = ({ children, onClick }) => (
  <Box
    position="relative"
    height="100%"
    _hover={{
      borderBottom: "4px solid #11047a",
      transition: "background 0.3s",
    }}
    _focus={{
      borderBottom: "4px solid #11047a",
      transition: "background 0.3s",
    }}
  >
    <Link
      display="flex"
      alignItems="center"
      height="100%"
      px={4}
      borderBottom="2px solid transparent"
      _hover={{
        textDecoration: "none",
        color: "white",
        borderBottomColor: "white",
        transition: "color 0.3s, border-bottom-color 0.3s",
      }}
      _focus={{
        textDecoration: "none",
        color: "white",
        borderBottomColor: "white",
        transition: "color 0.3s, border-bottom-color 0.3s",
      }}
      onClick={onClick}
    >
      <Text fontSize="lg" fontWeight="bold" color="brand.secondary">
        {children}
      </Text>
    </Link>
  </Box>
);
const LandingPage = () => {
  const history = useHistory();
  const [selectedNavItem, setSelectedNavItem] = useState('home');
  const handleNavigationClick = (navItem) => {
    setSelectedNavItem(navItem);
  };
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <ChakraProvider theme={theme}>
      <Box bg="brand.primary" minH="100vh" color="brand.text">
        <Container maxW="container.xl">
          <Flex p={2} align="center">
            <Box>
              <Image
                src={logo}
                alt="Your Logo"
                width={isSmallScreen ? "70px" : "100px"}
                onClick={() => handleNavigationClick(null)}
              />
            </Box>
            <Spacer />
            {isSmallScreen && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<HamburgerIcon />}
                  variant="outline"
                  color="brand.secondary"
                />
                <MenuList display={{ base: "block", md: "none" }}>
                  <MenuItem onClick={() => handleNavigationClick("home")}>
                    <Text color="brand.secondary">Home</Text>
                  </MenuItem>
                  <MenuItem onClick={() => handleNavigationClick("hackathons")}>
                    <Text color="brand.secondary">Hackathons</Text>
                  </MenuItem>
                  <MenuItem onClick={() => handleNavigationClick("projects")}>
                    <Text color="brand.secondary">Projects</Text>
                  </MenuItem>
                  <MenuItem>
                    <Text
                      color="brand.secondary"
                      onClick={() => history.push("/auth/sign-up")}
                    >
                      Register
                    </Text>
                  </MenuItem>
                  <MenuItem>
                    <Text
                      color="brand.secondary"
                      onClick={() => history.push("/auth/sign-in")}
                    >
                      Login
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            {!isSmallScreen && (
              <>
                <HStack spacing={4}>
                  <Stack direction="row" spacing={4} align="center">
                    <NavigationItem
                      onClick={() => handleNavigationClick("home")}
                    >
                      HOME
                    </NavigationItem>
                    <NavigationItem
                      onClick={() => handleNavigationClick("hackathons")}
                    >
                      HACKATHONS
                    </NavigationItem>
                    <NavigationItem
                      onClick={() => handleNavigationClick("projects")}
                    >
                      PROJECTS
                    </NavigationItem>
                  </Stack>
                </HStack>
                <Spacer />
                <HStack spacing={4}>
                  <HStack spacing={4}>
                    <Button
                      variant="outline"
                      borderColor="#11047a"
                      color="#11047a"
                      _hover={{ bg: "#11047a", color: "white" }}
                      onClick={() => history.push("/auth/sign-in")}
                    >
                      Login
                    </Button>
                    <Button
                      bg="#11047a"
                      color="white"
                      _hover={{ bg: "white", color: "#11047a" }}
                      onClick={() => history.push("/auth/sign-up")}
                    >
                      Register
                    </Button>
                  </HStack>
                </HStack>
              </>
            )}
          </Flex>
        </Container>

        <Box
          bgGradient={`linear(${theme.colors.brand.secondaryB.direction}, ${theme.colors.brand.secondaryB.start}, ${theme.colors.brand.secondaryB.end})`}
          py={20}
        >
          <Container maxW="container.xl" textAlign="center">
            <Heading as="h1" size="2xl">
              Transform Your Ideas into Reality
            </Heading>
            <Text mt={4} fontSize="xl">
              Join a community of innovators and showcase your projects.
            </Text>
            <Button
              bg="white"
              color="#11047a"
              mt={8}
              size="lg"
              onClick={() => history.push("/auth/sign-in")}
            >
              Get Started
            </Button>
          </Container>
        </Box>

        {selectedNavItem !== "hackathons" && selectedNavItem !== "projects" && (
          <Box py={12} bg="brand.primary">
            <Container maxW="container.xl" textAlign="center">
              <Heading as="h2" size="xl" mb={4} color="brand.secondary">
                Ready to start your journey?
              </Heading>
              <Text fontSize="lg" mb={8} color="brand.secondary">
                Join Ecosystem DAO and unleash your creative potential.
              </Text>
              <Button
                bg="#11047a"
                color="white"
                _hover={{ bg: "white", color: "#11047a" }}
                size="lg"
                onClick={() => history.push("/auth/sign-up")}
              >
                Sign Up for Free
              </Button>
            </Container>
          </Box>
        )}
        {(selectedNavItem === "hackathons" ||
          selectedNavItem === "projects") && (
          <Box bg="brand.primary">
            <Container maxW="container.xl">
              {selectedNavItem === "hackathons" && <Hackathons />}
              {selectedNavItem === "projects" && <Projects />}
            </Container>
          </Box>
        )}
      </Box>
      {selectedNavItem === "home" && (
        <>
          <Benefits />
          {/* <HowWorks /> */}
          <Values />
          {/* <Advantages /> */}
          {/* <JoinUs/> */}
        </>
      )}
      <Footer/>

       {/* <Flex
        as="footer"
        align="center"
        justify="space-between"
        bgGradient="linear(to-r, purple.500, blue.500)"
        color="white"
        p="4"
        mt="20px"
        borderTop="1px"
        borderColor="gray.200"
      >
        <Box>
          <Text mb={2}>Pages</Text>
          <Link href="#" mr={4}>
            Home
          </Link>
          <Link href="#" mr={4}>
            About
          </Link>
          <Link href="#" mr={4}>
            Services
          </Link>
          <Link href="#">Contact</Link>
        </Box>
        <Box>
          <Text mb={2}>Legal</Text>
          <Link href="#" mr={4}>
            Terms of Service
          </Link>
          <Link href="#">Privacy Policy</Link>
        </Box>

        <Box>
          <Text fontSize="sm">
            © {new Date().getFullYear()} EcoSystem Dao . All rights reserved.
          </Text>
        </Box>
      </Flex> */}
    </ChakraProvider>
  );
};

export default LandingPage;
