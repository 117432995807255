import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  FormLabel,
  FormControl,
  Textarea,
  Flex,
  Text,
  Heading,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import { ToastContainer } from "react-toastify";
import { funds } from "utils/constants";
import { users } from "utils/constants";

const Step2 = (props) => {
  const { grantsdata, setgrantdata, setStep } = props;
  const [fund, setfunds] = useState("");
  const [proposalSentence, setProposalSentence] = useState("");
  const [proposalSummary, setProposalSummary] = useState("");
  const [build, setBuild] = useState("");
  const [usersNumber, setUsers] = useState("");
  const [competitors, setCompetitors] = useState("");

  const [funderror, setfundserror] = useState("");
  const [proposalSentenceerror, setProposalSentenceerror] = useState("");
  const [proposalSummaryerror, setProposalSummaryerror] = useState("");
  const [builderror, setBuilderror] = useState("");
  const [usersNumbererror, setUserserror] = useState("");
  const [competitorserror, setCompetitorserror] = useState("");
  const validForm = () => {
    let valid = true;
    const date = new Date();
    if (!fund) {
      setfundserror("funds is required!");
      valid = false;
    } else {
      setfundserror("");
    }

    if (!proposalSentence) {
      setProposalSentenceerror("Proposal Sentence is required!");
      valid = false;
    } else {
      setProposalSentenceerror("");
    }
    if (!proposalSummary) {
      setProposalSummaryerror("Proposal Summary is required!");
      valid = false;
    } else {
      setProposalSummaryerror("");
    }
    if (!build) {
      setBuilderror("required!");
      valid = false;
    } else {
      setBuilderror("");
    }
    if (!usersNumber) {
      setUserserror("Users Number is required!");
      valid = false;
    } else {
      setUserserror("");
    }
    if (!competitors) {
      setCompetitorserror("Competitors is required!");
      valid = false;
    } else {
      setCompetitorserror("");
    }
    return valid;
  };

  const confirm = async () => {
    if (!validForm()) {
      return;
    }
    const data = {
      id:grantsdata.id,
      project: grantsdata.project,
      fund:fund,
      proposalSentence: proposalSentence,
      proposalSummary:proposalSummary,
      choiceToBuildOnHedera: build,
      customerCountQuestion: usersNumber,
      competitors: competitors,
      teamSize: 0,
      teamExperience: "",
      startedStatus: "",
      developmentTimeline: "",
      estimatedLaunchDate: "",
      fundingRequestedHBAR: 0,
      allocatedTowards: "",
      codeRepository: "",
      previousFunding: "",
      fromWhere: "",
      hearAboutUs: "",
      video: "",
      pdfPresentation: "",
    };
    setgrantdata(data);
    setStep(3);
  };
  const back = () => {
    setStep(1);
  };
  return (
    <Card>
      <Box>
        <ToastContainer />
        <Card>
          <Heading size="sm" mb="2">
            General Informations
          </Heading>
          <Divider mb="2" />
          <Flex mt="2" direction={"column"}>
            <Text>Choose your Funds?* </Text>
            <Select
              mb="3"
              onChange={(ev) => {
                setfunds(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              width="100%"
            >
              {funds.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </Select>
            {funderror && <Text color="red">{funderror}</Text>}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Textarea
              // isInvalid={descriptionError}
              marginTop={"5px!important"}
              w={"100%"}
              marginRight="10px"
              placeholder="Proposal Sentence!*"
              borderRadius="5px"
              value={proposalSentence}
              onChange={(e) => setProposalSentence(e.target.value)}
            />
            {proposalSentenceerror && (
              <Text color="red">{proposalSentenceerror}</Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Textarea
              // isInvalid={descriptionError}
              marginTop={"5px!important"}
              w={"100%"}
              marginRight="10px"
              placeholder="Proposal Summary!*"
              borderRadius="5px"
              value={proposalSummary}
              onChange={(e) => setProposalSummary(e.target.value)}
            />
            {proposalSummaryerror && (
              <Text color="red">{proposalSummaryerror}</Text>
            )}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Textarea
              // isInvalid={descriptionError}
              marginTop={"5px!important"}
              w={"100%"}
              marginRight="10px"
              placeholder="Why do you choose to build this project on EcoSystem Dao?*"
              borderRadius="5px"
              value={build}
              onChange={(e) => setBuild(e.target.value)}
            />
            {builderror && <Text color="red">{builderror}</Text>}
          </Flex>
          <Flex mt="2" direction={"column"}>
            <Text>How many users does your product already have?*</Text>
            <Select
              mb="3"
              onChange={(ev) => {
                setUsers(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              width="100%"
            >
              {users.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </Select>
            {usersNumbererror && <Text color="red">{usersNumbererror}</Text>}
          </Flex>

          <Flex mt="2" direction={"column"}>
            <Textarea
              marginTop={"5px!important"}
              w={"100%"}
              marginRight="10px"
              placeholder="Who would you consider your competitors?*"
              borderRadius="5px"
              value={competitors}
              onChange={(e) => setCompetitors(e.target.value)}
            />
            {competitorserror && <Text color="red">{competitorserror}</Text>}
          </Flex>
        </Card>
        <Flex justifyContent="end" mt="4">
          <Button
            colorScheme="blue"
            variant="outline"
            size="md"
            mr="2"
            onClick={back}
          >
            Back
          </Button>
          <Button colorScheme="blue" size="md" onClick={confirm}>
            Next
          </Button>
        </Flex>
      </Box>
    </Card>
  );
};

export default Step2;
