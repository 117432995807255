import React, { useState } from "react";
import { Box, Icon, Flex, Text, IconButton } from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { ToastContainer } from "react-toastify";
import { BsDownload, BsFillFileEarmarkImageFill, BsX } from "react-icons/bs";

const allowedImageTypes = [
  "application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
  "application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.presentationml.presentation", 
  "application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
  "text/plain",
  "application/pdf",
  "application/zip"
];
const allowedVideoTypes = [
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/x-msvideo",
];

const UploadBox = ({ title, allowedTypes, setFile, file, settf, round }) => {
  const [files, setFiles] = useState([]);

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles.filter((file) => allowedTypes.includes(file.type)));
    setFile(acceptedFiles);
    settf(file);
  };

  const deleteFile = (file) => {
    setFiles(files.filter((f) => f !== file));
    setFile("");
  };

  const [isDragging, setIsDragging] = useState(false);

  const onDragEnter = () => {
    setIsDragging(true);
  };

  const onDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <Box
      border={isDragging ? "6px dotted #11047A" : "3px solid #6c757d45"}
      m="auto"
      w="100%"
      h="120px"
      p="20px 10px"
      borderRadius="5px"
      style={{ borderRadius: round ? "50%" : null }}
    >
      <Dropzone
        onDragLeave={onDragLeave}
        onDragEnter={onDragEnter}
        onDrop={handleDrop}
        multiple={false}
        accept={allowedTypes}
      >
        {({ getRootProps, getInputProps }) => (
          <Box {...getRootProps()} p="20px">
            <input {...getInputProps()} />
            {files.length === 0 ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Icon as={BsDownload} w={6} h={6} color="grey" />
                <Text color="grey">Upload File</Text>
              </Flex>
            ) : (
              <Flex flexDirection="column" width="100%">
                {files.map((file) => (
                  <Flex
                    justifyContent={"center"}
                    alignContent="center"
                    alignItems="center"
                    key={file.name}
                  >
                    <Icon
                      as={BsFillFileEarmarkImageFill}
                      w={8}
                      h={8}
                      color="black"
                      mr="2"
                    />

                    <Text mr="10px">{file.name.slice(0, 12) + ".."}</Text>
                    <IconButton
                      variant={"outline"}
                      size="sm"
                      onClick={() => deleteFile(file)}
                      icon={<Icon as={BsX} w={6} h={6} color="black" />}
                    />
                  </Flex>
                ))}
              </Flex>
            )}
          </Box>
        )}
      </Dropzone>
    </Box>
  );
};

const UploadPDF = (props) => {
  const { f, ms, sf } = props;
  const [files, setFiles] = useState([]);

  const PuttingFiles = (f) => {
    setFiles([...files, ...f]);
    props.setFiles(f);
  };
  return (
    <Flex flexDirection="column">
      <ToastContainer />

      <Flex>
        <Flex flexDirection={"column"} mr="3">
          <Text color="#1B2559" fontWeight="700" fontSize="20px"></Text>
          <UploadBox
            setFile={PuttingFiles}
            settf={sf}
            file={files}
            allowedTypes={allowedImageTypes}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UploadPDF;
