import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { applyOpportunityService } from "services/callofStartupsService";
import { RequestOpportunityService } from "services/callofStartupsService";
import { getRequestCallofstartupByIdOwnerService } from "services/callofStartupsService";
import { getRequestCallofstartupByIdService } from "services/callofStartupsService";
import { updateCOSRequestStatusService } from "services/callofStartupsService";
import { getRequestsByCOSService } from "services/callofStartupsService";
import { GetOpportunityDetailsService } from "services/callofStartupsService";
import { addOpportunityService } from "services/callofStartupsService";

export const addOpportunity = createAsyncThunk(
  "Opportunity/add",
  async (opportunitesData, thunkAPI) => {
    try {
      const { status, data } = await addOpportunityService(
        opportunitesData.Oppdata
      );
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getDetailsOpportunity = createAsyncThunk(
  "Opportunity/getDetailsOpportunity",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await GetOpportunityDetailsService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getRequestsByCOS = createAsyncThunk(
  "COS/requests",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getRequestsByCOSService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateCOSRequestStatus = createAsyncThunk(
  "COS/update-request-status",
  async (updateData, thunkAPI) => {
    try {
      const { status, data } = await updateCOSRequestStatusService(updateData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const applyOpportunity = createAsyncThunk(
  "Opportunity/apply",
  async (applyData, thunkAPI) => {
    try {
      const { status, data } = await applyOpportunityService(
        applyData.Oppdata
      );
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const RequestOpportunity = createAsyncThunk(
  "Opportunity/apply",
  async (applyData, thunkAPI) => {
    try {
      const { status, data } = await RequestOpportunityService(applyData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getRequestCallofstartupById = createAsyncThunk(
  "Opportunity/getRequestCallofstartupByIdService",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getRequestCallofstartupByIdService(id);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  addOpp: {
    status: "idle",
    data: {},
    error: {},
  },
  getOpp: {
    status: "idle",
    data: {},
    error: {},
  },
  applyOpp: {
    status: "idle",
    data: {},
    error: {},
  },
  requests: {
    status: "idle",
    data: {},
    error: {},
  },
  requestStatus: {
    status: "idle",
    data: {},
    error: {},
  },
  requestOppHistoric: {
    status: "idle",
    data: {},
    error: {},
  },
  requestCallofstartupById: {
    status: "idle",
    data: {},
    error: {},
  },
};

const OpportunitiesSlice = createSlice({
  name: "Opportunities",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [addOpportunity.pending.type]: (state) => {
      state.addOpp = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addOpportunity.fulfilled.type]: (state, action) => {
      state.addOpp = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addOpportunity.rejected.type]: (state, action) => {
      state.addOpp = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getDetailsOpportunity.pending.type]: (state) => {
      state.getOpp = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getDetailsOpportunity.fulfilled.type]: (state, action) => {
      state.getOpp = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getDetailsOpportunity.rejected.type]: (state, action) => {
      state.getOpp = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getRequestsByCOS.pending.type]: (state) => {
      state.requests = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getRequestsByCOS.fulfilled.type]: (state, action) => {
      state.requests = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getRequestsByCOS.rejected.type]: (state, action) => {
      state.requests = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateCOSRequestStatus.pending.type]: (state) => {
      state.requestStatus = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateCOSRequestStatus.fulfilled.type]: (state, action) => {
      state.requestStatus = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateCOSRequestStatus.rejected.type]: (state, action) => {
      state.requestStatus = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [applyOpportunity.pending.type]: (state) => {
      state.applyOpp = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [applyOpportunity.fulfilled.type]: (state, action) => {
      state.applyOpp = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [applyOpportunity.rejected.type]: (state, action) => {
      state.applyOpp = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [RequestOpportunity.pending.type]: (state) => {
      state.requestOppHistoric = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [RequestOpportunity.fulfilled.type]: (state, action) => {
      state.requestOppHistoric = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [RequestOpportunity.rejected.type]: (state, action) => {
      state.requestOppHistoric = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getRequestCallofstartupById.pending.type]: (state) => {
      state.requestCallofstartupById = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getRequestCallofstartupById.fulfilled.type]: (state, action) => {
      state.requestCallofstartupById = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getRequestCallofstartupById.rejected.type]: (state, action) => {
      state.requestCallofstartupById = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default OpportunitiesSlice.reducer;
