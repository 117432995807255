import Api from "config/axios/api";
import config from "../config/axios/config";
export const getStaticsService = async () => {
  const url = config.apiUrl + "/admin/getStatic";
  try {
    return await Api.get(url,        {headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getStaticsEcoSystemMembersService = async () => {
  const url = config.apiUrl + "/admin/getStatic";
  try {
    return await Api.get(url,        {headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getprojectperMonthService = async (yearMonth) => {
  const url = config.apiUrl + "/admin/projectperMonth";
  try {
    return await Api.post(url,{"monthyear":yearMonth},{headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getuserAccountCreationCountByMonthService = async (yearMonth) => {
  const url = config.apiUrl + "/admin/userAccountCreationCountByMonth";
  try {
    return await Api.post(url,{"monthyear":yearMonth},{headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getCohortCreationCountByMonthService = async (yearMonth) => {
  const url = config.apiUrl + "/admin/CohortCreationCountByMonth";
  try {
    return await Api.post(url,{"monthyear":yearMonth},{headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getvotesessionCountByMonthService = async (yearMonth) => {
  const url = config.apiUrl + "/admin/votesessionCountByMonth";
  try {
    return await Api.post(url,{"monthyear":yearMonth},{headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getReviewCountByMonthService = async (yearMonth) => {
  const url = config.apiUrl + "/admin/ReviewCountByMonth";
  try {
    return await Api.post(url,{"monthyear":yearMonth},{headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const gettopcategorymeetService = async () => {
  const url = config.apiUrl + "/admin/topcategorymeet";
  try {
    return await Api.get(url,{headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const topvotedcohortService = async () => {
  const url = config.apiUrl + "/admin/topvotedcohot";
  try {
    return await Api.get(url,{headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getstatisticpagesService = async (page) => {
  const url = config.apiUrl + "/statistic-pages";
  try {
    return await Api.put(url,{page},{headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {getstatisticpagesService,getStaticsEcoSystemMembersService,topvotedcohortService,gettopcategorymeetService,getReviewCountByMonthService,getStaticsService,getprojectperMonthService,getuserAccountCreationCountByMonthService,getCohortCreationCountByMonthService,getvotesessionCountByMonthService};
