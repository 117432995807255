import Api from "config/axios/api";
import config from "../config/axios/config";
export const HistoricReviewAdminService = async (dataR) => {
  const url = config.apiUrl + "/meetingReview/get/";
  try {
    return await Api.post(url,dataR,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const HistoricReviewDetailsAdminService = async (id) => {
  const url = config.apiUrl + "/meetingReview/" + id;
  try {
    return await Api.get(url,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {HistoricReviewAdminService,HistoricReviewDetailsAdminService};
