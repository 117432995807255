import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gettopcategorymeet } from "reducers/dashboard";
import StatsChart from "./StatsChart";

export default function MeetingsByTopicStats() {
  const dispatch = useDispatch();
  const [xValues, setxValues] = useState([]);
  const [yValues, setyValues] = useState([]);

  const data = useSelector((state) =>
    state.Dashboard.topcategorymeet.data
      ? state.Dashboard.topcategorymeet.data.result
      : []
  );
  const status = useSelector((state) =>
    state.Dashboard.topcategorymeet.status
      ? state.Dashboard.topcategorymeet.status
      : []
  );

  const getMeetingsByTopicAction = async () => {
    await dispatch(gettopcategorymeet()).unwrap();
  };

  useEffect(() => {
    if (status == "success") {
      const categories = data.map((item) => item.category);
      setxValues(categories);
      const nbProjects = data.map((item) => item.meetCount);
      setyValues(nbProjects);
    }
  }, [status, data]);

  const config = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: xValues,
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      fill: {
        colors: ["#008000a3"],
      },
    },
    series: [
      {
        name: "top category per meet",
        data: yValues,
      },
    ],
  };

  useEffect(() => {
    getMeetingsByTopicAction();
  }, []);

  return (
    <StatsChart
      title={"Meetings by Topic"}
      config={config}
      data={data}
      noDataText={"There is no Meetings"}
      showFilter={false}
      setMonthYearFilter={() => {}}
      type={"bar"}
    />
  );
}
