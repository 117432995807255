import React, { useState } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  Box,
  Flex,
  Button,
  Image,
  useColorModeValue,
  Badge,
  Stack,
  Heading,
  Divider,
  ButtonGroup,
} from "@chakra-ui/react";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { Link as Linkk } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateCOSRequestStatus } from "reducers/callofStartups";

export default function CallOfStartupsRequestItem(props) {
  const { item } = props;
  const dispatch = useDispatch();
  const [badgeColor, setBadgeColor] = useState("");
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "none"
  );
  useEffect(() => {
    if (item.status === "accepted") {
      setBadgeColor("green");
    } else if (item.status === "rejected") {
      setBadgeColor("red");
    } else if (item.status === "pending") {
      setBadgeColor("yellow");
    }
  }, [item]);

  const updateStatusRequestAction = async (status) => {
    const updateData = {
      id: item._id,
      status: status,
    };
    await dispatch(updateCOSRequestStatus(updateData)).unwrap();
    window.location.reload(false);
  };
  return (
    <Card boxShadow={shadow} maxW="100%" p="15px">
      <Stack>
        <Box>
          <Image
            boxSize="150px"
            width="100%"
            objectFit="cover"
            src={config.apiUrl + "/api/getFileEvent/" + item.project.images}
            alt=""
            borderRadius="lg"
            fallbackSrc={placeholder}
          />
        </Box>
        <Stack mt="6" spacing="3">
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Heading size="md">{item.project.title} </Heading>
            <Badge variant="subtle" colorScheme={badgeColor} size="sm">
              {item.status}
            </Badge>
          </Flex>
          <Text className="threelines">{item.project.description}</Text>
        </Stack>
      </Stack>
      <Divider mt="2" />
      <Stack mt="2">
        <ButtonGroup spacing="2" justifyContent={"space-between"}>
          <Button variant="solid" colorScheme="blue" size="sm">
            View
          </Button>
          <Flex>
            <Button
              variant="solid"
              colorScheme="green"
              size="sm"
              mr="2"
              onClick={() => updateStatusRequestAction("accepted")}
            >
              Accept
            </Button>
            <Button
              size="sm"
              variant="solid"
              colorScheme="red"
              onClick={() => updateStatusRequestAction("rejected")}
            >
              Refuse
            </Button>
          </Flex>
        </ButtonGroup>
      </Stack>
    </Card>
  );
}
