import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { SocketContext } from "contexts/SocketContext";
import { addCohort } from "reducers/cohort";
import { useHistory } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import HackathonInfoForm from "./components/addHackathon/HackathonInfoForm";
import HackathonMediaUpload from "./components/addHackathon/HackathonMediaUpload";
import PrizesForm from "./components/addHackathon/PrizesForm";
import AboutHackathonForm from "./components/addHackathon/AboutHackathonForm";
import EligibilityconditionsForm from "./components/addHackathon/EligibilityconditionsForm";
import { useEffect } from "react";
import SelectVoters from "./components/addHackathon/SelectVoters";
import RulesForm from "./components/addHackathon/RulesForm";
import { addHackathon } from "reducers/hackathon";

const AddHackathon = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const ConstSteps = [
    { title: "Hackathon details", onClick: () => setActiveStep(0) },
    { title: "Media", onClick: () => setActiveStep(1) },
    { title: "Prizes", onClick: () => setActiveStep(2) },
    { title: "About Hackathon", onClick: () => setActiveStep(3) },
    { title: "Judging criteria", onClick: () => setActiveStep(4) },
    { title: "Rules", onClick: () => setActiveStep(5) },
    { title: "Voters", onClick: () => setActiveStep(6) },
  ];
  const [steps, setSteps] = useState(ConstSteps);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [prizes, setPrizes] = useState([]);
  const [image, setImage] = useState([]);
  const [video, setVideo] = useState([]);
  const [selectedWhatToSubmit, setSelectedWhatToSubmit] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [rules, setRules] = useState([]);
  const [whatToBuild, setWhatToBuild] = useState("");
  const [HackathonData, setHackathonData] = useState({
    title: "",
    general: false,
    description: "",
    managerEmail: "",
    end: null,
    start: null,
    typeOfCohort: "Hackathon",
  });
  const notificationsSettings = useSelector((state) =>
    state.NotificationsSettings.notificationsSettings.data
      ? state.NotificationsSettings.notificationsSettings.data.message
      : []
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const status = useSelector((state) => state.Cohort.addCohort.status);

  const addHackathonHandler = async () => {
    const hackathon = new FormData();
    hackathon.append("end", HackathonData.end);
    hackathon.append("start", HackathonData.start);
    hackathon.append("title", HackathonData.title);
    hackathon.append("description", HackathonData.description);
    hackathon.append("general", HackathonData.general);
    hackathon.append("image", image[0]);
    hackathon.append("video", video[0]);
    // hackathon.append("projects", []);
    // hackathon.append("typeOfCohort", "Hackathon");
    const selectedSubmit = selectedWhatToSubmit.map((i) => i.value);
    hackathon.append("submitTypes", JSON.stringify(selectedSubmit));
    hackathon.append("whatToBuild", whatToBuild);
    hackathon.append("judgingCriteria", JSON.stringify(criteria));
    hackathon.append("managerEmail", HackathonData.managerEmail);
    hackathon.append("voters", JSON.stringify(selectedUsers));
    hackathon.append("prizes", JSON.stringify(prizes));
    hackathon.append("rules", JSON.stringify(rules));

    const data = {
      hackathon,
      // roles: notificationsSettings
      //   ? notificationsSettings.AddingNewCohorte
      //   : null,
      // currentUserId: currentUser ? currentUser._id : null,
      // socket: socket,
    };
    await dispatch(addHackathon(data)).unwrap();
    history.push({
      pathname: "/admin/hackathons",
      state: { add: true },
    });
  };
  // useEffect(() => {
  //   if (HackathonData.general === true) {
  //     setSteps([
  //       ...ConstSteps,
  //       { title: "Voters", onClick: () => setActiveStep(6) },
  //     ]);
  //   } else {
  //     setSteps(ConstSteps);
  //   }
  // }, [HackathonData]);
  return (
    <>
      <Flex
        flexDirection={"column"}
        pt={{ base: "130px", md: "80px", xl: "80px" }}
      >
        <Card>
          <Stepper steps={steps} activeStep={activeStep} />
          <div style={{ padding: "20px" }}>
            {activeStep === 0 && (
              <HackathonInfoForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                setHackathonData={setHackathonData}
                HackathonData={HackathonData}
              />
            )}
            {activeStep === 1 && (
              <HackathonMediaUpload
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                setImage={setImage}
                image={image}
                setVideo={setVideo}
                video={video}
              />
            )}
            {activeStep === 2 && (
              <PrizesForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                prizes={prizes}
                setPrizes={setPrizes}
                addHackathonHandler={addHackathonHandler}
                isLoading={status === "loading"}
              />
            )}
            {activeStep === 3 && (
              <AboutHackathonForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                selectedWhatToSubmit={selectedWhatToSubmit}
                setSelectedWhatToSubmit={setSelectedWhatToSubmit}
                setWhatToBuild={setWhatToBuild}
                whatToBuild={whatToBuild}
                addHackathonHandler={addHackathonHandler}
                isLoading={status === "loading"}
              />
            )}
            {activeStep === 4 && (
              <EligibilityconditionsForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                addHackathonHandler={addHackathonHandler}
                setCriteria={setCriteria}
                criteria={criteria}
                HackathonData={HackathonData}
                isLoading={status === "loading"}
              />
            )}
            {activeStep === 5 && (
              <RulesForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                addHackathonHandler={addHackathonHandler}
                setRules={setRules}
                HackathonData={HackathonData}
                rules={rules}
                isLoading={status === "loading"}
              />
            )}
            {activeStep === 6 && (
              <SelectVoters
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                addHackathonHandler={addHackathonHandler}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                isLoading={status === "loading"}
              />
            )}
          </div>
        </Card>
      </Flex>
    </>
  );
};

export default AddHackathon;
