import React, { useState, useEffect } from "react";
import {
  Button,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { Input, Stack } from "@chakra-ui/react";
import "./ProfileSettings.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { updateHourlyPrice } from "reducers/userInformation";
import { checkIsNumber } from "utils/functions";

const ExpertHourlyPrice = (props) => {
  const { user, getUser } = props;
  const dispatch = useDispatch();
  const [hourlyPrice, setHourlyPrice] = useState();
  const [hourlyPriceError, setHourlyPriceError] = useState("");
  useEffect(() => {
    if (user && user.message) {
      setHourlyPrice(user.message.hourPrice);
    }
  }, [user]);
  const notify = () =>
    toast.success("Your hourly price was successfully updated!", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });
  const updateHourlyPriceHandler = async () => {
    if (!checkIsNumber(hourlyPrice)) {
      setHourlyPriceError("Invalid price !");
      return;
    } else {
      setHourlyPriceError("");
    }
    const x = { hourRate: hourlyPrice };
    await dispatch(updateHourlyPrice(x)).unwrap();
    notify();
  };
  const updateHourlyPriceStatus = useSelector(
    (state) => state.UserInformation.updateHourlyPrice.status
  );
  useEffect(() => {
    if (updateHourlyPriceStatus === "success") {
      getUser();
    }
  }, [updateHourlyPriceStatus]);

  return (
    <Stack
      padding="8"
      display={"flex"}
      flexDirection={"column"}
      borderRadius={"30px"}
      backgroundColor={"white"}
    >
      <h3 className="SettingTitle">Price</h3>
      <Text marginBottom={"24px!important"} mt="0" color="gray.500" isTruncated>
        The changes you'll make will appear on your profile
      </Text>

      <Text fontWeight={"700"} color="#1B2559" fontSize={"14px"}>
        Hourly price
      </Text>
      <Flex flexDirection={"column"}>
        <InputGroup>
          <Input
            value={hourlyPrice}
            onChange={(e) => {
              setHourlyPrice(e.target.value);
            }}
            placeholder="Hourly price"
          />
          <InputRightElement
            pointerEvents="none"
            color="gray.400"
            fontSize="0.8em"
            children="DT"
          />
        </InputGroup>
        {hourlyPriceError && (
          <Text mb="2" color="red">
            {hourlyPriceError}
          </Text>
        )}
      </Flex>
      <Flex justifyContent="flex-end" alignItems={"end"}>
        <Button
          variant="brand"
          onClick={() => updateHourlyPriceHandler()}
          marginTop={"24px!important"}
          size="md"
          width="180px"
        >
          Save changes
        </Button>
      </Flex>
    </Stack>
  );
};

export default ExpertHourlyPrice;
