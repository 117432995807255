import React from "react";
import Card from "components/card/Card.js";
import { Text, Flex, Divider,Box} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";
import { FaCalendar } from "react-icons/fa";

export default function AssistinProgramsInfos(props) {
  const { assistingProgram } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Flex direction={"column"}>
      {" "}
      <Flex justifyContent={"space-between"}>
        <Text
          color={textColor}
          mr="4px"
          fontSize={"2xl"}
          mb="5px"
          fontWeight="bold"
          me="14px"
        >
          {assistingProgram.title}
        </Text>
      </Flex>
      <Flex justifyContent={"space-between"} mt="3">
        <Text
          me="14px"
          width="60%"
        >
          {assistingProgram.description}
        </Text>
        <Flex direction={"column"} fontSize={"md"} width="40%">
          <Box
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            height="max-content"
            backgroundColor="#9e9e9e14"
            textAlign={"start"}
          >
            {assistingProgram && assistingProgram.endDateSubmission && (
              <>
                <Flex direction="column">
                  <Flex
                    fontWeight={"700"}
                    direction="row"
                    alignItems={"center"}
                    color="#11047a"
                  >
                    <FaCalendar />
                    <Text ml="2" fontWeight={"bold"}>
                      Submission Deadline
                    </Text>
                  </Flex>
                  <Text fontWeight={"400"}>
                    {moment(assistingProgram.endDateSubmission).format(
                      "llll"
                    )}
                  </Text>
                </Flex>
                <Divider borderColor="lightGray" my={2} />
              </>
            )}
            <Flex direction="column">
              <Flex
                fontWeight={"700"}
                direction="row"
                alignItems={"center"}
                color="#11047a"
              >
                <FaCalendar />
                <Text ml="2" fontWeight={"bold"}>
                  Start Date
                </Text>
              </Flex>
              <Text fontWeight={"400"}>
                {moment(assistingProgram.start).format("llll")}
              </Text>
            </Flex>
            <Divider borderColor="lightGray" my={2} />
            <Flex direction="column">
              <Flex
                fontWeight={"700"}
                direction="row"
                alignItems={"center"}
                color="#11047a"
              >
                <FaCalendar />
                <Text ml="2" fontWeight={"bold"}>
                  End Date
                </Text>
              </Flex>
              <Text fontWeight={"400"}>
                {moment(assistingProgram.end).format("llll")}
              </Text>
            </Flex>
            
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
