import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import TextareaField from "components/fields/TextareaField";
import BasicButton from "components/button/BasicButton";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { update } from "reducers/hackathon";

const EditRule = ({ isOpen, onClose, initialData, item, onReload }) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const validateForm = () => {
    let valid = true;

    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    return valid;
  };
  const onEditHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    let rules = [];
    if (item) {
      rules = initialData.rules.map((i) =>
        i._id === item._id
          ? { _id: i._id, title: title, description: description }
          : i
      );
    } else {
      rules = [
        ...initialData.rules,
        { title: title, description: description },
      ];
    }
    const data = new FormData();

    data.append("rules", JSON.stringify(rules));

    const hackathonData = {
      data,
      id: initialData._id,
    };
    await dispatch(update(hackathonData)).unwrap();
    onReload();
    onClose();
  };
  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [title, description]);

  useEffect(() => {
    if (item) {
      setTitle(item.title);
      setDescription(item.description);
    }
  }, [item]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item ? "Edit Rule" : "Add Rule"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mb="3">
              <InputField
                label="Title"
                placeholder="Title"
                value={title}
                setValue={setTitle}
                error={titleError}
                type="text"
              />
            </Flex>
            <Flex mb="3">
              <TextareaField
                label="Description"
                placeholder="Description"
                value={description}
                setValue={setDescription}
                error={descriptionError}
              />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={onEditHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditRule;
