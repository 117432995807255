import Card from "components/card/Card.js";
import Pagination from "components/pagination/Pagination";
import { useColorModeValue } from "@chakra-ui/system";
import {
  Center,
  Select,
  Text,
  Box,
  Flex,
  Button,
  Input,
  Textarea,
  Heading,
  Divider,
  Checkbox,
  Image,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { toast, ToastContainer } from "react-toastify";
import { SocketContext } from "contexts/SocketContext";
import { getallProjects } from "reducers/project";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";
import { addCohort } from "reducers/cohort";
import { Link as Linkk } from "react-router-dom";
import MySelect from "react-select";
import { MeetingReviewReducer } from "reducers/callOfVoting";
import { useParams } from "react-router-dom";

const AddReviewProjectHolder = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [loader, setLoader] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [selectedOptions, setSelectedOptions] = useState();
  const [rateoverall, setrateoverall] = useState();
  const [ratethesession, setratethesession] = useState();
  const [summary, setSummary] = useState();
  const [summaryError, setsummaryError] = useState();

  const [rate, setrate] = useState();
  const [rateError, setrateError] = useState();

  const [followupsession, setfollowupsession] = useState();
  const [followupsessionError, setfollowupsessionError] = useState();

  const [tasks, settasks] = useState("");
  const [tasksError, settasksError] = useState();

  const [keyareas, setkeyareas] = useState();
  const [keyareasError, setkeyareasError] = useState();
  const [meeting, setmeeting] = useState();
  const [meetingError, setmeetingError] = useState();
  const [conditions, setconditions] = useState();
  const [conditionsError, setconditionsError] = useState();
  const [objectif, setobjectif] = useState();
  const [objectifError, setobjectifError] = useState();
  const [recommendations, setrecommendations] = useState();
  const [recommendationsError, setrecommendationsError] = useState();
  const [prerequisites, setprerequisites] = useState();
  const [prerequisitesError, setprerequisitesError] = useState();
  const [Whathappen, setWhathappen] = useState();
  const [NextSession, setNextSession] = useState("");

  const [WhathappenError, setWhathappenError] = useState();

  const validateForm = () => {
    let valid = true;
    if (!recommendations) {
      setrecommendationsError(" commendations required!");
      valid = false;
    } else {
      setrecommendationsError("");
    }
    if (!summary) {
      setsummaryError(" summary required!");
      valid = false;
    } else {
      setsummaryError("");
    }
    if (!rate) {
      setrateError("rate required!");
      valid = false;
    } else {
      setrateError("");
    }
    if (!keyareas) {
      setkeyareasError("key areas required!");
      valid = false;
    } else {
      setkeyareasError("");
    }
    if (!tasks) {
      settasksError("tasks required!");
      valid = false;
    } else {
      settasksError("");
    }
    if (!meeting) {
      setmeetingError(" meeting required!");
      valid = false;
    } else {
      setmeetingError("");
    }
    // if (!Whathappen) {
    //   setWhathappenError("required!");
    //   valid = false;
    // } else {
    //   setWhathappenError("");
    // }
    return valid;
  };
  const validateForm2 = () => {
    let valid2 = true;

     if (!Whathappen) {
        setWhathappenError("required!");
        valid2 = false;
      } else {
        setWhathappenError("");
      }
    return valid2;
  };
  const MeetingReviewReducerAction = async () => {
    if (!validateForm() && !validateForm2()) {
      return;
    }
    const comp = {
      Whathappen: Whathappen,
    };
    const questions = {
      summary: summary,
      rate: rate,
      tasks: tasks,
      keyareas: keyareas,
      meetings: {
        meeting: meeting,
        conditions: conditions ? conditions : "",
        NextSession: NextSession ? NextSession : "",
      },
    };
    console.log(id, "kakakalalalaal");
    const data = {
      idMeet: id,
      questions: objectif == "Review" ? questions : comp,
      ToExpert: false,
      typeReview: objectif,
    };

    await dispatch(MeetingReviewReducer(data))
      .unwrap()
      .then((response) => {
        console.log(response, "gigigigigi");
        toast.success(response.message, {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
        window.location.href = "/#/admin/MeetingsExpert";
      })
      .catch((error) => {
        toast.info(error.message, {
          position: "top-right",
          hideProgressBar: true,
          theme: "light",
        });
      });
  };
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <ToastContainer />

        <Card>
          <Heading size="sm" mb="2">
            General Informations
          </Heading>
          <Divider mb="2" />
          <Flex mt="2" direction={"column"}>
            <Text>What is your goal from this form</Text>

            <Select
              justifyContent={"end"}
              onChange={(ev) => {
                setobjectif(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            >
              <option value="">---</option>
              <option value="Complaint">Complaint</option>
              <option value="Review">Review</option>
            </Select>
            {objectifError && (
              <Text mt="3" color="red">
                {objectifError}
              </Text>
            )}
          </Flex>
          <Flex mt="2" alignItems={"center"}></Flex>
          {objectif == "Review" && (
            <>
              <Flex mt="2" direction={"column"}>
                <Textarea
                  marginTop={"5px!important"}
                  w={"100%"}
                  marginRight="10px"
                  onChange={(e) => {
                    setSummary(e.target.value);
                  }}
                  placeholder="Can you provide a brief summary of the session?"
                  borderRadius="5px"
                />
                {summaryError && (
                  <Text mt="3" color="red">
                    {summaryError}
                  </Text>
                )}
              </Flex>
              <Flex mt="2" direction={"column"}>
                <Text>
                  How would you rate the entrepreneur's understanding and
                  receptivity during the session?{" "}
                </Text>
                <Select
                  justifyContent={"end"}
                  onChange={(ev) => {
                    setrate(ev.target.value);
                  }}
                  name="category"
                  id="category"
                  borderColor={"#E0E5F2"}
                  backgroundColor="white"
                >
                  <option value="">---</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Select>
                {rateError && (
                  <Text mt="3" color="red">
                    {rateError}
                  </Text>
                )}
              </Flex>
              <Flex mt="2" direction={"column"}>
                <Textarea
                  marginTop={"5px!important"}
                  w={"100%"}
                  marginRight="10px"
                  onChange={(e) => {
                    settasks(e.target.value);
                  }}
                  placeholder=" What is the current status of the tasks that the entrepreneur needs to complete?"
                  borderRadius="5px"
                />
                {tasksError && (
                  <Text mt="3" color="red">
                    {tasksError}
                  </Text>
                )}
              </Flex>
              <Flex mt="2" direction={"column"}>
                <Textarea
                  marginTop={"5px!important"}
                  w={"100%"}
                  marginRight="10px"
                  onChange={(e) => {
                    setkeyareas(e.target.value);
                  }}
                  placeholder="What are the key areas the entrepreneur should focus on?"
                  borderRadius="5px"
                />
                {keyareasError && (
                  <Text mt="3" color="red">
                    {keyareasError}
                  </Text>
                )}
                <Flex mt="2" direction={"column"}>
                  <Textarea
                    marginTop={"5px!important"}
                    w={"100%"}
                    marginRight="10px"
                    onChange={(e) => {
                      setrecommendations(e.target.value);
                    }}
                    placeholder="What are your recommendations for the entrepreneur in terms of upskilling and other assistance needed?"
                    borderRadius="5px"
                  />

                  {recommendationsError && (
                    <Text mt="3" color="red">
                      {recommendationsError}
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex mt="2" direction={"column"}>
                <Text>Do you think there is a need for another meeting?</Text>
                <Select
                  justifyContent={"end"}
                  onChange={(ev) => {
                    // handleFilter(ev.target.value);
                    setmeeting(ev.target.value);
                  }}
                  name="category"
                  id="category"
                  borderColor={"#E0E5F2"}
                  backgroundColor="white"
                >
                  <option value="">---</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>

                {meetingError && (
                  <Text mt="3" color="red">
                    {meetingError}
                  </Text>
                )}
              </Flex>
              {meeting == "Yes" && (
                <>
                  <Flex mt="2" direction={"column"}>
                    <Textarea
                      marginTop={"5px!important"}
                      w={"100%"}
                      marginRight="10px"
                      onChange={(e) => {
                        setconditions(e.target.value);
                      }}
                      placeholder="If yes, under what conditions should the next meeting be planned?"
                      borderRadius="5px"
                    />
                  </Flex>
                  <Flex mt="2" direction={"column"}>
                    <DatePicker
                      inputClass={"fullwidth"}
                      format="MM/DD/YYYY HH:mm"
                      plugins={[
                        <TimePicker position="bottom" hideSeconds={true} />,

                        <DatePanel
                          markFocused
                          header="When do you suggest the next meeting should take place?"
                        />,
                      ]}
                      placeholder="When do you suggest the next meeting should take place?"
                      id="date"
                      name="date"
                      value={NextSession[0]}
                      onChange={(ev) =>
                        setNextSession(
                          ev.year +
                            "/" +
                            (ev.month.index + 1).toString() +
                            "/" +
                            ev.day +
                            " " +
                            ev.hour +
                            ":" +
                            ev.minute
                        )
                      }
                    />
                  </Flex>
                </>
              )}
            </>
          )}
          {objectif == "Complaint" && (
            <Flex mt="2" direction={"column"}>
              <Textarea
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="What happen ? "
                borderRadius="5px"
                onChange={(ev) => {
                  setWhathappen(ev.target.value);
                }}
              />
              {WhathappenError && (
                <Text mt="3" color="red">
                  {WhathappenError}
                </Text>
              )}
            </Flex>
          )}
          <Flex justifyContent="end" mt="4">
            <Button
              colorScheme="blue"
              size="md"
              onClick={MeetingReviewReducerAction}
            >
              Confirm
            </Button>
          </Flex>
        </Card>
      </Box>
    </>
  );
};

export default AddReviewProjectHolder;
