import Card from "components/card/Card.js";
import {
  Text,
  Textarea,
  Flex,
  Button,
  Heading,
  Divider,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { applyOpportunity } from "reducers/callofStartups";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getRequestCallofstartupById } from "reducers/callofStartups";
import { useSelector } from "react-redux";

const Step4 = (props) => {
  const { applyData, setApplyData, setStep ,requestCall} = props;
  const { idrequest, id } = useParams();
  const [productDescription, setProductDescription] = useState(
    requestCall ? requestCall.ProductDescription : applyData.productDescription
  );
  const [developmentStage, setDevelopmentStage] = useState(
    requestCall ? requestCall.DevelopmentStage : applyData.developmentStage
  );
  const [relevantExperience, setRelevantExperience] = useState(
    requestCall ? requestCall.RelevantExperience : applyData.relevantExperience
  );
  const [errorproductDescription, setErrorproductDescription] = useState("");
  const [errordevelopmentStage, setErrordevelopmentStage] = useState("");
  const [errorrelevantExperience, setErrorRelevantExperience] = useState("");
  const dispatch = useDispatch();

  const validateForm = () => {
    let valid = true;
    if (!productDescription) {
      setErrorproductDescription("This filed are required");
      valid = false;
    } else {
      setErrorproductDescription("");
    }
    if (!developmentStage) {
      setErrordevelopmentStage("This filed are required");
      valid = false;
    } else {
      setErrordevelopmentStage("");
    }
    if (!relevantExperience) {
      setErrorRelevantExperience("This filed are required");
      valid = false;
    } else {
      setErrorRelevantExperience("");
    }
    return valid;
  };
  const confirm = async () => {
    if (!validateForm()) {
      return;
    }
    const Oppdata = new FormData();
    Oppdata.append("callOfStartup", id);
    Oppdata.append("project", requestCall ? requestCall.project._id : applyData.project);
    Oppdata.append("ProductDescription", productDescription);
    Oppdata.append("DevelopmentStage", developmentStage);
    Oppdata.append("RelevantExperience", relevantExperience);
    Oppdata.append("step", 5);

    const dataaddOpp = {
      Oppdata,
    };
    await dispatch(applyOpportunity(dataaddOpp)).unwrap();
    const data = {
      ...applyData,
      productDescription,
      developmentStage,
      relevantExperience,
    };
    setApplyData(data);
    setStep(5);
  };
  const back = () => {
    setStep(3);
    setProductDescription("");
    setDevelopmentStage("");
    const data = {
      ...applyData,
      productDescription: "",
      developmentStage: "",
    };
    setApplyData(data);
  };
  return (
    <Card>
      <Heading size="sm" mb="2">
        Product/Service
      </Heading>
      <Divider mb="2" />
      <Flex mt="2" flexDirection={"column"}>
        <Text>
          Product/Service Description: (Provide a detailed description of your
          product or service.)
        </Text>
        <Textarea
          mb="2"
          onChange={(e) => {
            setProductDescription(e.target.value);
          }}
          value={productDescription}
          placeholder="Product/Service Description"
        />
        {errorproductDescription && (
          <Text color="red">{errorproductDescription}</Text>
        )}

        <Text>
          Stage of Development: (Is it just an idea, MVP, fully developed,
          etc.?)
        </Text>
        <Textarea
          mb="2"
          onChange={(e) => {
            setDevelopmentStage(e.target.value);
          }}
          value={developmentStage}
          placeholder="Stage of Development"
        />
        {errordevelopmentStage && (
          <Text color="red">{errordevelopmentStage}</Text>
        )}
        <Text>
          Relevant Experience: (Highlight any relevant experience or
          achievements of the team that would be of interest to investors.)
        </Text>
        <Textarea
          mb="2"
          onChange={(e) => {
            setRelevantExperience(e.target.value);
          }}
          value={relevantExperience}
          placeholder="Relevant Experience"
        />
        {errorrelevantExperience && (
          <Text color="red">{errorrelevantExperience}</Text>
        )}
      </Flex>

      <Flex justifyContent="end" mt="4">
        <Button
          colorScheme="blue"
          variant="outline"
          size="md"
          onClick={back}
          mr="2"
        >
          Back
        </Button>
        <Button colorScheme="blue" size="md" onClick={confirm}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};

export default Step4;
