/* eslint-disable */
import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { FormErrorMessage, Image, Spinner } from "@chakra-ui/react";
// Formik imports
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import illustration from "assets/img/logoCov.jpg";
import { useDispatch, useSelector } from "react-redux";
import { signInUser } from "reducers/signIn";
import { toast, ToastContainer } from "react-toastify";
import queryString from "query-string";
import logo from "assets/img/logo.png";

function SignIn() {
  const dispatch = useDispatch();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [topSCreenErr, setTopScreenErr] = useState("");
  const [loader, setLoader] = useState(false);
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email"),
    password: Yup.string().required("This field is required"),
  });

  const status = useSelector((state) => state.SignIn.signin.status);
  const error = useSelector((state) => state.SignIn.signin.error);
  const { search } = useLocation();
  const params = queryString.parse(search);

  useEffect(() => {
    if (status === "success") {
      window.location.href = "/";
      toast.info("Welcome to our Application", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
      setLoader(false);
    } else if (status === "failed") {
      toast.error(error, {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
      setLoader(false);
      setTopScreenErr(error);
    }
  }, [status]);

  const handleSubmit = async (values) => {
    const userData = {
      userData: values,
      params: params.token,
    };
    await dispatch(signInUser(userData)).unwrap();
  };

  return (
    <DefaultAuth
      backgroundColor={"gray"}
      illustrationBackground={illustration}
      image={illustration}
    >
      <ToastContainer />
      {loader === true ? (
        <Flex
          width={"96"}
          my="auto"
          alignItems={"center"}
          justifyContent="center"
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "40px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "10px", md: "4vh" }}
          flexDirection="column"
        >
          <Box
            me="auto"
            width="100%"
            display={"flex"}
            flexDirection="column"
            justifyContent={"center"}
            alignItems={{ base: "center", lg: "unset" }}
          >
            <Heading
              display={{ base: "none", lg: "flex" }}
              color={textColor}
              fontSize="36px"
              mb="10px"
            >
              Sign In
            </Heading>
            <Image
              src={logo}
              alt="Logo"
              maxW="200px"
              display={{ base: "flex", lg: "none" }}
            />
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <Formik
              validationSchema={schema}
              initialValues={{ email: "", password: "" }}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form>
                  <Field name="email">
                    {({ field }) => (
                      <FormControl isInvalid={errors.email && touched.email}>
                        <FormLabel
                          htmlFor="email"
                          display="flex"
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          mb="8px"
                          mt="15px"
                        >
                          Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                          value={values.email}
                          id="email"
                          isRequired={true}
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="email"
                          placeholder="your@email.di"
                          mb={errors.email && touched.email ? "0px" : "24px"}
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="password">
                    {({ field }) => (
                      <FormControl
                        isInvalid={errors.password && touched.password}
                      >
                        <FormLabel
                          htmlFor="password"
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          Password<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size="md">
                          <Input
                            id="password"
                            isRequired={true}
                            fontSize="sm"
                            placeholder="Insert your password here."
                            mb={
                              errors.password && touched.password
                                ? "0px"
                                : "24px"
                            }
                            size="lg"
                            type={show ? "text" : "password"}
                            {...field}
                          />

                          <InputRightElement
                            display="flex"
                            alignItems="center"
                            mt="4px"
                          >
                            <Icon
                              color={textColorSecondary}
                              _hover={{ cursor: "pointer" }}
                              as={show ? MdOutlineRemoveRedEye : RiEyeCloseLine}
                              onClick={handleClick}
                            />
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Flex justifyContent="space-between" align="center" mb="24px">
                    <FormControl display="flex" alignItems="center">
                      <Checkbox
                        id="remember-login"
                        colorScheme="brandScheme"
                        me="10px"
                      />
                      <FormLabel
                        htmlFor="remember-login"
                        mb="0"
                        fontWeight="normal"
                        color={textColor}
                        fontSize="sm"
                      >
                        Keep me logged in
                      </FormLabel>
                    </FormControl>

                    <NavLink to="/auth/forgot-password">
                      <Text
                        color={textColorBrand}
                        fontSize="sm"
                        w="124px"
                        fontWeight="500"
                      >
                        Forgot password?
                      </Text>
                    </NavLink>
                  </Flex>
                  <Text
                    mb="36px"
                    ms="4px"
                    color={setTopScreenErr !== "" ? "red" : textColorSecondary}
                    fontWeight="400"
                    fontSize="md"
                  >
                    {setTopScreenErr !== ""
                      ? topSCreenErr
                      : "Enter your email and password to sign in!"}
                  </Text>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="15px"
                    type="submit"
                  >
                    Sign In
                  </Button>
                </Form>
              )}
            </Formik>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
              mt="0px"
              mb="25px"
            >
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                Not registered yet ?
                <NavLink to="/auth/sign-up">
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                  >
                    Create an Account
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </DefaultAuth>
  );
}

export default SignIn;
