import React from "react";
import Card from "components/card/Card.js";
import { Text } from "@chakra-ui/react";

export default function TextCard(props) {
  const { text, subText } = props;
  return (
    <Card mb="20px">
      <Text color="black" textAlign={"center"} fontSize="large">
        {text}
      </Text>
      {subText && (
        <Text color="gray.600" textAlign={"center"}>
          {subText}
        </Text>
      )}
    </Card>
  );
}
