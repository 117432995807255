import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { commentNewService } from "services/newsService";
import { likeNewService } from "services/newsService";
import { getDetailsNew } from "services/newsService";
import { addNewService } from "services/newsService";
import { updateNotificationService } from "services/notificationsSettingsService";
import { getAllNotificationService } from "services/notificationsSettingsService";
import { updateNotificationsSettingsService } from "services/notificationsSettingsService";
import { getNotificationsSettingsService } from "services/notificationsSettingsService";

export const getNotificationsSettings = createAsyncThunk(
  "notificationsSettings/get",
  async (thunkAPI) => {
    try {
      const { status, data } = await getNotificationsSettingsService();
      if (status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateNotificationsSettings = createAsyncThunk(
  "notificationsSettings/update",
  async (settingsData, thunkAPI) => {
    try {
      const { status, data } = await updateNotificationsSettingsService(
        settingsData
      );
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllNotifications = createAsyncThunk(
  "getnotifications/get",
  async (thunkAPI) => {
    try {
      const { status, data } = await getAllNotificationService();
      console.log("hahahahahah")
      if (status === 200) {
        console.log(data,'fafa');
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "notifications/update",
  async (idnotif, thunkAPI) => {
    try {
      const { status, data } = await updateNotificationService(
        idnotif
      );
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  notificationsSettings: {
    status: "idle",
    data: {},
    error: {},
  },
  updateNotificationsSettings: {
    status: "idle",
    data: {},
    error: {},
  },
  allNotification: {
    status: "idle",
    data: {},
    error: {},
  },
  updateNotification: {
    status: "idle",
    data: {},
    error: {},
  },
};

const NotificationsSettingsSlice = createSlice({
  name: "notificationsSettings",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getNotificationsSettings.pending.type]: (state) => {
      state.notificationsSettings = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getNotificationsSettings.fulfilled.type]: (state, action) => {
      state.notificationsSettings = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getNotificationsSettings.rejected.type]: (state, action) => {
      state.notificationsSettings = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateNotificationsSettings.pending.type]: (state) => {
      state.updateNotificationsSettings = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateNotificationsSettings.fulfilled.type]: (state, action) => {
      state.updateNotificationsSettings = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateNotificationsSettings.rejected.type]: (state, action) => {
      state.updateNotificationsSettings = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAllNotifications.pending.type]: (state) => {
      state.allNotification = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllNotifications.fulfilled.type]: (state, action) => {
      state.allNotification = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllNotifications.rejected.type]: (state, action) => {
      state.allNotification = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [updateNotifications.pending.type]: (state) => {
      state.updateNotification = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [updateNotifications.fulfilled.type]: (state, action) => {
      state.updateNotification = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateNotifications.rejected.type]: (state, action) => {
      state.updateNotification = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default NotificationsSettingsSlice.reducer;
