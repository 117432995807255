import React from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Divider,
  Flex,
  ButtonGroup,
  Button,
  Badge,
  Image,
} from "@chakra-ui/react";

import config from "config/axios/config";

import { Link as Linkk } from "react-router-dom";
import placeholder from "assets/img/placeholder.jpg";

export default function CallForStartupsItem(props) {
  const { opportunity } = props;
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card p="10px" boxShadow={cardShadow}>
      <Flex direction="column">
        <Image
                     src={config.apiUrl + "/org/file/" + opportunity.image}
          w={{ base: "100%", "2xl": "100%" }}
          h="225px"
          borderRadius="20px"
          fallbackSrc={placeholder}
        />
        <Flex justify="space-between" alignItems={"center"}>
          <Text
            color={textColor}
            mr="4px"
            fontSize={{
              base: "xl",
              md: "lg",
              lg: "lg",
              xl: "lg",
              "2xl": "md",
              "3xl": "lg",
            }}
            mb="5px"
            fontWeight="bold"
            me="14px"
          >
            {opportunity.title}{" "}
          </Text>
        </Flex>
        <Text className="description" color={textColor} mb="5px" me="14px">
          {opportunity.description}{" "}
        </Text>
      </Flex>
      <Divider mt="2" mb="2" />
      <Flex color={textColor} direction="row" alignItems={"center"}>
        <Text className="description" color={textColor} mb="5px" me="14px">
        Budget : ${opportunity.budget}{" "} 
        </Text>
      </Flex>
      <Divider mt="2" mb="2" />
      <Text fontWeight="normal" color="blue">
        <ButtonGroup spacing="2" display={"flex"} justifyContent="flex-end">
          <Button variant="outline" colorScheme="blue" size="sm">
            <Linkk to={"/admin/CallForStartups/"+opportunity._id}>Details</Linkk>
          </Button>
        </ButtonGroup>
      </Text>
    </Card>
  );
}
