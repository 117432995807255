import React, { useState, useEffect } from "react";
import {
  Text,
  IconButton,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { Input, Stack } from "@chakra-ui/react";
import "./ProfileSettings.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getResume, updateResume } from "reducers/userInformation";
import { useDispatch, useSelector } from "react-redux";

const Languages = (props) => {
  const dispatch = useDispatch();
  const resumeData = useSelector((state) => state.UserInformation.resume.data);
  const updatedResumeStatus = useSelector(
    (state) => state.UserInformation.updatedResume.status
  );
  const [showAdd, setShowAdd] = useState(false);
  const [language, setLanguage] = useState("");
  const notify = () =>
    toast.success("Your languages has been successfully updated!", {
      position: "top-right",
      hideProgressBar: false,
      onClick: () => {
        window.location.href = "/";
      },
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

  const [resume, setResume] = useState({
    Education: [
      {
        diplome: "",
        start: "",
        end: "",
        place: "",
      },
    ],
    workHistory: [
      {
        workingType: "",
        start: "",
        end: "",
        company: "",
      },
    ],
    Language: [],
  });

  const getResumeHandler = async () => {
    await dispatch(getResume()).unwrap();
  };

  const updateResumeHandler = async () => {
    const updatedLanguage = [...resume.Language];
    updatedLanguage.push(language);
    setResume({ ...resume, Language: updatedLanguage });
    await dispatch(
      updateResume({ ...resume, Language: updatedLanguage })
    ).unwrap();
    setLanguage("");
    notify();
  };

  useEffect(() => {
    if (
      resumeData &&
      resumeData.message &&
      resumeData.message.resume &&
      resumeData.message.resume[0]
    ) {
      const { Education, workHistory, Language } = resumeData.message.resume[0];
      setResume({ Education, workHistory, Language });
    }
  }, [resumeData]);

  useEffect(() => {
    if (updatedResumeStatus === "success") {
      props.getUser();
    }
  }, [updatedResumeStatus]);

  useEffect(() => {
    getResumeHandler();
  }, []);

  const deleteLanguageHandler = async (index) => {
    const updatedLanguage = [...resume.Language];
    updatedLanguage.splice(index, 1);
    setResume({ ...resume, Language: updatedLanguage });
    await dispatch(updateResume({ ...resume, Language: updatedLanguage })).unwrap();
    notify();
  };

  const handleLanguageChange = (value, index = 0) => {
    setLanguage(value);
  };

  const showAddHandler = () => {
    if (!showAdd) {
      setShowAdd(true);
    } else {
      setShowAdd(false);
    }
  };
  return (
    <Stack
      padding="8"
      display={"flex"}
      flexDirection={"column"}
      borderRadius={"30px"}
      backgroundColor={"white"}
    >
      <Flex spacing="4">
        <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
          <h3 className="SettingTitle">Languages</h3>
        </Flex>
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="add"
          icon={!showAdd ? <AddIcon /> : <CloseIcon />}
          onClick={showAddHandler}
        />
      </Flex>
      <Text marginBottom={"15px!important"} mt="0" color="gray.500" isTruncated>
        The changes you'll make will appear on your profile
      </Text>
      {resume.Language.length > 0 && (
        <HStack spacing={2} mb="20px">
          {resume.Language.map((language, index) => (
            <Tag
              size={"md"}
              key={index}
              borderRadius="full"
              variant="solid"
              colorScheme="blue"
            >
              <TagLabel>{language}</TagLabel>
              <TagCloseButton onClick={() => deleteLanguageHandler(index)} />
            </Tag>
          ))}
        </HStack>
      )}
      {showAdd && (
        <div>
          <Flex justifyContent="flex-end" alignItems={"end"}>
            <Input
              required
              type="text"
              placeholder="Language"
              value={language}
              onChange={(e) => handleLanguageChange(e.target.value)}
              marginRight="10px"
            />
            <IconButton
              disabled={!language}
              colorScheme="blue"
              aria-label="add"
              icon={<AddIcon />}
              onClick={updateResumeHandler}
            />
          </Flex>
        </div>
      )}
    </Stack>
  );
};

export default Languages;
