import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  Box,
  Flex,
  Checkbox,
  Badge,
  Image,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import config from "config/axios/config";
import placeholder from "assets/img/placeholder.jpg";

export default function PartnerItem(props) {
  const { partner, selectedPartners, setSelectedPartners } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [isChecked, setIsChecked] = useState(false);

  const togglePartner = (partner) => {
    const x = [...selectedPartners];
    if (x.includes(partner._id)) {
      const res = x.filter((item) => item !== partner._id);
      setSelectedPartners(res);
    } else {
      x.push(partner._id);
      setSelectedPartners(x);
    }
  };

  useEffect(() => {
    setIsChecked(selectedPartners.includes(partner._id));
  }, [selectedPartners, partner]);

  return (
    <Card p="1" mb="2" boxShadow={cardShadow}>
      <Flex>
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
        >
          <Image
            h="50px"
            w="50px"
            src={config.apiUrl + "/api/getfile/" + partner.Image}
            borderRadius="8px"
            fallbackSrc={placeholder}
            objectFit="cover"
          />
          <Box ml="3" flexDirection={"column"}>
            <Text fontWeight="normal">{partner.name}</Text>
            <Badge colorScheme={"blue"} variant="solid" size={"sm"}>
              {"Partner"}
            </Badge>
          </Box>
        </Flex>
        <Checkbox
          me="16px"
          colorScheme="brandScheme"
          borderColor={"#3965ff"}
          onChange={() => togglePartner(partner)}
          isChecked={isChecked}
        />
      </Flex>
    </Card>
  );
}
