import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVotesByCategory } from "reducers/project";
import StatsChart from "./StatsChart";

export default function VotesByCategoryStats() {
  const dispatch = useDispatch();
  const [xValues, setXValues] = useState([]);
  const [yValues, setYValues] = useState([]);
  const votesByCategory = useSelector((state) =>
    state.Project.votesByCategory.data
      ? state.Project.votesByCategory.data.result
      : []
  );

  const getVotesByCategoryAction = async () => {
    await dispatch(getVotesByCategory()).unwrap();
  };

  useEffect(() => {
    if (votesByCategory) {
      const categories = votesByCategory.map((item) => item.category);
      setXValues(categories);
      const nbVotes = votesByCategory.map((item) => item.totalLikes);
      setYValues(nbVotes);
    }
  }, [votesByCategory]);

  const config = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: xValues,
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      fill: {
        colors: ["#008000a3"],
      },
    },
    series: [
      {
        name: "Votes",
        data: yValues,
      },
    ],
  };

  useEffect(() => {
    getVotesByCategoryAction();
  }, []);

  return (
    <StatsChart
      title={"Votes by category"}
      config={config}
      data={votesByCategory}
      noDataText={"There is no project"}
      setMonthYearFilter={() => {}}
      showFilter={false}
      type={"bar"}
    />
  );
}
