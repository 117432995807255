import React from "react";
import {
  Text,
  Flex,
  Heading,
  IconButton,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { FaPlus, FaTrophy } from "react-icons/fa";
import PrizeItem from "./PrizeItem";
import EditPrize from "../editHackathon/EditPrize";

export default function Prizes(props) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { hackathonPrize, hackathon, onReload, canEdit } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex direction={"column"} color={textColor}>
      <Flex alignItems="center" mt="3">
        <Flex align="center">
          <Text fontSize="2xl" mr={2}>
            <FaTrophy color="#ffd333" />
          </Text>
          <Heading fontSize="2xl" color={textColor}>
            Prizes
          </Heading>
        </Flex>
        {/* <IconButton
          ml="2"
          aria-label="Add"
          icon={<Icon as={FaPlus} />}
          colorScheme="blue"
          variant="ghost"
          size="md"
          onClick={onOpen}
        /> */}
      </Flex>
      <Flex>
        {hackathonPrize &&
          hackathonPrize.map((item) => (
            <PrizeItem
              prize={item}
              canEdit={canEdit}
              hackathon={hackathon}
              onReload={onReload}
            />
          ))}
      </Flex>
      <EditPrize
        isOpen={isOpen}
        onClose={onClose}
        initialData={hackathon}
        prize={null}
        onReload={onReload}
      />
    </Flex>
  );
}
