import React from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Divider,
  Flex,
  ButtonGroup,
  Button,
  Badge,
  Image,
} from "@chakra-ui/react";

import config from "config/axios/config";

import { Link as Linkk } from "react-router-dom";
import placeholder from "assets/img/placeholder.jpg";
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";
import { useState } from "react";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteCohort } from "reducers/cohort";
import { getAllGrants } from "reducers/grants";
import { deleteGrant } from "reducers/grants";

export default function GrantsItem(props) {
  const { grants, page } = props;
  const [userType, setUserType] = useState("");
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const dispatch = useDispatch();

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  useEffect(() => {
    const USER_TYPE = jwt_decode(localStorage.getItem("token")).userType;
    setUserType(USER_TYPE);
  }, []);

  const deleteGrantAction = async () => {
    await dispatch(deleteGrant(grants._id)).unwrap();
    const filterData = {
      page: page,
      limit: 6,
    };
    await dispatch(getAllGrants(filterData)).unwrap();
  };
  return (
    <Card p="10px" boxShadow={cardShadow}>
      <Flex direction="column">
        <Image
          src={config.apiUrl + "/grant/" + grants.Image}
          w={{ base: "100%", "2xl": "100%" }}
          h="225px"
          borderRadius="20px"
          fallbackSrc={placeholder}
        />
        <Flex justify="space-between" alignItems={"center"}>
          <Text
            color={textColor}
            mr="4px"
            fontSize={{
              base: "xl",
              md: "lg",
              lg: "lg",
              xl: "lg",
              "2xl": "md",
              "3xl": "lg",
            }}
            mb="5px"
            fontWeight="bold"
            me="14px"
          >
            {grants.name}{" "}
          </Text>
        </Flex>
        <Text className="description" color={textColor} mb="5px" me="14px">
          {grants.description}{" "}
        </Text>
      </Flex>
      <Divider mt="2" mb="2" />
      <Flex color={textColor} direction="row" alignItems={"center"}>
        <BiTimeFive w="20px" h="20px" color="#3b82f6" />
        <Text color="#3b82f6" fontWeight={"700"} ml="2">
          {moment(grants.startDate).format("ll")} -{" "}
          {moment(grants.endDate).format("ll")}
        </Text>
      </Flex>
      <Divider mt="2" mb="2" />
      <Text fontWeight="normal" color="blue">
        <ButtonGroup spacing="2" display={"flex"} justifyContent="flex-end">
          {userType == "ProjectHolder" && (
            <Button variant="outline" colorScheme="blue" size="sm">
              <Linkk to={"/admin/ApplyGrants/" + grants._id}>Apply</Linkk>
            </Button>
          )}
          {(userType === "Admin" || userType === "SuperAdmin") &&
            currentUser._id === grants.createdby && (
              <Button
                variant="outline"
                colorScheme="red"
                size="sm"
                onClick={deleteGrantAction}
              >
                Delete
              </Button>
            )}
          <Button variant="outline" colorScheme="blue" size="sm">
            <Linkk to={"/admin/grantsdetails/" + grants._id}>Details</Linkk>
          </Button>
        </ButtonGroup>
      </Text>
    </Card>
  );
}
