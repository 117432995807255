import Api from "config/axios/api";
import config from "../config/axios/config";
export const addEventService = async (files) => {
  const url = config.apiUrl + "/events";

  try {
    return await Api.post(url, files);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const deleteEventService = async (id) => {
  const url = config.apiUrl + "/events/" + id;
  try {
    return await Api.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getEventsService = async (filterData) => {
  const url = config.apiUrl + "/events";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getEventByIdService = async (id) => {
  const url = config.apiUrl + "/events/" + id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getUpComingEventsService = async (page) => {
  const url = config.apiUrl + "/event/upcomingPublicEvent?page=" + page;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getLastUpComingEventsService = async () => {
  // to replace by api get all events (status=upcoming and limit=3)
  const url = config.apiUrl + "/event/getLastFiveEvent";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAllEventCalanderService = async () => {
  const url = config.apiUrl + "/event/";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getTimeLineCalanderService = async () => {
  const url = config.apiUrl + "/event/Timeline/";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const participateToEventService = async (id) => {
  const url = config.apiUrl + "/events/participate/" + id;
  try {
    return await Api.put(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const addAvabilityExpert = async (dataExpert) => {
  const url = config.apiUrl + "/event/createavAilability";
  try {
    return await Api.post(url, {start:dataExpert.start,end:dataExpert.end},{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
     
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const editEventService = async (dataUpdated) => {
  const url = config.apiUrl + "/admin/updatepublicEvent/" + dataUpdated.id;
  try {
    return await Api.put(url,dataUpdated.data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getEventsService,
  getUpComingEventsService,
  getLastUpComingEventsService,
  getAllEventCalanderService,
  getTimeLineCalanderService,
  participateToEventService,
  addAvabilityExpert,
  deleteEventService,
  editEventService
};
