import React from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Divider,
  Flex,
  Heading,
  Icon,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { BiTimeFive } from "react-icons/bi";
import { CloseIcon, EditIcon } from "@chakra-ui/icons";
import EditTrackForm from "../editTrack/EditTrackForm";

export default function TrackInfos(props) {
  const { track, canEdit } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [showEditInfos, setShowEditInfos] = useState(false);

  const onEdit = () => {
    setShowEditInfos(false);
  };

  return (
    <>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Heading size="sm" mt="4" mb="2">
          General Informations{" "}
        </Heading>
        {/* {!showEditInfos && canEdit && (
          <Icon as={EditIcon} onClick={() => setShowEditInfos(true)} />
        )} */}
        {showEditInfos && (
          <Icon
            as={CloseIcon}
            fontSize="12px"
            onClick={() => setShowEditInfos(false)}
          />
        )}
      </Flex>
      <Divider mb="2" />
      {!showEditInfos && (
        <Card p="2" direction="column" w="100%">
          <Flex direction={"column"}>
            <Text
              color={textColor}
              mr="4px"
              fontSize={{
                base: "xl",
                md: "lg",
                lg: "lg",
                xl: "lg",
                "2xl": "md",
                "3xl": "lg",
              }}
              mb="5px"
              fontWeight="bold"
              me="14px"
            >
              {track.title}
            </Text>
            <Text color={textColor} mb="5px" me="14px">
              {track.description}
            </Text>
          </Flex>
          {/* <Divider mt="2" mb="2" /> */}
          {/* <Flex color={textColor} direction="row" alignItems={"center"}>
            <BiTimeFive w="20px" h="20px" color="#3b82f6" />
            <Text color="#3b82f6" fontWeight={"700"} ml="2">
              {moment(track.voteSession.start).format("llll")} -{" "}
              {moment(track.voteSession.end).format("llll")}
            </Text>
          </Flex> */}
        </Card>
      )}
      {showEditInfos && <EditTrackForm track={track} onEdit={onEdit} />}
    </>
  );
}
