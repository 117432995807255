import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import {
  Text,
  Flex,
  Textarea,
  Input,
  Button,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { updateCohort } from "reducers/cohort";
import BasicButton from "components/button/BasicButton";
import CardItem from "../addAssistingProgram/CardItem";
import { update } from "reducers/assistingProgram";

export default function EditAssistingProgramRules(props) {
  const { isOpen, onClose, currentRule, editType, id, assistingProgram , onReload} =
    props;
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [rulesError, setRulesError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rules, setRules] = useState([]);

  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }

    return valid;
  };
  const validateRules = () => {
    // let valid = true;
    // if (rules.length == 0) {
    //   setRulesError("Rules is required!");
    //   valid = false;
    // } else {
    //   setRulesError("");
    // }
    // return valid;
  };
  const handleAddRule = async () => {
    if (!validateForm()) {
      return;
    }
    console.log(title, description, "currentRule");
    const rulesArray = [
      ...assistingProgram.rules,
      {
        title: title,
        description: description,
      },
    ];

    const data = new FormData();
    if (rulesArray.length && title != "" && description != "") {
      data.append("rules", JSON.stringify(rulesArray));
      const programData = {
        data,
        id: id,
      };
      await dispatch(update(programData)).unwrap();
    }
    setTitle("");
    setDescription("");
    onReload()
  };
  
  useEffect(() => {
    if (currentRule && editType == "Edit") {
      setTitle(currentRule.title);
      setDescription(currentRule.description);
    }
    if (editType == "Add") {
      console.log(currentRule, "fix rule");
      // setTitle("");
      // setDescription("");
    }
  }, [currentRule, editType]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Assisting Program Rule</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Title <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Input
                isInvalid={titleError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Title"
                borderRadius="5px"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {titleError && (
                <Text mt="3" color="red">
                  {titleError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <FormLabel>
                Description <span style={{ color: "red" }}>*</span>
              </FormLabel>{" "}
              <Textarea
                isInvalid={descriptionError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Description"
                borderRadius="5px"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {descriptionError && (
                <Text mt="3" color="red">
                  {descriptionError}
                </Text>
              )}
            </Flex>
            {rulesError && (
              <Text mt="3" color="red">
                {rulesError}
              </Text>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={editType == "Edit" ? "Edit Rule" : "Add Rule"}
              colorScheme="blue"
              variant="solid"
              onClick={handleAddRule}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
