import Api from "config/axios/api";
import config from "../config/axios/config";
export const signInService = async (userData, params) => {
  const url =
    config.apiUrl +
    (typeof params !== "undefined"
      ? "/api/signin?token=" + params
      : "/api/signin/");

  // const url = config.apiUrl + "/api/signin";
  try {
    return await Api.post(url, userData);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const generateAuthTokenService = async () => {
  const url = config.apiUrl + "/api/auth/generate-token";
  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default { signInService, generateAuthTokenService };
