import React from "react";
import {
  Text,
  useColorModeValue,
  Divider,
  Flex,
  Box,
  Button,
  Badge,
} from "@chakra-ui/react";
import moment from "moment";
import { deletecallforVoting } from "reducers/callOfVoting";
import { useDispatch } from "react-redux";
import { FaCalendar } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export default function PhasesItem(props) {
  const { voteSession, getPhasesByIdAssistingProgramAction, userType } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const goTo = () =>
    history.push("/admin/program-phases/" + voteSession._id);
  return (
    <Box p={2} flex={1}>
      
      <Flex
        maxW="100%"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        bg="white"
        boxShadow="md"
        direction="column"
        backgroundColor={"#9e9e9e12"}
        justifyContent={"space-between"}
        h="100%"
      >
        <Box
          pl="4"
          pr="4"
          pt="2"
          pb="2"
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          height={"100%"}
        >
          <Flex flexDirection={"column"}>
            <Flex
              mt="3"
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <Text
                _hover={{ color: "blue.500", cursor: "pointer" }}
                fontWeight="bold"
                fontSize="xl"
                onClick={goTo}
              >
                {voteSession.title}
              </Text>
              <Badge colorScheme="purple" variant="solid" p="2px 0px" mr="1">
              <Text
              >
                {voteSession.order}
              </Text>
              </Badge>
              {/* {currentDate >= startDate && currentDate <= endDate && (
                <Badge className="description" colorScheme="green" mb="5px">
                  Open
                </Badge>
              )}
              {currentDate < startDate && (
                <Badge className="description" colorScheme="purple" mb="5px">
                  Upcoming
                </Badge>
              )}
              {currentDate > endDate && (
                <Badge className="description" colorScheme="red" mb="5px">
                  Closed
                </Badge>
              )} */}
            </Flex>
            <Text color="gray.600" className="description">
              {voteSession.description}
            </Text>
          </Flex>
          <Flex flexDirection={"column"}>
            <Flex
              fontWeight={"700"}
              color={"blackAlpha.700"}
              direction="row"
              alignItems={"center"}
              mt="2"
            >
              <FaCalendar />
              <Text ml="2">Deadline </Text>
            </Flex>
            <Text color={"blackAlpha.700"}>
              {moment(voteSession.endDateSubmission).format("llll")}
            </Text>
          </Flex>
        </Box>
        <Flex flexDirection={"column"}>
          <Divider my="2" />
          <Flex justify="space-between" pl="4" pr="4" pb="2">
            {/* {(userType == "Admin" || userType == "SuperAdmin") && (
              <Button
                variant="solid"
                colorScheme="red"
                size="sm"
                onClick={deletecallforVotingAction}
              >
                Delete
              </Button>
            )} */}
            {/* {!canEdit && (
              <Button
                variant="solid"
                colorScheme="green"
                size="sm"
                onClick={() => {}}
              >
                Join
              </Button>
            )} */}
            <Button onClick={goTo} colorScheme="blue" variant="solid" size="sm">
              Details
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
