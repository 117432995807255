import Api from "config/axios/api";
import config from "../config/axios/config";
import jwt_decode from "jwt-decode";

export const getAllUserService = async (page, limit, type, name) => {
  const url =
    config.apiUrl +
    "/admin/getAllUser?page=" +
    page +
    "&userType=" +
    type +
    "&name=" +
    name +
    "&limit=" +
    limit;
  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getAllAdminService = async (page, limit) => {
  const url =
    config.apiUrl + "/admin/admin_users?limit=" + limit + "&page=" + page;
  try {
    return await Api.get(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
//api/reset-password/" + token
export const forgotPasswordService = async (email) => {
  const url = config.apiUrl + "/api/forgot-password/";
  try {
    return await Api.post(url, email);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const resetPasswordService = async (password, token) => {
  const url = config.apiUrl + "/api/reset-password/" + token;
  try {
    return await Api.post(url, password);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const VerfiedUserService = async (id) => {
  const url = config.apiUrl + "/admin/comfirm/" + id;
  try {
    return await Api.put(url);
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateUserService = async (userSettings) => {
  const url = config.apiUrl + "/api/updateProfile";
  try {
    return await Api.put(url, userSettings, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateSocialProfilesService = async (socialProfiles) => {
  const url = config.apiUrl + "/api/socialmedia";
  try {
    return await Api.put(url, socialProfiles, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const updatePasswordService = async (data) => {
  const url = config.apiUrl + "/api/updatePassword";
  try {
    return await Api.put(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getResumeService = async () => {
  const url = config.apiUrl + "/api/resume";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateResumeService = async (data) => {
  const url = config.apiUrl + "/api/resume";
  try {
    return await Api.put(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const updateHourlyPriceService = async (data) => {
  const url = config.apiUrl + "/expert/hourRate";
  try {
    return await Api.put(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getUserByIdService = async (id) => {
  const url = config.apiUrl + `/api/profileInfo/${id}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getCurrentUserService = () => {
  const user = JSON.parse(localStorage.getItem("CurrentUserData"));
  return user;
};
export const addAdminService = async (adminData) => {
  const url = config.apiUrl + `/admin/createAdmin`;
  try {
    return await Api.post(url, adminData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const AdminAddUserService = async (UserData) => {
  const url = config.apiUrl + `/admin/add_user`;
  try {
    return await Api.post(url, UserData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getHistoricMeetings = async (id) => {
  const url = config.apiUrl + `/api/getmeetingbyIdUser/${id}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getExcelProfilbyIdService = async (id) => {
  const url = config.apiUrl + `/admin/getExcelProfilbyId/${id}`;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  getAllUserService,
  VerfiedUserService,
  forgotPasswordService,
  resetPasswordService,
  updateUserService,
  updateSocialProfilesService,
  updatePasswordService,
  getResumeService,
  updateResumeService,
  updateHourlyPriceService,
  getUserByIdService,
  getCurrentUserService,
  getAllAdminService,
  addAdminService,
  AdminAddUserService,
  getHistoricMeetings,
  getExcelProfilbyIdService,
};
