import Api from "config/axios/api";
import config from "../config/axios/config";

export const addOrganizationService = async (orgData) => {
  const url = config.apiUrl + `/org/`;
  try {
    // {"description":orgData.description,"type":orgData.type,
    // "name":orgData.name,"range":orgData.range,"expectedReturn":orgData.expectedReturn,
    // "riskTolerance":orgData.riskTolerance,"exitStrategy":orgData.exitStrategy,
    // "startDate":orgData.startDate,"endDate":orgData.endDate,"requirements":orgData.requirements,
    // "categories":orgData.categories,"image":orgData.image,"investorRole":orgData.investorRole,
    // "document":orgData.document}
    return await Api.post(url, orgData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getOrganizationsService = async (filterData) => {
  const url = config.apiUrl + "/org/";
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: filterData,
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};

export const getOrganizationService = async (id) => {
  const url = config.apiUrl + "/org/" + id;
  try {
    return await Api.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An error occured");
  }
};
export const getCountOrganizationbycategoryService = async () => {
  const url = config.apiUrl + "/admin/countOrganizationbyCategory";
  try {
    return await Api.get(url,{headers: {
        Authorization: localStorage.getItem("token"),
      }});
  } catch (error) {
    throw new Error("An error occured");
  }
};
export default {
  addOrganizationService,
  getOrganizationsService,
  getOrganizationService,
  getCountOrganizationbycategoryService
};
