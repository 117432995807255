import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalFooter,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import CustomNumberInput from "components/fields/CustomNumberInput";
import TextareaField from "components/fields/TextareaField";
import BasicButton from "components/button/BasicButton";
import { useEffect } from "react";
import { update } from "reducers/programPhase";
import { useDispatch } from "react-redux";
import moment from "moment";

const EditGeneralInfosForm = ({ isOpen, onClose, initialData, onReload }) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [startError, setStartError] = useState("");
  const [maxSelectedProjectsError, setMaxSelectedProjectsError] = useState("");
  const [maxSelectedProjects, setMaxSelectedProjects] = useState();
  const [votingStartDateError, setVotingStartDateError] = useState("");
  const [votingStartDate, setVotingStartDate] = useState();
  const [endError, setEndError] = useState("");

  const validateForm = () => {
    let valid = true;
    const date = new Date();
    if (!start) {
      setStartError("You should enter a start date");
      valid = false;
    } else if (new Date(start) < date) {
      setStartError("Start date must be greater than current date");
    } else {
      setStartError("");
    }
    if (!end) {
      setEndError("You should enter an end date");
      valid = false;
    } else if (end < start) {
      setEndError("End date should be greater than start date");
    } else {
      setEndError("");
    }
    if (!votingStartDate) {
      setVotingStartDateError("You should enter a voting start date");
      valid = false;
    } else if (
      new Date(votingStartDate) > new Date(end) ||
      new Date(votingStartDate) < new Date(start)
    ) {
      setVotingStartDateError(
        "Voting Start Date must be between start and end date"
      );
    } else {
      setVotingStartDateError("");
    }
    if (!title) {
      setTitleError("Title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("Description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!maxSelectedProjects) {
      setMaxSelectedProjectsError("Max Selected Projects is required!");
      valid = false;
    } else {
      setMaxSelectedProjectsError("");
    }
    return valid;
  };
  const onEditHandler = async () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }

    const phaseData = {
      data: {
        title,
        description,
        start,
        end,
        maxSelectedProjects,
        votingStartDate,
      },
      id: initialData._id,
    };
    await dispatch(update(phaseData)).unwrap();
    onClose();
    onReload();
  };
  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [title, description, start, end, votingStartDate, maxSelectedProjects]);

  useEffect(() => {
    setTitle(initialData.title);
    setDescription(initialData.description);
    setStart(initialData.start);
    setEnd(initialData.end);
    setMaxSelectedProjects(initialData.maxSelectedProjects);
    setVotingStartDate(initialData.votingStartDate);
  }, [initialData]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Phase: General Informations</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"}>
            <Flex mb="3">
              <InputField
                label="Title"
                placeholder="Title"
                value={title}
                setValue={setTitle}
                error={titleError}
                type="text"
              />
            </Flex>
            <Flex mb="3">
              <TextareaField
                label="Description"
                placeholder="Description"
                value={description}
                setValue={setDescription}
                error={descriptionError}
              />
            </Flex>
            <Flex mb="3">
              <InputField
                label="Start Date"
                placeholder="Start Date"
                value={moment(new Date(start)).format("YYYY-MM-DD")}
                setValue={setStart}
                error={startError}
                type="date"
              />
              <InputField
                label="End Date"
                placeholder="End Date"
                value={moment(new Date(end)).format("YYYY-MM-DD")}
                setValue={setEnd}
                error={endError}
                type="date"
              />
            </Flex>
            <Flex mb="3">
              <InputField
                label="Voting Start Date"
                placeholder="Voting Start Date"
                value={moment(new Date(votingStartDate)).format("YYYY-MM-DD")}
                setValue={setVotingStartDate}
                error={votingStartDateError}
                type="date"
              />
            </Flex>
            <Flex mb="3">
              <CustomNumberInput
                label={"Max Selected Projects"}
                min={1}
                step={1}
                precision={0}
                required={true}
                error={maxSelectedProjectsError}
                initialValue={1}
                value={maxSelectedProjects}
                setValue={setMaxSelectedProjects}
              />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <BasicButton
              title={"Cancel"}
              onClick={onClose}
              colorScheme="gray"
              variant="solid"
              borderRadius="5px"
            />
            <BasicButton
              title={"Confirm"}
              colorScheme="blue"
              variant="solid"
              onClick={onEditHandler}
              borderRadius="5px"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditGeneralInfosForm;
