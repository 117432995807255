// Chakra imports
import {
  Text,
  useColorModeValue,
  Tooltip,
  Flex,
  SimpleGrid,
  Center,
  Box,
  Image,
  Link,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect } from "react";
import Project from "views/admin/profile/components/Project";
import { useDispatch, useSelector } from "react-redux";
import config from "config/axios/config";
import { CheckCircleIcon } from "@chakra-ui/icons";
import Pagination from "components/pagination/Pagination";
import { RequestOpportunity } from "reducers/callofStartups";
import { useState } from "react";
import { Link as Linkk } from "react-router-dom";
import placeholder from "assets/img/placeholder.jpg";
export default function HistoricRequestInvestment(props) {
  const { userId } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();
  const Historicrequests = useSelector((state) =>
    state.CallofStartups.requestOppHistoric.data.result
      ? state.CallofStartups.requestOppHistoric.data.result.doc
      : []
  );
  const totalPages = useSelector((state) =>
    state.CallofStartups.requestOppHistoric.data.result
      ? state.CallofStartups.requestOppHistoric.data.result.totalPages
      : 0
  );
  const [page, setPage] = useState(1);
  const bg = useColorModeValue("white", "navy.700");

  const getHistoricrequestsByUserAction = async () => {
    const filterData = {
      page,
      limit: 6,
    };
    await dispatch(RequestOpportunity(filterData));
    setPage(page);

  };

  useEffect(() => {
    console.log(Historicrequests, "Historicrequests");
    getHistoricrequestsByUserAction();
  }, [page]);
  return (
    <>
      {Historicrequests && Historicrequests.length > 0 && (
        <Card mb={{ base: "0px", "2xl": "20px" }} mt="3">
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Historic Investment
          </Text>
          <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
            Here you can find more details about your request investment.
          </Text>
          {Historicrequests.map((requests, index) => {
            // const title =
            //   requests.state === true ? (
            //     <Flex direction="row" alignItems={"center"}>
            //       {/* <Text marginRight={"10px"}>{requests.title}</Text> */}
            //       <Tooltip label="confirmed" fontSize="md">
            //         <CheckCircleIcon color="green" />
            //       </Tooltip>
            //     </Flex>
            //   ) : (
            //     <Flex direction="row" alignItems={"center"}>
            //       {/* <Text marginRight={"10px"}>{requests.title}</Text> */}
            //       <Tooltip label="not confirmed" fontSize="md">
            //         <CheckCircleIcon color="red" />
            //       </Tooltip>
            //     </Flex>
            //   );
            return (
              <Card bg={bg} p="14px">
                <Flex align="center" direction={{ base: "column", md: "row" }}>
                  <Image
                    h="80px"
                    w="80px"
                    src={
                      config.apiUrl + "/project/file/" + requests.project.images
                    }
                    borderRadius="8px"
                    me="20px"
                    fallbackSrc={placeholder}
                    objectFit="cover"
                  />
                  <Box mt={{ base: "10px", md: "0" }}>
                    <Text
                      color={textColorPrimary}
                      fontWeight="500"
                      fontSize="md"
                      mb="4px"
                    >
                      {requests.callOfStartup.title}{" "}
                    </Text>
                    <Text
                      fontWeight="500"
                      color={textColorSecondary}
                      fontSize="sm"
                      me="4px"
                    >
                      {requests.project.title}{" "}
                      {requests.state == "completed" && (
                        <Linkk
                          fontWeight="500"
                          fontSize="sm"
                          style={{ color: "blue" }}
                          to={"/admin/CallOfStartupDetails/" + requests._id}

                        >
                          See Request Invesment details
                        </Linkk>
                      )}
                       {requests.state == "brouillons" && (
                        <Linkk
                          fontWeight="500"
                          fontSize="sm"
                          style={{ color: "blue" }}
                          to={"/admin/Apply-Opportunity/" + requests.callOfStartup._id +  "/" + requests._id}

                        >
                          Complete your request
                        </Linkk>
                      )}
                    </Text>
                  </Box>
                  <Link
                    variant="no-hover"
                    me="16px"
                    ms="auto"
                    p="0px !important"
                  >
                    {/*<Icon as={MdEdit} color='secondaryGray.500' h='18px' w='18px' /> */}{" "}
                  </Link>
                </Flex>
              </Card>
            );
          })}

          <SimpleGrid columns="1" gap="10px" mb="10px">
            {totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={setLoader}

                />
              </Center>
            )}
          </SimpleGrid>
          {/* {Historicrequests && Historicrequests.length == 0 && (
            <Center fontSize="5xl" textAlign={"center"}>
              There are no Historic to show
            </Center>
          )} */}
        </Card>
      )}
    </>
  );
}
