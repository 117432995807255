import React from "react";
import placeholder from "assets/img/placeholder.jpg";
import {
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  IconButton,
  ModalFooter,
  Tooltip,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import { FaCamera } from "react-icons/fa";
import FileUpload from "components/upload/FileUpload";

export default function BannerImage(props) {
  const {
    src,
    w = "100%",
    h = "300px",
    borderRadius = "10px",
    objectFit = "cover",
    bg = "white",
    mb = "10px",
    boxShadow = "md",
    fallbackSrc = placeholder,
    onEdit = () => {},
    image,
    setImage,
    imageError,
    canEdit = false,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div style={{ position: "relative", height: h }}>
        {canEdit && (
          <Tooltip label="Change Image" hasArrow placement="top-end">
            <IconButton
              icon={<FaCamera />}
              size="sm"
              colorScheme="blue"
              position="absolute"
              top="10px"
              right="10px"
              onClick={onOpen}
            />
          </Tooltip>
        )}
        <Image
          src={src}
          w={w}
          h={h}
          borderRadius={borderRadius}
          objectFit={objectFit}
          bg={bg}
          mb={mb}
          boxShadow={boxShadow}
          fallbackSrc={fallbackSrc}
        />
      </div>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FileUpload
              multiple={false}
              files={image}
              setFiles={setImage}
              error={imageError}
            />
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <BasicButton
                title={"Cancel"}
                onClick={onClose}
                colorScheme="gray"
                variant="solid"
              />
              <BasicButton
                title={"Confirm"}
                colorScheme="blue"
                variant="solid"
                onClick={onEdit}
              />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
