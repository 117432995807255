import { Badge, Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";

const HeadingTitle = (props) => {
  const { badgeTitle, title, subTitle, bgColor = "brand.primary" } = props;
  return (
    <Box py={8} bg={bgColor}>
      <Container maxW="container.xl" textAlign="center">
        <Badge colorScheme="blue">{badgeTitle}</Badge>
        <Heading as="h2" size="xl" mb={4} color="brand.secondary">
          {title}
        </Heading>
        <Text fontSize="lg" mb={8} color="brand.secondary">
          {subTitle}
        </Text>
      </Container>
    </Box>
  );
};

export default HeadingTitle;
