// Chakra imports
import {
  SimpleGrid,
  Text,
  useColorModeValue,
  Box,
  Flex,
  Image,
  Tag,
  TagLabel,
  Avatar,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  Icon,
  Heading,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  Badge,
  Grid,
  GridItem,
  Tooltip,
} from "@chakra-ui/react";
import {
  FaCalendar,
  FaDiscord,
  FaEdit,
  FaFacebook,
  FaGlobe,
  FaHeart,
  FaLink,
  FaLinkedin,
  FaPhone,
  FaRegHeart,
  FaTachometerAlt,
  FaTwitter,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import Card from "components/card/Card.js";
import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import Information from "views/admin/profile/components/Information";
import { useParams } from "react-router-dom";
import { getEventById } from "reducers/event";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "config/axios/config";
import moment from "moment";
import {
  CheckCircleIcon,
  NotAllowedIcon,
  ChevronDownIcon,
  EditIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { participateToEvent } from "reducers/event";
import { toast } from "react-toastify";
import { NumToTime } from "utils/functions";
import placeholder from "assets/img/placeholder.jpg";
import EditEventForm from "./components/editEvent/EditEventForm";
import { BsDiscord, BsLinkedin, BsDisc, BsGlobe } from "react-icons/bs";
import EditEventFormContact from "./components/editEvent/EditEventFormContact";
import EditEventFormSpeakers from "./components/editEvent/EditEventFormSpeakers";
import EditEventImage from "./components/editEvent/EditEventImage";
import BannerImage from "components/image/BannerImage";
import TextCard from "components/card/TextCard";

const EventDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");
  const [showEditInfos, setShowEditInfos] = useState(false);
  const [showEditInfosContact, setShowEditInfosContact] = useState(false);
  const [showEditInfosSpeakers, setShowEditInfosSpeakers] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reload, setReload] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const event = useSelector((state) =>
    state.Event.event.data ? state.Event.event.data : null
  );
  const statusEventDetails = useSelector((state) => state.Event.event.status);

  const status = useSelector((state) => state.Event.participateToEvent.status);
  const participateToEventAction = async () => {
    await dispatch(participateToEvent(event._id)).unwrap();
    setReload(false);
  };
  const getEventByIdAction = async () => {
    await dispatch(getEventById(id)).unwrap();
  };
  useEffect(() => {
    getEventByIdAction();
    setReload(true);
  }, [id, reload]);

  useEffect(() => {
    // if (status === "success") {
    //   toast.success("Participation updated!", {
    //     position: "top-right",
    //     hideProgressBar: true,
    //     theme: "light",
    //   });
    // }
  }, [status]);
  useEffect(() => {
    setUserId(jwt_decode(localStorage.getItem("token"))._id);
    setUserType(jwt_decode(localStorage.getItem("token")).userType);
  });
  return (
    <>
     
      {event && (
        <Flex mt="3" mb="3" direction={"column"}>
          <BannerImage
            src={config.apiUrl + "/events/getImage/" + event.image}
            canEdit={false}
          />
          <Flex direction={"column"} m="3">
            <Flex direction={"column"}>
              <Flex justifyContent={"space-between"}>
                <Box>
                  <Text
                    color={textColor}
                    fontSize={"3xl"}
                    mr="2"
                    fontWeight={"bold"}
                  >
                    {event.title}{" "}
                  </Text>
                </Box>
                <Flex mt="2"></Flex>
              </Flex>
              <Flex justifyContent={"space-between"} mt="2">
                <Flex width="60%" className="description">
                  <Text fontSize={"l"} color={textColor}>
                    {event.description
                      ? event.description
                      : "There is no description"}
                  </Text>
                </Flex>
                <Flex
                  direction={"column"}
                  width="40%"
                  ml="4"
                  fontSize={"md"}
                  height="max-content"
                  position="relative"
                >
                  {userType !== "Admin" &&
                    userType !== "SuperAdmin" &&
                    event.participants &&
                    !event.participants.some(
                      (participant) => participant._id === userId
                    ) && (
                      <Button
                        size="md"
                        leftIcon={<CheckCircleIcon />}
                        backgroundColor={"#F4F7FE"}
                        color="#0000FF"
                        mb={"15px"}
                        onClick={() => {
                          participateToEventAction();
                        }}
                        disabled={new Date(event.end) < new Date()}
                      >
                        Participate
                      </Button>
                    )}
                  {userType !== "Admin" &&
                    userType !== "SuperAdmin" &&
                    event.participants &&
                    event.participants.some(
                      (participant) => participant._id === userId
                    ) && (
                      <Menu>
                        <MenuButton
                          mb={"10px"}
                          size="md"
                          as={Button}
                          aria-label="Options"
                          leftIcon={<CheckCircleIcon />}
                          rightIcon={<ChevronDownIcon />}
                          colorScheme="green"
                          disabled={new Date(event.end) < new Date()}
                        >
                          Participate
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            size="md"
                            icon={<NotAllowedIcon />}
                            onClick={() => {
                              participateToEventAction();
                            }}
                          >
                            Not Interested
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                  <Box
                    p={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    height="100%"
                    backgroundColor="white"
                    textAlign={"start"}
                  >
                    {event.start && (
                      <>
                        <Flex direction="column">
                          <Flex
                            fontWeight={"700"}
                            direction="row"
                            alignItems={"center"}
                            color="#11047a"
                          >
                            <FaCalendar />
                            <Text ml="2" fontWeight={"bold"}>
                              Start Date{" "}
                            </Text>
                          </Flex>
                          <Text fontWeight={"400"}>
                            {moment(event.start).format("LLLL")}{" "}
                          </Text>
                        </Flex>
                        <Flex direction="column">
                          <Flex
                            fontWeight={"700"}
                            direction="row"
                            alignItems={"center"}
                            color="#11047a"
                          >
                            <FaCalendar />
                            <Text ml="2" fontWeight={"bold"}>
                              End Date{" "}
                            </Text>
                          </Flex>
                          <Text fontWeight={"400"}>
                            {moment(event.end).format("LLLL")}{" "}
                          </Text>
                        </Flex>
                      </>
                    )}{" "}
                    {event.phone && (
                      <Flex direction="column" mt="2">
                        <Flex
                          fontWeight={"700"}
                          direction="row"
                          alignItems={"center"}
                          color="#11047a"
                        >
                          <FaPhone />
                          <Text ml="2" fontWeight={"bold"}>
                            Phone Number
                          </Text>
                        </Flex>
                        <Text fontWeight={"400"}>{event.phone} </Text>
                      </Flex>
                    )}
                    {event.type && (
                      <Flex direction="column" mt="2">
                        <Flex
                          fontWeight={"700"}
                          direction="row"
                          alignItems={"center"}
                          color="#11047a"
                        >
                          <FaUsers />
                          <Text ml="2" fontWeight={"bold"}>
                            {event.type}{" "}
                          </Text>
                        </Flex>
                        <Text fontWeight="400">
                          {event.url ? (
                            <a href={event.url}>{event.url}</a>
                          ) : (
                            event.emplacement
                          )}
                        </Text>
                      </Flex>
                    )}
                    {event.speakers && event.speakers.length > 0 && (
                      <Box>
                        <Flex
                          fontWeight={"700"}
                          direction="row"
                          alignItems={"center"}
                          color="#11047a"
                        >
                          <FaUser />
                          <Text ml="2" fontWeight={"bold"}>
                            Speakers{" "}
                          </Text>
                        </Flex>

                        <HStack spacing={4} mt="20px">
                          {event.speakers.map((speaker) => (
                            <Tag
                              size="lg"
                              colorScheme="blue"
                              borderRadius="full"
                            >
                              <Avatar
                                src={
                                  config.apiUrl +
                                  "/speaker/getImage/" +
                                  speaker.image
                                }
                                size="xs"
                                name={speaker.image}
                                ml={-1}
                                mr={2}
                              />
                              <TagLabel>{speaker.name}</TagLabel>
                            </Tag>
                          ))}
                        </HStack>
                      </Box>
                    )}
                    <Divider my="3" />
                    <Flex justifyContent={"center"} mt="4" mb="2">
                      {event.linkedin && (
                        <Tooltip label="Linkedin" hasArrow placement="top-end">
                          <IconButton
                            mr="10px"
                            onClick={() => {
                              window.open(event.linkedin, "_blank").focus();
                            }}
                            aria-label="Search database"
                            icon={
                              <Icon
                                as={FaLinkedin}
                                w={6}
                                h={6}
                                color="#0077B5"
                              />
                            }
                          />
                        </Tooltip>
                      )}
                      {event.website && (
                        <Tooltip label="website" hasArrow placement="top-end">
                          <IconButton
                            mr="10px"
                            onClick={() => {
                              window.open(event.website, "_blank").focus();
                            }}
                            aria-label="Search database"
                            icon={
                              <Icon as={FaGlobe} w={6} h={6} color="#0077B5" />
                            }
                          />
                        </Tooltip>
                      )}
                      {event.emplacement && (
                        <Tooltip label="Discord" hasArrow placement="top-end">
                          <IconButton
                            mr="10px"
                            onClick={() => {
                              window.open(event.emplacement, "_blank").focus();
                            }}
                            aria-label="Search database"
                            icon={
                              <Icon
                                as={FaDiscord}
                                w={6}
                                h={6}
                                color="blue.500"
                              />
                            }
                          />
                        </Tooltip>
                      )}
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            <Divider my="4" />

            <Box>
              <Flex direction={"column"}>
                <Heading size="md" mb="2">
                  Participant
                </Heading>
                {event.participants &&
                  event.participants.length > 0 &&
                  event.participants.map((participant) => (
                    <Box
                      borderWidth="1px"
                      borderRadius="lg"
                      p={4}
                      mb={4}
                      display="flex"
                      alignItems="center"
                    >
                      <Avatar
                        size="md"
                        name={participant.firstName}
                        src={
                          config.apiUrl + "/api/getFile/" + participant.Image
                        }
                        cursor={"pointer"}
                      />
                      <Box flex="1" ml={4}>
                        <Text
                          fontWeight="bold"
                          fontSize="lg"
                          _hover={{
                            color: "blue.500",
                            cursor: "pointer",
                          }}
                        >
                          {participant.userName}
                        </Text>
                      </Box>
                    </Box>
                  ))}
                {event.participants && event.participants.length === 0 && (
                  <TextCard text="There is no participant" />
                )}
              </Flex>
            </Box>
          </Flex>
        </Flex>
      )}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose New Picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <video
             style={{
               height: "200%",
               width: "200%",
               backgroundColor: "black",
               borderRadius: "20px",
               marginBottom: "20px",
             }}
             controls
             rounded="20px"
           >
             <source
               src={
                 config.apiUrl + `/cohort/video/` + cohort.video
               }
               type="video/mp4"
             />
           </video> */}{" "}
            <EditEventImage></EditEventImage>
          </ModalBody>
          {/* <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default EventDetails;
