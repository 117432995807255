import React from "react";
import { FormLabel, FormControl, Text, Input, Select } from "@chakra-ui/react";

const CustomSelectField = (props) => {
  const { label, required = true, options, error, value, setValue } = props;
  return (
    <FormControl mr="2">
      {label && (
        <FormLabel>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      <Select
        isInvalid={error}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {options.map((item) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </Select>
      {error && <Text color="red">{error}</Text>}
    </FormControl>
  );
};

export default CustomSelectField;
