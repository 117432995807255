import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Box,
  Stat,
  StatNumber,
  Link,
} from "@chakra-ui/react";
import config from "config/axios/config";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import routes from "routes.js";
import { NavLink } from "react-router-dom";
import { SocketContext } from "contexts/SocketContext";
import { Link as Linkk } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllNotifications } from "reducers/notificationsSettings";
import { useDispatch } from "react-redux";
import moment from "moment";
import placeholder from "assets/img/placeholder.jpg";
import { updateNotifications } from "reducers/notificationsSettings";
import { balanceReducer } from "reducers/Konnect";
import { FaRegBell, FaRegCommentDots } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { scrollbarStyle } from "utils/constants";
import jwt_decode from "jwt-decode";
import { getUserById } from "reducers/userInformation";

export default function HeaderLinks(props) {
  const history = useHistory();
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const { secondary } = props;
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [newNotification, setNewNotification] = useState(false);
  const [newNotif, setNewNotif] = useState(false);
  const [unreadedMessages, setUnreadedMessages] = useState([]);
  const [Role, setRole] = useState("");
  const [reload, setReload] = useState(false);
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  const savedNotifications = useSelector((state) =>
    state.NotificationsSettings.allNotification.data
      ? state.NotificationsSettings.allNotification.data.notifications
      : []
  );
  const Balances = useSelector((state) =>
    state.Konnect.balance.data ? state.Konnect.balance.data : null
  );
  const currentUser = useSelector((state) => state.UserInformation.user.data);

  const getUserByIdAction = async () => {
    const id = jwt_decode(localStorage.getItem("token"))._id;
    await dispatch(getUserById(id)).unwrap();
  };

  useEffect(() => {
    getUserByIdAction();
  }, []);

  const getAllNotificationsAction = async () => {
    await dispatch(getAllNotifications()).unwrap();
  };
  const updateNotificationsAction = async (idNotification) => {
    await dispatch(updateNotifications(idNotification)).unwrap();
    getAllNotificationsAction();
  };
  const GetBalanceAction = async () => {
    await dispatch(balanceReducer());
  };
  useEffect(() => {
    setRole(JSON.parse(localStorage.getItem("CurrentUserData")).userType);
    GetBalanceAction();
    getAllNotificationsAction();
  }, []);

  useEffect(() => {
    if (numberOfNotifications) {
      setNewNotification(true);
    }
  }, [numberOfNotifications]);

  useEffect(() => {
    if (savedNotifications && newNotif) {
      setNumberOfNotifications(savedNotifications.length);
    }
  }, [savedNotifications, newNotif]);

  useEffect(() => {
    socket.on("getNotification", (data) => {
      setNewNotif(true);
      getAllNotificationsAction();
    });
  }, [socket]);

  useEffect(() => {
    if (currentUser) {
      socket.on("unreadMessagesUpdated", (unreadMessagesUpdated) => {
        setUnreadedMessages(unreadMessagesUpdated);
      });
      socket.emit("getUnreadedMessages", { receiver: currentUser._id });
      socket.on("unreadedMessages", (messages) => {
        setUnreadedMessages(messages);
      });
      setReload(false);
    }
  }, [socket, currentUser, reload]);

  const markMessageAsRead = (message) => {
    socket.emit("joinRoom", {
      userIds: [message.sender._id, message.lastMessage.receiver],
    });

    socket.emit("update-message-status", {
      sender: message.sender._id,
      receiver: message.lastMessage.receiver,
    });
    setReload(true);
    history.replace({
      pathname: "/admin/chat",
      state: { sender: message.sender },
    });
  };

  useEffect(() => {
    console.log(unreadedMessages, "unreadedMessages");
  }, [unreadedMessages]);

  return (
    <>
      <Flex
        w={{ sm: "100%", md: "auto" }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        p="10px"
        borderRadius="30px"
        boxShadow={shadow}
      >
        <SearchBar
          mb={secondary ? { base: "10px", md: "unset" } : "unset"}
          me="10px"
          borderRadius="30px"
        />
        <SidebarResponsive routes={routes} />
        <Menu>
          {Role !== "Admin" &&
            Role !== "Investor" &&
            Role !== "community" &&
            Role !== "SuperAdmin" && (
              <Flex mr={"15px"}>
                <Stat>
                  <StatNumber
                    color={textColor}
                    fontSize={{
                      base: "sm",
                    }}
                  >
                    Balance: {Balances.balance} Hbar
                  </StatNumber>
                </Stat>
              </Flex>
            )}

          <MenuButton
            p="0px"
            onClick={() => {
              setNewNotification(false);
              setNewNotif(false);
            }}
          >
            <Icon
              mt="6px"
              as={FaRegBell}
              color={newNotification ? "red" : navbarIcon}
              w="18px"
              h="18px"
              me="10px"
            />
          </MenuButton>

          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: "30px", md: "unset" }}
            minW={{ base: "unset", md: "400px", xl: "450px" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Flex jusitfy="space-between" w="100%" mb="20px">
              <Text fontSize="md" fontWeight="600" color={textColor}>
                Notifications{" "}
                {"(" +
                  (savedNotifications ? savedNotifications.length : 0) +
                  ")"}
              </Text>
              {/*<Text
                fontSize="sm"
                fontWeight="500"
                color={textColorBrand}
                ms="auto"
                cursor="pointer"
              >
                Mark all read
                </Text>*/}
            </Flex>
            <div style={{ height: "300px", overflow: "auto" }}>
              {savedNotifications &&
                savedNotifications.length > 0 &&
                savedNotifications.map((item, index) => (
                  <>
                    {item.type === "AddCohort" && (
                      <Linkk to={"/admin/cohort/" + item.data._id} key={index}>
                        <MenuItem
                          className={"notifItem"}
                          padding={0}
                          onClick={() => updateNotificationsAction(item._id)}
                        >
                          <Flex
                            width="100%"
                            height="100%"
                            padding="0.6rem"
                            backgroundColor={item.read ? "white" : "#eef2ff"}
                          >
                            <Box ml="3">
                              <Text fontWeight="normal">
                                <b>{item.data.title} </b>a new cohort has been
                                added
                              </Text>
                              <Text fontSize="sm">
                                {moment(item.createdAt).format("LLL")}
                              </Text>
                            </Box>
                          </Flex>
                        </MenuItem>{" "}
                      </Linkk>
                    )}
                    {item.type === "AddNew" && (
                      <Linkk to={"/admin/new/" + item.data._id} key={index}>
                        <MenuItem
                          className={"notifItem"}
                          padding={0}
                          onClick={() => updateNotificationsAction(item._id)}
                        >
                          <Flex
                            width="100%"
                            height="100%"
                            padding="0.6rem"
                            backgroundColor={item.read ? "white" : "#eef2ff"}
                          >
                            <Avatar
                              src={
                                config.apiUrl +
                                "/api/getFile1/" +
                                item.data.image
                              }
                              borderRadius={"10px"}
                              fallbackSrc={placeholder}
                            />
                            <Box ml="3">
                              <Text fontWeight="normal">
                                <b>{item.data.title} </b>
                                new has been added
                              </Text>
                              <Text fontSize="sm">
                                {moment(item.createdAt).format("LLL")}
                              </Text>
                            </Box>
                          </Flex>
                        </MenuItem>{" "}
                      </Linkk>
                    )}
                    {item.type === "AddProject" && (
                      <Linkk to={"/admin/project/" + item.data._id} key={index}>
                        <MenuItem
                          className={"notifItem"}
                          padding={0}
                          onClick={() => updateNotificationsAction(item._id)}
                        >
                          <Flex
                            width="100%"
                            height="100%"
                            padding="0.6rem"
                            backgroundColor={item.read ? "white" : "#eef2ff"}
                          >
                            <Avatar
                              src={
                                config.apiUrl +
                                "/api/getfile/" +
                                item.data.createdby.Image
                              }
                            />
                            <Box ml="3">
                              <Text fontWeight="normal">
                                <b>
                                  {item.data.createdby.firstName +
                                    " " +
                                    item.data.createdby.lastName}{" "}
                                </b>
                                added a new project
                              </Text>
                              <Text fontSize="sm">
                                {moment(item.createdAt).format("LLL")}
                              </Text>
                            </Box>
                          </Flex>
                        </MenuItem>
                      </Linkk>
                    )}
                    {item.type === "AddExpert" && (
                      <Linkk
                        to={"/admin/expert/profile/" + item.data._id}
                        key={index}
                      >
                        <MenuItem
                          className={"notifItem"}
                          padding={0}
                          onClick={() => updateNotificationsAction(item._id)}
                        >
                          <Flex
                            width="100%"
                            height="100%"
                            padding="0.6rem"
                            backgroundColor={item.read ? "white" : "#eef2ff"}
                          >
                            <Avatar
                              src={
                                config.apiUrl +
                                "/api/getfile/" +
                                item.data.Image
                              }
                            />
                            <Box ml="3">
                              <Text fontWeight="normal">
                                <b>
                                  {item.data.firstName +
                                    " " +
                                    item.data.lastName}{" "}
                                </b>
                                is a new expert
                              </Text>
                              <Text fontSize="sm">
                                {moment(item.createdAt).format("LLL")}
                              </Text>
                            </Box>
                          </Flex>
                        </MenuItem>{" "}
                      </Linkk>
                    )}
                    {item.type === "AddEvent" && (
                      <Linkk to={"/admin/event/" + item.data._id} key={index}>
                        {" "}
                        <MenuItem
                          className={"notifItem"}
                          padding={0}
                          onClick={() => updateNotificationsAction(item._id)}
                        >
                          <Flex
                            width="100%"
                            height="100%"
                            padding="0.6rem"
                            backgroundColor={item.read ? "white" : "#eef2ff"}
                          >
                            <Avatar
                              src={
                                config.apiUrl +
                                "/api/getFileEvent/" +
                                item.data.photo
                              }
                              borderRadius={"10px"}
                            />
                            <Box ml="3">
                              <Text fontWeight="normal">
                                <b>{item.data.title} </b>
                                new event has been added
                              </Text>
                              <Text fontSize="sm">
                                {moment(item.createdAt).format("LLL")}
                              </Text>
                            </Box>
                          </Flex>
                        </MenuItem>
                      </Linkk>
                    )}
                  </>
                ))}

              {savedNotifications && savedNotifications.length == 0 && (
                <MenuItem>No notification</MenuItem>
              )}
            </div>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton p="0px" mt="6px" me="10px">
            <Box position="relative">
              <Icon
                as={FaRegCommentDots}
                color={navbarIcon}
                w="18px"
                h="18px"
              />
              {unreadedMessages.length > 0 && (
                <Box
                  position="absolute"
                  top="-35%"
                  right="-15%"
                  bg="red.500"
                  color="white"
                  borderRadius="100%"
                  fontSize="10px"
                  width="15px"
                  height="15px"
                  p="2"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Text textAlign="center">
                    {unreadedMessages.length < 10
                      ? unreadedMessages.length
                      : "9+"}
                  </Text>
                </Box>
              )}
            </Box>
          </MenuButton>

          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: "30px", md: "unset" }}
            minW={{ base: "unset", md: "400px", xl: "450px" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Flex jusitfy="space-between" w="100%" mb="20px">
              <Text fontSize="md" fontWeight="600" color={textColor}>
                Unreaded Messages{" "}
                {"(" + (unreadedMessages ? unreadedMessages.length : 0) + ")"}
              </Text>
            </Flex>
            <Box maxH="300px" overflow="auto" css={scrollbarStyle}>
              {unreadedMessages &&
                unreadedMessages.length > 0 &&
                unreadedMessages.slice(0, 5).map((item, index) => (
                  <MenuItem
                    key={index}
                    className={"notifItem"}
                    padding={"10px 0"}
                    onClick={() => markMessageAsRead(item)}
                  >
                    <Flex width="100%" height="100%" backgroundColor={"white"}>
                      <Avatar
                        src={
                          config.apiUrl + "/api/getfile/" + item.sender.Image
                        }
                      />
                      <Box ml="3" flex="1" lineHeight={"20px"}>
                        <Flex justifyContent={"space-between"} w="100%">
                          <Text fontWeight="bold">
                            {item.sender.firstName + " " + item.sender.lastName}{" "}
                          </Text>
                          <Box fontWeight="bold">
                            ({item.unreadCount < 10 ? item.unreadCount : "9+"})
                          </Box>
                        </Flex>
                        {item.lastMessage && (
                          <Text maxWidth="150px" className="message-content">
                            {item.lastMessage.content}
                          </Text>
                        )}
                        {item.lastMessage && (
                          <Text fontSize="xs">
                            {moment(item.lastMessage.createdAt).fromNow()}
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  </MenuItem>
                ))}

              {unreadedMessages && unreadedMessages.length == 0 && (
                <MenuItem>There is no unreaded message</MenuItem>
              )}
            </Box>
            <Flex
              justifyContent="center"
              alignItems={"center"}
              w="100%"
              mt="20px"
            >
              <Link
                color="blue.500"
                fontSize="md"
                onClick={() => history.replace("/admin/chat")}
              >
                Show All Messages
              </Link>
            </Flex>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name={
                JSON.parse(localStorage.getItem("CurrentUserData")).firstName +
                " " +
                JSON.parse(localStorage.getItem("CurrentUserData")).lastName
              }
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
              src={JSON.parse(localStorage.getItem("CurrentUserData")) ? 
                config.apiUrl +
                "/api/getfile/" +
                JSON.parse(localStorage.getItem("CurrentUserData")).Image : ""
              }
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey,{" "}
                {JSON.parse(localStorage.getItem("CurrentUserData")).firstName}
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
              {Role !== "Admin" && Role !== "SuperAdmin" && (
                <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  px="14px"
                >
                  <NavLink to="/admin/settings">
                    <Text fontSize="sm">Profile Settings</Text>
                  </NavLink>
                </MenuItem>
              )}

              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
              >
                <Text
                  onClick={() => {
                    localStorage.removeItem("CurrentUserData");
                    localStorage.removeItem("token");
                    window.location.href = "/";
                  }}
                  fontSize="sm"
                >
                  Log out
                </Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
