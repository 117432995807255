import {
  Heading,
  useColorModeValue,
  Stack,
  StackDivider,
  Box,
  Flex,
  Avatar,
  Text,
  Image,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { Link as Linkk } from "react-router-dom";
import placeholder from "assets/img/placeholder.jpg";
import moment from "moment";
import config from "config/axios/config";

export default function MeetingItem(props) {
  const { meeting } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mt="2" bg={bg} p="14px" boxShadow={cardShadow}>
      <Heading size="sm" mb="4">
        {meeting.title}
      </Heading>
      <Stack divider={<StackDivider />} spacing="4">
        <Box>
          <Heading size="xs" textTransform="uppercase">
            From
          </Heading>
          <Text pt="2" fontSize="sm">
            {moment(meeting.start).format("LLLL")}
          </Text>
        </Box>
        <Box>
          <Heading size="xs" textTransform="uppercase">
            To
          </Heading>
          <Text pt="2" fontSize="sm">
            {moment(meeting.end).format("LLLL")}
          </Text>
        </Box>
        <Box>
          <Heading size="xs" textTransform="uppercase">
            Project
          </Heading>
          <Flex mt="2" alignItems={"center"}>
            <Image
              h="50px"
              w="50px"
              src={config.apiUrl + `/project/file/` + meeting.project.images}
              borderRadius="8px"
              fallbackSrc={placeholder}
              objectFit="cover"
            />
            <Box ml="3">
              <Text fontWeight="bold">{meeting.project.title}</Text>
            </Box>
          </Flex>
        </Box>
        {meeting && meeting.host != null &&
        <Box>
          <Heading size="xs" textTransform="uppercase">
            Project Holder
          </Heading>
          <Flex mt="2">
            <Avatar
              src={config.apiUrl + "/api/getfile/" + meeting.host.Image}
            />
            <Box ml="3">
              <Text fontWeight="bold">
                {meeting.host.firstName + " " + meeting.host.lastName}
              </Text>
              <Text fontSize="sm">{meeting.host.email}</Text>
            </Box>
          </Flex>
        </Box>
        }
      </Stack>
    </Card>
  );
}
