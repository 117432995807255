import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SimpleGrid, Center, Box, useDisclosure } from "@chakra-ui/react";
import Pagination from "components/pagination/Pagination";
import { getAllCohorts } from "reducers/cohort";
import HackathonCard from "./HackathonItem";
import AccountRequiredModal from "../AccountRequiredModal";
import Loader from "components/loader/Loader";
import TextCard from "components/card/TextCard";
import { getAllHackathons } from "reducers/hackathon";
import { getAllHackathonsNotAuth } from "reducers/hackathon";

const Hackathons = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const status = useSelector(
    (state) => state.Hackathon.hackathonNotAuth.status
  );
  const hackathons = useSelector((state) =>
    state.Hackathon.hackathonNotAuth.data.result
      ? state.Hackathon.hackathonNotAuth.data.result.hackathons
      : []
  );
  const totalPages = useSelector((state) =>
    state.Hackathon.hackathons.data
      ? state.Hackathon.hackathons.data.totalPages
      : 0
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchHackathons = async () => {
    const filterData = {
      page: page,
      limit: 6,
    };
    await dispatch(getAllHackathonsNotAuth(filterData)).unwrap();
  };
  useEffect(() => {
    fetchHackathons();
  }, [page]);

  return (
    <Box p={6}>
      {!hackathons && status === "loading" && <Loader />}
      {hackathons && hackathons.length === 0 && (
        <TextCard text={"There is no Hackathon"} />
      )}
      {hackathons && hackathons.length > 0 && (
        <>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
            {hackathons.map((item, index) => (
              <HackathonCard item={item} key={index} onClick={onOpen} />
            ))}
          </SimpleGrid>
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
        </>
      )}
      <AccountRequiredModal
        message={
          "Welcome to the vibrant community of Ecosystem DAO! We're happy to have you on board. To unveil the exciting details of our upcoming hackathon and make the most of your experience. <b>take a moment to create your account.</b> <br/>Your journey into the heart of innovation and collaboration begins here. Join us, and let's make great things happen together!"
        }
        message2={""}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
};

export default Hackathons;
