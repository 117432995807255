import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Heading,
  Center,
  Spinner,
  Box,
  HStack,
  Input,
  Stack,
  Badge,
} from "@chakra-ui/react";
import Pagination from "components/pagination/Pagination";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getallUsers } from "reducers/userInformation";
import BasicButton from "components/button/BasicButton";
import { getAssistingProgramPartners } from "reducers/assistingProgram";
import PartnerItem from "./PartnerItem";
import UserItem from "../UserItem";

const VotersForm = (props) => {
  const dispatch = useDispatch();
  const {
    programPhaseData,
    setProgramPhaseData,
    activeStep,
    setActiveStep,
    assistingProgramId,
  } = props;
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [userType, setUserType] = useState("Expert");
  const [selectedUsers, setSelectedUsers] = useState(programPhaseData.voters);
  const [selectedPartners, setSelectedPartners] = useState(
    programPhaseData.partners
  );
  const [selectedPartnersError, setSelectedPartnersError] = useState([]);
  const [selectedUsersError, setSelectedUsersError] = useState("");
  const [nameUser, setnameUser] = useState("");
  const users = useSelector(
    (state) => state.UserInformation.usersInformation.data.Users
  );
  const status = useSelector(
    (state) => state.UserInformation.usersInformation.status
  );
  const totalPages = useSelector(
    (state) => state.UserInformation.usersInformation.data.totalPages
  );
  const partners = useSelector(
    (state) => state.AssistingProgram.assistingProgramPartners.data.result
  );

  const fetchUsers = async () => {
    const filterdata = {
      page: page,
      limit: 4,
      type: userType,
      name: nameUser,
    };
    dispatch(getallUsers(filterdata));
  };

  const fetchAssistingProgramPartners = async () => {
    const filterdata = {
      page: page,
      limit: 4,
      name: nameUser,
      id: assistingProgramId,
    };
    dispatch(getAssistingProgramPartners(filterdata));
  };

  useEffect(() => {
    fetchUsers();
    fetchAssistingProgramPartners();
  }, [page, userType, nameUser]);

  const back = () => {
    setActiveStep(activeStep - 1);
    setSelectedUsers([]);
    const data = { ...programPhaseData, voters: [], partners: [] };
    setProgramPhaseData(data);
  };

  const confirm = async () => {
    const data = {
      ...programPhaseData,
      voters: selectedUsers,
      partners: selectedPartners,
    };
    setProgramPhaseData(data);
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    setPage(1);
  }, [userType, nameUser]);
  return (
    <Box p={6} borderWidth="1px" borderRadius="lg" width="100%" boxShadow="md">
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        Choose who can vote
      </Heading>
      <ToastContainer />

      {!users && status === "loading" && (
        <Flex flexDirection="column" mt="3" mb="3">
          <Spinner my="auto!important" mx="auto" />
        </Flex>
      )}

      {users && partners && (
        <Flex flexDirection={"column"}>
          <Box>
            <Stack direction="row">
              <Badge
                variant={userType === "Expert" ? "solid" : "outline"}
                colorScheme="green"
                cursor={"pointer"}
                onClick={() => setUserType("Expert")}
              >
                Experts
              </Badge>
              <Badge
                variant={userType === "community" ? "solid" : "outline"}
                colorScheme="gray"
                cursor={"pointer"}
                onClick={() => setUserType("community")}
              >
                Community Members
              </Badge>
              <Badge
                variant={userType === "partners" ? "solid" : "outline"}
                colorScheme="blue"
                cursor={"pointer"}
                onClick={() => setUserType("partners")}
              >
                Partners
              </Badge>
            </Stack>
            <HStack spacing={2} mb="3" mt="3">
              <Input
                variant="outline"
                onChange={(e) => setnameUser(e.target.value)}
                placeholder="Name"
              />
            </HStack>
            {userType !== "partners" &&
              users.map((item) => (
                <UserItem
                  user={item}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                />
              ))}
            {userType === "partners" &&
              partners.map((item) => (
                <PartnerItem
                  partner={item}
                  selectedPartners={selectedPartners}
                  setSelectedPartners={setSelectedPartners}
                />
              ))}
            {userType !== "partners" && totalPages > 1 && (
              <Center pt="5">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  setLoader={setLoader}
                />
              </Center>
            )}
          </Box>
          {selectedUsersError && (
            <Text mt="3" color="red">
              {selectedUsersError}
            </Text>
          )}
          {selectedPartnersError && (
            <Text mt="3" color="red">
              {selectedPartnersError}
            </Text>
          )}
        </Flex>
      )}
      {userType !== "partners" && users && !users.length && (
        <Flex flexDirection="column">
          <Card mt="3" mb="3">
            <Text fontSize={"2xl"} textAlign={"center"}>
              There is no user
            </Text>
          </Card>
        </Flex>
      )}
      {userType === "partners" && partners && !partners.length && (
        <Flex flexDirection="column">
          <Card mt="3" mb="3">
            <Text fontSize={"2xl"} textAlign={"center"}>
              There is no partner
            </Text>
          </Card>
        </Flex>
      )}
      <Flex mt="4" justifyContent={"space-between"}>
        <BasicButton
          onClick={back}
          title={"Previous"}
          variant="outline"
          size="md"
        />
        <BasicButton
          onClick={confirm}
          title={"Next"}
          variant="solid"
          size="md"
        />
      </Flex>
    </Box>
  );
};

export default VotersForm;
