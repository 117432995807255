import React from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Box,
  Heading,
} from "@chakra-ui/react";
import config from "config/axios/config";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import placeholder from "assets/img/placeholder.jpg";
import { getdetailscallOfVoting } from "reducers/callOfVoting";
import { useParams } from "react-router-dom";
import { Link as Linkk } from "react-router-dom";
import { getProjectsinCallOfVoting } from "reducers/callOfVoting";
import { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { RatingProjectInCallForVoting } from "reducers/callOfVoting";
import { getProjectsByTrack } from "reducers/track";

export default function TrackProjects(props) {
  const { trackId, voters = [], reload = false, projects } = props;
  const dispatch = useDispatch();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <>
      <Heading size="sm" mt="4" mb="2">
        Projects{" "}
      </Heading>
      <Divider mb="2" />
      {projects && projects.length > 0 && (
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="2px">
          {projects.map((item, index) => {
            // const rating = getProjectRatingByVoteSession(
            //   item.project.ratingByVotesession
            // );
            return (
              <Card p="1" boxShadow={cardShadow}>
                <Flex>
                  <Flex
                    width="100%"
                    height="100%"
                    padding="0.6rem"
                    fontWeight="bold"
                    color={textColor}
                    fontSize="md"
                    textAlign="start"
                  >
                    <Image
                      h="50px"
                      w="50px"
                      src={config.apiUrl + `/project/file/` + item.images}
                      borderRadius="8px"
                      fallbackSrc={placeholder}
                      objectFit="cover"
                    />
                    <Box ml="3">
                      <Text fontWeight="normal">
                        <Linkk to={"/admin/project/" + item._id}>
                          {item.title}
                        </Linkk>
                      </Text>
                      <Text fontSize="sm">{item.category}</Text>
                    </Box>
                  </Flex>
                </Flex>
              </Card>
            );
          })}
        </SimpleGrid>
      )}
      {projects && projects.length == 0 && <p> There is no project yet</p>}
    </>
  );
}
