import Card from "components/card/Card.js";
import Pagination from "components/pagination/Pagination";
import { useColorModeValue } from "@chakra-ui/system";
import {
  Center,
  Select,
  Text,
  Box,
  Flex,
  Button,
  Input,
  Textarea,
  Heading,
  Divider,
  Checkbox,
  Image,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { toast, ToastContainer } from "react-toastify";
import { SocketContext } from "contexts/SocketContext";
import { getallProjects } from "reducers/project";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";
import { addCohort } from "reducers/cohort";
import { Link as Linkk } from "react-router-dom";
import MySelect from "react-select";
import { Review } from "./Review";
import { MeetingReviewReducer } from "reducers/callOfVoting";
import { useParams } from "react-router-dom";

const AddReview = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [loader, setLoader] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [selectedOptions, setSelectedOptions] = useState();
  const [rateoverall, setrateoverall] = useState();
  const [ratethesession, setratethesession] = useState();
  const [keytake, setkeytake] = useState();
  const [keytakeError, setkeytakeError] = useState();

  const [tasks, settasks] = useState();
  const [tasksError, settasksError] = useState();

  const [followupsession, setfollowupsession] = useState();
  const [followupsessionError, setfollowupsessionError] = useState();

  const [rateoverallError, setrateoverallError] = useState("");
  const [ratethesessionError, setratethesessionError] = useState();

  const [comments, setcomments] = useState();
  const [commentsError, setcommentsError] = useState();
  const [objectif, setobjectif] = useState("");
  const [objectifError, setobjectifError] = useState();
  const [yestasks, setyestasks] = useState();
  const [yestasksError, setyestasksError] = useState();
  const [prerequisites, setprerequisites] = useState();
  const [prerequisitesError, setprerequisitesError] = useState();
  const [Whathappen, setWhathappen] = useState();
  const [NextSession, setNextSession] = useState("");

  const [WhathappenError, setWhathappenError] = useState(); 
  
  const validateForm = () => {
    let valid = true;
    if (!objectif) {
      setobjectif("Objectif required!");
      valid = false;
    } else {
    }
    if (!rateoverall) {
      setrateoverallError("the rate overall required!");
      valid = false;
    } else {
      setrateoverallError("");
      valid = true;
    }
    if (!ratethesession) {
      setratethesessionError("the rate required!");
      valid = false;
    } else {
      setratethesessionError("");
    }
    if (!keytake) {
      setkeytakeError("required!");
      valid = false;
    } else {
      setkeytakeError("");
    }
    if (!tasks) {
      settasksError("task required!");
      valid = false;
    } else {
      settasksError("");
    }
    if (!followupsession) {
      setfollowupsessionError("the follow up session required!");
      valid = false;
    } else {
      setfollowupsessionError("");
    }
    if (!comments) {
      setcommentsError("comments required!");
      valid = false;
    } else {
      setcommentsError("");
    }
    console.log(valid,'valid')
    return valid;
  };
  const validateForm2 = () => {
    let valid2 = true;

    if (!Whathappen) {
      setWhathappenError("comments required!");
      valid2 = false;
    } else {
      setWhathappenError("");
    }
    console.log(valid2,'valid2')

    return valid2;
  };
  const MeetingReviewReducerAction = async () => {
    if(objectif=='Review'){
      if(!validateForm())
      {
        return;
      }
    }else{
      if(!validateForm2())
      {
        return;
      }
    }
   
    const comp = {
      "Whathappen":Whathappen
    };
    const questions = {
      "rateOverall":rateoverall,
      "ratethesession":ratethesession,
      "keytake":keytake,
      "tasks":{"task":tasks,
      "yestasks":yestasks ? yestasks : "",

    },
      "followupsession":{"followup":followupsession,
      "prerequisites": prerequisites ? prerequisites : "",
      "NextSession":NextSession
    },
      "comments":comments
    }
    console.log(id,"kakakalalalaal")
    const data = {
      "idMeet": id,
      "questions":objectif == "Review" ? questions : comp,
      "ToExpert":true,
      "typeReview":objectif,
    }
    
    await dispatch(MeetingReviewReducer(data)).unwrap().then((response) => {
      console.log(response,"gigigigigi")
      toast.success(response.message, {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
      window.location.href = '/#/admin/Meetings';

    })
    .catch((error) => {
      toast.info(error.message, {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });    });;
  };
  useEffect(() => {
    console.log(objectif,'objectif')
  }, [tasks,objectif]);
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer />

        <Card>
          <Heading size="sm" mb="2">
            General Informations
          </Heading>
          <Divider mb="2" />
          <Flex mt="2" alignItems={"center"}></Flex>
          <Flex mt="2" direction={"column"}>
            <Text>What is your goal from this form</Text>

            <Select
              justifyContent={"end"}
              onChange={(ev) => {
                setobjectif(ev.target.value);
              }}
              name="category"
              id="category"
              borderColor={"#E0E5F2"}
              backgroundColor="white"
            >
              <option value="">---</option>
              <option value="Complaint">Complaint</option>
              <option value="Review">Review</option>
            </Select>
            {objectifError && (
              <Text mt="3" color="red">
                {objectifError}
              </Text>
            )}
          </Flex>
          {objectif == "Review" && (
            <>
              {" "}
              <Flex mt="2" direction={"column"}>
                <Text>
                  How would you rate your overall experience with the expert?
                  Scale from 1 (Poor) to 5 (Excellent)
                </Text>

                <Select
                  justifyContent={"end"}
                  onChange={(ev) => {
                    console.log("aaaaddddd", ev.target.value);
                    setrateoverall(ev.target.value);
                  }}
                  name="category"
                  id="category"
                  borderColor={"#E0E5F2"}
                  backgroundColor="white"
                >
                  <option value="">---</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Select>
                {ratethesessionError && (
                  <Text mt="3" color="red">
                    {ratethesessionError}
                  </Text>
                )}
              </Flex>
              <Flex mt="2" direction={"column"}>
                <Text>
                  How would you rate the session in terms of relevance and
                  usefulness? Scale from 1 (Not useful at all) to 5 (Extremely
                  useful)
                </Text>
                <Select
                  justifyContent={"end"}
                  onChange={(ev) => {
                    // handleFilter(ev.target.value);
                    setratethesession(ev.target.value);
                  }}
                  name="category"
                  id="category"
                  borderColor={"#E0E5F2"}
                  backgroundColor="white"
                >
                  <option value="">---</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Select>
                {rateoverallError && (
                  <Text mt="3" color="red">
                    {rateoverallError}
                  </Text>
                )}
              </Flex>{" "}
              <Flex mt="2" direction={"column"}>
                <Textarea
                  marginTop={"5px!important"}
                  w={"100%"}
                  marginRight="10px"
                  onChange={(e) => {
                    setkeytake(e.target.value);
                  }}
                  placeholder="What are the key take aways from the session?                  "
                  borderRadius="5px"
                />
                {keytakeError && (
                  <Text mt="3" color="red">
                    {keytakeError}
                  </Text>
                )}
              </Flex>
              <Flex mt="2" direction={"column"}>
                <Text>
                  Did the expert assign any tasks for you to complete?
                </Text>
                <Select
                  justifyContent={"end"}
                  onChange={(ev) => {
                    // handleFilter(ev.target.value);
                    settasks(ev.target.value);
                  }}
                  name="category"
                  id="category"
                  borderColor={"#E0E5F2"}
                  backgroundColor="white"
                >
                  <option value="">---</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
                {tasksError && (
                  <Text mt="3" color="red">
                    {tasksError}
                  </Text>
                )}
              </Flex>
              {tasks == "Yes" && (
                <Flex mt="2" direction={"column"}>
                  <Textarea
                    marginTop={"5px!important"}
                    w={"100%"}
                    marginRight="10px"
                    placeholder="If yes, what are these tasks?"
                    borderRadius="5px"
                    onChange={(ev) => {
                      // handleFilter(ev.target.value);
                      setyestasks(ev.target.value);
                    }}
                  />
                </Flex>
              )}
              <Flex mt="2" direction={"column"}>
                <Text>
                  Is there a follow-up session planned with the expert?
                </Text>
                <Select
                  justifyContent={"end"}
                  onChange={(ev) => {
                    // handleFilter(ev.target.value);
                    setfollowupsession(ev.target.value);
                  }}
                  name="category"
                  id="category"
                  borderColor={"#E0E5F2"}
                  backgroundColor="white"
                >
                  <option value="">---</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
                {followupsessionError && (
                  <Text mt="3" color="red">
                    {followupsessionError}
                  </Text>
                )}
              </Flex>
              {followupsession == "Yes" && (
                <>
                  <Flex mt="2" direction={"column"}>
                    <Textarea
                      marginTop={"5px!important"}
                      w={"100%"}
                      marginRight="10px"
                      placeholder="If yes, what are the conditions or prerequisites for the next session?              "
                      borderRadius="5px"
                      onChange={(ev) => {
                        // handleFilter(ev.target.value);
                        setprerequisites(ev.target.value);
                      }}
                    />
                  </Flex>
                
                  <Flex mt="2" direction={"column"}>
              <DatePicker
                inputClass={"fullwidth"}
                format="MM/DD/YYYY HH:mm"
                plugins={[
                  <TimePicker position="bottom" hideSeconds={true} />,

                  <DatePanel markFocused header="Next Session" />,
                ]}
                placeholder="Next Session"
                id="date"
                name="date"
                value={NextSession[0]}
                onChange={(ev) =>
                  setNextSession(
                    ev.year +
                      "/" +
                      (ev.month.index + 1).toString() +
                      "/" +
                      ev.day +
                      " " +
                      ev.hour +
                      ":" +
                      ev.minute
                  )
                }
              />
             
            </Flex>
                </>
              )}
              <Flex mt="2" direction={"column"}>
                <Textarea
                  marginTop={"5px!important"}
                  w={"100%"}
                  marginRight="10px"
                  placeholder="Do you have any other comments or suggestions for improvement?"
                  borderRadius="5px"
                  onChange={(ev) => {
                    setcomments(ev.target.value);
                  }}
                />
                {commentsError && (
                  <Text mt="3" color="red">
                    {commentsError}
                  </Text>
                )}
              </Flex>
            </>
          )}
          {objectif == "Complaint" && (
            <Flex mt="2" direction={"column"}>
              <Textarea
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="What happen ? "
                borderRadius="5px"
                onChange={(ev) => {
                  setWhathappen(ev.target.value);
                }}
              />
               {WhathappenError && (
                  <Text mt="3" color="red">
                    {WhathappenError}
                  </Text>
                )}
            </Flex>
          )}
          <Flex justifyContent="end" mt="4">
            <Button colorScheme="blue" size="md" onClick={MeetingReviewReducerAction}>
              Confirm
            </Button>
          </Flex>
        </Card>
      </Box>
    </>
  );
};

export default AddReview;
