import {
  SimpleGrid,
  Text,
  useColorModeValue,
  Box,
  Flex,
  Image,
  Badge,
  Button,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "config/axios/config";
import placeholder from "assets/img/placeholder.jpg";
import { Link as Linkk } from "react-router-dom";
import { getDetailsOpportunity } from "reducers/callofStartups";
import CallOfStartupsRequests from "./components/CallOfStartupsRequests";
import AceptedRequest from "./components/applySteps/AceptedRequest";

const CallForStartupsDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [userType, setUserType] = useState("");
  const opportunity = useSelector((state) =>
    state.CallofStartups.getOpp.data
      ? state.CallofStartups.getOpp.data.result
      : null
  );
  const currentUserType = useSelector((state) =>
    state.UserInformation.currentUser.data
      ? state.UserInformation.currentUser.data.userType
      : ""
  );
  const getOpportunityByIdAction = async () => {
    await dispatch(getDetailsOpportunity(id)).unwrap();
  };
  useEffect(() => {
    setUserType(currentUserType);
    getOpportunityByIdAction(id);
  }, [currentUserType]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {opportunity && (
        <>
          {currentUserType === "ProjectHolder" && (
            <Flex w="100%" justifyContent="flex-end" mb="20px">
              <Button ml={"24px"} bgColor={"#7551FF"} color="white" my="auto">
                <Linkk to={"/admin/NewApply-Opportunity/" + opportunity._id + "" + "/" + ""}>
                  <Text>Apply For Opportunity</Text>
                </Linkk>
              </Button>
            </Flex>
          )}
          <Card mb={{ base: "0px", "2xl": "20px" }}>
            <Image
              src={config.apiUrl + "/org/file/" + opportunity.image}
              borderRadius="16px"
              boxSize="220px"
              width="100%"
              objectFit="cover"
              fallbackSrc={placeholder}
            />
            <Flex align="center" direction={{ base: "column", md: "row" }}>
              <Box mt={{ base: "10px", md: "0" }} w="100%" mb="5">
                <Flex
                  mt="2"
                  w="100%"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Text
                    color={textColorPrimary}
                    fontWeight="bold"
                    fontSize="2xl"
                    mt="10px"
                    mb="4px"
                    display="flex"
                    alignItems={"center"}
                  >
                    {opportunity.title}{" "}
                  </Text>
                </Flex>

                <Flex mt="5px" mb="5px">
                  {opportunity.categories.map((categ) => (
                    <Badge
                      colorScheme="blue"
                      p="2px 0px"
                      mr="1"
                      variant="outline"
                    >
                      <Text
                        fontSize="10px"
                        width="100%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {categ}
                      </Text>
                    </Badge>
                  ))}
                </Flex>
                <Text color={textColorSecondary} fontSize="md">
                  {opportunity.description
                    ? opportunity.description
                    : "There is no description"}
                </Text>
              </Box>
            </Flex>

            <SimpleGrid columns="2" gap="20px">
              <Information
                boxShadow={cardShadow}
                title="Organization Name"
                value={opportunity.organization.name}
              />
              <Information
                boxShadow={cardShadow}
                title="Organization Name"
                value={opportunity.organization.description}
              />
              <Information
                boxShadow={cardShadow}
                title="ticket Value"
                value={opportunity.ticketValue}
              />
              <Information
                boxShadow={cardShadow}
                title="ticket Number"
                value={opportunity.ticketNumber}
              />

              <Information
                boxShadow={cardShadow}
                title="list Of documents"
                value={opportunity.listOfdocs.map((doc) => (
                  <Badge
                    colorScheme="pink"
                    p="2px 0px"
                    mr="1"
                    variant="outline"
                  >
                    <Text
                      fontSize="10px"
                      width="100%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {doc}
                    </Text>
                  </Badge>
                ))}
              />
            </SimpleGrid>
              <AceptedRequest id={opportunity._id}
              />
            
            <CallOfStartupsRequests id={opportunity._id} />
          </Card>
        </>
      )}
    </Box>
  );
};

export default CallForStartupsDetails;
