import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Card from "components/card/Card.js";
import Stepper from "react-stepper-horizontal";
import GeneralInformationsForm from "./components/addProgramPhase/GeneralInformationsForm";
import SelectionCriteriaForm from "./components/addProgramPhase/SelectionCriteriaForm";
import VotersForm from "./components/addProgramPhase/VotersForm";
import VotingPercentageForm from "./components/addProgramPhase/VotingPercentageForm";
import WhatToSubmit from "./components/addProgramPhase/WhatToSubmit";

const AddProgramPhase = () => {
  const location = useLocation();
  const assistingProgramId =
    location && location.state ? location.state.assistingProgramId : "";
  const [steps, setSteps] = useState([
    { title: "General Informations", onClick: () => setActiveStep(0) },
    { title: "Selection Criteria", onClick: () => setActiveStep(1) },
    { title: "Voting Percentage", onClick: () => setActiveStep(2) },
    { title: "Voters", onClick: () => setActiveStep(3) },
    { title: "What To Submit", onClick: () => setActiveStep(4) },
  ]);
  const [activeStep, setActiveStep] = useState(0);

  const [programPhaseData, setProgramPhaseData] = useState({
    start: "",
    end: "",
    title: "",
    description: "",
    maxSelectedProjects: 1,
    assistingProgram: assistingProgramId,
    voters: [],
    partners: [],
    selectionCriteria: [],
    votingPercentage: {
      experts: 0,
      partners: 0,
      community: 0,
    },
    WhatToSubmit: [],
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Stepper steps={steps} activeStep={activeStep} />
        <div style={{ padding: "20px" }}>
          {activeStep === 0 && (
            <GeneralInformationsForm
              programPhaseData={programPhaseData}
              setProgramPhaseData={setProgramPhaseData}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 1 && (
            <SelectionCriteriaForm
              programPhaseData={programPhaseData}
              setProgramPhaseData={setProgramPhaseData}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 2 && (
            <VotingPercentageForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              programPhaseData={programPhaseData}
              setProgramPhaseData={setProgramPhaseData}
            />
          )}
          {activeStep === 3 && (
            <VotersForm
              programPhaseData={programPhaseData}
              setProgramPhaseData={setProgramPhaseData}
              assistingProgramId={assistingProgramId}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 4 && (
            <WhatToSubmit
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              programPhaseData={programPhaseData}
              setProgramPhaseData={setProgramPhaseData}
            />
          )}
        </div>
      </Card>
    </Box>
  );
};

export default AddProgramPhase;
