import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  FormLabel,
  FormControl,
  Textarea,
  Flex,
  Text,
  Heading,
  useColorModeValue,
  Image,
  Box,
  Checkbox,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import placeholder from "assets/img/placeholder.jpg";
import { getProjectsInGrants } from "reducers/grants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import config from "config/axios/config";

const Step1 = (props) => {
  const { grantsdata, setgrantdata, setStep } = props;
  const { id } = useParams();
  const dispatch = useDispatch();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const grantsProjects = useSelector((state) =>
    state.Grants.projectsgrants.data
      ? state.Grants.projectsgrants.data.message
      : []
  );
  const [selectedProjects, setSelectedProjects] = useState("");
  const [selectedProjectserror, setSelectedProjectserror] = useState("");

  const GetProjectsInGrantsAction = async () => {
    await dispatch(getProjectsInGrants(id)).unwrap();
  };
  const toggleProject = (project) => {
    setSelectedProjects(project._id);
    console.log(selectedProjects, "selectedProjects");
  };
  const validForm = () => {
    let valid = true;
    if (!selectedProjects) {
      setSelectedProjectserror("Project is required!");
      valid = false;
    } else {
      setSelectedProjectserror("");
    }
    return valid;
  };

  const confirm = async () => {
    if (!validForm()) {
      return;
    }
    const data = {
      id:id,
      project: selectedProjects,
      fund: "",
      proposalSentence: "",
      proposalSummary: "",
      choiceToBuildOnHedera: "",
      customerCountQuestion: "",
      competitors: "",
      teamSize: 0,
      teamExperience: "",
      startedStatus: "",
      developmentTimeline: "",
      estimatedLaunchDate: "",
      fundingRequestedHBAR: 0,
      allocatedTowards: "",
      codeRepository: "",
      previousFunding: "",
      fromWhere: "",
      hearAboutUs: "",
      video: "",
      pdfPresentation: "",
    };
    setgrantdata(data);
    setStep(2);
  };

  useEffect(() => {
    GetProjectsInGrantsAction();
  }, []);

  return (
    <Card>
      {grantsProjects && grantsProjects.length > 0 && (
          <>
          <Card p="1" mb="2" boxShadow={cardShadow}>
            {grantsProjects.map((item) => (
              <Flex>
                <Flex
                  width="100%"
                  height="100%"
                  padding="0.6rem"
                  fontWeight="bold"
                  color={textColor}
                  fontSize="md"
                  textAlign="start"
                >
                  <Image
                    h="50px"
                    w="50px"
                    src={config.apiUrl + `/project/file/` + item.images}
                    borderRadius="8px"
                    fallbackSrc={placeholder}
                    objectFit="cover"
                  />
                  <Box ml="3">
                    <Text fontWeight="normal">{item.title}</Text>
                    <Text fontSize="sm">{item.category}</Text>
                  </Box>
                </Flex>
                <Checkbox
                  me="16px"
                  colorScheme="brandScheme"
                  borderColor={"#3965ff"}
                  onChange={() => toggleProject(item)}
                />
              </Flex>
            ))}
            {selectedProjectserror && (
              <Text color="red">{selectedProjectserror}</Text>
            )}
          </Card>
          <Flex mt="4" justifyContent={"flex-end"}>
            <Button colorScheme={"blue"} onClick={confirm}>
              Next
            </Button>
          </Flex>
        </>
      )}
      {grantsProjects && grantsProjects.length === 0 && (
        <Card p="2" direction="column" w="100%">
          <Text>you actively engage in grant applications for all your ongoing projects, or are you considering introducing a new project into the application process</Text>
        </Card>
      )}
    </Card>
  );
};

export default Step1;
