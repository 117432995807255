import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { acceptMeetInvitationService } from "services/meetingService";
import { getAllEventMeetingsService } from "services/meetingService";
import { addBookMeetingExpertService } from "services/meetingService";
import { getAllMeetingsService } from "services/meetingService";
import { addMeetingGeneralInHackathonService } from "services/meetingService";

export const addMeetingGeneralInHackathon = createAsyncThunk(
  "hackathon/addMeetingGeneralInHackathon",
  async (MeetingData, thunkAPI) => {
    try {
      const { status, data } = await addMeetingGeneralInHackathonService(
        MeetingData
      );
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllMeetings = createAsyncThunk(
  "meeting/getAllMeetingsService",
  async (getAllMeeting, thunkAPI) => {
    try {
      const { status, data } = await getAllMeetingsService(getAllMeeting);
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addBookMeetingExpert = createAsyncThunk(
  "meeting/addBookMeetingExpert",
  async (MeetingData, thunkAPI) => {
    try {
      const { status, data } = await addBookMeetingExpertService(MeetingData);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const acceptMeetInvitation = createAsyncThunk(
  "meeting/acceptMeetInvitation",
  async (MeetingData, thunkAPI) => {
    try {
      const { status, data } = await acceptMeetInvitationService(
        MeetingData.status,
        MeetingData.token
      );
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllEventMeetings = createAsyncThunk(
  "meeting/getAllEventMeetings",
  async (id, thunkAPI) => {
    try {
      const { status, data } = await getAllEventMeetingsService(id);
      if (status === 200 || status === 201) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  addMeetingHackathon: {
    status: "idle",
    data: {},
    error: {},
  },
  getAll: {
    status: "idle",
    data: {},
    error: {},
  },
  addBookMeeting: {
    status: "idle",
    data: {},
    error: {},
  },
  acceptMeet: {
    status: "idle",
    data: {},
    error: {},
  },
  eventMeetings: {
    status: "idle",
    data: {},
    error: {},
  },
};

const MeetingSlice = createSlice({
  name: "meeting",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getAllEventMeetings.pending.type]: (state) => {
      state.eventMeetings = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllEventMeetings.fulfilled.type]: (state, action) => {
      state.eventMeetings = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllEventMeetings.rejected.type]: (state, action) => {
      state.eventMeetings = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addMeetingGeneralInHackathon.pending.type]: (state) => {
      state.addMeetingHackathon = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addMeetingGeneralInHackathon.fulfilled.type]: (state, action) => {
      state.addMeetingHackathon = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addMeetingGeneralInHackathon.rejected.type]: (state, action) => {
      state.addMeetingHackathon = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [getAllMeetings.pending.type]: (state) => {
      state.getAll = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getAllMeetings.fulfilled.type]: (state, action) => {
      state.getAll = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getAllMeetings.rejected.type]: (state, action) => {
      state.getAll = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [addBookMeetingExpert.pending.type]: (state) => {
      state.addBookMeeting = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addBookMeetingExpert.fulfilled.type]: (state, action) => {
      state.addBookMeeting = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [addBookMeetingExpert.rejected.type]: (state, action) => {
      state.addBookMeeting = {
        status: "failed",
        data: {},
        error: {},
      };
    },
    [acceptMeetInvitation.pending.type]: (state) => {
      state.acceptMeet = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [acceptMeetInvitation.fulfilled.type]: (state, action) => {
      state.acceptMeet = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [acceptMeetInvitation.rejected.type]: (state, action) => {
      state.acceptMeet = {
        status: "failed",
        data: {},
        error: {},
      };
    },
  },
});

export default MeetingSlice.reducer;
