import React from "react";
import Card from "components/card/Card.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { Link as Linkk } from "react-router-dom";
import Pagination from "components/pagination/Pagination";
import GrantsItem from "./components/GrantsItem";
import { getAllGrants } from "reducers/grants";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TextCard from "components/card/TextCard";
import Loader from "components/loader/Loader";

const Grants = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [userType, setUserType] = useState("");
  const status = useSelector((state) => state.Grants.getgrantsState.status);
  const GrantsList = useSelector((state) =>
    state.Grants.getgrantsState.data
      ? state.Grants.getgrantsState.data.grants
      : []
  );
  const totalPages = useSelector((state) =>
    state.Grants.getgrantsState.data
      ? state.Grants.getgrantsState.data.totalPages
      : 0
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const fetchgrants = async () => {
    const filterData = {
      page: page,
      limit: 6,
    };
    await dispatch(getAllGrants(filterData)).unwrap();
  };
  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
    fetchgrants();
  }, [currentUser, page]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer />
      <Card p="20px">
        <Flex direction={"column"} gap={{ base: "20px", xl: "20px" }}>
          {(userType == "Admin" || userType == "SuperAdmin") && (
            <Flex w="100%" justifyContent="flex-end">
              <BasicButton
                title={"Add Grants"}
                onClick={() => history.push("/admin/addGrants")}
              />
            </Flex>
          )}
          {GrantsList && GrantsList.length > 0 && (
            <>
              <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                {GrantsList.map((item, index) => (
                  <GrantsItem grants={item} page={page}></GrantsItem>
                ))}
              </SimpleGrid>{" "}
              {totalPages > 1 && (
                <Center pt="5">
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    setPage={setPage}
                    setLoader={() => {}}
                    />
                </Center>
              )}
            </>
          )}
          {GrantsList && GrantsList.length === 0 && (
              <TextCard
                text="There is no Grants"
              />
          )}
          {!GrantsList && (
            <Loader />
          )}
        </Flex>
      </Card>
    </Box>
  );
};

export default Grants;
