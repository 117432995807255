import {
  Box,
  Flex,
  Image,
  Text,
  Badge,
  Avatar,
  AvatarGroup,
  useDisclosure,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import config from "config/axios/config";
import { FaHeart } from "react-icons/fa";
import BasicButton from "components/button/BasicButton";
import SubmissionModal from "../SubmissionModal";
import placeholder from "assets/img/placeholder.jpg";
import ReactStars from "react-rating-stars-component";
import { RatingProjectsInHackathon } from "reducers/hackathon";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const ProjectCardVertical = (props) => {
  const {
    hackathon,
    project,
    track,
    showSubmit = false,
    trackId,
    onReload,
    canSubmit,
    showRating = false,
    isWinner = false,
    hackathonId,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const goTo = () => history.push("/admin/project/" + project._id);
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const RatingProjectsInHackathonAction = async (projectId, rate) => {
    const data = {
      hackathonId: hackathonId,
      rate: rate,
      projectId: projectId,
    };
    await dispatch(RatingProjectsInHackathon(data));
    onReload();
  };
  useEffect(() => {
    console.log(hackathonId, "fofofofofof");
  }, [hackathonId]);
  return (
    <Box
      maxW="md"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      bg="white"
      boxShadow="md"
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
    >
      {isOpen && (
        <SubmissionModal
          project={project}
          idHackathon={hackathonId}
          track={track}
          isOpen={isOpen}
          onClose={onClose}
          trackId={trackId}
          onReload={onReload}
        />
      )}
      <Image
        src={config.apiUrl + `/project/file/` + project.images}
        alt={`${project.title} Image`}
        boxSize="full"
        objectFit="cover"
        onClick={goTo}
        h="140px"
        fallbackSrc={placeholder}
      />
      {isWinner && (
        <Box
          position="absolute"
          background="rgb(255, 215, 0)"
          transform="rotate(-45deg)"
          boxShadow="md"
          style={{
            padding: "0px 5px",
            width: "100px",
            top: "16px",
            left: "-24px",
            textAlign: "center",
            color: "rgb(121 85 72)",
            letterSpacing: "1.5px",
          }}
        >
          <Text fontWeight={"700"} fontSize={"xs"}>
            WINNER
          </Text>
        </Box>
      )}
      <Flex
        p="2"
        pr="4"
        pl="4"
        direction={"column"}
        justifyContent={"space-between"}
        flexGrow={1}
      >
        <Flex direction={"column"} flexGrow={1}>
          {!showSubmit && (
            <Text
              _hover={{ color: "blue.500", cursor: "pointer" }}
              fontWeight="bold"
              fontSize="l"
              onClick={goTo}
            >
              {project.title}
            </Text>
          )}
          {showSubmit && (
            <Flex justifyContent={"space-between"} alignItems={"center"} mb="2">
              <Text
                _hover={{ color: "blue.500", cursor: "pointer" }}
                fontWeight="bold"
                fontSize="l"
                onClick={goTo}
              >
                {project.title}
              </Text>
              <BasicButton
                size={"xs"}
                title={project.hasSubmitted ? "Update Submit" : "Submit"}
                onClick={onOpen}
                colorScheme="green"
                variant="solid"
              />
            </Flex>
          )}
          <Text fontSize="sm" className="description" color="gray.600" mb="2">
            {project.description}
          </Text>
          <Flex direction="column" mb="2">
            {hackathon && (
              <ReactStars
                defaultState={5}
                count={5}
                size={20}
                onChange={(rate) =>
                  RatingProjectsInHackathonAction(project._id, rate)
                }
                activeColor="#ffd700"
                value={project.rate}
                isHalf
                edit={hackathon.voters.includes(currentUser._id)}
              />
            )}
            <Badge colorScheme="blue" size="xs" mr="2" whiteSpace={"unset"}>
              {project.category}{" "}
            </Badge>
            <Badge colorScheme="green" size="xs" mt="2" whiteSpace={"unset"}>
              {track}
            </Badge>
          </Flex>
        </Flex>
        <Flex mt="2" justifyContent={"space-between"}>
          {project.teamMemberEmails && (
            <AvatarGroup size="xs" max={5}>
              <Avatar
                name={
                  project.createdby.firstName + " " + project.createdby.lastName
                }
                src={config.apiUrl + "/api/getfile/" + project.createdby.Image}
              />
              {project.teamMemberEmails.map((member, index) => (
                <Avatar
                  name={member.firstName + " " + member.lastName}
                  src={config.apiUrl + "/api/getfile/" + member.Image}
                />
              ))}
            </AvatarGroup>
          )}
          <Flex alignItems={"center"}>
            <Text color="#9e9e9ea1" fontSize={"sm"} mr="2">
              {project.likes.length}{" "}
            </Text>
            <FaHeart color="#9e9e9ea1" fontSize={"sm"} />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProjectCardVertical;
