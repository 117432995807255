import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Flex, Heading } from "@chakra-ui/react";
import { getProjectById } from "reducers/project";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import DeletedItemCard from "components/card/DeletedItemCard";
import ProjectInfos from "./components/projectDetails/ProjectInfos";
import ProjectSubmission from "./components/projectDetails/ProjectSubmission";
import { ToastContainer } from "react-toastify";

const ProjectDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState("");
  const [reload, setReload] = useState(false);
  const project = useSelector((state) =>
    state.Project.project.data ? state.Project.project.data.result : null
  );
  const status = useSelector((state) => state.Project.project.status);
  const vote = useSelector((state) =>
    state.Project.project.data ? state.Project.project.data : null
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const getProjectByIdAction = async () => {
    await dispatch(getProjectById(id)).unwrap();
  };
  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
    setUserId(currentUser ? currentUser._id : "");
    getProjectByIdAction();
    setReload(false);
  }, [currentUser, reload]);

  return (
    <Flex
      flexDirection={"column"}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <ToastContainer />
      {!project && status === "loading" && <Loader />}
      {!project && status === "failed" && <DeletedItemCard item={"Project"} />}
      {project && (
        <>
          <ProjectInfos
            project={project}
            vote={vote}
            isOwner={userId == project.createdby._id}
            userType={userType}
            userId={userId}
            onReload={() => setReload(true)}
          />
          {project && project.hackthonSumbmition.length > 0 && (
            <div mt="3" mb="3">
              <Heading size="md" mb="4">
                Submission Hackathon
              </Heading>
              {project.hackthonSumbmition.map((item, index) => (
                <ProjectSubmission submission={item} />
              ))}
            </div>
          )}
        </>
      )}
    </Flex>
  );
};

export default ProjectDetails;
