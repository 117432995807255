import React from "react";
import Card from "components/card/Card.js";
import { Flex, Box, Center } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getdetailscallOfVoting } from "reducers/callOfVoting";
import { useParams } from "react-router-dom";
import TrackProjects from "./components/trackDetails/TrackProjects";
import TrackInfos from "./components/trackDetails/TrackInfos";
import Loader from "components/loader/Loader";
import DeletedItemCard from "components/card/DeletedItemCard";
import BackButton from "components/button/BackButton";
import { ToastContainer } from "react-toastify";
import TrackVoters from "./components/trackDetails/TrackVoters";
import { getTrackById } from "reducers/track";
import { getProjectsByTrack } from "reducers/track";
import Pagination from "components/pagination/Pagination";

export default function TrackDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const [canEdit, setCanEdit] = useState(false);
  const [voters, setVoters] = useState([]);
  const projects = useSelector((state) =>
    state.Track.projectsByTrack.data
      ? state.Track.projectsByTrack.data.result
      : []
  );
  const totalPages = useSelector((state) =>
    state.Track.projectsByTrack.data
      ? state.Track.projectsByTrack.data.totalPages
      : []
  );
  const track = useSelector((state) =>
    state.Track.trackById.data ? state.Track.trackById.data : []
  );
  const status = useSelector((state) => state.Track.trackById.status);
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );

  const getTrackAction = async () => {
    await dispatch(getTrackById(id)).unwrap();
  };

  useEffect(() => {
    getTrackAction();
  }, [id]);

  useEffect(() => {
    if (currentUser && track && track.createdBy) {
      console.log(track, "cutt");
      setCanEdit(
        currentUser.userType === "SuperAdmin" ||
          track.createdBy._id === currentUser._id
          ? true
          : false
      );
      // setVoters(track.voteSession.idsUsers);
    }
  }, [track, currentUser]);
  useEffect(() => {
    console.log(track, status, "trackone");
  }, [track, status]);
  const getProjectsByTrackAction = async () => {
    const payload = {
      id: id,
      projectTitle: "",
      limit: 6,
      page: page,
    };
    await dispatch(getProjectsByTrack(payload)).unwrap();
  };

  useEffect(() => {
    getProjectsByTrackAction();
  }, [page]);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ToastContainer />
      {!track && status === "loading" && <Loader />}
      {!track && status === "failed" && <DeletedItemCard item={"Track"} />}
      {track && (
        <Card>
          <TrackInfos track={track} canEdit={canEdit} />
          {/* <TrackVoters id={id} /> */}
          {projects && projects.length > 0 && (
            <TrackProjects trackId={id} projects={projects} />
          )}
          {totalPages > 1 && (
            <Center pt="5">
              <Pagination
                page={page}
                totalPages={totalPages}
                setPage={setPage}
                setLoader={() => {}}
              />
            </Center>
          )}
        </Card>
      )}
    </Box>
  );
}
