import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getcoursesService } from "services/academy";

export const getallCourses = createAsyncThunk(
  "moodle/getallCourses",
  async (Data, thunkAPI) => {
    try {
      const { status, data } = await getcoursesService();
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


const initialState = {
  getcourse: {
    status: "idle",
    data: {},
    error: {},
  },

};

const AcademysSlice = createSlice({
  name: "Academy",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getallCourses.pending.type]: (state) => {
      state.getcourse = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getallCourses.fulfilled.type]: (state, action) => {
      state.getcourse = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getallCourses.rejected.type]: (state, action) => {
      state.getcourse = {
        status: "failed",
        data: {},
        error: {},
      };
    },
 
  },
});

export default AcademysSlice.reducer;
