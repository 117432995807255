
export const getUserToken = async () => {
  
  try {
    return  await localStorage.getItem("token");
  } catch (error) {
    throw new Error("An error occured");
  }
};

export default { getUserToken };