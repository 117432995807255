import React from "react";
import Chart from "react-apexcharts";
import { Box, Flex, Select, Text, useColorModeValue } from "@chakra-ui/react";

export default function StatsChart(props) {
  const {
    title,
    config,
    data,
    noDataText,
    type = "line",
    showFilter = true,
    setMonthYearFilter,
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <div className="card">
      <Flex
        px="25px"
        justify="space-between"
        mt="10px"
        mb="10px"
        align="center"
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {title}
        </Text>
        {showFilter && (
          <Select
            justifyContent={"end"}
            onChange={(ev) => {
              setMonthYearFilter(ev.target.value);
            }}
            w="385px"
            name="category"
            id="category"
            borderColor={"#E0E5F2"}
            backgroundColor="white"
          >
            <option value="month">Month</option>
            <option value="year">Year</option>
          </Select>
        )}
      </Flex>
      {data && data.length > 0 && (
        <Chart
          options={config.options}
          series={config.series}
          type={type}
          width="100%"
          height="250"
        />
      )}
      {data && data.length === 0 && (
        <Box
          backgroundColor={"transparent"}
          borderRadius="20px"
          my="25px"
          px="19px"
          py="17"
          width={"100%"}
          display={"flex"}
        >
          <Text textAlign="center">{noDataText}</Text>
        </Box>
      )}
    </div>
  );
}
