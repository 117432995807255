import React from "react";
import {
  Text,
  Flex,
  Box,
  Image,
  Button,
  useColorModeValue,
  Badge,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import { Link as Linkk } from "react-router-dom";
import { useEffect } from "react";
export default function PartnersItem(props) {
  const { item } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  useEffect(() => {
    console.log(item, "item");
  }, []);
  return (
    <Card p="1" mb="2" boxShadow={cardShadow}>
      <Flex>
        <Flex
          width="100%"
          height="100%"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
          textAlign="start"
        >
          <Image
            h="50px"
            w="50px"
            src={config.apiUrl + "/org/file/" + item.image}
            borderRadius="8px"
            fallbackSrc={placeholder}
            objectFit="cover"
          />
          <Box ml="3">
            <Text fontWeight="normal" color="blue">
              <Linkk>{item.name}</Linkk>
            </Text>
            {item &&
              item.categories &&
              item.categories.map((category) => (
                <Badge
                  variant={"outline"}
                  colorScheme="blue"
                  cursor={"pointer"}
                  ml={"2px"}
                >
                  {category}{" "}
                </Badge>
              ))}
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
}
