import {
  Text,
  Box,
  Flex,
  Avatar,
  HStack,
  Button,
  Input,
  Textarea,
  InputGroup,
  InputLeftElement,
  Heading,
  Divider,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwitchField from "components/fields/SwitchField";
import UploadComponent from "./components/UploadFile";
import UploadImageSpeakers from "./components/UploadImageSpeakers";
import { addEvent } from "reducers/event";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import { checkIsValidLinkedinUrl } from "utils/functions";
import { checkIsValidSiteWeb } from "utils/functions";
import { checkIsValidEmail } from "utils/functions";
import { validatePhoneNumber } from "utils/functions";
import { BsX } from "react-icons/bs";
import { toast } from "react-toastify";
import { useContext } from "react";
import { SocketContext } from "contexts/SocketContext";
import FileUpload from "components/upload/FileUpload";

const AddEvent = () => {
  const socket = useContext(SocketContext);

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dates, setDates] = useState([]);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [online, setOnline] = useState(false);
  const [emplacement, setEmplacement] = useState("");
  const [url, setUrl] = useState("");
  const [SpeekData, setSpeekData] = useState({ names: [], photos: [] });
  const [linkedin, setLinkedin] = useState("");
  const [siteWeb, setSiteWeb] = useState("");
  const [discord, setDiscord] = useState("");

  const [titleError, setTitleError] = useState("");
  const [urlError, setUrlError] = useState("");
  const [emplacementError, setEmplacementError] = useState("");
  const [speakersError, setSpeakersError] = useState("");
  const [datesError, setDatesError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [linkedinError, setLinkedinError] = useState("");
  const [siteWebError, setSiteWebError] = useState("");
  const [discordError, setDiscordError] = useState("");
  const [photoError, setPhotoError] = useState("");

  const [index, setIndex] = useState(0);
  const [currentSpeakerPhoto, setCurrentSpeakerPhoto] = useState("");
  const [currentSpeakerName, setCurrentSpeakerName] = useState("");
  const status = useSelector((state) => state.Event.addevent.status);
  const event = useSelector((state) =>
    state.Event.addevent.data ? state.Event.addevent.data.event : null
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const notificationsSettings = useSelector((state) =>
    state.NotificationsSettings.notificationsSettings.data
      ? state.NotificationsSettings.notificationsSettings.data.message
      : []
  );
  const range = (size, startAt = 0) => {
    return [...Array(size).keys()].map((i) => i + startAt);
  };
  const Upfiles = (file) => {
    setPhoto(file);
  };
  const UpSpeakerPhoto = (file, i) => {
    setCurrentSpeakerPhoto(file);
  };
  const validateForm = () => {
    let inValid = false;
    if (!photo) {
      setPhotoError("The image is required!");
      inValid = true;
    } else {
      setPhotoError("");
    }
    if (!title) {
      setTitleError("The title is required!");
      inValid = true;
    } else {
      setTitleError("");
    }
    if (SpeekData.names.length === 0) {
      setSpeakersError("You should add at least one speaker!");
      inValid = true;
    } else {
      setSpeakersError("");
    }
    if (!online &&!emplacement) {
      setEmplacementError("The emplacement is required!");
      inValid = true;
    } else {
      setEmplacementError("");
    }
    if (online && !url) {
      setUrlError("The url is required!");
      inValid = true;
    } else {
      setUrlError("");
    }
    if (!start || !end) {
      setDatesError("The start & end dates are required!");
      inValid = true;
    } else {
      setDatesError("");
    }

   
    if (!email) {
      setEmailError("The email is required!");
      inValid = true;
    } else if (!checkIsValidEmail(email)) {
      setEmailError("The email is not valid!");
    } else {
      setEmailError("");
    }

    if (phone && !validatePhoneNumber(phone)) {
      setPhoneError("The phone number is not valid!");
      inValid = true;
    } else {
      setPhoneError("");
    }
    if (linkedin && !checkIsValidLinkedinUrl(linkedin)) {
      setLinkedinError("Invalid linkedin url!");
      inValid = true;
    } else {
      setLinkedinError("");
    }
    if (siteWeb && !checkIsValidSiteWeb(siteWeb)) {
      setSiteWebError("Invalid url!");
      inValid = true;
    } else {
      setSiteWebError("");
    }
    if (discord && !checkIsValidSiteWeb(discord)) {
      setDiscordError("Invalid discord url!");
      inValid = true;
    } else {
      setDiscordError("");
    }
    return inValid;
  };
  const dispatch = useDispatch();

  const addEventAction = async () => {
    const inValid = validateForm();
    if (inValid) {
      return;
    }
    const data = new FormData();
    data.append("end", end);
    data.append("start", start);
    data.append("title", title);
    data.append("description", description);
    data.append("email", email);
    data.append("phone", phone);
    data.append("linkedin", linkedin);
    data.append("website", siteWeb);
    data.append("discord", discord);
    data.append("type", online ? "online" : "offline");
    console.log(emplacement, "kakakaoaoaoaoao");
    data.append("emplacement", emplacement);
    data.append("url", online ? url : "");
    console.log(SpeekData,"lalalala")
    SpeekData.names.map((name) => {
      data.append("speakers", name);
    });
    // data.append("speakers", JSON.stringify(SpeekData.names));
    data.append("image", photo[0]);
    SpeekData.photos.map((f, index) => {
      data.append("speakerFiles" + index, f[0]);
    });
    const eventData = {
      data: data,
      roles: notificationsSettings ? notificationsSettings.AddingEvent : null,
      currentUserId: currentUser ? currentUser._id : null,
      socket: socket,
    };
    await dispatch(addEvent(eventData))
      .unwrap()
      .then((res) => {
        console.log(res, "mamaapapapapapapa");
        if (res.success) {
          toast.success("Event has been added successfully!", {
            position: "top-right",
            hideProgressBar: true,
            theme: "light",
          });
        }
      });
  };
  // useEffect(() => {
  //   if (status === "success") {
  //     const notify = () =>
  //       toast.success("Event has been added successfully!", {
  //         position: "top-right",
  //         hideProgressBar: true,
  //         theme: "light",
  //       });
  //     notify();
  //   }
  // }, [status]);

  const removeSpeaker = (i) => {
    SpeekData.names.splice(i, 1);
    SpeekData.photos.splice(i, 1);
    const x = {
      names: SpeekData.names,
      photos: SpeekData.photos,
    };
    setSpeekData(x);
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Heading size="sm" mb="2">
          General Informations
        </Heading>
        <Divider mb="2" />
        <Flex justifyContent={"flex-start"} flexDirection={"column"} mb="4">
          {/* <Heading size="sm" mb="3" >
            1- Upload an image:
          </Heading> */}
          <FileUpload
            multiple={false}
            files={photo}
            setFiles={setPhoto}
            error={photoError}
          />
        </Flex>
        {/* <Flex mt="2" direction={"column"}>
          <UploadComponent setFiles={Upfiles} />
          {photoError && (
            <Text mt="3" color="red">
              {photoError}
            </Text>
          )}
        </Flex> */}
        <Flex mt="2" direction={"column"}>
          <Input
            isInvalid={titleError}
            marginTop={"5px!important"}
            w={"100%"}
            marginRight="10px"
            placeholder="Title"
            borderRadius="5px"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {titleError && (
            <Text mt="3" color="red">
              {titleError}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <Textarea
            marginTop={"5px!important"}
            w={"100%"}
            marginRight="10px"
            placeholder="Description"
            borderRadius="5px"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Flex>
        <Flex mt="2" direction={"column"}>
          <DatePicker
            inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
            format="MM/DD/YYYY HH:mm"
            range
            plugins={[
              <TimePicker position="bottom" hideSeconds={true} />,
              <DatePanel markFocused header="Start - End" />,
            ]}
            placeholder="Start & End Dates"
            id="dates"
            name="dates"
            value={dates}
            onChange={(ev) => {
              setDates(ev);
              if (ev[0]) {
                setStart(
                  ev[0].year +
                    "/" +
                    (ev[0].month.index + 1).toString() +
                    "/" +
                    ev[0].day +
                    " " +
                    ev[0].hour +
                    ":" +
                    ev[0].minute
                );
              }

              if (ev[1]) {
                setEnd(
                  ev[1].year +
                    "/" +
                    (ev[1].month.index + 1).toString() +
                    "/" +
                    ev[1].day +
                    " " +
                    ev[1].hour +
                    ":" +
                    ev[1].minute
                );
              }
            }}
            onClose={() => true}
          />
          {datesError && (
            <Text mt="3" color="red">
              {datesError}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <SwitchField
            reversed={true}
            fontSize="sm"
            id="6"
            label="Online"
            isChecked={online}
            onChange={() => {
              setOnline((c) => (c ? false : true));
            }}
          />
        </Flex>
        <Flex mt="2" direction={"column"}>
          {!online && (
            <>
              <Input
                isInvalid={emplacementError}
                type="text"
                placeholder="Emplacement"
                id="emplacement"
                name="emplacement"
                value={emplacement}
                onChange={(ev) => setEmplacement(ev.target.value)}
              />
              {emplacementError && (
                <Text mt="3" color="red">
                  {emplacementError}
                </Text>
              )}
            </>
          )}

          {online && (
            <>
              <Input
                isInvalid={urlError}
                type="text"
                placeholder="Link"
                id="link"
                name="link"
                value={url}
                onChange={(ev) => setUrl(ev.target.value)}
              />
              {urlError && (
                <Text mt="3" color="red">
                  {urlError}
                </Text>
              )}
            </>
          )}
        </Flex>
        {/* Contact  */}
        <Heading size="sm" mt="2" mb="2">
          Contact
        </Heading>
        <Divider mb="2" />
        <Flex mt="2" direction={"column"}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<EmailIcon color="gray.300" />}
            />
            <Input
              isInvalid={emailError}
              w={"100%"}
              marginRight="10px"
              type="email"
              placeholder="Email"
              borderRadius="5px"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          {emailError && (
            <Text mt="3" color="red">
              {emailError}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<PhoneIcon color="gray.300" />}
            />
            <Input
              isInvalid={phoneError}
              w={"100%"}
              marginRight="10px"
              borderRadius="5px"
              value={phone}
              type="tel"
              placeholder="Phone number"
              onChange={(e) => setPhone(e.target.value)}
            />
          </InputGroup>
          {phoneError && (
            <Text mt="3" color="red">
              {phoneError}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <InputGroup>
            <Input
              isInvalid={linkedinError}
              w={"100%"}
              marginRight="10px"
              borderRadius="5px"
              value={linkedin}
              placeholder="Linkedin"
              onChange={(e) => setLinkedin(e.target.value)}
            />
          </InputGroup>
          {linkedinError && (
            <Text mt="3" color="red">
              {linkedinError}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <InputGroup>
            <Input
              isInvalid={siteWebError}
              w={"100%"}
              marginRight="10px"
              borderRadius="5px"
              value={siteWeb}
              placeholder="Site web"
              onChange={(e) => setSiteWeb(e.target.value)}
            />
          </InputGroup>
          {siteWebError && (
            <Text mt="3" color="red">
              {siteWebError}
            </Text>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <InputGroup>
            <Input
              isInvalid={discordError}
              w={"100%"}
              marginRight="10px"
              borderRadius="5px"
              value={discord}
              placeholder="Discord"
              onChange={(e) => setDiscord(e.target.value)}
            />
          </InputGroup>
          {discordError && (
            <Text mt="3" color="red">
              {discordError}
            </Text>
          )}
        </Flex>
        {/* Contact  */}

        <Heading size="sm" mt="2" mb="2">
          Speakers
        </Heading>
        <Divider mb="2" />
        <Flex direction={"column"}>
          {SpeekData.names.length > 0 && (
            <HStack spacing={4}>
              {range(index).map((i) => (
                <>
                  {SpeekData.names[i] && (
                    <Tag
                      size="lg"
                      colorScheme="blue"
                      variant={"outline"}
                      borderRadius="full"
                    >
                      <Avatar
                        src={
                          SpeekData.photos[i] && SpeekData.photos[i][0]
                            ? URL.createObjectURL(SpeekData.photos[i][0])
                            : ""
                        }
                        size="xs"
                        name={SpeekData.names[i]}
                        ml={-1}
                        mr={2}
                      />
                      <TagLabel mr="2">{SpeekData.names[i]}</TagLabel>
                      <BsX
                        color="#343a40"
                        cursor={"pointer"}
                        onClick={() => removeSpeaker(i)}
                      />
                    </Tag>
                  )}
                </>
              ))}
            </HStack>
          )}
        </Flex>
        <Flex mt="2" direction={"column"}>
          <HStack spacing="4px" mb="2">
            <UploadImageSpeakers
              settingPhoto={UpSpeakerPhoto}
              index={index}
              show={show}
              setShow={setShow}
            />
            <Input
              type="text"
              placeholder="Speaker Name"
              name="speaker"
              value={currentSpeakerName}
              onChange={(ev) => {
                setCurrentSpeakerName(ev.target.value);
              }}
            />
            <Button
              variant={"outline"}
              onClick={() => {
                const array = SpeekData;
                array.names[index] = currentSpeakerName;
                array.photos[index] = currentSpeakerPhoto;
                setSpeekData(array);
                setIndex((c) => c + 1);
                setShow(false);
                setCurrentSpeakerName("");
                setCurrentSpeakerPhoto("");
              }}
            >
              +
            </Button>
          </HStack>
          {speakersError && (
            <Text mt="3" color="red">
              {speakersError}
            </Text>
          )}
        </Flex>

        <Flex justifyContent="end" mt="4">
          <Button colorScheme="blue" size="sm" onClick={() => addEventAction()}>
            Add Event
          </Button>
        </Flex>
      </Card>
    </Box>
  );
};

export default AddEvent;
