import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserToken } from "../services/UserToken";

export const getUserTokenReducers = createAsyncThunk(
  "users/getToken",
  async (thunkAPI) => {
    try {
      const data  = await getUserToken();
      console.log(data);
     return data;
    
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
    loading:false,
    data: "",
    error: {},
  

  
};
const UserToken = createSlice({
  name: "getUserToken",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getUserTokenReducers.pending]:(state,action)=>{
        state.loading = true;
    },
    [getUserTokenReducers.fulfilled]:(state,action)=>{
        state.loading = false;
        state.data =action.payload;
    },
    [getUserTokenReducers.rejected]:(state,action)=>{
        state.loading = false;
        state.error=true;
    },

 
  },
});

export default UserToken.reducer;
