import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCohortCreationCountByMonth } from "reducers/dashboard";
import StatsChart from "./StatsChart";

export default function AssistingProgramsStats() {
  const dispatch = useDispatch();
  const [xValues, setxValues] = useState([]);
  const [yValues, setyValues] = useState([]);
  const [monthYearFilter, setMonthYearFilter] = useState("month");

  const config = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: xValues,
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
    },
    series: [
      {
        name: "Cohorts",
        data: yValues,
      },
    ],
  };
  const data = useSelector((state) =>
    state.Dashboard.CohortCreationCountByMonth.data
      ? state.Dashboard.CohortCreationCountByMonth.data.result
      : []
  );
  const status = useSelector((state) =>
    state.Dashboard.CohortCreationCountByMonth.status
      ? state.Dashboard.CohortCreationCountByMonth.status
      : []
  );

  const getAssistingProgramsAction = async () => {
    const payload = {
      monthyear: monthYearFilter,
    };
    await dispatch(getCohortCreationCountByMonth(payload)).unwrap();
  };
  useEffect(() => {
    if (status == "success") {
      const month = data.map((item) => (item.month ? item.month : item.year));
      setxValues(month);
      const Count = data.map((item) => item.count);
      setyValues(Count);
    }
  }, [status, data]);
  useEffect(() => {
    getAssistingProgramsAction();
  }, [monthYearFilter]);
  return (
    <StatsChart
      title={"Assisting Programs"}
      config={config}
      data={data}
      noDataText={"There is no Assisting Program"}
      setMonthYearFilter={setMonthYearFilter}
      type={"bar"}
    />
  );
}
