import React from "react";
import { Flex } from "@chakra-ui/react";
import TextCard from "components/card/TextCard";

const NewMemberConfirmation = () => {
  return (
    <Flex
      flexDirection={"column"}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <Flex flexDirection="column" h="100vh">
        <TextCard
          text="Success!"
          subText={"You have successfully added a new team member"}
        />
      </Flex>
    </Flex>
  );
};

export default NewMemberConfirmation;
