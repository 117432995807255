import React, { useState, useEffect } from "react";
import { Text, Flex, Input, Textarea, FormLabel } from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import SwitchField from "components/fields/SwitchField";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { checkIsValidEmail } from "utils/functions";
import moment from "moment";

const HackathonInfoForm = (props) => {
  const { HackathonData, setHackathonData, activeStep, steps, setActiveStep } =
    props;
  const [title, setTitle] = useState(HackathonData.title);
  const [description, setDescription] = useState(HackathonData.description);
  const [dates, setDates] = useState(
    HackathonData.start
      ? [
          moment(new Date(HackathonData.start)).format("MM/DD/YYYY HH:mm"),
          moment(new Date(HackathonData.end)).format("MM/DD/YYYY HH:mm"),
        ]
      : ""
  );
 
  const [general, setGeneral] = useState(HackathonData.general);
  const [start, setStart] = useState(
    HackathonData.start
      ? moment(new Date(HackathonData.start)).format("MM/DD/YYYY HH:mm")
      : ""
  );
  const [end, setEnd] = useState(
    HackathonData.end
      ? moment(new Date(HackathonData.start)).format("MM/DD/YYYY HH:mm")
      : ""
  );
  const [managerEmail, setManagerEmail] = useState(HackathonData.managerEmail);
  const [managerEmailError, setManagerEmailError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [datesError, setDatesError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const validateForm = () => {
    let valid = true;
    if (!title) {
      setTitleError("The title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!managerEmail) {
      setManagerEmailError("Manager email is required!");
      valid = false;
    } else if (!checkIsValidEmail(managerEmail)) {
      setManagerEmailError("Invalid email!");
      valid = false;
    } else {
      setManagerEmailError("");
    }
    if (!description) {
      setDescriptionError("The description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!start || !end) {
      setDatesError("The start & end dates are required!");
      valid = false;
    } else {
      setDatesError("");
    }
    
    // if (general && !endDateSubmission) {
    //   setEndDateSubmissionError("The end date submission are required!");
    //   valid = false;
    // }
    // if (general && new Date(endDateSubmission) <= new Date(start)) {
    //   setEndDateSubmissionError(
    //     "The end date submission must be after the start date of the hackathon"
    //   );
    //   valid = false;
    // } else {
    //   setEndDateSubmissionError("");
    // }
    return valid;
  };

  const next = () => {
    setSubmitted(true);
    if (!validateForm()) {
      return;
    }
    const data = {
      title: title,
      description: description,
      general: general,
      // endDateSubmition: endDateSubmission,
      end: end,
      start: start,
      typeOfCohort: "Hackathon",
      managerEmail: managerEmail,
    };
    setHackathonData(data);
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [title, general, description, end, start, submitted]);
  return (
    <>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Title <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Input
          isInvalid={titleError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Title"
          borderRadius="5px"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {titleError && (
          <Text mt="3" color="red">
            {titleError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Description <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Textarea
          isInvalid={descriptionError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="Description"
          borderRadius="5px"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {descriptionError && (
          <Text mt="3" color="red">
            {descriptionError}
          </Text>
        )}
      </Flex>
      <Flex mt="2" direction={"column"}>
        <SwitchField
          reversed={true}
          fontSize="sm"
          label="General"
          isChecked={general}
          onChange={() => {
            setGeneral((c) => (c ? false : true));
          }}
        />
        <Text fontWeight="500" fontSize="sm">
          If you have created a non-general hackathon, you must create one or
          more tracks for your hackathon to be visible.
        </Text>
      </Flex>
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Dates <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <DatePicker
          inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
          format="MM/DD/YYYY HH:mm"
          range
          plugins={[
            <TimePicker position="bottom" hideSeconds={true} />,
            <DatePanel markFocused header="Start - End" />,
          ]}
          placeholder="Start & End Dates"
          id="dates"
          name="dates"
          value={dates}
          onChange={(ev) => {
            console.log(ev[0]);
            setDates(ev);
            if (ev[0]) {
              setStart(
                ev[0].year +
                  "/" +
                  (ev[0].month.index + 1).toString() +
                  "/" +
                  ev[0].day +
                  " " +
                  ev[0].hour +
                  ":" +
                  ev[0].minute
              );
            }

            if (ev[1]) {
              setEnd(
                ev[1].year +
                  "/" +
                  (ev[1].month.index + 1).toString() +
                  "/" +
                  ev[1].day +
                  " " +
                  ev[1].hour +
                  ":" +
                  ev[1].minute
              );
            }
          }}
          onClose={() => true}
        />
        {datesError && (
          <Text mt="3" color="red">
            {datesError}
          </Text>
        )}
      </Flex>
      {/* {general == true &&
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          End Date Submission <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <DatePicker
          inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
          format="MM/DD/YYYY HH:mm"
          plugins={[
            <TimePicker position="bottom" hideSeconds={true} />,
            <DatePanel markFocused header="Date Submission" />,
          ]}
          placeholder="End Date Submission"
          id="date"
          name="date"
          value={endDateSubmission[0]}
          onChange={(ev) =>
            setEndDateSubmission(
              ev.year +
                "/" +
                (ev.month.index + 1).toString() +
                "/" +
                ev.day +
                " " +
                ev.hour +
                ":" +
                ev.minute
            )
          }
        />
        {endDateSubmissionError && (
          <Text mt="3" color="red">
            {endDateSubmissionError}
          </Text>
        )}
      </Flex>
      } */}
      <Flex mt="2" direction={"column"}>
        <FormLabel>
          Hackathon Manager Email <span style={{ color: "red" }}>*</span>
        </FormLabel>{" "}
        <Input
          isInvalid={managerEmailError}
          marginTop={"5px!important"}
          w={"100%"}
          marginRight="10px"
          placeholder="name@gmail.com"
          borderRadius="5px"
          value={managerEmail}
          onChange={(e) => setManagerEmail(e.target.value)}
        />
        {managerEmailError && (
          <Text mt="3" color="red">
            {managerEmailError}
          </Text>
        )}
      </Flex>
      <Flex justifyContent={"flex-end"} mt="3">
        {activeStep !== 0 && activeStep !== steps.length - 1 && (
          <BasicButton
            onClick={() => setActiveStep(activeStep - 1)}
            title={"Previous"}
            variant="solid"
          />
        )}
        {activeStep !== steps.length - 1 && (
          <BasicButton onClick={next} title={"Next"} variant="solid" />
        )}
      </Flex>
    </>
  );
};

export default HackathonInfoForm;
