import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  MeetingReviewService,
  ProjectsNextCallForVotingService,
  RatingProjectInCallForVotingService,
  addCallOfVotingService,
  deleteCallForVotingService,
  getAllCallOfVotingService,
  getDetailsCallOfVotingService,
  getProjectsinCallOfVotingService,
} from "../services/callOfVoting";
export const deletecallforVoting = createAsyncThunk(
  "user/deletecallOfVoting",
  async (idCallForVoting, thunkAPI) => {
    try {
      const { status, data } = await deleteCallForVotingService(
        idCallForVoting
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addcallOfVoting = createAsyncThunk(
  "user/addcallOfVoting",
  async (CallOfVotingData, thunkAPI) => {
    try {
      const { status, data } = await addCallOfVotingService(CallOfVotingData);
      if (status === 200 || status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getcallOfVoting = createAsyncThunk(
  "user/getcallOfVoting",
  async (CallOfVotingData, thunkAPI) => {
    try {
      const { status, data } = await getAllCallOfVotingService();
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getdetailscallOfVoting = createAsyncThunk(
  "user/getdetailscallOfVoting",
  async (CallOfVotingid, thunkAPI) => {
    try {
      const { status, data } = await getDetailsCallOfVotingService(
        CallOfVotingid
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getProjectsinCallOfVoting = createAsyncThunk(
  "user/getProjectsinCallOfVoting",
  async (CallOfVotingid, thunkAPI) => {
    try {
      const { status, data } = await getProjectsinCallOfVotingService(
        CallOfVotingid
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const ProjectsNextCallForVoting = createAsyncThunk(
  "user/ProjectsNextCallForVoting",
  async (dataProjects, thunkAPI) => {
    try {
      const { status, data } = await ProjectsNextCallForVotingService(
        dataProjects
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const RatingProjectInCallForVoting = createAsyncThunk(
  "user/RatingProjectInCallForVoting",
  async (dataProjects, thunkAPI) => {
    try {
      const { status, data } = await RatingProjectInCallForVotingService(
        dataProjects
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const MeetingReviewReducer = createAsyncThunk(
  "user/MeetingReviewReducer",
  async (dataReview, thunkAPI) => {
    try {
      const { status, data } = await MeetingReviewService(
        dataReview
      );
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
  callofvote: {
    status: "idle",
    data: {},
    error: {},
  },
  deleteForVote: {
    status: "idle",
    data: {},
    error: {},
  },
  getcallofvote: {
    status: "idle",
    data: {},
    error: {},
  },
  detailsCallforVoting: {
    status: "idle",
    data: {},
    error: {},
  },
  projectsInCallForVoting: {
    status: "idle",
    data: {},
    error: {},
  },
  ProjectsNextCall: {
    status: "idle",
    data: {},
    error: {},
  },
  ratingProjects: {
    status: "idle",
    data: {},
    error: {},
  },
  MeetingReview: {
    status: "idle",
    data: {},
    error: {},
  },
};
const CallOfVotingSlice = createSlice({
  name: "voting-session",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [MeetingReviewReducer.pending.type]: (state) => {
      state.MeetingReview = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [MeetingReviewReducer.fulfilled.type]: (state, action) => {
      state.MeetingReview = {
        status: "success",
        data: {},
        error: {},
      };
    },
    [MeetingReviewReducer.rejected.type]: (state, action) => {
      state.MeetingReview = {
        status: "failed",
        data: {},
        error: action.payload.message ? action.payload.message : "",
      };
    },
    [deletecallforVoting.pending.type]: (state) => {
      state.deleteForVote = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [deletecallforVoting.fulfilled.type]: (state, action) => {
      state.deleteForVote = {
        status: "success",
        data: {},
        error: {},
      };
    },
    [deletecallforVoting.rejected.type]: (state, action) => {
      state.deleteForVote = {
        status: "failed",
        data: {},
        error: action.payload.message ? action.payload.message : "",
      };
    },
    [addcallOfVoting.pending.type]: (state) => {
      state.callofvote = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addcallOfVoting.fulfilled.type]: (state, action) => {
      state.callofvote = {
        status: "success",
        data: {},
        error: {},
      };
    },
    [addcallOfVoting.rejected.type]: (state, action) => {
      state.callofvote = {
        status: "failed",
        data: {},
        error: action.payload.message ? action.payload.message : "",
      };
    },
    [getcallOfVoting.pending.type]: (state) => {
      state.getcallofvote = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getcallOfVoting.fulfilled.type]: (state, action) => {
      state.getcallofvote = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getcallOfVoting.rejected.type]: (state, action) => {
      state.getcallofvote = {
        status: "failed",
        data: action.payload,
        error: action.payload.message ? action.payload.message : "",
      };
    },
    [getdetailscallOfVoting.pending.type]: (state) => {
      state.detailsCallforVoting = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getdetailscallOfVoting.fulfilled.type]: (state, action) => {
      state.detailsCallforVoting = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getdetailscallOfVoting.rejected.type]: (state, action) => {
      state.detailsCallforVoting = {
        status: "failed",
        data: action.payload,
        error: action.payload.message ? action.payload.message : "",
      };
    },
    [getProjectsinCallOfVoting.pending.type]: (state) => {
      state.projectsInCallForVoting = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [getProjectsinCallOfVoting.fulfilled.type]: (state, action) => {
      state.projectsInCallForVoting = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getProjectsinCallOfVoting.rejected.type]: (state, action) => {
      state.projectsInCallForVoting = {
        status: "failed",
        data: action.payload,
        error: action.payload.message ? action.payload.message : "",
      };
    },
    [ProjectsNextCallForVoting.pending.type]: (state) => {
      state.ProjectsNextCall = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [ProjectsNextCallForVoting.fulfilled.type]: (state, action) => {
      state.ProjectsNextCall = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [ProjectsNextCallForVoting.rejected.type]: (state, action) => {
      state.ProjectsNextCall = {
        status: "failed",
        data: action.payload,
        error: action.payload.message ? action.payload.message : "",
      };
    },
    [RatingProjectInCallForVoting.pending.type]: (state) => {
      state.ratingProjects = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [RatingProjectInCallForVoting.fulfilled.type]: (state, action) => {
      state.ratingProjects = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [RatingProjectInCallForVoting.rejected.type]: (state, action) => {
      state.ratingProjects = {
        status: "failed",
        data: action.payload,
        error: action.payload.message ? action.payload.message : "",
      };
    },
  },
});

export default CallOfVotingSlice.reducer;
