import React from "react";
import { Box, Button, Text, Flex, Image } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import logo from "assets/img/logo.png";
import { useDispatch } from "react-redux";
import { acceptTeamInvitation } from "reducers/project";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";

const TeamInvitationAcceptance = () => {
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isAccepted, setIsAccepted] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectOwnerName, setProjectOwnerName] = useState("");

  const handleAccept = () => {
    dispatch(acceptTeamInvitation(token))
      .unwrap()
      .then(() => {
        setIsAccepted(true);
      });
  };

  useEffect(() => {
    const data = jwt_decode(token);
    console.log(data,'fix fix')
    if (data) {
      setProjectName(data.newInvPromise.projectId.title);
      setProjectOwnerName(data.newInvPromise.invitedBy.firstName + " " + data.newInvPromise.invitedBy.lastName);
    }
  }, [token]);

  return (
    <Flex direction={"column"} justifyContent={"center"} alignItems={"center"}>
      <Image src={logo} alt="Logo" maxW="140px" />
      {!isAccepted && (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="lg"
          bg="white"
          p={4}
          mt={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Team Invitation
          </Text>
          <Text mb={2}>
            You have been invited by <b>{projectOwnerName}</b> to join the team
            of <b>{projectName}</b> project.
          </Text>
          <Text mb={2}>
            We appreciate your expertise and look forward to your valuable
            contributions to the project.
          </Text>
          <Text mb={2}>
            Please click the link below to accept the invitation and get
            started.
          </Text>
          <Flex justify="space-between" mt="3">
            <Button colorScheme="blue" size="md" onClick={handleAccept}>
              Accept Invitation
            </Button>
          </Flex>
        </Box>
      )}
      {isAccepted && (
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="lg"
          bg="white"
          p={4}
          mt={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Congratulations!
          </Text>
          <Text mb={2}>
            You are now a valued member of the team working on the{" "}
            <strong>{projectName}</strong> project.
          </Text>
          <Text mb={2}>
            Your expertise and contributions are essential to the success of our
            collaborative efforts. We look forward to achieving great milestones
            together.
          </Text>
          <Flex justify="space-between" mt="3">
            <Button
              colorScheme="blue"
              size="md"
              onClick={() => history.push("/auth/sign-in")}
            >
              Login
            </Button>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default TeamInvitationAcceptance;
