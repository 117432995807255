import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Spinner,
  FormErrorMessage,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/logoCov.jpg";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signUpUser } from "reducers/signUp";
import { useEffect } from "react";
import queryString from "query-string";
import Card from "components/card/Card";
import logo from "assets/img/logo.png";

function SignUp() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [loader, setLoader] = useState(false);
  const [topSCreenErr, setTopScreenErr] = useState("");
  const notify = () =>
    toast.success("a verification email has been sent to you.", {
      position: "top-right",
      hideProgressBar: false,
      onClick: () => {
        window.location.href = "/";
      },
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

  const schema = Yup.object().shape({
    policy: Yup.bool().oneOf(
      [true],
      "Please accept our Terms and Conditions, and our Privacy Policy"
    ),

    firstName: Yup.string()
      .required("This field is required")
      .min(2, "First name must be at least two characters."),

    lastName: Yup.string()
      .required("This field is required")
      .min(2, "last name must be at least two characters."),
    userName: Yup.string()
      .min(2, "user name is required and must be at least two characters long.")
      .matches(
        /^[a-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+/,
        "User name must contain only lowercase letters"
      ),

    email: Yup.string()
      .required("This field is required")
      .email("Please enter a valid email"),

    password: Yup.string()
      .required("This field is required")
      .min(
        8,
        "Your password must be at least 8 characters and should include a combination of numbers, letters and special characters (!$@%)"
      )
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
  });

  const paramsh = useParams();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const status = useSelector((state) => state.SignUp.signup.status);
  const error = useSelector((state) => state.SignUp.signup.error);
  useEffect(() => {
    if (status === "success") {
      //window.location.href = "/";
      toast.info("Welcome to our application , you need to signIn", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
      setLoader(false);
    } else if (status === "failed") {
      toast.info(error, {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
      setLoader(false);
      setTopScreenErr(error);
    }
  }, [status]);
  const dispatch = useDispatch();
  const handleSubmitExpert = async (values) => {
    const expert = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      policy: values.policy,
      userName: values.userName,
      role: "Expert",
    };
    console.log(values, "aajjaajjajaakakak");
    const userData = {
      userData: expert,
      params: params.token,
    };
    console.log(userData, "userData");
    await dispatch(signUpUser(userData)).unwrap();
  };
  const handleSubmitCommunity = async (values) => {
    const community = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      policy: values.policy,
      userName: values.userName,
      role: "community",
    };
    const userData = {
      userData: community,
      params: params.token,
    };
    await dispatch(signUpUser(userData)).unwrap();
  };
  const handleSubmitInvestor = async (values) => {
    const investor = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      policy: values.policy,
      userName: values.userName,
      role: "Investor",
    };
    const userData = {
      userData: investor,
      params: params.token,
    };
    await dispatch(signUpUser(userData)).unwrap();
  };
  return (
    <>
      <ToastContainer />
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        {loader ? (
          <Spinner my="auto" />
        ) : (
          <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "25px", md: "15vh" }}
            flexDirection="column"
          >
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <Box>
                <Flex
                  width="100%"
                  flexDirection="column"
                  justifyContent={"center"}
                  alignItems={{ base: "center", lg: "unset" }}
                  display={{ base: "flex", lg: "none" }}
                  mb="20px"
                >
                  <Image
                    src={logo}
                    alt="Logo"
                    maxW={{ base: "70%", md: "50%" }}
                  />
                </Flex>
                <Box me="auto" display={{ base: "none", lg: "flex" }}>
                  <Heading color={textColor} fontSize="30px">
                    Sign Up
                  </Heading>
                </Box>
                <Card>
                  {" "}
                  <Tabs>
                    <TabList>
                      <Tab>Community</Tab>
                      <Tab>Expert</Tab>
                      <Tab>Investor</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel pt="20px">
                        <div>
                          <Formik
                            validationSchema={schema}
                            initialValues={{
                              policy: false,
                              firstName: "",
                              lastName: "",
                              userName: "",
                              email: "",
                              password: "",
                              role: "community",
                            }}
                            onSubmit={(values) =>
                              handleSubmitCommunity(values, params)
                            }
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <Form>
                                <Flex>
                                  <Field name="firstName">
                                    {({ field }) => (
                                      <FormControl
                                        mr="5"
                                        isInvalid={
                                          errors.firstName && touched.firstName
                                        }
                                      >
                                        <FormLabel
                                          htmlFor="firstName"
                                          display="flex"
                                          ms="4px"
                                          fontSize="sm"
                                          fontWeight="500"
                                          color={textColor}
                                          mb="8px"
                                        >
                                          First name
                                          <Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                          fontSize="sm"
                                          id="firstName"
                                          isRequired={true}
                                          ms={{ base: "0px", md: "0px" }}
                                          type="text"
                                          mb={
                                            errors.firstName &&
                                            touched.firstName
                                              ? "0px"
                                              : "24px"
                                          }
                                          fontWeight="500"
                                          size="lg"
                                          {...field}
                                          placeholder="Enter your First Name"
                                        />
                                        <FormErrorMessage>
                                          {errors.firstName}
                                        </FormErrorMessage>
                                      </FormControl>
                                    )}
                                  </Field>

                                  <Field name="lastName">
                                    {({ field }) => (
                                      <FormControl
                                        isInvalid={
                                          errors.lastName && touched.lastName
                                        }
                                      >
                                        <FormLabel
                                          htmlFor="lastName"
                                          display="flex"
                                          ms="4px"
                                          fontSize="sm"
                                          fontWeight="500"
                                          color={textColor}
                                          mb="8px"
                                        >
                                          Last name
                                          <Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                          id="lastName"
                                          isRequired={true}
                                          fontSize="sm"
                                          ms={{ base: "0px", md: "0px" }}
                                          type="text"
                                          placeholder="Last name"
                                          mb={
                                            errors.lastName && touched.lastName
                                              ? "0px"
                                              : "24px"
                                          }
                                          fontWeight="500"
                                          size="lg"
                                          {...field}
                                        />
                                        <FormErrorMessage>
                                          {errors.lastName}
                                        </FormErrorMessage>
                                      </FormControl>
                                    )}
                                  </Field>
                                </Flex>
                                <Field name="userName">
                                  {({ field }) => (
                                    <FormControl isInvalid={errors.userName}>
                                      <FormLabel
                                        htmlFor="userName"
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                      >
                                        User name
                                      </FormLabel>
                                      <Input
                                        id="userName"
                                        isRequired={true}
                                        fontSize="sm"
                                        ms={{ base: "0px", md: "0px" }}
                                        type="text"
                                        placeholder="Your username"
                                        mb={errors.userName ? "0px" : "24px"}
                                        fontWeight="500"
                                        size="lg"
                                        {...field}
                                      />
                                      <FormErrorMessage>
                                        {errors.userName}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="email">
                                  {({ field }) => (
                                    <FormControl
                                      isInvalid={errors.email && touched.email}
                                    >
                                      <FormLabel
                                        htmlFor="email"
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                      >
                                        Email<Text color={brandStars}>*</Text>
                                      </FormLabel>
                                      <Input
                                        value={values.email}
                                        id="email"
                                        isRequired={true}
                                        fontSize="sm"
                                        ms={{ base: "0px", md: "0px" }}
                                        type="email"
                                        placeholder="your@email.di"
                                        mb={
                                          errors.email && touched.email
                                            ? "0px"
                                            : "24px"
                                        }
                                        fontWeight="500"
                                        size="lg"
                                        {...field}
                                      />
                                      <FormErrorMessage>
                                        {errors.email}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="password">
                                  {({ field }) => (
                                    <FormControl
                                      isInvalid={
                                        errors.password && touched.password
                                      }
                                    >
                                      <FormLabel
                                        htmlFor="password"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        display="flex"
                                      >
                                        Password
                                        <Text color={brandStars}>*</Text>
                                      </FormLabel>
                                      <InputGroup size="md">
                                        <Input
                                          id="password"
                                          isRequired={true}
                                          fontSize="sm"
                                          placeholder="Min. 8 characters"
                                          mb={
                                            errors.password && touched.password
                                              ? "0px"
                                              : "24px"
                                          }
                                          size="lg"
                                          type={show ? "text" : "password"}
                                          {...field}
                                        />
                                        <InputRightElement
                                          display="flex"
                                          alignItems="center"
                                          mt="4px"
                                        >
                                          <Icon
                                            color={textColorSecondary}
                                            _hover={{ cursor: "pointer" }}
                                            as={
                                              show
                                                ? MdOutlineRemoveRedEye
                                                : RiEyeCloseLine
                                            }
                                            onClick={handleClick}
                                          />
                                        </InputRightElement>
                                      </InputGroup>
                                      <FormErrorMessage>
                                        {errors.password}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Flex
                                  direction={"column"}
                                  justifyContent="space-between"
                                  mb="24px"
                                >
                                  <Flex>
                                    <Field
                                      mr="5"
                                      type="checkbox"
                                      name="policy"
                                    />
                                    <FormLabel
                                      ml="5"
                                      htmlFor="policy"
                                      mb="0"
                                      fontWeight="normal"
                                      color={textColor}
                                      fontSize="sm"
                                    >
                                      By creating an account means you agree to
                                      the Terms and Conditions, and our Privacy
                                      Policy
                                    </FormLabel>
                                  </Flex>
                                  {errors.policy && touched.policy && (
                                    <Text mt="3" color="red">
                                      {errors.policy}
                                    </Text>
                                  )}
                                </Flex>

                                <Button
                                  fontSize="sm"
                                  variant="brand"
                                  fontWeight="500"
                                  w="100%"
                                  h="50"
                                  mb="10px"
                                  type="submit"
                                >
                                  Sign up
                                </Button>
                                <Flex
                                  flexDirection="column"
                                  justifyContent="start"
                                  alignItems="start"
                                >
                                  <Text
                                    color={textColorDetails}
                                    fontWeight="400"
                                    fontSize="14px"
                                  >
                                    Already a member?
                                    <NavLink to="/auth/sign-in">
                                      <Text
                                        color={textColorBrand}
                                        as="span"
                                        ms="5px"
                                        fontWeight="500"
                                      >
                                        Sign in
                                      </Text>
                                    </NavLink>
                                  </Text>
                                </Flex>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </TabPanel>
                      <TabPanel pt="30px">
                        <div>
                          <Formik
                            validationSchema={schema}
                            initialValues={{
                              policy: false,
                              firstName: "",
                              lastName: "",
                              userName: "",
                              email: "",
                              password: "",
                              role: "Expert",
                            }}
                            onSubmit={(values) =>
                              handleSubmitExpert(values, params)
                            }
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <Form>
                                <Flex>
                                  <Field name="firstName">
                                    {({ field }) => (
                                      <FormControl
                                        mr="5"
                                        isInvalid={
                                          errors.firstName && touched.firstName
                                        }
                                      >
                                        <FormLabel
                                          htmlFor="firstName"
                                          display="flex"
                                          ms="4px"
                                          fontSize="sm"
                                          fontWeight="500"
                                          color={textColor}
                                          mb="8px"
                                        >
                                          First name
                                          <Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                          fontSize="sm"
                                          id="firstName"
                                          isRequired={true}
                                          ms={{ base: "0px", md: "0px" }}
                                          type="text"
                                          mb={
                                            errors.firstName &&
                                            touched.firstName
                                              ? "0px"
                                              : "24px"
                                          }
                                          fontWeight="500"
                                          size="lg"
                                          {...field}
                                          placeholder="Enter your First Name"
                                        />
                                        <FormErrorMessage>
                                          {errors.firstName}
                                        </FormErrorMessage>
                                      </FormControl>
                                    )}
                                  </Field>

                                  <Field name="lastName">
                                    {({ field }) => (
                                      <FormControl
                                        isInvalid={
                                          errors.lastName && touched.lastName
                                        }
                                      >
                                        <FormLabel
                                          htmlFor="lastName"
                                          display="flex"
                                          ms="4px"
                                          fontSize="sm"
                                          fontWeight="500"
                                          color={textColor}
                                          mb="8px"
                                        >
                                          Last name
                                          <Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                          id="lastName"
                                          isRequired={true}
                                          fontSize="sm"
                                          ms={{ base: "0px", md: "0px" }}
                                          type="text"
                                          placeholder="Last name"
                                          mb={
                                            errors.lastName && touched.lastName
                                              ? "0px"
                                              : "24px"
                                          }
                                          fontWeight="500"
                                          size="lg"
                                          {...field}
                                        />
                                        <FormErrorMessage>
                                          {errors.lastName}
                                        </FormErrorMessage>
                                      </FormControl>
                                    )}
                                  </Field>
                                </Flex>
                                <Field name="userName">
                                  {({ field }) => (
                                    <FormControl isInvalid={errors.userName}>
                                      <FormLabel
                                        htmlFor="userName"
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                      >
                                        User name
                                      </FormLabel>
                                      <Input
                                        id="userName"
                                        isRequired={true}
                                        fontSize="sm"
                                        ms={{ base: "0px", md: "0px" }}
                                        type="text"
                                        placeholder="Your username"
                                        mb={errors.userName ? "0px" : "24px"}
                                        fontWeight="500"
                                        size="lg"
                                        {...field}
                                      />
                                      <FormErrorMessage>
                                        {errors.userName}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="email">
                                  {({ field }) => (
                                    <FormControl
                                      isInvalid={errors.email && touched.email}
                                    >
                                      <FormLabel
                                        htmlFor="email"
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                      >
                                        Email<Text color={brandStars}>*</Text>
                                      </FormLabel>
                                      <Input
                                        value={values.email}
                                        id="email"
                                        isRequired={true}
                                        fontSize="sm"
                                        ms={{ base: "0px", md: "0px" }}
                                        type="email"
                                        placeholder="your@email.di"
                                        mb={
                                          errors.email && touched.email
                                            ? "0px"
                                            : "24px"
                                        }
                                        fontWeight="500"
                                        size="lg"
                                        {...field}
                                      />
                                      <FormErrorMessage>
                                        {errors.email}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="password">
                                  {({ field }) => (
                                    <FormControl
                                      isInvalid={
                                        errors.password && touched.password
                                      }
                                    >
                                      <FormLabel
                                        htmlFor="password"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        display="flex"
                                      >
                                        Password
                                        <Text color={brandStars}>*</Text>
                                      </FormLabel>
                                      <InputGroup size="md">
                                        <Input
                                          id="password"
                                          isRequired={true}
                                          fontSize="sm"
                                          placeholder="Min. 8 characters"
                                          mb={
                                            errors.password && touched.password
                                              ? "0px"
                                              : "24px"
                                          }
                                          size="lg"
                                          type={show ? "text" : "password"}
                                          {...field}
                                        />
                                        <InputRightElement
                                          display="flex"
                                          alignItems="center"
                                          mt="4px"
                                        >
                                          <Icon
                                            color={textColorSecondary}
                                            _hover={{ cursor: "pointer" }}
                                            as={
                                              show
                                                ? MdOutlineRemoveRedEye
                                                : RiEyeCloseLine
                                            }
                                            onClick={handleClick}
                                          />
                                        </InputRightElement>
                                      </InputGroup>
                                      <FormErrorMessage>
                                        {errors.password}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Flex
                                  direction={"column"}
                                  justifyContent="space-between"
                                  mb="24px"
                                >
                                  <Flex>
                                    <Field
                                      mr="5"
                                      type="checkbox"
                                      name="policy"
                                    />
                                    <FormLabel
                                      ml="5"
                                      htmlFor="policy"
                                      mb="0"
                                      fontWeight="normal"
                                      color={textColor}
                                      fontSize="sm"
                                    >
                                      By creating an account means you agree to
                                      the Terms and Conditions, and our Privacy
                                      Policy
                                    </FormLabel>
                                  </Flex>
                                  {errors.policy && touched.policy && (
                                    <Text mt="3" color="red">
                                      {errors.policy}
                                    </Text>
                                  )}
                                </Flex>

                                <Button
                                  fontSize="sm"
                                  variant="brand"
                                  fontWeight="500"
                                  w="100%"
                                  h="50"
                                  mb="10px"
                                  type="submit"
                                >
                                  Sign up
                                </Button>
                                <Flex
                                  flexDirection="column"
                                  justifyContent="start"
                                  alignItems="start"
                                >
                                  <Text
                                    color={textColorDetails}
                                    fontWeight="400"
                                    fontSize="14px"
                                  >
                                    Already a member?
                                    <NavLink to="/auth/sign-in">
                                      <Text
                                        color={textColorBrand}
                                        as="span"
                                        ms="5px"
                                        fontWeight="500"
                                      >
                                        Sign in
                                      </Text>
                                    </NavLink>
                                  </Text>
                                </Flex>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </TabPanel>
                      <TabPanel pt="30px">
                        <div>
                          <Formik
                            validationSchema={schema}
                            initialValues={{
                              policy: false,
                              firstName: "",
                              lastName: "",
                              userName: "",
                              email: "",
                              password: "",
                              role: "Investor",
                            }}
                            onSubmit={(values) =>
                              handleSubmitInvestor(values, params)
                            }
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <Form>
                                <Flex>
                                  <Field name="firstName">
                                    {({ field }) => (
                                      <FormControl
                                        mr="5"
                                        isInvalid={
                                          errors.firstName && touched.firstName
                                        }
                                      >
                                        <FormLabel
                                          htmlFor="firstName"
                                          display="flex"
                                          ms="4px"
                                          fontSize="sm"
                                          fontWeight="500"
                                          color={textColor}
                                          mb="8px"
                                        >
                                          First name
                                          <Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                          fontSize="sm"
                                          id="firstName"
                                          isRequired={true}
                                          ms={{ base: "0px", md: "0px" }}
                                          type="text"
                                          mb={
                                            errors.firstName &&
                                            touched.firstName
                                              ? "0px"
                                              : "24px"
                                          }
                                          fontWeight="500"
                                          size="lg"
                                          {...field}
                                          placeholder="Enter your First Name"
                                        />
                                        <FormErrorMessage>
                                          {errors.firstName}
                                        </FormErrorMessage>
                                      </FormControl>
                                    )}
                                  </Field>

                                  <Field name="lastName">
                                    {({ field }) => (
                                      <FormControl
                                        isInvalid={
                                          errors.lastName && touched.lastName
                                        }
                                      >
                                        <FormLabel
                                          htmlFor="lastName"
                                          display="flex"
                                          ms="4px"
                                          fontSize="sm"
                                          fontWeight="500"
                                          color={textColor}
                                          mb="8px"
                                        >
                                          Last name
                                          <Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                          id="lastName"
                                          isRequired={true}
                                          fontSize="sm"
                                          ms={{ base: "0px", md: "0px" }}
                                          type="text"
                                          placeholder="Last name"
                                          mb={
                                            errors.lastName && touched.lastName
                                              ? "0px"
                                              : "24px"
                                          }
                                          fontWeight="500"
                                          size="lg"
                                          {...field}
                                        />
                                        <FormErrorMessage>
                                          {errors.lastName}
                                        </FormErrorMessage>
                                      </FormControl>
                                    )}
                                  </Field>
                                </Flex>
                                <Field name="userName">
                                  {({ field }) => (
                                    <FormControl isInvalid={errors.userName}>
                                      <FormLabel
                                        htmlFor="userName"
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                      >
                                        User name
                                      </FormLabel>
                                      <Input
                                        id="userName"
                                        isRequired={true}
                                        fontSize="sm"
                                        ms={{ base: "0px", md: "0px" }}
                                        type="text"
                                        placeholder="Your username"
                                        mb={errors.userName ? "0px" : "24px"}
                                        fontWeight="500"
                                        size="lg"
                                        {...field}
                                      />
                                      <FormErrorMessage>
                                        {errors.userName}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="email">
                                  {({ field }) => (
                                    <FormControl
                                      isInvalid={errors.email && touched.email}
                                    >
                                      <FormLabel
                                        htmlFor="email"
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                      >
                                        Email<Text color={brandStars}>*</Text>
                                      </FormLabel>
                                      <Input
                                        value={values.email}
                                        id="email"
                                        isRequired={true}
                                        fontSize="sm"
                                        ms={{ base: "0px", md: "0px" }}
                                        type="email"
                                        placeholder="your@email.di"
                                        mb={
                                          errors.email && touched.email
                                            ? "0px"
                                            : "24px"
                                        }
                                        fontWeight="500"
                                        size="lg"
                                        {...field}
                                      />
                                      <FormErrorMessage>
                                        {errors.email}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                                <Field name="email">
                                  {({ field }) => (
                                    <FormControl>
                                      <FormLabel
                                        htmlFor="email"
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                      ></FormLabel>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="password">
                                  {({ field }) => (
                                    <FormControl
                                      isInvalid={
                                        errors.password && touched.password
                                      }
                                    >
                                      <FormLabel
                                        htmlFor="password"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        display="flex"
                                      >
                                        Password
                                        <Text color={brandStars}>*</Text>
                                      </FormLabel>
                                      <InputGroup size="md">
                                        <Input
                                          id="password"
                                          isRequired={true}
                                          fontSize="sm"
                                          placeholder="Min. 8 characters"
                                          mb={
                                            errors.password && touched.password
                                              ? "0px"
                                              : "24px"
                                          }
                                          size="lg"
                                          type={show ? "text" : "password"}
                                          {...field}
                                        />
                                        <InputRightElement
                                          display="flex"
                                          alignItems="center"
                                          mt="4px"
                                        >
                                          <Icon
                                            color={textColorSecondary}
                                            _hover={{ cursor: "pointer" }}
                                            as={
                                              show
                                                ? MdOutlineRemoveRedEye
                                                : RiEyeCloseLine
                                            }
                                            onClick={handleClick}
                                          />
                                        </InputRightElement>
                                      </InputGroup>
                                      <FormErrorMessage>
                                        {errors.password}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Flex
                                  direction={"column"}
                                  justifyContent="space-between"
                                  mb="24px"
                                >
                                  <Flex>
                                    <Field
                                      mr="5"
                                      type="checkbox"
                                      name="policy"
                                    />
                                    <FormLabel
                                      ml="5"
                                      htmlFor="policy"
                                      mb="0"
                                      fontWeight="normal"
                                      color={textColor}
                                      fontSize="sm"
                                    >
                                      By creating an account means you agree to
                                      the Terms and Conditions, and our Privacy
                                      Policy
                                    </FormLabel>
                                  </Flex>
                                  {errors.policy && touched.policy && (
                                    <Text mt="3" color="red">
                                      {errors.policy}
                                    </Text>
                                  )}
                                </Flex>

                                <Button
                                  fontSize="sm"
                                  variant="brand"
                                  fontWeight="500"
                                  w="100%"
                                  h="50"
                                  mb="10px"
                                  type="submit"
                                >
                                  Sign up
                                </Button>
                                <Flex
                                  flexDirection="column"
                                  justifyContent="start"
                                  alignItems="start"
                                >
                                  <Text
                                    color={textColorDetails}
                                    fontWeight="400"
                                    fontSize="14px"
                                  >
                                    Already a member?
                                    <NavLink to="/auth/sign-in">
                                      <Text
                                        color={textColorBrand}
                                        as="span"
                                        ms="5px"
                                        fontWeight="500"
                                      >
                                        Sign in
                                      </Text>
                                    </NavLink>
                                  </Text>
                                </Flex>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Card>
              </Box>
            </Flex>
          </Flex>
        )}
      </DefaultAuth>
    </>
  );
}

export default SignUp;
