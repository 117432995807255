import React from "react";
import Card from "components/card/Card.js";
import { Text, Box, Flex, Checkbox, Avatar } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import config from "config/axios/config";

export default function ProjectItem({
  project,
  selectedProjects,
  toggleProject,
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const isChecked = selectedProjects.includes(project._id);

  return (
    <Card p="1" mb="2" boxShadow={cardShadow}>
      <Flex align="center" justifyContent="space-between">
        <Flex
          align="center"
          padding="0.6rem"
          fontWeight="bold"
          color={textColor}
          fontSize="md"
        >
          <Avatar
            w="50px"
            h="50px"
            src={config.apiUrl + "/api/getfile/" + project.images}
          />
          <Box ml="3">
            <Text fontWeight="normal">{project.title}</Text>
            <Text fontSize="sm">{project.category}</Text>
          </Box>
        </Flex>
        <Checkbox
          colorScheme="brandScheme"
          borderColor={"#3965ff"}
          onChange={() => toggleProject(project._id)}
          isChecked={isChecked}
        />
      </Flex>
    </Card>
  );
}
