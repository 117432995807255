import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import {
  Box,
  Flex,
  SimpleGrid,
  Stack,
  HStack,
  Tag,
  TagLabel,
  Heading,
  Text,
  useColorModeValue,
  Icon,
  Badge,
  Spinner,
  Button,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Banner from "views/admin/profile/components/Banner";
import banner from "assets/img/auth/banner.png";
import { EditIcon } from "@chakra-ui/icons";
import EducationItem from "./profileSettings/EducationItem";
import WorkHistoryItem from "./profileSettings/WorkHistoryItem";
import MyProjects from "./components/MyProjects";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "reducers/userInformation";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { MdOutlineShoppingCart } from "react-icons/md";
import { MdOutlineHourglassBottom } from "react-icons/md";
import { MdOutlineHourglassEmpty } from "react-icons/md";
import MeetingsHistoric from "./components/MeetingsHistoric";
import HistoricRequestInvestment from "./components/HistoricRequestInvestment";
import config from "config/axios/config";
import BasicButton from "components/button/BasicButton";
import { Chat } from "components/chat";

export default function Overview(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isCurrentUser, setIsCurrentUser] = useState(true);
  const [education, setEducation] = useState([]);
  const [workHistory, setWorkHistory] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [userType, setUserType] = useState("");
  const [hourlyPrice, setHourlyPrice] = useState();
  const [canBook, setCanBook] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const user = useSelector((state) =>
    state.UserInformation.user.data
      ? state.UserInformation.user.data.message
      : null
  );
  const status = useSelector((state) => state.UserInformation.user.status);
  const haveMeet = useSelector((state) =>
    state.UserInformation.user.data ? state.UserInformation.user.data : null
  );
  const profilePercentage = useSelector((state) =>
    state.UserInformation.user.data
      ? state.UserInformation.user.data.percentage
      : null
  );
  const staticProfile = useSelector((state) =>
    state.UserInformation.user.data ? state.UserInformation.user.data : null
  );
  const meetings = useSelector((state) =>
    state.UserInformation.user.data
      ? state.UserInformation.user.data.MeetingHistory
      : null
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  useEffect(() => {
    if (id) {
      setIsCurrentUser(false);
    }
  }, [id]);

  useEffect(() => {
    const userType = jwt_decode(localStorage.getItem("token")).userType;
    if (
      id &&
      userType === "ProjectHolder" &&
      user &&
      user.userType === "Expert"
    ) {
      setCanBook(true);
    } else {
      setCanBook(false);
    }
  }, [id, user]);

  const getUserByIdAction = async () => {
    if (id) {
      await dispatch(getUserById(id)).unwrap();
    } else {
      const x = jwt_decode(localStorage.getItem("token"))._id;
      await dispatch(getUserById(x)).unwrap();
    }
  };
  
  useEffect(() => {
    getUserByIdAction();
  }, []);

  useEffect(() => {
    if (user && user.resume && user.resume[0]) {
      setEducation(user.resume[0].Education);
      setWorkHistory(user.resume[0].workHistory);
      setLanguages(user.resume[0].Language);
    }
    if (user) {
      setUserType(user.userType);
      setHourlyPrice(user.hourPrice);
    }
  }, [user]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {!user && status === "loading" && (
        <Flex flexDirection="column" h="100vh">
          <Spinner my="auto!important" mx="auto" />
        </Flex>
      )}
      {!user && status === "failed" && (
        <Flex flexDirection="column" h="100vh">
          <Card mt="3" mb="3">
            <Text fontSize={"2xl"} textAlign={"center"}>
              User does not exist
            </Text>
            <Text fontSize="sm" textAlign={"center"}>
              User has been deleted
            </Text>
          </Card>
        </Flex>
      )}

      {user && !sendMessage && (
        <>
          <Flex
            templateColumns={{
              base: "2fr",
              lg: "1.34fr 1fr 1.62fr",
            }}
            templateRows={{
              base: "repeat(3, 1fr)",
              lg: "1fr",
            }}
            gap={{ base: "20px", xl: "20px" }}
          >
            <Banner
              haveMeet={haveMeet}
              banner={banner}
              user={user}
              getUser={getUserByIdAction}
              icon={isCurrentUser ? EditIcon : null}
              canBook={canBook}
              profilePercentage={profilePercentage}
              showSendMessage={!isCurrentUser}
              sendMessageHandler={() => setSendMessage(true)}
            />
          </Flex>
          {staticProfile && userType === "Expert" && (
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
              gap="20px"
              mb="20px"
              mt="2"
            >
              {staticProfile.numberOfMeet !== undefined && (
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlineHourglassEmpty}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Meeting hours"
                  value={staticProfile.numberOfMeet}
                />
              )}

              {staticProfile.totalprojectassisted !== undefined && (
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlineShoppingCart}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Number of assisted project"
                  value={staticProfile.totalprojectassisted}
                />
              )}

              {staticProfile.totalDurationVariable !== undefined && (
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlineHourglassBottom}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Total Duration per hours"
                  value={staticProfile.totalDurationVariable}
                />
              )}
            </SimpleGrid>
          )}
          {user.bio && (
            <Stack
              padding="8"
              display={"flex"}
              flexDirection={"column"}
              borderRadius={"30px"}
              backgroundColor={"white"}
              mt="3"
            >
              <Heading size="md" mb="2">
                Bio
              </Heading>
              <Text>{user.bio}</Text>
            </Stack>
          )}
          {user.sector && user.sector.length > 0 && (
            <Stack
              padding="8"
              display={"flex"}
              flexDirection={"column"}
              borderRadius={"30px"}
              backgroundColor={"white"}
              mt="3"
            >
              <Heading size="md" mb="2">
                Secteurs d'activités
              </Heading>
              <Flex>
                <Text>
                  {user.sector.map((sec, i) => (
                    <Badge
                      colorScheme="purple"
                      variant="outline"
                      p="2px 0px"
                      mr="1"
                    >
                      <Text
                        fontSize="10px"
                        width="100%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        #{sec}
                      </Text>
                    </Badge>
                  ))}
                </Text>
              </Flex>
            </Stack>
          )}
          {(userType === "Expert" ||
            education.length > 0 ||
            workHistory.length > 0 ||
            languages.length > 0) && (
            <SimpleGrid
              columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }}
              gap="20px"
              mb="20px"
              mt="20px"
            >
              {education.length > 0 && (
                <Stack
                  padding="8"
                  display={"flex"}
                  flexDirection={"column"}
                  borderRadius={"30px"}
                  backgroundColor={"white"}
                >
                  <Heading size="md" mb="2">
                    Education
                  </Heading>
                  {education.map((item, index) => (
                    <EducationItem item={item} index={index} canEdit={false} />
                  ))}
                </Stack>
              )}
              {workHistory.length > 0 && (
                <Stack
                  padding="8"
                  display={"flex"}
                  flexDirection={"column"}
                  borderRadius={"30px"}
                  backgroundColor={"white"}
                >
                  <Heading size="md" mb="2">
                    Work History
                  </Heading>
                  {workHistory.map((item, index) => (
                    <WorkHistoryItem
                      item={item}
                      index={index}
                      canEdit={false}
                    />
                  ))}
                </Stack>
              )}
              {languages.length > 0 && (
                <Stack
                  padding="8"
                  display={"flex"}
                  flexDirection={"column"}
                  borderRadius={"30px"}
                  backgroundColor={"white"}
                >
                  <Heading size="md" mb="2">
                    Languages
                  </Heading>
                  <HStack spacing={2} mb="20px">
                    {languages.map((language, index) => (
                      <Tag
                        size={"md"}
                        key={index}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="blue"
                      >
                        <TagLabel>{language}</TagLabel>
                      </Tag>
                    ))}
                  </HStack>
                </Stack>
              )}
              {userType === "Expert" && hourlyPrice !== undefined && (
                <Stack
                  padding="8"
                  display={"flex"}
                  flexDirection={"column"}
                  borderRadius={"30px"}
                  backgroundColor={"white"}
                >
                  <Heading size="md" mb="2">
                    Hourly Price
                  </Heading>
                  <Text mt="3">
                    <b>
                      {hourlyPrice !== undefined
                        ? hourlyPrice + " HBAR"
                        : "No Price"}
                    </b>
                  </Text>
                </Stack>
              )}
            </SimpleGrid>
          )}
          <MyProjects userId={user._id} />
          {userType === "ProjectHolder" && (
            <HistoricRequestInvestment userId={user._id} />
          )}
          <MeetingsHistoric meetings={meetings} />
        </>
      )}
      {user && sendMessage && (
        <Chat
          sender={currentUser}
          receiver={user}
          backHandler={() => setSendMessage(false)}
        />
      )}
    </Box>
  );
}
