import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import DeletedItemCard from "components/card/DeletedItemCard";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { programPhaseDetails } from "reducers/programPhase";
import GeneralInformations from "./components/programPhaseDetails/GeneralInformations";
import SelectionCriteria from "./components/programPhaseDetails/SelectionCriteria";
import WhatToSubmit from "./components/programPhaseDetails/WhatToSubmit";
import ProgramPhaseProjects from "./components/programPhaseDetails/ProgramPhaseProjects";
import Voters from "./components/programPhaseDetails/Voters";

export default function ProgramPhaseDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [reload, setReload] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const programPhase = useSelector(
    (state) => state.ProgramPhase.programPhaseDetails.data
  );
  const status = useSelector(
    (state) => state.ProgramPhase.programPhaseDetails.status
  );
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );
  const programPhaseDetailsHandler = async () => {
    await dispatch(programPhaseDetails(id)).unwrap();
  };
  useEffect(() => {
    programPhaseDetailsHandler();
    setReload(false);
  }, [id, reload]);
  useEffect(() => {
    if (currentUser && programPhase && programPhase.createdBy) {
      setIsOwner(currentUser._id == programPhase.createdBy._id);
    }
  }, [currentUser, programPhase]);
  return (
    <Flex
      flexDirection={"column"}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <ToastContainer />
      {!programPhase && status === "loading" && <Loader />}
      {!programPhase && status === "failed" && (
        <DeletedItemCard item={"Phase"} />
      )}
      {programPhase && (
        <Tabs isManual variant="enclosed">
          <TabList>
            <Tab _selected={{ bg: "white" }}>Overview</Tab>
            <Tab _selected={{ bg: "white" }}>Selection Criteria </Tab>
            <Tab _selected={{ bg: "white" }}>What To Submit</Tab>
            <Tab _selected={{ bg: "white" }}>Projects</Tab>
            <Tab _selected={{ bg: "white" }}>Voters</Tab>
          </TabList>
          <TabPanels backgroundColor={"white"} pr="2" pl="2">
            <TabPanel>
              <GeneralInformations
                onReload={() => setReload(true)}
                isOwner={isOwner}
              />
            </TabPanel>
            <TabPanel>
              <SelectionCriteria
                onReload={() => setReload(true)}
                isOwner={isOwner}
              />
            </TabPanel>
            <TabPanel>
              <WhatToSubmit />
            </TabPanel>
            <TabPanel>
              <ProgramPhaseProjects
                programPhaseDetailsHandler={programPhaseDetailsHandler}
              />
            </TabPanel>
            <TabPanel>
              <Voters id={id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Flex>
  );
}
