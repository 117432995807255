import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
} from "@chakra-ui/react";
import BasicButton from "components/button/BasicButton";
import { useHistory } from "react-router-dom";

const AccountRequiredModal = (props) => {
  const { message, isOpen, onClose } = props;
  const history = useHistory();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">
          Create an Account in Ecosystem DAO{" "}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction={"column"} fontSize="sm">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </Flex>
        </ModalBody>
        <ModalFooter display={"flex"} justifyContent={"flex-end"}>
          <BasicButton
            variant="solid"
            colorScheme="blue"
            title="Create an account"
            onClick={() => history.push("/auth/sign-up")}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AccountRequiredModal;
