import React from "react";
import { Flex, Text, Textarea, Input } from "@chakra-ui/react";
import SwitchField from "components/fields/SwitchField";

const AddWorkshopForm = (props) => {
  const { workshopData, setWorkshopData, workshopErrors } = props;

  const { titleError, startError, endError, locationError, onlineUrlError } =
    workshopErrors;

  return (
    <Flex flexDirection={"column"}>
      <Text fontSize="sm" color="gray.700" mb="2" mt="2">
        Title
      </Text>
      <Input
        isInvalid={titleError}
        value={workshopData.title}
        onChange={(e) => {
          setWorkshopData({ ...workshopData, title: e.target.value });
        }}
        placeholder="Title"
        size="sm"
      />
      {titleError && <Text color="red">{titleError}</Text>}

      <Text fontSize="sm" color="gray.700" mb="2" mt="2">
        Description
      </Text>
      <Textarea
        onChange={(e) => {
          setWorkshopData({
            ...workshopData,
            description: e.target.value,
          });
        }}
        value={workshopData.description}
        size="sm"
        placeholder="Leave a description of the workshop"
      />
      <Text fontSize="sm" color="gray.700" mb="2" mt="2">
        Start Date
      </Text>
      <Input
        isInvalid={startError}
        value={workshopData.start}
        placeholder="Start Date"
        type="datetime-local"
        onChange={(e) => {
          setWorkshopData({
            ...workshopData,
            start: e.target.value,
          });
        }}
        size="sm"
      />
      {startError && <Text color="red">{startError}</Text>}
      <Text fontSize="sm" color="gray.700" mb="2" mt="2">
        End Date
      </Text>
      <Input
        isInvalid={endError}
        value={workshopData.end}
        placeholder="End Date"
        size="sm"
        type="datetime-local"
        onChange={(e) =>
          setWorkshopData({
            ...workshopData,
            end: e.target.value,
          })
        }
      />
      {endError && <Text color="red">{endError}</Text>}
      <Flex mt="2" direction={"column"}>
        <SwitchField
          reversed={true}
          fontSize="sm"
          id="6"
          label="Online"
          isChecked={workshopData.online}
          onChange={() => {
            setWorkshopData({
              ...workshopData,
              online: workshopData.online ? false : true,
            });
          }}
        />
      </Flex>
      <Flex mt="2" direction={"column"}>
        {!workshopData.online && (
          <>
            <Input
              isInvalid={locationError}
              type="text"
              placeholder="Location"
              id="emplacement"
              name="emplacement"
              value={workshopData.location}
              onChange={(e) =>
                setWorkshopData({
                  ...workshopData,
                  location: e.target.value,
                })
              }
              size="sm"
            />
            {locationError && (
              <Text mt="3" color="red">
                {locationError}
              </Text>
            )}
          </>
        )}
        {workshopData.online && (
          <>
            <Input
              isInvalid={onlineUrlError}
              type="text"
              placeholder="Link"
              id="link"
              name="link"
              value={workshopData.onlineUrl}
              onChange={(e) =>
                setWorkshopData({
                  ...workshopData,
                  onlineUrl: e.target.value,
                })
              }
              size="sm"
            />
            {onlineUrlError && (
              <Text mt="3" color="red">
                {onlineUrlError}
              </Text>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default AddWorkshopForm;
