import React from "react";
import { Text, Button, Icon } from "@chakra-ui/react";

export default function BasicButton(props) {
  const {
    title,
    colorScheme = "blue",
    onClick,
    style = null,
    cssClass = "",
    variant = "outline", //[outline,ghost,link,unstyled,solid],
    size = "sm", // {lg,md,xs,sm}
    disabled = false,
    mt = "unset",
    iconColor,
    icon = null,
    color,
    bg,
    hover,
    borderRadius
  } = props;
  return (
    <Button
      my="auto"
      mt={mt}
      onClick={onClick}
      style={style}
      className={cssClass}
      variant={variant}
      size={size}
      colorScheme={colorScheme}
      disabled={disabled}
      bg={bg}
      color={color}
      _hover={hover}
      borderRadius={borderRadius}
    >
      {icon && <Icon as={icon} w={4} h={4} color={iconColor} mr="2"/>}
      <Text>{title}</Text>
    </Button>
  );
}
