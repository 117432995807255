import React, { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Flex,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { whatToSubmitOptions } from "utils/constants";
import MySelect from "react-select";
import BasicButton from "components/button/BasicButton";
import { useDispatch } from "react-redux";
import { createProgramPhase } from "reducers/programPhase";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const WhatToSubmit = (props) => {
  const { programPhaseData, setProgramPhaseData, activeStep, setActiveStep } =
    props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedWhatToSubmit, setSelectedWhatToSubmit] = useState([]);
  const [descriptions, setDescriptions] = useState({});
  const [error, setError] = useState("");
  const handleDescriptionChange = (option, value) => {
    setDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [option]: value,
    }));
  };

  const back = () => {
    setActiveStep(activeStep - 1);
  };
  const confirm = async () => {
    if (!selectedWhatToSubmit.length) {
      setError("You should select what to submit!");
      return;
    }
    const whatToSubmit = selectedWhatToSubmit.map((item) => ({
      title: item.value,
      description: descriptions[item.value] || "",
    }));
    const data = { ...programPhaseData, whatToSubmit: whatToSubmit };
    setProgramPhaseData(data);
    await dispatch(createProgramPhase(data))
      .unwrap()
      .then((response) => {
        if (response.programPhase) {
          toast.success("Call for voting was added successfully", {
            position: "top-right",
            hideProgressBar: true,
            theme: "light",
          });
          history.push("/admin/program-phases/" + response.programPhase._id);
        }
      });
  };
  return (
    <Box p={6} borderWidth="1px" borderRadius="lg" width="100%" boxShadow="md">
      <ToastContainer />
      <Heading as="h2" size="lg" mb={4} textAlign="center">
        What To Submit
      </Heading>

      <FormControl>
        <FormLabel>Select Options:</FormLabel>
        <MySelect
          className="InputGroupy"
          placeholder="What to Submit"
          options={whatToSubmitOptions}
          value={selectedWhatToSubmit}
          onChange={setSelectedWhatToSubmit}
          isMulti
        />
      </FormControl>

      <Stack mt={4} spacing={4}>
        {selectedWhatToSubmit.map((option) => (
          <FormControl key={option.value}>
            <FormLabel>{`${option.label} Description:`}</FormLabel>
            <Input
              type="text"
              placeholder={`Enter ${option.label} description `}
              value={descriptions[option.value] || ""}
              onChange={(e) =>
                handleDescriptionChange(option.value, e.target.value)
              }
            />
          </FormControl>
        ))}
      </Stack>
      {error && (
        <Alert status="error" mb={4} borderRadius="md">
          <AlertIcon />
          {error}
        </Alert>
      )}
      <Flex mt="4" justifyContent={"space-between"}>
        <BasicButton
          onClick={back}
          title={"Previous"}
          variant="outline"
          size="md"
        />
        <BasicButton
          onClick={confirm}
          title={"Confirm"}
          variant="solid"
          size="md"
        />
      </Flex>
    </Box>
  );
};

export default WhatToSubmit;
