import React from "react";
import Card from "components/card/Card.js";
import jwt_decode from "jwt-decode";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Grid,
  GridItem,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { Link as Linkk } from "react-router-dom";
import logo from "assets/img/download.jpg";
import CourseItem from "./components/CourseItem";
import { getallCourses } from "reducers/academy";

export default function Academy() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [userType, setUserType] = useState("");
  const dispatch = useDispatch();
  const CoursesData = useSelector((state) => state.Academy.getcourse.data.result);
  const getCourses = async () => {
    await dispatch(getallCourses()).unwrap();
  };
  useEffect(() => {
    const USER_TYPE = jwt_decode(localStorage.getItem("token")).userType;
    setUserType(USER_TYPE);
    getCourses()
  }, []);
  useEffect(() => {
    console.log(CoursesData,'CoursesData')
  }, [CoursesData]);
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <ToastContainer />

      <Flex
        w="100%"
        mb="20px"
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }}
      >
        <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
          List of Courses
        </Text>
        {/* {(userType === "Admin" || userType === "SuperAdmin") && (
        <BasicButton onClick={onOpen} title={"add New"}></BasicButton>
      )} */}
      </Flex>
      <div>
        {/* {statusNews && statusNews === "loading" && (
          <Center>
            <Spinner />
          </Center>
        )} */}
        <Grid>
          <GridItem>
            {/* List of News */}
            <SimpleGrid
              columns={{ base: 1, lg: 2, xl: 3, md: 2 }}
              gap="20px"
              mb="20px"
              mt="20px"
            >
              {" "}
              {CoursesData &&
                    CoursesData.courses.length > 0 &&
                    CoursesData.courses.map((course, index) => {
                      return (
                        <CourseItem CoursesData={CoursesData} course={course}></CourseItem>

                      );
                    })}
            </SimpleGrid>
          </GridItem>
        </Grid>
        {/* {allNewsData && allNewsData.length === 0 && (
          <>
            <TextCard text="There are no news to show" />
            {userType === "Admin" ||
              (userType === "SuperAdmin" && (
                <>
                  <TextCard text="You can add some news using the 'Add News' button" />
                  <TextCard
                    text=" Come back soon to checkout any news and new information
                    that has been added"
                  />
                </>
              ))}
          </>
        )} */}
      </div>
    </Box>
    // <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    //   <Card p="20px">
    //     <Box h="100vh">
    //       <Center h="100%">
    //         <Box textAlign="center">
    //           <Text fontSize="3xl" fontWeight="bold" mb={6}>
    //           Dar Blockchain Academy!
    //           </Text>
    //           <Text fontSize="lg">
    //             Dar Blockchain Academy operates as an innovative platform
    //             designed to facilitate comprehensive learning experiences in the
    //             realm of blockchain technology. Through our dynamic curriculum
    //             and expert-led courses, we aim to equip individuals with the
    //             necessary knowledge and skills to navigate the intricacies of
    //             blockchain technology effectively. Our academy fosters an
    //             interactive learning environment, integrating practical
    //             applications and theoretical knowledge to cultivate a deep
    //             understanding of the blockchain ecosystem. <br />
    //             Moreover, to streamline your learning journey, user accounts are
    //             automatically generated upon joining the Ecosysteme DAO. Your
    //             unique username and password serve as your gateway to our
    //             immersive learning resources, ensuring seamless access to our
    //             diverse range of educational materials, tutorials, and
    //             real-world case studies. As you embark on this educational
    //             venture, we remain dedicated to supporting your growth and
    //             exploration within the dynamic landscape of blockchain
    //             technology.{" "}
    //           </Text>
    //           <Flex w="100%" justifyContent="center" mt={'15px'}>
    //           <Button ml={"24px"} bgColor={"#7551FF"} color="white" my="auto">
    //             {/* <Linkk to={"https://academy.darblockchain.io/login/index.php"}> */}
    //               <Text><a href="https://academy.darblockchain.io/login/index.php" target="_blank">Dar Blockchain Academy</a></Text>
    //             {/* </Linkk> */}
    //           </Button>
    //         </Flex>
    //         </Box>
    //       </Center>
    //     </Box>
    //   </Card>
    // </Box>
  );
}
