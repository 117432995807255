import React from "react";
import Card from "components/card/Card.js";
import {
  Text,
  useColorModeValue,
  Divider,
  Flex,
  ButtonGroup,
  Button,
  Image,
  useDisclosure,
  Box,
  Badge,
} from "@chakra-ui/react";
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link as Linkk } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import placeholder from "assets/img/placeholder.jpg";
import config from "config/axios/config";
import ApplyAssistingProgramsModal from "./ApplyAssistingProgramsModal";
import { deleteAssistingProgram } from "reducers/assistingProgram";
import { calculateRemainingPeriod } from "utils/functions";
import DeleteConfirmationModal from "components/modals/DeleteConfirmationModal";

export default function AssistingProgramsItem(props) {
  const { item, onRelaod } = props;

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const dispatch = useDispatch();
  const [userType, setUsetType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [remainingPeriod, setRemainingPeriod] = useState(
    calculateRemainingPeriod(item.endDateSubmission)
  );
  const {
    isOpen: isOpenDM,
    onOpen: onOpenDM,
    onClose: onCloseDM,
  } = useDisclosure();

  const deleteAssistingProgramAction = async () => {
    await dispatch(deleteAssistingProgram(item._id)).unwrap();
    toast.success("Assisting program was deleted successfully!", {
      position: "top-right",
      hideProgressBar: true,
      theme: "light",
    });
    onRelaod();
  };

  useEffect(() => {
    const USER_TYPE = jwt_decode(localStorage.getItem("token")).userType;
    setUsetType(USER_TYPE);
  }, []);

  return (
    <>
      <Card boxShadow={cardShadow}>
        <ToastContainer />

        {isOpen && (
          <ApplyAssistingProgramsModal
            assistingProgram={item}
            isOpen={isOpen}
            onClose={onClose}
            onReload={onRelaod}
          />
        )}
        <Flex direction="column" justifyContent="space-between" height="100%">
          <Flex direction="column">
            <Flex direction="column">
              <Box>
                <Image
                  src={config.apiUrl + `/assisting-programs/file/` + item.image}
                  mb="5px"
                  boxSize="150px"
                  width="100%"
                  objectFit="cover"
                  alt=""
                  borderRadius="lg"
                  fallbackSrc={placeholder}
                />
              </Box>
              <Flex mt="2px" justify="space-between" alignItems={"center"}>
                <Text
                  color={textColor}
                  mr="4px"
                  fontSize={{
                    base: "xl",
                    md: "lg",
                    lg: "lg",
                    xl: "lg",
                    "2xl": "md",
                    "3xl": "lg",
                  }}
                  mb="5px"
                  fontWeight="bold"
                  me="14px"
                >
                  {item.title}
                </Text>
                <Badge
                  ml="1"
                  variant="solid"
                  lineHeight={"1"}
                  fontSize="xs"
                  p={1}
                  borderRadius={"1000px"}
                  bgColor={remainingPeriod ? "teal" : "red"}
                >
                  {remainingPeriod
                    ? "About " +
                      remainingPeriod.value +
                      " " +
                      remainingPeriod.unit +
                      " left"
                    : "Expired Deadline"}
                </Badge>
              </Flex>
              <Text
                className="description"
                color={textColor}
                mb="5px"
                me="14px"
              >
                {item.description}{" "}
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column">
            <Divider mt="2" mb="2" />
            <Flex color={textColor} direction="row" alignItems={"center"}>
              <BiTimeFive w="20px" h="20px" color="#3b82f6" />
              <Text color="#3b82f6" fontWeight={"700"} ml="2">
                {moment(item.start).format("ll")} -{" "}
                {moment(item.end).format("ll")}
              </Text>
            </Flex>
            <Divider mt="2" mb="2" />
            <ButtonGroup spacing="2" display={"flex"} justifyContent="flex-end">
              <Button variant="solid" colorScheme="blue" size="sm">
                <Linkk to={"/admin/assisting-program/" + item._id}>View</Linkk>
              </Button>
              {(userType === "Admin" || userType === "SuperAdmin") && (
                <Button
                  variant="solid"
                  colorScheme="red"
                  size="sm"
                  onClick={onOpenDM}
                >
                  Delete
                </Button>
              )}
              {["ProjectHolder", "community"].includes(userType) && (
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme={remainingPeriod ? "teal" : "red"}
                  onClick={onOpen}
                  disabled={!remainingPeriod}
                >
                  Apply
                </Button>
              )}
            </ButtonGroup>
          </Flex>
        </Flex>
        <DeleteConfirmationModal
          isOpen={isOpenDM}
          onClose={onCloseDM}
          headerContent="Confirm Deletion"
          bodyContent={`Are you sure you want to remove <b>${item.title}</b>? This action cannot be undone.`}
          handleDelete={deleteAssistingProgramAction}
        />
      </Card>
    </>
  );
}
