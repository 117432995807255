import {
  Box,
  Flex,
  Heading,
  Divider,
  Icon,
  Grid,
  GridItem,
  Tooltip,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { CloseIcon, EditIcon } from "@chakra-ui/icons";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "config/axios/config";
import VideoPlayer from "components/video/VideoPlayer";
import AssistinProgramsInfos from "./AssistinProgramsInfos";
import EditAssistingProgramForm from "../EditAssistingProgram/EditAssistingProgramForm";
import AskManager from "./AskManager";
import StackHolder from "./StackHolder";
import { update } from "reducers/assistingProgram";

export default function AssistingProgramOverview(props) {
  const { assistingProgram, getAssistingProgramByIdAction ,onReload} = props;
  const dispatch = useDispatch();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [userType, setUserType] = useState("");
  const [video, setVideo] = useState([]);
  const [videoError, setVideoError] = useState("");
  const currentUser = useSelector(
    (state) => state.UserInformation.currentUser.data
  );

  useEffect(() => {
    setUserType(currentUser ? currentUser.userType : "");
  }, [currentUser]);

  const updateVideoHandler = async () => {
    if (!video.length) {
      setVideoError("The video is required!");
      return;
    } else {
      setVideoError("");
      const data = new FormData();
      data.append("video", video[0]);
      const programData = {
        data,
        id: assistingProgram._id,
      };
      await dispatch(update(programData)).unwrap();
      onReload();
    }
  };
  return (
    <>
      <Heading size="sm" mt="4" mb="2">
        <Flex justifyContent={"flex-end"}>
          {(userType == "Admin" || userType == "SuperAdmin") && (
            <Tooltip
              label="Edit Assisting Programs Details"
              hasArrow
              placement="top-end"
            >
              <IconButton
                icon={<FaEdit />}
                size="sm"
                colorScheme="blue"
                onClick={onOpenModal}
              />
            </Tooltip>
          )}
        </Flex>
      </Heading>

      <Flex direction={"column"} mt="2">
        <AssistinProgramsInfos assistingProgram={assistingProgram} />{" "}
      </Flex>

      <EditAssistingProgramForm
        assistingProgram={assistingProgram}
        getAssistingProgramByIdAction={getAssistingProgramByIdAction}
        isOpen={isOpenModal}
        onClose={onCloseModal}
        onReload={() => onReload(true)}

      />

      <Divider my="2" />
      <Box p={2}>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "2fr 1fr",
            lg: "2fr 1fr",
          }}
          gap={4}
        >
          <GridItem>
            <VideoPlayer
              videoSrc={
                config.apiUrl +
                `/assisting-programs/file/` +
                assistingProgram.video
              }
              width="100%"
              height="200px"
              canEdit={userType == "Admin"}
              video={video}
              setVideo={setVideo}
              videoError={videoError}
              onEdit={updateVideoHandler}
              noVideoMessage="No video available for this project."
            />
          </GridItem>
          <GridItem>
            <AskManager managerEmail={assistingProgram.managerEmail} />
          </GridItem>
        </Grid>
        <Divider my="4" />
        <StackHolder
          organization={assistingProgram.partners}
          id={assistingProgram._id}
          onReload={() => onReload(true)}

        />
      </Box>
    </>
  );
}
