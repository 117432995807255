import Card from "components/card/Card.js";
import {
  Text,
  Textarea,
  Flex,
  Button,
  Heading,
  Divider,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { AiOutlineCloudSync } from "react-icons/ai";
import { applyOpportunity } from "reducers/callofStartups";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getRequestCallofstartupById } from "reducers/callofStartups";
import { useSelector } from "react-redux";

const Step3 = (props) => {
  const { idrequest ,id} = useParams();

  const { applyData, setApplyData, setStep,requestCall } = props;
  const [marketSize, setMarketSize] = useState(requestCall ? requestCall.MarketSize : applyData.marketSize);
  const [competitiveLandscape, setCompetitiveLandscape] = useState(
    requestCall ? requestCall.CompetitiveLandscape : applyData.competitiveLandscape
  );
  const [errormarketSize, setErrormarketSize] = useState("");
  const [errorcompetitiveLandscape, setErrorcompetitiveLandscape] =
    useState("");
    const dispatch = useDispatch();
   
  const validateForm = () => {
    let valid = true;
    if (!marketSize) {
      setErrormarketSize("This filed are required");
      valid = false;
    } else {
      setErrormarketSize("");
    }
    if (!competitiveLandscape) {
      setErrorcompetitiveLandscape("This filed are required");
      valid = false;
    } else {
      setErrorcompetitiveLandscape("");
    }

    return valid;
  };
  const confirm = async() => {
    if (!validateForm()) {
      return;
    }
    const Oppdata = new FormData();
    Oppdata.append("callOfStartup", id);
    Oppdata.append("project", requestCall ? requestCall.project._id : applyData.project);
    Oppdata.append("MarketSize", marketSize);
    Oppdata.append("CompetitiveLandscape", competitiveLandscape);
    Oppdata.append("step", 4);

    const dataaddOpp = {
      Oppdata,
    };
    await dispatch(applyOpportunity(dataaddOpp)).unwrap();
    const data = {
      ...applyData,
      marketSize,
      competitiveLandscape,
    };
    setApplyData(data);
    setStep(4);
  };
  const back = () => {
    setStep(2);
    setMarketSize("");
    setCompetitiveLandscape("");
    const data = {
      ...applyData,
      marketSize: "",
      competitiveLandscape: "",
    };
    setApplyData(data);
  };
  return (
    <Card>
      <Heading size="sm" mb="2">
        Market Analysis
      </Heading>
      <Divider mb="2" />
      <Flex mt="2" flexDirection={"column"}>
        <Text>
          Market Size: (Provide details on the size of the market you're
          targeting.)
        </Text>
        <Textarea
          mb="2"
          onChange={(e) => {
            setMarketSize(e.target.value);
          }}
          value={marketSize}
          placeholder="Market Size"
        />
        {errormarketSize && <Text color="red">{errormarketSize}</Text>}
        <Text>
          Competitive Landscape:** (Who are your main competitors? What
          differentiates you from them?)
        </Text>
        <Textarea
          mb="2"
          onChange={(e) => {
            setCompetitiveLandscape(e.target.value);
          }}
          value={competitiveLandscape}
          placeholder="Competitive Landscape"
        />
        {errorcompetitiveLandscape && (
          <Text color="red">{errorcompetitiveLandscape}</Text>
        )}
      </Flex>

      <Flex justifyContent="end" mt="4">
        <Button
          colorScheme="blue"
          variant="outline"
          size="md"
          onClick={back}
          mr="2"
        >
          Back
        </Button>
        <Button colorScheme="blue" size="md" onClick={confirm}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};

export default Step3;
