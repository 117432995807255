import React from "react";
import { Box, Avatar, Text, IconButton, useDisclosure } from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";

import { DeleteIcon } from "@chakra-ui/icons";
import config from "config/axios/config";
import { useDispatch } from "react-redux";
import { deleteMemberR } from "reducers/cohort";
import { useHistory } from "react-router-dom";
import DeleteConfirmationModal from "components/modals/DeleteConfirmationModal";

const TeamMember = (props) => {
  const { member, canDelete, projectId, onReload } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleDelete = async () => {
    const filterData = {
      idmember: member._id,
      idproject: projectId,
    };
    await dispatch(deleteMemberR(filterData)).unwrap();
    toast.success(
      `${member.firstName} ${member.lastName} has been removed from the team.`,
      {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      }
    );
    onReload();
  };
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      mb={4}
      display="flex"
      alignItems="center"
    >
      <ToastContainer />
      <Avatar
        size="md"
        name={member.firstName}
        src={config.apiUrl + "/api/getfile/" + member.Image}
        onClick={() => history.push("/admin/expert/profile/" + member._id)}
        cursor={"pointer"}
      />
      <Box flex="1" ml={4}>
        <Text
          fontWeight="bold"
          fontSize="lg"
          _hover={{ color: "blue.500", cursor: "pointer" }}
          onClick={() => history.push("/admin/expert/profile/" + member._id)}
        >
          {member.firstName} {member.lastName}
        </Text>
        <Text color="gray.500" mb={2}>
          {member.email}
        </Text>
      </Box>
      {canDelete && (
        <IconButton
          colorScheme="red"
          aria-label="Delete"
          icon={<DeleteIcon />}
          onClick={onOpen}
        />
      )}
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        headerContent="Confirm Deletion"
        bodyContent={`Are you sure you want to remove <b>${member.firstName} ${member.lastName}</b> from your team? This action cannot be undone.`}
        handleDelete={handleDelete}
      />
    </Box>
  );
};

export default TeamMember;
