import React, { useState, useEffect } from "react";
import { Button, Text, IconButton } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { AddIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { Input, Stack } from "@chakra-ui/react";
import "./ProfileSettings.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getResume, updateResume } from "reducers/userInformation";
import { useDispatch, useSelector } from "react-redux";
import EducationItem from "./EducationItem";

const Education = (props) => {
  const dispatch = useDispatch();
  const cancelProfileSettings = () => {};
  const resumeData = useSelector((state) => state.UserInformation.resume.data);
  const updatedResumeStatus = useSelector(
    (state) => state.UserInformation.updatedResume.status
  );
  const [currentEducation, setCurrentEducation] = useState({
    diplome: "",
    start: "",
    end: "",
    place: "",
  });
  const [index, setIndex] = useState(0);
  const [showAddEducation, setShowAddEducation] = useState(false);
  const [showEditEducation, setShowEditEducation] = useState(false);
  const [diplomeError, setDiplomeError] = useState("");
  const [startError, setStartError] = useState("");
  const [endError, setEndError] = useState("");
  const [placeError, setPlaceError] = useState("");
  const validateForm = () => {
    let invalidForm = false;
    if (!currentEducation.diplome) {
      invalidForm = true;
      setDiplomeError("This field is required!");
    } else {
      setDiplomeError("");
    }
    if (!currentEducation.start) {
      invalidForm = true;
      setStartError("This field is required!");
    } else if (
      currentEducation.start &&
      currentEducation.start > new Date().toISOString()
    ) {
      setStartError("Start date cannot be in the future");
    } else {
      setStartError("");
    }
    if (!currentEducation.end) {
      invalidForm = true;
      setEndError("This field is required!");
    } else {
      setEndError("");
    }
    if (!currentEducation.place) {
      invalidForm = true;
      setPlaceError("This field is required!");
    } else {
      setPlaceError("");
    }
    return invalidForm;
  };
  const notify = () =>
    toast.success("Your education has been successfully updated!", {
      position: "top-right",
      hideProgressBar: false,
      onClick: () => {
        window.location.href = "/";
      },
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

  const [resume, setResume] = useState({
    Education: [
      {
        diplome: "",
        start: "",
        end: "",
        place: "",
      },
    ],
    workHistory: [
      {
        workingType: "",
        start: "",
        end: "",
        company: "",
      },
    ],
    Language: [""],
  });

  const getResumeHandler = async () => {
    await dispatch(getResume()).unwrap();
  };

  const updateResumeHandler = async () => {
    const invalidForm = validateForm();
    if (invalidForm) {
      return;
    }
    const updatedEducation = [...resume.Education];
    if (showAddEducation) {
      updatedEducation[updatedEducation.length] = currentEducation;
    } else {
      updatedEducation[index] = currentEducation;
    }
    setResume({ ...resume, Education: updatedEducation });
    await dispatch(
      updateResume({ ...resume, Education: updatedEducation })
    ).unwrap();
    setCurrentEducation({
      diplome: "",
      start: "",
      end: "",
      place: "",
    });
    setShowAddEducation(false);
    setShowEditEducation(false);
    notify();
  };

  useEffect(() => {
    if (
      resumeData &&
      resumeData.message &&
      resumeData.message.resume &&
      resumeData.message.resume[0]
    ) {
      const { Education, workHistory, Language } = resumeData.message.resume[0];
      setResume({ Education, workHistory, Language });
    }
  }, [resumeData]);

  useEffect(() => {
    if (updatedResumeStatus === "success") {
      props.getUser();
      getResumeHandler();
    }
  }, [updatedResumeStatus]);

  useEffect(() => {
    getResumeHandler();
  }, []);

  const handleEducationChange = (event, index) => {
    const x = { ...currentEducation };
    const { name, value } = event.target;
    x[name] = value;
    setCurrentEducation(x);
  };

  const handleDeleteEducation = async (index) => {
    const updatedEducation = [...resume.Education];
    updatedEducation.splice(index, 1);
    setResume({ ...resume, Education: updatedEducation });
    const x = { ...resume, Education: updatedEducation };
    await dispatch(updateResume(x)).unwrap();
    notify();
  };

  const showAddHandler = () => {
    if (!showAddEducation && !showEditEducation) {
      setShowAddEducation(true);
    } else if (showAddEducation) {
      setShowAddEducation(false);
    } else if (showEditEducation) {
      setShowEditEducation(false);
    }
    setCurrentEducation({
      diplome: "",
      start: "",
      end: "",
      place: "",
    });
  };

  const showEditHandler = (item, index) => {
    setShowEditEducation(true);
    setCurrentEducation(item);
    setIndex(index);
  };

  return (
    <Stack
      padding="8"
      display={"flex"}
      flexDirection={"column"}
      borderRadius={"30px"}
      backgroundColor={"white"}
    >
      <Flex spacing="4">
        <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
          <h3 className="SettingTitle">Education</h3>
        </Flex>
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="add"
          icon={
            !showAddEducation && !showEditEducation ? (
              <AddIcon />
            ) : (
              <CloseIcon />
            )
          }
          onClick={showAddHandler}
        />
      </Flex>
      <Text marginBottom={"24px!important"} mt="0" color="gray.500" isTruncated>
        The changes you'll make will appear on your profile
      </Text>
      {!showAddEducation &&
        !showEditEducation &&
        resume.Education.map((item, index) => (
          <EducationItem
            item={item}
            index={index}
            handleDeleteEducation={() => handleDeleteEducation(index)}
            setCurrentEducation={setCurrentEducation}
            showEditHandler={() => showEditHandler(item, index)}
          />
        ))}
      {(showAddEducation || showEditEducation) && (
        <>
          <label>
            <Input
              type="text"
              name="diplome"
              placeholder="diplome"
              marginTop={"5px!important"}
              borderRadius="16px"
              marginBottom="10px"
              marginRight="10px"
              required
              value={currentEducation.diplome}
              onChange={(event) => handleEducationChange(event, index)}
            />
            {diplomeError && (
              <Text mb="2" color="red">
                {diplomeError}
              </Text>
            )}
            <Input
              type="date"
              name="start"
              placeholder="start date"
              marginTop={"5px!important"}
              borderRadius="16px"
              marginBottom="10px"
              marginRight="10px"
              required
              max={currentEducation.end ? currentEducation.end : ""}
              onChange={(event) => handleEducationChange(event, index)}
              value={
                currentEducation.start
                  ? new Date(currentEducation.start)
                      .toISOString()
                      .substring(0, 10)
                  : Date.now()
              }
            />
            {startError && (
              <Text mb="2" color="red">
                {startError}
              </Text>
            )}
            <Input
              type="date"
              name="end"
              placeholder="end date"
              marginTop={"5px!important"}
              borderRadius="16px"
              marginBottom="10px"
              marginRight="10px"
              min={currentEducation.start ? currentEducation.start : ""}
              onChange={(event) => handleEducationChange(event, index)}
              value={
                currentEducation.end
                  ? new Date(currentEducation.end)
                      .toISOString()
                      .substring(0, 10)
                  : Date.now()
              }
            />
            {endError && (
              <Text mb="2" color="red">
                {endError}
              </Text>
            )}
            <Input
              type="text"
              name="place"
              placeholder="place"
              marginTop={"5px!important"}
              borderRadius="16px"
              marginBottom="10px"
              marginRight="10px"
              required
              onChange={(event) => handleEducationChange(event, index)}
              value={currentEducation.place}
            />
            {placeError && (
              <Text mb="2" color="red">
                {placeError}
              </Text>
            )}
          </label>
          <Flex justifyContent="flex-end" alignItems={"end"}>
            {false && (
              <Button
                onClick={() => {
                  cancelProfileSettings();
                }}
                size="md"
                marginTop={"24px!important"}
                color="#422AFB"
                border="2px solid #422AFB"
                mr="12px"
                marginLeft={"auto!important"}
                width="180px"
              >
                Cancel changes
              </Button>
            )}
            <Button
              variant="brand"
              onClick={() => {
                updateResumeHandler();
              }}
              marginTop={"24px!important"}
              size="md"
              width="180px"
            >
              Save changes
            </Button>
          </Flex>
        </>
      )}
    </Stack>
  );
};

export default Education;
