import Card from "components/card/Card.js";

import {
  Text,
  Box,
  Flex,
  Button,
  Input,
  Textarea,
  Heading,
  Divider,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { toast, ToastContainer } from "react-toastify";
import { addgrants } from "reducers/grants";
import UploadComponent from "components/upload/UploadFile";

const AddGrants = () => {
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);

  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dates, setDates] = useState([]);

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [maxBudget, setmaxBudget] = useState();
  const [maxBudgeterror, setmaxBudgeterror] = useState();

  const [minBudget, setminBudget] = useState();
  const [minBudgeterror, setminBudgeterror] = useState();

  const [maximumperproject, setmaximumperproject] = useState();
  const [totalfundvalue, settotalfundvalue] = useState();
  const [maximumperprojecterror, setmaximumperprojecterror] = useState();
  const [totalfundvalueerror, settotalfundvalueerror] = useState();

  const [photoError, setPhotoError] = useState("");
  const [photo, setPhoto] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [datesError, setDatesError] = useState("");
  const grantsStatus = useSelector((state) =>
    state.Grants.addgrantsState ? state.Grants.addgrantsState.status : 0
  );

  const validateForm = () => {
    let valid = true;

    if (!photo) {
      setPhotoError("The image is required!");
      valid = false;
    } else {
      setPhotoError("");
    }
    if (!title) {
      setTitleError("The title is required!");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description) {
      setDescriptionError("The description is required!");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!start || !end) {
      setDatesError("The start & end dates are required!");
      valid = false;
    } else {
      setDatesError("");
    }
    if (!maxBudget) {
      setmaxBudgeterror("The max budget are required!");
      valid = false;
    } else {
      setmaxBudgeterror("");
    }
    if (!minBudget) {
      setminBudgeterror("The min budget are required!");
      valid = false;
    } else {
      setminBudgeterror("");
    }
    if (!maximumperproject) {
      valid = false;
    } else {
      setmaximumperprojecterror("");
    }
    if (!totalfundvalue) {
      valid = false;
    } else {
      settotalfundvalueerror("");
    }
    return valid;
  };

  const addGrantsAction = async () => {
    if (!validateForm()) {
      return;
    }
    const grants = new FormData();
    grants.append("endDate", end);
    grants.append("startDate", start);
    grants.append("name", title);
    grants.append("description", description);
    grants.append("Image", photo[0]);
    grants.append("minAllowedBudget", minBudget);
    grants.append("maxAllowedBudget", maxBudget);
    grants.append("maxPerProject", maximumperproject);
    grants.append("totalFundValue", totalfundvalue);

    await dispatch(addgrants(grants)).unwrap();
    console.log(grantsStatus,'grantsStatus')
    if (grantsStatus == "success") {
      toast.info("Grant added", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
      window.location.href = "/#/admin/grants";
    }
    if (grantsStatus == "failed") {
      toast.error("check your data", {
        position: "top-right",
        hideProgressBar: true,
        theme: "light",
      });
    }
  };
  const Upfiles = (file) => {
    setPhoto(file);
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <ToastContainer />
        {!confirm && (
          <Card>
            <Heading size="sm" mb="2">
              General Informations
            </Heading>
            <Divider mb="2" />
            <Flex mt="2" alignItems={"center"}>
              <UploadComponent setFiles={Upfiles} />
              {photoError && (
                <Text mt="3" color="red">
                  {photoError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <Input
                isInvalid={titleError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="grants name"
                borderRadius="5px"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {titleError && (
                <Text mt="3" color="red">
                  {titleError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <Textarea
                isInvalid={descriptionError}
                marginTop={"5px!important"}
                w={"100%"}
                marginRight="10px"
                placeholder="Description"
                borderRadius="5px"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {descriptionError && (
                <Text mt="3" color="red">
                  {descriptionError}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <Text color={"grey"}>What is your maximum allowed budget !</Text>

              <NumberInput
                onChange={(valueString) => {
                  setmaxBudget(valueString);
                }}
                placeholder="Max Allowed Budget"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {maxBudgeterror && (
                <Text mt="3" color="red">
                  {maxBudgeterror}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <Text color={"grey"}>What is your minimum allowed budget !</Text>

              <NumberInput
                onChange={(valueString) => {
                  setminBudget(valueString);
                }}
                placeholder="minimum allowed budget"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {minBudgeterror && (
                <Text mt="3" color="red">
                  {minBudgeterror}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <Text color={"grey"}>maximum per project !</Text>

              <NumberInput
                onChange={(valueString) => {
                  setmaximumperproject(valueString);
                }}
                placeholder="maximum per project "
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {maximumperprojecterror && (
                <Text mt="3" color="red">
                  {maximumperprojecterror}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <Text color={"grey"}>total fund value !</Text>

              <NumberInput
                onChange={(valueString) => {
                  settotalfundvalue(valueString);
                }}
                placeholder="total fund value"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {totalfundvalueerror && (
                <Text mt="3" color="red">
                  {totalfundvalueerror}
                </Text>
              )}
            </Flex>
            <Flex mt="2" direction={"column"}>
              <DatePicker
                inputClass={datesError ? "fullwidth redborder" : "fullwidth"}
                format="MM/DD/YYYY HH:mm"
                range
                plugins={[
                  <TimePicker position="bottom" hideSeconds={true} />,
                  <DatePanel markFocused header="Start - End" />,
                ]}
                placeholder="Start & End Dates"
                id="dates"
                name="dates"
                value={dates}
                onChange={(ev) => {
                  setDates(ev);
                  if (ev[0]) {
                    setStart(
                      ev[0].year +
                        "/" +
                        (ev[0].month.index + 1).toString() +
                        "/" +
                        ev[0].day +
                        " " +
                        ev[0].hour +
                        ":" +
                        ev[0].minute
                    );
                  }

                  if (ev[1]) {
                    setEnd(
                      ev[1].year +
                        "/" +
                        (ev[1].month.index + 1).toString() +
                        "/" +
                        ev[1].day +
                        " " +
                        ev[1].hour +
                        ":" +
                        ev[1].minute
                    );
                  }
                }}
                onClose={() => true}
              />
              {datesError && (
                <Text mt="3" color="red">
                  {datesError}
                </Text>
              )}
            </Flex>
            <Flex justifyContent="end" mt="4">
              <Button colorScheme="blue" size="md" onClick={addGrantsAction}>
                Confirm
              </Button>
            </Flex>
          </Card>
        )}
      </Box>
    </>
  );
};

export default AddGrants;
